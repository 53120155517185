import styled from 'styled-components';
import { idpwsign, SIGN_TYPE } from '../../../../components/auth/SignIframe';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../components/button/Button';
import Input from '../../../../components/input/Input';
import InputBox from '../../../../components/input/InputBox';
import ListHeader from '../../../../components/layout/ListHeader';
import ListItem from '../../../../components/layout/ListItem';
const SpanBlock = styled.span`
  // .desc
  display: block;
  margin-top: 8px;
  padding-left: 20px;
  font-size: 13px;
  color: #8c939c;
  line-height: 1.33;
`;
const ManagerPwChangeForm = ({ register, stateMsg, handlePwChange }) => {
  const openSignModal = (payload) => {
    setTimeout(() => {
      //idpwsign(selTabNum === 0 ? SIGN_TYPE.PERSON : SIGN_TYPE.CORP);
      idpwsign(SIGN_TYPE.CORP, callback);
    }, 0);
  };

  const callback = (sign, payload) => {
    console.log(sign);
  };

  return (
    <>
      <ListHeader
        title="조회용 로그인"
        description="조회 전용 비밀번호를 설정하여 주세요"
      />
      <ListItem>
        <InputBox title="아이디">
          <Input register={register} name="corpRegisterNumber" />
        </InputBox>
        <InputBox title="비밀번호">
          <Button
            type={ButtonTypes.Primary}
            size={ButtonSize.Default}
            onClick={openSignModal}
          >
            변경
          </Button>
          <SpanBlock>{stateMsg}</SpanBlock>
        </InputBox>
      </ListItem>
    </>
  );
};
export default ManagerPwChangeForm;
