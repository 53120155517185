import { all, takeLatest } from '@redux-saga/core/effects';
import APIs from '..';
import createRequestSaga from '../../../utils/createRequestSaga';
import {
  fetchGetElectionsAgmBallotAction,
  fetchPostElectionsAgmVoteAction,
  fetchPutElectionsAgmCancelProxyAction,
  fetchPutElectionsAgmCancelVoteAction
} from './promiseActions';
/**
 * 투표하기
 */

// 투표 화면
const fetchGetElectionsAgmBallotSaga = createRequestSaga(
  fetchGetElectionsAgmBallotAction,
  APIs.Elections.fetchGetElectionsAgmBallot
);

// 투표하기 전자서명
const fetchPostElectionsAgmVoteSaga = createRequestSaga(
  fetchPostElectionsAgmVoteAction,
  APIs.Elections.fetchPostElectionsAgmVote
);

// 투표하기 - 전자위임장 취소
const fetchPutElectionsAgmCancelProxySaga = createRequestSaga(
  fetchPutElectionsAgmCancelProxyAction,
  APIs.Elections.fetchPutElectionsAgmCancelProxy
);

// 투표하기 - 전자의결권 취소
const fetchPutElectionsAgmCancelVoteSaga = createRequestSaga(
  fetchPutElectionsAgmCancelVoteAction,
  APIs.Elections.fetchPutElectionsAgmCancelVote
);

export default function* saga() {
  yield all([
    takeLatest(
      fetchGetElectionsAgmBallotAction,
      fetchGetElectionsAgmBallotSaga
    ),
    takeLatest(fetchPostElectionsAgmVoteAction, fetchPostElectionsAgmVoteSaga),
    takeLatest(
      fetchPutElectionsAgmCancelProxyAction,
      fetchPutElectionsAgmCancelProxySaga
    ),
    takeLatest(
      fetchPutElectionsAgmCancelVoteAction,
      fetchPutElectionsAgmCancelVoteSaga
    )
  ]);
}
