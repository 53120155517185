import PropsType from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import spriteCommonPng from '../../styles/images/common/sprite/sp_common.png';

const AlertBlock = styled.div`
  // .state
  display: block;
  margin-top: 25px;
  font-size: 17px;
  font-weight: bold;
  position: relative;
  ${(props) =>
    // .state i01
    props.type === AlertTypes.Success &&
    css`
      margin-top: 28px;
      padding-left: 44px;
      color: #24995a;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        margin-top: -5px;
        display: block;
        width: 30px;
        height: 30px;
        background: url(${spriteCommonPng}) no-repeat;
        background-size: 400px auto;
        background-position: -83px -95px;
      }
    `}
  ${(props) =>
    // .state i02
    props.type === AlertTypes.Warning &&
    css`
      margin-top: 28px;
      padding-left: 44px;
      color: #ff4040;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        margin-top: -5px;
        display: block;
        width: 30px;
        height: 30px;
        background: url(${spriteCommonPng}) no-repeat;
        background-size: 400px auto;
        background-position: -83px -125px;
      }
    `}
  ${(props) =>
    // .state i03
    props.type === AlertTypes.Error &&
    css`
      margin-top: 28px;
      padding-left: 44px;
      color: #ff4040;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        margin-top: -5px;
        display: block;
        width: 30px;
        height: 30px;
        background: url(${spriteCommonPng}) no-repeat;
        background-size: 400px auto;
        background-position: -83px -155px;
      }
    `}
`;

export const AlertTypes = {
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
  Error: 'error'
};
const Alert = (props) => {
  const { type, message } = props;
  return <AlertBlock type={type}>{message}</AlertBlock>;
};

Alert.propTypes = {
  type: PropsType.oneOf(Object.values(AlertTypes)),
  message: PropsType.string
};

export default Alert;
