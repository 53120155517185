import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../components/button/Button';
import ButtonGroup from '../../../../components/button/ButtonGroup';
import Input, { ErrorTypes } from '../../../../components/input/Input';
import InputBox from '../../../../components/input/InputBox';
import { PopupSearchInput } from '../../../../components/input/SearchInput';
import Select from '../../../../components/input/Select';
import ListHeader from '../../../../components/layout/ListHeader';
import ListItem from '../../../../components/layout/ListItem';
import { LayerBodyContent } from '../../../../components/modal/LayerBodyContent';
import { MODAL_SIZE } from '../../../../components/modal/Modal';
import Notice, { NoticeTypes } from '../../../../components/notice/Notice';
import {
  fetchGetAgmLimitsStockholdersAction,
  fetchGetAgmStockholderAction,
  fetchGetAgmVotingRightsTotalAction,
  fetchPostAgmBrowseIndividualAction,
  fetchPostAgmLimitsAction,
  fetchPutAgmLimitsAction
} from '../../../../services/api/agm/promiseActions';
import {
  getConstNameByCode,
  getConstNoVotingRightsByCode,
  getConstVotingRightsLimitByCode,
  VotingRightsLimitCode
} from '../../../../utils/constant/AgmConstant';
import { checkUndefinedNum } from '../../../../utils/StringUtil';
import SubModal from '../step4/main/SubModal';

const AgmRegisterStep3Modal = (props) => {
  const DEFAULT_SELECT_CODE = '-1';
  const ALL_SELECT_CODE = '0';
  const {
    closeModal,
    setReRender,
    votingRightsLimits = [],
    isUpdateFlag = false,
    rowIndex,
    agmSeqno
  } = props;
  const dispatch = useDispatch();
  const [isEtc, setIsEtc] = useState(false);
  const {
    register,
    reset,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'all' });
  const [agendas, setAgendas] = useState([]);
  const [stockholder, setStockholder] = useState({});
  const [showVotingRightsLimit, setShowVotingRightsLimit] = useState(true);
  const [limitReasonCode, setLimitReasonCode] = useState(DEFAULT_SELECT_CODE);
  const [applyAgendaSeqno, setApplyAgendaSeqno] = useState(DEFAULT_SELECT_CODE);
  const [showNoVotingRights, setShowNoVotingRights] = useState(true);
  const [stockholderLimitList, setStockholderLimitList] =
    useState(votingRightsLimits);
  const [stockholderIdInput, setStockholderIdInput] = useState();
  const [subModalOpen, setSubModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSumStockAmmount, setIsSumStockAmmount] = useState(false);
  const selAgendaRef = useRef(votingRightsLimits[rowIndex]);

  const totalStockAmount = useMemo(() => {
    return (
      stockholder.commonStockAmount +
      checkUndefinedNum(stockholder.exercisablePreferredStockAmount)
    );
  }, [stockholder]);

  useEffect(() => {
    dispatch(fetchGetAgmVotingRightsTotalAction({ agmSeqno })).then((res) => {
      if (!res.error) {
        setAgendas(res.data.totalVotingRights);
      }
    });
    if (isUpdateFlag) {
      dispatch(
        fetchPostAgmBrowseIndividualAction({
          agmSeqno,
          data: {
            shareholderSeqno: selAgendaRef.current?.stockholderSeqno
          }
        })
      ).then((res) => {
        if (!res.error) {
          onVoterSearch(atob(res.data.stockholderRealNameNumber));
          setTimeout(() => {
            reset({
              searchInputValue: selAgendaRef.current.stockholderRealNameNumber,
              elecVoterName: selAgendaRef.current.stockholderName,
              votingRightsLimitCode: selAgendaRef.current.votingRightsLimitCode,
              agendaSeqno: selAgendaRef.current.agendaSeqno,
              votingRightsLimitContent:
                selAgendaRef.current.votingRightsLimitContent
            });
            setLimitReasonCode(selAgendaRef.current.votingRightsLimitCode + '');
            setApplyAgendaSeqno(selAgendaRef.current.agendaSeqno + '');
          }, 100);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(stockholder).length !== 0) {
      if (!isUpdateFlag) {
        resetAllFields();
      }
      getStockholdersLimits(stockholder);
    }
  }, [stockholder]);

  useEffect(() => {
    if (
      limitReasonCode !== DEFAULT_SELECT_CODE &&
      applyAgendaSeqno !== DEFAULT_SELECT_CODE
    ) {
      setStockAmmountField();
    }
  }, [applyAgendaSeqno]);

  useEffect(() => {
    setValue('votingRightsLimitCode', limitReasonCode);
    if (limitReasonCode === DEFAULT_SELECT_CODE) {
      setShowVotingRightsLimit(true);
      setShowNoVotingRights(true);
    } else {
      setIsEtc(limitReasonCode === VotingRightsLimitCode.ETC.code);
      setShowNoVotingRights(
        getConstNoVotingRightsByCode(VotingRightsLimitCode, limitReasonCode)
      );
      setShowVotingRightsLimit(
        getConstVotingRightsLimitByCode(VotingRightsLimitCode, limitReasonCode)
      );
    }
  }, [limitReasonCode]);

  const watchFields = watch([
    'noVotingRightsStockAmmount',
    'votingRightsLimitStockAmmount'
  ]);

  useEffect(() => {
    const sum = watchFields.reduce((acc, cur) => Number(acc) + Number(cur), 0);
    if (totalStockAmount > 0) {
      setValue('commonStockAmount', totalStockAmount);
      setValue('exercisableStockAmmount', totalStockAmount - sum);
      setValue('sumStockAmmount', getFinalStockAmmount(totalStockAmount - sum));
    }
  }, [watchFields]);

  const resetAllFields = useCallback(() => {
    onChangeLimitReason(DEFAULT_SELECT_CODE);
    setIsSumStockAmmount(false);
    reset({
      elecVoterName: stockholder.elecVoterName,
      commonStockAmount: totalStockAmount,
      exercisableStockAmmount: totalStockAmount,
      votingRightsLimitStockAmmount: 0,
      noVotingRightsStockAmmount: 0
    });
  }, [stockholder]);

  const onVoterSearch = (idNo) => {
    const data = { identificationNumber: idNo, agmSeqno };
    setStockholderIdInput(idNo);
    dispatch(fetchGetAgmStockholderAction(data))
      .then((res) => {
        if (!res.error) {
          const stockholder = res.data;
          if (!!Object.keys(stockholder).length) {
            setStockholder(stockholder);
          } else {
            setErrorMessage(
              '잘못된 실명번호입니다.<br />실명번호를 확인 후 다시 검색해주세요.'
            );
            setSubModalOpen(true);
          }
        } else {
          setErrorMessage(res.error.dev_message);
          setSubModalOpen(true);
        }
      })
      .catch((e) => {
        console.log('!!!!! error !!!!!');
        console.log(e);
      });
  };

  // 단일주주 의결권 제한 조회
  const getStockholdersLimits = useCallback(
    (stockholder) => {
      const data = {
        securitiesCorpSeqno: stockholder.securitiesCorpSeqno,
        stockholderSeqno: stockholder.stockholderSeqno,
        agmSeqno
      };
      dispatch(fetchGetAgmLimitsStockholdersAction(data)).then((res) => {
        if (!res.error) {
          const data = res.data?.votingRightsLimits;
          setStockholderLimitList(data.length === 0 ? [] : data);
        }
      });
    },
    [stockholder]
  );

  // 주식 수 필드 값 채우기.
  const setStockAmmountField = () => {
    setIsSumStockAmmount(true);
    let exercisableStockAmmount = totalStockAmount;
    if (checkLimitAgenda(applyAgendaSeqno)) {
      // 이미 동일한 사유, 안건에 제한이 있는 경우 -> 수정 폼으로 변환
      const agenda = findLimitAgenda();
      exercisableStockAmmount -=
        agenda.noVotingRightsStockAmmount +
        agenda.votingRightsLimitStockAmmount;
      setValue('commonStockAmount', totalStockAmount);
      setValue('noVotingRightsStockAmmount', agenda.noVotingRightsStockAmmount);
      setValue(
        'votingRightsLimitStockAmmount',
        agenda.votingRightsLimitStockAmmount
      );
      setValue('exercisableStockAmmount', exercisableStockAmmount);
    } else {
      // 새로 제한을 하는 경우
      setValue('commonStockAmount', totalStockAmount);
      setValue('noVotingRightsStockAmmount', 0);
      setValue('votingRightsLimitStockAmmount', 0);
      setValue('exercisableStockAmmount', exercisableStockAmmount);
    }
    setTimeout(() => {
      setValue(
        'sumStockAmmount',
        getFinalStockAmmount(exercisableStockAmmount)
      );
    }, 100);
  };

  // 최종 의결권 가능 주식 수 구하기
  const getFinalStockAmmount = (exercisableStockAmmount) => {
    let sum = 0;
    for (let limit of stockholderLimitList) {
      if (isUpdateFlag) {
        // 수정일 때 : 현재 item 만 빼고 더해주면 됨.
        if (
          !(
            limit.agendaSeqno === parseInt(applyAgendaSeqno) &&
            limit.votingRightsLimitCode === limitReasonCode
          )
        ) {
          sum +=
            limit.votingRightsLimitStockAmmount +
            limit.noVotingRightsStockAmmount;
        }
      } else {
        // 새로 등록할 때,
        if (limit.agendaSeqno === 0) {
          // 전체면 현재가 아닌 경우를 제외하고 더해준다.
          if (
            !(
              limit.agendaSeqno === parseInt(applyAgendaSeqno) &&
              limit.votingRightsLimitCode === limitReasonCode
            )
          ) {
            sum +=
              limit.votingRightsLimitStockAmmount +
              limit.noVotingRightsStockAmmount;
          }
        } else if (
          // 현재 안건 및 다른 제한 사유일 때 빼준다.
          limit.agendaSeqno === parseInt(applyAgendaSeqno) &&
          limit.votingRightsLimitCode !== limitReasonCode
        ) {
          sum +=
            limit.votingRightsLimitStockAmmount +
            limit.noVotingRightsStockAmmount;
        }
      }
    }
    return exercisableStockAmmount - sum;
  };

  const checkNum = (num) => {
    let result = Number(num);

    if (isNaN(result) === true) {
      return false;
    } else {
      return true;
    }
  };

  const setDefaultNumToZero = (value) => {
    if (checkNum(value)) {
      return value;
    } else {
      return 0;
    }
  };

  // 제한 사유 변경
  const onChangeLimitReason = (votingRightsLimitCode) => {
    setLimitReasonCode(votingRightsLimitCode);

    // 제한 사유 변경 시 적용 안건을 기본으로 돌려놓자.
    if (!isUpdateFlag) {
      setApplyAgendaSeqno(DEFAULT_SELECT_CODE);
      setValue('agendaSeqno', DEFAULT_SELECT_CODE);
    }
  };

  // 적용 안건 변경
  const onChangeApplyAgenda = (agendaSeqno) => {
    if (limitReasonCode === DEFAULT_SELECT_CODE) {
      setErrorMessage('제한 사유를 먼저 선택해주세요');
      setSubModalOpen(true);
      setValue('agendaSeqno', DEFAULT_SELECT_CODE);
      return;
    }
    setApplyAgendaSeqno(agendaSeqno);
  };

  // 현재 사유와 안건으로 이미 제한되어있는지 판단
  const checkLimitAgenda = (agendaSeqno: string): boolean => {
    if (stockholderLimitList.length !== 0) {
      return !!stockholderLimitList.find(
        (item) =>
          item.agendaSeqno === parseInt(agendaSeqno) &&
          item.votingRightsLimitCode === limitReasonCode
      );
    }
    return false;
  };

  // 현재 제한 사유, 적용 안건과 동일한 제한 안건 찾기
  const findLimitAgenda = () => {
    return stockholderLimitList.find(
      (item) =>
        item.votingRightsLimitCode === limitReasonCode &&
        item.agendaSeqno === parseInt(applyAgendaSeqno)
    );
  };

  const checkETC = (stockAmmount) => {
    if (stockAmmount === '') {
      return false;
    }

    if (getValues('votingRightsLimitCode') === VotingRightsLimitCode.ETC.code) {
      if (
        parseInt(getValues('votingRightsLimitStockAmmount')) === 0 &&
        parseInt(getValues('noVotingRightsStockAmmount')) === 0
      ) {
        return false;
      } else if (
        stockholder?.commonStockAmount +
          stockholder?.exercisablePreferredStockAmount -
          parseInt(getValues('votingRightsLimitStockAmmount')) -
          parseInt(getValues('noVotingRightsStockAmmount')) <
        0
      ) {
        return false;
      }
    } else {
      if (!(parseInt(stockAmmount) > 0)) {
        return false;
      } else if (
        stockholder?.commonStockAmount +
          stockholder?.exercisablePreferredStockAmount -
          parseInt(stockAmmount) <
        0
      ) {
        return false;
      }
    }
    return true;
  };

  const submit = (data) => {
    if (!!getValues('sumStockAmmount') && getValues('sumStockAmmount') < 0) {
      setErrorMessage(
        '최종 의결권 가능 주식 수(e)는 0주보다 작을 수 없습니다.'
      );
      setSubModalOpen(true);
    } else {
      const params = {
        agmSeqno: agmSeqno,
        data: {
          votingRightsLimits: [
            {
              agendaSeqno: Number(data.agendaSeqno),
              stockholderSeqno: stockholder.stockholderSeqno,
              votingRightsLimitCode: data.votingRightsLimitCode,
              votingRightsLimitContent:
                data.votingRightsLimitCode === VotingRightsLimitCode.ETC.code
                  ? data.votingRightsLimitContent
                  : getConstNameByCode(
                      VotingRightsLimitCode,
                      data.votingRightsLimitCode
                    ),
              noVotingRightsStockAmmount: showNoVotingRights
                ? Number(data.noVotingRightsStockAmmount)
                : 0,
              votingRightsLimitStockAmmount: showVotingRightsLimit
                ? Number(data.votingRightsLimitStockAmmount)
                : 0,
              exercisableStockAmmount:
                totalStockAmount -
                data.noVotingRightsStockAmmount -
                data.votingRightsLimitStockAmmount,
              screenOrder: 1
            }
          ]
        }
      };
      if (isUpdateFlag || checkLimitAgenda(applyAgendaSeqno)) {
        const limitAgenda = findLimitAgenda();
        params.data.votingRightsLimits[0].limitSeqno = limitAgenda.limitSeqno;
        params.data.votingRightsLimits[0].screenOrder = limitAgenda.screenOrder;
      }
      const action =
        isUpdateFlag || checkLimitAgenda(applyAgendaSeqno)
          ? fetchPutAgmLimitsAction
          : fetchPostAgmLimitsAction;
      dispatch(action(params)).then((res) => {
        if (!res.error) {
          if (typeof closeModal === 'function') {
            closeModal();
            setReRender(true);
          }
        }
      });
    }
  };

  const clickModalClose = () => {
    setValue('searchInputValue', '');
    setSubModalOpen(false);
  };

  return (
    <>
      <LayerBodyContent>
        <ListHeader small />
        <ListItem>
          <InputBox
            title="실명번호"
            isRequired
            noFlex
            error={errors.searchInputValue?.message}
            errorType={ErrorTypes.ERROR}
          >
            <PopupSearchInput
              placeholder="주민/사업자등록 '-'없이 입력"
              onSearch={onVoterSearch}
              resetValue={stockholderIdInput}
              name="searchInputValue"
              register={register}
              disabled={typeof rowIndex !== 'undefined'}
            />
            <input type="hidden" name="stockholderSeqno" register={register} />
          </InputBox>
          <InputBox title="주주명" isRequired>
            <Input
              name="elecVoterName"
              placeholder="홍길동"
              register={register}
              disabled
            />
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox
            title="제한 사유"
            isRequired
            error={errors.votingRightsLimitCode?.message}
            errorType={ErrorTypes.ERROR}
          >
            <Select
              name="votingRightsLimitCode"
              register={register}
              registerOption={{
                validate: {
                  required: (value) =>
                    value !== DEFAULT_SELECT_CODE || '제한 사유를 선택하세요.'
                }
              }}
              onchange={(e) => onChangeLimitReason(e.target.value)}
              optionDisabled={isUpdateFlag ? true : false}
            >
              {/* TODO 
              {Object.keys(VotingRightsLimitCode).map((key, idx) =>
                isUpdateFlag ? (
                  <option
                    key={`limitCode_${idx + 1}`}
                    value={VotingRightsLimitCode[key].code}
                    disabled={isUpdateFlag ? true : false}
                  >
                    {VotingRightsLimitCode[key].name}
                  </option>
                ) : (
                  VotingRightsLimitCode[key].code !==
                    VotingRightsLimitCode.TREASURY_STOCK.code && (
                    <option
                      key={`limitCode_${idx + 1}`}
                      value={VotingRightsLimitCode[key].code}
                      disabled={isUpdateFlag ? true : false}
                    >
                      {VotingRightsLimitCode[key].name}
                    </option>
                  )
                )
              )}
              */}
              {Object.keys(VotingRightsLimitCode).map((key, idx) =>
                isUpdateFlag ? (
                  <option
                    key={`limitCode_${idx + 1}`}
                    value={VotingRightsLimitCode[key].code}
                    disabled={isUpdateFlag ? true : false}
                  >
                    {VotingRightsLimitCode[key].name}
                  </option>
                ) : (
                  <option
                    key={`limitCode_${idx + 1}`}
                    value={VotingRightsLimitCode[key].code}
                    disabled={isUpdateFlag ? true : false}
                  >
                    {VotingRightsLimitCode[key].name}
                  </option>
                )
              )}
            </Select>
          </InputBox>
          {isEtc ? (
            <InputBox
              title="직접 입력"
              error={errors.votingRightsLimitContent?.message}
              errorType={ErrorTypes.ERROR}
            >
              <Input
                placeholder="제한 사유 입력"
                name="votingRightsLimitContent"
                register={register}
                registerOption={{
                  required: {
                    value: true,
                    message: '제한 사유를 입력해주세요'
                  }
                }}
              />
            </InputBox>
          ) : (
            <InputBox isEmpty />
          )}
        </ListItem>
        <ListItem>
          <InputBox
            title="적용 안건"
            isRequired
            error={errors.agendaSeqno?.message}
            errorType={ErrorTypes.ERROR}
          >
            <Select
              name="agendaSeqno"
              register={register}
              onchange={(e) => onChangeApplyAgenda(e.target.value)}
              registerOption={{
                validate: {
                  required: (value) =>
                    value !== DEFAULT_SELECT_CODE || '적용 안건을 선택하세요.'
                }
              }}
              optionDisabled={isUpdateFlag ? true : false}
            >
              <option
                key="agenda_0"
                value="0"
                disabled={isUpdateFlag ? true : false}
              >
                {checkLimitAgenda(ALL_SELECT_CODE)
                  ? `전체 안건 (제한됨)`
                  : `전체 안건`}
              </option>
              {agendas.map((agenda, idx) => (
                <option
                  key={`agenda_${idx + 1}`}
                  value={agenda.agendaSeqno}
                  disabled={isUpdateFlag ? true : false}
                >
                  {checkLimitAgenda(agenda.agendaSeqno)
                    ? `[제 ${agenda.agendaNumber} 안건] ${agenda.agendaName} (제한됨)`
                    : `[제 ${agenda.agendaNumber} 안건] ${agenda.agendaName}`}
                </option>
              ))}
            </Select>
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox title="의결권 있는 주식 수 (a)">
            <Input
              placeholder="의결권 있는 주식 수"
              disabled
              register={register}
              name="commonStockAmount"
            />
          </InputBox>
          {showNoVotingRights ? (
            <InputBox
              title={`의결권 없는 주식 수 (b)`}
              isRequired
              error={errors.noVotingRightsStockAmmount?.message}
              errorType={ErrorTypes.ERROR}
            >
              <Input
                name="noVotingRightsStockAmmount"
                placeholder="의결권 없는 주식 수"
                register={register}
                registerOption={{
                  pattern: {
                    value: /^[0-9]+$/g,
                    message: '숫자만 입력 가능합니다.'
                  },
                  validate: {
                    checkETC: (value) =>
                      checkETC(value) || '주식 수를 확인해주세요',
                    onlyNumber: (value) =>
                      !isNaN(parseInt(value)) || '숫자만 입력 가능합니다.'
                  }
                }}
              />
            </InputBox>
          ) : (
            <InputBox isEmpty>
              <Input
                name="noVotingRightsStockAmmount"
                register={register}
                // type="number"
              />
            </InputBox>
          )}
        </ListItem>
        <ListItem>
          {showVotingRightsLimit ? (
            <InputBox
              title={`의결권 제한 주식 수 ${
                showNoVotingRights ? '(c)' : '(b)'
              }`}
              isRequired
              error={errors.votingRightsLimitStockAmmount?.message}
              errorType={ErrorTypes.ERROR}
            >
              <Input
                name="votingRightsLimitStockAmmount"
                placeholder="의결권 제한 주식 수"
                register={register}
                registerOption={{
                  pattern: {
                    value: /^[0-9]+$/g,
                    message: '숫자만 입력 가능합니다.'
                  },
                  validate: {
                    checkETC: (value) =>
                      checkETC(value) || '주식 수를 확인해주세요',
                    onlyNumber: (value) =>
                      !isNaN(parseInt(value)) || '숫자만 입력 가능합니다.'
                  }
                }}
              />
            </InputBox>
          ) : (
            <InputBox isEmpty>
              <Input
                name="votingRightsLimitStockAmmount"
                // type="number"
                register={register}
              />
            </InputBox>
          )}
          <InputBox
            title={`
          행사 가능 주식 수 ${
            showNoVotingRights && showVotingRightsLimit ? '(a-b-c)' : '(a-b)'
          }
          `}
          >
            <Input
              name="exercisableStockAmmount"
              placeholder="행사 가능 주식 수"
              register={register}
              disabled
            />
          </InputBox>
        </ListItem>
        {isSumStockAmmount ? (
          <ListItem>
            <InputBox title="최종 의결권 가능 주식 수(e)">
              <Input name="sumStockAmmount" register={register} readOnly />
            </InputBox>
            <InputBox>
              <Notice
                type={NoticeTypes.PopUp}
                description="최종 의결권 가능 주식은 한 주주가 여러건의 의결권 제한이 있는 경우,<br/>의결권 제한사항들이 반영된 그 의안의 최종 행사 가능한 주식수이다."
              />
            </InputBox>
          </ListItem>
        ) : (
          <></>
        )}
        {selAgendaRef.current?.votingRightsLimitCode !==
        VotingRightsLimitCode.TREASURY_STOCK.code ? (
          <ButtonGroup>
            <Button
              type={ButtonTypes.Primary}
              size={ButtonSize.Popup}
              onClick={handleSubmit(submit)}
            >
              {isUpdateFlag || checkLimitAgenda(applyAgendaSeqno)
                ? '수정'
                : '등록'}
            </Button>
          </ButtonGroup>
        ) : (
          <></>
        )}
      </LayerBodyContent>
      {subModalOpen && (
        <SubModal
          layerTitle="에러"
          content={errorMessage}
          modalSize={MODAL_SIZE.SHORT}
          handleModal={clickModalClose}
        />
      )}
    </>
  );
};

export default AgmRegisterStep3Modal;
