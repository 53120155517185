import { DELETE, GET, POST, PUT } from '../../../utils/constant/httpMethod';
import { request, requestFile } from '../../../utils/requestHelper';

const Common = {
  // 자주 하는 질문 - 자주묻는 질문 카테고리 조회
  fetchGetFaqCategories() {
    return request({
      method: GET,
      url: `/v1/faq/categories`
    });
  },
  // 자주 하는 질문 - 자주 하는 질문 목록 조회
  fetchGetFaq({ categorySeqno }) {
    return request({
      method: GET,
      url: `/v1/faq`,
      params: { categorySeqno: categorySeqno }
    });
  },
  // 공지사항 목록 조회
  fetchGetNotice({ page, size }) {
    return request({
      method: GET,
      url: `/v1/notice`,
      params: {
        page: page,
        size: size
      }
    });
  },
  // 공지사항 조회
  fetchGetNoticeByNotientSeqno(notientSeqno) {
    return request({
      method: GET,
      url: `/v1/notice/${notientSeqno}`
    });
  },
  // 공지사항 파일 조회
  fetchGetNoticeFiles({ notientSeqno, fileSeqno }) {
    return requestFile({
      method: GET,
      url: `/v1/notice/${notientSeqno}/files/${fileSeqno}`
    });
  },
  // 공지사항 상단 게시물 목록 조회
  fetchGetNoticeNotified() {
    return request({
      method: GET,
      url: `/v1/notice/notified`
    });
  },

  /* QNA */
  // 문의사항 목록 조회
  fetchGetQna(params) {
    return request({
      method: GET,
      url: `/v1/qna`,
      params
    });
  },
  // 나의 문의사항 목록 조회
  fetchGetQnaQuestioned(params) {
    return request({
      method: GET,
      url: `/v1/qna/questioned`,
      params
    });
  },
  // 문의사항 파일 조회
  fetchGetQnaFiles(params) {
    const { notientSeqno, fileSeqno } = params;
    return requestFile({
      method: GET,
      url: `/v1/qna/${notientSeqno}/files/${fileSeqno}`
    });
  },
  // 문의사항 조회
  fetchGetQnaByNotientSeqno(params) {
    const { notientSeqno, password } = params;
    return request({
      method: GET,
      url: `/v1/qna/${notientSeqno}`,
      params: {
        password
      }
    });
  },

  // 문의사항 등록
  fetchPostQna(data) {
    return request({
      method: POST,
      url: `/v1/qna`,
      data
    });
  },

  // 문의사항 재문의 (등록)
  fetchPostQnaReply(data) {
    const { highNotientSeqno, notientSeqno } = data;
    const seqno = notientSeqno ? notientSeqno : highNotientSeqno;
    return request({
      method: POST,
      url: `/v1/qna/${seqno}/reply`,
      data
    });
  },

  // 문의사항 수정
  fetchPutQna(data) {
    const { notientSeqno } = data;
    return request({
      method: PUT,
      url: `/v1/qna/${notientSeqno}`,
      data
    });
  },

  // 문의사항 삭제
  fetchDeleteQna(data) {
    const { notientSeqno } = data;
    return request({
      method: DELETE,
      url: `/v1/qna/${notientSeqno}`
    });
  },

  // 증권사 관리자 - 공지사항 - 공지사항 등록
  fetchPostNotice(data) {
    return request({
      method: POST,
      url: `/v1/notice`,
      data
    });
  },
  // 증권사 관리자 - 자주 하는 질문 - 자주묻는 질문 등록
  fetchPostFaq(data) {
    return request({
      method: POST,
      url: `/v1/faq`,
      data
    });
  },
  // 주소 검색
  fetchGetAddress(params) {
    return request({
      method: GET,
      url: `/v1/address`,
      params
    });
  }
};

export default Common;
