import PropTypes from 'prop-types'; // ES6
import React from 'react';
import styled from 'styled-components';
import { Icons } from '../../styles/Icons';

const IconButtonBlock = styled.button`
  ${(props) => {
    if (props.iconName) {
      return Icons[props.iconName].css;
    } else if (props.icon) {
      return props.icon.css;
    }
  }}
`;

const IconButton = (props) => {
  return <IconButtonBlock onClick={props.onClick} {...props}></IconButtonBlock>;
};

IconButton.propTypes = {
  iconName: PropTypes.string,
  icon: PropTypes.object,
  onclick: PropTypes.func
};

IconButton.defaultProps = {
  iconName: null,
  icon: null,
  onclick: null
};

export default IconButton;
