import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router';
import Spinner from './components/alert/Spinner';
import { fetchPostAuthRefreshAction } from './services/api/auth/promiseActions';
import { action as commonAction } from './store/commonSlice';
import { Role } from './utils/constant/AgmConstant';
import { getGnbRole } from './utils/constant/GnbMenu';
import { setAccessToken } from './utils/requestHelper';
import { isCommonUrl } from './utils/UrlUtil';

const AuthRoute = ({ children }) => {
  const dispatch = useDispatch();
  const { isAuth, userSeqno, role } = useSelector((state) => state.common.user);
  const [locationKeys, setLocationKeys] = useState();
  const [scrollTop, setScrollTop] = useState();
  const history = useHistory();

  const [isLogined, setIsLogined] = useState(
    isCommonUrl(history.location.pathname)
  );

  useEffect(() => {
    if (isAuth && userSeqno) {
      setIsLogined(true);
    }
    if (isAuth) {
      if (history.location.pathname === '/login') {
        movePage(role);
      }
    }
  }, [isAuth, userSeqno]);

  useEffect(() => {
    // 뒷정리 함수 이용
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
        setScrollTop(document.documentElement.scrollTop);
      }
      if (history.action === 'POP') {
        if (locationKeys && locationKeys.length > 0) {
          if (locationKeys[1] === location.key) {
            setLocationKeys(([_, ...keys]) => keys);
            // 앞으로 가기
          } else {
            setLocationKeys((keys) => [location.key, ...keys]);
            if (scrollTop) {
              setTimeout(() => {
                window.scrollTo(0, scrollTop);
              }, 500);
            }

            // 토큰이 없으면 메인으로 보낸다.
            const refreshToken = sessionStorage.getItem('refreshToken');
            if (!refreshToken) {
              history.push('/');
            }
          }
        }
      }
    });
  }, [locationKeys, history]);

  const movePage = (role) => {
    switch (role) {
      case Role.ISSINC_ADMIN.code:
      case Role.VIEW_ISSINC_ADMIN.code:
        history.push('/corp/agm/dashboard');
        break;
      case Role.INDIVIUDAL_SHAREHOLDER.code:
        history.push('/shareholder/my-agms');
        break;
      case Role.CORP_SHAREHOLDER.code:
        history.push('/shareholder/my-agms');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const refreshToken = sessionStorage.getItem('refreshToken');
    if (refreshToken) {
      dispatch(fetchPostAuthRefreshAction(refreshToken))
        .then((res) => {
          setAccessToken(res.data.accessToken);

          if (!res.error) {
            dispatch(
              commonAction.setCurrentUser({
                userSeqno: res.data.userSeqno,
                userName: res.data.userName,
                currentAgm: null,
                role: res.data.role,
                isAuth: true
              })
            );
            const refreshTime = 1000 * 60 * 5; //(expiredTime.getTime() - Date.now()) / 1000 - 60000; // 한시간 남으면 리프레시

            // 특정 시간 지난 후 리프레시
            if (!window.tokenRefresh) {
              window.tokenRefresh = setInterval(() => {
                dispatch(
                  fetchPostAuthRefreshAction(res.data.refreshToken)
                ).then((res) => {
                  setAccessToken(res.data.accessToken);
                });
              }, refreshTime);
            }
            dispatch(commonAction.setCurrentGnb(getGnbRole(res.data.role)));
            setIsLogined(true);
            //movePage(res.data.role);
          } else {
            setIsLogined(true);
            history.push('/');
          }
        })
        .catch((e) => console.log(e));
    } else {
      if (!isCommonUrl(history.location.pathname)) {
        console.log('unauthorized');
        setIsLogined(true);
        history.push('/');
      }
    }
  }, [dispatch, history]);

  return (
    <>
      {!isLogined && <Spinner isEmptyScreen={true} />}
      {isLogined && children}
    </>
  );
};

export default withRouter(AuthRoute);
