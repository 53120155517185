import styled from 'styled-components';

export const UnitTitleBlock = styled.div`
  // .unit_ti
  display: block;
  margin-bottom: 10px;
  & > span {
    position: relative;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: #080808;
  }
`;
