import React from 'react';
import styled, { css } from 'styled-components';
import SpriteCommonPng from '../../styles/images/common/sprite/sp_common.png';

const AnchorBlock = styled.a`
  display: block;
  width: 32px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  text-align: center;
  border: 1px solid #e9ecf4;
  background: #fff;
  border-radius: 3px;
  color: #9eb3cd;
  font-size: 15px;
  transform: skew(-0.04deg);
  position: relative;
  cursor: pointer;
  ${(props) =>
    !props.active &&
    css`
      &:hover {
        border: 1px solid #66676b;
        background: #fff;
        color: #66676b;
      }
    `}
  ${(props) =>
    props.active &&
    css`
      border: 1px solid #66676b;
      background: #66676b;
      color: #ffffff;
    `}
    ${(props) =>
    props.prev &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 8px;
        left: 12px;
        display: block;
        width: 8px;
        height: 14px;
        background: url(${SpriteCommonPng}) no-repeat;
        background-size: 400px auto;
        background-position: -302px -1px;
      }
    `}
    ${(props) =>
    props.next &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 8px;
        left: 12px;
        display: block;
        width: 8px;
        height: 14px;
        background: url(${SpriteCommonPng}) no-repeat;
        background-size: 400px auto;
        background-position: -312px -1px;
      }
    `};
`;

const PaginationBlock = styled.div`
  width: 100%;
  text-align: center;
  height: 32px;
  & ul::after {
    content: '';
    display: block;
    clear: both;
  }
  & ul {
    width: auto;
    display: inline-block;
    & li {
      float: left;
      display: block;
      margin: 0 2px;
      & ${AnchorBlock} {
      }
    }
  }
`;
const getPageingInfo = (pageConfig) => {
  const { pageNumber, totalPageNumber, size } = pageConfig;

  const totalPage = totalPageNumber;
  const currentPage = pageNumber + 1;
  const navPage = size;
  const pageGroup = Math.ceil(currentPage / navPage);

  let last = pageGroup * navPage;
  if (last > totalPage) {
    last = totalPage;
  }
  let first = pageGroup * size - size + 1;
  const foreFront = 1;
  const prev = first - 1;
  let next = last + 1;
  if (next > totalPage) {
    next = 0;
  }
  const rearMost = totalPage;

  return {
    totalPage,
    currentPage,
    navPage,
    pageGroup,
    first,
    last,
    foreFront,
    prev,
    next,
    rearMost
  };
};

const Pagination = (props) => {
  const { pageConfig } = props;
  const { onClick } = pageConfig;
  const pageInfo = getPageingInfo(pageConfig);

  const {
    currentPage,
    first,
    last,
    prev,
    next
    // totalPage,
    // navPage,
    // pageGroup,
    // foreFront,
  } = pageInfo;

  let pageArr = [];
  for (let i = first; i <= last; i++) {
    pageArr.push(i);
  }

  return (
    <PaginationBlock>
      <ul>
        {!!prev && (
          <li>
            <AnchorBlock
              prev={true}
              onClick={() => {
                onClick(prev - 1);
              }}
            />
          </li>
        )}

        {pageArr.map((page, idx) => {
          return (
            <li key={`paging_a_${idx}`}>
              <AnchorBlock
                onClick={() => {
                  onClick(page - 1);
                }}
                active={page === currentPage}
              >
                {page}
              </AnchorBlock>
            </li>
          );
        })}

        {!!next && (
          <li>
            <AnchorBlock
              next={true}
              onClick={() => {
                onClick(next - 1);
              }}
            />
          </li>
        )}
      </ul>
    </PaginationBlock>
  );
};

export default Pagination;
