import React from 'react';
import styled from 'styled-components';
import {
  MONTH_COUNT,
  SELECT_BOX_YEAR_COUNT,
  THIS_YEAR
} from '../../utils/DateUtil';

const SelectYMBlock = styled.select`
  flex-basis: auto;
  height: auto;
  padding: 7px 20px 5px;
  background: #3985d9;
  border: 1px solid #58a0ef;
  border-radius: 3px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
`;
const YMSpanBlock = styled.span`
  display: inline-block;
  margin: 0 4px;
  font-size: 13px;
  color: #fff;
`;
const YMOptionBlock = styled.option`
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
`;

const DateSelectBox = (props) => {
  const { date, changeMonth, changeYear } = props;
  const setYearBlock = () => {
    return (
      <SelectYMBlock
        key="yearBlock"
        value={date.getFullYear()}
        onChange={(e) => {
          typeof changeYear === 'function' && changeYear(e.target.value);
        }}
      >
        {Array(SELECT_BOX_YEAR_COUNT)
          .fill()
          .map((_, index) => {
            return (
              <YMOptionBlock key={`yearKey_${index}`}>
                {THIS_YEAR + 2 - index}
              </YMOptionBlock>
            );
          })}
      </SelectYMBlock>
    );
  };
  const setMonthBlock = () => {
    return (
      <SelectYMBlock
        key="monthBlock"
        value={date.getMonth() + 1}
        onChange={(e) =>
          typeof changeMonth === 'function' && changeMonth(e.target.value)
        }
      >
        {Array(MONTH_COUNT)
          .fill()
          .map((_, index) => {
            return (
              <YMOptionBlock key={`monthKey_${index + 1}`}>
                {index + 1}
              </YMOptionBlock>
            );
          })}
      </SelectYMBlock>
    );
  };
  // const selYMBlock = (props) => {
  //   const type = props.type === YM_TYPE.YEAR ? YM_TYPE.YEAR : YM_TYPE.MONTH;
  //   const value =
  //     type === YM_TYPE.YEAR ? date.getFullYear() : date.getMonth() + 1;
  //   const action = type === YM_TYPE.YEAR ? changeYear : changeMonth;
  //   const count = type === YM_TYPE.YEAR ? SELECT_BOX_YEAR_COUNT : MONTH_COUNT;
  //   return (
  //     <SelectYMBlock
  //       key={`${type}`}
  //       value={value}
  //       onChange={(e) => action(e.target.value)}
  //     >
  //       {Array(count)
  //         .fill()
  //         .map((_, index) => {
  //           return (
  //             <YMOptionBlock key={`monthKey_${index + 1}`}>
  //               {index + 1}
  //             </YMOptionBlock>
  //           );
  //         })}
  //     </SelectYMBlock>
  //   );
  // };
  return (
    <>
      {setYearBlock()}
      <YMSpanBlock key="yearSpanBlock">년</YMSpanBlock>
      {setMonthBlock()}
      <YMSpanBlock key="monthSpanBlock">월</YMSpanBlock>
    </>
  );
};
export default DateSelectBox;

export const YM_TYPE = {
  YEAR: 'year',
  MONTH: 'month'
};
