import React from 'react';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../components/button/CommonStyle';
import CheckBox from '../../../components/input/CheckBox';

const AgreeBoxBlock = styled.div`
  // .agree
  background-color: rgba(221, 227, 235, 0.35);
  border-radius: 4px;
  padding: 24px 54px 20px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding: 14px 16px;
  }
`;

const Title = styled.div`
    // .unit_ti
    display: block;
    margin-bottom: 10px;
}
`;

const AgreeBox = (props) => {
  const { setCheckedEmail, setCheckedSms, checkedSms, checkedEmail } = props;
  return (
    <AgreeBoxBlock>
      <Title>
        SMS, E-maiL 수신동의 시, 주주총회 전자투표 서비스에서 제공하는 다양한
        정보를 받을 수 있습니다.
      </Title>
      <CheckBox
        title="SMS 수신동의"
        noMargin
        checked={checkedSms}
        onChange={setCheckedSms}
      />
      <CheckBox
        title="E-mail 수신동의"
        checked={checkedEmail}
        onChange={setCheckedEmail}
      />
    </AgreeBoxBlock>
  );
};

export default AgreeBox;
