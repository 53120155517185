import PropTypes from 'prop-types'; // ES6
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import spCommonPng from '../../styles/images/common/sprite/sp_common.png';
import spCommonMobilePng from '../../styles/images/common/sprite/sp_common_mobile.png';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';

const RadioBlock = styled.div`
  //.radio_ty
  position: relative;
  display: inline-block;

  // .ml_ty01
  margin-left: 3%;
  // TODO 왜 3%가 6% 처럼 먹는건지,,,
`;

const RadioInputBlock = styled.input`
  // .radio_ty input[type="radio"]
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
`;

const RadioLabelBlock = styled.label`
  // .radio_ty input[type="radio"] + label
  position: relative;
  display: inline-block;
  padding: 4px 0 2px 40px;
  line-height: 1.67;
  letter-spacing: -0.5px;
  color: #080808;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: relative;
    display: inline-block;
    padding: 2px 0 2px 24px;
    letter-spacing: -0.5px;
    color: #080808;
  }
  &::before {
    // .radio_ty input[type="radio"] + label::before
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    width: 27px;
    height: 27px;
    background: url(${spCommonPng}) no-repeat;
    background-size: 360px auto;
    background-position: -134px -1px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      width: 17px;
      height: 17px;
      background: url(${spCommonMobilePng}) no-repeat;
      background-size: 230px auto;
      background-position: -86px -1px;

      top: 3px;
    }
  }
  ${(props) =>
    props.checked &&
    css`
      // .radio_ty input[type="radio"]:checked + label
      font-weight: bold;
      transform: skew(-0.04deg);

      // .radio_ty input[type="radio"]:ch
      &::before {
        background-position: -134px -28px;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          background-position: -86px -18px;
        }
      }
    `}
`;

const Radio = (props) => {
  const {
    key,
    name,
    register,
    registerOption,
    value,
    label,
    onSelect,
    selectedValue
  } = props;
  const localChecked = useMemo(() => {
    return value === selectedValue;
  }, [selectedValue]);
  const reg = register ? register(name, registerOption) : {};

  const changeHandler = (e) => {
    if (typeof onSelect === 'function') {
      onSelect(e.target.value);
    }
  };

  return (
    <RadioBlock key={key}>
      <RadioLabelBlock checked={localChecked}>
        {label}
        <RadioInputBlock
          {...reg}
          type="radio"
          value={value}
          onClick={changeHandler}
        />
      </RadioLabelBlock>
    </RadioBlock>
  );
};

Radio.propTypes = {
  name: PropTypes.string,
  register: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onSelect: PropTypes.func,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Radio;
