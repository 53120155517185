import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { TableTitleBlock } from '../../../../../components/table/TableTitle';
import { fetchGetAgmProxyResultAction } from '../../../../../services/api/agm/promiseActions';
import ProxyApplyInfo from './contents/proxy/ProxyApplyInfo';
import ProxyResult from './contents/proxy/ProxyResult';

const ProxyExerciseList = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { agmSeqno } = match.params;
  const [proxyResult, setProxyResult] = useState();

  useEffect(() => {
    dispatch(fetchGetAgmProxyResultAction({ agmSeqno })).then((res) => {
      if (!res.error) {
        console.log(res.data);
        setProxyResult(res.data);
      }
    });
  }, [agmSeqno, dispatch]);
  return (
    <>
      {!!proxyResult && (
        <ProxyApplyInfo
          summary={proxyResult.proxySummary}
          agmSeqno={agmSeqno}
        />
      )}
      {/* <ProxyApplyInfo /> */}
      <TableTitleBlock>
        <span>의안별 전자위임 행사 내역</span>
      </TableTitleBlock>
      {!!proxyResult && (
        <ProxyResult agmSeqno={agmSeqno} agendas={proxyResult.agendas} />
      )}
    </>
  );
};

export default ProxyExerciseList;
