import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { idpwsign, SIGN_TYPE } from '../../../components/auth/SignIframe';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import { SCREEN_SIZE_MOBILE } from '../../../components/button/CommonStyle';
import Input, { ErrorTypes, InputSize } from '../../../components/input/Input';
import InputBox from '../../../components/input/InputBox';
import PhoneInput from '../../../components/input/PhoneInput';
import Label from '../../../components/label/Label';
import ListItem from '../../../components/layout/ListItem';
import Section from '../../../components/layout/Section';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import { ModalContext } from '../../../context/ModalContext';
import useWindowSize from '../../../hooks/useWindowSize';
import { fetchGetAuthPassCertAction } from '../../../services/api/auth/promiseActions';
import {
  fetchGetUsersMeAction,
  fetchPostUsersIndividualAuthenticationMethodAction,
  fetchPutUsersIndividualAction
} from '../../../services/api/users/promiseActions';
import { AuthenticationMethod } from '../../../utils/constant/AgmConstant';
import { getCurrentDatetime } from '../../../utils/DateUtil';
import { makeCellObject, makeCellString } from '../../../utils/ObjectUtil';
import Validation from '../../../utils/ValidationUtil';
import AgreeBox from './AgreeBox';

const IndivisualInfo = () => {
  let { openModal } = useContext(ModalContext);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const [realNumberRegisted, setRealNumberRegisted] = useState(false);
  const [certRegisted, setCertRegisted] = useState(false);
  const [passRegisted, setPassRegisted] = useState(false);
  const [checkedEmail, setCheckedEmail] = useState(false);
  const [checkedSms, setCheckedSms] = useState(false);
  const [prevCellNotiAgreeDate, setPrevCellNotiAgreeDate] = useState(null);
  const [prevEmailNotiAgreeDate, setPrevEmailNotiAgreeDate] = useState(null);
  const history = useHistory();
  const methods = useSelector((state) => state.common.authorization.methods);

  const windowSize = useWindowSize();
  const isMobile = useMemo(() => {
    return windowSize.width <= SCREEN_SIZE_MOBILE;
  }, [windowSize]);

  useEffect(() => {
    const fetchFunc = async () => {
      const result = await dispatch(fetchGetUsersMeAction());
      if (!result.error) {
        const individualUser = result.data.individualUser;
        const data = {
          ...result.data,
          ...makeCellObject(individualUser.cell),
          individualUser: {
            birthDt: result.data?.individualUser?.birthDt?.replaceAll('-', '')
          }
        };
        reset(data);
        setPrevCellNotiAgreeDate(result.data.cellphNotificationAgreeDateTime);
        setPrevEmailNotiAgreeDate(result.data.emailNotificationAgreeDateTime);

        // 주민등록번호 등록 여부
        if (individualUser.realNameNumberRegisterDateTime) {
          setRealNumberRegisted(true);
        }

        // 공동인증서 등록 여부
        if (
          individualUser.authenticationMethods.includes(
            AuthenticationMethod.CO_CERTIFICATE.code
          )
        ) {
          setCertRegisted(true);
        }
        // PASS 등록 여부
        if (
          individualUser.authenticationMethods.includes(
            AuthenticationMethod.PASS_APP.code
          )
        ) {
          setPassRegisted(true);
        }

        // Sms 수신 동의 여부
        if (result.data.cellphNotificationAgreeDateTime) {
          setCheckedSms(true);
        }
        // Email 수신 동의 여부
        if (result.data.emailNotificationAgreeDateTime) {
          setCheckedEmail(true);
        }
      } else {
        const errMessage = !!result.error?.error
          ? result.error?.error
          : result.error?.user_message;
        openModal('에러', errMessage);
      }
    };
    fetchFunc();
  }, []);
  const onUpdate = (signData, payload) => {
    const data = {
      signData,
      individualUser: {
        authenticationMethod: '1'
      }
    };
    dispatch(fetchPutUsersIndividualAction(data)).then((res) => {
      if (res.meta.status === 201) {
        openModal('확인', '변경되었습니다');
      } else {
        if (res.error) {
          openModal('확인', res.error.user_message);
        } else {
          openModal('확인', '변경에 실패했습니다');
        }
      }
    });
  };

  const openSignModal = (handler, payload) => {
    const { userName, individualUser, frontCell, middleCell, endCell } =
      payload;
    setTimeout(() => {
      const data = {
        userName,
        cellphNotificationAgreeDateTime: !checkedSms
          ? null
          : prevCellNotiAgreeDate
          ? prevCellNotiAgreeDate
          : getCurrentDatetime(),
        emailNotificationAgreeDateTime: !checkedEmail
          ? null
          : prevEmailNotiAgreeDate
          ? prevEmailNotiAgreeDate
          : getCurrentDatetime(),
        marketingAgreeDateTime: null,
        individualUser: {
          emailAddress: individualUser.emailAddress,
          birthDt: individualUser.birthDt,
          cell: makeCellString([frontCell, middleCell, endCell]),
          authenticationMethod: AuthenticationMethod.CO_CERTIFICATE.code
        }
      };

      idpwsign(SIGN_TYPE.PERSON, handler, data);
    }, 0);
  };

  const navigateCertRealNumber = () => {
    history.push('/shareholder/my-agms');
  };

  const coCertHandler = (signData) => {
    const data = {
      authenticationMethod: AuthenticationMethod.CO_CERTIFICATE.code,
      signData: signData,
      identify: realNumberRegisted
    };
    dispatch(fetchPostUsersIndividualAuthenticationMethodAction(data)).then(
      (res) => {
        if (!res.error) {
          setCertRegisted(true);
        } else {
          openModal('에러', res.error.user_message);
        }
      }
    );
  };

  const regCoCertificate = () => {
    idpwsign(SIGN_TYPE.PERSON, coCertHandler);
  };

  const urlCode = process.env.REACT_APP_KMC_URL_CODE;
  const callbackDomain = process.env.REACT_APP_KMC_CALLBACK_DOMAIN;
  const openPassPopup = () => {
    const certNum = uuidv4();
    const popupWidth = 400;
    const popupHeight = 645;
    popupCenter('', 'KMCISWindow', popupWidth, popupHeight);
    dispatch(fetchGetAuthPassCertAction({ certNum, urlCode })).then((res) => {
      const tr_cert = res.data.tr_cert;
      //const tr_url = res.data.tr_url;

      //popupCenter('', 'KMCISWindow', popupWidth, popupHeight);

      var form = document.createElement('form');
      form.setAttribute('method', 'POST'); //Post 방식
      form.setAttribute('target', 'KMCISWindow'); //Post 방식
      form.setAttribute(
        'action',
        'https://www.kmcert.com/kmcis/web/kmcisReq.jsp'
      ); //요청 보낼 주소

      var hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', 'tr_url');
      //hiddenField.setAttribute('value', tr_url);
      hiddenField.setAttribute(
        'value',
        `${callbackDomain}/pass/signup/callback`
      );

      form.appendChild(hiddenField);

      hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', 'tr_cert');
      hiddenField.setAttribute('value', tr_cert);
      form.appendChild(hiddenField);

      document.body.appendChild(form);
      console.log(form);
      form.submit();
    });
  };

  function popupCenter(url, title, w, h) {
    let dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
    let dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screen.top;
    let width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    let height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;
    var left = width / 2 - w / 2 + dualScreenLeft;
    var top = height / 2 - h / 2 + dualScreenTop;
    var newWindow = window.open(
      url,
      title,
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
        w +
        ', height=' +
        h +
        ', top=' +
        top +
        ', left=' +
        left
    );
    if (newWindow) {
      newWindow.focus();
      window.addEventListener('message', eventHandler, false);
    }
  }

  const eventHandler = (event) => {
    if (event.data) {
      window.removeEventListener('message', eventHandler);
      console.log(event);
      const popupData = JSON.parse(event.data);
      console.log('popupData = ');
      console.log(popupData);
      if (popupData) {
        if (popupData.error) {
          openModal('확인', popupData.meta.data.user_message);
        } else {
          // 로그인 처리
          handleSubmit((data) => onPassSignup(data, popupData.certNum))();
        }
      }
    }
  };

  const onPassSignup = (data, certNum) => {
    const payload = {
      authenticationMethod: AuthenticationMethod.PASS_APP.code,
      passAuthorizationNumber: certNum,
      identify: realNumberRegisted
    };
    dispatch(fetchPostUsersIndividualAuthenticationMethodAction(payload)).then(
      (res) => {
        if (!res.error) {
          setPassRegisted(true);
        } else {
          openModal('에러', res.error.user_message);
        }
      }
    );
  };

  return (
    <Page>
      <PageHeader title="내 정보 수정" />
      <Section>
        {isMobile ? (
          <>
            <ListItem>
              <InputBox title="이름" isRequired isMobile={isMobile}>
                <Input
                  register={register}
                  name="userName"
                  size={InputSize.MEDIUM}
                  short={isMobile}
                />
              </InputBox>
            </ListItem>
            <br />
            <ListItem>
              <InputBox
                title="생년월일"
                isRequired
                isMobile={isMobile}
                error={errors.individualUser?.birthDt?.message}
                errorType={ErrorTypes.ERROR}
              >
                <Input
                  register={register}
                  name="individualUser.birthDt"
                  registerOption={Validation.BIRTHDAY}
                  size={InputSize.MEDIUM}
                  placeholder="(ex)19940401"
                  short={isMobile}
                />
              </InputBox>
            </ListItem>
          </>
        ) : (
          <ListItem>
            <InputBox title="이름" isRequired isMobile={isMobile}>
              <Input
                register={register}
                name="userName"
                size={InputSize.MEDIUM}
              />
            </InputBox>
            <InputBox
              title="생년월일"
              isRequired
              isMobile={isMobile}
              error={errors.individualUser?.birthDt?.message}
              errorType={ErrorTypes.ERROR}
            >
              <Input
                register={register}
                name="individualUser.birthDt"
                registerOption={Validation.BIRTHDAY}
                size={InputSize.MEDIUM}
                placeholder="(ex)19940401"
              />
            </InputBox>
          </ListItem>
        )}
        <ListItem>
          <InputBox
            title="주민등록번호"
            isOptional
            error={[
              '온라인 주주총회에서 전자투표 · 전자의결권 이용 시 주주 여부 확인을 위해 주민등록번호가 필요합니다. (외국인인 경우, 외국인등록번호 입력)',
              '1년 동안 온라인 주주총회 미참석 시등록된 주민등록번호는 자동 삭제됩니다.'
            ]}
            short={isMobile}
          >
            <Label
              defaultMessage="주민등록번호 미등록"
              okMessage="주민등록번호 등록 완료"
              isOk={realNumberRegisted}
            />
            {!realNumberRegisted && (
              <Button
                type={ButtonTypes.Primary}
                onClick={navigateCertRealNumber}
              >
                주민등록번호 등록
              </Button>
            )}
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox title="휴대전화" isRequired isMobile={isMobile}>
            <PhoneInput register={register} name="individualUser.cell" />
          </InputBox>
          <InputBox />
        </ListItem>
      </Section>
      <Section>
        <ListItem>
          <InputBox
            title="인증수단 선택"
            isRequired
            isVertical
            isMobile={isMobile}
          >
            <>
              <Label
                defaultMessage="공동인증서 미등록"
                okMessage="공동인증서 등록 완료"
                isOk={certRegisted}
              />
              {!certRegisted && (
                <Button type={ButtonTypes.Primary} onClick={regCoCertificate}>
                  공동인증서 등록
                </Button>
              )}
            </>

            {!!methods && methods.includes(AuthenticationMethod.PASS_APP.code) && (
              <>
                <Label
                  defaultMessage="통신사(PASS) 인증 미완료"
                  okMessage="통신사(PASS) 인증 완료"
                  isOk={passRegisted}
                />
                {!passRegisted && (
                  <Button type={ButtonTypes.Inert} onClick={openPassPopup}>
                    통신사(PASS) 인증
                  </Button>
                )}
              </>
            )}
            <AgreeBox
              setCheckedEmail={setCheckedEmail}
              setCheckedSms={setCheckedSms}
              checkedSms={checkedSms}
              checkedEmail={checkedEmail}
            />
          </InputBox>
        </ListItem>
      </Section>
      <ButtonGroup isPage>
        <Button
          type={ButtonTypes.Primary}
          size={ButtonSize.Large}
          onClick={handleSubmit((data) => {
            openSignModal(onUpdate, data);
          })}
        >
          수정하기
        </Button>
      </ButtonGroup>
    </Page>
  );
};
export default IndivisualInfo;
