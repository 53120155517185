import Issinc from './issueIncorporated';
import Common from './common';
import Externals from './externals';
import Files from './files';
import Agm from './agm';
import Users from './users';
import Auth from './auth';
import Elections from './elections';
import Terms from './terms';
import Brokerage from './brokerage';
import ElecBill from './elecBill';
const APIs = {
  Common,
  Agm,
  Auth,
  //
  Issinc,
  Files,
  Externals,
  Users,
  Elections,
  Terms,
  Brokerage,
  ElecBill
};

export default APIs;
