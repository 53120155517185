import PropTypes from 'prop-types'; // ES6
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import SpCommonPng from '../../styles/images/common/sprite/sp_common.png';
import { DateFormatName, getDateFormat } from '../../utils/DateFormatUtil';
import { checkInvalidDate, DATE_FORMAT } from '../../utils/DateUtil';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';
import Calendar from '../calendar/Calendar';

const InputWrapperBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
`;
const input_ty = css`
  height: 52px;
  padding: 0 20px;
  border-radius: 8px;
  background-color: #f2f3f6;
  border: solid 1px #f2f3f6;
  box-sizing: border-box;
  line-height: 1.54;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    height: 34px;
    padding: 0 12px;
    border-radius: 4px;
    background-color: #fff;
    border: solid 1px #a5b7ce;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    margin-right: 4px;
  }
`;

const input_ty_type_date = css`
  max-width: 150px;
  padding-right: 0;
  margin-right: 0;
  border-radius: 8px 0 0 8px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding-right: 0;
    margin-right: 0;
    font-size: 12px;
    border-radius: 4px 0 0 4px;
  }
`;

const InputBlock = styled.input`
  flex: 1;
  margin-right: 4px;
  //
  ${input_ty}
  ${input_ty_type_date}

  &:hover {
    background-color: #fff;
    border: solid 1px #8c939c;
  }
  ${(props) =>
    props.flag &&
    css`
      background-color: #fff;
      border: solid 1px #8c939c;
    `}
  &:focus {
    background-color: #fff;
    border: solid 1px #8c939c;
  }

  ${(props) => {
    props.isComplete &&
      // .input_ty.fc_ty01
      css`
        color: #24995a;
        background-color: rgba(36, 153, 90, 0.08);
      `;
  }}
  ${(props) => {
    props.isError &&
      // .input_ty.fc_ty02
      css`
        color: #ff4040;
        background-color: rgba(255, 64, 64, 0.08);
      `;
  }}
`;

const ButtonBlock = styled.button`
  // .btn_i05
  position: relative;
  padding: 16px 0 15px;
  height: 52px;
  min-width: 56px;
  background-color: #f2f3f6;
  margin-left: -4px;
  margin-right: 4px;
  border-radius: 0 8px 8px 0;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    height: 34px;
    position: relative;
    padding: 9px 0;
    min-width: 30px;
    margin-left: -2px;
    margin-right: 2px;
    border: solid 1px #ccd5e0;
    border-left: 0;
    background-color: #fff;
    border-radius: 0 4px 4px 0;

    flex-basis: auto;
    flex-shrink: 0;
  }

  // .btn_i05::before
  &::before {
    content: '';
    position: absolute;
    top: 40%;
    left: 12px;
    display: block;
    margin-top: -9.5px;
    background: url(${SpCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -262px -21px;
    width: 28px;
    height: 28px;

    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      top: 50%;
      left: 5px;
      background-size: 230px auto;
      background-position: -151px -13px;
      width: 18px;
      height: 18px;
    }
  }
`;

const DateInput = (props) => {
  const { defaultValue, name, callback } = props;
  const [dateResult, setDateResult] = useState();
  const [calendarFlag, setCalendarFlag] = useState(false);
  const btnRef = useRef();

  useEffect(() => {
    !checkInvalidDate(defaultValue) && setDateResult(defaultValue);
  }, [defaultValue]);

  const updateCalendarFlag = () => {
    setCalendarFlag(!calendarFlag);
  };

  const setDate = (data) => {
    setDateResult(data);
    setCalendarFlag(!calendarFlag);
    typeof callback === 'function' && callback(data, name);
  };

  return (
    <InputWrapperBlock>
      <InputBlock
        value={
          checkInvalidDate(dateResult)
            ? ''
            : DATE_FORMAT(
                dateResult,
                getDateFormat(DateFormatName.SHORT_DATE_HYPEN)
              )
        }
        flag={calendarFlag}
        onClick={() => btnRef.current.click()}
        readOnly
      />
      <ButtonBlock onClick={updateCalendarFlag} ref={btnRef} />
      {calendarFlag && (
        <Calendar
          setDate={setDate}
          selDate={dateResult}
          setCalendarFlag={setCalendarFlag}
        />
      )}
    </InputWrapperBlock>
  );
};

DateInput.propTypes = {
  callback: PropTypes.func,
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

DateInput.defaultProps = {
  placeholder: ''
};

export default DateInput;
