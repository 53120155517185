import { createPromiseAction } from '@adobe/redux-saga-promise';

/**
 * 주총총회 개설
 */

// 주총개설 - 기본정보 입력(Page1)
export const fetchPostAgmBaseInfoAction = createPromiseAction(
  'FETCH_POST_AGM_BASE_INFO'
);
// 주총개설 - 기본정보 수정
export const fetchPutAgmBaseInfoAction = createPromiseAction(
  'FETCH_PUT_AGM_BASE_INFO'
);
// 주총개설 - 기본정보 조회(Page1)
export const fetchGetAgmBaseInfoAction = createPromiseAction(
  'FETCH_GET_AGM_BASE_INFO'
);
// 주주총회 파일 조회
export const fetchGetFilesAgmAction = createPromiseAction(
  'FETCH_GET_FILES_AGM'
);
/**
 * 안건
 */
// 주총개설 - 안건등록(Page2)
export const fetchPostAgmAgendaAction = createPromiseAction(
  'FETCH_POST_AGM_AGENDA'
);

// 주총개설 - 안건수정(Page2)
export const fecthPutAgmAgendaAction = createPromiseAction(
  'FETCH_PUT_AGM_AGENDA'
);

// 주총개설 - 안건삭제(Page2)
export const fetchDeleteAgmAgendaAction = createPromiseAction(
  'FETCH_DELETE_AGM_AGENDA'
);
// 주총개설 - 안건 목록 조회(전체)
export const fetchGetAgmAgendasAction = createPromiseAction(
  'FETCH_GET_AGM_AGENDAS'
);
// 주총개설 - 안건 목록 조회(부모만)
export const fetchGetAgmAgendasParentOnlyAction = createPromiseAction(
  'FETCH_GET_AGM_AGENDAS_PARENT_ONLY'
);
// 주총개설 - 안건 목록 조회(투표대상만)
export const fetchGetAgmAgendasVoteableAction = createPromiseAction(
  'FETCH_GET_AGM_AGENDAS_VOTEABLE'
);
// 주총개설 - 안건 조회
export const fetchGetAgmAgendasByAgendaSeqnoAction = createPromiseAction(
  'FETCH_GET_AGM_AGENDAS_BY_AGENDA_SEQNO'
);

/**
 * 주주명부
 */
// 주총개설 - 명부업로드(Page2)
export const fetchPostAgmStockholdersAction = createPromiseAction(
  'FETCH_POST_AGM_STOCKHOLDERS'
);

// 주총개설 - 실패명부다운로드(Page2)
export const fetchGetAgmStockholdersFailExcelAction = createPromiseAction(
  'FETCH_GET_AGM_STOCKHOLDERS_FAIL_EXCEL'
);

// 주총개설 - 명부 전체 조회
export const fetchGetAgmStockholdersAction = createPromiseAction(
  'FETCH_GET_AGM_STOCKHOLDERS'
);

// 주총개설 - 명부 검색(이름)
export const fetchGetAgmStockholdersSearchAction = createPromiseAction(
  'FETCH_GET_AGM_STOCKHOLDERS_SEARCH'
);

// 주총개설 - 주주 조회
export const fetchGetAgmStockholderAction = createPromiseAction(
  'FETCH_GET_AGM_STOCKHOLDER'
);

/**
 * 전자고지서
 */
// 타입별 전자고지서 목록
export const fetchGetENoticeAgmTypeAction = createPromiseAction(
  'FETCH_GET_E_NOTICE_AGM_TYPE'
);
// 타입별 전자고지서 상세 목록
export const fetchGetENoticeAgmTypeDetailAction = createPromiseAction(
  'FETCH_GET_E_NOTICE_AGM_TYPE_DETAIL'
);
// 전자고지서 수정화면
export const fetchGetENoticeAgmSendAction = createPromiseAction(
  'FETCH_GET_E_NOTICE_AGM_SEND'
);
// 전자고지서 등록
export const fetchPostENoticeAgmAction = createPromiseAction(
  'FETCH_POST_E_NOTICE_AGM'
);
// 전자고지서 수정
export const fetchPutENoticeAgmSendAction = createPromiseAction(
  'FETCH_PUT_E_NOTICE_AGM_SEND'
);
// 전자고지서 삭제
export const fetchDeleteENoticeAgmSendAction = createPromiseAction(
  'FETCH_DELETE_E_NOTICE_AGM_SEND'
);
// 전자고지서 템플릿 조회
export const fetchGetENoticeTemplatesAgmTypeAction = createPromiseAction(
  'FETCH_GET_E_NOTICE_TEMPLATES_AGM_TYPE'
);
// 전자고지서 문서 조회 - 주주
export const fetchGetENoticeAction = createPromiseAction('FETCH_GET_E_NOTICE');
// 전자고지서 상세 정보
export const fetchGetENoticeAgmSendDetailAction = createPromiseAction(
  'FETCH_GET_E_NOTICE_AGM_SEND_DETAIL'
);
// 전자고지서 발송 실패자 목록
export const fetchGetENoticeAgmSendFailsAction = createPromiseAction(
  'FETCH_GET_E_NOTICE_AGM_SEND_FAILS'
);
// 전자고지서 발송 실패자 목록 엑셀 다운로드
export const fetchGetENoticeAgmSendFailsExcelDownloadAction =
  createPromiseAction('FETCH_GET_E_NOTICE_AGM_SEND_FAILS_EXCEL_DOWNLOAD');
// 전자고지서 파일 다운로드
export const fetchGetFilesENoticeAgmSendAction = createPromiseAction(
  'FETCH_FILES_E_NOTICE_AGM_SEND'
);

/**
 * 의결권
 */

// 주총개설 - 의결권 제한 등록
export const fetchPostAgmLimitsAction = createPromiseAction(
  'FETCH_POST_AGM_LIMITS'
);
// 주총개설 - 의결권 제한 수정
export const fetchPutAgmLimitsAction = createPromiseAction(
  'FETCH_PUT_AGM_LIMITS'
);
// 주총개설 - 의결권 제한 삭제
export const fetchPutAgmLimitsDeleteAction = createPromiseAction(
  'FETCH_PUT_AGM_LIMITS_DELETE'
);
// 주총개설 - 의결권 제한 조회
export const fetchGetAgmLimitsAction = createPromiseAction(
  'FETCH_GET_AGM_LIMITS'
);
// 주총개설 - 의결권 제한 조회 페이징
export const fetchGetAgmLimitsPageAction = createPromiseAction(
  'FETCH_GET_AGM_LIMITS_PAGE'
);
// 주총개설 - 의결권 조회(단일주주)
export const fetchGetAgmElectionVoterAction = createPromiseAction(
  'FETCH_GET_AGM_ELECTION_VOTER'
);

// 주총개설 - 안건별 의결권 조회(전체)
export const fetchGetAgmAgendaElectionVotersAction = createPromiseAction(
  'FETCH_GET_AGM_AGENDA_ELECTION_VOTERS'
);
// 주총개설 - 의결권 합산
export const fetchGetAgmVotingRightsTotalAction = createPromiseAction(
  'FETCH_GET_AGM_VOTING_RIGHTS_TOTAL'
);
/**
 * 의결권 제한 등록(엑셀)
 */
// 주총개설 - 의결권 제한 샘플 다운로드
export const fetchGetFilesLimitsSampleAction = createPromiseAction(
  'FETCH_GET_FILES_LIMITS_SAMPLE'
);
// 주총개설 - 의결권 제한 등록(엑셀 업로드)
export const fetchPostAgmLimitsFileUploadAction = createPromiseAction(
  'FETCH_POST_AGM_LIMITS_FILE_UPLOAD'
);
// 주총개설 - 의결권 제한 실패 다운로드
export const fetchGetAgmLimitsFailExcelAction = createPromiseAction(
  'FETCH_GET_AGM_LIMITS_FAIL_EXCEL'
);
/**
 * 전자투표권자 지정 (엑셀)
 */
// 주총개설 - 전자투표권자 지정 샘플 다운로드
export const fetchGetFilesElectionVoterSampleAction = createPromiseAction(
  'FETCH_GET_FILES_ELECTION_VOTER_SAMPLE'
);
// 주총개설 - 전자투표권자 지정(엑셀 업로드)
export const fetchPostAgmElectionVoterFileUploadAction = createPromiseAction(
  'FETCH_POST_AGM_ELECTION_VOTER_FILE_UPLOAD'
);
// 주총개설 - 전자투표권자 지정 실패 다운로드
export const fetchGetAgmElectionVoterFailExcelAction = createPromiseAction(
  'FETCH_GET_AGM_ELECTION_VOTER_FAIL_EXCEL'
);

/**
 * 전자투표권자
 */
// 임시 주총 상태일 때 개인정보 조회
export const fetchPostAgmBrowseIndividualAction = createPromiseAction(
  'FETCH_POST_AGM_BROWSE_INDIVIDUAL'
);

// 주총개설 - 전자투표권자 지정
export const fetchPostAgmElectionVoterAction = createPromiseAction(
  'FETCH_POST_AGM_ELECTION_VOTER'
);

// 전자투표권자 지정 (개설 완료 후)
export const fetchPostAgmElectionVoterWithSignAction = createPromiseAction(
  'FETCH_POST_AGM_ELECTION_VOTER_WITH_SIGN'
);

// 의안별 전자투표권자명부 다운로드
export const fetchGetAgmAgendasElectionVotersExcelAction = createPromiseAction(
  'FETCH_GET_AGM_AGENDAS_ELECTION_VOTERS_EXCEL'
);

/**
 * 주주총회 상태 변경
 */

// 주총개설 - 주총개설완료
export const fetchPutAgmCompleteAction = createPromiseAction(
  'FETCH_PUT_AGM_COMPLETE'
);

// 주총개설 - 주총수정시작
export const fetchPutAgmStartModificationAction = createPromiseAction(
  'FETCH_PUT_AGM_START_MODIFICATION'
);

/**
 * 주주총회 조회
 */

// 주주총회 일정 조회-사용자

/**
 * 주주총회 일정 조회 - 사용자
 * fetchGetAgmSchedule
 *
 * @param {string} startDate 주총 시작일
 * @param {string} endDate 주총 종료일
 * @param {number} page 페이지
 */
export const fetchGetAgmScheduleAction = createPromiseAction(
  'FETCH_GET_AGM_SCHEDULE'
);

//주주총회 일정 조회-게스트
export const fetchGetAgmScheduleGuestAction = createPromiseAction(
  'FETCH_GET_AGM_SCHEDULE_GUEST'
);

/**
 * 주주총회 조회 > 주주
 */
// 내 주주총회 목록 조회
export const fetchGetAgmAction = createPromiseAction('FETCH_GET_AGM');

// 주주-> 주주총회 조회
export const fetchGetAgmByAgmSeqnoAction = createPromiseAction(
  'FETCH_GET_AGM_BY_AGM_SEQNO'
);

// 의결권 행사내역 조회
export const fetchGetAgmExcercisedRightsAction = createPromiseAction(
  'FETCH_GET_AGM_EXCERCISED_RIGHTS'
);

// 주주 - 참여 행사확인서 pdf 다운로드
export const fetchGetAgmExcercisedConfirmPdfAction = createPromiseAction(
  'FETCH_GET_AGM_EXCERCISED_CONFIRM_PDF'
);

/**
 * 주주총회 조회 > 발행회사
 */

// 투표 개설 후 개인정보 조회
export const fetchGetAgmBrowseIndividualAction = createPromiseAction(
  'FETCH_GET_AGM_BROWSE_INDIVIDUAL'
);
// 위임장 pdf다운로드
export const fetchGetAgmElectronicProxyPdfAction = createPromiseAction(
  'FETCH_GET_AGM_ELECTRONIC_PROXY_PDF'
);
// 이전 주주총회 목록 조회
export const fetchGetAgmPreviousAction = createPromiseAction(
  'FETCH_GET_AGM_PREVIOUS'
);
export const fetchGetAgmOpenedAction = createPromiseAction(
  'FETCH_GET_AGM_OPENED'
);

// 발행회사 최근 주주총회 조회
export const fetchGetAgmLatestAction = createPromiseAction(
  'FETCH_GET_AGM_LATEST'
);

// 발행회사 주주총회 개요 조회
/**
 * @payload agemSeqno
 */
export const fetchGetAgmOutlineAction = createPromiseAction(
  'FETCH_GET_AGM_OUTLINE'
);

// 의결권 행사내역 조회(전자투표)
export const fetchGetAgmAgendasVotedRightsAction = createPromiseAction(
  'FETCH_GET_AGM_AGENDAS_VOTED_RIGHTS'
);

// 의결권 행사내역 조회(전자위임장)
export const fetchGetAgmAgendasProxyRightsAction = createPromiseAction(
  'FETCH_GET_AGM_AGENDAS_PROXY_RIGHTS'
);

// 의결권 조회
export const fetchGetAgmElectionVoterMineAction = createPromiseAction(
  'FETCH_GET_AGM_ELECTION_VOTER_MINE'
);

// 의결권 불통일 행사내역 조회
export const fetchGetAgmVotingRightsDiversitiesAction = createPromiseAction(
  'FETCH_GET_AGM_VOTING_RIGHTS_DIVERSITIES'
);

// 주총개설 - 단일주주 의결권 제한 조회
export const fetchGetAgmLimitsStockholdersAction = createPromiseAction(
  'FETCH_GET_AGM_LIMITS_STOCKHOLDERS'
);

// 주총개설 - 전자투표권자 의결권 제한 조회
export const fetchGetAgmLimitsStockholdersAllAction = createPromiseAction(
  'FETCH_GET_AGM_LIMITS_STOCKHOLDERS_ALL'
);

// 현황 엑셀 다운로드
export const fetchGetFilesAgmExcelAction = createPromiseAction(
  'FETCH_GET_FILES_AGM_EXCEL'
);

/**
 * 주총 결과
 */
// 주총 결과 - 전자투표 결과
/**
 * @param agmSeqno number
 */
export const fetchGetAgmVoteboxResultAction = createPromiseAction(
  'FETCH_GET_AGM_VOTEBOX_RESULT'
);

// 주총 결과 - 안건별 상세 투표 주주 정보
export const fetchGetAgmVoteboxResultAgendaParticipantsAction =
  createPromiseAction('FETCH_GET_AGM_VOTEBOX_RESULT_AGENDA_PARTICIPANTS');

// 주총 결과 - 전자위임장 결과
export const fetchGetAgmProxyResultAction = createPromiseAction(
  'FETCH_GET_AGM_PROXY_RESULT'
);

// 주총 결과 - 안건별 상세 위임 주주 정보
export const fetchGetAgmProxyResultAgendaParticipantsAction =
  createPromiseAction('FETCH_GET_AGM_PROXY_RESULT_AGENDA_PARTICIPANTS');

// 주총 결과 합산 - 남은 행사 가능 수량 조회
export const fetchGetAgmRemainExercisableStockAction = createPromiseAction(
  'FETCH_GET_AGM_REMAIN_EXERCISABLE_STOCK'
);

// 주총 결과 합산 - 현장 합산 결과 등록
export const fetchPostAgmSpotResultAction = createPromiseAction(
  'FETCH_POST_AGM_SPOT_RESULT'
);

// 주총 결과 합산 - 현장 합산 결과 등록
export const fetchPostAgmTotalResultAction = createPromiseAction(
  'FETCH_POST_AGM_TOTAL_RESULT'
);

// 주총 결과 - 합산 결과 조회
export const fetchGetAgmTotalResultAction = createPromiseAction(
  'FETCH_GET_AGM_TOTAL_RESULT'
);

// 주총 결과 - 합산 결과 엑셀
export const fetchGetAgmTotalResultExcelAction = createPromiseAction(
  'FETCH_GET_AGM_TOTAL_RESULT_EXCEL'
);

// 의안 파일 조회
export const fetchGetFilesAgmAgendaAction = createPromiseAction(
  'FETCH_GET_FILES_AGM_AGENDA'
);
