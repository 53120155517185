import React from 'react';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';

const LayerHeaderBlock = styled.div`
  position: relative;
  padding-bottom: 14px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: -0.5px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: relative;
    padding-bottom: 14px;
    margin-bottom: 14px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.5px;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 1.5px;
    background-color: #e9ecf4;
  }
`;

const LayerHeader = ({ children }) => {
  return <LayerHeaderBlock>{children}</LayerHeaderBlock>;
};

LayerHeader.prototype = {};

LayerHeader.defaultProps = {};

export default LayerHeader;
