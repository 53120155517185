import React from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';

const ListItemBlock = styled.ul`
  display: flex;
  & > li:first-of-type {
    margin-left: 0;
  }
  /* 여기 빼도 되나? 여기 빼야 padding-bottom: 55px 적용됨 */
  &:last-of-type li {
    /* padding-bottom: 0; */
  }
`;

const ListContentItemBlock = styled.li`
  position: relative;
  flex: 1;
  padding-bottom: 55px;
  margin-left: 8%;
  ${(props) =>
    props.heightType === 'short' &&
    css`
      /* padding-bottom: 30px; */
      padding-bottom: 40px;
      margin-left: 6%;
    `}
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding-bottom: 0;
  }
`;

const ListItem = ({ children, heightType }) => {
  return (
    <ListItemBlock>
      {/**
       * TODO 자식이 하나 일 때 처리 재검토
       */}
      {Array.isArray(children) ? (
        children.map((child, idx) => (
          <ListContentItemBlock key={`listItem_${idx}`} heightType={heightType}>
            {child}
          </ListContentItemBlock>
        ))
      ) : (
        <ListContentItemBlock heightType={heightType}>
          {children}
        </ListContentItemBlock>
      )}
    </ListItemBlock>
  );
};

export default ListItem;
