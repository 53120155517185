import { useContext, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import { ModalContext } from '../../../../../context/ModalContext';
import useWindowSize from '../../../../../hooks/useWindowSize';
import spCommonPng from '../../../../../styles/images/common/sprite/sp_common.png';
import spCommonMobilePng from '../../../../../styles/images/common/sprite/sp_common_mobile.png';
import {
  checkUndefinedNum,
  formatNumber
} from '../../../../../utils/StringUtil';
import { ExerciseMethod } from '../../../exerciseRecord/main/BoxInfo';

const DataTableWrapperBlock = styled.div`
  // .tblcommon-01
  display: inline-block;
  width: auto;
  height: 227px;
  overflow-y: auto;
  margin: 20px 0 0 200px;
  border: solid 1.5px #505f7e;
  border-radius: 5px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    display: inline-block;
    width: auto;
    height: 138px;
    overflow-y: auto;
    margin: 8px 0 0 0;
    border: solid 1.5px #505f7e;
    border-radius: 5px;
  }
`;

const TableHeadBlock = styled.thead`
  & > tr {
    &:first-of-type {
      & > th:first-of-type {
        border-left: 0;
      }
    }
    & > th {
      background: #fbfbfc;
      border-top: solid 1px #e9ecf4;
      border-right: solid 1px #e9ecf4;
      box-shadow: 0px 3px 2px rgb(0 0 0 / 3%);
      position: relative;
      padding: 4px 8px 2px;
      height: 46px;
      font-size: 14px;
      text-align: center;
      transform: skew(-0.04deg);
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        background: #fbfbfc;
        border-top: solid 1px #e9ecf4;
        box-shadow: 0px 3px 2px rgb(0 0 0 / 3%);
        position: relative;
        padding: 5px px 4px;
        height: 22px;
        font-size: 12px;
        text-align: center;
        transform: skew(-0.04deg);
      }
    }
  }
`;

const TableRowBlock = styled.tr`
  ${(props) =>
    props.$on &&
    css`
      background: rgba(175, 236, 255, 0.16);
      cursor: pointer;
    `}
  ${(props) =>
    props.disabled &&
    css`
      background: rgba(0, 0, 0, 0.04);
      cursor: default;
    `}
  & > td {
    padding: 3px 8px 0;
    height: 54px;
    font-size: 15px;
    border-bottom: solid 1px #e9ecf4;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    transform: skew(-0.04deg);
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      padding: 4px 8px 3px;
      height: 24px;
      font-size: 13.6px;
      border-bottom: solid 1px #e9ecf4;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      transform: skew(-0.04deg);
    }
    & > em {
      display: inline-block;
      position: relative;
      width: 15px;
      height: 15px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        margin-top: 6px;
      }
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        display: block;
        width: 13px;
        height: 13px;
        background: url(${spCommonPng}) no-repeat;
        background-size: 400px auto;
        ${(props) =>
          props.yes
            ? css`
                background-position: -359px -1px;
              `
            : css`
                background-position: -359px -15px;
              `}
      }
    }
  }
`;

const CheckboxWrapper = styled.div`
  // .checkbox_ty03
  position: relative;
  display: inline-block;
  margin: 10px 0;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: relative;
    display: inline-block;
    margin: 7px 0;
  }
  & > input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
  & > label {
    position: relative;
    display: inline-block;
    padding: 0 0 0 40px;
    min-height: 20px;
    line-height: 1.67;
    letter-spacing: -0.5px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      position: relative;
      display: inline-block;
      padding: 0 0 0 25px;
      width: auto;
      letter-spacing: -0.5px;

      line-height: inherit;
      min-height: auto;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 26px;
      height: 26px;
      background: url(${spCommonPng}) no-repeat;
      background-size: 400px auto;
      background-position: -123px -65px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 17px;
        height: 17px;
        background: url(${spCommonMobilePng}) no-repeat;
        background-size: 230px auto;
        background-position: -74px -38px;
      }
    }
  }
  & > input[type='checkbox']:checked + label::before {
    background-position: -83px -65px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      background-position: -48px -38px;
    }
  }
`;

const VoterNameBlock = styled.button`
  &&& {
    background-color: transparent !important;
    font-weight: 700;
  }
`;

const CertificateDataTable = ({
  voters,
  selectedVoters,
  setSelectedVoters,
  openStockDetailPopup,
  onCleanData,
  isCleanData,
  isSelectNonParticipation
}) => {
  const [checkedList, setCheckedList] = useState([]);
  const { openModal } = useContext(ModalContext);
  const match = useRouteMatch();
  const isVote = match.url.includes('vote');
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => {
    return windowSize.width <= SCREEN_SIZE_MOBILE;
  }, [windowSize]);

  useEffect(() => {
    if (isSelectNonParticipation) {
      const temp = voters.reduce(function (a, e, i) {
        if (!e.excerised) {
          a.push(i);
        }
        return a;
      }, []);
      setCheckedList(temp);
      setSelectedVoters(temp);
    } else {
      const temp = voters.filter((voter) => voter.excerised);
      setCheckedList(temp);
      setSelectedVoters(temp);
    }
  }, [isSelectNonParticipation]);

  const onClickHandler = (index) => {
    if (checkedList.includes(index)) {
      setCheckedList((prev) => {
        const newList = checkedList.filter((target) => target !== index);
        setSelectedVoters(newList);
        return newList;
      });
    } else {
      const votedVoter = selectedVoters.find((voter) => voter.participated);
      const excerisedVoters = voters[index].excerised;
      if (
        !isCleanData &&
        selectedVoters.length !== 0 &&
        (votedVoter || excerisedVoters)
      ) {
        openModal('확인', '이미 투표를 행사한 주주와 함께 지정할 수 없습니다');
        onCleanData(() =>
          setCheckedList((prev) => {
            const newList = [...checkedList, index];
            setSelectedVoters(newList);
            return newList;
          })
        );
        return;
      } else {
        setCheckedList((prev) => {
          const newList = [...checkedList, index];
          setSelectedVoters(newList);
          return newList;
        });
      }
    }
  };

  return (
    <DataTableWrapperBlock>
      <table>
        <colgroup>
          <col width="12.5%;" />
          <col width="*;" />
          <col width="25%;" />
          <col width="12.5%;" />
          <col width="25%;" />
        </colgroup>
        <TableHeadBlock>
          <tr>
            <th>선택</th>
            <th>주주명</th>
            <th>보유주식</th>
            <th>행사여부</th>
            <th>행사방법</th>
          </tr>
        </TableHeadBlock>
        <tbody>
          {voters.map((voter, index) => {
            const isDisabled =
              (isVote && voter.exerciseMethod === ExerciseMethod.PROXY) ||
              (!isVote && voter.exerciseMethod === ExerciseMethod.VOTE);

            return (
              <TableRowBlock
                key={`TableRowBlock-${index}`}
                $on={checkedList.includes(index)}
                yes={voter.excerised}
                disabled={isDisabled}
              >
                <td>
                  {!isDisabled && (
                    <CheckboxWrapper>
                      <input
                        type="checkbox"
                        id={`chk_id${index}`}
                        checked={checkedList.includes(index)}
                      />
                      <label
                        htmlFor={`chk_id${index}`}
                        onClick={() => onClickHandler(index)}
                      >
                        {isMobile && <br />}
                      </label>
                    </CheckboxWrapper>
                  )}
                </td>
                <td>
                  <VoterNameBlock onClick={() => openStockDetailPopup(voter)}>
                    {voter.stockholderName}
                  </VoterNameBlock>
                </td>
                <td>{`${formatNumber(
                  voter.commonStockAmount +
                    checkUndefinedNum(voter.exercisablePreferredStockAmount) +
                    checkUndefinedNum(voter.preferredStockAmount)
                )}주`}</td>
                <td>
                  <em></em>
                </td>
                <td>
                  {voter.exerciseMethod === ExerciseMethod.VOTE
                    ? '전자의결권'
                    : voter.exerciseMethod === ExerciseMethod.PROXY
                    ? '전자위임장'
                    : ''}
                </td>
              </TableRowBlock>
            );
          })}
        </tbody>
      </table>
    </DataTableWrapperBlock>
  );
};

export default CertificateDataTable;
