import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';
import TermsItemTitle from './TermsItemTitle';

const DT = styled.dt`
  padding: 16px 0 15px;
  border-top: 1px solid #f2f3f6;
  position: relative;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding: 13px 0 12px;
  }
`;

const DD = styled.dd`
  display: none;
  padding: 16px 30px;
  height: 140px;
  overflow-y: auto;
  background-color: #fbfcfd;
  border: 1px solid rgba(158, 179, 205, 0.25);
  border-radius: 5px;
  ${(props) =>
    props.active &&
    css`
      display: block;
    `}
  & > span {
    font-size: 13px;
    line-height: 1.65;
    letter-spacing: 1px;
  }
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding: 10px 12px;
    height: 120px;
  }
`;

const TermsItemBlock = styled.div``;

const TermsItem = ({
  inner,
  title,
  type,
  children,
  onCheckTerms,
  checked,
  compulsory
}) => {
  const [active, setActive] = useState(false);

  return (
    <TermsItemBlock>
      <DT>
        <TermsItemTitle
          inner
          title={title}
          type={type}
          active={active}
          onToggle={() => setActive(!active)}
          onCheckTerms={onCheckTerms}
          checked={checked}
          compulsory={compulsory}
        />
      </DT>
      <DD active={active}>
        <span
          dangerouslySetInnerHTML={{
            __html: children
          }}
        />
      </DD>
    </TermsItemBlock>
  );
};

export default TermsItem;
