import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { checkInvalidDate } from '../../utils/DateUtil';
import { STOP_EVENT_FUNCTION } from '../../utils/FunctionUtil';
import Button, { ButtonSize, ButtonTypes } from '../button/Button';

export const TimePickerBlock = styled.div`
  // .timepicker
  width: 240px;
  position: absolute;
  top: 56px;
  z-index: 500;
  & > div > div {
    // .timepicker_body
    padding: 6px;
    border-radius: 12px;
    background: #3985d9;
  }
`;
export const TimePickerBoxBlock = styled.div`
  // .timepicker_box
  border-radius: 5px;
  background: #ffffff;
  padding: 8px 7px 10px 7px;
`;
export const UlBlock = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  & > li {
    flex: 1;
    padding: 0 2px;
    border-radius: 3px;
    font-size: 12px;
    height: 240px;
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
export const BtnBlock = styled.button`
  flex: 1;
  width: 100%;
  margin: 2px 0;
  padding: 6px 0;
  background: #fff;
  border-radius: 3px;
  &:hover {
    background: #d0e6ff;
  }
  ${(props) =>
    props.sel &&
    css`
      background: #0572ef;
      color: #fff;
    `}
`;

const TimeSelect = ({
  isHalfDay,
  minUnit,
  setTime,
  defaultValue,
  setShowTimeBlock
}) => {
  const AM = '오전';
  const PM = '오후';
  const inputEl = useRef();
  const hourRef = useRef();
  const minRef = useRef();
  const timeRef = useRef();
  const [halfDay, setHalfDay] = useState(
    checkInvalidDate(defaultValue)
      ? AM
      : defaultValue.getHours() >= 11
      ? PM
      : AM
  );
  const [hour, setHour] = useState(
    checkInvalidDate(defaultValue)
      ? -1
      : halfDay === PM
      ? defaultValue.getHours() - 12
      : defaultValue.getHours()
  );
  const [min, setMin] = useState(
    checkInvalidDate(defaultValue) ? -1 : defaultValue.getMinutes()
  );
  const [colonFlag, setColonFlag] = useState(false);
  const hourArr = [
    '12',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11'
  ];

  useEffect(() => {
    hourRef.current.scroll({
      top: hour * 29,
      behavior: 'smooth'
    });
    minRef.current.scroll({
      top: min * 30,
      behavior: 'smooth'
    });
  });

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleClickOutside = (event) => {
    if (timeRef && !timeRef.current.contains(event.target)) {
      setShowTimeBlock(false);
    } else {
      setShowTimeBlock(true);
    }
  };

  const onclickMin = useCallback(
    (e) => {
      colonFlag || setColonFlag(true);
      setMin(e.target.value);
    },
    [colonFlag]
  );
  const onclickHour = useCallback(
    (e) => {
      colonFlag || setColonFlag(true);
      setHour(e.target.value);
    },
    [colonFlag]
  );
  const onclickHalfDay = (e) => {
    if (e.target.dataset.config === AM) {
      setHalfDay(AM);
    } else {
      setHalfDay(PM);
    }
  };
  const onclickAMSpan = (e) => {
    STOP_EVENT_FUNCTION(e);
    inputEl.current.click();
  };
  const printHalfDay = () => {
    return (
      <>
        <BtnBlock
          sel={halfDay === AM}
          onClick={onclickHalfDay}
          data-config={AM}
          ref={inputEl}
        >
          <span onClick={onclickAMSpan}>오전</span>
        </BtnBlock>
        <BtnBlock
          sel={halfDay === PM}
          onClick={onclickHalfDay}
          data-config={PM}
        >
          <span>오후</span>
        </BtnBlock>
      </>
    );
  };
  const printHours = () => {
    return hourArr.map((h, index) => {
      const strHours = h;
      return (
        <BtnBlock
          onClick={onclickHour}
          key={`hourKey_${index}`}
          value={strHours}
          sel={
            Number(hour) !== 0 && Number(hour) === index
              ? true
              : (Number(hour) === 12 || Number(hour) === 0) && index === 0
              ? true
              : false
          }
        >
          {strHours}
        </BtnBlock>
      );
    });
  };
  const printFullHours = () => {
    return Array(24)
      .fill()
      .map((_, index) => {
        const strHours = parseInt(index) < 9 ? `0${index}` : index;
        return (
          <BtnBlock
            onClick={onclickHour}
            key={`hourKey_${index}`}
            value={strHours}
            sel={Number(hour) === index ? true : false}
          >
            {strHours}
          </BtnBlock>
        );
      });
  };
  const printMinutes = () => {
    const arrLen = 60 / Number(minUnit);
    return Array(arrLen)
      .fill()
      .map((_, index) => {
        const strMinutes =
          parseInt(index * minUnit) <= 9
            ? `0${index * minUnit}`
            : index * minUnit;
        return (
          <BtnBlock
            onClick={onclickMin}
            key={`minKey_${index}`}
            sel={
              min === '　'
                ? false
                : Number(min) === Number(strMinutes)
                ? true
                : false
            }
            value={strMinutes}
          >
            {strMinutes}
          </BtnBlock>
        );
      });
  };

  const onClickHandler = () => {
    if (halfDay === AM) {
      setTime(new Date(2021, 11, 11, Number(hour) % 12, min));
    } else {
      setTime(new Date(2021, 11, 11, (Number(hour) % 12) + 12, min));
    }
  };

  return (
    <TimePickerBlock ref={timeRef}>
      <div>
        <div>
          <TimePickerBoxBlock>
            <UlBlock>
              {isHalfDay && <li>{printHalfDay()}</li>}
              {isHalfDay && <li ref={hourRef}>{printHours()}</li>}
              {isHalfDay || <li>{printFullHours()}</li>}
              <li ref={minRef}>{printMinutes()}</li>
            </UlBlock>
            <ul
              style={{
                textAlign: 'center',
                borderTop: 'solid 2px #0572ef',
                margin: '5px 0 0 0'
              }}
            >
              <li style={{ marginTop: '10px' }}>
                <Button
                  type={
                    checkInvalidDate(defaultValue)
                      ? ButtonTypes.Basic
                      : ButtonTypes.Primary
                  }
                  size={ButtonSize.Table}
                  onClick={onClickHandler}
                >
                  확인
                </Button>
              </li>
            </ul>
          </TimePickerBoxBlock>
        </div>
      </div>
    </TimePickerBlock>
  );
};

export default TimeSelect;
