import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import Alert, { AlertTypes } from '../../../../../components/alert/Alert';
import Spinner from '../../../../../components/alert/Spinner';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../../components/button/Button';
import FileButton from '../../../../../components/button/FileButton';
import DataTable from '../../../../../components/datatable/DataTable';
import Input, { ErrorTypes } from '../../../../../components/input/Input';
import InputBox from '../../../../../components/input/InputBox';
import Select from '../../../../../components/input/Select';
import { MODAL_SIZE } from '../../../../../components/modal/Modal';
import { TableDataType } from '../../../../../components/table/Table';
import TableTitle from '../../../../../components/table/TableTitle';
import { ModalContext } from '../../../../../context/ModalContext';
import { useFileDownload } from '../../../../../hooks/useFileDownload';
import {
  fetchGetAgmStockholdersFailExcelAction,
  fetchGetAgmVotingRightsTotalAction,
  fetchPostAgmStockholdersAction
} from '../../../../../services/api/agm/promiseActions';
import { fetchGetFilesShareholderSampleAction } from '../../../../../services/api/files/promiseActions';
import { Icons } from '../../../../../styles/Icons';
import spriteCommonPng from '../../../../../styles/images/common/sprite/sp_common.png';
import { AgentOrganizationType } from '../../../../../utils/constant/AgmConstant';
import { EMPTY_FUNCTION } from '../../../../../utils/FunctionUtil';
import { formatNumber } from '../../../../../utils/StringUtil';
import { OkBtn } from '../../../../shareholder/vote/main/components/popup/common/PopupButton';

const UnitBlock = styled.ul`
  // .unit
  flex: 1;
  margin-left: 5%;
  li {
    padding-bottom: 55px;
    &:last-of-type {
      padding-bottom: 0;
    }
  }
  ${(props) =>
    props.index === 'first' &&
    css`
      flex: none;
      flex-basis: 340px;
      flex-shrink: 0;
      padding-bottom: 0;
      padding-right: 5%;
      margin-left: 0;
      border-right: 1px solid #e9ecf4;
    `}
  ${(props) =>
    props.index === 'last' &&
    css`
      flex: none;
      min-width: 240px;
      text-align: center;
    `}
`;
const CirclesBlock = styled.div`
  // .circles
  height: 167px;
  & > div {
    // .circle
    width: 124px;
    margin: 17px 6px 7px;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 1.2;
    &::before {
      content: '';
      position: absolute;
      top: 38px;
      left: 43px;
      display: block;
      width: 37px;
      height: 47px;
      background: url(${spriteCommonPng}) no-repeat;
      background-size: 400px auto;
      background-position: -302px -39px;
      & > canvas {
        width: 124;
        height: 124;
        vertical-align: top;
        aspect-ratio: auto 124 / 124;
      }
    }
  }
  & > span {
    display: block;
    font-size: 13px;
  }
`;

const StockholdersRegister = (props) => {
  const { agmSeqno, setFileUploadCplt, agmInfo } = props;
  let { openModal, setModalConfig, closeModal } = useContext(ModalContext);
  const [uploadTime] = useState();
  const [uploadType, setUploadType] = useState(AlertTypes.Info);
  const [stateMsg, setStateMsg] = useState('명부 업로드가 필요합니다');
  const {
    register,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors }
  } = useForm({ mode: 'all' });
  const circleEl = useRef();
  const dispatch = useDispatch();
  const [fileClear, setFileClear] = useState(false);
  const watchAgentOrganizationType = watch('agentOrganizationType');
  // const circleProgress = () => {
  //   const circle = circleEl.current;
  //   console.info('circ : ' + circle);
  //   circle
  //     .circleProgress({
  //       value: 0.0, // value 0.0 ~ 1.0
  //       size: 124,
  //       emptyFill: 'rgba(0, 0, 0, .03)',
  //       fill: { gradient: ['#fff', '#0572ef'] },
  //     })
  //     .on('circle-animation-progress', function (event, progress) {
  //       this.find('strong').html(Math.round(20 * progress) + '<i>%</i>');
  //     });
  // };

  useEffect(() => {
    !!agmInfo &&
      setValue('agentOrganizationType', agmInfo.agentOrganizationType);
    dispatch(fetchGetAgmVotingRightsTotalAction({ agmSeqno })).then((res) => {
      if (!res.error && !!res.data) {
        const data = res.data;
        setUploadType(AlertTypes.Success);
        setStateMsg('명부 업로드 완료');
        setFileUploadCplt(true);
        setValue(
          'totalStockAmount',
          data.totalCommonStockAmount +
            data.totalPrefferedStockAmount +
            data.totalExercisablePreferredStockAmount
        );
        setValue(
          'successStockAmmount',
          data.totalCommonStockAmount +
            data.totalPrefferedStockAmount +
            data.totalExercisablePreferredStockAmount
        );
      }
    });
  }, []);

  useEffect(() => {
    if (fileClear) {
      setFileClear((prev) => !prev);
    }
  }, [fileClear]);

  const fileUpload = (type, file) => {
    if (!getValues('totalStockAmount')) {
      // setErrorMsg('총 발행주식수를 입력해주세요');
      setModalConfig({ size: MODAL_SIZE.SHORT });
      openModal('실패', '총 발행주식수를 입력해주세요');
      setFileClear(true);
    } else {
      setModalConfig({
        buttons: []
      });
      openModal(
        '주주명부 업로드',
        <div style={{ height: '200px' }}>
          <Spinner />
        </div>,
        EMPTY_FUNCTION,
        false,
        EMPTY_FUNCTION,
        true
      );
      const data = new FormData();
      data.append('securitiesCorpSeqno', 1);
      data.append('agmSeqno', agmSeqno);
      data.append('agentOrganizationType', getValues('agentOrganizationType'));
      data.append('totalStockAmount', getValues('totalStockAmount'));
      data.append('file', file);
      dispatch(fetchPostAgmStockholdersAction({ agmSeqno, data })).then(
        (res) => {
          if (!res.error && res.data?.success) {
            setModalConfig({
              buttons: [<OkBtn title="확인" onClick={closeModal} />]
            });
            openModal('확인', '명부 업로드가 정상적으로 처리되었습니다');
            reset({
              successStockAmmount: res.data.successStockAmmount
            });
            setUploadType(AlertTypes.Success);
            setStateMsg('명부 업로드 성공');
            setFileUploadCplt(true);
          } else if (res.error?.status === 500) {
            setFileClear(true);

            setUploadType(AlertTypes.Error);
            setStateMsg('명부 업로드 실패');

            setModalConfig({ size: MODAL_SIZE.SHORT });
            openModal(
              '명부 업로드 실패',
              <>
                <p>확인할 수 없는 명부입니다.</p>
                <p>명부를 다시 확인 후 업로드 해주세요.</p>
              </>
            );
          } else if (res.data?.errors?.length === 0) {
            setFileClear(true);

            setUploadType(AlertTypes.Error);
            setStateMsg('명부 업로드 실패');

            setModalConfig({ size: MODAL_SIZE.SHORT });
            openModal(
              '실패',
              <>
                <p>총 발행주식수와 업로드 된 발행주식 수가 다릅니다.</p>
                <p>
                  업로드 된 발행주식 수 :
                  {formatNumber(res.data.successStockAmmount)}주
                </p>
              </>
            );
          } else {
            setFileClear(true);

            setUploadType(AlertTypes.Error);
            setStateMsg('명부 업로드 실패');

            setModalConfig({
              size: MODAL_SIZE.MEDIUM,
              buttons: [
                <Button
                  type={ButtonTypes.Basic}
                  size={ButtonSize.Popup}
                  onClick={closeModal}
                >
                  확인
                </Button>,
                <Button
                  type={ButtonTypes.Primary}
                  size={ButtonSize.Popup}
                  endIcon={Icons.paper_download}
                  onClick={(e) => uploadErrorFileDownload(res.data.file)}
                >
                  내역 저장
                </Button>
              ]
            });
            openModal('주주명부 업로드 오류', () => {
              return (
                <>
                  <TableTitle small>오류 내역</TableTitle>
                  <DataTable
                    columns={columns}
                    dataSource={getDataSource(res?.data?.errors)}
                    pageConfig={{
                      currentPage: 1,
                      totalPage: 2,
                      onclick: (page) => console.log(page)
                    }}
                  />
                </>
              );
            });
          }
        }
      );
    }
  };

  const { callDownloadFetchAPI } = useFileDownload();
  const uploadErrorFileDownload = (file) => {
    download(file).then((res) => {
      closeModal();
    });
  };

  const download = async (file) => {
    const res = await callDownloadFetchAPI(
      file,
      fetchGetAgmStockholdersFailExcelAction,
      agmSeqno
    );
    return res;
  };

  const columns = [
    {
      title: '번호',
      key: 'index',
      type: TableDataType.NUMBER,
      col: '15%'
    },
    {
      title: '오류행',
      key: 'row',
      type: TableDataType.NUMBER,
      col: '20%'
    },
    {
      title: '내용',
      key: 'desc',
      col: '*',
      align: 'left'
    }
  ];
  const getDataSource = (errors) => {
    if (!errors) {
      return [];
    }
    let newArr = [];
    for (let i = 0; i < errors.length; i++) {
      newArr.push({
        index: i + 1,
        row: errors[i].row_no,
        desc: errors[i].err_cont
      });
    }
    return newArr;
  };

  const { callDownloadFetchAPIOnlyAgmseqno } = useFileDownload();

  const onSampleDownload = () => {
    //샘플파일 다운로드
    callDownloadFetchAPIOnlyAgmseqno(
      null,
      fetchGetFilesShareholderSampleAction
    );
  };

  return (
    <>
      <UnitBlock index="first">
        <li>
          <InputBox
            title="총 발행주식수"
            isRequired
            error={errors.totalStockAmount?.message}
            errorType={ErrorTypes.ERROR}
          >
            <Input
              register={register}
              name="totalStockAmount"
              registerOption={{
                required: {
                  value: true,
                  message: '총 발행주식수는 필수 입력 항목입니다.'
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: '숫자만 입력 가능합니다.'
                }
              }}
            />
          </InputBox>
        </li>
        <li>
          <InputBox
            title="명의개서 대행기관"
            isRequired
            error={errors.agentOrganizationType?.message}
          >
            <Select
              register={register}
              name="agentOrganizationType"
              registerOption={{
                validate: (value) =>
                  parseInt(value) > 0 || '명의개서 대행기관을 선택해주세요'
              }}
              noDefault
            >
              {Object.values(AgentOrganizationType).map((item) => {
                return <option value={item.code}>{item.name}</option>;
              })}
            </Select>
            {watchAgentOrganizationType ===
              AgentOrganizationType.DIRECT_MANAGEMENT.code && (
              <Button
                type={ButtonTypes.Basic}
                size={ButtonSize.Default}
                endIcon={Icons.paper_download}
                onClick={onSampleDownload}
              >
                샘플
              </Button>
            )}
          </InputBox>
        </li>
      </UnitBlock>
      <UnitBlock>
        <li>
          <InputBox title="업로드 된 총 발행주식 수">
            <Input
              register={register}
              name="successStockAmmount"
              readOnly={true}
            />
          </InputBox>
        </li>
        <li>
          <InputBox title="업로드 상태">
            <Alert type={uploadType} message={stateMsg} />
          </InputBox>
        </li>
      </UnitBlock>
      <UnitBlock index="last">
        <li>
          <CirclesBlock>
            <div ref={circleEl}>
              <canvas width="124" height="124" />
            </div>
            {uploadTime && <span>{uploadTime}</span>}
          </CirclesBlock>
          <FileButton
            type={ButtonTypes.Defaults}
            size={ButtonSize.Table}
            handleFile={fileUpload}
            register={register}
            extension={['xls', 'xlsx', 'csv']}
            clearFile={fileClear}
          >
            주주명부 업로드
          </FileButton>
        </li>
      </UnitBlock>
    </>
  );
};

export default StockholdersRegister;
