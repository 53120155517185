import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { idpwsign, SIGN_TYPE } from '../../../components/auth/SignIframe';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import Input, { ErrorTypes } from '../../../components/input/Input';
import InputBox from '../../../components/input/InputBox';
import PhoneInput from '../../../components/input/PhoneInput';
import ListItem from '../../../components/layout/ListItem';
import Section from '../../../components/layout/Section';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import { ModalContext } from '../../../context/ModalContext';
import { fetchPutAuthCertificateIndividualAction } from '../../../services/api/auth/promiseActions';
import { getYMDSplit } from '../../../utils/DateUtil';
import Validation from '../../../utils/ValidationUtil';

const CertificateRegisterPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const dispatch = useDispatch();
  const { openModal, closeModal } = useContext(ModalContext);

  const openSignModal = (handler, payload) => {
    setTimeout(() => {
      idpwsign(SIGN_TYPE.PERSON, handler, payload);
    }, 0);
  };

  const certificate = (signData) => {
    dispatch(fetchPutAuthCertificateIndividualAction({ signData })).then(
      (res) => {
        closeModal();
        if (!res.error && res.data.success) {
          openModal('공동인증서 (재)등록', '공동인증서가 (재)등록 되었습니다.');
        } else {
          openModal('공동인증서 (재)등록', res.error.user_message);
        }
      }
    );
  };

  const getErrorMsg = () => {
    if (!!errors.frontCell?.message) {
      return errors.frontCell?.message;
    } else if (!!errors.middleCell?.message) {
      return errors.middleCell?.message;
    } else if (!!errors.endCell?.message) {
      return errors.endCell?.message;
    } else {
      return '';
    }
  };

  return (
    <Page>
      <PageHeader title="공동인증서 (재)등록" />
      <Section>
        <div style={{ maxWidth: '500px', margin: '0 auto' }}>
          <ListItem>
            <InputBox
              title="이름"
              isRequired
              error={errors.userName?.message}
              errorType={ErrorTypes.ERROR}
            >
              <Input
                register={register}
                name="userName"
                placeholder="홍길동"
                registerOption={Validation.NAME}
              />
            </InputBox>
          </ListItem>
          <ListItem>
            <InputBox
              title="생년월일"
              isRequired
              error={errors.birthDt?.message}
              errorType={ErrorTypes.ERROR}
            >
              <Input
                register={register}
                name="birthDt"
                placeholder="19940401"
                registerOption={Validation.BIRTHDAY}
              />
            </InputBox>
          </ListItem>
          <ListItem>
            <InputBox
              title="휴대전화번호"
              isRequired
              error={getErrorMsg()}
              errorType={ErrorTypes.ERROR}
            >
              <PhoneInput register={register} />
            </InputBox>
          </ListItem>
          <ButtonGroup>
            <Button
              type={ButtonTypes.Primary}
              size={ButtonSize.Medium}
              onClick={() =>
                handleSubmit((data) => {
                  const [y, m, d] = getYMDSplit(data.birthDt); // 생년월일
                  const inputData = {
                    name: data.userName, //  이름
                    cell: `${data.frontCell}${data.middleCell}${data.endCell}`, // 전화번호
                    birthDt: `${y}-${m}-${d}`
                  };
                  openSignModal(certificate, inputData);
                })()
              }
            >
              공동인증서 (재)등록
            </Button>
          </ButtonGroup>
        </div>
      </Section>
    </Page>
  );
};

export default CertificateRegisterPage;
