import React from 'react';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';

const VoteAgendaContainerBlock = styled.li`
  // .boxlist_ty > ul > li
  margin: 16px 0;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin: 12px 0;
  }
`;

const VoteAgendaBoxBlock = styled.ul`
  // .boxlist_ty .unit
  position: relative;
  display: block;
  padding: 50px 100px 36px;
  background-color: #fff;
  border: solid 1px #d8e1eb;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 12%);

  /* 이거 맞나 */
  text-align: -webkit-center;

  // .boxlist_ty .unit li
  & > li {
    width: 100%;
    font-size: 15px;
    letter-spacing: -0.5px;
    text-align: center;
  }
`;

const VoteAgendaBox = ({ children }) => {
  return (
    <VoteAgendaContainerBlock>
      <VoteAgendaBoxBlock>{children}</VoteAgendaBoxBlock>
    </VoteAgendaContainerBlock>
  );
};

export default VoteAgendaBox;
