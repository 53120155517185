const ROOT_URL = '/';
const COMMON_URLS = [
  '/',
  '/about',
  '/shareholder-guide',
  '/issue-incorporated-guide',
  '/shareholder/agm-schedules',
  '/certificate',
  '/certificate-register',
  '/notice/:noticeSeqno?',
  '/faq',
  '/qna',
  '/login',
  '/signup',
  '/signup/individual',
  '/signup/corporate',
  '/corp/contract',
  '/privacy/:prevDate?',
  '/pass/login/callback',
  '/pass/signup/callback',
  '/pass/cert',
  '/users/me',
  '/doc'
];

export const isCommonUrl = (path) => {
  const target = checkProcess(path);
  if (COMMON_URLS.includes(target)) {
    return true;
  } else {
    return findIncludesUrl(path);
  }
};

const checkProcess = (path) => {
  if (path === ROOT_URL) return path;
  else if (path.endsWith('/')) return path.substring(0, path.length - 1);
  else return path;
};

const findIncludesUrl = (path) => {
  const pathSplitArr = path.split('/');
  const targetUrl = COMMON_URLS.find(
    (item, index) => index > 0 && item.split('/')[1] === pathSplitArr[1]
  );
  if (!targetUrl) {
    return false;
  } else if (pathSplitArr.length === 2) {
    return true;
  } else {
    return checkMatch(targetUrl, path);
  }
};

const checkColon = (targetUrl) => {
  return targetUrl.includes(':');
};

const checkOptional = (target) => {
  return target.includes('?');
};

const checkMatch = (targetUrl, path) => {
  const targetArr = targetUrl.split('/');
  const pathSplitArr = path.split('/');

  for (let i = 1; i < targetArr.length; i++) {
    if (checkOptional(targetArr[i])) {
      if (i === pathSplitArr.length - 1) return true;
      else return false;
    } else if (checkColon(targetArr[i])) {
      continue;
    } else {
      if (targetArr[i] !== pathSplitArr[i]) return false;
    }
  }

  return targetArr.length === pathSplitArr.length;
};
