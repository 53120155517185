import { GET, POST } from '../../../utils/constant/httpMethod';
import { requestFile, requestMultipart } from '../../../utils/requestHelper';

const API_GROUP = 'files';
// 파일 임시 업로드 (공통)
const Files = {
  // 파일 임시 업로드 (공통)
  fetchPostFilesTemporary(data) {
    return requestMultipart({
      method: POST,
      url: `/v1/${API_GROUP}/temporary`,
      data
    });
  },
  // 임시 파일 다운로드 (공통)
  fetchGetFilesTemporary(params) {
    return requestFile({
      method: GET,
      url: `/v1/${API_GROUP}/temporary`,
      params: params
    });
  },
  // 주총개설 - 주주명부 샘플 다운로드
  fetchGetFilesShareholderSample() {
    return requestFile({
      method: GET,
      url: `/v1/files/shareholder/sample`
    });
  },
  // 불통일 행사 파일 다운로드
  fetchGetFilesAgmDiversityExercise({ agmSeqno, params }) {
    return requestFile({
      method: GET,
      url: `/v1/${API_GROUP}/agm/${agmSeqno}/diversity-exercise`,
      params: params
    });
  }
};

export default Files;
