import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../components/button/Button';
import ButtonGroup from '../../../../components/button/ButtonGroup';
import FileInput from '../../../../components/input/FileInput';
import Input, { ErrorTypes } from '../../../../components/input/Input';
import InputBox from '../../../../components/input/InputBox';
import Select from '../../../../components/input/Select';
import ListHeader from '../../../../components/layout/ListHeader';
import ListItem from '../../../../components/layout/ListItem';
import Notice, { NoticeTypes } from '../../../../components/notice/Notice';
import {
  AgendaCategory,
  getConstNameByCode,
  TempFileType
} from '../../../../utils/constant/AgmConstant';
import spCommonPng from '../../../../styles/images/common/sprite/sp_common.png';
import { useFileDownload } from '../../../../hooks/useFileDownload';
import { fetchGetFilesAgmAgendaAction } from '../../../../services/api/agm/promiseActions';
import { fetchGetFilesTemporaryAction } from '../../../../services/api/files/promiseActions';

const AgmRegisterStep2Modal = ({
  otherEvent,
  agendaNum,
  resetValue,
  closeModal
}) => {
  const defaultChildsCnt = 2;
  const defaultPassChildCnt = 3;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    unregister,
    formState: { errors }
  } = useForm({ mode: 'all' });
  const [selectedCategory, setSelectedCategory] = useState();
  const [isUpdateFlag, setIsUpdateFlag] = useState();
  const [isEtc, setIsEtc] = useState(false); // 기타의안 여부
  const [isDirectors, setIsDirectors] = useState(false); // 이사선임 여부
  const [childAgendaCnt, setChildAgendaCnt] = useState(0); // 자식의안 개수
  const [passChildAgendaCnt, setPassChildAgendaCnt] =
    useState(defaultPassChildCnt); // 집중 선임이사 수
  const [childAgendaState, setChildAgendaState] = useState(); // 분리투표 여부
  const [concentrateVoteFlag, setConcentrateVoteFlag] = useState(); // 집중투표 여부
  const [agendaFile, setAgendaFile] = useState();

  const renderRef = useRef(false);

  const fiveAgendaMemo = useMemo(() => {
    const childAgendaCategory = [
      AgendaCategory.CHANGE_THE_ARTICLES_OF_ASSOCIATION.code,
      AgendaCategory.APPOINTMENT_OF_DIRECTORS.code,
      AgendaCategory.APPOINTMENT_OF_AUDITOR.code,
      AgendaCategory.APPOINTMENT_OF_OUTSIDE_DIRECTORS.code,
      AgendaCategory.ETC.code
    ];
    return childAgendaCategory.includes(selectedCategory);
  }, [selectedCategory]);

  const getPassConcentrateChildCnt = useMemo(() => {
    return passChildAgendaCnt - 2;
  }, [passChildAgendaCnt]);

  const setDefaultValues = useCallback(() => {
    setSelectedCategory(resetValue.agendaCategory); // 의안명 세팅
    setChildAgendaState(
      // 분리투표 여부 세팅
      !!resetValue.childAgendaCount
        ? resetValue.concentrateVote
          ? false
          : true
        : false
    );
    setTimeout(() => {
      setConcentrateVoteFlag(resetValue.concentrateVote); // 집중투표 세팅
      setChildAgendaCnt(resetValue.childAgendaCount); // 자식의안 개수 세팅
      setValue('childAgendaCount', resetValue.childAgendaCount);
      if (resetValue.concentrateVote) {
        setPassChildAgendaCnt(resetValue.childAgendaCount);
        setValue('passChildAgendaCount', resetValue.passChildAgendaCount);
      }
    });
    if (Object.keys(resetValue).includes('fileName')) {
      setAgendaFile({
        fileName: resetValue.fileName,
        filePath: resetValue.filePath,
        fileSize: resetValue.fileSize
      });
    }
    reset(resetValue);
  }, [reset, resetValue, setValue]);

  const resetChildsField = useCallback(
    (count = 0) => {
      setChildAgendaCnt(count);
      setValue('childCandCount', count);
    },
    [setValue]
  );

  useEffect(() => {
    if (typeof resetValue !== 'undefined') {
      setDefaultValues();
      setIsUpdateFlag(true);
      // 나중에 수정 해야될 사항 : renderRef 를 여기서 해줘야 하는데,,
    } else {
      renderRef.current = true;
    }
  }, [resetValue, setDefaultValues]);

  useEffect(() => {
    if (childAgendaCnt === 0) {
      unregister('childs');
    }
  }, [childAgendaCnt]);

  useEffect(() => {
    // 의안 상세 내용에 selectedCategory 값 넣어주기
    typeof selectedCategory !== 'undefined' &&
      !resetValue &&
      setValue(
        'agendaDetailContent',
        getConstNameByCode(AgendaCategory, selectedCategory)
      );

    setIsEtc(selectedCategory === AgendaCategory.ETC.code);
    setIsDirectors(
      selectedCategory === AgendaCategory.APPOINTMENT_OF_DIRECTORS.code
    );
    if (renderRef.current) {
      typeof selectedCategory === 'undefined' &&
        setValue(
          'agendaDetailContent',
          AgendaCategory.APPROVAL_OF_FINANCIAL_STATEMENTS.name
        );

      setChildAgendaState(false);
      setConcentrateVoteFlag(false);
      resetChildsField(0);
      !!getValues('childs') && reset({ childs: [] });
    }
  }, [selectedCategory, setValue, getValues, reset, resetChildsField]);

  useEffect(() => {
    if (typeof childAgendaState !== 'undefined') {
      if (childAgendaState) {
        if (isUpdateFlag) {
          resetChildsField(resetValue.childAgendaCount);
        } else {
          resetChildsField(defaultChildsCnt);
        }
      } else {
        resetChildsField(0);
      }
    }
  }, [childAgendaState]);

  useEffect(() => {
    if (typeof concentrateVoteFlag !== 'undefined') {
      if (concentrateVoteFlag) {
        if (isUpdateFlag) {
          resetChildsField(resetValue.childAgendaCount);
        } else {
          resetChildsField(defaultChildsCnt + 1);
        }
      } else {
        resetChildsField(0);
        setPassChildAgendaCnt(defaultPassChildCnt);
      }
    }
    if (isUpdateFlag && typeof resetValue !== 'undefined') {
      setConcentrateVoteFlag(resetValue.concentrateVote);
      setIsUpdateFlag(false);
      resetChildsField(resetValue.childAgendaCount);
      renderRef.current = true;
    }
  }, [concentrateVoteFlag]);

  const onChangeUseChildAgenda = (e) => {
    const value = e.target.value;
    if (typeof value !== 'undefined') {
      if (value === 'true') {
        concentrateVoteFlag && setConcentrateVoteFlag(false);
      }
      setTimeout(() => setChildAgendaState(value === 'true' ? true : false));
    }
  };

  const onChangeConcentVote = (e) => {
    const value = e.target.value;
    if (typeof value !== 'undefined') {
      if (value === 'true') {
        setChildAgendaState(false);
      }
      setConcentrateVoteFlag(value === 'true' ? true : false);
      setValue('concentrateVote', value === 'true' ? true : false);
    }
  };

  const onChangeAgendaCnt = (e) => {
    setChildAgendaCnt(e.target.value);
  };

  const addAgenda = (data) => {
    if (!!childAgendaCnt && childAgendaCnt !== data.childs.length) {
      data.childs = data.childs.slice(0, childAgendaCnt);
    }
    if (concentrateVoteFlag) {
      data.passChildAgendaCount = Number.parseInt(
        getValues('passChildAgendaCount')
      );
    }
    !childAgendaState && !concentrateVoteFlag && delete data.childs;
    otherEvent({ ...data, files: agendaFile });
  };

  const onChangeCategory = (e) => {
    setSelectedCategory(e.target.value);
  };

  const renderChildAgenda = (type) => {
    return childAgendaCnt !== 0 ? (
      type === CHILD_AGENDA_TYPE.SEPARATE ? (
        <>{setChildAgendas()}</>
      ) : (
        <>{setConcentrateAgendas()}</>
      )
    ) : (
      <></>
    );
  };

  const setChildAgendas = () => {
    let childsCnt = 1;
    const listItemCnt = Math.floor(childAgendaCnt / 2) + (childAgendaCnt % 2);
    return (
      <>
        {Array(listItemCnt)
          .fill()
          .map((_, idx) => {
            return (
              <ListItem heightType="short">
                {childAgendaOneItem(childsCnt++)}
                {childAgendaCnt >= childsCnt
                  ? childAgendaOneItem(childsCnt++)
                  : childAgendaBlankItem()}
              </ListItem>
            );
          })}
      </>
    );
  };

  const setConcentrateChilds = () => {
    setChildAgendaCnt((prev) => prev + 1);
    setPassChildAgendaCnt((prev) => prev + 1);
  };

  const setConcentrateAgendas = () => {
    let childsCnt = 1;
    const listItemCnt = Math.floor(childAgendaCnt / 2) + (childAgendaCnt % 2);
    return (
      <ConcChildsBlock>
        <ConcChildTitleBlock>
          <span>후보자</span>
          {childAgendaCnt < 6 && (
            <Button
              type={ButtonTypes.Basic}
              size={ButtonSize.Small}
              onClick={setConcentrateChilds}
            >
              추가
            </Button>
          )}
        </ConcChildTitleBlock>
        {Array(listItemCnt)
          .fill()
          .map((_, idx) => {
            return (
              <ListItem heightType="short">
                {childAgendaOneItem(childsCnt++)}
                {childAgendaCnt >= childsCnt
                  ? childAgendaOneItem(childsCnt++)
                  : childAgendaBlankItem()}
              </ListItem>
            );
          })}
      </ConcChildsBlock>
    );
  };

  const setAgendaNum = (childsCnt) => {
    setValue(
      `childs.${childsCnt - 1}.agendaNumber`,
      `${agendaNum}-${childsCnt}`
    );
  };

  const onClickDelChildAgenda = () => {
    setChildAgendaCnt((prev) => prev - 1);
    setPassChildAgendaCnt((prev) => prev - 1);
  };

  const childAgendaOneItem = (childsCnt) => {
    return (
      <InputBox
        isRequired
        title={
          childAgendaState
            ? `제 ${agendaNum}-${childsCnt}호`
            : concentrateVoteFlag
            ? `후보자 ${childsCnt}`
            : ''
        }
        key={`childAgenda_${childsCnt - 1}`}
      >
        <Input
          placeholder="세부 안건명을 입력해주세요"
          register={register}
          registerOption={{ required: true, maxLength: 100 }}
          name={`childs.${childsCnt - 1}.agendaName`}
          onChange={setAgendaNum(childsCnt)}
        />
        {concentrateVoteFlag &&
          childAgendaCnt !== 3 &&
          childsCnt === childAgendaCnt && (
            <Button
              type={ButtonTypes.Basic}
              size={ButtonSize.Small}
              onClick={onClickDelChildAgenda}
            >
              삭제
            </Button>
          )}
        <input
          type="hidden"
          register={register}
          name={`childs.${childsCnt - 1}.agendaNumber`}
        />
      </InputBox>
    );
  };

  const childAgendaBlankItem = () => {
    return <InputBox isEmpty></InputBox>;
  };

  const renderChildAgendaSelBox = () => {
    if (childAgendaState) {
      return (
        <>
          {` (분리의안수 :　`}
          <Select
            onchange={onChangeAgendaCnt}
            register={register}
            name="childAgendaCount"
            noWidth
            noDefault
          >
            {Array(5)
              .fill()
              .map((_, index) => {
                return (
                  <option value={index + 2} key={`agendaCnt_${index}`}>
                    {index + 2}
                  </option>
                );
              })}
          </Select>
          {`)　`}
        </>
      );
    } else {
      return <></>;
    }
  };

  const renderChildCandSelBox = () => {
    if (concentrateVoteFlag) {
      return (
        <>
          {` (선임이사 수 :　`}
          <Select
            register={register}
            name="passChildAgendaCount"
            noWidth
            noDefault
          >
            {Array(getPassConcentrateChildCnt)
              .fill()
              .map((_, index) => {
                return (
                  <option value={index + 2} key={`candidateCnt_${index}`}>
                    {index + 2}
                  </option>
                );
              })}
          </Select>
          {`)　`}
        </>
      );
    } else {
      return <></>;
    }
  };

  const callbackFileUpload = (file, type) => {
    setAgendaFile(file);
  };

  const { callDownloadFetchAPIAgmParam, callDownloadFetchAPITemporary } =
    useFileDownload();

  const fileDownload = (type) => {
    if (!!resetValue) {
      callDownloadFetchAPIAgmParam(
        agendaFile,
        fetchGetFilesAgmAgendaAction,
        resetValue.agmSeqno,
        resetValue.agendaSeqno
      );
    } else {
      callDownloadFetchAPITemporary(agendaFile, fetchGetFilesTemporaryAction);
    }
  };

  return (
    <>
      <ListHeader isRequired popup />
      <ListItem heightType="short">
        <InputBox title="의안명" isRequired>
          <Select
            register={register}
            name="agendaCategory"
            onchange={onChangeCategory}
            noDefault
          >
            {Object.keys(AgendaCategory).map((key, index) => {
              return (
                <option
                  value={AgendaCategory[key].code}
                  key={`agendaCaterogt_${index}`}
                >
                  {AgendaCategory[key].name}
                </option>
              );
            })}
          </Select>
        </InputBox>
        {isEtc ? (
          <InputBox
            title="직접 입력"
            isRequired
            error={errors.agendaName?.message}
            errorType={ErrorTypes.ERROR}
          >
            <Input
              register={register}
              name="agendaName"
              placeholder="직접 입력하세요"
              registerOption={{
                required: {
                  value: true,
                  message: '기타 의안의 의안명은 필수 입력 항목입니다.'
                },
                maxLength: {
                  value: 300,
                  message: '의안명의 길이는 300자 이하여야 합니다.'
                }
              }}
            />
          </InputBox>
        ) : (
          <InputBox isEmpty />
        )}
      </ListItem>
      {fiveAgendaMemo && (
        <ListItem heightType="short">
          <>
            <UnitTiPopBlock>
              <span>의안 분리</span>
            </UnitTiPopBlock>
            <RadioTyBlock
              checked={String(childAgendaState) === 'true'}
              onClick={onChangeUseChildAgenda}
            >
              <input type="radio" id="true" value="true" />
              <label htmlFor="true">분리</label>
            </RadioTyBlock>
            {renderChildAgendaSelBox()}
            <RadioTyBlock
              checked={String(childAgendaState) === 'false'}
              onClick={onChangeUseChildAgenda}
            >
              <input type="radio" id="false" value="false" />
              <label htmlFor="false">비분리</label>
            </RadioTyBlock>
          </>
          {isDirectors ? (
            <>
              <UnitTiPopBlock>
                <span>의안 투표방법</span>
              </UnitTiPopBlock>
              <RadioTyBlock
                register={register}
                name="concentrateVote"
                checked={String(concentrateVoteFlag) === 'true'}
                onClick={onChangeConcentVote}
              >
                <input type="radio" id="radio02_1" value="true" />
                <label htmlFor="radio02_1">집중</label>
              </RadioTyBlock>
              {renderChildCandSelBox()}
              <RadioTyBlock
                checked={String(concentrateVoteFlag) === 'false'}
                onClick={onChangeConcentVote}
              >
                <input type="radio" id="radio02_2" value="false" />
                <label htmlFor="radio02_2">비집중</label>
              </RadioTyBlock>
            </>
          ) : (
            <InputBox isEmpty />
          )}
        </ListItem>
      )}
      {/* 여기 있으면 안됨 */}
      {childAgendaState && renderChildAgenda(CHILD_AGENDA_TYPE.SEPARATE)}
      {concentrateVoteFlag && renderChildAgenda(CHILD_AGENDA_TYPE.CONCENTRATE)}
      <ListItem heightType="short">
        <InputBox
          title="의안상세내용"
          isRequired
          error={errors.agendaDetailContent?.message}
          errorType={ErrorTypes.ERROR}
        >
          <Input
            register={register}
            name="agendaDetailContent"
            registerOption={{
              required: {
                value: true,
                message: '의안상세내용은 필수 입력 항목입니다.'
              },
              maxLength: {
                value: 300,
                message: '의안 상세 내용의 길이는 300자 이하여야 합니다.'
              }
            }}
            placeholder="의안상세내용"
          />
        </InputBox>
        <InputBox title="의안추가자료">
          <FileInput
            fileType={TempFileType.AGENDA_MORE_FIELS}
            callback={callbackFileUpload}
            setFile={agendaFile}
            isPopup={true}
            fileDownload={fileDownload}
            onDelete={() => setAgendaFile(null)}
          />
        </InputBox>
      </ListItem>
      <>
        <Notice
          type={NoticeTypes.PopUp}
          description="이사 선임 의안건의 경우에만 의안투표방법이 활성화됩니다. 집중투표제
          선택 시 선임할 이사의 수를 입력하고 후보자 수를 입력하고 후보자 이름을
          입력해야 집중투표가 가능합니다."
        />
      </>
      <ButtonGroup>
        <Button
          type={ButtonTypes.Basic}
          size={ButtonSize.Popup}
          onClick={closeModal}
        >
          닫기
        </Button>
        <Button
          type={ButtonTypes.Primary}
          size={ButtonSize.Popup}
          onClick={handleSubmit(addAgenda)}
        >
          등록
        </Button>
      </ButtonGroup>
    </>
  );
};

const CHILD_AGENDA_TYPE = {
  SEPARATE: '1',
  CONCENTRATE: '2'
};

const UnitTiPopBlock = styled.div`
  // .unit_ti
  display: block;
  margin-bottom: 10px;
  & > span {
    position: relative;
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    color: #080808;
  }
`;

const RadioTyBlock = styled.div`
  // .radio_ty
  position: relative;
  display: inline-block;
  margin-left: 3%;
  & input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
  & input[type='radio'] + label {
    position: relative;
    display: inline-block;
    padding: 4px 0 2px 40px;
    line-height: 1.67;
    letter-spacing: -0.5px;
    color: #080808;
    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 30px;
      height: 30px;
      background: url(${spCommonPng}) no-repeat;
      background-size: 400px auto;
      background-position: -149px -1px;
      ${(props) =>
        props.checked &&
        css`
          background-position: -149px -31px;
        `}
    }
  }
`;

const ConcChildsBlock = styled.div`
  background: #efefef;
  padding: 20px 20px 0 20px;
  margin-bottom: 20px;
  border-radius: 16px;
`;

const ConcChildTitleBlock = styled.p`
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(204, 213, 224);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 35px;
`;

export default AgmRegisterStep2Modal;
