import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../components/button/Button';
import Pagination from '../../../components/datatable/Pagination';
import { MODAL_SIZE } from '../../../components/modal/Modal';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import { PageInnerListWrap } from '../../../components/page/PageInner';
import { ModalContext } from '../../../context/ModalContext';
import {
  fetchGetQnaQuestionedAction,
  fetchPostQnaAction
} from '../../../services/api/common/promiseActions';
import IngImg02 from '../../../styles/images/common/sprite/inq_img01.png';
import { DATE_FORMAT } from '../../../utils/DateUtil';
import { EMPTY_FUNCTION } from '../../../utils/FunctionUtil';
import QnaWritePage from './QnaWritePage';

const QnaListPage = () => {
  const { user } = useSelector((state) => state.common);
  console.log('user = ');
  console.log(user);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [pageConfig, setPageConfig] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const { openModal, closeModal, setModalConfig } = useContext(ModalContext);
  const history = useHistory();
  const [registed, setRegisted] = useState(false);
  useEffect(() => {
    if (user.isAuth) {
      const parmas = {
        page: pageNumber,
        size: 5
      };
      dispatch(fetchGetQnaQuestionedAction(parmas)).then((res) => {
        if (!res.error) {
          const { number, size, totalElements, totalPages } = res.data;
          setPageConfig({
            pageNumber: number,
            size,
            totalCount: totalElements,
            totalPageNumber: totalPages
          });
          setData(res.data.content);
          setRegisted(false);
        } else {
          openModal('확인', res?.error?.user_message);
        }
      });
    }
  }, [pageNumber, registed, user.isAuth]);

  const reloadList = () => {
    closeModal();
    setPageNumber(0);
    setRegisted(true);
  };

  const onWrite = () => {
    setModalConfig({ size: MODAL_SIZE.MEDIUM, isNoButton: true });
    openModal(
      '1:1 문의',
      <QnaWritePage
        action={fetchPostQnaAction}
        callback={reloadList}
        closeModal={closeModal}
      />,
      EMPTY_FUNCTION,
      true
    );
  };

  const navigateQna = (notientSeqno) => {
    history.push(`/qna/${notientSeqno}`);
  };

  const EmptyQna = () => {
    return (
      <EmptyQnaBlock>
        <img src={IngImg02} style={{ width: '130px' }} alt="QNA 없음" />
        {user.isAuth ? (
          <>
            <p>1:1 문의를 등록해주세요.</p>
            <span>내역이 없습니다.</span>
          </>
        ) : (
          <>
            <p>1:1 문의를 등록하려면 로그인을 해주세요.</p>
            <span>
              로그인이 불가할 경우 문의는 고객센터 070-7791-1100으로 전화 주시기
              바랍니다.
            </span>
            <br />
            <br />
            <Button
              type={ButtonTypes.Outline}
              size={ButtonSize.Medium}
              onClick={() => history.push('/login')}
            >
              로그인
            </Button>
          </>
        )}
      </EmptyQnaBlock>
    );
  };

  return (
    <Page>
      <PageHeader title="1:1 문의" />
      <PageInnerListWrap>
        <DivListTy05>
          {data.length === 0 && <EmptyQna />}
          <ul>
            {/* 반복 */}
            {data &&
              data.map((qna, idx) => {
                const isComplete =
                  qna.childs &&
                  qna.childs.length !== 0 &&
                  qna.childs.find((item) => item.isAnswer);
                return (
                  <li key={idx}>
                    <ul>
                      <li>
                        <span>
                          {DATE_FORMAT(
                            qna.registerDateTime,
                            'yyyy-MM-dd HH:mm'
                          )}
                        </span>
                      </li>
                      <li>
                        <p
                          dangerouslySetInnerHTML={{ __html: qna.title }}
                          onClick={() => navigateQna(qna.notientSeqno)}
                        />
                        <StateDiv complete={isComplete}>{`답변 ${
                          isComplete ? '완료' : '대기'
                        }`}</StateDiv>
                      </li>
                    </ul>
                  </li>
                );
              })}
          </ul>
        </DivListTy05>
        <Pagination pageConfig={{ ...pageConfig, onClick: setPageNumber }} />
        {user.isAuth && (
          <div style={{ float: 'right' }}>
            <Button
              type={ButtonTypes.Primary}
              size={ButtonSize.Table}
              onClick={onWrite}
            >
              등록
            </Button>
          </div>
        )}
      </PageInnerListWrap>
    </Page>
  );
};

const StateDiv = styled.div`
  // temp
  transform: skew(-0.04deg);
  // .state.answer
  color: #a0a6b5;
  background-color: rgba(202, 204, 215, 0.2);
  // .list_ty05 .unit li .state
  position: absolute;
  display: block;
  top: 18px;
  right: 0;
  margin: 0;
  width: 142px;
  padding: 10px 0 9px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  // .list_ty05 .inq li .state
  width: 88px;
  padding: 7px 0;
  font-size: 13px;
  ${(props) =>
    props.complete &&
    css`
    // .state.answer-complete
    color: #EC2A1E;
    border: 1px solid #CACCD7;
    background-color: #fff;
}
  `}
`;
const DivListTy05 = styled.div`
  margin-bottom: 20px;
  & > ul {
    // .list_ty05 > ul
    border-top: solid 1px #e9ecf4;
    & > li {
      // .list_ty05 > ul > li
      border-bottom: solid 1px #e9ecf4;
      & > ul {
        // .list_ty05 .unit
        position: relative;
        display: block;
        // .list_ty05 .inq
        padding: 16px 0;
        & > li {
          // .list_ty05 .unit li
          width: 100%;
          font-size: 15px;
          letter-spacing: -0.5px;
          color: #080808;
          text-align: left;

          // .list_ty05 .unit li:first-of-type
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 8px;
          & span {
            // .list_ty05 .unit li span
            display: inline-block;
            width: 110px;
            padding: 7px 0;
            font-size: 14px;
            color: #24375c;
            margin-right: 20px;
            // .list_ty05 .inq li span
            font-size: 13px;
            font-weight: normal;
            color: #9eb3cd;
          }
          & > p {
            // .list_ty05 .unit li p
            display: inline-block;
            // .list_ty05 .inq li p
            font-size: 16px;
            // temp
            cursor: pointer;
          }
          & > ${StateDiv} {
          }
        }
      }
    }
  }
`;

const EmptyQnaBlock = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  & > img {
    width: 140px;
  }
  & > p {
    // .mem_wrap .mem_done > p
    padding-top: 160px;
    font-size: 28px;
    margin-bottom: 20px;
    position: relative;

    // .mem_wrap .mem_done > p.inq
    padding-top: 20px;
  }
  & > span {
    // .mem_wrap .mem_done > span
    font-size: 18px;
    line-height: 1.5;
    // .ft-col06
    color: #9eb3cd !important;
  }
`;

export default QnaListPage;
