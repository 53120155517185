import { useState } from 'react';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import GuidePageTab from '../../../components/tabs/GuidePageTab';
import AgmRegister from './main/contents/issinc/AgmRegister';
import AgmResult from './main/contents/issinc/AgmResult';
import Contract from './main/contents/issinc/Contract';

const CorpServiceGuidePage = () => {
  const tabArr = Object.values(Tab_Const);
  const [selTab, setSelTab] = useState(0);

  const onChangeTab = (index) => {
    setSelTab(index);
  };

  return (
    <Page extend>
      {<PageHeader title="발행회사 서비스 이용안내" />}
      <GuidePageTab itemArr={tabArr} onChange={onChangeTab} />
      {selTab === 0 && <Contract title={Tab_Const.contract} />}
      {selTab === 1 && <AgmRegister title={Tab_Const.agmRegister} />}
      {selTab === 2 && <AgmResult title={Tab_Const.agmResult} />}
    </Page>
  );
};

const Tab_Const = {
  contract: '서비스 계약',
  agmRegister: '주주총회 등록',
  agmResult: '결과 조회'
};

export default CorpServiceGuidePage;
