import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../components/button/Button';
import ButtonGroup from '../../../../components/button/ButtonGroup';
import FileDownButton from '../../../../components/button/FileDownButton';
import ListItem from '../../../../components/layout/ListItem';
import Main from '../../../../components/layout/Main';
import { MODAL_SIZE } from '../../../../components/modal/Modal';
import { ModalContext } from '../../../../context/ModalContext';
import { useFileDownload } from '../../../../hooks/useFileDownload';
import {
  fetchGetAgmBaseInfoAction,
  fetchGetAgmTotalResultAction,
  fetchGetAgmTotalResultExcelAction,
  fetchGetAgmVoteboxResultAction,
  fetchPostAgmSpotResultAction,
  fetchPostAgmTotalResultAction
} from '../../../../services/api/agm/promiseActions';
import {
  AgmStateCd,
  AgmType,
  getConstNameByCode
} from '../../../../utils/constant/AgmConstant';
import {
  DateFormatName,
  getDateFormat
} from '../../../../utils/DateFormatUtil';
import { dateDiff, DATE_FORMAT, now } from '../../../../utils/DateUtil';
import { formatNumber } from '../../../../utils/StringUtil';
import { RadioBtnBlock } from '../../../shareholder/certification/main/components/CertificateCheckSelect';
import { ListWrapBlock } from '../../dashboard/CorpDashboardPage';
import { TableBtnBlock } from '../../dashboard/main/components/TableBlock';
import '../../register/main/components//tblcommon-01.css';
import ResultTable from '../../register/main/components/TableBlock';
import ConcentrateTable from './main/ConcentrateTable';
import SeparationTable from './main/SeparationTable';
import TotalConcentrateTable from './main/TotalConcentrateTable';
import TotalSeparationTable from './main/TotalSeparationTable';
import TotalYesNoTable from './main/TotalYesNoTable';
import YesNoTable from './main/YesNoTable';

export const AGENDA_TYPE = {
  NON_SEP: 1,
  SEP: 2,
  CONCENTRAT: 3
};

const styles = {
  divStyle: {
    marginTop: '45px'
  }
};

export const getDivStyle = (agenda) => {
  if (Number(agenda.agendaNumber) !== 1) {
    return styles.divStyle;
  } else {
    return null;
  }
};

export const getAgendaDisplayName = (agenda) => {
  return `${agenda.agendaNumber}안 ${agenda.agendaName} -${agenda.agendaDetailContent}`;
};

const AgmResult = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  // const [agmResult, setAgmResult] = useState([]);
  const [voteSummary, setVoteSummary] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const { agmSeqno } = match.params;
  const [tabIdx, setTabIdx] = useState(0);
  const [dataArr, setDataArr] = useState([]);
  const [isVoteEnd, setIsVoteEnd] = useState(false);
  const [agmBaseInfo, setAgmBaseInfo] = useState(null);
  const [radioValue, setRadioValue] = useState(0);
  const { openModal, setModalConfig } = useContext(ModalContext);

  useEffect(() => {
    dispatch(fetchGetAgmBaseInfoAction(agmSeqno)).then((res) => {
      setAgmBaseInfo(res.data);
      setIsVoteEnd(
        res.data.agmStateCode === AgmStateCd.END &&
          dateDiff(new Date(res.data.agmDateTime), now()) > 7
      );
    });
  }, [dispatch, agmSeqno]);

  useEffect(() => {
    // 현장 결과가 등록되어있으면 합산 결과로 조회하고
    // 등록되어있지 않으면 전자투표 결과로 조회한다.
    dispatch(fetchGetAgmVoteboxResultAction({ agmSeqno })).then((result) => {
      console.log('fetchGetAgmVoteboxResultAction');
      console.log(result);
      setVoteSummary(result.data.voteSummary);

      if (!result.error) {
        dispatch(fetchGetAgmTotalResultAction({ agmSeqno })).then((res) => {
          if (
            Object.keys(res.data[0]).includes('totalValue') ||
            (res.data[0].childs.length > 0 &&
              Object.keys(res.data[0].childs[0]).includes('totalValue'))
          ) {
            makeResultData(res.data, false);
          } else {
            // 현장 결과가 등록되지 않은 상태
            makeResultData(result.data.agendas, true);
            setIsEdit(true);
          }
        });
      }
    });
  }, []);

  const makeResultData = (orgData, isEletronicVote) => {
    const dataArr = orgData?.map((data) => {
      const agendaType =
        data.childs.length === 0
          ? AGENDA_TYPE.NON_SEP
          : data.concentrateVote
          ? AGENDA_TYPE.CONCENTRAT
          : AGENDA_TYPE.SEP;
      let exercisableStockCnt = 0;
      if (Object.keys(data).includes('exercisableStockQuantity')) {
        exercisableStockCnt = data?.exercisableStockQuantity;
      } else {
        if (data.totalValue) {
          exercisableStockCnt = data.totalValue.exercisableStockQuantity;
        } else {
          exercisableStockCnt = 999;
        }
      }
      const result = {
        type: agendaType,
        rowCnt:
          agendaType === AGENDA_TYPE.NON_SEP
            ? 4
            : agendaType === AGENDA_TYPE.CONCENTRAT
            ? data.childs.length + 1
            : data.childs.length * 5,

        agenda: {
          agendaSeqno: data.agendaSeqno,
          agendaNumber: data.agendaNumber,
          agendaName: data.agendaName,
          agendaDetailContent: data.agendaDetailContent,
          concentrateVote: data.concentrateVote,
          childAgendaCount: data.childAgendaCount,
          agendaCategory: data.agendaCategory,
          exercisableStockQuantity: exercisableStockCnt
        },
        electronicVoteValue: isEletronicVote
          ? data.voteBoxSummary
          : data.electronicVoteValue,
        electronicProxyValue: data.electronicProxyValue,
        spotValue: fillEmptySpotValue(data.agendaSeqno, data.spotValue),
        totalValue: fillEmptyTotalValue(
          isEletronicVote ? data.voteBoxSummary : data.electronicVoteValue,
          data.totalValue
        ),
        result: data.result,
        childs: data.childs.map((child) => {
          let exercisableStockQuantity = 0;
          if (child.exercisableStockQuantity) {
            exercisableStockQuantity = child.exercisableStockQuantity;
          } else if (data.exercisableStockQuantity) {
            exercisableStockQuantity = data.exercisableStockQuantity;
          } else {
            exercisableStockQuantity =
              child.totalValue.exercisableStockQuantity;
          }
          return {
            agenda: {
              parentAgendaSeqno: data.agendaSeqno,
              agendaSeqno: child.agendaSeqno,
              agendaNumber: child.agendaNumber,
              agendaName: child.agendaName,
              concentrateVote: child.concentrateVote,
              childAgendaCount: child.childAgendaCount,
              agendaCategory: child.agendaCategory,
              exercisableStockQuantity: exercisableStockQuantity
            },
            electronicVoteValue: isEletronicVote
              ? child.voteBoxSummary
              : child.electronicVoteValue,
            electronicProxyValue: child.electronicProxyValue,
            spotValue: fillEmptySpotValue(child.agendaSeqno, child.spotValue),
            totalValue: fillEmptyTotalValue(
              isEletronicVote
                ? child.voteBoxSummary
                : child.electronicVoteValue,
              child.totalValue
            ),
            result: child.result
          };
        })
      };
      return result;
    });
    setDataArr(dataArr);
  };

  const fillEmptyTotalValue = (electronicProxyValue, totalValue) => {
    if (!totalValue) {
      return {
        ...electronicProxyValue
      };
    } else {
      return totalValue;
    }
  };

  const fillEmptySpotValue = (agendaSeqno, spotValue) => {
    if (!spotValue) {
      return {
        abstentionCount: 0,
        abstentionPercent: 0,
        agendaSeqno: 12,
        ayesCount: 0,
        ayesPercent: 0,
        noesCount: 0,
        noesPercent: 0,
        totalCount: 0
      };
    } else {
      return spotValue;
    }
  };

  const onChangeCount = (value, parentAgendaSeqno, agendaSeqno, type) => {
    console.log('onChangeCount');
    console.log(type);
    value = Number(value);
    const newArr = dataArr?.map((data) => {
      if (data.agenda.agendaSeqno === parentAgendaSeqno) {
        if (data.type === AGENDA_TYPE.NON_SEP) {
          return tabIdx === 0
            ? {
                ...data,
                spotValue: {
                  ...data.spotValue,
                  [type]: Number(value)
                },
                totalValue: {
                  ...data.totalValue,
                  [type]: data.electronicVoteValue[type] + Number(value)
                }
              }
            : {
                ...data,
                totalValue: {
                  ...data.totalValue,
                  [type]: Number(value)
                }
              };
        } else {
          const newChilds = data.childs.map((child) => {
            if (child.agenda.agendaSeqno === agendaSeqno) {
              const a =
                tabIdx === 0
                  ? {
                      ...child,
                      spotValue: {
                        ...child.spotValue,
                        [type]: Number(value)
                      },
                      totalValue: {
                        ...child.totalValue,
                        [type]: child.electronicVoteValue[type] + Number(value)
                      }
                    }
                  : {
                      ...child,
                      totalValue: {
                        ...child.totalValue,
                        [type]: Number(value)
                      }
                    };
              return a;
            } else {
              return child;
            }
          });
          return {
            ...data,
            childs: newChilds
          };
        }
      } else {
        return data;
      }
    });
    console.log('newArr');
    console.log(newArr);
    setDataArr(newArr);
  };

  const onChangeReuslt = (value, parentAgendaSeqno, agendaSeqno) => {
    const newArr = dataArr.map((data) => {
      if (data.agenda.agendaSeqno === parentAgendaSeqno) {
        if (data.type === AGENDA_TYPE.NON_SEP) {
          return {
            ...data,
            result: value
          };
        } else {
          const newChilds = data.childs.map((child) => {
            if (child.agenda.agendaSeqno === agendaSeqno) {
              return {
                ...child,
                result: value
              };
            } else {
              return child;
            }
          });
          return {
            ...data,
            childs: newChilds
          };
        }
      } else {
        return data;
      }
    });

    setDataArr(newArr);
  };

  const renderTable = () => {
    if (!dataArr || dataArr.length === 0) return null;
    return dataArr?.map((data, idx) => {
      switch (data?.type) {
        case AGENDA_TYPE.SEP:
          return (
            <SeparationTable
              key={`SeparationTable_${idx}`}
              data={data}
              onChangeReuslt={onChangeReuslt}
              onChangeCount={onChangeCount}
              isEdit={isEdit}
            />
          );
        case AGENDA_TYPE.NON_SEP:
          return (
            <YesNoTable
              key={`YesNoTable_${idx}`}
              data={data}
              onChangeReuslt={onChangeReuslt}
              onChangeCount={onChangeCount}
              isEdit={isEdit}
            />
          );
        case AGENDA_TYPE.CONCENTRAT:
          return (
            <ConcentrateTable
              key={`ConcentrateTable_${idx}`}
              data={data}
              onChangeReuslt={onChangeReuslt}
              onChangeCount={onChangeCount}
              isEdit={isEdit}
            />
          );
        default:
          return null;
      }
    });
  };

  const renderTotalTable = () => {
    console.log('renderTotalTable');
    console.log(dataArr);
    return dataArr?.map((data, idx) => {
      switch (data?.type) {
        case AGENDA_TYPE.SEP:
          return (
            <TotalSeparationTable
              key={`TotalSeparationTable_${idx}`}
              data={data}
              onChangeReuslt={onChangeReuslt}
              onChangeCount={onChangeCount}
              isEdit={isEdit}
            />
          );
        case AGENDA_TYPE.NON_SEP:
          return (
            <TotalYesNoTable
              key={`TotalYesNoTable_${idx}`}
              data={data}
              onChangeReuslt={onChangeReuslt}
              onChangeCount={onChangeCount}
              isEdit={isEdit}
            />
          );
        case AGENDA_TYPE.CONCENTRAT:
          return (
            <TotalConcentrateTable
              key={`TotalConcentrateTable_${idx}`}
              data={data}
              onChangeReuslt={onChangeReuslt}
              onChangeCount={onChangeCount}
              isEdit={isEdit}
            />
          );
        default:
          return null;
      }
    });
  };

  const onSubmit = () => {
    // 등록용 데이터 생성
    const arr = dataArr.map((data) => {
      if (data.childs.length > 0) {
        return {
          ...data,
          childs: data.childs.filter((item) => item.agenda.agendaSeqno !== -1)
        };
      } else {
        return data;
      }
    });
    const values = arr.map((item) => {
      const key = tabIdx === 0 ? 'spotValue' : 'totalValue';

      if (item.childs.length > 0) {
        return {
          agendaSeqno: item.agenda.agendaSeqno,
          concentrateVote: item.agenda.concentrateVote,
          childs: item.childs.map((child) => {
            return {
              agendaSeqno: child.agenda.agendaSeqno,
              ayesCount: child[key].ayesCount,
              noesCount: child[key].noesCount,
              abstentionCount: child[key].abstentionCount,
              result: child.result
            };
          })
        };
      } else {
        return {
          agendaSeqno: item.agenda.agendaSeqno,
          concentrateVote: item.agenda.concentrateVote,
          ayesCount: item[key].ayesCount,
          noesCount: item[key].noesCount,
          abstentionCount: item[key].abstentionCount,
          result: item.result
        };
      }
    });
    const submitData = {
      values,
      participantCount: 0
    };
    if (tabIdx === 0) {
      dispatch(
        fetchPostAgmSpotResultAction({ agmSeqno, data: submitData })
      ).then((res) => {
        console.log(res);
        setModalConfig({
          size: MODAL_SIZE.SHORT
        });
        if (!res.error) {
          openModal('주주총회 결과 등록', '등록되었습니다', () => {
            setIsEdit(false);
          });
        } else {
          openModal('주주총회 결과 등록', res.error.user_message);
        }
      });
    } else {
      dispatch(
        fetchPostAgmTotalResultAction({ agmSeqno, data: submitData })
      ).then((res) => {
        console.log(res);
        setModalConfig({
          size: MODAL_SIZE.SHORT
        });
        if (!res.error) {
          openModal('주주총회 결과 등록', '등록되었습니다', () => {
            setIsEdit(false);
          });
        } else {
          openModal('주주총회 결과 등록', res.error.user_message);
        }
      });
    }
  };

  const onModify = () => {
    setIsEdit((isEdit) => !isEdit);
  };

  const onClickHandler = (value) => {
    setRadioValue((prev) => value);
    setTabIdx((prev) => value);
  };

  const { callDownloadFetchAPIOnlyAgmseqno } = useFileDownload();

  const onClickDownResult = () => {
    callDownloadFetchAPIOnlyAgmseqno(
      agmSeqno,
      fetchGetAgmTotalResultExcelAction
    );
  };

  return (
    <Main>
      {!isVoteEnd && (
        <ListWrapBlock>
          <TableTitle>
            <span>전자투표 현황</span>
            <Unit>단위 : 1 주</Unit>
          </TableTitle>
          <div className="tblcommon-01 nothead">
            <table>
              <colgroup>
                <col width="27%;" />
                <col width="23%;" />
                <col width="25%" />
                <col width="23%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>발행주식 총 수</th>
                  <td>{formatNumber(voteSummary?.totalIssueStockQuantity)}</td>
                  <th>주주명부 인원</th>
                  <td>{formatNumber(voteSummary?.totalShareholderCount)}</td>
                </tr>
                <tr>
                  <th>참여 인원</th>
                  <td>
                    {formatNumber(voteSummary?.voteParcptShareholderCount)}
                  </td>
                  <th>참여율</th>
                  <td>{voteSummary?.votedShareholderRate}%</td>
                </tr>
              </tbody>
            </table>
          </div>

          <TableTitle>
            <span>주주총회 결과 등록(합산결과 등록)</span>
            <TableBtnBlock>
              <ul>
                <li>
                  <RadioBtnBlock active={0 === radioValue}>
                    <input type="radio" id="radio01_1" name="radio01" checked />
                    <label
                      htmlFor="radio01_1"
                      onClick={() => onClickHandler(0)}
                    >
                      현장투표결과만 등록
                    </label>
                  </RadioBtnBlock>
                  <RadioBtnBlock active={1 === radioValue}>
                    <input type="radio" id="radio01_2" name="radio02" />
                    <label
                      htmlFor="radio01_2"
                      onClick={() => onClickHandler(1)}
                    >
                      전체결과 등록
                    </label>
                  </RadioBtnBlock>
                </li>
                <li></li>
              </ul>
            </TableBtnBlock>
            <Unit>단위 : 1 주</Unit>
          </TableTitle>
          {/*
          <PageTab
            itemArr={['현장투표결과 등록', '전체결과 등록']}
            onChange={setTabIdx}
          />
          */}

          {tabIdx === 0 ? renderTable() : renderTotalTable()}
          <ButtonGroup>
            {agmBaseInfo && agmBaseInfo.agmStateCode === AgmStateCd.END && (
              <Button
                type={ButtonTypes.Primary}
                size={ButtonSize.Large}
                onClick={!isEdit ? onModify : onSubmit}
              >
                {!isEdit ? '수정' : '등록'}
              </Button>
            )}
            {agmBaseInfo && agmBaseInfo.agmStateCode === AgmStateCd.PLACE && (
              <Button
                type={ButtonTypes.Primary}
                size={ButtonSize.Large}
                onClick={onSubmit}
              >
                등록
              </Button>
            )}
          </ButtonGroup>
        </ListWrapBlock>
      )}
      {isVoteEnd && agmBaseInfo && (
        <ListWrapBlock>
          <TableTitle>
            <span>주주총회 개최 현황</span>
          </TableTitle>
          <ListItem>
            <ResultTable>
              {/*
              <tr>
                <th>회사명</th>
                <td colSpan="3">{agmBaseInfo?.solicitorName}</td>
              </tr>
              */}
              <tr>
                <th>주총구분</th>
                <td>
                  {getConstNameByCode(AgmType, agmBaseInfo.agmType)}
                  {agmBaseInfo.agmSessionCount
                    ? `/${agmBaseInfo.agmSessionCount}회`
                    : ''}
                </td>
                <th>기준일</th>
                <td>{agmBaseInfo.agmRecordDate}</td>
              </tr>
              <tr>
                <th>전자투표/위임기간</th>
                <td>
                  {DATE_FORMAT(
                    new Date(agmBaseInfo.voteBeginDateTime),
                    getDateFormat(DateFormatName.LONG_DATE_TIME)
                  )}
                  {` ~ `}
                  {DATE_FORMAT(
                    new Date(agmBaseInfo.voteEndDateTime),
                    getDateFormat(DateFormatName.LONG_DATE_TIME)
                  )}
                </td>
                <th>주주총회일</th>
                <td>
                  {DATE_FORMAT(
                    new Date(agmBaseInfo.agmDateTime),
                    getDateFormat(DateFormatName.LONG_DATE_TIME)
                  )}
                </td>
              </tr>
              <tr>
                <th>장소</th>
                <td colSpan="3">{agmBaseInfo.agmPlace}</td>
              </tr>
            </ResultTable>
          </ListItem>
          <ListWrapBlock>
            <TableTitle>
              <span>주주총회 결과</span>
            </TableTitle>
            <FileDownButton
              style={{ marginBottom: '12px' }}
              onClick={onClickDownResult}
            >
              주주총회 결과 출력
            </FileDownButton>
            {renderTotalTable()}
          </ListWrapBlock>
        </ListWrapBlock>
      )}
    </Main>
  );
};

//
const TableTitle = styled.div`
  // .tbl_ty
  display: block;
  margin-top: 50px;
  margin-bottom: 12px;
  position: relative;
  & > span:first-of-type {
    // .tbl_ty > span
    position: relative;
    display: inline-block;
    padding-left: 18px;
    font-size: 20px;
    font-weight: bold;
    &::after {
      // .tbl_ty > span::after
      content: '';
      position: absolute;
      top: 6px;
      left: 0;
      display: block;
      width: 3px;
      height: 18px;
      margin-top: -4.5px;
      background: #0572ef;
    }
  }
`;

const Unit = styled.span`
  display: inline-block;
  padding-left: 18px;

  // .tbl_ty .unit
  &&& {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 14px;
    font-weight: normal;
  }
`;

export default AgmResult;
