import styled from 'styled-components';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../components/button/Button';
import Page from '../../../components/page/Page';
import ctfImg01Png from '../../../styles/images/common/sprite/ctf_img01.png';

const CertificatePage = () => {
  const onClickBtn = () => {
    window.open(
      'https://www.signkorea.com/mac_Manage/mac_manage.jsp#this',
      '_blank'
    );
  };
  return (
    <Page extend>
      <CtfCenterBlock>
        <h2>
          <p>공동인증서 복사하기</p>
          <Button
            type={ButtonTypes.Basic}
            size={ButtonSize.Medium}
            onClick={onClickBtn}
          >
            복사하기
          </Button>
          <span>Window/Mac OS에서 지원됩니다.</span>
          <span>(크롬, 익스플로러 브라우저에서 이용해보세요.)</span>
        </h2>
      </CtfCenterBlock>
    </Page>
  );
};

const CtfCenterBlock = styled.div`
  // .top_ti_div
  width: 100%;
  // temp : height: auto;
  /* height: 100vh; */
  padding: 110px 0 130px;
  border-bottom: 0;
  background: #0094ff;
  text-align: center;
  & > h2 {
    font-size: 50px;
    color: #fff;
    height: 204px;
    padding: 25px 0 0 348px;
    font-weight: 600;
    line-height: 1.18;
    display: inline-block;
    position: relative;
    // temp
    margin-top: 70px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 288px;
      height: 172px;
      background: url(${ctfImg01Png}) no-repeat;
      background-size: 288px auto;
    }
    & > button {
      display: block;
      margin: 40px auto 20px;
      padding: 12px 40px 10px 40px;
      font-size: 25px;
      font-weight: 800;
      background-color: #fff;
      box-shadow: 0px 4px 8px rgb(0 0 0 / 20%);
      //
      color: #080808;
    }
    & > span {
      display: block;
      margin-top: 5px;
      font-size: 16px;
      font-weight: normal;
    }
  }
`;

export default CertificatePage;
