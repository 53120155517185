import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { idpwsign, SIGN_TYPE } from '../components/auth/SignIframe';
import { MODAL_SIZE } from '../components/modal/Modal';
import { ModalContext } from '../context/ModalContext';
import {
  CancelBtn,
  OkBtn
} from '../pages/shareholder/vote/main/components/popup/common/PopupButton';
import {
  fetchPostAuthLoginCertAction,
  fetchPostAuthRefreshAction
} from '../services/api/auth/promiseActions';
import { Role } from '../utils/constant/AgmConstant';
import { setAccessToken, setRefreshToken } from '../utils/requestHelper';
import { action as commonAction } from '../store/commonSlice';
import { getGnbRole } from '../utils/constant/GnbMenu';

export const useAuth = () => {
  const { openModal, setModalConfig, closeModal } = useContext(ModalContext);
  const dispatch = useDispatch();

  const changeRole = (role) => {
    switch (role) {
      case Role.ISSINC_ADMIN.code:
        changeRoleIssInc();
        break;

      default:
        break;
    }
  };

  const changeRoleIssInc = () => {
    setModalConfig({
      size: MODAL_SIZE.SHORT,
      buttons: [
        <OkBtn
          onClick={() => {
            closeModal(250);
            idpwsign(SIGN_TYPE.PERSON, signin);
          }}
        />,
        <CancelBtn onClick={() => closeModal(250)} />
      ]
    });
    openModal('확인', confirmAuth());
  };

  const confirmAuth = () => {
    return (
      <ConfirmBlock>
        <h3>공동인증서 로그인이 필요한 서비스입니다.</h3>
        <h4>발행회사 공동인증서로 로그인해주세요.</h4>
      </ConfirmBlock>
    );
  };

  const signin = (signData) => {
    const data = { signData, asIssueIncorporated: true };
    dispatch(fetchPostAuthLoginCertAction(data)).then((res) => {
      if (!res.error) {
        loginSuccess(res);
      } else {
        openModal('확인', res.error.user_message);
      }
    });
  };

  const loginSuccess = (res) => {
    const result = res.accessToken ? res : res.data;
    setAccessToken(result.accessToken);
    setRefreshToken(result.refreshToken);
    dispatch(
      commonAction.setCurrentUser({
        userSeqno: result.userSeqno,
        userName: result.userName,
        currentAgm: null,
        role: result.role,
        isAuth: true
      })
    );
    dispatch(commonAction.setCurrentGnb(getGnbRole(result.role)));

    // 현재 시간으로 부터 expiredTime까지 남은 시간 초로 환산
    // (new Date('2021-08-07T18:06:00.924').getTime() - 60000 - Date.now()) / 1000
    //const expiredTime = new Date(res.data.expiredTime);
    const refreshTime = 1000 * 60 * 5; //(expiredTime.getTime() - Date.now()) / 1000 - 60000; // 한시간 남으면 리프레시

    // 특정 시간 지난 후 리프레시
    window.tokenRefresh = setInterval(() => {
      dispatch(fetchPostAuthRefreshAction(result.refreshToken)).then((res) => {
        setAccessToken(res.data.accessToken);
      });
    }, refreshTime);
  };

  return { changeRole };
};

const ConfirmBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 5px;
`;
