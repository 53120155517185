import PropsType from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { DescBoxBlock } from '../../pages/IssueIncorporated/management/diverseExercise/DiverseExercise';
import { EMPTY_FUNCTION } from '../../utils/FunctionUtil';
import { formatNumber } from '../../utils/StringUtil';
import CheckBox from '../input/CheckBox';
import Notice, { NoticeTypes } from '../notice/Notice';
import TableTitle from '../table/TableTitle';
import DataFilter from './DataFilter';
import DataTableFooter from './DataTableFooter';
export const DataTableBlock = styled.div`
  // .tblcommon-01
  width: 100%;
  & table {
    min-width: 100%;
    ${(props) =>
      props.readOnlyTable &&
      css`
        & tr:hover {
          background: rgba(5, 114, 239, 0.08);
          cursor: pointer;
        }
      `}
    & th {
      background: #fbfbfc;
      border-top: solid 1px #e9ecf4;
      border-right: solid 1px #e9ecf4;
      box-shadow: 0px 3px 2px rgb(0 0 0 / 3%);
      position: relative;
      ${(props) =>
        props.shortHeight
          ? css`
              padding: 8px 8px 7px;
              height: 32px;
            `
          : css`
              padding: 5px 8px 2px;
              height: 50px;
            `}
      font-size: 14px;
      text-align: center;
      transform: skew(-0.04deg);
    }
    & tr:first-of-type {
      border-left: 0;
    }
    & th:first-of-type {
      border-left: 0;
    }

    & td {
      ${(props) =>
        props.shortHeight
          ? css`
              padding: 8px 8px 7px;
              height: 34px;
            `
          : css`
              padding: 3px 8px 0;
              height: 54px;
            `}
      font-size: 15px;
      border-bottom: solid 1px #e9ecf4;
      text-overflow: ellipsis;
      word-break: break-all;
      text-align: center;
      transform: skew(-0.04deg);
      ${(props) =>
        props.overFlow ||
        css`
          white-space: nowrap;
          overflow: hidden;
        `}
    }
  }
`;
export const DataType = {
  NUMBER: 'number',
  REALNUMBER: 'realNumber',
  LINK: 'link',
  CUSTOM: 'custom'
};
const DataTable = (props) => {
  const {
    title,
    columns,
    dataSource = [],
    pageConfig,
    filterConfig,
    actionItems,
    onSelected,
    description,
    onRowClick,
    clearSeleted,
    shortHeight = false,
    overFlow = false,
    detailValueNames = []
  } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const isExistChildren = columns.some((column) => column.children);
  const [allChecked, setAllChecked] = useState(false);
  const checkboxIndex = dataSource.map((data, index) => index);
  const [readOnlyTable, setReadOnlyTable] = useState(false);

  useEffect(() => {
    !!onRowClick && setReadOnlyTable(true);
  }, [onRowClick]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys]);
    if (typeof onSelected === 'function') {
      onSelected([...selectedRowKeys]);
    }
  };

  useEffect(() => {
    if (clearSeleted) {
      setSelectedRowKeys([]);
    }
  }, [clearSeleted]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  useEffect(() => {
    if (selectedRowKeys && checkboxIndex.length !== 0) {
      setAllChecked(checkboxIndex.length === selectedRowKeys.length);
    }
  }, [checkboxIndex, selectedRowKeys]);

  const getChildren = () => {
    const newArr = [];
    columns.map((column) => {
      if (column.children) {
        newArr.push(...column.children);
      }
      return null;
    });
    return newArr;
  };

  const getColumnIndex = () => {
    const newArr = [];
    columns.map((column) => {
      if (column.children) {
        column.children.map((child) => {
          newArr.push(child);
          return null;
        });
      } else {
        newArr.push(column);
      }
      return null;
    });
    return newArr;
  };

  let children = getChildren();
  let conlumnIndex = getColumnIndex();

  const allSelect = (checked) => {
    if (!checked) {
      rowSelection.onChange([]);
    } else {
      rowSelection.onChange(checkboxIndex);
    }
  };

  const setChecked = (checked, value) => {
    if (checked) {
      rowSelection.onChange([...rowSelection.selectedRowKeys, value]);
    } else {
      rowSelection.onChange(
        rowSelection.selectedRowKeys.filter((item) => item !== value)
      );
    }
  };

  const renderColumns = () => {
    return (
      <>
        {onSelected && (
          <th rowSpan={isExistChildren ? 2 : 1}>
            <CheckBox checked={allChecked} onChange={allSelect} />
          </th>
        )}
        {columns.map((column, idx) => (
          <th
            key={`dataTable_th_${idx}`}
            rowSpan={column.children ? 1 : 2}
            colSpan={
              column.colSpan
                ? column.colSpan
                : column.children
                ? column.children.length
                : 1
            }
          >
            {/* {column.title} */}
            {renderColumnTitle(column)}
          </th>
        ))}
      </>
    );
  };

  // TODO 배열로 처리하도록 변경 필요
  const renderColumnTitle = (column) => {
    const { title } = column;
    if (Array.isArray(title)) {
      return title.map((line, index) => {
        return (
          <>
            {line}
            {index < title.length - 1 && <br />}
          </>
        );
      });
    } else {
      return title;
    }
  };

  const onLocalRowClick = (e) => {
    if (e.target.nodeName === 'LABEL' || e.target.nodeName === 'INPUT') {
      return;
    }
    onRowClick(e);
  };

  const renderData = (column, data, index) => {
    let formattedData = data[column.key];
    const { type, format } = column;
    // TODO 타입이 여러개인 경우가 생길 경우 if 문 또는 재귀로 대체 필요
    switch (type) {
      case DataType.NUMBER:
        if (!formattedData) {
          return 0;
        }
        formattedData = formatNumber(Number(formattedData));
        break;
      case DataType.CUSTOM:
        formattedData = format(column, data, index);
        break;
      case DataType.LINK:
        formattedData = (
          <Link to={getLinkKey(column.url, data)}>{formattedData}</Link>
        );
        break;
      default:
        break;
    }

    return formattedData;
  };

  const getLinkKey = (link, data) => {
    return link.replace(/\[(.*?)\]/g, function (match, group) {
      const matchData = data[group];
      return matchData;
    });
  };

  const renderDataRow = () => {
    const handleRowClick = onRowClick ? onLocalRowClick : EMPTY_FUNCTION;
    if (dataSource.length > 0) {
      return dataSource.map((data, idx) => {
        const selectedRow = rowSelection
          ? rowSelection.selectedRowKeys.includes(idx)
          : false;

        return detailValueNames.length !== 0 ? (
          <>
            <tr key={`dataTable_row_tr_${idx}`} onClick={handleRowClick}>
              {/* Checkbox */}
              {onSelected && (
                <td>
                  <CheckBox
                    checked={selectedRow}
                    onChange={setChecked}
                    value={idx}
                  />
                </td>
              )}
              {/* Table Column */}
              {conlumnIndex.map((column, idx) => {
                return (
                  <td
                    style={{
                      textAlign: !!column.align ? column.align : 'center'
                    }}
                    key={`dataTable_td_${idx}`}
                  >
                    {renderData(column, data, idx)}
                  </td>
                );
              })}
            </tr>
            <DescBoxBlock style={{ display: 'none' }}>
              <td colSpan="6">
                <p>불통일행사 사유</p>
                {data.exerciseReason}
              </td>
            </DescBoxBlock>
          </>
        ) : (
          <tr key={`dataTable_row_tr_${idx}`} onClick={handleRowClick}>
            {/* Checkbox */}
            {onSelected && (
              <td>
                <CheckBox
                  checked={selectedRow}
                  onChange={setChecked}
                  value={idx}
                />
              </td>
            )}
            {/* Table Column */}
            {conlumnIndex.map((column, idx) => {
              if (column.type === DataType.CUSTOM) {
                return renderData(column, data, idx);
              } else {
                return (
                  <td
                    style={{
                      textAlign: !!column.align ? column.align : 'center'
                    }}
                    key={`dataTable_td_${idx}`}
                    colSpan={!!column.colSpan ? column.colSpan : 1}
                  >
                    {renderData(column, data, idx)}
                  </td>
                );
              }
            })}
          </tr>
        );
      });
    } else {
      let len = 0;
      for (const column of columns) {
        if (!!column.colSpan) {
          len += column.colSpan - 1;
        }
      }
      const colSpanLength = !!columns.find((column) => {
        return column.colSpan;
      })
        ? onSelected
          ? conlumnIndex.length + 1 + len
          : conlumnIndex.length + len
        : onSelected
        ? conlumnIndex.length + 1
        : conlumnIndex.length;
      return (
        <tr>
          <td colSpan={colSpanLength}>
            <Notice type={NoticeTypes.Notice}>조회된 정보가 없습니다</Notice>
          </td>
        </tr>
      );
    }
  };
  return (
    <>
      <DataTableBlock
        readOnlyTable={readOnlyTable}
        shortHeight={shortHeight}
        overFlow={overFlow}
      >
        {title && <TableTitle>{title}</TableTitle>}
        <DataFilter filterConfig={filterConfig} />
        <table>
          <colgroup>
            {onSelected && <col key={`dataTable_col_checkbox`} width="100px" />}
            {columns.map((column, idx) =>
              column.children ? (
                column.children.map((child, idx) => {
                  return (
                    <col
                      key={`dataTable_col_child_${idx}`}
                      width={child.col ? child.col : '*'}
                    />
                  );
                })
              ) : column.colArr ? (
                <>
                  <col
                    key={`dataTable_col_child_${idx}_1`}
                    width={column.colArr.split(',')[0]}
                  />
                  <col
                    key={`dataTable_col_child_${idx}_2`}
                    width={column.colArr.split(',')[1]}
                  />
                </>
              ) : (
                <col
                  key={`dataTable_col_${idx}`}
                  width={column.col ? column.col : '*'}
                />
              )
            )}
          </colgroup>
          <thead>
            <tr>{renderColumns()}</tr>
            {isExistChildren && (
              <tr>
                {children.map((child, idx) => {
                  return (
                    <th key={`dataTable_th_child_${idx}`}>
                      {renderColumnTitle(child)}
                    </th>
                  );
                })}
              </tr>
            )}
          </thead>
          <tbody>{renderDataRow()}</tbody>
        </table>
      </DataTableBlock>
      <DataTableFooter
        pageConfig={pageConfig}
        actionItems={actionItems}
        description={description}
      />
    </>
  );
};

DataTable.propTypes = {
  title: PropsType.string,
  columns: PropsType.array,
  dataSource: PropsType.array,
  onSelected: PropsType.func,
  filterConfig: PropsType.object,
  pageConfig: PropsType.object,
  actionItems: PropsType.oneOfType([PropsType.func, PropsType.object]),
  description: PropsType.oneOfType([PropsType.string, PropsType.array]),
  onRowClick: PropsType.oneOfType([PropsType.func, PropsType.object])
};

DataTable.defaultProps = {
  title: '',
  columns: [],
  dataSource: [],
  rowSelection: null,
  onSelected: null
};

export default DataTable;
