import styled from 'styled-components';
import ctfImgPng from '../../../../../../../../../styles/images/common/sprite/ctf_img01_1.png';

const ErrorPage = () => {
  return (
    <ErrorULBlock>
      <li style={{ textAlign: 'center' }}>
        <img src={ctfImgPng} alt={`image_${0}`} width="70%" />
        <br />
        <h2>만료된 전자문서입니다.</h2>
      </li>
    </ErrorULBlock>
  );
};

const ErrorULBlock = styled.ul`
  background: rgba(158, 179, 205, 0.1) !important;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default ErrorPage;
