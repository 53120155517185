import { UnitTitleBlock } from '../../../../../../../components/layout/title/UnitTitle';
import { TableTitleBlock } from '../../../../../../../components/table/TableTitle';
import { ListType11Block } from '../layout/LayoutWrapper';
import ElectronicBillForm from './sendForm/ElectronicBillForm';
import KakaoForm from './sendForm/KakaoForm';

const SendView = ({ setValue, info }) => {
  return (
    <>
      <TableTitleBlock>
        <span>카카오 발송화면</span>
      </TableTitleBlock>
      <ListType11Block>
        <ul>
          <li>
            <UnitTitleBlock>카카오톡</UnitTitleBlock>
            <KakaoForm
              setValue={setValue}
              agm={info?.agm}
              electronicNotice={info?.electronicNotice}
            />
          </li>
          <li>
            <UnitTitleBlock>전자고지서 열람하기</UnitTitleBlock>
            <ElectronicBillForm setValue={setValue} info={info} />
          </li>
        </ul>
      </ListType11Block>
    </>
  );
};

export default SendView;
