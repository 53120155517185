import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';

export const VOTE_BUTTON_SIZE = {
  Short: 0,
  Long: 1
};

export const VOTE_BUTTON_TYPE = {
  Foucs: 0,
  Done: 1,
  Stock: 2
};

export const VOTE_BUTTON_TEXT_ALIGN = {
  Left: 0,
  Center: 1,
  Right: 2
};

const DefaultButtonStyle = styled.button`
  // .boxlist_ty .unit button
  position: relative;
  display: inline-block;
  margin: 16px 2px 0;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 12px 30px;
  min-height: 52px;
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid #0094ff;
  box-sizing: border-box;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 22%);

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: relative;
    display: inline-block;
    margin: 12px 1px 0;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 7px 16px;
    min-height: 40px;
    background-color: #fff;
    border-radius: 4px;
    border: 2px solid #0094ff;
    box-sizing: border-box;
    box-shadow: 0px 3px 3px rgb(0 0 0 / 22%);
  }

  // .boxlist_ty .unit .off
  ${(props) =>
    (props.isAbsten || props.disabled) &&
    css`
      font-weight: normal;
      color: #b4c3d8;
      border-color: #e8edf3;
      box-shadow: none;
    `}
  ${(props) =>
    props.active ||
    ((props.type === VOTE_BUTTON_TYPE.Done ||
      props.type === VOTE_BUTTON_TYPE.Foucs) &&
      css`
        font-weight: bold;
        box-shadow: 0px 3px 3px rgb(0 0 0 / 22%);

        // temp = 기권
        /*
        ${(props) =>
          props.isAbsten &&
          css`
            background-color: #dde3eb;
            color: #fff;
            border: none;
            box-shadow: none;
          `}
          */
      `)}
`;

export default DefaultButtonStyle;
