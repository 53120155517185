import { createPromiseAction } from '@adobe/redux-saga-promise';

// 사용 권한 조회
export const fetchGetSeccoAuthorizationAction = createPromiseAction(
  'FETCH_GET_SECCO_AUTHORIZATION'
);

// 발행회사 정보 조회
export const fetchGetIssueIncorporatedPageAction = createPromiseAction(
  'FETCH_GET_ISSUE_INCORPORATED_PAGE'
);

// 전자주총 현황 조회
export const fetchGetAgmStatusAction = createPromiseAction(
  'FETCH_GET_AGM_STATUS'
);
