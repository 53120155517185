import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gnb: { currentGnb: 'common', currentMenu: 'guide' },
  user: {
    userSeqno: null,
    userName: null,
    currentAgm: null,
    role: null,
    isAuth: false
  },
  authorization: {
    methods: null,
    didUseMediaList: null,
    didUseForce: false
  }
};

const reducers = {
  setCurrentGnb: (state, { payload: currentGnb }) => {
    state.gnb.currentGnb = currentGnb;
  },
  setCurrentUser: (state, { payload: user }) => {
    state.user = user
      ? user
      : {
          userSeqno: null,
          userName: null,
          currentAgm: null,
          role: null,
          isAuth: false
        };
  },
  setAuthorization: (state, { payload: authorization }) => {
    state.authorization = authorization
      ? authorization
      : {
          methods: null,
          didUseMediaList: null,
          didUseForce: false
        };
  },
  startLoading: (state, { payload }) => {
    state[payload] = true;
  },
  finishLoading: (state, { payload }) => {
    state[payload] = true;
  }
};

export const slice = createSlice({
  name: 'common',
  initialState,
  reducers
});

export const reducer = slice.reducer;
export const action = slice.actions;
