import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { css, keyframes } from 'styled-components';
import { ModalContext } from '../../context/ModalContext';
import { Icons } from '../../styles/Icons';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';
import IconButton from '../button/IconButton';
import ListTag from '../layout/ListTag';
import LayerBody from './LayerBody';
import LayerHeader from './LayerHeader';

export const MODAL_SIZE = {
  MEDIUM: 'medium',
  LARGE: 'large',
  SHORT: 'short',
  WIDE: 'wide'
};

const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`;

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, 50%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
`;

const slideDown = keyframes`
  from {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  to {
    opacity: 0;
    transform: translate(-50%, 50%);
  }
`;
export const ModalBackgroundBlock = styled.div`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  //display: none;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;

  // animation
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;

  ${(props) =>
    props.hide &&
    css`
      animation-name: ${fadeOut};
    `}
`;
const ModalBlock = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  //display: none;
  width: calc(100% - 60px);
  max-width: 600px;
  max-height: calc(100% - 60px);
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 200;

  // animation
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${slideUp};
  animation-fill-mode: forwards;

  ${(props) =>
    props.hide &&
    css`
      animation-name: ${slideDown};
    `}

  ${(props) => {
    if (props.size === MODAL_SIZE.MEDIUM) {
      return css`
        max-width: 920px;
      `;
    } else if (props.size === MODAL_SIZE.LARGE) {
      return css`
        max-width: 1020px;
      `;
    } else if (props.size === MODAL_SIZE.SHORT) {
      return css`
        max-width: 500px;
      `;
    } else if (props.size === MODAL_SIZE.WIDE) {
      return css`
        max-width: 1220px;
      `;
    } else {
      return css``;
    }
  }}
`;

const LayerWrapBlock = styled.div`
  padding: 20px 28px 18px;
  border-radius: 16px;
  background-color: #ffffff;
  overflow: hidden;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding: 16px 20px;
    border-radius: 6px;
    background-color: #ffffff;
    overflow: hidden;
  }
`;

const Modal = () => {
  let {
    title,
    modalContent,
    modal,
    okHandler,
    closeModal,
    isRequired,
    modalConfig = {},
    closeCallback,
    isRemoveCloseIcon
  } = React.useContext(ModalContext);
  const [animate, setAnimate] = useState(false);
  const [localVisible, setLocalVisible] = useState(modal);
  useEffect(() => {
    // modal 값이 true -> false 되는 시점에 수행!
    if (localVisible && !modal) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 250);
    }
    setLocalVisible(modal);
  }, [localVisible, modal]);
  if (!animate && !localVisible) return null;
  return ReactDOM.createPortal(
    <>
      <ModalBackgroundBlock hide={!localVisible} />
      <ModalBlock hide={!localVisible} {...modalConfig}>
        <LayerWrapBlock>
          <LayerHeader>
            {title}
            {isRequired && <ListTag isRequired />}
          </LayerHeader>
          <LayerBody
            {...modalConfig}
            okHandler={() => {
              if (typeof okHandler === 'function') {
                okHandler();
              }
              closeModal();
            }}
          >
            {modalContent}
          </LayerBody>
          {!isRemoveCloseIcon && (
            <IconButton
              iconName={Icons.layer_close.name}
              onClick={() => {
                if (typeof closeCallback === 'function') {
                  closeCallback();
                }
                closeModal(250);
              }}
            />
          )}
        </LayerWrapBlock>
      </ModalBlock>
    </>,
    document.querySelector('#modal-root')
  );
};

export default Modal;
