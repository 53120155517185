import PropsType from 'prop-types';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';
import gnbPng from '../../styles/images/common/sprite/sp_gnb2.png';
import useWindowSize from '../../hooks/useWindowSize';
import HamburgurMenu from './HamburgerMenu';

const PageTitleBlock = styled.div`
  width: 100%;
  height: auto;
  //padding: 30px 0 50px;
  padding: 50px 0 50px;
  border-bottom: 1px solid #e9ecf4;
  text-align: center;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    & > span {
      position: absolute;

      // LNB가 덮히지 않으면 z-index 지워봐라
      z-index: 1;
      &::before {
        content: '';
        display: block;
        width: 16px;
        height: 15px;
        margin: 0 auto;
        margin-left: 18px;
        background: url(${gnbPng}) no-repeat;
        background-size: 300px auto;
        background-position: -1px -1px;

        cursor: pointer;
      }
    }
  }
  & h2 {
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    display: inline-block;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      font-size: 18px;
      color: #ffffff;
      flex: 1;
    }
  }
`;

const PageTitle = ({ title, noHamburger }) => {
  const [hamburgerState, setHamburgerState] = useState(false);
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => {
    return windowSize.width <= SCREEN_SIZE_MOBILE;
  }, [windowSize]);

  const openHamburgerMenu = () => {
    setHamburgerState(true);
  };

  const onClose = () => {
    setHamburgerState(false);
  };

  return (
    <>
      <PageTitleBlock>
        {isMobile && !noHamburger && <span onClick={openHamburgerMenu}></span>}
        <h2>{title}</h2>
      </PageTitleBlock>
      <HamburgurMenu state={hamburgerState} onClose={onClose} />
    </>
  );
};

PageTitle.propTypes = {
  title: PropsType.string,
  noHamburger: PropsType.bool
};

PageTitle.defaultProps = {
  title: '',
  noHamburger: false
};

export default PageTitle;
