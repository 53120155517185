import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../../../components/button/Button';
import signup1JpgM from '../../../../../../styles/images/common/sprite/stockholder/mobile/signup_1.jpg';
import signup2JpgM from '../../../../../../styles/images/common/sprite/stockholder/mobile/signup_2.jpg';
import signup3JpgM from '../../../../../../styles/images/common/sprite/stockholder/mobile/signup_3.jpg';
import signup4JpgM from '../../../../../../styles/images/common/sprite/stockholder/mobile/signup_4.jpg';
import signup1Jpg from '../../../../../../styles/images/common/sprite/stockholder/signup_1.jpg';
import signup2Jpg from '../../../../../../styles/images/common/sprite/stockholder/signup_2.jpg';
import signup3Jpg from '../../../../../../styles/images/common/sprite/stockholder/signup_3.jpg';
import signup4Jpg from '../../../../../../styles/images/common/sprite/stockholder/signup_4.jpg';
import { Tab_State_Const } from '../../../../../../utils/constant/AgmConstant';
import {
  SerInnerBlock,
  SerStepBlock,
  SerStepItemBlock,
  SerViewBlock,
  SerViewItemBlock,
  SerViewNextBtnBlock,
  SerViewPrevBtnBlock,
  StyledSlider
} from '../../components/InnerBlock';

const SignUp = ({ title }) => {
  const [showIndex, setShowIndex] = useState(0);
  const [nav, setNav] = useState();
  const history = useHistory();

  const Images = [
    {
      url: isMobile ? signup1JpgM : signup1Jpg,
      desc: '메인 화면에서 로그인 버튼을 클릭하여 로그인 화면으로 이동'
    },
    {
      url: isMobile ? signup2JpgM : signup2Jpg,
      desc: '로그인 화면에서 서비스 가입 버튼을 눌러 서비스 가입 절차를 진행'
    },
    {
      url: isMobile ? signup3JpgM : signup3Jpg,
      desc: '개인은 개인사용자 가입으로, 법인은 법인사용자 가입으로 진행'
    },
    {
      url: isMobile ? signup4JpgM : signup4Jpg,
      desc: '순서대로 기입하여 선택하면 가입 완료'
    }
  ];

  const length = Images.length;

  useEffect(() => {
    nav && nav.slickGoTo(showIndex);
  }, [showIndex, nav]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    swipeToSlide: false
  };

  const onClickPrevBtn = () => {
    if (showIndex !== 0) {
      setShowIndex((index) => index - 1);
    } else {
      setShowIndex(length - 1);
    }
  };

  const onClickNextBtn = () => {
    if (showIndex !== length - 1) {
      setShowIndex((index) => index + 1);
    } else {
      setShowIndex(0);
    }
  };

  const getShowState = (state) => {
    return showIndex === state
      ? Tab_State_Const.ING
      : showIndex < state
      ? Tab_State_Const.WILL
      : Tab_State_Const.DONE;
  };

  return (
    <SerInnerBlock>
      {/* <h3>{title}</h3> */}
      <SerStepBlock>
        <ul>
          {/* step 개수대로 map 돌려야됨 */}
          {Array(length)
            .fill()
            .map((_, index) => {
              return (
                <SerStepItemBlock state={getShowState(index)}>
                  <a href="#!" onClick={() => setShowIndex(index)}>
                    {index + 1}
                  </a>
                </SerStepItemBlock>
              );
            })}
        </ul>
      </SerStepBlock>
      <SerViewBlock>
        <SerViewPrevBtnBlock onClick={onClickPrevBtn}>이전</SerViewPrevBtnBlock>
        <SerViewNextBtnBlock onClick={onClickNextBtn}>다음</SerViewNextBtnBlock>
        <StyledSlider {...settings} ref={(c) => setNav(c)}>
          {Images.map((image, index) => {
            return (
              <SerViewItemBlock key={index} notImportant>
                <img src={image.url} alt={`image_${index}`} />
                <p>{image.desc}</p>
              </SerViewItemBlock>
            );
          })}
        </StyledSlider>
        <Button
          type={ButtonTypes.Defaults}
          size={ButtonSize.Medium}
          onClick={() => history.push('/login')}
        >
          회원가입 바로가기
        </Button>
      </SerViewBlock>
    </SerInnerBlock>
  );
};

export default SignUp;
