import React from 'react';
import styled, { css } from 'styled-components';
import SpinnerImg from '../../styles/images/common/spinner.gif';

const ScreenDiv = styled.div`
  ${(props) =>
    props.isEmptyScreen
      ? css`
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
        `
      : css`
          height: 200;
          text-align: center;
        `}
`;

const Spinner = ({ isEmptyScreen = false }) => {
  return (
    <ScreenDiv isEmptyScreen={isEmptyScreen}>
      <img src={SpinnerImg} alt="spinner" />
    </ScreenDiv>
  );
};

export default Spinner;
