import React from 'react';
import styled from 'styled-components';

const WaveBlock = styled.em`
  margin: 0 10px;
`;

const Wave = () => {
  return <WaveBlock>~</WaveBlock>;
};

export default Wave;
