import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Spinner from '../../../../../../components/alert/Spinner';
import { ButtonSize } from '../../../../../../components/button/Button';
import { SCREEN_SIZE_MOBILE } from '../../../../../../components/button/CommonStyle';
import { MODAL_SIZE } from '../../../../../../components/modal/Modal';
import { ModalContext } from '../../../../../../context/ModalContext';
import { fetchGetAgmLimitsStockholdersAllAction } from '../../../../../../services/api/agm/promiseActions';
import {
  checkUndefinedNum,
  formatNumber
} from '../../../../../../utils/StringUtil';
import {
  BoxInfoBlock,
  BoxListBlock,
  DescPTagBlock,
  PopupType
} from './common/PopupBlockStyle';
import { OkBtn } from './common/PopupButton';

/**
 * 의결권 제한 팝업
 */
const VotingLimitsPopup = (props) => {
  let { setModalConfig, closeModal } = useContext(ModalContext);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVoter, setSelectedVoter] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dispatch = useDispatch();
  const { agmSeqno, voters } = props;
  useEffect(() => {
    setModalConfig({
      size: MODAL_SIZE.MEDIUM,
      buttons: [
        <OkBtn size={ButtonSize.Table} onClick={() => closeModal(250)} />
      ],
      innerPadding: true
    });
  }, []);

  useEffect(() => {
    setSelectedVoter(voters[0]);
  }, []);

  useEffect(() => {
    const onFetchLimits = (voter) => {
      if (!voter) return;
      const securitiesCorpSeqno = 1;
      dispatch(
        fetchGetAgmLimitsStockholdersAllAction({
          securitiesCorpSeqno,
          agmSeqno
        })
      ).then((res) => {
        if (!isLoading) {
          // MultiExercise에서 index 0 을 찾기위한 작업. loading 중 일때만 검사.
          const target = res.data.find(
            (d, i) => d.votingRightsLimits?.length > 0
          );
          setSelectedVoter(
            voters.find(
              (st, index) =>
                target?.votingRightsLimits[0]?.stockholderSeqno ===
                st.stockholderSeqno
            )
          );
        }
        setIsLoading(true);
        if (res.data && res.data.length > 0) {
          const target = res.data.filter(
            (d, i) => d.votingRightsLimits?.length > 0
          );
          if (target.length !== 0) {
            const newArr = [];
            target.forEach((stockholder, index) => {
              // selectedIndex 구하기
              selectedVoter.stockholderSeqno ===
                stockholder.votingRightsLimits[0]?.stockholderSeqno &&
                setSelectedIndex(index);

              // selectedStockholder 포함하는 제한 List 구하기
              return stockholder.votingRightsLimits.forEach((item) => {
                newArr.push(item);
              });
            });
            setData(newArr);
          }
        }
      });
    };

    onFetchLimits(selectedVoter);
  }, [dispatch, agmSeqno, selectedVoter]);

  let tmpArr = [];
  const displayVoter =
    data &&
    data.filter((item) => {
      if (!item) return false;
      if (!tmpArr.includes(item.stockholderSeqno)) {
        tmpArr.push(item.stockholderSeqno);
        return true;
      } else {
        return false;
      }
    });

  return (
    <>
      {!isLoading && <Spinner />}
      {data && (
        <>
          <DescPTagBlock>
            {data.length === 0
              ? '의결권 제한 내역이 없습니다'
              : '우선주는 의결권 없는 주식수에 포함되어 있습니다.'}
          </DescPTagBlock>
          <BoxInfoBlock
            // selectedIndex={data.findIndex(
            //   (item) => item.stockholderSeqno === selectedVoter.stockholderSeqno
            // )}
            selectedIndex={selectedIndex}
          >
            {displayVoter &&
              displayVoter.map((voter) => (
                <ul
                  onClick={() => {
                    setSelectedVoter(voter);
                  }}
                >
                  <li>
                    <BoxSpanTagBlock>주주명</BoxSpanTagBlock>
                    <BoxPTagBlock>
                      {voter.stockholderName}
                      <span>({voter.stockholderRealNameNumber})</span>
                    </BoxPTagBlock>
                  </li>
                  <li>
                    <BoxSpanTagBlock>의결권 있는 주식수</BoxSpanTagBlock>
                    <BoxPTagBlock>
                      {formatNumber(
                        voter.commonStockAmount +
                          checkUndefinedNum(
                            voter.exercisablePreferredStockAmount
                          )
                      )}
                      주
                    </BoxPTagBlock>
                  </li>
                </ul>
              ))}
          </BoxInfoBlock>
          <BoxListBlock type={PopupType.VOTING_LIMITS}>
            {data &&
              data
                .filter((item) => {
                  return (
                    item.stockholderSeqno === selectedVoter.stockholderSeqno
                  );
                })
                .map((item, index) => {
                  console.log(item);
                  return (
                    <ul key={`BoxListBlock-ul-${index}`}>
                      <li>
                        <ul>
                          <li>
                            <div className="sequence">
                              {item.agendaSeqno === 0
                                ? '전체 의안'
                                : `의안${item.agendaSeqno}`}
                            </div>
                            <p>{item.agendaName}</p>
                          </li>
                          <li>
                            <p>
                              제한사유
                              <span>
                                <strong className="ft-col03">
                                  {item.votingRightsLimitContent}
                                </strong>
                              </span>
                            </p>
                          </li>
                          <li>
                            <p>
                              의결권 있는 주식수
                              <span>
                                {formatNumber(
                                  item.commonStockAmount +
                                    checkUndefinedNum(
                                      item.exercisablePreferredStockAmount
                                    )
                                )}
                                주
                              </span>
                            </p>
                          </li>
                          <li>
                            <p>
                              의결권 없는 주식수
                              <span>
                                {formatNumber(item.noVotingRightsStockAmmount)}
                                주
                              </span>
                            </p>
                          </li>
                          <li>
                            <p>
                              의결권 제한 주식수
                              <span>
                                {formatNumber(
                                  item.votingRightsLimitStockAmmount
                                )}
                                주
                              </span>
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>행사가능주식수</strong>
                              <span>
                                <span>
                                  {formatNumber(item.exercisableStockAmmount)}주
                                </span>
                              </span>
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  );
                })}
          </BoxListBlock>
        </>
      )}
      {!data && <p>의결권 제한 내역이 없습니다.</p>}
    </>
  );
};

const BoxPTagBlock = styled.p`
  flex-grow: 1;
  font-size: 17px;
  text-align: right;
  padding-right: 24px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    font-size: 14px !important;
  }
`;

const BoxSpanTagBlock = styled.span`
  &&& {
    width: 105px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      padding: 5px 0 5px 20px;
    }
  }
`;

export default VotingLimitsPopup;
