import styled from 'styled-components';
import { useFileDownload } from '../../../../../../../hooks/useFileDownload';
import { fetchGetAgmElectronicProxyPdfAction } from '../../../../../../../services/api/agm/promiseActions';
import spCommonPng from '../../../../../../../styles/images/common/sprite/sp_common.png';
import { formatNumber } from '../../../../../../../utils/StringUtil';

const ProxyApplyInfo = ({ summary, agmSeqno }) => {
  const { callDownloadFetchAPIOnlyAgmseqno } = useFileDownload();
  const onClickPrintProxy = () => {
    callDownloadFetchAPIOnlyAgmseqno(
      agmSeqno,
      fetchGetAgmElectronicProxyPdfAction
    );
  };
  return (
    <ListTy09Block>
      <ul>
        <li>
          <p>
            위임 신청 주주 인원은
            <br /> 총
            <FtCol02Block>
              {formatNumber(summary.participantCount)}
            </FtCol02Block>
            명 이며,
          </p>
        </li>
        <li>
          <p>
            합산 주식수는
            <br /> 총
            <FtCol02Block>
              {formatNumber(summary.participatedCommonStockCount)}
            </FtCol02Block>
            주 입니다
          </p>
        </li>
        <li>
          {summary.participantCount > 0 && (
            <button onClick={onClickPrintProxy}>의안별 위임장 출력</button>
          )}
        </li>
      </ul>
    </ListTy09Block>
  );
};

const ListTy09Block = styled.div`
  & ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 34px 80px 28px;
    border: solid 1px #ccd5e0;
    border-radius: 8px;
    box-sizing: border-box;
  }
  & > ul > li {
    flex: 1;
    letter-spacing: -0.5px;
    position: relative;
    text-align: center;
    & > p {
      font-size: 17px;
      font-weight: bold;
      box-sizing: border-box;
      position: relative;
      line-height: 1.65;
      & > strong {
        font-size: 32px;
        padding: 0 12px;
      }
    }
    & > button {
      display: inline-block;
      width: auto;
      padding: 0 64px 0 25px;
      height: 44px;
      font-size: 14px;
      font-weight: bold;
      color: #505f7e;
      margin: 0 2px 0;
      border-radius: 4px;
      border: 1px solid #e3e5ea;
      background-color: transparent;
      box-sizing: border-box;
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 10px;
        right: 20px;
        width: 24px;
        height: 23px;
        background: url(${spCommonPng}) no-repeat;
        background-size: 400px auto;
        background-position: -33px -201px;
      }
    }
  }
`;

const FtCol02Block = styled.strong`
  color: #0094ff !important;
`;

export default ProxyApplyInfo;
