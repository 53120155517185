import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import Page from '../../../components/page/Page';
import Steps from '../../../components/step/Steps';
import { fetchPostFilesTemporaryAction } from '../../../services/api/files/promiseActions';
import AgmRegisterStep1 from './step1/AgmRegisterStep1';
import AgmRegisterStep2 from './step2/AgmRegisterStep2';
import AgmRegisterStep3 from './step3/AgmRegisterStep3';
import AgmRegisterStep4 from './step4/AgmRegisterStep4';

export const AGM_REGISTER_STEPS = {
  BASE_INFO: '1',
  AGENDA: '2',
  LIMITS: '3',
  ELECTION_VOTER: '4'
};

const AgmRegister = () => {
  const { register } = useForm();
  const match = useRouteMatch();
  const [files, setFiles] = useState([]);
  let history = useHistory();
  const dispatch = useDispatch();
  let { agmSeqno, step } = useParams();

  // 파일 업로드
  const handleFile = (fileType, uploadeldFile) => {
    const fd = new FormData();
    fd.append('file', uploadeldFile);
    dispatch(fetchPostFilesTemporaryAction(fd))
      .then((res) => {
        const fileData = {
          ...res.data,
          type: fileType
        };
        setFiles([...files, fileData]);
        console.log('fileUploadedData');
        console.log(files);
      })
      .catch((e) => console.log(e));
  };

  const renderStepContnet = () => {
    switch (step) {
      case '1':
        return (
          <AgmRegisterStep1
            register={register}
            handleData={handleBaseInfoData}
            handleFile={handleFile}
            moveStep={moveStep}
          />
        );
      case '2':
        return (
          <AgmRegisterStep2
            register={register}
            handleFile={handleFile}
            moveStep={moveStep}
          />
        );
      case '3':
        return (
          <AgmRegisterStep3
            register={register}
            handleFile={handleFile}
            moveStep={moveStep}
          />
        );
      case '4':
        return <AgmRegisterStep4 register={register} handleFile={handleFile} />;

      default:
        break;
    }
  };

  const moveStep = (step, seqno) => {
    if (!!seqno) {
      // 최초 register 할 때, seqno가 null이기에,,
      agmSeqno = seqno;
    }
    if (match.path.includes('test')) {
      history.push(`/corp/agm/${agmSeqno}/register/${step}/test`);
    } else {
      history.push(`/corp/agm/${agmSeqno}/register/${step}`);
    }
  };

  const handleBaseInfoData = (a) => {
    console.log('--- root Step1 Data ---');
    console.log(a);
    console.log('--- // rrot Step1 Data ---');
  };

  return (
    <Page>
      <Steps
        // moveStep={moveStep}
        currentStep={step}
        stepInfo={[
          {
            stepSeq: 1,
            title: '01 주주총회 정보등록'
          },
          {
            stepSeq: 2,
            title: '02 의안/주주명부 등록'
          },
          {
            stepSeq: 3,
            title: '03 의결권 행사 제한 등록'
          },
          {
            stepSeq: 4,
            title: '04 투표권자 명부 확정'
          }
        ]}
        wide={step !== '1'}
      />
      {renderStepContnet()}
    </Page>
  );
};

export default AgmRegister;
