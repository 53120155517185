import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../components/button/CommonStyle';
import { MODAL_SIZE } from '../../../components/modal/Modal';
import { ModalContext } from '../../../context/ModalContext';
import { fetchGetTermsSeqnoAction } from '../../../services/api/terms/promiseActions';
import logoPng from '../../../styles/images/common/logo.png';
import { AgmTermsType } from '../../../utils/constant/AgmConstant';
import { STOP_EVENT_FUNCTION } from '../../../utils/FunctionUtil';

const FooterArea = styled.div`
  // #footer
  width: 100%;
  background-color: #fff;
  //height: 360px !important;
  // temp
  border-top: solid 1px #f4f5f9;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    display: none;
  }
`;

const Footer = styled.div`
  // #footer .fInner
  max-width: 1360px;
  margin: 0 auto;
  padding: 32px 0 30px;
  position: relative;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding: 12px 0 10px;
  }
  & > h1 {
    // #footer .fInner > h1
    position: absolute;
    top: 44px;
    left: 0;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      display: none;
    }
    & > a {
      display: block;
      width: 160px;
      height: 22px;
      background: url(${logoPng}) no-repeat;
      cursor: pointer;
      background-size: 160px auto;
    }
  }
  & > ul {
    // #footer .fInner > ul
    width: auto;
    padding-left: 210px;
    text-align: left;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      padding-left: 10px;
    }
    & li {
      // #footer .fInner > ul li
      margin-top: 10px;
      & a {
        // #footer .fInner > ul li a
        position: relative;
        display: inline-block;
        padding-right: 22px;
        margin-right: 20px;
        font-size: 15px;
        &:before {
          // #footer .fInner > ul li a:before
          position: absolute;
          top: 1px;
          right: 0;
          width: 1px;
          height: 14px;
          background-color: #d2d4d5;
          content: '';
        }
      }
      & a:last-child:before {
        // #footer .fInner > ul li a:before
        position: absolute;
        top: 1px;
        right: 0;
        width: 1px;
        height: 14px;
        background-color: #d2d4d5;
        content: '';
      }
      & p {
        // #footer .fInner > ul li p
        display: inline-block;
        margin-right: 16px;
        font-size: 13px;
        color: #939393;
      }
    }
  }
`;

const TermsBlock = styled.span`
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 1px;
  & p {
    margin: 1rem;
  }
`;

const PageFooter = () => {
  const dispatch = useDispatch();
  const { openModal, setModalConfig, closeModal } = useContext(ModalContext);
  let history = useHistory();

  const openTermsModal = (seqno) => {
    //PRIVATE_POLICY
    dispatch(fetchGetTermsSeqnoAction(seqno))
      .then((res) => {
        setModalConfig({
          size: MODAL_SIZE.MEDIUM
        });
        openModal(
          res.data.title,
          <TermsBlock
            onScroll={(e) => {
              console.log(e);
              STOP_EVENT_FUNCTION(e);
            }}
            dangerouslySetInnerHTML={{
              __html: res.data.content
            }}
          ></TermsBlock>
        );
      })
      .then(() => {
        const previousArr = document.querySelectorAll(
          '.privacy-policy-history'
        );
        previousArr.forEach((item) => {
          item.addEventListener('click', () => {
            const prevNo = item.getAttribute('path').split('/').pop();
            closeModal(250);
            history.push(`/privacy/${prevNo}`);
          });
        });
      });
  };

  return (
    <FooterArea>
      <Footer>
        <h1>
          <Link to="#"></Link>
        </h1>
        <ul>
          <li>
            <Link
              to="#"
              onClick={() =>
                openTermsModal(AgmTermsType.USE_OF_VOTING_SERVICE.code)
              }
            >
              서비스 이용약관
            </Link>
            <Link
              to="#"
              onClick={() => openTermsModal(AgmTermsType.PRIVATE_POLICY.code)}
            >
              <b>개인정보처리방침</b>
            </Link>
          </li>
          <li>
            <p>
              (07255) 서울특별시 영등포구 선유로 146 이앤씨드림타워
              706호&nbsp;&nbsp;&nbsp;Copyright ⓒ KEVoting All Rights Reserved.
            </p>
          </li>
        </ul>
      </Footer>
    </FooterArea>
  );
};

export default PageFooter;
