import React from 'react';
import styled from 'styled-components';
import PropsType from 'prop-types';
import { SCREEN_SIZE_MOBILE } from '../../../../components/button/CommonStyle';

const SignupTitleBlock = styled.div`
  font-size: 16px;
  font-weight: bold;
  transform: skew(-0.04deg);
  padding-bottom: 16px;
  border-bottom: 1px solid #e9ecf4;
  margin-bottom: 30px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    font-size: 15px;
    padding-bottom: 10px;
    margin-bottom: 16px;
  }
  & > p {
    margin-top: 12px;
    font-size: 13px;
    font-weight: normal;
  }
`;

const SignupTitle = ({ title, desc }) => {
  return (
    <SignupTitleBlock>
      {title}
      {desc && <p>{desc}</p>}
    </SignupTitleBlock>
  );
};

SignupTitle.propTypes = {
  title: PropsType.string
};

SignupTitle.defaultProps = {
  title: ''
};

export default SignupTitle;
