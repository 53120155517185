import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Pagination from '../../../components/datatable/Pagination';
import SearchInput from '../../../components/input/SearchInput';
import { fetchGetAddressAction } from '../../../services/api/common/promiseActions';

const SearchAddressModalBlock = styled.div``;
const ListItem = styled.ul`
  position: relative;
  display: block;
  padding: 10px 20px 10px;
  background-color: #fff;
  border: solid 1px #d8e1eb;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 12%);
  margin-top: 10px;
  & li {
    width: 100%;
    letter-spacing: -0.5px;
    font-size: 15px;
    margin-top: 5px;
  }
`;

const PostNoBlock = styled.p`
  font-size: 16px;
  font-weight: 800;
`;
const TitleLabelBlock = styled.p`
  width: 50px;
  text-align: center;
  color: #0075ff;
  display: inline-block;
  margin-right: 15px;
`;
const AddressBlock = styled.span`
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
`;

const ErrorMessage = styled.p`
  font-size: 15px;
  font-weight: 400;
  padding-top: 15px;
  padding-left: 15px;
`;
const SearchAddressModal = (props) => {
  const { onSetBaseAddr } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageConfig, setPageConfig] = useState();
  const [searchValue, setSearchValue] = useState();
  const [errorMessage, setErrorMessage] = useState('검색어를 입력해주세요');

  useEffect(() => {
    if (searchValue) {
      search(searchValue);
    }
  }, [pageNumber]);

  const search = (value) => {
    setSearchValue(value);
    const searchText = value;
    dispatch(
      fetchGetAddressAction({
        searchText: searchText,
        page: pageNumber + 1,
        size: 5
      })
    ).then((res) => {
      if (res.data.juso) {
        setData(res.data.juso);
        setErrorMessage(null);
        if (res.data.common.totalCount !== 0) {
          setPageConfig({
            pageNumber: res.data.common.currentPage - 1,
            size: res.data.common.countPerPage,
            totalCount: res.data.common.totalCount,
            totalPageNumber: Math.ceil(
              res.data.common.totalCount / res.data.common.countPerPage
            )
          });
        }
      } else {
        setErrorMessage(res.data.common.errorMessage);
      }
    });
  };

  return (
    <SearchAddressModalBlock>
      <SearchInput placeholder="검색어를 입력해주세요" onSearch={search} />
      {data?.map((item, index) => {
        return (
          <div style={{ marginBottom: index === data.length - 1 ? '20px' : 0 }}>
            <ul>
              <li>
                <ListItem>
                  <li>
                    <PostNoBlock>{item.zipNo}</PostNoBlock>
                  </li>
                  <li>
                    <TitleLabelBlock>도로명</TitleLabelBlock>
                    <AddressBlock onClick={() => onSetBaseAddr(item.roadAddr)}>
                      {item.roadAddr}
                    </AddressBlock>
                  </li>
                  <li>
                    <TitleLabelBlock>지 번</TitleLabelBlock>
                    <AddressBlock onClick={() => onSetBaseAddr(item.jibunAddr)}>
                      {item.jibunAddr}
                    </AddressBlock>
                  </li>
                </ListItem>
              </li>
            </ul>
          </div>
        );
      })}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {pageConfig && (
        <Pagination pageConfig={{ ...pageConfig, onClick: setPageNumber }} />
      )}
    </SearchAddressModalBlock>
  );
};

export default SearchAddressModal;
