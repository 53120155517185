import PropTypes from 'prop-types'; // ES6
import React from 'react';
import styled, { css } from 'styled-components';

const LabelBlock = styled.p`
  // .input_wrap p.ctf_state
  flex: none;
  width: 300px !important;
  padding-top: 13px;
  height: 42px;
  background-color: rgba(221, 227, 235, 0.35);
  border: solid 1px rgba(221, 227, 235, 0.8);
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
  margin-right: 6px;
  ${(props) =>
    props.isOk &&
    css`
      background-color: #284cb0;
      color: #fff;
    `}
`;

const Label = ({ defaultMessage, okMessage, isOk }) => {
  return (
    <LabelBlock isOk={isOk}>{isOk ? okMessage : defaultMessage}</LabelBlock>
  );
};

Label.propTypes = {
  defaultMessage: PropTypes.string,
  okMessage: PropTypes.string,
  isOk: PropTypes.bool
};

Label.defaultProps = {
  defaultMessage: '',
  okMessage: '',
  isOk: false
};

export default Label;
