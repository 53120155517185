import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../components/button/CommonStyle';
import { LOGO_PLACE } from '../../../components/page/Gnb';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import useWindowSize from '../../../hooks/useWindowSize';
import serTxt02Png from '../../../styles/images/common/sprite/ser_txt02.png';
import { SerInnerBlock } from './main/components/InnerBlock';

const ServiceAboutPage = () => {
  const history = useHistory();
  const [selVideoIndex, setSelVideoIndex] = useState(Videos[0].index);
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => {
    return windowSize.width <= SCREEN_SIZE_MOBILE;
  }, [windowSize]);
  const size = useMemo(() => {
    return {
      width: isMobile ? '100%' : '560',
      hegiht: isMobile ? '250' : '315'
    };
  });

  const ServiceGuide = () => {
    return isMobile ? null : (
      <ul>
        <li>
          {' 주주서비스 이용고객이라면?'}
          <SerBtnBlock onClick={() => history.push('/shareholder-guide')}>
            주주서비스 이용안내
          </SerBtnBlock>
        </li>
        <li>
          {' 발행회사서비스 이용고객이라면?'}
          <SerBtnBlock
            onClick={() => history.push('/issue-incorporated-guide')}
          >
            발행회사서비스 이용안내
          </SerBtnBlock>
        </li>
      </ul>
    );
  };

  const onClickVideo = (index) => {
    const target = Videos.find((item, idx) => item.index === index);
    setSelVideoIndex(target.index);
  };

  return (
    <Page logoPlace={LOGO_PLACE.NONE}>
      {isMobile && <PageHeader title="서비스 소개" />}
      <SectionBlock>
        <SerInnerBlock>
          <SerContentBlock>
            <h2>
              <p>블록체인 전자주총 서비스 소개</p>
            </h2>
            <SerTxt02Block>
              <p>DID와 블록체인 기술로 주주총회의 새로운 지평을 열다.</p>
            </SerTxt02Block>
            <FrameBlock>
              <iframe
                display="block"
                width={size.width}
                height={size.hegiht}
                src={`https://www.youtube.com/embed/${Videos[selVideoIndex].source}`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <ExplainBlock>
                {Details[selVideoIndex].title.map((line) => (
                  <span>{line}</span>
                ))}
                {Details[selVideoIndex].subTitle.map((content) => (
                  <p>{content}</p>
                ))}
              </ExplainBlock>
            </FrameBlock>
            <ContentBlock>
              <ThumbnailBlock>
                {Videos.map((item, index) => {
                  return (
                    <li onClick={() => onClickVideo(index)}>
                      <img src={item.src} alt="" />
                      <p>{`${item.title}`}</p>
                    </li>
                  );
                })}
              </ThumbnailBlock>
            </ContentBlock>
            <ServiceGuide />
          </SerContentBlock>
        </SerInnerBlock>
      </SectionBlock>
    </Page>
  );
};

const Details = [
  {
    index: 0,
    title: ['비대면시대 주주총회를', ' 어떻게 진행할까요?'],
    subTitle: [
      '다양한 기업에서 진행중인 비대면 전자 주주총회.',
      '블록체인을 활용한 블록체인 전자주총.'
    ]
  },
  {
    index: 1,
    title: ['블록체인 전자주총 퀵가이드'],
    subTitle: [
      '시간은 물론 비용까지 아껴줘야 요즘 주주총회!',
      '기존 주주총회 소집통지서 배포/위임장/의사록 관리 복잡한 주주총회 준비/행사를 간편하게 진행할 수 있습니다.',
      '주주총회가 신속하고, 편리하고, 안전해 집니다.',
      '세상의 가치를 모으는 한국전자투표과 자본시장 IT 솔루션 리더 코스콤이 함께 합니다.'
    ]
  },
  {
    index: 2,
    title: ['블록체인과 DID 주주증명을 활용한', ' 주주총회 전자투표'],
    subTitle: [
      '주주와 발행회사 모두에게 도움이 되는 블록체인 전자주총입니다.',
      'DID주주증명을 통해 개개인이 개인정보를 안전하고 쉽게 관리 통제할 수 있습니다.',
      'A부터 Z까지 주주총회를 도와주는 블록체인 전자주총.'
    ]
  },
  {
    index: 3,
    title: ['블록체인 전자투표는', ' 신속하고, 편리하고, 안전합니다.'],
    subTitle: [
      'DID주주증명을 활용하여 개인정보 유출걱정 없습니다.',
      '주주는 언제 어디서나 비상장사까지 주주총회에 참여 가능하고,',
      '발행회사는 주주총회 과정을 간편하게 작성 진행 할 수 있습니다.'
    ]
  },
  {
    index: 4,
    title: ['블록체인 전자주총 사용가이드'],
    subTitle: [
      '주주의 투표 방법, 총회 등록 방법,',
      '주주총회 결과 등록 가이드입니다.'
    ]
  }
];

const Videos = [
  {
    index: 0,
    src: 'https://img.youtube.com/vi/B0Jsmw0MGc0/default.jpg',
    source: 'B0Jsmw0MGc0',
    title: '비대면시대 주주총회'
  },
  {
    index: 1,
    src: 'https://img.youtube.com/vi/WT07T4KTzY0/default.jpg',
    source: 'WT07T4KTzY0',
    title: '블록체인 전자주총 가이드'
  },
  {
    index: 2,
    src: 'https://img.youtube.com/vi/NjXgZYrdGGE/default.jpg',
    source: 'NjXgZYrdGGE',
    title: '블록체인 DID 전자주총'
  },
  {
    index: 3,
    src: 'https://img.youtube.com/vi/Br5_VGH7Bxk/default.jpg',
    source: 'Br5_VGH7Bxk',
    title: '전자투표가 필요한이유'
  },
  {
    index: 4,
    src: 'https://img.youtube.com/vi/VXO1bZBR8yg/default.jpg',
    source: 'VXO1bZBR8yg',
    title: '사용 가이드'
  }
];

const FrameBlock = styled.div`
  display: flex;
  margin: 20px 0;
  border-bottom: 1px solid black;
  padding-bottom: 20px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    align-items: center;
    flex-direction: column;
  }
`;

const ExplainBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 50%;
  padding: 50px 50px;
  text-align: left;
  font-size: 27px;
  font-weight: bold;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    width: -webkit-fill-available;
    display: block;
    text-align: center;
    font-size: 21px;
    padding: 40px 4px;
    & > span,
    p {
      word-break: keep-all;
    }
  }
  &&& > p {
    font-size: 17px;
    &:first-of-type {
      margin-top: 20px;
    }
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      font-size: 15px;
    }
  }
`;

const ContentBlock = styled.div`
  padding-bottom: 20px;
  border-bottom: solid 1px lightgray;
`;

const ThumbnailBlock = styled.ul`
  display: flex;
  justify-content: space-evenly;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    flex-wrap: wrap;
    column-gap: 10%;
    justify-content: start;
  }
  & > li {
    flex-direction: column;
    display: flex;
    width: 17%;
    cursor: pointer;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      width: 25%;
    }
    & > p {
      text-align: center;
      margin: 5px 0;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        word-break: keep-all;
      }
    }
  }
`;

const SectionBlock = styled.div`
  // .fp_section
  position: relative;
  box-sizing: border-box;
  height: 100%;
`;

const SerContentBlock = styled.div`
  // .ser_cont
  width: 952px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    width: auto;
  }
  margin: 0 auto;
  // padding 100 -> 200px
  padding: 50px 0;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding: 0;
  }
  & h2 {
    margin: 0 0 20px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      font-size: 15px;
    }
    & span {
      font-size: 38px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        font-size: 34px;
      }
      &.ft-col01 {
        color: #ff9330 !important;
      }
      &.ft-col02 {
        color: #0094ff !important;
      }
    }
    & p {
      margin-top: 12px;
      font-size: 38px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        font-size: 21px;
      }
    }
  }
  & > ul {
    display: block;
    text-align: center;
    & > li {
      display: inline-block;
      width: 340px;
      margin: 32px 5px 28px;
      vertical-align: middle;
      & > p {
        position: relative;
        display: block;
        width: 100%;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 17px;
        line-height: 1.35;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          display: block;
        }
        & > span {
          text-decoration: underline;
        }
      }
    }
    & > li:only-child {
      width: 400px;
    }
  }
`;

const SerTxt02Block = styled.p`
  // .ser_txt02
  text-align: center;
  /* padding-right: 92px; */
  &::before {
    margin-top: -32px;
    right: 0;
    width: 77px;
    height: 75px;
    background: url(${serTxt02Png}) no-repeat;
    background-size: 77px 75px;
  }
  & > p {
    font-size: 20px;
    margin-bottom: 50px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      margin-top: 4px;
      font-size: 15px;
      margin-bottom: 30px;
    }
  }
`;

const SerBtnBlock = styled.button`
  display: block;
  width: 100%;
  box-sizing: border-box;
  line-height: 1.5;
  letter-spacing: -0.5px;
  text-align: center;
  margin-top: 12px;
  height: 44px;
  border-radius: 22px;
  font-size: 17px;
  background-color: #fff;
  border: 1px solid #cad1d7;
  color: #080808;
`;

export default ServiceAboutPage;
