import React from 'react';
import styled from 'styled-components';
import Button, {
  ButtonTypes
} from '../../../../../../components/button/Button';
import ButtonGroup from '../../../../../../components/button/ButtonGroup';
import DataTable, {
  DataType
} from '../../../../../../components/datatable/DataTable';

const ConcentrateErrorPopupBlock = styled.div`
  max-height: 250px;
  overflow-y: auto;
`;

const ConcentrateErrorPopup = ({ dataSource, closeSubModal }) => {
  console.log('dataSource');
  console.log(dataSource);
  const columns = [
    {
      title: '번호',
      key: 'index'
    },
    {
      title: '주주명',
      key: 'stockholderName'
    },
    {
      title: '행사 가능 수 ',
      key: 'amount',
      type: DataType.NUMBER
    },
    {
      title: '행사 수',
      key: 'value',
      type: DataType.NUMBER
    }
  ];

  return (
    <ConcentrateErrorPopupBlock>
      <DataTable columns={columns} dataSource={dataSource} />
      <ButtonGroup isLayer={true}>
        <Button type={ButtonTypes.Primary} onClick={closeSubModal}>
          확인
        </Button>
      </ButtonGroup>
    </ConcentrateErrorPopupBlock>
  );
};

export default ConcentrateErrorPopup;
