import React from 'react';
import styled from 'styled-components';

const ListContentItemBlock = styled.li`
  width: 100%;
  display: flex;
  margin: 30px 0;
  padding-bottom: 0;
`;

const ListItemVertical = ({ children }) => {
  return <ListContentItemBlock>{children}</ListContentItemBlock>;
};

export default ListItemVertical;
