import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import Spinner from '../../../components/alert/Spinner';
import { fetchPostAuthLoginPassCallbackAction } from '../../../services/api/auth/promiseActions';

const PassSignupCallbackPopup = () => {
  const match = useRouteMatch();
  const history = useHistory();
  console.log('[popup]');
  console.log(match);
  console.log(history);

  const dispatch = useDispatch();

  useEffect(() => {
    const params = history.location.search
      .substring(1)
      .split('&')
      .map((item) => {
        const arr = item.split('=');
        return {
          [arr[0]]: arr[1]
        };
      });
    const recCert = history.location.search;
    dispatch(fetchPostAuthLoginPassCallbackAction(recCert)).then((res) => {
      console.log('fetchPostAuthLoginPassCallbackAction');
      console.log(res);
      if (!res.error) {
        if (!isMobile) {
          const targetWindow = window.opener;
          targetWindow.postMessage(JSON.stringify({ ...res, ...params[1] }));
        } else {
          setTimeout(() => {
            window.location.replace(
              'agmapp://pass?type=signup&' +
                recCert.substring(1).substring(1).split('&')[1]
            );
          }, 200);
        }
      }
      window.close();
    });
  }, []);

  return <Spinner isEmptyScreen={true} />;
};

export default PassSignupCallbackPopup;
