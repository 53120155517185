import Slider from 'react-slick';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import { Tab_State_Const } from '../../../../../utils/constant/AgmConstant';

export const SerInnerBlock = styled.div`
  // .ser_inner
  width: 100%;
  // temp
  padding: 30px 0;
  /* padding-top: 30px; */
  //padding-bottom: 80px;
  & > h3 {
    font-size: 28px;
    font-weight: 800;
    text-align: center;
    margin: 28px 0 20px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      font-size: 17px;
      font-weight: 800;
      margin-top: 20px;
      text-align: -webkit-auto;
    }
  }
`;

export const SerStepBlock = styled.div`
  // .ser_step
  max-width: 480px;
  margin: 20px auto 28px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    max-width: 134px;
    margin: -22px 0 26px auto;
  }
  & > ul {
    display: flex;
  }
`;

export const SerStepItemBlock = styled.li`
  flex: 1;
  text-align: center;
  position: relative;
  & a {
    display: inline-block;
    width: 32px;
    height: 32px;
    padding-top: 4px;
    box-sizing: border-box;
    border-radius: 20px;
    border: 2px solid #e9ecf4;
    background-color: #fff;
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    color: #a0a6b5;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      width: 22px;
      padding-top: 1px;
      height: 22px;
      box-sizing: border-box;
      border-radius: 12px;
      border: 2px solid #e9ecf4;
      background-color: #fff;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #a0a6b5;
      position: relative;
      z-index: 1;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: #e9ecf4;
  }
  &:first-child::after {
    left: 50%;
    width: 50%;
  }
  &:last-child::after {
    width: 50%;
  }
  ${(props) =>
    props.state === Tab_State_Const.DONE
      ? css`
          & a {
            border-color: #a0a6b5;
            background-color: #a0a6b5;
            color: #fff;
          }
        `
      : props.state === Tab_State_Const.ING
      ? css`
          & a {
            border-color: #0094ff;
            background-color: #0094ff;
            color: #fff;
            font-weight: 800;
          }
        `
      : css``}
`;

export const SerViewBlock = styled.div`
  // .ser_view
  display: block;
  width: 100%;
  text-align: center;
  position: relative;
  /* & > ul {
    display: inline-block;
  } */
  & > button:last-of-type {
    display: block;
    margin: 30px 0 0 95px;
    position: absolute;
    left: 50%;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      margin: 0;
      position: relative;
      left: 0;
    }
  }
`;

export const SerViewItemBlock = styled.li`
  ${(props) =>
    props.notImportant
      ? css`
          width: 100%;
        `
      : css`
          width: 900px !important;
        `}
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    width: auto !important;
  }
  height: auto;
  text-align: center;
  position: relative;
  display: none;
  ${(props) =>
    props.show &&
    css`
      display: block;
    `}
  & img {
    width: 100%;
    height: auto;
    border: 1px solid #e9ecf4;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      border: none;
    }
  }
  & p {
    margin-top: 15px;
    font-size: 16px;
    line-height: 1.45;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      font-size: 14px;
    }
  }
`;

export const SerViewPrevBtnBlock = styled.button`
  // .prev
  width: 50px;
  height: 50px;
  border-top: 2px solid #66676b;
  border-left: 2px solid #66676b;
  background-color: transparent;
  font-size: 0;
  transform: rotate(-45deg);
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 250px;
  /* top: 180px; */
  left: 23%;
  z-index: 2;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    width: 24px;
    height: 24px;
    top: 150px;
    left: 10%;
  }
`;

export const SerViewNextBtnBlock = styled.button`
  // .next
  width: 50px;
  height: 50px;
  border-top: 2px solid #66676b;
  border-right: 2px solid #66676b;
  background-color: transparent;
  font-size: 0;
  transform: rotate(45deg);
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 250px;
  /* top: 180px; */
  right: 23%;
  z-index: 2;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    width: 24px;
    height: 24px;
    top: 150px;
    right: 10%;
  }
`;

export const StyledSlider = styled(Slider)`
  width: 50%;
  left: 25%;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin-bottom: 50px;
  }
`;
