import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ModalContext } from '../../context/ModalContext';
import { useAuth } from '../../hooks/useAuth';
import {
  CancelBtn,
  OkBtn
} from '../../pages/shareholder/vote/main/components/popup/common/PopupButton';
import { action as commonAction } from '../../store/commonSlice';
import CommonStyles from '../../styles/CommonStyles';
import logoPng from '../../styles/images/common/logo.png';
import spGnbPng from '../../styles/images/common/sprite/sp_gnb3.png';
import { Role } from '../../utils/constant/AgmConstant';
import { GnbMenu, GnbSubMenu, GNB_ID } from '../../utils/constant/GnbMenu';
import { clearRefreshToken, setAccessToken } from '../../utils/requestHelper';
import SignIframe from '../auth/SignIframe';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';

const GnbBlock = styled.section`
  // .gnb
  width: 100%;
  height: auto;
  background-color: rgba(255, 255, 255, 0.94);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: 0.2s linear;
  position: absolute;
  top: 0;
  z-index: 200;
  &:hover {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      box-shadow: none;
    }
  }
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    // 모바일로 로그인했을 때,
    background-color: rgba(255, 255, 255, 0.7);
    height: 88px;
    & > * {
      display: none;
    }
    position: relative;
    width: 180px;
    height: 24px;
    /* z-index: 999; */
    box-shadow: none;
    ${(props) =>
      props.logoPlace === LOGO_PLACE.CENTER &&
      css`
        margin: 64px auto 0;
        padding-top: 70px;
        background: url(${logoPng}) no-repeat center bottom;
        background-size: 100% auto;
      `}
    ${(props) =>
      props.logoPlace === LOGO_PLACE.LEFT &&
      css`
        margin: 64px auto 42px;
        padding-top: 22px;
        background: url(${logoPng}) no-repeat left 24px;
        background-size: 128px auto;
      `}
    ${(props) =>
      props.logoPlace === LOGO_PLACE.NONE &&
      css`
        display: none;
      `}
  }
`;

const GnbInnerBlock = styled.div`
  // .gnb .inner
  max-width: 1360px;
  height: auto;
  margin: 0 auto;
  position: relative;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin-left: 10px;
  }
  & h1 {
    // .gnb .inner h1
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 28px;

    & a {
      // .gnb .inner h1 a
      display: block;
      width: 240px;
      height: 30px;
      background: url(${logoPng}) no-repeat;
      cursor: pointer;
      background-size: 240px 30px;
    }
  }
`;
const MemuWrapBlock = styled.div`
  // .gnb .inner .menu_wrap
  position: relative;
  width: auto;
  height: auto;
  min-height: 88px;
  padding: 0 230px 0 438px;
  text-align: left;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    display: none;
    min-height: 30px;
    padding: 0;
  }
  & ul {
    // .gnb .inner .menu_wrap ul
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 88px;
    height: auto;
  }
  &:hover {
    // .gnb .inner .menu_wrap:hover ul:last-of-type
    & ul:last-of-type {
      display: flex;
    }
  }
  & ul:last-of-type {
    display: none;
    align-items: baseline;
    justify-content: center;
    padding-bottom: 30px;
    & > li > a {
      height: auto;
      font-size: 15px;
      font-weight: normal;
      transform: skew(-0.04deg);
      margin-bottom: 22px;
      opacity: 1;
    }
    & > li > a:hover {
      color: #0094ff;
    }
  }

  & ul {
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      display: none;
    }
    & li {
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        display: none;
      }
    }
  }
`;

const MenuWrapLiBlock = styled.li`
  // .gnb .inner .menu_wrap ul > li
  flex: 1;
  // .gnb .inner .menu_wrap ul > li:nth-child(2)
  /* &:nth-child(2) {
    flex: 0.9;
  } */
  // .gnb .inner .menu_wrap ul > li:nth-child(4)
  &:nth-child(4) {
    flex: 0.7;
  }
  // .gnb .inner .menu_wrap ul > li > a
  & > a {
    display: inline-block;
    width: 100%;
    height: 100%;
    min-width: auto;
    font-size: 20px;
    font-weight: bold;
    opacity: 0.6;
    transition: 0.2s linear;
    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -ms-transition: 0.2s linear;
    color: #080808;

    ${(props) =>
      props.$active &&
      // .gnb .inner .menu_wrap ul > li.on > a
      css`
        opacity: 1;
      `}
    &:hover {
      // .gnb .inner .menu_wrap ul > li > a:hover
      opacity: 1;
    }
  }
`;
const UntilAreaLiBlock = styled.li`
  float: left;
  display: block;
  text-align: center;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    display: none;
  }
  &:first-child a {
    width: auto;
    padding-top: 12px;
    margin-right: 10px;
    height: 38px;
    font-size: 13px;
    text-align: right;
    background: none;
    position: relative;
    & span {
      color: ${CommonStyles.THEME_COLOR};
      font-weight: bold;
    }
  }
  a {
    display: block;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    background: url(${spGnbPng}) no-repeat;
    background-size: 80px auto;
    ${(props) =>
      props.logout
        ? css`
            background-position: 0 -40px;
          `
        : css`
            background-position: 0 0;
          `}
    ${(props) =>
      props.allmenu &&
      css`
        background-position: -40px 0;
      `}
  }
`;

const UntilAreaBlock = styled.ul`
  position: absolute;
  top: 28px;
  right: 0;
  z-index: 1;
`;

const SubMenuLink = styled(Link)`
  &&& {
    ${(props) => {
      return (
        props.$active &&
        css`
          color: #0094ff;
        `
      );
    }};
  }
`;
const LogoutBtn = ({ onClick }) => {
  return <Link to="#" onClick={onClick} />;
};

const Gnb = ({ logoPlace = LOGO_PLACE.NONE }) => {
  let match = useRouteMatch();
  const dispatch = useDispatch();
  const { user, gnb } = useSelector((state) => state.common);
  const { currentGnb } = gnb;
  const gnbMenuList = GnbMenu[currentGnb];
  const gnbSubMenuList = GnbSubMenu[currentGnb];
  let { openModal, setModalConfig, closeModal } = useContext(ModalContext);
  let history = useHistory();
  const Block_URL = ['/corp/agm/register/1', '/corp/agm/register/1/test'];
  const { changeRole } = useAuth();

  const logoutCallback = () => {
    closeModal();
    dispatch(commonAction.setCurrentUser(null));
    setAccessToken(null);
    clearRefreshToken();
    clearInterval(window.tokenRefresh);
    dispatch(commonAction.setCurrentGnb(GNB_ID.COMMON));
    history.push('/login');
  };

  const logout = () => {
    setModalConfig({
      buttons: [
        <CancelBtn onClick={closeModal} />,
        <OkBtn onClick={logoutCallback} />
      ]
    });
    openModal('로그아웃', '로그아웃 하시겠습니까?');
  };

  const getHomeURL = () => {
    if (!user.isAuth) {
      return '/';
    } else {
      switch (user.role) {
        case Role.ISSINC_ADMIN.code:
        case Role.VIEW_ISSINC_ADMIN.code:
          return '/corp/agm/dashboard';
        case Role.INDIVIUDAL_SHAREHOLDER.code:
        case Role.CORP_SHAREHOLDER.code:
          return '/shareholder/my-agms';
        default:
          return '/';
      }
    }
  };

  return (
    <>
      <GnbBlock logoPlace={logoPlace}>
        <GnbInnerBlock>
          <h1 style={{ cursor: 'pointer', zIndex: 999 }}>
            <Link to={getHomeURL()} />
          </h1>
          <MemuWrapBlock>
            <ul>
              {gnbMenuList &&
                gnbMenuList.map((menu, idx) => (
                  <MenuWrapLiBlock
                    key={`menu_${idx}`}
                    $active={
                      menu.matchUrl
                        ? menu.matchUrl.includes(match.path)
                        : menu.id === menu.path
                    }
                  >
                    {user?.role === Role.VIEW_ISSINC_ADMIN.code &&
                    Block_URL.includes(menu.url) ? (
                      <GnbATagBlock
                        onClick={() => changeRole(Role.ISSINC_ADMIN.code)}
                      >
                        {menu.title}
                      </GnbATagBlock>
                    ) : (
                      <Link to={menu.url}>{menu.title}</Link>
                    )}
                  </MenuWrapLiBlock>
                ))}
            </ul>
            <ul>
              {gnbSubMenuList &&
                gnbSubMenuList.map((subMenu, idx) => {
                  return (
                    <MenuWrapLiBlock key={`sub-menu-wrap-${idx}`}>
                      {subMenu.map((item, itemIdx) => {
                        return user?.role === Role.VIEW_ISSINC_ADMIN.code &&
                          Block_URL.includes(item.url) ? (
                          <GnbATagBlock
                            onClick={() => changeRole(Role.ISSINC_ADMIN.code)}
                          >
                            {item.title}
                          </GnbATagBlock>
                        ) : (
                          <SubMenuLink
                            key={`sub-menu-${idx}-${itemIdx}`}
                            $active={
                              item.matchUrl
                                ? item.matchUrl.includes(match.path)
                                : match.path === item.url
                            }
                            to={item.url}
                          >
                            {item.title}
                          </SubMenuLink>
                        );
                      })}
                    </MenuWrapLiBlock>
                  );
                })}
            </ul>
          </MemuWrapBlock>
          <UntilAreaBlock>
            <UntilAreaLiBlock>
              {user.isAuth &&
                (user.role === Role.VIEW_ISSINC_ADMIN.code ? (
                  <Link
                    to={`/${gnb.currentGnb}/info`}
                    style={{ paddingTop: 0 }}
                  >
                    <>
                      <span style={{ fontSize: '16px' }}>조회용</span>
                      <br />
                      <span>{user.userName}</span>
                    </>
                    님 환영합니다
                  </Link>
                ) : (
                  <Link to={`/${gnb.currentGnb}/info`}>
                    <span>{user.userName}</span>님 환영합니다
                  </Link>
                ))}
            </UntilAreaLiBlock>
            <UntilAreaLiBlock logout={user.isAuth}>
              {user.isAuth ? (
                <LogoutBtn onClick={logout} />
              ) : (
                <Link to="/login"></Link>
              )}
            </UntilAreaLiBlock>
          </UntilAreaBlock>
        </GnbInnerBlock>
      </GnbBlock>
      <SignIframe />
    </>
  );
};

const GnbATagBlock = styled.a`
  z-index: 999;
  cursor: pointer;
`;

export const LOGO_PLACE = {
  CENTER: 'center',
  LEFT: 'left',
  NONE: 'none'
};

export default Gnb;
