import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import {
  fetchGetENoticeAgmSendDetailAction,
  fetchGetENoticeAgmTypeDetailAction
} from '../../../../../../services/api/agm/promiseActions';
import { ElecNoticeType } from '../../../../../../utils/constant/AgmConstant';
import NoticeAgm from './component/NoticeAgm';
import NotRcvdList from './component/NotRcvdList';

const ElecBillPage = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { agmSeqno, sendSeqno } = match.params;
  const [info, setInfo] = useState();
  useEffect(() => {
    const action = !!sendSeqno
      ? fetchGetENoticeAgmSendDetailAction
      : fetchGetENoticeAgmTypeDetailAction;
    const data = !!sendSeqno
      ? { agmSeqno: agmSeqno, sendSeqno: sendSeqno }
      : { agmSeqno: agmSeqno, type: ElecNoticeType.ELEC_BILL.code };
    dispatch(action(data)).then((res) => {
      if (!res.error) {
        //const { electronicNotice } = res.data[0]; // 현재는 전자고지서가 1개밖에 없기 때문에
        // setElectronicNotice(electronicNotice);
        const data = !!sendSeqno ? res.data : res.data[0];
        setInfo(data);
      }
    });
  }, [dispatch]);
  return (
    <>
      {info && (
        <>
          <NoticeAgm agmSeqno={agmSeqno} electronicNotice={info} />
          <NotRcvdList agmSeqno={agmSeqno} sendSeqno={info?.sendSeqno} />
        </>
      )}
    </>
  );
};

export default ElecBillPage;
