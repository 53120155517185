import { css } from 'styled-components';

// Media Query
export const SCREEN_SIZE_PC = 1200;
export const SCREEN_SIZE_TABLET = 992;
export const SCREEN_SIZE_MOBILE = 768;

// default button
export const btnType01 = css`
  &&& {
    display: inline-block;
    min-width: 128px;
    padding: 12px 20px 11px 20px;
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 1.33;
    letter-spacing: -0.5px;
    text-align: center;
    border: none;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      display: inline-block;
      min-width: 74px;
      padding: 9px 10px 8px;
      height: 34px;
      border-radius: 4px;
      box-sizing: border-box;
      letter-spacing: -0.5px;
      text-align: center;
    }
  }
`;
export const btnType02 = css`
  &&& {
    display: inline-block;
    padding: 4px 18px 4px 18px;
    //border: solid 1px #8c939c;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 2;
    text-align: center;
  }
`;
export const btnType03 = css`
  &&& {
    display: inline-block;
    min-width: 300px;
    padding: 22px 40px 22px 40px;
    border-radius: 4px;
    box-sizing: border-box;
    //border: solid 2px #0572ef;
    font-size: 18px;
    font-weight: bold;
    //color: #0572ef;
    line-height: 1.54;
    letter-spacing: -0.5px;
    text-align: center;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      display: inline-block;
      width: 100%;
      padding: 11px 14px 10px;
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -0.5px;
      text-align: center;
    }
  }
`;
export const btnType04 = css`
  &&& {
    display: inline-block;
    min-width: 300px;
    padding: 12px 40px 12px 40px;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.54;
    letter-spacing: -0.5px;
    text-align: center;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      display: inline-block;
      width: 100%;
      padding: 11px 14px 10px;
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -0.5px;
      text-align: center;
    }
  }
`;

export const btnType05 = css`
  &&& {
    display: inline-block;
    padding: 5px 20px 5px 20px;
    border-radius: 5px;
    box-sizing: border-box;
    min-width: 110px !important;
    line-height: 2;
    text-align: center;
  }
`;

export const btnType06 = css`
  &&& {
    display: inline-block;
    min-width: 120px !important;
    padding: 16px 20px 15px 20px;
    border-radius: 8px;
    box-sizing: border-box;
    line-height: 1.33;
    letter-spacing: -0.5px;
    text-align: center;
  }
`;

export const btnType07 = css`
  &&& {
    display: inline-block;
    width: auto;
    min-width: 200px;
    margin-left: 4px;
    padding: 12px 20px 11px 20px;
    border-radius: 4px;
    line-height: 1.33;
    letter-spacing: -0.5px;
    text-align: center;
  }
`;
