import { all, takeLatest } from '@redux-saga/core/effects';
import APIs from '..';
import createRequestSaga from '../../../utils/createRequestSaga';
import { fetchGetExternalOrganizationsAction } from './promiseActions';

/**
 * 법인번호 기업 조회
 */

// 법인번호 기업 조회
const fetchGetExternalOrganizationsSaga = createRequestSaga(
  fetchGetExternalOrganizationsAction,
  APIs.Externals.fetchGetExternalOrganizations
);

export default function* saga() {
  yield all([
    takeLatest(
      fetchGetExternalOrganizationsAction,
      fetchGetExternalOrganizationsSaga
    )
  ]);
}
