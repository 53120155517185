import React from 'react';
import styled, { css } from 'styled-components';
import spCommonPng from '../../styles/images/common/sprite/sp_common.png';

const AnchorBlock = styled.a``;
const StepBlock = styled.li`
  // .step_ty > ul > li
  flex: 1;
  text-align: center;
  & ${AnchorBlock} {
    // .step_ty > ul > li a
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 30px 0 35px;
    background-color: transparent;
    font-size: 20px;
    letter-spacing: -0.5px;
    text-align: center;
    color: #caccd7;
  }
  & ${AnchorBlock}::after {
    // .step_ty > ul > li a::after
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    margin-left: -15px;
    display: block;
    background: #fff url(${spCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -43px -1px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    z-index: 2;
  }
  ${(props) =>
    props.complete &&
    css`
      & ${AnchorBlock} {
        // .step_ty > ul > li.on a
        color: #66676b;
        font-weight: bold;
      }
      & ${AnchorBlock}::after {
        // .step_ty > ul > li.on a::after
        background-position: -43px -61px;
      }
    `}
  ${(props) =>
    props.active &&
    css`
      & ${AnchorBlock} {
        // .step_ty > ul > li.on a
        color: #0572ef;
        font-weight: bold;
      }
      & ${AnchorBlock}::after {
        // .step_ty > ul > li.on a::after
        background-position: -43px -31px;
      }
    `}
`;
const StepsBlock = styled.div`
  // .step_ty
  ${(props) =>
    props.wide
      ? css`
          max-width: 1360px;
        `
      : css`
          max-width: 1020px;
        `}

  margin: 0 auto 20px;
  & > ul {
    // .step_ty > ul
    position: relative;
    display: flex;
  }
`;

const ProgressBlock = styled.div`
  // .progress
  width: 100%;
  height: 3px;
  overflow: hidden;
  background-color: #f2f3f6;
  border-radius: 2px;
  position: relative;
  z-index: 1;
  position: inherit;
`;

const ProgressBarBlock = styled.div`
  float: left;
  ${(props) =>
    props.value
      ? css`
          width: ${props.value}%;
        `
      : css`
          width: 0%;
        `};
  height: 100%;
  background-color: #0572ef;

  ${(props) =>
    props.complete &&
    !props.active &&
    css`
      background-color: #66676b;
    `}
`;

const Steps = (props) => {
  const { stepInfo, currentStep, wide = false } = props;

  return (
    <StepsBlock wide={wide}>
      <ul>
        {stepInfo.map((step, idx) => (
          <StepBlock
            key={`step_${idx}`}
            active={Number(currentStep) === step.stepSeq}
            complete={step.stepSeq < Number(currentStep)}
          >
            {/* <AnchorBlock href="#none" onClick={() => moveStep(step.stepSeq)}> */}
            <AnchorBlock href="#none">{step.title}</AnchorBlock>

            <ProgressBlock>
              <ProgressBarBlock
                value={step.stepSeq <= Number(currentStep) ? 100 : 0}
                complete={step.stepSeq < Number(currentStep)}
                active={Number(currentStep) === step.stepSeq}
              >
                <span>{step.value}</span>
              </ProgressBarBlock>
            </ProgressBlock>
          </StepBlock>
        ))}
      </ul>
    </StepsBlock>
  );
};

export default Steps;
