import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CheckBox from '../../../../components/input/CheckBox';
import Input, { ErrorTypes } from '../../../../components/input/Input';
import InputBox from '../../../../components/input/InputBox';
import ListItem from '../../../../components/layout/ListItem';
import { Role } from '../../../../utils/constant/AgmConstant';
import { getCurrentDatetime } from '../../../../utils/DateUtil';
import Validation from '../../../../utils/ValidationUtil';
import { AgreeType } from '../../../IssueIncorporated/contract/IssueIncorporated';

const CorporationManager = (props) => {
  const {
    register,
    agreeTimeMap,
    setAgreeTimeMap,
    errors,
    isAgmRegister = false
  } = props;
  const role = useSelector((state) => state?.common?.user?.role);

  useEffect(() => {
    if (role === Role.VIEW_ISSINC_ADMIN.code) {
      setTimeout(() => {
        document
          .getElementsByTagName('input')
          .forEach((item) => (item.disabled = true));
        document
          .getElementsByTagName('select')
          .forEach((item) => (item.disabled = true));
      }, 100);
    }
  }, []);

  const AGREE_TYPE = {
    CELL: '1',
    EMAIL: '2'
  };

  const onChangeCheck = (state, value) => {
    if (value === AGREE_TYPE.CELL) {
      setAgreeTimeMap({
        ...agreeTimeMap,
        [AgreeType.CELLPH_NOTI_AGREE_DTTM]: state ? getCurrentDatetime() : null
      });
    } else if (value === AGREE_TYPE.EMAIL) {
      setAgreeTimeMap({
        ...agreeTimeMap,
        [AgreeType.EMAIL_NOTI_AGREE_DTTM]: state ? getCurrentDatetime() : null
      });
    }
  };

  return (
    <>
      <ListItem>
        <InputBox
          title="이름"
          isRequired
          error={errors?.personInCharge?.name?.message}
          errorType={ErrorTypes.ERROR}
        >
          <Input
            register={register}
            name="personInCharge.name"
            registerOption={Validation.NAME}
            placeholder="이름을 입력하세요"
          />
        </InputBox>
        <InputBox
          title="직위"
          error={errors?.personInCharge?.position?.message}
          errorType={ErrorTypes.ERROR}
        >
          <Input
            register={register}
            name="personInCharge.position"
            placeholder="직위"
          />
        </InputBox>
      </ListItem>
      <ListItem>
        <InputBox title="부서">
          <Input
            register={register}
            name="personInCharge.department"
            placeholder="부서"
          />
        </InputBox>
        <InputBox
          title="전화번호"
          isRequired
          error={errors?.personInCharge?.telephone?.message}
          errorType={ErrorTypes.ERROR}
        >
          <Input
            register={register}
            name="personInCharge.telephone"
            registerOption={Validation.TEL}
            placeholder="숫자만 입력 가능합니다."
          />
        </InputBox>
      </ListItem>
      <ListItem>
        <InputBox
          title="휴대폰번호"
          isRequired
          error={errors?.personInCharge?.cell?.message}
          errorType={ErrorTypes.ERROR}
        >
          <Input
            register={register}
            name="personInCharge.cell"
            registerOption={Validation.PHONE}
            placeholder="숫자만 입력 가능합니다."
          />
          {isAgmRegister || (
            <CheckBox
              title="문자 알림 동의"
              value={AGREE_TYPE.CELL}
              onChange={onChangeCheck}
              checked={agreeTimeMap[AgreeType.CELLPH_NOTI_AGREE_DTTM]}
            />
          )}
        </InputBox>
        <InputBox
          title="이메일"
          isRequired
          error={errors?.personInCharge?.email?.message}
          errorType={ErrorTypes.ERROR}
        >
          <Input
            register={register}
            name="personInCharge.email"
            registerOption={Validation.EMAIL}
            placeholder="이메일 형식을 맞추어 입력하세요"
          />
          {isAgmRegister || (
            <CheckBox
              title="이메일 알림 동의"
              value={AGREE_TYPE.EMAIL}
              onChange={onChangeCheck}
              checked={agreeTimeMap[AgreeType.EMAIL_NOTI_AGREE_DTTM]}
            />
          )}
        </InputBox>
      </ListItem>
    </>
  );
};

export default CorporationManager;
