import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { idpwsign, SIGN_TYPE } from '../../../components/auth/SignIframe';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import CheckBox from '../../../components/input/CheckBox';
import Input, { InputSize } from '../../../components/input/Input';
import InputBox from '../../../components/input/InputBox';
import PhoneInput from '../../../components/input/PhoneInput';
import Label from '../../../components/label/Label';
import ListHeader from '../../../components/layout/ListHeader';
import ListItem from '../../../components/layout/ListItem';
import Section from '../../../components/layout/Section';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import { ModalContext } from '../../../context/ModalContext';
import {
  fetchGetUsersMeAction,
  fetchPutUsersCorpAction
} from '../../../services/api/users/promiseActions';
import { getCurrentDatetime } from '../../../utils/DateUtil';
import { makeCellObject, makeCellString } from '../../../utils/ObjectUtil';
import AgreeBox from './AgreeBox';

const CorpInfo = () => {
  const dispatch = useDispatch();
  let { openModal } = useContext(ModalContext);
  const { register, handleSubmit, getValues, reset } = useForm();
  const [checkedEmail, setCheckedEmail] = useState(false);
  const [checkedSms, setCheckedSms] = useState(false);
  const [prevCellNotiAgreeDate, setPrevCellNotiAgreeDate] = useState(null);
  const [prevEmailNotiAgreeDate, setPrevEmailNotiAgreeDate] = useState(null);
  const [certRegisted] = useState(true);
  useEffect(() => {
    const fetchFunc = async () => {
      const result = await dispatch(fetchGetUsersMeAction());
      if (!result.error) {
        const corpUser = result.data.corpUser;
        const data = {
          ...result.data,
          ...makeCellObject(corpUser.personInChargeCell)
        };
        reset(data);
        setPrevCellNotiAgreeDate(result.data.cellphNotificationAgreeDateTime);
        setPrevEmailNotiAgreeDate(result.data.emailNotificationAgreeDateTime);

        // Sms 수신 동의 여부
        if (result.data.cellphNotificationAgreeDateTime) {
          setCheckedSms(true);
        }
        // Email 수신 동의 여부
        if (result.data.emailNotificationAgreeDateTime) {
          setCheckedEmail(true);
        }
      } else {
        const errMessage = !!result.error?.error
          ? result.error?.error
          : result.error?.user_message;
        openModal('에러', errMessage);
      }
    };
    fetchFunc();
  }, []);
  const onUpdate = (signData, payload) => {
    const updateData = {
      signData
    };
    dispatch(fetchPutUsersCorpAction(updateData)).then((res) => {
      if (res.meta.status === 201) {
        openModal('확인', '정보 수정이 정상적으로 처리되었습니다');
      } else {
        if (res.error) {
          openModal('확인', res.error.user_message);
        } else {
          openModal('확인', '변경에 실패했습니다');
        }
      }
    });
  };

  const openSignModal = (handler, payload) => {
    const { userName, corpUser, frontCell, middleCell, endCell } = payload;
    setTimeout(() => {
      const data = {
        userName,
        cellphNotificationAgreeDateTime: !checkedSms
          ? null
          : prevCellNotiAgreeDate
          ? prevCellNotiAgreeDate
          : getCurrentDatetime(),
        emailNotificationAgreeDateTime: !checkedEmail
          ? null
          : prevEmailNotiAgreeDate
          ? prevEmailNotiAgreeDate
          : getCurrentDatetime(),
        marketingAgreeDateTime: null, //'2021-09-22T17:00:00',
        corpUser: {
          institutionalInvestor: false,
          personInChargeName: corpUser.personInChargeName,
          personInChargePositionName: corpUser.personInChargePositionName,
          personInChargeDepartmentName: corpUser.personInChargeDepartmentName,
          personInChargeCell: makeCellString([frontCell, middleCell, endCell]),
          personInChargeEmailAddress: corpUser.emailAddress
        }
      };

      idpwsign(SIGN_TYPE.CORP, handler, data);
    }, 0);
  };

  return (
    <Page>
      <PageHeader title="내 정보 수정" />
      <Section>
        <ListHeader title="법인 가입정보" isRequired />
        <ListItem>
          <InputBox title="사업자등록번호" isRequired>
            <Input
              register={register}
              name="corpUser.bizRegistrationNumber"
              size={InputSize.MEDIUM}
            />
          </InputBox>
          <InputBox isEmpty />
        </ListItem>
        <ListItem>
          <InputBox title="인증수단" isRequired>
            <Label
              defaultMessage="공동인증서 미등록"
              okMessage="공동인증서 등록 완료"
              isOk={certRegisted}
            />
            {!certRegisted && (
              <Button type={ButtonTypes.Primary}>공동인증서 등록</Button>
            )}
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox title="법인 명" isRequired>
            <Input
              register={register}
              name="userName"
              size={InputSize.MEDIUM}
            />
            <CheckBox
              register={register}
              name="a3"
              title="기관투자자"
              value="testValue"
              checked={
                getValues('corpUser.institutionalInvestor') === true
                  ? true
                  : false
              }
            />
          </InputBox>
          <InputBox isEmpty />
        </ListItem>
      </Section>
      <Section>
        <ListHeader title="담당자 정보" isRequired />
        <ListItem>
          <InputBox title="담당자 이름" isRequired>
            <Input
              register={register}
              name="corpUser.personInChargeName"
              size={InputSize.MEDIUM}
            />
          </InputBox>
          <InputBox isEmpty />
        </ListItem>
        <ListItem>
          <InputBox title="휴대폰번호" isRequired isVertical>
            <PhoneInput
              register={register}
              name="corpUser.personInChargeCell"
            />
            <CheckBox
              register={register}
              noMargin
              name="cellphNotificationAgreeDateTime"
              title="문자 알림 동의"
              value="testValue"
              checked={
                getValues('cellphNotificationAgreeDateTime') !== null
                  ? true
                  : false
              }
            />
          </InputBox>
          <InputBox isEmpty />
        </ListItem>
        <ListItem>
          <InputBox title="담당자 직위" isOptional>
            <Input
              register={register}
              name="corpUser.personInChargePositionName"
              size={InputSize.MEDIUM}
            />
          </InputBox>
          <InputBox title="담당자 부서" isOptional>
            <Input
              register={register}
              name="corpUser.personInChargeDepartmentName"
              size={InputSize.MEDIUM}
            />
          </InputBox>
        </ListItem>
        <ListItem>
          <AgreeBox
            setCheckedEmail={setCheckedEmail}
            setCheckedSms={setCheckedSms}
            checkedSms={checkedSms}
            checkedEmail={checkedEmail}
          />
        </ListItem>
      </Section>
      <ButtonGroup isPage>
        <Button
          type={ButtonTypes.Primary}
          size={ButtonSize.Large}
          onClick={handleSubmit((data) => {
            openSignModal(onUpdate, data);
          })}
        >
          수정
        </Button>
      </ButtonGroup>
    </Page>
  );
};

export default CorpInfo;
