import React, { useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import Page from '../../../components/page/Page';
import SubMenu from '../../../components/page/SubMenu';
import AgmResult from './agmResult/AgmResult';
import AgmShortDesc from './agmShortDesc/AgmShortDesc';
import CountingResult from './countingResult/CountingResult';
import DiverseExercise from './diverseExercise/DiverseExercise';
import ElectronicBill from './elecBill/ElectronicBill';

const AgmPage = () => {
  const match = useRouteMatch();
  const agmSeqno = match.params.agmSeqno;
  const [baseInfo, setBaseInfo] = useState();
  return (
    <Page>
      {agmSeqno && <SubMenu agmSeqno={agmSeqno} setBaseInfo={setBaseInfo} />}
      <Switch>
        {/* 주총총회 관리 - 주주총회 정보 */}
        <Route
          path="/corp/agm/info/:agmSeqno/agm-short-desc"
          render={() => !!baseInfo && <AgmShortDesc baseInfo={baseInfo} />}
        />
        {/* 주주총회 관리 - 전자고지서 */}
        <Route
          path="/corp/agm/info/:agmSeqno/electronic-billings"
          component={ElectronicBill}
        />
        {/* 주총총회 관리 - 의결권 불통일 행사 내역 */}
        <Route
          path="/corp/agm/info/:agmSeqno/diverse-exercise"
          component={DiverseExercise}
        />
        {/* 주총총회 관리 - 전자투표/위임장 결과 */}
        <Route
          path="/corp/agm/info/:agmSeqno/counting-result"
          component={CountingResult}
        />
        {/* 주총총회 관리 - 주주총회 결과 */}
        <Route
          path="/corp/agm/info/:agmSeqno/agm-result"
          component={AgmResult}
        />
      </Switch>
    </Page>
  );
};

export default AgmPage;
