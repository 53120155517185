import { useEffect, useState } from 'react';
import styled from 'styled-components';
import DateInput from '../../../../../../../components/input/DateInput';
import InputBox from '../../../../../../../components/input/InputBox';
import Radio from '../../../../../../../components/input/Radio';
import RadioGroup from '../../../../../../../components/input/RadioGroup';
import TimeInput from '../../../../../../../components/input/TimeInput';
import { TableTitleBlock } from '../../../../../../../components/table/TableTitle';
import {
  SendPlatForm,
  ReceiverType,
  SendTimeType
} from '../../../../../../../utils/constant/AgmConstant';
import {
  checkInvalidDate,
  dateTimeToISOString
} from '../../../../../../../utils/DateUtil';
import { ListTypeBlock, UnitTitle } from '../layout/LayoutWrapper';

const SendOption = (props) => {
  const { register, setValue, electronicNotice } = props;
  const [sendOpt, setSendOpt] = useState();
  const [sendDateTime, setSendDateTime] = useState({
    sendDate: '',
    sendTime: ''
  });
  const [sendPlatformType, setSendPlatformType] = useState(
    SendPlatForm.KAKAO.code
  );
  const [receiverType, setReceiverType] = useState();

  useEffect(() => {
    if (sendOpt === SendTimeType.IMMEDIATE.value) {
      setValue('reservationDatetime', null);
    }
  }, [sendOpt]);

  useEffect(() => {
    if (sendOpt === SendTimeType.RESERVE.value) {
      setValue(
        'reservationDatetime',
        dateTimeToISOString(sendDateTime.sendDate, sendDateTime.sendTime)
      );
    }
  }, [sendDateTime]);

  useEffect(() => {
    if (!!electronicNotice) {
      setSendOpt(
        electronicNotice.reservationDatetime
          ? SendTimeType.RESERVE.value
          : SendTimeType.IMMEDIATE.value
      );
      setSendPlatformType(electronicNotice.intermediator);
      setReceiverType(electronicNotice.receiverType);
    }
  }, [electronicNotice]);

  const callbackFunc = (date, name) => {
    if (!checkInvalidDate(date)) {
      setSendDateTime((prev) => ({
        ...prev,
        [`${name}`]: date
      }));
    }
  };

  const onChangeSendTimeType = (value) => {
    !!value && setSendOpt(value);
  };

  const onChangeReceiverType = (value) => {
    !!value && setReceiverType(value);
  };

  return (
    <>
      <TableTitleBlock>
        <span>주주총회 소집 통지서 발송</span>
      </TableTitleBlock>
      <ListTypeBlock>
        <ul>
          <li>
            <UnitTitle>
              <span>발송 일시 </span>
              <strong>(필수)</strong>
            </UnitTitle>
          </li>
          <li>
            <InputBox noFlex>
              <RadioGroup
                register={register}
                name="sendTimeType"
                selectedValue={sendOpt}
                onChange={onChangeSendTimeType}
              >
                <RadioWrapper
                  label={SendTimeType.IMMEDIATE.text}
                  value={SendTimeType.IMMEDIATE.value}
                />
                <RadioWrapper
                  label={SendTimeType.RESERVE.text}
                  value={SendTimeType.RESERVE.value}
                />
              </RadioGroup>
              {sendOpt === SendTimeType.RESERVE.value && (
                <>
                  <DateInput
                    name="sendDate"
                    callback={callbackFunc}
                    defaultValue={sendDateTime.sendDate}
                  />
                  <TimeInput
                    name="sendTime"
                    callback={callbackFunc}
                    defaultValue={sendDateTime.sendTime}
                  />
                  <DescSpanBlock>
                    전자주총일 이전에만 발송 가능합니다.
                  </DescSpanBlock>
                </>
              )}
            </InputBox>
          </li>
        </ul>
        <ul>
          <li>
            <UnitTitle>
              <span>사용 플랫폼 </span>
              <strong>(필수)</strong>
            </UnitTitle>
          </li>
          <li>
            <InputBox noFlex>
              <RadioWrapper
                label="카카오톡"
                value={sendPlatformType}
                selectedValue={sendPlatformType}
                register={register}
                name="intermediator"
              />
            </InputBox>
          </li>
        </ul>
        <ul>
          <li>
            <UnitTitle>
              <span>발송 주주 </span>
              <strong>(필수)</strong>
            </UnitTitle>
          </li>
          <li>
            <RadioGroup
              register={register}
              name="receiverType"
              selectedValue={receiverType}
              onChange={onChangeReceiverType}
            >
              <RadioWrapper
                label={ReceiverType.ALL.name}
                value={ReceiverType.ALL.code}
              />
              <RadioWrapper
                label={ReceiverType.MIN_1_PERCENT.name}
                value={ReceiverType.MIN_1_PERCENT.code}
              />
            </RadioGroup>
          </li>
        </ul>
      </ListTypeBlock>
    </>
  );
};

const DescSpanBlock = styled.span`
  // .desc .ft-col03
  position: absolute;
  right: 0;
  top: 3rem;
  display: block;
  margin-top: 8px;
  padding-left: 20px;
  font-size: 13px;
  color: #ec2a1e !important;
  line-height: 1.33;
`;

const RadioWrapper = styled(Radio)`
  min-width: 150px;
`;

export default SendOption;
