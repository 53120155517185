import { all, takeLatest } from '@redux-saga/core/effects';
import { takeEvery } from 'redux-saga/effects';
import APIs from '..';
import createRequestSaga from '../../../utils/createRequestSaga';
import {
  fetchPostUsersIndividualAction,
  fetchPutUsersIndividualRealNameNumberAction,
  fetchPostUsersCorpAction,
  fetchGetUsersMeAction,
  fetchPutUsersCorpAction,
  fetchPutUsersIndividualAction,
  fetchPostUsersIndividualAuthenticationMethodAction
} from './promiseActions';

/**
 * 사용자 가입
 */

// 개인 주주가입
const fetchPostUsersIndividualSaga = createRequestSaga(
  fetchPostUsersIndividualAction,
  APIs.Users.fetchPostUsersIndividual
);
// 개인 주주가입
const fetchPutUsersIndividualSaga = createRequestSaga(
  fetchPutUsersIndividualAction,
  APIs.Users.fetchPutUsersIndividual
);
// 개인주주 실명번호 등록
const fetchPutUsersIndividualRealNameNumberSaga = createRequestSaga(
  fetchPutUsersIndividualRealNameNumberAction,
  APIs.Users.fetchPutUsersIndividualRealNameNumber
);
// 법인 주주 가입
const fetchPostUsersCorpSaga = createRequestSaga(
  fetchPostUsersCorpAction,
  APIs.Users.fetchPostUsersCorp
);
// 내정보 조회
const fetchGetUsersMeSaga = createRequestSaga(
  fetchGetUsersMeAction,
  APIs.Users.fetchGetUsersMe
);
// 법인 주주 정보 수정
const fetchPutUsersCorpSaga = createRequestSaga(
  fetchPutUsersCorpAction,
  APIs.Users.fetchPutUsersCorp
);
// 인증수단 추가
const fetchPostUsersIndividualAuthenticationMethodSaga = createRequestSaga(
  fetchPostUsersIndividualAuthenticationMethodAction,
  APIs.Users.fetchPostUsersIndividualAuthenticationMethod
);

export default function* saga() {
  yield all([
    takeLatest(fetchPostUsersIndividualAction, fetchPostUsersIndividualSaga),
    takeLatest(fetchPutUsersIndividualAction, fetchPutUsersIndividualSaga),
    takeLatest(
      fetchPutUsersIndividualRealNameNumberAction,
      fetchPutUsersIndividualRealNameNumberSaga
    ),
    takeLatest(fetchPostUsersCorpAction, fetchPostUsersCorpSaga),
    takeEvery(fetchGetUsersMeAction, fetchGetUsersMeSaga),
    takeLatest(fetchPutUsersCorpAction, fetchPutUsersCorpSaga),
    takeLatest(
      fetchPostUsersIndividualAuthenticationMethodAction,
      fetchPostUsersIndividualAuthenticationMethodSaga
    )
  ]);
}
