import React from 'react';
import styled, { css } from 'styled-components';
import Button, { ButtonTypes } from '../button/Button';
import ButtonGroup from '../button/ButtonGroup';

const LayerBodyBlock = styled.div`
  //padding: 0 30px;
  height: auto;
  //
  position: relative;
  max-height: calc(100vh - 165px);
  overflow: hidden;
  overflow-y: auto;
  ${(props) =>
    props.innerPadding &&
    css`
      padding: 0 20px;
    `}

  // Modal 스크롤 Block
  overscroll-behavior: contain;
`;

const LayerBody = ({
  buttons,
  isNoButton,
  children,
  okHandler,
  eventHandler,
  innerPadding = false
}) => {
  return (
    <LayerBodyBlock innerPadding={innerPadding}>
      {children && children}
      {!isNoButton && (
        <>
          {buttons ? (
            <ButtonGroup>{buttons}</ButtonGroup>
          ) : (
            <ButtonGroup>
              <Button
                type={ButtonTypes.Basic}
                onClick={eventHandler ? eventHandler : okHandler}
              >
                닫기
              </Button>
            </ButtonGroup>
          )}
        </>
      )}
    </LayerBodyBlock>
  );
};

export default LayerBody;
