import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import {
  AuthenticationMethod,
  getAuthMethodStr
} from '../../../../utils/constant/AgmConstant';
import loginType1 from '../../../../styles/images/common/sprite/log_ty_01_2.png';
import loginType2 from '../../../../styles/images/common/sprite/log_ty_02_2.png';

const LoginGuide = () => {
  const methods = useSelector((state) => state.common.authorization.methods);
  return (
    <GuideBlock>
      <div>
        <p>
          주주총회 전자투표 서비스를 이용하기 위해서는{' '}
          {getAuthMethodStr(methods)} 인증이 필요합니다.
        </p>
        <LoginTypeBlock type={LOGIN_TYPE.CERT}>
          <p>공동인증서</p>
          공동인증서는 고객님이 거래하고 계시는 증권사 혹은 은행 등에서 발급
          받으실 수 있습니다.
        </LoginTypeBlock>
        {!!methods && methods.includes(AuthenticationMethod.PASS_APP.code) && (
          <LoginTypeBlock type={LOGIN_TYPE.PASS}>
            <p>PASS 인증</p>
            PASS 앱을 이용해 인증하는 방식으로, 스마트폰에 PASS 앱이 설치되어야
            사용 가능합니다.
            <br />
            PASS 인증은 이동통신 3사가 출시한 것으로 스마트폰 USIM을 활용하는
            간편인증방식 중 하나입니다.
          </LoginTypeBlock>
        )}
      </div>
    </GuideBlock>
  );
};

const GuideBlock = styled.div`
  width: 100%;
  & > div {
    padding: 16px 0 0;
    & > p {
      background-color: rgba(158, 179, 205, 0.1);
      padding: 15px 16px 14px;
      font-size: 12px;
      word-break: keep-all;
    }
  }
`;

const LoginTypeBlock = styled.div`
  display: block;
  margin-top: 24px;
  padding-left: 76px;
  font-size: 12px;
  line-height: 1.35;
  word-break: keep-all;
  position: relative;
  ${(props) =>
    props.type === LOGIN_TYPE.CERT &&
    css`
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 18px;
        width: 32px;
        height: 48px;
        background: url(${loginType1}) no-repeat;
        background-size: 32px auto;
      }
    `}
  ${(props) =>
    props.type === LOGIN_TYPE.PASS &&
    css`
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 6px;
        left: 16px;
        width: 38px;
        height: 62px;
        background: url(${loginType2}) no-repeat;
        background-size: 38px auto;
      }
    `}
  & > p {
    font-size: 15px;
    font-weight: 800;
    margin-bottom: 6px;
  }
`;

const LOGIN_TYPE = {
  CERT: '1',
  PASS: '2'
};

export default LoginGuide;
