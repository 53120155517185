import { GET, POST, PUT } from '../../../utils/constant/httpMethod';
import { request } from '../../../utils/requestHelper';

const API_GROUP = 'users';
const Users = {
  /**
   * 사용자 가입
   */
  // 개인 주주가입 - 공동인증서
  fetchPostUsersIndividual(data) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/individual`,
      data
    });
  },
  // 개인주주 실명번호 등록
  fetchPutUsersIndividualRealNameNumber(data) {
    return request({
      method: PUT,
      url: `/v1/${API_GROUP}/individual/real-name-number`,
      data
    });
  },
  // 법인 주주 가입
  fetchPostUsersCorp(data) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/corp`,
      data
    });
  },
  // 내정보 조회 & 법인 주주 정보 조회
  fetchGetUsersMe() {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/me`
    });
  },
  // 개인 주주 정보 수정
  fetchPutUsersIndividual(data) {
    return request({
      method: PUT,
      url: `/v1/${API_GROUP}/individual`,
      data
    });
  },
  // 법인 주주 정보 수정
  fetchPutUsersCorp(data) {
    return request({
      method: PUT,
      url: `/v1/${API_GROUP}/corp`,
      data
    });
  },
  // 인증수단 추가
  fetchPostUsersIndividualAuthenticationMethod(data) {
    return request({
      method: POST,
      url: `/v1/users/individual/authentication-method`,
      data
    });
  }
};

export default Users;
