import React from 'react';
import styled from 'styled-components';

const ListBoxBlock = styled.div`
  & > ul {
    position: relative;
    & > li {
      width: 100%;
      padding-bottom: 30px;
    }
    & > li:last-of-type {
      flex: 1;
      padding-bottom: 0;
    }
  }
`;

const ListBox = ({ children }) => {
  return (
    <ListBoxBlock>
      <ul>
        {Array.isArray(children) ? (
          <>
            {children.map((child, idx) => (
              <li key={`ListLi_${idx}`}>{child}</li>
            ))}
          </>
        ) : (
          children
        )}
      </ul>
    </ListBoxBlock>
  );
};

export default ListBox;
