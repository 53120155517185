import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { idpwsign, SIGN_TYPE } from '../../../components/auth/SignIframe';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import { SCREEN_SIZE_MOBILE } from '../../../components/button/CommonStyle';
import Input from '../../../components/input/Input';
import InputBox from '../../../components/input/InputBox';
import PhoneInput from '../../../components/input/PhoneInput';
import { MODAL_SIZE } from '../../../components/modal/Modal';
import { LOGO_PLACE } from '../../../components/page/Gnb';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import { MemInnerBlock } from '../../../components/page/PageInner';
import PageTab from '../../../components/tabs/PageTab';
import { ModalContext } from '../../../context/ModalContext';
import useWindowSize from '../../../hooks/useWindowSize';
import {
  fetchGetAuthPassCertAction,
  fetchPostAuthLoginAdminAction,
  fetchPostAuthLoginCertAction,
  fetchPostAuthLoginPasswordAction,
  fetchPostAuthRefreshAction,
  fetchPutAuthCertificateIndividualAction
} from '../../../services/api/auth/promiseActions';
import { action as commonAction } from '../../../store/commonSlice';
import logGuidePng from '../../../styles/images/common/sprite/log_guide.png';
import logType01Png from '../../../styles/images/common/sprite/log_ty_01.png';
import logType02Png from '../../../styles/images/common/sprite/log_ty_02.png';
import {
  AuthenticationMethod,
  Role
} from '../../../utils/constant/AgmConstant';
import { getGnbRole } from '../../../utils/constant/GnbMenu';
import { getYMDSplit } from '../../../utils/DateUtil';
import { setAccessToken, setRefreshToken } from '../../../utils/requestHelper';
import LoginGuide from './component/LoginGuide';

const ButtonWrapper = styled.button`
  // .btn_log_type1
  ${(props) =>
    props.PASS
      ? css`
          padding: 74px 40px 70px 128px;
          background: #1354a4;
          color: #fff;
          font-weight: bold;
        `
      : css`
          padding: 74px 40px 70px 128px;
          background: #9eb3cd;
          color: #fff;
          font-weight: bold;
        `}
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding: 20px 0 70px;
  }
  &::before {
    ${(props) =>
      props.PASS
        ? css`
            content: '';
            position: absolute;
            top: 62px;
            left: 26%;
            transform: translateX(-50%);
            display: block;
            width: 57px;
            height: 88px;
            background: url(${logType02Png}) no-repeat;
            background-size: 57px 88px;
          `
        : css`
            content: '';
            position: absolute;
            top: 65px;
            left: 26%;
            transform: translateX(-50%);
            display: block;
            width: 58px;
            height: 84px;
            background: url(${logType01Png}) no-repeat;
            background-size: 58px 84px;
          `}
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      display: block;
      top: auto;
      left: 50%;
      bottom: 16px;
      width: 29px;
      height: 44px;
      background-size: 28px 44px;
    }
  }
`;

const BtnGuideBlock = styled.button`
  // .btn_log_guide
  position: relative;
  display: block;
  margin: 0 auto;
  padding-left: 30px;
  font-size: 15px;
  color: #66676b;
  background-color: transparent;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding-left: 22px;
    font-size: 12px;
  }
  &::before {
    // .btn_log_guide::before
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 21px;
    height: 23px;
    background: url(${logGuidePng}) no-repeat;
    background-size: 21px 23px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      width: 17px;
      height: 18px;
      background-size: 17px 18px;
    }
  }
  & > span {
    color: #a9b9cd;
  }
`;

const ButtonBlock = styled.button`
  ${(props) =>
    props.JOIN
      ? css`
          background-color: #2e487c;
          color: #fff;
        `
      : css`
          background-color: #fff;
          border: 1px solid #cad1d7;
          color: #080808;
        `}
`;

/*
const PopLoginPTagWrapper = styled.p`
  line-height: 1.53;
  word-break: keep-all;
  margin-bottom: 8px;

  background-color: rgba(158, 179, 205, 0.1);
  padding: 15px 20px 14px;
`;

const PopLoginWrapper = styled.div`
  // .pop_log_type1_dir .pop_log_typ2_dir
  display: block;
  margin-top: 30px;
  padding-left: 86px;
  font-size: 15px;
  line-height: 1.55;
  word-break: keep-all;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 12px;
    display: block;
    ${(props) =>
      props.type === 1
        ? css`
            width: 47px;
            height: 68px;
            background: url(${logType01}) no-repeat;
            background-size: 47px 68px;
          `
        : css`
            width: 49px;
            height: 75px;
            background: url(${logType02}) no-repeat;
            background-size: 49px 75px;
          `}
  }
  & > p {
    font-size: 20px;
    font-weight: 800;
  }
`;
*/
function useQuery() {
  console.log(useLocation().search);
  return new URLSearchParams(useLocation().search);
}

const LoginPage = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const { methods } = useSelector((state) => state.common.authorization);
  let history = useHistory();
  const query = useQuery();
  const loginType = query.get('type');
  let { openModal, setModalConfig, closeModal } = useContext(ModalContext);
  const btnEl = useRef(null);
  const [selTabNum, setSelTabNum] = useState(
    !loginType ? PAGE.STOCKHOLDER : PAGE.ISSINC
  );
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => {
    return windowSize.width <= SCREEN_SIZE_MOBILE;
  }, [windowSize]);
  const [tabItemArr, setTabItemArr] = useState(
    isMobile ? ['주주'] : ['주주', '발행회사']
  );

  const BROKERAGE_ADMIN_IP = '115.21.72.66';
  const setBrokerageTab = useCallback(() => {
    setTabItemArr((prev) => [...prev, '증권사']);
  }, []);
  useEffect(() => {
    const getLocationIP = async () => {
      const ipData = await fetch('https://geolocation-db.com/json/');
      const locationIp = await ipData.json();
      return locationIp.IPv4;
    };
    const res = getLocationIP();
    res.then((ip) => {
      ip === BROKERAGE_ADMIN_IP && setBrokerageTab();
    });
  }, []);

  const onChangeTab = (value) => {
    setSelTabNum(value);
  };

  const certificate = (signData) => {
    dispatch(fetchPutAuthCertificateIndividualAction({ signData })).then(
      (res) => {
        closeModal();
        console.log(res);
        if (!res.error && res.data.success) {
          openModal('공동인증서 재등록', '공동인증서가 (재)등록 되었습니다.');
        } else {
          openModal('공동인증서 재등록', res.error.user_message);
        }
      }
    );
  };

  const signin = (signData, payload) => {
    const data = { signData, ...payload };
    dispatch(fetchPostAuthLoginCertAction(data)).then((res) => {
      console.log('fetchPostAuthLoginCertAction');
      console.log(res);

      if (!res.error) {
        loginSuccess(res);
      } else {
        openModal('확인', res.error.user_message);
      }
    });
  };

  const loginSuccess = (data) => {
    const result = data.accessToken ? data : data.data;
    setAccessToken(result.accessToken);
    setRefreshToken(result.refreshToken);
    dispatch(
      commonAction.setCurrentUser({
        userSeqno: result.userSeqno,
        userName: result.userName,
        currentAgm: null,
        role: result.role,
        isAuth: true
      })
    );
    dispatch(commonAction.setCurrentGnb(getGnbRole(result.role)));

    // 현재 시간으로 부터 expiredTime까지 남은 시간 초로 환산
    // (new Date('2021-08-07T18:06:00.924').getTime() - 60000 - Date.now()) / 1000
    //const expiredTime = new Date(res.data.expiredTime);
    const refreshTime = 1000 * 60 * 5; //(expiredTime.getTime() - Date.now()) / 1000 - 60000; // 한시간 남으면 리프레시

    // 특정 시간 지난 후 리프레시
    window.tokenRefresh = setInterval(() => {
      dispatch(fetchPostAuthRefreshAction(result.refreshToken)).then((res) => {
        setAccessToken(res.data.accessToken);
      });
    }, refreshTime);
    movePage(result.role);
  };

  const movePage = (role) => {
    switch (role) {
      case Role.ISSINC_ADMIN.code:
      case Role.VIEW_ISSINC_ADMIN.code:
        history.push('/corp/agm/dashboard');
        break;
      case Role.INDIVIUDAL_SHAREHOLDER.code:
        history.push('/shareholder/my-agms');
        break;
      case Role.CORP_SHAREHOLDER.code:
        history.push('/shareholder/my-agms');
        break;
      case Role.SECCO_ADMIN.code:
        history.push('/brokerage/issuer-info');
        break;
      default:
        break;
    }
  };

  const openLoginGuide = () => {
    openModal('로그인 이용 안내', <LoginGuide />);
  };

  const openCertificateModal = () => {
    setModalConfig({
      size: MODAL_SIZE.SHORT,
      isNoButton: true,
      buttons: []
    });
    openModal(
      '공동인증서 재등록',
      <>
        {/* openSignModal */}
        <li>
          <InputBox title="이름" isRequired>
            <Input register={register} name="userName" placeholder="홍길동" />
          </InputBox>
        </li>
        <li>
          <InputBox title="생년월일" isRequired>
            <Input register={register} name="birthDt" placeholder="19940401" />
          </InputBox>
        </li>
        <li>
          <InputBox title="휴대전화번호" isRequired>
            <PhoneInput register={register} />
          </InputBox>
        </li>
        <ButtonGroup>
          <Button
            type={ButtonTypes.Primary}
            size={ButtonSize.Medium}
            onClick={() =>
              handleSubmit((data) => {
                const [y, m, d] = getYMDSplit(data.birthDt); // 생년월일
                const inputData = {
                  name: data.userName, //  이름
                  cell: `${data.frontCell}${data.middleCell}${data.endCell}`, // 전화번호
                  birthDt: `${y}-${m}-${d}`
                };
                openSignModal(certificate, inputData);
              })()
            }
          >
            공동인증서 (재)등록
          </Button>
        </ButtonGroup>
      </>
    );
  };

  const openSignModal = (handler, payload) => {
    setTimeout(() => {
      idpwsign(SIGN_TYPE.PERSON, handler, payload);
    }, 0);
  };

  const urlCode = process.env.REACT_APP_KMC_URL_CODE;
  const callbackDomain = process.env.REACT_APP_KMC_CALLBACK_DOMAIN;
  const openPassPopup = () => {
    const certNum = uuidv4();
    console.log('certNum = ' + certNum);

    const popupWidth = 400;
    const popupHeight = 645;
    popupCenter('', 'KMCISWindow', popupWidth, popupHeight);
    dispatch(fetchGetAuthPassCertAction({ certNum, urlCode })).then((res) => {
      console.log(fetchGetAuthPassCertAction);
      console.log(res);
      const tr_cert = res.data.tr_cert;
      //const tr_url = res.data.tr_url;

      //popupCenter('', 'KMCISWindow', popupWidth, popupHeight);

      var form = document.createElement('form');
      form.setAttribute('method', 'POST'); //Post 방식
      form.setAttribute('target', 'KMCISWindow'); //Post 방식
      form.setAttribute(
        'action',
        'https://www.kmcert.com/kmcis/web/kmcisReq.jsp'
      ); //요청 보낼 주소

      var hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', 'tr_url');
      //hiddenField.setAttribute('value', tr_url);
      hiddenField.setAttribute(
        'value',
        `${callbackDomain}/pass/login/callback`
      );

      form.appendChild(hiddenField);

      hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', 'tr_cert');
      hiddenField.setAttribute('value', tr_cert);
      form.appendChild(hiddenField);

      document.body.appendChild(form);
      console.log(form);
      form.submit();
    });
  };

  function popupCenter(url, title, w, h) {
    let dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
    let dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screen.top;
    let width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    let height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;
    var left = width / 2 - w / 2 + dualScreenLeft;
    var top = height / 2 - h / 2 + dualScreenTop;
    var newWindow = window.open(
      url,
      title,
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
        w +
        ', height=' +
        h +
        ', top=' +
        top +
        ', left=' +
        left
    );
    if (newWindow) {
      newWindow.focus();
      window.addEventListener('message', eventHandler, false);
    }
  }

  const eventHandler = (event) => {
    if (event.data) {
      try {
        const popupData = JSON.parse(event.data);
        if (popupData) {
          if (popupData.error) {
            openModal('확인', popupData.meta.data.user_message);
          } else {
            // 로그인 처리
            console.log(popupData);
            loginSuccess(popupData.data);
          }
        }
      } catch (e) {}
    }
  };

  const onClickIdPwdLogin = (data) => {
    dispatch(fetchPostAuthLoginPasswordAction(data)).then((res) => {
      console.log(res);
      if (!res?.error) {
        loginSuccess(res);
      } else {
        openModal('오류', res.error?.user_message);
      }
    });
  };

  const onKeyPressEnter = (e) => {
    if (e.key === 'Enter') {
      btnEl.current.click();
    }
  };

  const onClickBrokerageLogin = (data) => {
    dispatch(fetchPostAuthLoginAdminAction(data)).then((res) => {
      if (!res.error) {
        loginSuccess(res);
      } else {
        openModal('오류', res.error?.user_message);
      }
    });
  };

  return (
    <>
      <Page logoPlace={LOGO_PLACE.CENTER}>
        <PageHeader title="로그인" />
        <PageTab
          itemArr={tabItemArr}
          onChange={onChangeTab}
          tabIndex={selTabNum}
        />
        {selTabNum === PAGE.STOCKHOLDER ? (
          <>
            <MemInnerBlock>
              <div>
                <div>
                  <ul>
                    <li>
                      <ButtonWrapper
                        PASS={false}
                        onClick={() =>
                          openSignModal(signin, {
                            role: Role.INDIVIUDAL_SHAREHOLDER.code,
                            asIssueIncorporated: false
                          })
                        }
                      >
                        공인인증서
                        <p>로그인</p>
                      </ButtonWrapper>
                    </li>
                    {!!methods &&
                      methods.includes(AuthenticationMethod.PASS_APP.code) && (
                        <li>
                          <ButtonWrapper PASS={true} onClick={openPassPopup}>
                            PASS 인증
                            <p>로그인</p>
                          </ButtonWrapper>
                        </li>
                      )}
                  </ul>
                  <BtnGuideBlock onClick={openLoginGuide}>
                    {`공인인증서 `}
                    <span>로그인 이용 </span>
                    {!!methods &&
                      methods.includes(AuthenticationMethod.PASS_APP.code) && (
                        <>
                          | PASS <span>인증</span>
                        </>
                      )}
                  </BtnGuideBlock>
                  <BottomRegULBlock>
                    <li>
                      {isMobile ? (
                        <>
                          공동인증서가 <br /> 변경되었나요?
                        </>
                      ) : (
                        <>공동인증서가 변경되었나요?</>
                      )}
                      <ButtonBlock JOIN={false} onClick={openCertificateModal}>
                        공동인증서 (재)등록
                      </ButtonBlock>
                    </li>
                    <li>
                      {isMobile ? (
                        <>
                          주주총회 전자투표 서비스 <br /> 이용이 처음이신가요?
                        </>
                      ) : (
                        <>주주총회 전자투표 서비스 이용이 처음이신가요?</>
                      )}
                      <ButtonBlock
                        JOIN={true}
                        onClick={() => history.push('/signup')}
                      >
                        서비스 가입
                      </ButtonBlock>
                    </li>
                  </BottomRegULBlock>
                </div>
              </div>
            </MemInnerBlock>
          </>
        ) : selTabNum === PAGE.ISSINC ? (
          <MemInnerBlock>
            <div>
              <div>
                <ul>
                  <VerticalMiddleLiBlock>
                    <ButtonWrapper
                      PASS={false}
                      onClick={() =>
                        openSignModal(signin, {
                          role: Role.ISSINC_ADMIN.code,
                          asIssueIncorporated: true
                        })
                      }
                    >
                      공인인증서
                      <p>로그인</p>
                    </ButtonWrapper>
                  </VerticalMiddleLiBlock>
                  {/* 조회용 로그인 배제 */}
                  <IdPwdLoginBlock>
                    <h2>조회용 로그인</h2>
                    <InputWrapBlock mt6>
                      <Input
                        type="text"
                        register={register}
                        name="bizRegistrationNumber"
                        placeholder="사업자등록번호 입력"
                      />
                    </InputWrapBlock>
                    <InputWrapBlock mt3>
                      <Input
                        type="password"
                        register={register}
                        name="password"
                        placeholder="비밀번호"
                        onKeyPress={onKeyPressEnter}
                      />
                    </InputWrapBlock>
                    {/* <a href="#!">비밀번호 찾기</a> */}
                    <button
                      onClick={handleSubmit(onClickIdPwdLogin)}
                      ref={btnEl}
                      style={{ marginTop: '3%' }}
                    >
                      로그인
                    </button>
                  </IdPwdLoginBlock>
                </ul>
                <BtnGuideBlock>
                  {`공인인증서 `}
                  <span onClick={openLoginGuide}>로그인 이용 | </span>
                  {/* 임시로 가림 */}
                  {/* {`PASS `} */}
                </BtnGuideBlock>
                <BottomRegULBlock>
                  <li>
                    {`공동인증서가 변경되었나요?`}
                    <ButtonBlock JOIN={false} onClick={openCertificateModal}>
                      공동인증서 (재)등록
                    </ButtonBlock>
                  </li>
                  <li>
                    {`주주총회 전자투표 서비스 이용을 원하신다면?`}
                    <ButtonBlock
                      JOIN={true}
                      onClick={() => history.push('/corp/contract')}
                    >
                      발행회사 계약 신청
                    </ButtonBlock>
                  </li>
                </BottomRegULBlock>
              </div>
            </div>
          </MemInnerBlock>
        ) : (
          selTabNum === PAGE.BROKERAGE && (
            <MemInnerBlock>
              <div>
                <div>
                  <ul>
                    <IdPwdLoginBlock>
                      <h2>증권사 로그인</h2>
                      <InputWrapBlock mt6>
                        <Input
                          type="text"
                          register={register}
                          name="userId"
                          placeholder="아이디 입력"
                        />
                      </InputWrapBlock>
                      <InputWrapBlock mt3>
                        <Input
                          type="password"
                          register={register}
                          name="password"
                          placeholder="비밀번호"
                        />
                      </InputWrapBlock>
                      <a href="#!">비밀번호 찾기</a>
                      <button onClick={handleSubmit(onClickBrokerageLogin)}>
                        로그인
                      </button>
                    </IdPwdLoginBlock>
                  </ul>
                </div>
              </div>
            </MemInnerBlock>
          )
        )}
      </Page>
    </>
  );
};

const InputWrapBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  ${(props) =>
    props.mt6 &&
    css`
      margin-top: 6% !important;
    `}
  ${(props) =>
    props.mt3 &&
    css`
      margin-top: 3% !important;
    `}
    & > input {
    height: 37px;
    font-size: 16px;
  }
`;

const IdPwdLoginBlock = styled.li`
  // .form
  padding: 12px 30px 0 50px;
  box-sizing: border-box;

  vertical-align: middle;
  & > a {
    display: block;
    text-align: right;
    margin: 5px 0 10px;
    text-decoration: underline;
    font-size: 13px;
    color: #ff9330;
  }
  &&& > button {
    background-color: #0094ff;
    color: #fff;
    font-size: 16px;
    font-weight: 800;
    height: 42px;
  }
`;

const VerticalMiddleLiBlock = styled.li`
  vertical-align: middle;
`;

const BottomRegULBlock = styled.ul`
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0; */
    position: absolute;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    bottom: 20px;
    left: 0;
    right: 0;
  }
  &&& > li {
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      max-width: 145px;
      font-size: 12px;
      text-align: center;
      &:first-of-type {
        margin-left: 0;
      }
    }
    & > button {
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        margin-top: 8px;
        height: 30px;
        border-radius: 15px;
        font-size: 13px;
      }
    }
  }
`;

export default LoginPage;

const PAGE = {
  STOCKHOLDER: 0,
  ISSINC: 1,
  BROKERAGE: 2
};
