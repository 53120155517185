import { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import Pagination from '../../../components/datatable/Pagination';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import ScheduleItem from './main/ScheduleItem';
import SearchForm from './main/SearchForm';

const ListWrapper = styled.div`
  // .list_wrap2
  max-width: 960px;
  margin: 0 auto;
  padding-bottom: 10px;
  l & > div > ul {
    border-top: solid 1px #e9ecf4;
  }
`;

const EmptyBlock = styled.li`
  justify-content: center;
  width: 100% !important;
  padding: 60px 0 60px !important;
  letter-spacing: -0.5px;
  text-align: center;
  color: #caccd7;
  border-bottom: solid 1px #e9ecf4;
`;

const AgmSchedules = () => {
  const { register, setValue, handleSubmit, getValues } = useForm();
  const [schedules, setSchedules] = useState([]);
  const [pageConfig, setPageConfig] = useState();
  const renderData = () => {
    return (
      <>
        <ListWrapper>
          <div>
            {/* .list_ty05 */}
            <ul>
              {schedules.length === 0 ? (
                <EmptyBlock>데이터가 없습니다.</EmptyBlock>
              ) : (
                schedules.map((schedule, index) => {
                  return (
                    <ScheduleItem
                      schedule={schedule}
                      key={`schedule_${index}`}
                    />
                  );
                })
              )}
            </ul>
          </div>
        </ListWrapper>
        {pageConfig && <Pagination pageConfig={{ ...pageConfig }} />}
      </>
    );
  };
  return (
    <Page>
      <PageHeader title="주주총회 일정" />
      <SearchForm
        title="주주총회일"
        register={register}
        setSchedules={setSchedules}
        setPageConfig={setPageConfig}
        handleSubmit={handleSubmit}
        setValue={setValue}
        getValues={getValues}
      />
      {renderData()}
    </Page>
  );
};

export default AgmSchedules;
