import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import styled from 'styled-components';
import Button, {
  ButtonTypes
} from '../../../../../../components/button/Button';
import { BtnAreaBlock } from '../../../../../../components/layout/btnArea/BtnArea';
import { ModalContext } from '../../../../../../context/ModalContext';
import {
  fetchGetENoticeAgmSendAction,
  fetchGetENoticeTemplatesAgmTypeAction,
  fetchPostENoticeAgmAction
} from '../../../../../../services/api/agm/promiseActions';
import {
  ElecNoticeType,
  SendTimeType
} from '../../../../../../utils/constant/AgmConstant';
import { ListWrapBlock } from '../../../../dashboard/CorpDashboardPage';
import SendContent from './contents/SendContent';
import SendOption from './contents/SendOption';
import SendView from './contents/SendView';

const SendElecBillPage = () => {
  const location = useLocation();
  const params = location?.state; // { updateFlag, sendSeqno }
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { agmSeqno } = match.params;
  const { register, setValue, getValues, handleSubmit, reset } = useForm({
    mode: 'all'
  });
  const [info, setInfo] = useState();
  const { openModal } = useContext(ModalContext);
  const history = useHistory();

  const resetForm = useCallback(() => {
    const { sendSeqno } = params;
    dispatch(fetchGetENoticeAgmSendAction({ agmSeqno, sendSeqno })).then(
      (res) => {
        if (!res.error) {
          setInfo(res.data);
          reset(res.data?.electronicNotice);
        }
      }
    );
  }, [reset]);

  const getDefaultData = useCallback(() => {
    const action = fetchGetENoticeTemplatesAgmTypeAction;
    const data = { agmSeqno: agmSeqno, type: ElecNoticeType.ELEC_BILL.code };
    dispatch(action(data)).then((res) => {
      if (!res.error) {
        setInfo(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (!!params) {
      const { updateFlag } = params;
      updateFlag && resetForm();
    } else {
      getDefaultData();
    }
  }, []);

  const getBtnState = () => {
    const type = getValues('sendTimeType');
    switch (type) {
      case SendTimeType.IMMEDIATE.value:
        return SendTimeType.IMMEDIATE.text;
      case SendTimeType.RESERVE.value:
        return SendTimeType.RESERVE.text;
      default:
        return '발송하기';
    }
  };

  const onSubmit = (data) => {
    data.type = ElecNoticeType.ELEC_BILL.code;
    dispatch(fetchPostENoticeAgmAction({ agmSeqno, data })).then((res) => {
      if (!res.error) {
        openModal('확인', '발송 신청이 완료되었습니다.', () =>
          history.push(`/corp/agm/info/${agmSeqno}/electronic-billings`)
        );
      } else {
        openModal('확인', res.error?.user_message);
      }
    });
  };

  return (
    <ListWrapWrapper>
      {!!info && (
        <>
          <SendOption
            register={register}
            setValue={setValue}
            electronicNotice={info?.electronicNotice}
          />
          <SendContent
            setValue={setValue}
            agm={info?.agm}
            electronicNotice={info?.electronicNotice}
          />
          <SendView setValue={setValue} info={info} />
        </>
      )}
      <BtnAreaBlock>
        <ul>
          <li></li>
          <ButtonBlock>
            <Button type={ButtonTypes.Primary} onClick={handleSubmit(onSubmit)}>
              {getBtnState()}
            </Button>
          </ButtonBlock>
          <li></li>
        </ul>
      </BtnAreaBlock>
    </ListWrapWrapper>
  );
};

const ListWrapWrapper = styled(ListWrapBlock)`
  max-width: 980px;
`;

const ButtonBlock = styled.li`
  display: flex;
  & > button {
    flex: 1;
    margin-left: 4px;
  }
`;

export default SendElecBillPage;
