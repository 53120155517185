import PropsType from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Pagination from './Pagination';

const DataTableFooterBlock = styled.div`
  // .tblbtn_area
  padding-top: 10px;

  // .tblbtn_area > ul
  & > ul {
    display: flex;
    align-items: center;
    // .tblbtn_area > ul > li:first-of-type
    & > li {
      flex: 1;
      text-align: left;
    }
    // .tblbtn_area > ul > li:first-of-type
    & > li:first-of-type {
      margin-left: 0;
    }
    // .tblbtn_area > ul > li:only-of-type
    & > li:only-of-type {
      text-align: center;
    }
    // .tblbtn_area > ul > li:nth-child(2
    & > li:nth-child(2) {
      flex-basis: auto;
      text-align: center;
    }
    & > li:last-of-type {
      text-align: right;
    }
    & > li > button {
      /*
      display: flex;
      width: 100%;
      height: 100%;
      */
      min-width: auto;
      align-items: center;
      justify-content: center;
      margin-left: 4px;
    }
    & > li > button:first-child {
      margin-left: 0;
    }
  }
`;

const DescriptionBlock = styled.span`
  // .desc
  display: block;
  margin-top: 8px;
  padding-left: 20px;
  font-size: 13px;
  color: #8c939c;
  line-height: 1.33;

  // .desc.ty03
  position: relative;
  margin-top: 6px;
  padding-left: 15px;
`;

const DataTableFooter = (props) => {
  const { pageConfig, actionItems, description } = props;

  const renderDescription = () => {
    if (!description) {
      return null;
    }

    if (typeof description === 'string') {
      return <DescriptionBlock>{`- ${description}`}</DescriptionBlock>;
    } else {
      return description.map((desc) => (
        <DescriptionBlock>{`- ${desc}`}</DescriptionBlock>
      ));
    }
  };

  if (!pageConfig && !actionItems) {
    return null;
  } else {
    return (
      <DataTableFooterBlock>
        <ul>
          <li>{renderDescription()}</li>
          {pageConfig && (
            <li>
              <Pagination pageConfig={pageConfig} />
            </li>
          )}
          {actionItems ? <li>{actionItems}</li> : <li></li>}
        </ul>
      </DataTableFooterBlock>
    );
  }
};

DataTableFooter.propTypes = {
  pageConfiog: PropsType.object,
  actionItems: PropsType.oneOfType([PropsType.func, PropsType.object]),
  description: PropsType.oneOfType([PropsType.string, PropsType.array])
};

export default DataTableFooter;
