import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { idpwsign } from '../../../../../components/auth/SignIframe';
import DataTable, {
  DataType
} from '../../../../../components/datatable/DataTable';
import SearchInput from '../../../../../components/input/SearchInput';
import Select from '../../../../../components/input/Select';
import { MODAL_SIZE } from '../../../../../components/modal/Modal';
import { TableDataType } from '../../../../../components/table/Table';
import { ModalContext } from '../../../../../context/ModalContext';
import {
  fetchGetAgmStockholderAction,
  fetchGetAgmStockholdersAction,
  fetchGetAgmStockholdersSearchAction
} from '../../../../../services/api/agm/promiseActions';
import {
  checkUndefinedNum,
  formatNumber
} from '../../../../../utils/StringUtil';
import AgmRegisterStep4Modal from '../../step4/AgmRegisterStep4Modal';

const StockholdersList = (props) => {
  const { agmSeqno, baseInfo, isRegisterStep, agendas } = props;
  const [stockholders, setStockHolders] = useState([]);
  const [selRowIndex, setSelRowIndex] = useState(-1);
  const [selAgendaSeq, setSelAgendaSeq] = useState(() =>
    !!agendas ? agendas.totalVotingRights[0]?.agendaSeqno : 0
  );
  const [agendaExercisableStockAmmount, setAgendaExercisableStockAmmount] =
    useState(0);

  const dispatch = useDispatch();
  const { openModal, setModalConfig, closeModal } = useContext(ModalContext);
  const [pageConfig, setPageConfig] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [reRender, setReRender] = useState(true);

  const onclickRow = (e) => {
    setSelRowIndex(e.currentTarget.rowIndex - 2);
  };

  useEffect(() => {
    if (!!agendas) {
      const data = {
        pageNumber: pageNumber,
        size: 10
      };
      let stockholderCount = 1;
      dispatch(fetchGetAgmStockholdersAction({ agmSeqno, data })).then(
        (res) => {
          if (!res.error) {
            const {
              pageNumber,
              size,
              totalCount,
              totalPageNumber,
              stockholders
            } = res.data;
            setStockHolders(
              stockholders.map((stockholder, index) => {
                const targetAgenda = stockholder.voteRights.find((agenda) =>
                  !!selAgendaSeq
                    ? agenda.agendaSeqno === selAgendaSeq
                    : agenda.agendaSeqno ===
                      agendas.totalVotingRights[0].agendaSeqno
                );
                return {
                  index: stockholderCount++,
                  ...stockholder,
                  totalStockAmount:
                    stockholder.commonStockAmount +
                    checkUndefinedNum(
                      stockholder.exercisablePreferredStockAmount
                    ) +
                    checkUndefinedNum(stockholder.preferredStockAmount),
                  ...targetAgenda
                };
              })
            );
            setPageConfig({
              pageNumber,
              size,
              totalCount,
              totalPageNumber
            });
          }
        }
      );
      setReRender(false);
    }
  }, [agmSeqno, dispatch, pageNumber, selAgendaSeq, agendas, reRender]);

  useEffect(() => {
    const openStockHolderUpdateModal = () => {
      setModalConfig({
        size: MODAL_SIZE.LARGE,
        buttons: null,
        isNoButton: true
      });
      openModal(
        '의결권 보유내역 및 의결권 제한내역',
        <AgmRegisterStep4Modal
          agmSeqno={agmSeqno}
          otherEvent={() => setSelRowIndex(-1)}
          closeModal={closeModal}
          resetValue={stockholders[selRowIndex]}
          setReRender={setReRender}
          baseInfo={baseInfo}
          idpwsign={idpwsign}
          isRegisterStep={isRegisterStep}
        />
      );
    };
    selRowIndex === -1 || openStockHolderUpdateModal();
  }, [selRowIndex]);

  const columns2 = [
    {
      title: 'No',
      key: 'index',
      type: TableDataType.NUMBER,
      col: '6%'
    },
    {
      title: '주주',
      children: [
        {
          title: '주주명',
          key: 'stockholderName'
        },
        {
          title: '주민등록번호/사업자번호',
          key: 'stockholderRealNameNumber'
        }
      ]
    },
    {
      title: '투표권자/위임장피권유자',
      children: [
        {
          title: '주주명',
          key: 'elecVoterName'
        },
        {
          title: '주민등록번호/사업자번호',
          key: 'elecVoterRealNameNumber'
        }
      ]
    },
    {
      title: '보유주식수',
      key: 'totalStockAmount',
      type: DataType.NUMBER
    },
    // {
    //   title: '의결권 있는, 우선주',
    //   key: 'exercisablePreferredStockAmount',
    //   type: DataType.NUMBER,
    //   col: '8%'
    // },
    {
      title: ['의결권 없는', '주식주'],
      key: 'noVotingRightsStockAmmount',
      type: DataType.NUMBER,
      col: '8%'
    },
    {
      title: ['의결권 제한', '주식수'],
      key: 'votingRightsLimitStockAmmount',
      type: DataType.NUMBER,
      col: '8%'
    },
    {
      title: '행사가능 주식수',
      key: 'exercisableStockAmmount',
      type: DataType.NUMBER
    }
  ];

  useEffect(() => {
    if (!!agendas && selAgendaSeq !== 0) {
      setAgendaExercisableStockAmmount(
        agendas?.totalVotingRights?.find(
          (item) => item.agendaSeqno === selAgendaSeq
        )?.exercisableStockAmmount
      );
    }
  }, [agendas, selAgendaSeq]);

  const renderSelOptAgendas = () => {
    return !!agendas
      ? agendas.totalVotingRights.map((agenda) => {
          return (
            <option
              value={agenda.agendaSeqno}
              key={`parent_${agenda.agendaSeqno}_${agenda.agendaNumber}`}
            >{`제 ${agenda.agendaNumber}안건`}</option>
          );
        })
      : null;
  };

  const onchangeAgenda = (e) => {
    const seqno = Number(e.target.value);
    setSelAgendaSeq(seqno);
  };

  const onSearch = (value) => {
    if (!!value.match(/[^0-9]+$/) || value === '') {
      searchByName(value);
    } else {
      searchByIdNumber(value);
    }
  };

  const searchByName = (value) => {
    const data = {
      agmSeqno: agmSeqno,
      params: {
        stockholderName: value,
        pageNumber: 0,
        size: 10
      }
    };
    dispatch(fetchGetAgmStockholdersSearchAction(data)).then((res) => {
      if (!res.error) {
        const { pageNumber, size, stockholders, totalCount, totalPageNumber } =
          res.data;
        if (totalCount === 0) {
          setStockHolders([]);
        } else {
          const newArr = [];
          stockholders.forEach((stockholder, idx) => {
            const targetAgenda = stockholder.voteRights.find((agenda) =>
              !!selAgendaSeq
                ? agenda.agendaSeqno === selAgendaSeq
                : agenda.agendaSeqno ===
                  agendas.totalVotingRights[0].agendaSeqno
            );
            if (typeof targetAgenda !== 'undefined') {
              newArr.push({
                ...stockholder,
                ...targetAgenda,
                index: idx + 1
              });
            }
          });
          setStockHolders(newArr);
          setPageConfig({
            pageNumber,
            size,
            totalCount,
            totalPageNumber
          });
        }
      }
    });
  };

  const searchByIdNumber = (value) => {
    const data = {
      agmSeqno: agmSeqno,
      identificationNumber: value
    };
    dispatch(fetchGetAgmStockholderAction(data)).then((res) => {
      if (!res.error) {
        const newArr = [];
        let stockholderCount = 1;
        const stockholder = res.data;
        if (Object.keys(res.data).length === 0) {
          setStockHolders([]);
        } else {
          if (!!agendas) {
            const targetAgenda = stockholder.voteRights.find((agenda) =>
              !!selAgendaSeq
                ? agenda.agendaSeqno === selAgendaSeq
                : agenda.agendaSeqno ===
                  agendas.totalVotingRights[0].agendaSeqno
            );
            newArr.push({
              index: stockholderCount++,
              ...stockholder,
              ...targetAgenda
            });
          }
          setStockHolders(newArr);
          setPageConfig({});
        }
      }
    });
  };

  return (
    <>
      {!!stockholders.length !== 0 && (
        <DataTable
          columns={columns2}
          dataSource={stockholders}
          filterConfig={{
            desc: '주주를 선택하면 보유내역 및 의결권 제한을 확인할 수 있으며 투표권자 변경도 할 수 있습니다.',
            filterItems: (
              <>
                <Select onchange={onchangeAgenda} noDefault>
                  {renderSelOptAgendas()}
                </Select>
                <SearchInput
                  placeholder="실명인증번호 입력"
                  onSearch={(value) => onSearch(value)}
                />
              </>
            )
          }}
          pageConfig={{
            ...pageConfig,
            onClick: setPageNumber
          }}
          onRowClick={onclickRow}
          actionItems={
            <>
              <p>
                <b>
                  총 행사가능 주식수 :{' '}
                  {formatNumber(agendaExercisableStockAmmount)}주
                </b>
              </p>
            </>
          }
          shortHeight
          overFlow
        />
      )}
    </>
  );
};

export default StockholdersList;
