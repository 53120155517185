import { all, takeLatest } from '@redux-saga/core/effects';
import APIs from '..';
import createRequestSaga from '../../../utils/createRequestSaga';
import {
  fetchGetFilesAgmDiversityExerciseAction,
  fetchGetFilesShareholderSampleAction,
  fetchGetFilesTemporaryAction,
  fetchPostFilesTemporaryAction
} from './promiseActions';

const fetchPostFilesTemporarySaga = createRequestSaga(
  fetchPostFilesTemporaryAction,
  APIs.Files.fetchPostFilesTemporary
);

// 임시 파일 다운로드 (공통)
const fetchGetFilesTemporarySaga = createRequestSaga(
  fetchGetFilesTemporaryAction,
  APIs.Files.fetchGetFilesTemporary
);

// 불통일 행사 파일 다운로드
const fetchGetFilesAgmDiversityExerciseSaga = createRequestSaga(
  fetchGetFilesAgmDiversityExerciseAction,
  APIs.Files.fetchGetFilesAgmDiversityExercise
);

// 주총개설 - 주주명부 샘플 다운로드
const fetchGetFilesShareholderSampleSaga = createRequestSaga(
  fetchGetFilesShareholderSampleAction,
  APIs.Files.fetchGetFilesShareholderSample
);

export default function* saga() {
  yield all([
    takeLatest(fetchPostFilesTemporaryAction, fetchPostFilesTemporarySaga),
    takeLatest(fetchGetFilesTemporaryAction, fetchGetFilesTemporarySaga),
    takeLatest(
      fetchGetFilesAgmDiversityExerciseAction,
      fetchGetFilesAgmDiversityExerciseSaga
    ),
    takeLatest(
      fetchGetFilesShareholderSampleAction,
      fetchGetFilesShareholderSampleSaga
    )
  ]);
}
