import { all, takeLatest } from '@redux-saga/core/effects';
import APIs from '..';
import createRequestSaga from '../../../utils/createRequestSaga';
import {
  fetchPostIssueIncorporatedAction,
  fetchPutIssueIncorporatedAction,
  fetchPutIssueIncorporatedPasswordAction,
  fetchGetIssueIncorporatedAction,
  fetchGetIssueIncorporatedFilesAction
} from './promiseActions';

const fetchPostIssueIncorporatedSaga = createRequestSaga(
  fetchPostIssueIncorporatedAction,
  APIs.Issinc.fetchPostIssueIncorporated
);
const fetchPutIssueIncorporatedSaga = createRequestSaga(
  fetchPutIssueIncorporatedAction,
  APIs.Issinc.fetchPutIssueIncorporated
);
const fetchPutIssueIncorporatedPasswordSaga = createRequestSaga(
  fetchPutIssueIncorporatedPasswordAction,
  APIs.Issinc.fetchPutIssueIncorporatedPassword
);
const fetchGetIssueIncorporatedSaga = createRequestSaga(
  fetchGetIssueIncorporatedAction,
  APIs.Issinc.fetchGetIssueIncorporated
);
const fetchGetIssueIncorporatedFilesSaga = createRequestSaga(
  fetchGetIssueIncorporatedFilesAction,
  APIs.Issinc.fetchGetIssueIncorporatedFiles
);

export default function* saga() {
  yield all([
    takeLatest(
      fetchPostIssueIncorporatedAction,
      fetchPostIssueIncorporatedSaga
    ),
    takeLatest(fetchPutIssueIncorporatedAction, fetchPutIssueIncorporatedSaga),
    takeLatest(
      fetchPutIssueIncorporatedPasswordAction,
      fetchPutIssueIncorporatedPasswordSaga
    ),
    takeLatest(fetchGetIssueIncorporatedAction, fetchGetIssueIncorporatedSaga),
    takeLatest(
      fetchGetIssueIncorporatedFilesAction,
      fetchGetIssueIncorporatedFilesSaga
    )
  ]);
}
