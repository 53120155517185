import { GET } from '../../../utils/constant/httpMethod';
import { request } from '../../../utils/requestHelper';

const API_GROUP = 'e-notice';
const ElecBill = {
  /**
   * 전자고지서
   */
  // 전자고지서 - 템플릿 조회
  fetchGetENoticeTemplatesAgmType({ agmSeqno, typeSeqno }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/e-notice/templates/agm/${agmSeqno}/type/${typeSeqno}`
    });
  }

  // 전자고지서 등록
};

export default ElecBill;
