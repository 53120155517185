import styled, { css } from 'styled-components';

const RowDataTable = (props) => {
  const { columnName, dataSource = [], style } = props;
  return (
    <RowTableBlock style={{ ...style }}>
      <table>
        <colgroup></colgroup>
        <tr>
          <th>{columnName}</th>
          {dataSource.map((item, index) => {
            return <td key={`rowDataTable_tdKey_${index}`}>{item}</td>;
          })}
        </tr>
      </table>
    </RowTableBlock>
  );
};

const RowTableBlock = styled.div`
  // .tblcommon-01
  width: 100%;
  & table {
    min-width: 100%;
    & th {
      background: #fbfbfc;
      border-top: solid 1px #e9ecf4;
      border-right: solid 1px #e9ecf4;
      box-shadow: none;
      position: relative;
      padding: 5px 8px 2px;
      height: 50px;
      font-size: 14px;
      text-align: center;
      transform: skew(-0.04deg);
    }
    & tr:first-of-type {
      border-left: 0;
    }
    & th:first-of-type {
      border-left: 0;
    }

    & td {
      padding: 3px 8px 0;
      height: 54px;
      font-size: 15px;
      border-top: solid 1px #e9ecf4;
      border-bottom: solid 1px #e9ecf4;
      border-right: solid 1px #e9ecf4;
      text-overflow: ellipsis;
      word-break: break-all;
      text-align: center;
      transform: skew(-0.04deg);
      /* &:not(:last-of-type) {
        border-right: solid 1px #e9ecf4;
      } */
      ${(props) =>
        props.overFlow ||
        css`
          white-space: nowrap;
          overflow: hidden;
        `}
    }
  }
`;

export default RowDataTable;
