export function formatNumber(num) {
  if (num === null || num === undefined) {
    return 0;
  }

  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
export function checkUndefinedNum(num) {
  if (Number.isNaN(num) || !num) return 0;
  else return num;
}

export const formatRealNumber = (realNumber) => {
  if (realNumber.indexOf('-') === -1) {
    let newMaskingStr = '';
    for (let index = 0; index < realNumber.length; index++) {
      newMaskingStr += realNumber.charAt(index);
      if (index === 5) {
        newMaskingStr += '-';
      }
    }
    return newMaskingStr;
  } else {
    return realNumber;
  }
};

export const splitCellNumber = (text) => {
  var firstCheck = false;
  var tmp = [];
  var len = text.length;
  if (len === 7 || len === 8) {
    tmp.push('');
    tmp.push(text.substring(0, len - 4));
    tmp.push(text.substring(len - 4, len));
    return tmp;
  }
  for (let i = 0; i < localTelList.length; i++) {
    if (text.indexOf(localTelList[i]) === 0) {
      tmp.push(localTelList[i]);
      firstCheck = true;
    }
  }

  if (!firstCheck) {
    for (let i = 0; i < cellTelList.length; i++) {
      if (text.indexOf(cellTelList[i]) === 0) {
        tmp.push(cellTelList[i]);
        firstCheck = true;
      }
    }
  }

  if (!firstCheck) {
    return null;
  }

  if (text.length < 9) {
    tmp.push(text.substring(tmp[0].length, len));
    tmp.push('');
    return tmp;
  }

  tmp.push(text.substring(tmp[0].length, len - 4));
  tmp.push(text.substring(len - 4, len));

  return tmp;
};

export const getExtension = (fileName) => {
  return fileName.substr(fileName.indexOf('.') + 1);
};

export function arrayBufferToBase64(buffer) {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

// 임시 자리 이동 필요
const localTelList = [
  '02',
  '031',
  '032',
  '033',
  '041',
  '042',
  '043',
  '044',
  '051',
  '052',
  '053',
  '054',
  '055',
  '061',
  '062',
  '063',
  '064',
  '070',
  '0505'
];

const cellTelList = ['010', '011', '016', '017', '018', '019'];

export function phoneFomatter(num, type) {
  let formatNum = '';
  if (num.length === 11) {
    formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (num.length === 8) {
    formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else {
    if (num.indexOf('02') === 0) {
      if (num.length === 9) {
        formatNum = num.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
      } else {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else {
      formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
  }
  return formatNum;
}
