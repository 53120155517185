import { useRouteMatch } from 'react-router';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import CheckBox from '../../../../../components/input/CheckBox';
import selectPng from '../../../../../styles/images/common/select_bg.png';
import spCommonPng from '../../../../../styles/images/common/sprite/sp_common.png';
import { ExerciseMethod } from '../../../exerciseRecord/main/BoxInfo';

export const RadioBtnBlock = styled.div`
  // .radio_ty
  position: relative;
  display: inline-block;
  width: 240px;
  padding: 7px 16px 9px;
  border: 2px solid #d8e1eb;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  font-size: 17px;
  text-align: left;
  &:last-of-type {
    margin-left: 3%;
  }
  ${(props) =>
    props.active &&
    css`
      border-color: #24375c;
    `}
  & > input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
  & > input[type='radio'] + label {
    position: relative;
    display: inline-block;
    padding: 4px 0 2px 40px;
    line-height: 1.67;
    letter-spacing: -0.5px;
    color: #080808;
    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 30px;
      height: 30px;
      background: url(${spCommonPng}) no-repeat;
      background-size: 400px auto;
      background-position: -149px -1px;
    }
  }
  & > input[type='radio']:checked + label {
    font-weight: bold;
    transform: skew(-0.04deg);
    &::before {
      background-position: -149px -31px;
      ${(props) =>
        props.active ||
        css`
          background-position: -149px -1px;
        `}
    }
  }
`;

const SelectBlock = styled.select`
  flex: 1;
  height: 52px;
  padding: 15px 46px 15px 20px;
  border-radius: 4px;
  border: solid 1px #ccd5e0;
  box-sizing: border-box;
  background: url(${selectPng}) no-repeat right center;
  background-color: #fff;
  background-size: 36px auto;
  line-height: 1.33;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    height: 34px;
    padding: 7px 22px 6px 12px;
    border-radius: 4px;
    border: solid 1px #a5b7ce;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    background: url(${selectPng}) no-repeat right -3px center;
    background-color: #fff;
    background-size: 22px auto;
  }
`;

const CertificateCheckSelect = ({
  info,
  solicitor,
  openWithdrawDiversPopup,
  openDiverseReasonPopup,
  useDiverse,
  onChangeElectronicProxyName,
  electronicProxyName,
  selectedVoters
}) => {
  const match = useRouteMatch();
  const exerciseMethod = match.url.includes('proxy')
    ? ExerciseMethod.PROXY
    : ExerciseMethod.VOTE;

  const onClickHandler = (checked) => {
    if (!useDiverse) {
      openDiverseReasonPopup();
    } else {
      openWithdrawDiversPopup();
    }
  };

  return (
    <>
      <li>
        <span>불통일여부</span>
        <CheckBox
          title="불통일 신청"
          checked={useDiverse}
          onlyUseProps={true}
          onChange={onClickHandler}
        />
      </li>
      {exerciseMethod === ExerciseMethod.PROXY && (
        <li>
          <span>권유자</span>
          <SelectBlock>
            <option>{solicitor.solicitorName}</option>
          </SelectBlock>
          <span style={{ marginLeft: '1.2%', width: '48px' }}>대리인</span>
          <SelectBlock
            onChange={(e) => onChangeElectronicProxyName(e.target.value)}
          >
            {solicitor.voteProxyMap.map((proxy, index) => {
              return (
                <option
                  key={`proxy_${index}`}
                  selected={selectedVoters[0]?.electronicProxyName === proxy}
                >
                  {proxy}
                </option>
              );
            })}
          </SelectBlock>
        </li>
      )}
    </>
  );
};

export default CertificateCheckSelect;
