import { css } from 'styled-components';
import NanumSquareB_eot from './fonts/NanumSquareB.eot';
import NanumSquareB_ttf from './fonts/NanumSquareB.ttf';
import NanumSquareB_woff from './fonts/NanumSquareB.woff';
import NanumSquareEB_eot from './fonts/NanumSquareEB.eot';
import NanumSquareEB_ttf from './fonts/NanumSquareEB.ttf';
import NanumSquareEB_woff from './fonts/NanumSquareEB.woff';
import NanumSquareL_eot from './fonts/NanumSquareL.eot';
import {
  default as NanumSquareL_ttf,
  default as NanumSquareL_woff
} from './fonts/NanumSquareL.ttf';
import NanumSquareR_eot from './fonts/NanumSquareR.eot';
import NanumSquareR_ttf from './fonts/NanumSquareR.ttf';
import NanumSquareR_woff from './fonts/NanumSquareR.woff';
import RobotoMedium_eot from './fonts/Roboto-Medium.eot';
import RobotoMedium_ttf from './fonts/Roboto-Medium.ttf';
import RobotoMedium_woff from './fonts/Roboto-Medium.woff';
/*
	fonts
*/
export const fontFaces = css`
  @font-face {
    font-family: 'NanumSquare';
    font-weight: normal;
    src: url(${NanumSquareR_eot});
    src: url(${NanumSquareR_eot}?#iefix) format('embedded-opentype'),
      url(${NanumSquareR_woff}) format('woff'),
      url(${NanumSquareR_ttf}) format('truetype');
  }
  @font-face {
    font-family: 'NanumSquare';
    font-weight: bold;
    src: url(${NanumSquareB_eot});
    src: url(${NanumSquareB_eot}?#iefix) format('embedded-opentype'),
      url(${NanumSquareB_woff}) format('woff'),
      url(${NanumSquareB_ttf}) format('truetype');
  }
  @font-face {
    font-family: 'NanumSquare';
    font-weight: 800;
    src: url(${NanumSquareEB_eot});
    src: url(${NanumSquareEB_eot}?#iefix) format('embedded-opentype'),
      url(${NanumSquareEB_woff}) format('woff'),
      url(${NanumSquareEB_ttf}) format('truetype');
  }
  @font-face {
    font-family: 'NanumSquare';
    font-weight: 300;
    src: url(${NanumSquareL_eot});
    src: url(${NanumSquareL_eot}?#iefix) format('embedded-opentype'),
      url(${NanumSquareL_woff}) format('woff'),
      url(${NanumSquareL_ttf}) format('truetype');
  }
  @font-face {
    font-family: 'Roboto-Medium';
    font-weight: 300;
    src: url(${RobotoMedium_eot});
    src: url(${RobotoMedium_eot}?#iefix) format('embedded-opentype'),
      url(${RobotoMedium_woff}) format('woff'),
      url(${RobotoMedium_ttf}) format('truetype');
  }
`;
