import React, { useCallback, useEffect, useState } from 'react';
import PageTab from '../../../../components/tabs/PageTab';
import { ListWrapBlock } from '../../dashboard/CorpDashboardPage';
import ProxyExerciseList from './main/ProxyExerciseList';
import VoteExercise from './main/VoteExerciseList';
import FileDownButton from '../../../../components/button/FileDownButton';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router';
import { useFileDownload } from '../../../../hooks/useFileDownload';
import {
  fetchGetAgmOutlineAction,
  fetchGetFilesAgmExcelAction
} from '../../../../services/api/agm/promiseActions';
import { useDispatch } from 'react-redux';
import { getConstNameByCode } from '../../../../utils/constant/AgmConstant';
import { TableTitleBlock } from '../../../../components/table/TableTitle';

const CountingResult = () => {
  const [activeTabNum, setActiveTabNum] = useState();
  const match = useRouteMatch();
  const { agmSeqno } = match.params;
  const dispatch = useDispatch();
  const [elecUse, setElecUse] = useState();

  const getElecMethod = useCallback((voteUse, proxyUse) => {
    if (voteUse && proxyUse) return [TabPage.VOTE.code, TabPage.PROXY.code];
    else if (!voteUse && proxyUse) return [TabPage.PROXY.code];
    else if (voteUse && !proxyUse) return [TabPage.VOTE.code];
  }, []);

  useEffect(() => {
    dispatch(fetchGetAgmOutlineAction({ agmSeqno })).then((res) => {
      if (!res.error) {
        const data = res.data;
        setElecUse(
          getElecMethod(data.electronicVoteUse, data.electronicProxyUse)
        );
      }
    });
  }, [dispatch]);

  useEffect(() => {
    !activeTabNum && !!elecUse && setActiveTabNum(elecUse[0]);
  }, [elecUse]);

  const onChangeTab = (value) => {
    setActiveTabNum(elecUse[value]);
  };

  const { callDownloadFetchAPIOnlyAgmseqno } = useFileDownload();
  const onClickStatusDownload = () => {
    callDownloadFetchAPIOnlyAgmseqno(agmSeqno, fetchGetFilesAgmExcelAction);
  };

  return (
    <ListWrapBlock>
      <TableTitleBlock>
        {!!elecUse && (
          <PageTab
            itemArr={elecUse.map((code) => {
              return getConstNameByCode(TabPage, code);
            })}
            onChange={onChangeTab}
            style={{ maxWidth: 'none', borderBottom: '1px solid lightgrey' }}
            restrictStyle={{ maxWidth: '300px' }}
          />
        )}
        <FileDownButtonWrapper onClick={onClickStatusDownload}>
          전체 현황 다운로드
        </FileDownButtonWrapper>
        <br />
        {activeTabNum === TabPage.VOTE.code && <span>투표현황</span>}
        {activeTabNum === TabPage.PROXY.code && <span>위임 신청 정보</span>}
      </TableTitleBlock>
      {activeTabNum === TabPage.VOTE.code && <VoteExercise />}
      {activeTabNum === TabPage.PROXY.code && <ProxyExerciseList />}
    </ListWrapBlock>
  );
};

export default CountingResult;

const TabPage = {
  VOTE: { code: '1', name: '전자투표' },
  PROXY: { code: '2', name: '전자위임장' }
};

const FileDownButtonWrapper = styled(FileDownButton)`
  &&& {
    position: absolute;
    right: 0;
    top: 11%;
  }
`;
