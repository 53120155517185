import React from 'react';
import styled from 'styled-components';

const TableHeaderBlock = styled.div`
  display: block;
  margin-top: 80px;
  margin-bottom: 14px;
`;

const TableHeader = ({ children }) => {
  return <TableHeaderBlock>{children}</TableHeaderBlock>;
};

export default TableHeader;
