import PropTypes from 'prop-types'; // ES6
import { useState } from 'react';
import styled, { css } from 'styled-components';
import SpCommonPng from '../../styles/images/common/sprite/sp_common.png';
import spCommonMobilePng from '../../styles/images/common/sprite/sp_common_mobile.png';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';

const TermsListBlock = styled.div`
  display: list-item;
  /* margin-top: 16px; */
  margin-top: 0;
  & > dl {
    padding: 2px 0 0;
    & > dt {
      padding: 16px 0 15px;
      border-top: 1px solid #f2f3f6;
      position: relative;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        padding: 13px 0 12px;
        border-top: 1px solid #f2f3f6;
        position: relative;
      }
    }
    & > dt:last-of-type {
      border-bottom: 1px solid #f2f3f6;
    }
  }
`;

const DescriptionWrapper = styled.dd`
  display: none;
  padding: 24px 52px;
  background-color: #fbfcfd;
  border: 1px solid rgba(158, 179, 205, 0.25);
  border-radius: 5px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding: 10px 12px;
    height: 120px;
    overflow-y: auto;
    background-color: #fbfcfd;
    border: 1px solid rgba(158, 179, 205, 0.25);
    border-radius: 6px;
  }
  ${(props) =>
    props.active &&
    css`
      display: block;
    `}
  & > span {
    font-size: 13px;
    line-height: 1.65;
    letter-spacing: 1px;
    color: #8c939c;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      font-size: 12px;
      letter-spacing: 1px;
      color: #8c939c;
      line-height: unset;
    }
  }
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 24px;
  right: 0;
  width: 16px;
  height: 10px;
  background: url(${SpCommonPng}) no-repeat;
  background-size: 400px auto;
  background-position: -123px -1px;
  opacity: 0.2;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: absolute;
    top: 17px;
    right: 4px;
    width: 10px;
    height: 6px;
    background: url(${spCommonMobilePng}) no-repeat;
    background-size: 230px auto;
    background-position: -71px -1px;
    opacity: 0.2;
  }
  ${(props) =>
    props.on &&
    css`
      background-position: -123px -13px;
      opacity: 1;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        opacity: 1;
      }
    `}
  & > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin-top: -1px;
    box-sizing: border-box;
    color: transparent;
    overflow: hidden;
    opacity: 0;
  }
`;

const CheckBoxBlock = styled.div`
  // .checkbox_ty02
  position: relative;
  display: inline-block;
  & > input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
  & > input[type='checkbox']:checked {
    & label::before {
      background-position: -43px -31px;
    }
  }
  & > label {
    position: relative;
    display: inline-block;
    padding: 1px 0 1px 52px;
    font-size: 16px;
    line-height: 1.67;
    letter-spacing: -0.5px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      position: relative;
      display: inline-block;
      padding: 1px 0 1px 28px;
      font-size: 12px;
      letter-spacing: -0.5px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 30px;
      background: url(${SpCommonPng}) no-repeat;
      background-size: 400px auto;
      background-position: -43px -1px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 17px;
        height: 17px;
        background: url(${spCommonMobilePng}) no-repeat;
        background-size: 230px auto;
        background-position: -25px -1px;
      }
      ${(props) =>
        props.agree &&
        css`
          background-position: -43px -31px;
          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            background-position: -25px -18px;
          }
        `}
    }
  }
`;

const TermsOneItem = (props) => {
  const { title, content, confirmMessage, agreeState, setAgreeState } = props;
  const [arrowState, setArrowState] = useState(true);

  let parseContent = content;

  const onClickArrowHandler = () => {
    setArrowState(!arrowState);
  };
  const onClickAgreeHandler = () => {
    setAgreeState(!agreeState);
  };
  return (
    <TermsListBlock>
      <dl>
        <dt>
          {title}
          <ArrowButton on={arrowState} onClick={onClickArrowHandler}>
            <span>보기</span>
          </ArrowButton>
        </dt>
        {arrowState && (
          <DescriptionWrapper active={arrowState}>
            <span
              dangerouslySetInnerHTML={{
                __html: parseContent
              }}
            />
          </DescriptionWrapper>
        )}
        <dt>
          <CheckBoxBlock agree={agreeState}>
            <input type="checkbox" id="terms" onClick={onClickAgreeHandler} />
            <label htmlFor="terms">{confirmMessage}</label>
          </CheckBoxBlock>
        </dt>
      </dl>
    </TermsListBlock>
  );
};

export default TermsOneItem;

TermsOneItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  confirmMessage: PropTypes.string
};
