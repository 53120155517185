import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DataTable from '../../../components/datatable/DataTable';
import SearchInput from '../../../components/input/SearchInput';
import Select from '../../../components/input/Select';
import Page from '../../../components/page/Page';
import { TableTitleBlock } from '../../../components/table/TableTitle';
import { fetchGetIssueIncorporatedPageAction } from '../../../services/api/brokerage/promiseActions';
import {
  ContractState,
  getConstNameByCode
} from '../../../utils/constant/AgmConstant';
import { DateFormatName, getDateFormat } from '../../../utils/DateFormatUtil';
import { DATE_FORMAT } from '../../../utils/DateUtil';
import { phoneFomatter } from '../../../utils/StringUtil';
import { ListWrapBlock } from '../../IssueIncorporated/dashboard/CorpDashboardPage';

const InfoPage = () => {
  const dispatch = useDispatch();
  const [issuers, setIssuers] = useState();
  const [pageConfig, setPageConfig] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    const data = {
      page: pageNumber,
      size: 10,
      name: searchName
    };
    dispatch(fetchGetIssueIncorporatedPageAction(data)).then((res) => {
      if (!res.error) {
        const { number, size, totalElements, totalPages, content } = res.data;
        setIssuers(content);
        setPageConfig({
          pageNumber: number,
          size,
          totalCount: totalElements,
          totalPageNumber: totalPages
        });
      }
    });
  }, [dispatch, pageNumber, searchName]);

  const columns = [
    {
      title: '상태',
      key: 'contractState',
      col: '7%'
    },
    {
      title: '법인명',
      key: 'name',
      col: '13%'
    },
    {
      title: '계약일',
      key: 'contractDate',
      col: '14%'
    },
    {
      title: '계약기간',
      key: 'contractTerm',
      col: '14%'
    },
    {
      title: '이용현황',
      children: [
        {
          title: '전자투표',
          key: 'numberOfElectronicVote'
        },
        {
          title: '전자위임',
          key: 'numberOfElectronicProxy'
        }
      ]
    },
    {
      title: ['최근 전자주총', '시행일'],
      key: 'lastUseDateTime',
      col: '14%'
    },
    {
      title: '담당자 명',
      key: 'corpPersonName',
      col: '10%'
    },
    {
      title: '담당자 연락처',
      key: 'corpPersonPhone',
      col: '13%'
    }
  ];

  const getDataSource = () => {
    let newArr = [];
    if (issuers.length === 0) {
      return newArr;
    } else {
      issuers.forEach((issuer) => {
        newArr.push({
          ...issuer,
          contractState: getConstNameByCode(
            ContractState,
            issuer.contract.contractState
          ),
          contractDate: DATE_FORMAT(
            issuer.contract.applyDateTime,
            getDateFormat(DateFormatName.SHORT_DATE_OTHER_1)
          ),
          contractTerm: `${DATE_FORMAT(
            issuer.contract.applyDateTime,
            getDateFormat(DateFormatName.SHORT_DATE)
          )} ~ ${DATE_FORMAT(
            issuer.contract.contractEndDate,
            getDateFormat(DateFormatName.SHORT_DATE)
          )}`,
          numberOfElectronicVote:
            issuer.usageStatus.numberOfElectronicVote + ' 회',
          numberOfElectronicProxy:
            issuer.usageStatus.numberOfElectronicProxy + ' 회',
          lastUseDateTime: !!issuer.usageStatus.lastUseDateTime
            ? DATE_FORMAT(
                issuer.usageStatus.lastUseDateTime,
                getDateFormat(DateFormatName.SHORT_DATE_OTHER_1)
              )
            : 'X',
          corpPersonName: issuer.personInCharge.name,
          corpPersonPhone: phoneFomatter(issuer.personInCharge.telephone)
        });
      });
    }
    return newArr;
  };

  const onSearch = (value) => {
    setPageNumber(0);
    setSearchName(value);
  };

  const renderSelOptAgendas = () => {
    return <option>발행회사명</option>;
  };

  const onchangeAgenda = () => {};

  const onRowClick = () => {};

  return (
    <Page>
      <ListWrapBlock>
        <TableTitleBlock>
          <span>발행회사 정보 조회</span>
        </TableTitleBlock>
        {issuers && (
          <DataTable
            columns={columns}
            dataSource={getDataSource()}
            filterConfig={{
              desc: '발행회사를 클릭하면 상세 정보를 확인할 수 있습니다.',
              filterItems: (
                <>
                  <Select onchange={onchangeAgenda} noDefault>
                    {renderSelOptAgendas()}
                  </Select>
                  <SearchInput
                    placeholder="검색"
                    onSearch={(value) => onSearch(value)}
                  />
                </>
              )
            }}
            onRowClick={onRowClick}
            //   detailValueNames={['exerciseContent']}
            pageConfig={{
              ...pageConfig,
              onClick: setPageNumber
            }}
          />
        )}
      </ListWrapBlock>
    </Page>
  );
};

export default InfoPage;
