/**
 * 사용하지 않음
 */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: '',
  address: [],
  agmRegister: {
    agmSeqno: null
  },
  currentAgm: {
    agmSeqno: null
  }
};

const reducers = {
  setAgmRegisterNewSeqno: (state, { payload }) => {
    state.agmRegister.agmSeqno = payload;
  },
  setCurrentAgm: (state, { payload }) => {
    state.currentAgm.agmSeqno = 14;
  }
};

export const slice = createSlice({
  name: 'issinc',
  initialState,
  reducers
});

export const reducer = slice.reducer;
export const action = slice.actions;
