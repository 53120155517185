import { useContext } from 'react';
import styled from 'styled-components';
import { ButtonSize } from '../../../../../../../components/button/Button';
import { MODAL_SIZE } from '../../../../../../../components/modal/Modal';
import { ModalContext } from '../../../../../../../context/ModalContext';
import spCommonPng from '../../../../../../../styles/images/common/sprite/sp_common.png';
import { ExerciseMethod } from '../../../../../../shareholder/exerciseRecord/main/BoxInfo';
import { OkBtn } from '../../../../../../shareholder/vote/main/components/popup/common/PopupButton';
import ParticipantsTable from '../../components/ParticipantsTable';
import AgmStatus from '../AgmStatus';

const VoteboxResult = ({ agmSeqno, agendas }) => {
  let { openModal, setModalConfig, closeModal } = useContext(ModalContext);

  const openDetail = (agenda) => {
    const agendaName = !!agenda.childAgendaCount
      ? agenda.concentrateVote
        ? `${agenda.agendaNumber}안 ${agenda.agendaName}(${agenda.childAgendaCount}명)의 건 (집중투표)`
        : `${agenda.agendaNumber}안 ${agenda.agendaName}(${agenda.childAgendaCount}명)의 건 (분리투표)`
      : `${agenda.agendaNumber}안 ${agenda.agendaName}의 건 (찬성/반대)`;
    setModalConfig({
      size: MODAL_SIZE.WIDE,
      buttons: [
        <OkBtn
          size={ButtonSize.Popup}
          onClick={() => {
            closeModal(250);
          }}
        />
      ]
    });
    openModal(
      `${agendaName} 전자투표 행사 내역`,
      <>
        <AgmStatus
          agmSeqno={agmSeqno}
          agenda={agenda}
          exerciseMethod={ExerciseMethod.VOTE}
        />
        <ParticipantsTable
          agmSeqno={agmSeqno}
          stockholders={agenda.participatedStockholders}
          agendaSeqno={agenda.agendaSeqno}
          concentrateVote={agenda.concentrateVote}
          exerciseMethod={ExerciseMethod.VOTE}
        />
      </>
    );
  };

  return (
    <ListType08Block>
      <ul>
        {agendas.map((agenda, index) => {
          return (
            <li>
              <ul>
                <li>
                  <p>
                    {!!agenda.childAgendaCount
                      ? agenda.concentrateVote
                        ? `${agenda.agendaNumber}안 ${agenda.agendaName}(${agenda.childAgendaCount}명)의 건 (집중투표)`
                        : `${agenda.agendaNumber}안 ${agenda.agendaName}(${agenda.childAgendaCount}명)의 건 (분리투표)`
                      : `${agenda.agendaNumber}안 ${agenda.agendaName}의 건 (찬성/반대)`}
                    {/* <span>(불통일로 행사한 찬성 반대 값도 포함되었습니다)</span> */}
                  </p>
                  {(!agenda.childAgendaCount || agenda.concentrateVote) && (
                    <DetailBtnBlock
                      on={true}
                      onClick={() => openDetail(agenda)}
                    >
                      상세정보
                    </DetailBtnBlock>
                  )}
                </li>
                <li>
                  <AgmStatus
                    agmSeqno={agmSeqno}
                    agenda={agenda}
                    exerciseMethod={ExerciseMethod.VOTE}
                  />
                </li>
              </ul>
            </li>
          );
        })}
      </ul>
    </ListType08Block>
  );
};

const ListType08Block = styled.div`
  // .list_ty08
  & ul {
    width: 100%;
  }
  & > ul > li {
    box-sizing: border-box;
    width: 100%;
    letter-spacing: -0.5px;
    position: relative;
    display: block;
    padding: 20px 20px 4px;
    margin: 12px 0;
    background-color: #fff;
    border: solid 1px #ccd5e0;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 12%);

    & > ul > li:last-of-type {
      // temp
      // .list_ty08 .unit .detail
      border-top: solid 1px #e9ecf4;
      padding: 0 80px 40px;
    }
    & > ul > li:first-of-type p {
      font-size: 19px;
      font-weight: bold;
      padding-right: 220px;
      padding-bottom: 12px;
      box-sizing: border-box;
      position: relative;
    }
    & > ul > li:first-of-type button {
      position: absolute;
      top: 8px;
      right: 20px;
      display: inline-block;
      min-width: 120px;
      padding: 10px 30px 9px 12px;
      height: 40px;
      border-radius: 4px;
      box-sizing: border-box;
      line-height: 1.33;
      letter-spacing: -0.5px;
      text-align: center;
    }
  }
`;

const DetailBtnBlock = styled.button`
  &::after {
    content: '';
    position: absolute;
    top: 13px;
    right: 18px;
    width: 9px;
    height: 14px;
    background: url(${spCommonPng}) no-repeat;
    background-size: 400px auto;

    background-position: -311px -1px;
    opacity: 1;
  }
  border: solid 1px #8c939c !important;
  background-color: #fff;
  color: #080808;
  // temp 기존 상세정보 보는 방식이 변경되어 버튼 색 변경
  background-color: #284cb0;
  color: #fff;
  &:hover {
    &::after {
      background-position: -331px -1px;
    }
    background-color: #284cb0;
    color: #fff;
  }
`;

export default VoteboxResult;
