import React from 'react';
import Input from '../../../../../components/input/Input';
import { formatNumber } from '../../../../../utils/StringUtil';
import ResultTableSelect from '../../../register/main/components/ResultTableSelect';
import { getAgendaDisplayName, getDivStyle } from '../AgmResult';

const TotalConcentrateTable = ({
  data,
  onChangeReuslt,
  onChangeCount,
  isEdit
}) => {
  console.log('TotalConcentrateTable Props');
  console.log(data);

  return (
    <div className="tblcommon-01" style={getDivStyle(data.agenda)}>
      <table>
        <colgroup>
          <col width="*%;" />
          <col width="*%;" />
          <col width="*%" />
        </colgroup>
        <thead>
          <tr>
            <th colSpan="3" className="title atv">
              {getAgendaDisplayName(data.agenda)}
              <span className="stock">
                행사 가능 주식수
                <strong className="ft-col02 ">
                  {formatNumber(data.agenda.exercisableStockQuantity)}주
                </strong>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.childs.map((child, idx) => {
            if (child.agenda.agendaSeqno === -1) return null;
            return (
              <tr key={`TotalConcentrateTable-Tr-${idx}`}>
                <th>{child.agenda.agendaName}</th>
                <td className="nRight">
                  {isEdit ? (
                    <Input
                      type="number"
                      onChange={(e) =>
                        onChangeCount(
                          e.target.value,
                          data.agenda.agendaSeqno,
                          child.agenda.agendaSeqno,
                          'ayesCount'
                        )
                      }
                      value={child.totalValue?.ayesCount}
                    />
                  ) : (
                    formatNumber(child.totalValue?.ayesCount)
                  )}
                </td>
                <td>
                  <ResultTableSelect
                    onChange={onChangeReuslt}
                    parentAgendaSeqno={data.agenda.agendaSeqno}
                    agendaSeqno={child.agenda.agendaSeqno}
                    seletcedValue={child.result}
                    disabled={!isEdit}
                    isEdit={isEdit}
                  />
                </td>
              </tr>
            );
          })}
          <tr>
            <th>기권</th>
            <td className="nRight">
              {isEdit ? (
                <Input
                  type="number"
                  onChange={(e) =>
                    onChangeCount(
                      e.target.value,
                      data.agenda.agendaSeqno,
                      data.childs[0].agenda.agendaSeqno,
                      'abstentionCount'
                    )
                  }
                  value={data.childs[0].totalValue?.abstentionCount}
                />
              ) : (
                formatNumber(data.childs[0].totalValue?.abstentionCount)
              )}
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TotalConcentrateTable;
