import PropTypes from 'prop-types'; // ES6
import React from 'react';
import styled, { css } from 'styled-components';
import selectBgPng from '../../styles/images/common/select_bg.png';
export const SelectBlock = styled.select`
  flex: 1;
  ${(props) =>
    !props.noWidth &&
    css`
      width: 100%;
    `}
  margin-right: 4px;
  //
  height: 42px;
  padding: 10px 42px 10px 20px;
  border-radius: 4px;
  border: solid 1px #ccd5e0;
  box-sizing: border-box;
  background: url(${selectBgPng}) no-repeat right center;
  background-color: #fff;
  background-size: 36px auto;
  line-height: 1.33;
`;

const Select = (props) => {
  const {
    register,
    registerOption,
    name,
    children,
    onchange,
    noDefault,
    optionDisabled
  } = props;
  const reg = register ? register(name, registerOption) : {};

  return (
    <>
      {noDefault ? (
        // 선택해주세요가 없는 Select
        typeof onchange !== 'function' ? (
          <SelectBlock {...reg} {...props}>
            {children}
          </SelectBlock>
        ) : (
          // 선택해주세요가 없고, onchange props 필요로 하는 Select
          <SelectBlock {...reg} {...props} onChange={onchange}>
            {children}
          </SelectBlock>
        )
      ) : typeof onchange !== 'function' ? (
        <SelectBlock {...reg} {...props}>
          <option key="select_key_-1" value="-1">
            선택해주세요
          </option>
          {children}
        </SelectBlock>
      ) : (
        <SelectBlock {...reg} {...props} onChange={onchange}>
          <option key="select_key_-1" value="-1" disabled={optionDisabled}>
            선택해주세요
          </option>
          {children}
        </SelectBlock>
      )}
    </>
  );
};

Select.PropsType = {
  name: PropTypes.string,
  register: PropTypes.func,
  placeholder: PropTypes.string,
  onchange: PropTypes.func,
  noDefault: PropTypes.bool,
  optionDisabled: PropTypes.bool
};

Select.defaultProps = {
  name: null,
  register: null,
  placeholder: '',
  noDefault: false,
  optionDisabled: false
};

export default Select;
