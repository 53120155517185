import PropTypes from 'prop-types'; // ES6
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import SpCommonPng from '../../styles/images/common/sprite/sp_common.png';
import { DateFormatName, getDateFormat } from '../../utils/DateFormatUtil';
import { checkInvalidDate, DATE_FORMAT } from '../../utils/DateUtil';
import TimeSelect from '../time/TimeSelect';

const InputWrapperBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
`;
const input_ty = css`
  height: 52px;
  padding: 0 20px;
  border-radius: 8px;
  background-color: #f2f3f6;
  border: solid 1px #f2f3f6;
  box-sizing: border-box;
  line-height: 1.54;
`;

// .input_ty[type=time]
const input_ty_type_date = css`
 max-width: 110px;
    padding-right: 0;
    margin-right: 0;
    border-radius: 8px 0 0 8px;
}
`;

const InputBlock = styled.input`
  flex: 1;
  margin-right: 4px;
  //
  ${input_ty}
  ${input_ty_type_date}
  ${(props) =>
    props.flag &&
    css`
      background-color: #fff;
      border: solid 1px #8c939c;
    `}
  &:hover {
    background-color: #fff;
    border: solid 1px #8c939c;
  }
  &:focus {
    background-color: #fff;
    border: solid 1px #8c939c;
  }
`;

const ButtonBlock = styled.button`
  // .btn_i06
  position: relative;
  padding: 16px 0 15px;
  height: 52px;
  min-width: 56px;
  background-color: #f2f3f6;
  margin-left: -4px;
  margin-right: 4px;
  border-radius: 0 8px 8px 0;

  // .btn_i06::before
  &::before {
    content: '';
    position: absolute;
    top: 40%;
    left: 12px;
    display: block;
    margin-top: -7px;
    background: url(${SpCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -262px -53px;
    width: 24px;
    height: 25px;
  }
`;

const TimeInput = (props) => {
  const { isHalfDay, minUnit, defaultValue, name, callback } = props;
  const [timeResult, setTimeResult] = useState(
    checkInvalidDate(defaultValue)
      ? '  '
      : DATE_FORMAT(defaultValue, getDateFormat(DateFormatName.LONG_TIME))
  );
  const [showTimeBlock, setShowTimeBlock] = useState(false);
  const btnRef = useRef();

  const handleDate = useCallback(() => {
    typeof callback === 'function' && callback(timeResult, name);
  }, [callback, name, timeResult]);

  useEffect(() => {
    !checkInvalidDate(defaultValue) && setTimeResult(defaultValue);
  }, [defaultValue]);

  // 이거 오류 있을 수도 있음.
  useEffect(() => {
    typeof timeResult !== 'undefined' && handleDate();
  }, [timeResult]);

  const timeBlockBtnClick = () => {
    setShowTimeBlock((prev) => !prev);
  };

  const setTime = (data) => {
    setTimeResult(data);
    setShowTimeBlock(false);
    handleDate();
  };

  return (
    <InputWrapperBlock>
      <InputBlock
        value={
          checkInvalidDate(timeResult)
            ? ''
            : DATE_FORMAT(timeResult, getDateFormat(DateFormatName.LONG_TIME))
        }
        flag={showTimeBlock}
        onClick={() => btnRef.current.click()}
        readOnly
      />
      <ButtonBlock onClick={timeBlockBtnClick} ref={btnRef} />
      {showTimeBlock && (
        <TimeSelect
          minUnit={minUnit}
          isHalfDay={isHalfDay}
          setTime={setTime}
          defaultValue={timeResult}
          setShowTimeBlock={setShowTimeBlock}
        />
      )}
    </InputWrapperBlock>
  );
};

TimeInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isHalfDay: PropTypes.bool,
  minUnit: PropTypes.number,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  callback: PropTypes.func
};

TimeInput.defaultProps = {
  name: '',
  placeholder: '',
  isHalfDay: true,
  minUnit: 1,
  defaultValue: ''
};

export default TimeInput;
