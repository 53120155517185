import { all, takeLatest } from '@redux-saga/core/effects';
import APIs from '..';
import createRequestSaga from '../../../utils/createRequestSaga';
import {
  fetchGetTermsAction,
  fetchGetTermsPrivacyPolicyHistoryAction,
  fetchGetTermsSeqnoAction,
  fetchGetTermsSignUpAction,
  fetchGetTermsUniqueInfoTreatmentAction
} from './promiseActions';

// 약관 조회
const fetchGetTermsSaga = createRequestSaga(
  fetchGetTermsAction,
  APIs.Terms.fetchGetTerms
);

// 약관 조회 - 개별
const fetchGetTermsSeqnoSaga = createRequestSaga(
  fetchGetTermsSeqnoAction,
  APIs.Terms.fetchGetTermsSeqno
);

// 약관 조회 - 서비스가입
const fetchGetTermsSignUpSaga = createRequestSaga(
  fetchGetTermsSignUpAction,
  APIs.Terms.fetchGetTermsSignUp
);

// 약관 조회 - 개별
const fetchGetTermsUniqueInfoTreatmentSaga = createRequestSaga(
  fetchGetTermsUniqueInfoTreatmentAction,
  APIs.Terms.fetchGetTermsUniqueInfoTreatment
);

// 약관 조회 - 과거 약관
const fetchGetTermsPrivacyPolicyHistorySaga = createRequestSaga(
  fetchGetTermsPrivacyPolicyHistoryAction,
  APIs.Terms.fetchGetTermsPrivacyPolicyHistory
);

export default function* saga() {
  yield all([
    takeLatest(fetchGetTermsAction, fetchGetTermsSaga),
    takeLatest(fetchGetTermsSeqnoAction, fetchGetTermsSeqnoSaga),
    takeLatest(fetchGetTermsSignUpAction, fetchGetTermsSignUpSaga),
    takeLatest(
      fetchGetTermsUniqueInfoTreatmentAction,
      fetchGetTermsUniqueInfoTreatmentSaga
    ),
    takeLatest(
      fetchGetTermsPrivacyPolicyHistoryAction,
      fetchGetTermsPrivacyPolicyHistorySaga
    )
  ]);
}
