import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../components/button/CommonStyle';
import DataTable from '../../../../components/datatable/DataTable';
import { ListWrapBlock } from '../../../../components/page/PageInner';
import useWindowSize from '../../../../hooks/useWindowSize';
import { fetchGetNoticeAction } from '../../../../services/api/common/promiseActions';
import {
  DateFormatName,
  getDateFormat
} from '../../../../utils/DateFormatUtil';
import { DATE_FORMAT } from '../../../../utils/DateUtil';

const NoticeList = () => {
  const [noticeList, setNoticeList] = useState([]);
  const [pageConfig, setPageConfig] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => {
    return windowSize.width <= SCREEN_SIZE_MOBILE;
  }, [windowSize]);

  useEffect(() => {
    const data = {
      page: pageNumber,
      size: 5
    };
    dispatch(fetchGetNoticeAction(data)).then((res) => {
      if (!res.error) {
        const { number, size, totalElements, totalPages, content } = res.data;
        setNoticeList(content);
        setPageConfig({
          pageNumber: number,
          size,
          totalCount: totalElements,
          totalPageNumber: totalPages
        });
      }
    });
  }, [dispatch, pageNumber]);

  const column = [
    {
      title: '번호',
      key: 'no',
      col: '10%'
    },
    {
      title: '제목',
      key: 'title',
      col: '*'
    },
    {
      title: '등록일',
      key: 'date',
      col: '20%'
    }
  ];

  const onRowClick = (e) => {
    const index = e.currentTarget.rowIndex - 1;
    const notientSeqno = noticeList[index].notientSeqno;
    history.push({
      pathname: `/notice/${notientSeqno}`,
      state: { noticeDetail: noticeList[index] }
    });
  };

  const onMobileRowClick = (rowIndex) => {
    const notientSeqno = noticeList[rowIndex].notientSeqno;
    history.push({
      pathname: `/notice/${notientSeqno}`,
      state: { noticeDetail: noticeList[rowIndex] }
    });
  };

  const getDataSource = () => {
    const newArr = [];
    if (noticeList.length !== 0) {
      noticeList.forEach((item, index) => {
        newArr.push({
          no: ++index,
          title: item.title,
          date: DATE_FORMAT(
            item.registerDateTime,
            getDateFormat(DateFormatName.SHORT_DATE_HYPEN)
          )
        });
      });
    }
    return newArr;
  };

  return (
    <ListWrapBlock>
      {noticeList.length !== 0 && isMobile ? (
        <MobileULBlock>
          {noticeList.map((notice, index) => {
            return (
              <li>
                <p onClick={() => onMobileRowClick(index)}>
                  <p>{notice.title}</p>
                  <strong>
                    {DATE_FORMAT(
                      notice.registerDateTime,
                      getDateFormat(DateFormatName.SHORT_DATE_HYPEN)
                    )}
                  </strong>
                </p>
              </li>
            );
          })}
        </MobileULBlock>
      ) : (
        <DataTable
          columns={column}
          dataSource={getDataSource()}
          shortHeight
          onRowClick={onRowClick}
          pageConfig={{
            ...pageConfig,
            onClick: setPageNumber
          }}
        />
      )}
    </ListWrapBlock>
  );
};

const MobileULBlock = styled.ul`
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    & > li {
      border-bottom: solid 1px #e9ecf4;
      & > p {
        display: block;
        padding: 10px 0;
        & > p {
          font-size: 14px;
          margin-bottom: 4px;
        }
        & > strong {
          font-size: 12px;
          color: #9eb3cd !important;
        }
      }
    }
  }
`;

export default NoticeList;
