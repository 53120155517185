import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useCalendar } from '../../hooks/useCalendar';
import { Icons } from '../../styles/Icons';
import spriteCommonPng from '../../styles/images/common/sprite/sp_common.png';
import {
  checkInvalidDate,
  checkSameDay,
  DATE_CONST,
  getToday
} from '../../utils/DateUtil';
import { STOP_EVENT_FUNCTION } from '../../utils/FunctionUtil';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';
import IconButton from '../button/IconButton';
import DateSelectBox from '../input/DateSelectBox';

const CalBlock = styled.div`
  // .calendar
  width: 360px;
  position: absolute;
  top: 56px;
  left: 0;
  z-index: 500;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: fixed;
    width: 300px;
    left: calc(50% - 150px);
    top: 16%;
  }
`;
const CalBodyBlock = styled.div`
  // .calendar_body
  padding: 10px 6px 6px;
  border-radius: 12px;
  background: #3985d9;
`;
const CalTopBlock = styled.div`
  // .calendar_top
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
`;
const LayerBodyBlock = styled.div`
  // .layer_body
  max-height: 1000000px;
  margin: 0;
  padding: 0;
`;
const PrevNextBtn = styled.button`
  // .prev & .next
  position: relative;
  display: inline-block;
  width: 20px;
  margin: 0 4px;
  height: 18px;
  background: transparent;
  &::after {
    content: '';
    position: absolute;
    top: 2px;
    display: block;
    width: 8px;
    height: 14px;
    background: url(${spriteCommonPng}) no-repeat;
    background-size: 400px auto;
    ${(props) =>
      props.direction === 'left'
        ? css`
            left: 5px;
            background-position: -321px -1px;
          `
        : css`
            right: 5px;
            background-position: -331px -1px;
          `}
  }
`;
const CalBoxBlock = styled.div`
  // .calendar_box
  border-radius: 5px;
  background: #ffffff;
  padding: 8px 7px 10px 7px;
`;
const DayBlock = styled.ul`
  // .day
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DateBlock = styled.ul`
  // .date
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DayItem = styled.li`
  text-align: center;
  margin-bottom: 5px;
  font-size: 12px;
  flex: 1;
  margin: 1px;
  padding: 6px 8px 5px;
  border-radius: 3px;
`;
const DateItemBlock = styled.li`
  flex: 1;
  margin: 1px;
  padding: 6px 8px 5px;
  border-radius: 3px;
  text-align: right;
  ${(props) =>
    // .date2
    props.otherMonth ? `background: #F2F3F6;` : `background: white;`}
  ${(props) => props.today && `background: #D0E6FF;`}
  ${(props) => props.choice && `background: #0572ef;`}
  & > a {
    color: #080808;
    text-decoration: none;
    ${(props) => props.choice && `color: #fff;`}
  }
  &:focus,
  &:hover {
    // .sel
    & > a {
      color: #fff;
    }
    background: #0572ef;
  }
`;

const Calendar = (props) => {
  const { setDate, selDate, setCalendarFlag } = props;
  const calRef = useRef();
  const today = getToday();
  const [choiceDay, setChoiceDay] = useState(
    checkInvalidDate(selDate) ? new Date() : selDate
  );
  const [date, weekArr, events] = useCalendar(
    checkInvalidDate(selDate) ? new Date() : selDate
  );
  const { setPrevMonth, setNextMonth, changeMonth, changeYear } = events;

  useEffect(() => {
    if (typeof selDate !== 'undefined') {
      setChoiceDay(selDate);
    }
  }, [selDate]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleClickOutside = (event) => {
    if (calRef && !calRef.current.contains(event.target)) {
      setCalendarFlag(false);
    } else {
      setCalendarFlag(true);
    }
  };

  const onclickDate = (e) => {
    const date = new Date(e.target.dataset.date);
    setChoiceDay(date);
    if (typeof setDate === 'function') {
      setDate(date);
    }
    props.setTimeFlag && props.setTimeFlag(true);
  };

  const onClickBtn = (e, liBtn) => {
    STOP_EVENT_FUNCTION(e);
    liBtn.current.click();
  };

  const DateItem = ({ value, index }) => {
    const liBtn = useRef(index);
    return (
      <DateItemBlock
        key={`dateItem_${value}`}
        otherMonth={date.getMonth() !== value.getMonth()}
        onClick={onclickDate}
        data-year={value.getFullYear()}
        data-month={value.getMonth() + 1}
        data-day={value.getDate()}
        data-date={value}
        ref={liBtn}
        today={checkSameDay(today, value)}
        choice={checkSameDay(choiceDay, value)}
      >
        <a href={`#!`} onClick={(e) => onClickBtn(e, liBtn)}>
          {value.getDate()}
        </a>
      </DateItemBlock>
    );
  };

  const renderDays = () => {
    return (
      <DayBlock>
        {DATE_CONST.WEEK_NAMES.map((data, index) => {
          return <DayItem key={`dayKey_${index}`}>{data}</DayItem>;
        })}
      </DayBlock>
    );
  };
  const renderCalendar = () => {
    return (
      <>
        {weekArr.map((data, index) => (
          <DateBlock key={`dateBlock_${index}`}>
            {data.map((day, idx) => {
              return <DateItem key={`week_${idx}`} index={idx} value={day} />;
            })}
          </DateBlock>
        ))}
      </>
    );
  };

  return (
    <CalBlock ref={calRef}>
      <CalBodyBlock>
        <CalTopBlock>
          <PrevNextBtn direction="left" onClick={setPrevMonth} />
          <DateSelectBox
            date={date}
            changeMonth={changeMonth}
            changeYear={changeYear}
          />
          <PrevNextBtn direction="right" onClick={setNextMonth} />
        </CalTopBlock>
        <LayerBodyBlock>
          <CalBoxBlock>
            {renderDays()}
            {renderCalendar()}
          </CalBoxBlock>
        </LayerBodyBlock>
      </CalBodyBlock>
      <IconButton
        iconName={Icons.calendar_close.name}
        onClick={() => setCalendarFlag((prev) => !prev)}
      />
    </CalBlock>
  );
};

export default Calendar;
