import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CommonStyles from '../../styles/CommonStyles';
import { getCurrentDatetime } from '../../utils/DateUtil';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';
import TermsCheckbox from './TermsCheckbox';
import TermsItem from './TermsItem';
const TermsTitle = styled.div`
  font-size: 27px;
  font-weight: bold;
  color: ${CommonStyles.THEME_COLOR};
`;

const TermsBlock = styled.div`
  & > ul {
    position: relative;
    & > li {
      width: 100%;
      padding-bottom: 20px;
    }
    & > li:last-of-type {
      padding-bottom: 80px;
      border-bottom: solid 1px #e9ecf4;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        padding-bottom: 16px;
        border-bottom: none;
      }
    }
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      & > li:first-of-type {
        display: none;
      }
    }
  }
`;

const TermsItemList = styled.dl`
  padding: 24px 0 0 52px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding: 12px 0 0 28px;
  }
`;

const TermsList = styled.div`
  display: list-item;
  margin-top: 16px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin-top: 14px;
  }
`;

const Terms = (props) => {
  const { data, termsCheckArray, setTermsCheckArray, setIsAllChecked } = props;
  const [allChecked, setAllChecked] = useState(false);
  const [checkedArr, setCheckedArr] = useState([]);
  const checkRef = useRef(false);
  useEffect(() => {
    if (data) {
      const termsArr = data.map((terms) => ({
        type: terms.type,
        compulsory: terms.compulsory,
        checked: false,
        checkedDttm: null
      }));
      setTermsCheckArray(termsArr);
    }
  }, [data]);

  const onCheckTerms = useCallback(
    (type, checked) => {
      if (termsCheckArray.length !== 0) {
        const changedArr = termsCheckArray.map((item) => {
          if (item.type === type) {
            return {
              ...item,
              checked: checked,
              checkedDttm: checked ? getCurrentDatetime() : null
            };
          } else {
            return item;
          }
        });
        setTermsCheckArray(changedArr);
        setCheckedArr(changedArr);
        const checkedArr = changedArr.filter((item) => item.checked);

        if (checkedArr.length === termsCheckArray.length) {
          checkRef.current = true;
        }

        setAllChecked(checkedArr.length === termsCheckArray.length);
        typeof setIsAllChecked === 'function' &&
          setIsAllChecked(checkedArr.length === termsCheckArray.length);
      }
    },
    [termsCheckArray, setIsAllChecked, setTermsCheckArray]
  );

  const onAllCheckTerms = useCallback(
    (type, checked) => {
      if (!checkedArr.find((item) => item.checked === checked)) {
        const changedArr = termsCheckArray.map((item) => {
          return {
            ...item,
            checked: checked,
            checkedDttm: checked ? getCurrentDatetime() : null
          };
        });
        setTermsCheckArray(changedArr);
      } else {
        if (!checkRef.current) {
          if (!allChecked && !checked) {
          } else {
            const changedArr = termsCheckArray.map((item) => {
              return {
                ...item,
                checked: checked,
                checkedDttm: checked ? getCurrentDatetime() : null
              };
            });
            setTermsCheckArray(changedArr);
          }
        }
      }
      checkRef.current = false;
    },
    [termsCheckArray]
  );

  return (
    <TermsBlock>
      <ul>
        <li>
          <TermsTitle>이용약관</TermsTitle>
        </li>
        <li>
          <TermsList>
            <TermsCheckbox onCheckTerms={onAllCheckTerms} checked={allChecked}>
              전체 동의
            </TermsCheckbox>
            <TermsItemList>
              {data?.map((item, idx) => (
                <TermsItem
                  key={`termsItem_${idx}`}
                  onCheckTerms={onCheckTerms}
                  title={`${item.title}`}
                  compulsory={item.compulsory}
                  type={item.type}
                  checked={
                    termsCheckArray.find(
                      (termsCheck) => termsCheck.type === item.type
                    )?.checked
                  }
                >
                  {item.content}
                </TermsItem>
              ))}
            </TermsItemList>
          </TermsList>
        </li>
      </ul>
    </TermsBlock>
  );
};

export default Terms;
