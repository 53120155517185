import { useEffect, useState } from 'react';
import { ButtonSize } from '../../../../../../../../components/button/Button';
import Radio from '../../../../../../../../components/input/Radio';
import RadioGroup from '../../../../../../../../components/input/RadioGroup';
import {
  DateFormatName,
  getDateFormat
} from '../../../../../../../../utils/DateFormatUtil';
import { DATE_FORMAT } from '../../../../../../../../utils/DateUtil';
import {
  CancelBtn,
  OkBtn
} from '../../../../../../../shareholder/vote/main/components/popup/common/PopupButton';
import {
  BtnAreaBlockWrapper,
  KakaoAppBlock,
  KakaoBoxTopBlock,
  KakaoDescBlock,
  KakaoTextAreaBlock,
  ListType10BlockWrapper,
  ListType11Format,
  UnitTitle
} from '../../layout/LayoutWrapper';

const KakaoPopupForm = (props) => {
  const {
    register,
    closeModal,
    handleSubmit,
    onSubmitRegister,
    appendText,
    agm
  } = props;
  const [popupRadioVal, setPopupRadioVal] = useState(
    KAKAO_CONTENT_TYPE.MANUAL.value
  );
  const [appendContent, setAppendContent] = useState();

  useEffect(() => {
    !!appendText && setAppendContent(appendText);
  }, [appendText]);

  const onClickElecBillOkBtn = () => {
    if (popupRadioVal === KAKAO_CONTENT_TYPE.AUTO.value) {
      typeof onSubmitRegister === 'function' &&
        onSubmitRegister({ content: '', initFlag: true });
    } else {
      handleSubmit((data) => {
        typeof onSubmitRegister === 'function' &&
          onSubmitRegister({
            content: data.noticeContent,
            initFlag: false
          });
      })();
    }
    closeModal(250);
  };
  return (
    <>
      <ListType10BlockWrapper>
        <ul>
          <li>
            <UnitTitle>
              <span>내용 </span>
              <strong>(필수)</strong>
            </UnitTitle>
          </li>
          <li>
            <div>
              <RadioGroup
                selectedValue={popupRadioVal}
                onChange={(value) => setPopupRadioVal(value)}
              >
                <Radio
                  label={KAKAO_CONTENT_TYPE.MANUAL.text}
                  value={KAKAO_CONTENT_TYPE.MANUAL.value}
                  key={`kakao_radio_key_manual`}
                />
                <Radio
                  label={KAKAO_CONTENT_TYPE.AUTO.text}
                  value={KAKAO_CONTENT_TYPE.AUTO.value}
                  key={`kakao_radio_key_auto`}
                />
              </RadioGroup>
            </div>
          </li>
        </ul>
      </ListType10BlockWrapper>
      <ListType11Format>
        <KakaoAppBlock>
          <li>
            <p>블록체인 전자주총</p>
          </li>
          <li>
            <KakaoBoxTopBlock>알림도착</KakaoBoxTopBlock>
            <p>블록체인 전자주총에서 전자고지서를 보내 드립니다.</p>
            <p>
              [주주총회 소집통지서]
              <br />
              고객님의 소중한 의결권 행사를 위한 주주총회 관련 안내문입니다.
              하단의 [열람하기]를 눌러 주주총회의 상세정보를 확인해주세요.
            </p>
            <p>
              {agm?.issueIncorporatedName}은 「주식 · 사채 등의 전자등록에 관한
              법률」제38조 · 제70조에 근거하여 권리항사에 관한 업무를 수행하기
              위하여 본 메시지를 발송합니다.
            </p>
            {popupRadioVal === KAKAO_CONTENT_TYPE.MANUAL.value && (
              <KakaoTextAreaBlock
                placeholder="추가내용 기입"
                {...register('noticeContent')}
                defaultValue={appendContent}
              ></KakaoTextAreaBlock>
            )}
            <p>
              - 보낸이 : {agm?.issueIncorporatedName}
              <br />- 내용 : 주주총회 소집통지서
              <br />- 열람가능시간 :
              {DATE_FORMAT(
                agm?.agmDateTime,
                getDateFormat(DateFormatName.LONG_DATE_TIME)
              )}
              까지
              <br />- 고객센터 : 1899-1433
            </p>
            <KakaoDescBlock>
              본 기관은 기존에 우편으로 전달 드리던 <br />
              각종 중요문서, 통지서를 고객님의 카톡을 통해 전달하고자 합니다.
              안내문을 쉽고, 편하게 받아보시려면 아래 열람 버튼을 눌러주세요.
            </KakaoDescBlock>
            <p>
              <button>열람하기</button>
              <button style={{ marginLeft: '3%' }}>내문서함 홈</button>
            </p>
          </li>
        </KakaoAppBlock>
      </ListType11Format>
      <BtnAreaBlockWrapper>
        <ul>
          <li>
            <CancelBtn
              onClick={() => closeModal(250)}
              size={ButtonSize.Popup}
            />
            <OkBtn onClick={onClickElecBillOkBtn} size={ButtonSize.Popup} />
          </li>
        </ul>
      </BtnAreaBlockWrapper>
    </>
  );
};

const KAKAO_CONTENT_TYPE = {
  MANUAL: {
    text: '내용 추가',
    value: '1'
  },
  AUTO: {
    text: '자동 완성',
    value: '2'
  }
};

export default KakaoPopupForm;
