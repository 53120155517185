import React from 'react';
import styled, { css } from 'styled-components';
import CommonStyles from '../../styles/CommonStyles';
import Button, { ButtonSize, ButtonTypes } from '../button/Button';
const TableTitleSpanBlock = styled.span`
  position: relative;
  display: inline-block;
  padding-left: 18px;
  font-size: 20px;
  font-weight: bold;
  ${(props) =>
    props.small &&
    css`
      font-size: 17px;
    `}
  &::after {
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    display: block;
    width: 3px;
    height: 18px;
    margin-top: -4.5px;
    background: ${CommonStyles.THEME_COLOR};
  }
`;

const TableTitle = ({ children, small = false, btnTitle, btnHandler }) => {
  return (
    <>
      <TableTitleSpanBlock small={small}>{children}</TableTitleSpanBlock>
      {btnTitle && (
        <div style={{ float: 'right', marginBottom: '5px' }}>
          <Button
            type={ButtonTypes.Primary}
            size={ButtonSize.Table}
            onClick={btnHandler}
          >
            {btnTitle}
          </Button>
        </div>
      )}
    </>
  );
};

export const TableTitleBlock = ({ children }) => {
  return <TableTitleDivBlock>{children}</TableTitleDivBlock>;
};

const TableTitleDivBlock = styled.div`
  // .tbl_ty
  display: block;
  margin-top: 50px;
  margin-bottom: 12px;
  position: relative;
  & > span {
    position: relative;
    display: inline-block;
    padding-left: 18px;
    font-size: 20px;
    font-weight: bold;
    &::after {
      content: '';
      position: absolute;
      top: 6px;
      left: 0;
      display: block;
      width: 3px;
      height: 18px;
      margin-top: -4.5px;
      background: #0572ef;
    }
  }
  & > div {
    // 넣어서 문제 생길수도..?
    margin: 0 auto 40px 0;
  }
`;

export default TableTitle;
