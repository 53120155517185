import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { idpwsign, SIGN_TYPE } from '../../../components/auth/SignIframe';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import Input, { ErrorTypes } from '../../../components/input/Input';
import InputBox from '../../../components/input/InputBox';
import ListHeader from '../../../components/layout/ListHeader';
import ListItem from '../../../components/layout/ListItem';
import Section from '../../../components/layout/Section';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import Terms from '../../../components/terms/Terms';
import { ModalContext } from '../../../context/ModalContext';
import { fetchGetExternalOrganizationsAction } from '../../../services/api/externals/promiseActions';
import { fetchPostIssueIncorporatedAction } from '../../../services/api/issueIncorporated/promiseActions';
import { fetchGetTermsSignUpAction } from '../../../services/api/terms/promiseActions';
import { Icons } from '../../../styles/Icons';
import { Role } from '../../../utils/constant/AgmConstant';
import { getCurrentDatetime } from '../../../utils/DateUtil';
import Validation from '../../../utils/ValidationUtil';
import CorporationInfo from '../../test/Test/page/CorporationInfo';
import CorporationManager from '../../test/Test/page/CorporationManager';

export const AgreeType = {
  CELLPH_NOTI_AGREE_DTTM: 'cellphNotificationAgreeDateTime',
  EMAIL_NOTI_AGREE_DTTM: 'emailNotificationAgreeDateTime',
  MARKTN_AGREE_DTTM: 'marketingAgreeDateTime',
  UTIL_CLAUSE_AGREE_DTTM: 'utilClauseAgreeDateTime',
  PRIVACY_POLICY_AGREE_DTTM: 'individualInformationTreatAgreeDateTime'
};

const IssueIncorporated = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    reset,
    setValue,
    getValues
  } = useForm({ mode: 'onChange' });
  // const [issIncNo, setIssIncNo] = useState();
  const [fileUploadedData, setFileUploadedData] = useState([]);
  const [termsCheckArray, setTermsCheckArray] = useState([]);
  const [termsData, setTermsData] = useState();
  const history = useHistory();
  const pwdDefaultAlertMsg = [
    '조회 전용 비밀번호를 설정하여 주세요.',
    '8~16자리 영문, 숫자, 특수문자 사용하세요'
  ];
  const [pwdCheckAlertMsg] = useState('비밀번호를 한 번 더 입력해주세요');

  let { openModal } = useContext(ModalContext);

  // Test Login
  useEffect(() => {
    const params = {
      role: Role.ISSINC_ADMIN.code
    };
    dispatch(fetchGetTermsSignUpAction(params)).then((res) => {
      if (!res.error) {
        setTermsData(res.data);
      }
    });
  }, [dispatch]);

  /* 동의 관련 데이터 */
  const [agreeTimeMap, setAgreeTimeMap] = useState({
    [AgreeType.CELLPH_NOTI_AGREE_DTTM]: null,
    [AgreeType.EMAIL_NOTI_AGREE_DTTM]: null,
    [AgreeType.MARKTN_AGREE_DTTM]: getCurrentDatetime(),
    [AgreeType.UTIL_CLAUSE_AGREE_DTTM]: getCurrentDatetime(),
    [AgreeType.PRIVACY_POLICY_AGREE_DTTM]: getCurrentDatetime()
  });

  const fetchOrganizations = () => {
    const issIncNo = getValues('issIncNo');
    if (issIncNo === '') {
      return;
    }
    dispatch(fetchGetExternalOrganizationsAction({ searchText: issIncNo }))
      .then((res) => {
        console.log('res ././....!');
        console.log(res.data.response.body);
        if (res.data.response.body.items.item.length === 0) {
          openModal(
            '법인번호 오류',
            <>
              <p>법인등록번호를 찾을 수 없습니다.</p>
              <p>
                법인번호를 확인 후 재입력 하시거나, 아래의 가입정보를 수기로
                입력해주세요.
              </p>
            </>
          );
        } else {
          const data = res.data.response.body.items.item[0]; // 기업정보
          if (data.baseAddress === data.detailAddress) {
            data.fullAddress = data.baseAddress;
          } else {
            data.fullAddress = data.baseAddress + data.detailAddress;
          }
          reset(data);
        }
      })
      .catch((e) => console.log(e));
  };

  const onSignup = (formData, sign) => {
    const data = {
      snData: formData.bizRegistrationNumber,
      signData: sign
      /*
      authenticationMethod: AuthenticationMethod.CO_CERTIFICATE.code,
      utilClauseAgreeDateTime: agreeTimeMap.utilClauseAgreeDateTime,
      marketingAgreeDateTime: agreeTimeMap.marketingAgreeDateTime,
      personInCharge: {
        ...formData.personInCharge,
        ...agreeTimeMap,
      },
      files: fileUploadedData,
      */
    };
    dispatch(fetchPostIssueIncorporatedAction({ data })).then((res) => {
      if (!res.error) {
        openModal('확인', '가입되었습니다!', () => history.push('/login'));
      } else {
        openModal('에러', res.error.dev_message);
      }
    });
  };

  const onHandleSignup = () => {
    handleSubmit((data) => {
      const date = new Date().toISOString();
      const payload = {
        ...data,
        utilClauseAgreeDateTime: date,
        marketingAgreeDateTime: date,
        personInCharge: {
          ...data.personInCharge,
          cellphNotificationAgreeDateTime: date,
          emailNotificationAgreeDateTime: date,
          utilClauseAgreeDateTime: date,
          marketingAgreeDateTime: date,
          individualInformationTreatAgreeDateTime: date
        },
        files: fileUploadedData
      };
      idpwsign(SIGN_TYPE.CORP, (signData) => onSignup(data, signData), payload);
    })();
  };

  const pwdWatchFields = watch('password');

  const checkLength = (value) => {
    if (value.toString().length === 13 || value.toString().length === 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Page gnb={null}>
      <PageHeader title="발행회사 계약 신청" />
      <Section>
        <Terms
          data={termsData}
          termsCheckArray={termsCheckArray}
          setTermsCheckArray={setTermsCheckArray}
        />
      </Section>
      <Section>
        <ListHeader title="발행회사" />
        <ListItem>
          <InputBox
            title="검색 법인등록번호"
            error={errors.issIncNo?.message}
            errorType={ErrorTypes.ERROR}
          >
            <Input
              register={register}
              name="issIncNo"
              registerOption={{
                pattern: {
                  value: /^[0-9]+$/,
                  message: '숫자만 입력 가능합니다.'
                },
                validate: {
                  length: (value) =>
                    checkLength(value) || '법인등록번호는 13자리여야 합니다'
                }
              }}
              placeholder="'-'를 제외한 법인등록번호를 입력해주세요"
            />
            <Button
              type={ButtonTypes.Basic}
              startIcon={Icons.search_white}
              onClick={fetchOrganizations}
            >
              조회
            </Button>
          </InputBox>
          <InputBox isEmpty />
        </ListItem>
      </Section>
      <Section>
        <ListHeader title="가입정보" isRequired />
        <CorporationInfo
          register={register}
          setFileUploadedData={setFileUploadedData}
          setValue={setValue}
          watch={watch}
          errors={errors}
        />
      </Section>
      <Section>
        <ListHeader title="비밀번호" />
        <ListItem>
          <InputBox
            title="비밀번호"
            isRequired
            error={
              Object.keys(dirtyFields).includes('password')
                ? errors.password?.message
                : pwdDefaultAlertMsg
            }
            errorType={
              Object.keys(dirtyFields).includes('password')
                ? ErrorTypes.ERROR
                : ErrorTypes.DESCRIPTION
            }
          >
            <Input
              type="password"
              register={register}
              name="password"
              registerOption={Validation.PASSWORD}
              // onChange={onChangePwd}
            />
          </InputBox>
          <InputBox
            title="비밀번호 확인"
            isRequired
            // error={pwdCheckAlertMsg}
            error={
              Object.keys(dirtyFields).includes('password')
                ? errors.passwordForCheck?.message
                : pwdCheckAlertMsg
            }
            errorType={
              Object.keys(dirtyFields).includes('passwordForCheck')
                ? ErrorTypes.ERROR
                : ErrorTypes.DESCRIPTION
            }
          >
            <Input
              type="password"
              register={register}
              name="passwordForCheck"
              registerOption={{
                isRequired: true,
                validate: (value) =>
                  value === pwdWatchFields || '비밀번호가 일치하지 않습니다.'
              }}
            />
          </InputBox>
        </ListItem>
      </Section>
      <Section>
        <ListHeader title="발행회사 담당자" />
        <CorporationManager
          register={register}
          errors={errors}
          agreeTimeMap={agreeTimeMap}
          setAgreeTimeMap={setAgreeTimeMap}
        />
      </Section>
      {/*
      <Section>
        <ButtonGroup>
          <Button
            type={ButtonTypes.Outline}
            size={ButtonSize.Large}
            endIcon={Icons.search_blue}
          >
            전자투표관리서비스 위탁계약서
          </Button>
          <Button
            type={ButtonTypes.Outline}
            size={ButtonSize.Large}
            endIcon={Icons.search_blue}
          >
            전자위임장관리서비스 위탁계약서
          </Button>
        </ButtonGroup>
      </Section>
      */}
      <ButtonGroup>
        <Button
          type={ButtonTypes.Primary}
          size={ButtonSize.Large}
          onClick={onHandleSignup}
        >
          법인 공인인증서 등록 및 가입
        </Button>
      </ButtonGroup>
    </Page>
  );
};

export default IssueIncorporated;
