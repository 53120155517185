import React from 'react';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';
import PageTitle from './PageTitle';

const PageHeaderBlock = styled.div`
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    height: 44px;
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #365289;

    /* z-index: 1; */
  }
`;

const PageHeader = ({ title, children, noHamburger }) => {
  return (
    <PageHeaderBlock>
      <PageTitle title={title} noHamburger={noHamburger} />
      {children}
    </PageHeaderBlock>
  );
};

export default PageHeader;
