import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SIGN_TYPE } from '../../../../components/auth/SignIframe';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../components/button/Button';
import DataTable from '../../../../components/datatable/DataTable';
import Input from '../../../../components/input/Input';
import Select from '../../../../components/input/Select';
import { TableDataType } from '../../../../components/table/Table';
import {
  fetchPostAgmElectionVoterAction,
  fetchPostAgmElectionVoterWithSignAction
} from '../../../../services/api/agm/promiseActions';
import { ElecVoterClassCode } from '../../../../utils/constant/AgmConstant';
import {
  checkUndefinedNum,
  formatRealNumber
} from '../../../../utils/StringUtil';
import Validation from '../../../../utils/ValidationUtil';
import SubModal from './main/SubModal';

const AgmRegisterStep4SubModal = (props) => {
  const dispatch = useDispatch();
  const {
    resetValue,
    handleModal,
    agmSeqno,
    baseInfo,
    idpwsign,
    btnTitle,
    btnHandler,
    stockholderRealNameNumber,
    setUpdateStockholders
  } = props;
  const { register, setValue, handleSubmit } = useForm();

  /*
  useEffect(() => {
    setValue('stockholderHashNumber', resetValue.stockholderRealNameNumber);
  }, []);
  */
  const columns = [
    {
      title: '주주명부상',
      key: 'a1',
      type: TableDataType.NUMBER,
      children: [
        {
          title: '주주명',
          key: 'stockholderName',
          col: '14%'
        },
        {
          title: '실명번호',
          key: 'stockholderRealNameNumber',
          col: '21%'
        }
      ]
    },
    {
      title: '투표권자',
      children: [
        {
          title: '투표권자명',
          key: 'elecVoterName',
          col: '14%'
        },
        {
          title: '실명번호',
          key: 'elecVoterRealNameNumber',
          col: '21%'
        }
      ]
    },
    {
      title: '대리인 유형',
      key: 'elecVoterClassCode',
      col: '*'
    }
  ];

  const onInput = (e) => {
    const value = e.target.value;
    if (value.match(/[^0-9]+$/)) {
      setValue('elecVoterRealNameNumber', value.substring(0, value.length - 1));
    }
  };

  const getDataSource = () => {
    let newArr = [];
    newArr.push({
      index: 0, // <CheckBox checked={i % 2 ? true : false} />,
      ...resetValue,
      stockholderRealNameNumber: stockholderRealNameNumber
        ? formatRealNumber(stockholderRealNameNumber)
        : resetValue.stockholderRealNameNumber,
      elecVoterName: (
        <Input
          register={register}
          name="elecVoterName"
          size="short"
          placeholder="이름"
          registerOption={Validation.NAME}
        />
      ),
      elecVoterRealNameNumber: (
        <Input
          register={register}
          name="elecVoterRealNameNumber"
          size="short"
          placeholder="숫자만 입력"
          onInput={onInput}
          registerOption={{ required: true, maxLength: 13 }}
        />
      ),
      elecVoterClassCode: (
        <InputWrapBlock>
          <Select
            register={register}
            name="elecVoterClassCode"
            registerOption={{
              validate: (value) =>
                parseInt(value) > 0 || '대리인 유형을 선택해주세요'
            }}
          >
            <option value={ElecVoterClassCode.INSTITUTIONAL_INVESTORS.code}>
              {ElecVoterClassCode.INSTITUTIONAL_INVESTORS.name}
            </option>
            <option value={ElecVoterClassCode.STANDING_PROXY.code}>
              {ElecVoterClassCode.STANDING_PROXY.name}
            </option>
            <option value={ElecVoterClassCode.DR_CUSTODIAN.code}>
              {ElecVoterClassCode.DR_CUSTODIAN.name}
            </option>
            <option value={ElecVoterClassCode.CORPORATION.code}>
              {ElecVoterClassCode.CORPORATION.name}
            </option>
            <option value={ElecVoterClassCode.PERSONAL.code}>
              {ElecVoterClassCode.PERSONAL.name}
            </option>
          </Select>
        </InputWrapBlock>
      )
    });
    return newArr;
  };

  const changeVoter = (signData, payload) => {
    const data = {
      signData: signData
      // electionVoters: [payload]
    };
    dispatch(fetchPostAgmElectionVoterWithSignAction({ agmSeqno, data })).then(
      (res) => {
        if (!res.error) {
          typeof setUpdateStockholders === 'function' &&
            setUpdateStockholders(
              resetValue.details.map((item, index) => {
                return {
                  no: ++index,
                  stockholderName: resetValue.stockholderName,
                  ...item,
                  elecVoterName: payload.electionVoters[0]?.elecVoterName,
                  sumStockAmount:
                    item.commonStockAmount +
                    checkUndefinedNum(item.preferredStockAmount)
                };
              })
            );
          handleModal();
        } else {
          alert(res.error.user_message);
        }
      }
    );
  };

  const onclickChangeBtn = (data) => {
    if (baseInfo) {
      idpwsign(SIGN_TYPE.PERSON, changeVoter, {
        electionVoters: new Array({
          ...data,
          elecVoterRealNameNumber: btoa(data.elecVoterRealNameNumber),
          stockholderHashNumber: btoa(stockholderRealNameNumber),
          reason: '전자투표권자 변경'
        })
      });
    } else {
      data = new Array({
        ...data,
        elecVoterRealNameNumber: btoa(data.elecVoterRealNameNumber),
        stockholderHashNumber: btoa(stockholderRealNameNumber),
        reason: '전자투표권자 변경'
      });
      dispatch(fetchPostAgmElectionVoterAction({ agmSeqno, data })).then(
        (res) => {
          if (!res.error) {
            typeof setUpdateStockholders === 'function' &&
              setUpdateStockholders(
                resetValue.details.map((item, index) => {
                  return {
                    no: ++index,
                    stockholderName: resetValue.stockholderName,
                    ...item,
                    elecVoterName: data[0]?.elecVoterName,
                    sumStockAmount:
                      item.commonStockAmount +
                      checkUndefinedNum(item.preferredStockAmount)
                  };
                })
              );
            handleModal();
          } else {
            alert(res.error.user_message);
          }
        }
      );
    }
  };

  const handleOnChangeBtn = (e) => {
    if (stockholderRealNameNumber) {
      handleSubmit(onclickChangeBtn)();
    } else {
      alert('실명번호를 조회 후 시도해주세요.');
    }
  };

  const actionItems = () => {
    return (
      <>
        <Button
          type={ButtonTypes.Basic}
          size={ButtonSize.Table}
          onClick={handleModal}
        >
          취소
        </Button>
        <Button
          type={ButtonTypes.Primary}
          size={ButtonSize.Table}
          onClick={handleOnChangeBtn}
        >
          변경
        </Button>
      </>
    );
  };

  return (
    <>
      <SubModal
        title="전자투표권자 지정"
        subTitle="보유 내역"
        handleModal={(flag) => props.handleModal(flag)}
        btnTitle={btnTitle}
        btnHandler={btnHandler}
        content={
          <DataTable
            columns={columns}
            dataSource={getDataSource()}
            actionItems={actionItems()}
            description="실명번호는 주민등록번호/사업자등록번호를 입력해 주세요."
          />
        }
        isNoButton
      />
    </>
  );
};

const InputWrapBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
`;

export default AgmRegisterStep4SubModal;
