import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  fetchGetAgmBaseInfoAction,
  fetchGetAgmOpenedAction
} from '../../services/api/agm/promiseActions';
import CommonStyles from '../../styles/CommonStyles';
import { AgmStateCd, Role } from '../../utils/constant/AgmConstant';
import { InputWrapperBlock } from '../input/InputBox';
import Select, { SelectBlock } from '../input/Select';

const SubMenuBlock = styled.section`
  height: 78px;
  background: rgba(207, 208, 212, 0.2);
  position: relative;
  z-index: 199;
`;

const Inner = styled.div`
  max-width: 1360px;
  height: 78px;
  margin: 0 auto;
  position: relative;
`;

const MenuWrapBlock = styled.div`
  position: relative;
  width: auto;
  height: 78px;
  padding: 0 0 0 360px;
  text-align: center;
  &::before {
    content: '';
    position: absolute;
    left: 360px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 1px;
    height: 24px;
    background-color: #ccd5e0;
  }
`;

const MenuWrapUlBlock = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
`;

const MenuWrapLiBlock = styled.li`
  flex: auto;
  & > a {
    display: flex;
    width: 100%;
    height: 100%;
    min-width: auto;
    align-items: center;
    justify-content: right;
    color: #080808;
    font-size: 16px;
    font-weight: bold;
  }
  ${(props) =>
    props.$on &&
    css`
      & a {
        color: ${CommonStyles.SUB_MENU_COLOR};
        font-size: 21px;
      }
    `}
  ${(props) =>
    props.disabled &&
    css`
      & a {
        color: #bebebe;
      }
    `}
`;

const SubMenu = ({ setBaseInfo }) => {
  const [openedList, setOpenedList] = useState([]);
  const role = useSelector((state) => state.common.user.role);
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { agmSeqno, agmMenu } = match.params;
  const [isElecVoteEnd, setIsElecVoteEnd] = useState(true);

  useEffect(() => {
    dispatch(fetchGetAgmBaseInfoAction(agmSeqno)).then((res) => {
      if (!res.error) {
        setBaseInfo(res.data);
        const stateCd = res.data.agmStateCode;
        const isElecVoteEnd =
          stateCd === AgmStateCd.PLACE || stateCd === AgmStateCd.END;
        setIsElecVoteEnd(isElecVoteEnd);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(fetchGetAgmOpenedAction()).then((res) => {
      setOpenedList(res.data);
    });
  }, [dispatch]);

  const onChange = (e) => {
    const agmSeqno = e.target.value;
    history.push(`/corp/agm/info/${agmSeqno}/agm-short-desc`);
  };
  return (
    <SubMenuBlock>
      <Inner>
        <InputWrapBlock>
          <Select
            onchange={onChange}
            value={agmSeqno}
            style={{ marginLeft: '20px' }}
            noDefault
          >
            {openedList &&
              openedList.map((item, idx) => (
                <option
                  selected={item.agmSeqno === agmSeqno}
                  value={item.agmSeqno}
                  key={`subMenu_${idx}`}
                >
                  {item.agmName}
                </option>
              ))}
          </Select>
        </InputWrapBlock>
        <MenuWrapBlock>
          <MenuWrapUlBlock>
            {/* TODO url 매핑 동적으로 처리하도록.. */}
            {/* 권한에 따라 분기 */}
            {role === Role.ISSINC_ADMIN.code ||
            role === Role.VIEW_ISSINC_ADMIN.code ? (
              <>
                <MenuWrapLiBlock $on={agmMenu === 'agm-short-desc'}>
                  <Link to={`/corp/agm/info/${agmSeqno}/agm-short-desc`}>
                    주주총회 정보
                  </Link>
                </MenuWrapLiBlock>
                {/*
                <MenuWrapLiBlock $on={agmMenu === 'electronic-billings'}>
                  <Link to={`/corp/agm/info/${agmSeqno}/electronic-billings`}>
                    전자고지서
                  </Link>
                </MenuWrapLiBlock>
                */}
                <MenuWrapLiBlock $on={agmMenu === 'diverse-exercise'}>
                  <Link to={`/corp/agm/info/${agmSeqno}/diverse-exercise`}>
                    의결권 불통일 행사 내역
                  </Link>
                </MenuWrapLiBlock>
                <MenuWrapLiBlock
                  $on={agmMenu === 'counting-result'}
                  disabled={!isElecVoteEnd}
                >
                  <Link
                    to={`/corp/agm/info/${agmSeqno}/counting-result`}
                    onClick={(e) => {
                      !isElecVoteEnd && e.preventDefault();
                    }}
                  >
                    전자투표/위임장 행사내역
                  </Link>
                </MenuWrapLiBlock>
                <MenuWrapLiBlock
                  $on={agmMenu === 'agm-result'}
                  disabled={!isElecVoteEnd}
                >
                  <Link
                    to={`/corp/agm/info/${agmSeqno}/agm-result`}
                    onClick={(e) => {
                      !isElecVoteEnd && e.preventDefault();
                    }}
                  >
                    주주총회 결과
                  </Link>
                </MenuWrapLiBlock>
              </>
            ) : (
              <>
                <MenuWrapLiBlock $on={agmMenu === 'agm-short-desc'}>
                  <Link to={`/shareholder/agm/${agmSeqno}/certificate`}>
                    주주 증명발급
                  </Link>
                </MenuWrapLiBlock>
                <MenuWrapLiBlock $on={agmMenu === 'diverse-exercise'}>
                  <Link to={`/corp/agm/info/${agmSeqno}/diverse-exercise`}>
                    전자투표
                  </Link>
                </MenuWrapLiBlock>
                <MenuWrapLiBlock $on={agmMenu === 'counting-result'}>
                  <Link to={`/corp/agm/info/${agmSeqno}/counting-result`}>
                    전자투표 결과
                  </Link>
                </MenuWrapLiBlock>
              </>
            )}
          </MenuWrapUlBlock>
        </MenuWrapBlock>
      </Inner>
    </SubMenuBlock>
  );
};

const InputWrapBlock = styled(InputWrapperBlock)`
  // .submenu .inner .input_wrap
  position: absolute;
  width: 320px;
  height: 100%;
  padding: 2px 40px 0 0;
  z-index: 999;
  & ${SelectBlock} {
    // .submenu .inner .input_wrap .select_ty
    background-color: transparent;
    border: 0;
    font-size: 18px;
    font-weight: 800;
    height: 52px;
    padding: 8px 40px 8px 0;

    // .input_wrap .select_ty
    flex: 1;
    width: 100%;
    margin-right: 4px;

    margin-left: 0 !important;
  }
`;

export default SubMenu;
