import React from 'react';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import {
  DateFormatName,
  getDateFormat
} from '../../../../../utils/DateFormatUtil';
import { DATE_FORMAT } from '../../../../../utils/DateUtil';
import {
  checkUndefinedNum,
  formatNumber
} from '../../../../../utils/StringUtil';

const SoloExercise = ({
  info,
  openVotingLimitsPopup,
  openStockDetailPopup
}) => {
  const voter = info?.voters[0];
  return (
    <>
      <li>
        <span>주주총회일</span>
        <p>
          {DATE_FORMAT(
            info.agmDateTime,
            getDateFormat(DateFormatName.SHORT_DATE_2)
          )}
        </p>
      </li>
      <li>
        <span>행사기간</span>
        <p>{`${DATE_FORMAT(
          info.voteBeginDateTime,
          getDateFormat(DateFormatName.SHORT_DATE_2)
        )} ~ ${DATE_FORMAT(
          info.voteEndDateTime,
          getDateFormat(DateFormatName.LONG_DATE_TIME)
        )} `}</p>
      </li>
      <li>
        <button onClick={openVotingLimitsPopup}>의결권제한</button>
        <span>주주명</span>
        <p>{info.voters[0].stockholderName}</p>
      </li>
      <li>
        <span>총 보유주식</span>
        <p>
          {formatNumber(
            voter.commonStockAmount +
              checkUndefinedNum(voter.exercisablePreferredStockAmount) +
              checkUndefinedNum(voter.preferredStockAmount)
          )}
        </p>
        <DetailSpanBlock onClick={() => openStockDetailPopup(info.voters[0])}>
          상세보기
        </DetailSpanBlock>
      </li>
    </>
  );
};

const DetailSpanBlock = styled.span`
  &&& {
    padding: 0.25em 0.65em;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    vertical-align: top;
    border-radius: 22px;
    width: 76px;
    border: 2px solid #9eb3cd;
    margin-left: 15px;

    color: #24375c;
    cursor: pointer;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      padding: 0.15em 0.15em;
      font-size: 13px;
    }
  }
`;

export default SoloExercise;
