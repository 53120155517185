import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../components/button/CommonStyle';
import { AgmType } from '../../../../utils/constant/AgmConstant';
import {
  DateFormatName,
  getDateFormat
} from '../../../../utils/DateFormatUtil';
import { DATE_FORMAT, now } from '../../../../utils/DateUtil';

const ListItemBlock = styled.li`
  border-bottom: solid 1px #e9ecf4;
  & > ul {
    // .unit
    position: relative;
    display: block;
    padding: 16px 0;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      padding: 10px 0 5px;
    }
    & > li {
      width: 100%;
      /* font-size: 15px; */
      letter-spacing: -0.5px;
      color: #000826;
      text-align: left;
      & > em {
        display: inline-block;
        width: 110px;
        padding: 8px 0 7px;
        border-radius: 5px;
        font-size: 14px;
        color: #fff;
        text-align: center;
        margin-right: 20px;

        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          width: 64px;
          padding: 5px 0 4px;
          border-radius: 4px;
          font-size: 12px;
          color: #fff;
          text-align: center;
          margin-right: 12px;
        }

        // .ty01
        background-color: #71a0d9;

        ${(props) =>
          props.agmType === AgmType.GENERAL.code &&
          css`
            background-color: #284cb0;
          `}
      }
      & > p {
        display: inline-block;
      }
      & > span {
        display: inline-block;
        width: 110px;
        padding: 7px 0;
        font-size: 14px;
        color: #24375c;
        margin-right: 20px;

        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          display: inline-block;
          width: 92px;
          padding: 4px 0;
          font-size: 11px;
          color: #24375c;
          margin-right: 0;
        }
      }
    }
    & > li:first-of-type {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }
`;

const StateDiv = styled.div`
  // .state
  position: absolute;
  display: block;
  top: 18px;
  right: 0;
  margin: 0;
  width: 142px;
  padding: 10px 0 9px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: absolute;
    display: block;
    top: 10px;
    right: 0;
    margin: 0;
    width: 64px;
    padding: 4px 0 3px;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
  }
  ${(props) =>
    props.state === agmProgressState.BEFORE &&
    css`
      // .plan
      color: #fff;
      border: 2px solid #0094ff;
      background-color: #0094ff;
      & > p {
        position: absolute;
        display: block;
        width: 142px;
        right: 0;
        top: 46px;
        font-weight: 800;
        color: #0094ff;
        font-size: 36px;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          position: absolute;
          display: block;
          width: 64px;
          right: 0;
          top: 32px;
          font-weight: 800;
          color: #0094ff;
          font-size: 18px;
        }
      }
    `}
  ${(props) =>
    props.state === agmProgressState.ING &&
    css`
      // .voting
      color: #fff;
      border-color: 2px solid #ff9330;
      background-color: #ff9330;
      & > p {
        position: absolute;
        display: block;
        width: 142px;
        right: 0;
        top: 46px;
        font-weight: 800;
        color: #ff9330;
        font-size: 36px;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          position: absolute;
          display: block;
          width: 64px;
          right: 0;
          top: 32px;
          font-weight: 800;
          color: #ff9330;
          font-size: 18px;
        }
      }
    `}
    ${(props) =>
    props.state === agmProgressState.END &&
    css`
      // .close
      color: #a0a6b5;
      border: 2px solid #caccd7;
    `}
  & > p {
    display: inline-block;
  }
`;

const ScheduleItem = (props) => {
  const { schedule } = props;
  const today = new Date(
    now().getFullYear(),
    now().getMonth(),
    now().getDate(),
    23,
    59,
    59
  );

  const renderAgmState = () => {
    if (new Date(schedule.voteEndDateTime) < today) {
      // 종료
      return <StateDiv state={agmProgressState.END}>투표종료</StateDiv>;
    } else if (new Date(schedule.voteBeginDateTime) > today) {
      // 전
      return (
        <StateDiv state={agmProgressState.BEFORE}>
          투표예정
          <p>{`D - ${
            new Date(schedule.voteBeginDateTime).getDate() - today.getDate()
          }`}</p>
        </StateDiv>
      );
    } else {
      // 투표중
      return (
        <StateDiv state={agmProgressState.ING}>
          투표
          <p>D - 1</p>
        </StateDiv>
      );
    }
  };

  return (
    <ListItemBlock agmType={schedule.agmType}>
      <ul>
        <li>
          <em>
            {schedule.agmType === AgmType.GENERAL.code
              ? '정기주총'
              : '임시주총'}
          </em>
          <p>{schedule.issueIncorporatedName}</p>
        </li>
        <li>
          <span>주주총회일</span>
          <p>
            {DATE_FORMAT(
              schedule.agmDateTime,
              getDateFormat(DateFormatName.SHORT_DATE_OTHER_2)
            )}
          </p>
        </li>
        <li>
          <span>행사기간</span>
          <p>{`${DATE_FORMAT(
            schedule.voteBeginDateTime,
            getDateFormat(DateFormatName.SHORT_DATE_OTHER_2)
          )} ~ ${DATE_FORMAT(
            schedule.voteEndDateTime,
            getDateFormat(DateFormatName.SHORT_DATE_OTHER_2)
          )}`}</p>
          {schedule.voter && renderAgmState()}
        </li>
      </ul>
    </ListItemBlock>
  );
};

export default ScheduleItem;

const agmProgressState = {
  BEFORE: '1',
  ING: '2',
  END: '3'
};
