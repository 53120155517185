import { splitCellNumber } from './StringUtil';

export const makeCellObject = (cell) => {
  const cellArr = splitCellNumber(cell);
  if (cellArr.length !== 3) return null;
  return {
    frontCell: cellArr[0],
    middleCell: cellArr[1],
    endCell: cellArr[2]
  };
};

export const makeCellString = (cellArr) => {
  console.log('cellArr');
  console.log(cellArr);
  if (!Array.isArray(cellArr)) return '';
  return cellArr.reduce((fullText, cell) => (fullText += String(cell)));
};
