import { Route, Switch } from 'react-router';
import ElecBillPage from './main/info/ElecBillPage';
import SendElecBillPage from './main/send/SendElecBillPage';

const ElectronicBill = (props) => {
  return (
    <Switch>
      <Route
        exact
        path="/corp/agm/info/:agmSeqno/electronic-billings"
        component={ElecBillPage}
      />
      <Route
        path="/corp/agm/info/:agmSeqno/electronic-billings/send"
        component={SendElecBillPage}
      />
    </Switch>
  );
};

export default ElectronicBill;
