import { createPromiseAction } from '@adobe/redux-saga-promise';

/**
 * 파일 관련
 */

// 파일 임시 업로드 (공통)
export const fetchPostFilesTemporaryAction = createPromiseAction(
  'FILE_POST_UPLOAD_TEMPORARY'
);

// 임시 파일 다운로드 (공통)
export const fetchGetFilesTemporaryAction = createPromiseAction(
  'FETCH_GET_FILES_TEMPORARY'
);

// 주총개설 - 주주명부 샘플 다운로드
export const fetchGetFilesShareholderSampleAction = createPromiseAction(
  'FETCH_GET_FILES_SHAREHOLDER_SAMPLE'
);

// 불통일 행사 파일 다운로드
export const fetchGetFilesAgmDiversityExerciseAction = createPromiseAction(
  'FETCH_GET_FILES_AGM_DIVERSITY_EXERCISE'
);
