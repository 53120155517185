import { createPromiseAction } from '@adobe/redux-saga-promise';

/**
 * 약관
 */
// 약관 조회
export const fetchGetTermsAction = createPromiseAction('FETCH_GET_TERMS');

// 약관 조회 - 개별
export const fetchGetTermsSeqnoAction = createPromiseAction(
  'FETCH_GET_TERMS_SEQNO'
);

// 약관 조회 - 서비스 가입
export const fetchGetTermsSignUpAction = createPromiseAction(
  'FETCH_GET_TERMS_SIGN_UP'
);

// 약관 조회 - 고유식별처리 동의
export const fetchGetTermsUniqueInfoTreatmentAction = createPromiseAction(
  'FETCH_GET_TERMS_UNIQUE_INFO_TREATMENT'
);

// 약관 조회 - 과거 약관
export const fetchGetTermsPrivacyPolicyHistoryAction = createPromiseAction(
  'FETCH_GET_TERMS_PRIVACY_POLICY_HISTORY'
);
