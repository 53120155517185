import { useContext } from 'react';
import styled, { css } from 'styled-components';
import { ButtonSize } from '../../../../../../components/button/Button';
import { MODAL_SIZE } from '../../../../../../components/modal/Modal';
import { ModalContext } from '../../../../../../context/ModalContext';
import spCommonPng from '../../../../../../styles/images/common/sprite/sp_common.png';
import { AgendaResultType } from '../../../../../../utils/constant/AgmConstant';
import { formatNumber } from '../../../../../../utils/StringUtil';
import { ExerciseMethod } from '../../../../../shareholder/exerciseRecord/main/BoxInfo';
import BarChart from '../../../../../shareholder/result/main/components/charts/BarChart';
import { OkBtn } from '../../../../../shareholder/vote/main/components/popup/common/PopupButton';
import ParticipantsTable from '../components/ParticipantsTable';
import { TableTyBlock } from './proxy/ProxyResult';

const AgmStatus = ({ agmSeqno, agenda, exerciseMethod }) => {
  let { openModal, setModalConfig, closeModal } = useContext(ModalContext);

  const openDetail = (agenda, child, agendaSeqno) => {
    const agendaName = !!agenda.childAgendaCount
      ? agenda.concentrateVote
        ? `${agenda.agendaNumber}안 ${agenda.agendaName}(${agenda.childAgendaCount}명)의 건 (집중투표)`
        : `${agenda.agendaNumber}안 ${agenda.agendaName}(${agenda.childAgendaCount}명)의 건 (분리투표)`
      : `${agenda.agendaNumber}안 ${agenda.agendaName}의 건 (찬성/반대)`;

    setModalConfig({
      size: MODAL_SIZE.WIDE,
      buttons: [
        <OkBtn
          size={ButtonSize.Popup}
          onClick={() => {
            closeModal(250);
          }}
        />
      ]
    });

    const title =
      exerciseMethod === ExerciseMethod.VOTE
        ? '전자투표 행사 내역'
        : '전자위임 행사 내역';
    openModal(
      `${agendaName} ${title}`,
      <>
        <ChildAgendaTitle isLayer={true}>
          {`${child.agendaNumber}안 ${child.agendaName}`}
        </ChildAgendaTitle>
        <TableTyBlock isChild={true}>
          <p>
            행사 가능 주식수
            <span>{`${formatNumber(child.exercisableStockQuantity)}`}주</span>
          </p>
          <span>단위:1주</span>
        </TableTyBlock>
        <BarChart data={getBarChartData(child)} />
        <ParticipantsTable
          agmSeqno={agmSeqno}
          stockholders={child.participatedStockholders}
          agendaSeqno={child.agendaSeqno}
          concentrateVote={child.concentrateVote}
          exerciseMethod={exerciseMethod}
        />
      </>
    );
  };

  const getBarChartData = (chartData) => {
    const { childs, result, voteBoxSummary } = chartData;
    let data = [];
    if (chartData.childs.length === 0) {
      const {
        ayesCount,
        ayesPercent,
        noesCount,
        noesPercent,
        abstentionCount,
        abstentionPercent
      } = voteBoxSummary;

      data.push({
        label: '찬성',
        value: ayesCount,
        percent: ayesPercent,
        isAppointment: result === AgendaResultType.INVALID.code
      });

      data.push({
        label: '반대',
        value: noesCount,
        percent: noesPercent,
        isAppointment: chartData.result === AgendaResultType.INVALID.code
      });

      data.push({
        label: '기권',
        value: abstentionCount,
        percent: abstentionPercent,
        isAppointment: result === AgendaResultType.INVALID.code
      });
    } else {
      data = childs.map((child) => {
        return {
          label: child.agendaName,
          value: child.voteBoxSummary.ayesCount,
          percent: child.voteBoxSummary.ayesPercent,
          isAppointment: result === AgendaResultType.INVALID.code
        };
      });
    }
    return data;
  };
  return (
    <RecentStatusBlock>
      <ul>
        <li>
          {agenda.childAgendaCount === 0 || agenda.concentrateVote ? (
            <>
              <TableTyBlock isChild={true}>
                <p>
                  행사 가능 주식수
                  <span>
                    {`${formatNumber(agenda.exercisableStockQuantity)}`}주
                  </span>
                </p>
                <span>단위:1주</span>
              </TableTyBlock>
              <BarChart data={getBarChartData(agenda)} />
            </>
          ) : (
            <>
              {agenda.childs.map((child) => {
                return (
                  <>
                    <ChildAgendaTitle>
                      {`${child.agendaNumber}안 ${child.agendaName}`}
                      <DetailBtnBlock
                        // on={true}
                        onClick={() =>
                          openDetail(agenda, child, child.agendaSeqno)
                        }
                      >
                        상세정보
                      </DetailBtnBlock>
                    </ChildAgendaTitle>
                    <TableTyBlock isChild={true}>
                      <p>
                        행사 가능 주식수
                        <span>
                          {`${formatNumber(child.exercisableStockQuantity)}`}주
                        </span>
                      </p>
                      <span>단위:1주</span>
                    </TableTyBlock>
                    <BarChart data={getBarChartData(child)} />
                  </>
                );
              })}
            </>
          )}
        </li>
      </ul>
    </RecentStatusBlock>
  );
};

const ChildAgendaTitle = styled.p`
  // .bgUFKK > ul > li > ul > li:first-of-type p
  font-size: 19px;
  font-weight: bold;
  margin-top: 30px;
  ${(props) =>
    props.isLayer &&
    css`
      margin-top: 10px;
    `}
  margin-bottom: 12px;
  box-sizing: border-box;
  position: relative;
  // temp
  height: 40px;
  border-bottom: solid 1px #e9ecf4;
`;

const RecentStatusBlock = styled.div`
  // .datail
  display: block;
  width: 100%;
  padding: 0 0 5px 0;
  box-sizing: border-box;
`;

const DetailBtnBlock = styled.button`
  // .bgUFKK > ul > li > ul > li:first-of-type button
  position: absolute;
  top: -8px;
  right: 20px;
  display: inline-block;
  min-width: 120px;
  padding: 10px 30px 9px 12px;
  height: 40px;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 1.33;
  letter-spacing: -0.5px;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    top: 13px;
    right: 18px;
    width: 9px;
    height: 14px;
    background: url(${spCommonPng}) no-repeat;
    background-size: 400px auto;

    background-position: -311px -1px;
    opacity: 1;
  }
  border: solid 1px #8c939c !important;
  background-color: #fff;
  color: #080808;
  // temp 기존 상세정보 보는 방식이 변경되어 버튼 색 변경
  background-color: #284cb0;
  color: #fff;
  &:hover {
    &::after {
      background-position: -331px -1px;
    }
    background-color: #284cb0;
    color: #fff;
  }
`;

export default AgmStatus;
