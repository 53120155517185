import { Viewer } from '@toast-ui/react-editor';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import styled, { css } from 'styled-components';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import { SCREEN_SIZE_MOBILE } from '../../../components/button/CommonStyle';
import { MODAL_SIZE } from '../../../components/modal/Modal';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import { PageInnerListWrap } from '../../../components/page/PageInner';
import { ModalContext } from '../../../context/ModalContext';
import { useFileDownload } from '../../../hooks/useFileDownload';
import {
  fetchDeleteQnaAction,
  fetchGetQnaByNotientSeqnoAction,
  fetchGetQnaFilesAction,
  fetchPostQnaReplyAction,
  fetchPutQnaAction
} from '../../../services/api/common/promiseActions';
import { Role } from '../../../utils/constant/AgmConstant';
import { DATE_FORMAT, getCurrentDatetime } from '../../../utils/DateUtil';
import {
  CancelBtn,
  OkBtn
} from '../../shareholder/vote/main/components/popup/common/PopupButton';
import { DownloadBtnBlock } from '../notice/main/NoticeDetail';
import QnaWritePage from './QnaWritePage';

const CommentType = {
  Reply: 0,
  Answer: 1
};

const QnaViewPage = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [isComplete, setIsComplete] = useState(false);
  const match = useRouteMatch();
  const { notientSeqno } = match.params;
  const history = useHistory();
  const { callDownloadFetchNoticeFiles } = useFileDownload();
  const { openModal, setModalConfig, closeModal } = useContext(ModalContext);
  const { user } = useSelector((state) => state.common);
  const role = user.role;
  const [regDttm, setRegDttm] = useState(null);

  useEffect(() => {
    const parmas = {
      notientSeqno
    };
    dispatch(fetchGetQnaByNotientSeqnoAction(parmas)).then((res) => {
      if (!res.error) {
        setData(res.data);
        setIsComplete(
          res.data.childs &&
            res.data.childs.length !== 0 &&
            res.data.childs.find((item) => item.isAnswer)
        );
      } else {
        history.goBack();
      }
    });
  }, [regDttm]);

  const onDownloadFile = (file) => {
    callDownloadFetchNoticeFiles(file, fetchGetQnaFilesAction);
  };

  const onWrite = () => {
    setModalConfig({ size: MODAL_SIZE.MEDIUM, isNoButton: true });
    const title = role === Role.SECCO_ADMIN.code ? '답변' : '재질문';
    openModal(
      title,
      <QnaWritePage
        action={fetchPostQnaReplyAction}
        callback={() => {
          setRegDttm(getCurrentDatetime());
          closeModal();
        }}
        notientSeqno={notientSeqno}
        closeModal={closeModal}
      />
    );
  };

  const goBack = () => {
    history.goBack();
  };

  const TopButtonGroup = ({ children }) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '10px'
        }}
      >
        {children.map((item, idx) => (
          <div
            key={`TopButtonGrop_${idx}`}
            style={{ marginLeft: idx === 0 ? 0 : '5px' }}
          >
            {item}
          </div>
        ))}
      </div>
    );
  };

  const onModify = () => {
    setModalConfig({ size: MODAL_SIZE.MEDIUM, isNoButton: true });
    openModal(
      '1:1 문의',
      <QnaWritePage
        notientSeqno={notientSeqno}
        action={fetchPutQnaAction}
        callback={() => {
          setRegDttm(getCurrentDatetime());
          closeModal();
        }}
        closeModal={closeModal}
        data={data}
        fileAction={callDownloadFetchNoticeFiles}
      />
    );
  };

  const onDelete = () => {
    setModalConfig({
      buttons: [
        <CancelBtn onClick={closeModal} />,
        <OkBtn onClick={deleteQna} />
      ]
    });
    openModal('문의 삭제', '문의를 삭제하시겠습니까?');
  };

  const deleteQna = () => {
    setModalConfig({});
    dispatch(fetchDeleteQnaAction({ notientSeqno })).then((res) => {
      if (!res.error) {
        openModal('확인', '문의가 삭제되었습니다', () => history.push('/qna'));
      }
    });
  };

  return (
    <Page>
      <PageHeader title="1:1 문의" />
      <PageInnerListWrap>
        <TopButtonGroup>
          <Button
            type={ButtonTypes.Outline}
            size={ButtonSize.Table}
            onClick={onModify}
          >
            수정
          </Button>
          <Button
            type={ButtonTypes.Outline}
            size={ButtonSize.Table}
            onClick={onDelete}
          >
            삭제
          </Button>
        </TopButtonGroup>
        <DivListTy05>
          {data && (
            <ul>
              {/* 반복 */}
              <li>
                <ul>
                  <ContentLi>
                    <span>
                      {DATE_FORMAT(data.registerDateTime, 'yyyy-MM-dd HH:mm')}
                    </span>
                  </ContentLi>
                  <ContentLi>
                    <p dangerouslySetInnerHTML={{ __html: data.title }} />
                    <StateDiv complete={isComplete}>{`답변 ${
                      isComplete ? '완료' : '대기'
                    }`}</StateDiv>
                  </ContentLi>
                </ul>
              </li>
              <Contents>
                <Viewer initialValue={data.content} />
                {data.files &&
                  data.files.length !== 0 &&
                  data.files.map((file) => {
                    return (
                      <DownloadBtnBlock onClick={() => onDownloadFile(file)}>
                        {file.fileName}
                      </DownloadBtnBlock>
                    );
                  })}
              </Contents>
              {data.childs && (
                <Comment>
                  <ul>
                    {data.childs.map((item) => (
                      <CommentItem
                        type={
                          item.isAnswer ? CommentType.Answer : CommentType.Reply
                        }
                      >
                        <span>{item.isAnswer ? '답변' : '재질문'}</span>
                        <p style={{ fontWeight: 800 }}>{item.title}</p>
                        {/* <p>{item.content}</p> */}
                        <Viewer initialValue={item.content} />
                        {item.files &&
                          item.files.length !== 0 &&
                          item.files.map((file) => {
                            return (
                              <DownloadBtnBlock
                                onClick={() => onDownloadFile(file)}
                              >
                                {file.fileName}
                              </DownloadBtnBlock>
                            );
                          })}
                      </CommentItem>
                    ))}
                  </ul>
                </Comment>
              )}
            </ul>
          )}
        </DivListTy05>
        <ButtonBlock>
          <ButtonGroup>
            {user.isAuth && (
              <Button
                type={ButtonTypes.Primary}
                size={ButtonSize.Medium}
                onClick={onWrite}
              >
                {`${role === Role.SECCO_ADMIN.code ? '답변' : '재질문'}`}
              </Button>
            )}
            <Button
              type={ButtonTypes.Basic}
              size={ButtonSize.Medium}
              onClick={goBack}
            >
              목록
            </Button>
          </ButtonGroup>
        </ButtonBlock>
      </PageInnerListWrap>
    </Page>
  );
};

const StateDiv = styled.div`
  // temp
  transform: skew(-0.04deg);
  // .state.answer
  color: #a0a6b5;
  background-color: rgba(202, 204, 215, 0.2);
  // .list_ty05 .unit li .state
  position: absolute;
  display: block;
  top: 18px;
  right: 0;
  margin: 0;
  width: 142px;
  padding: 10px 0 9px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  // .list_ty05 .inq li .state
  width: 88px;
  padding: 7px 0;
  font-size: 13px;
  ${(props) =>
    props.complete &&
    css`
    // .state.answer-complete
    color: #EC2A1E;
    border: 1px solid #CACCD7;
    background-color: #fff;
}
  `}
`;

const ContentLi = styled.li`
  // .list_ty05 .unit li
  width: 100%;
  font-size: 15px;
  letter-spacing: -0.5px;
  color: #080808;
  text-align: left;

  // .list_ty05 .unit li:first-of-type
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  & span {
    // .list_ty05 .unit li span
    display: inline-block;
    width: 110px;
    padding: 7px 0;
    font-size: 14px;
    color: #24375c;
    margin-right: 20px;
    // .list_ty05 .inq li span
    font-size: 13px;
    font-weight: normal;
    color: #9eb3cd;
  }
  & > p {
    // .list_ty05 .unit li p
    display: inline-block;
    // .list_ty05 .inq li p
    font-size: 16px;
    // temp
    cursor: pointer;
  }
`;

const DivListTy05 = styled.div`
  margin-bottom: 20px;
  & > ul {
    // .list_ty05 > ul
    border-top: solid 1px #e9ecf4;
    & > li {
      // .list_ty05 > ul > li
      border-bottom: solid 1px #e9ecf4;
      & > ul {
        // .list_ty05 .unit
        position: relative;
        display: block;
        // .list_ty05 .inq
        padding: 16px 0;
      }
    }
    & > li:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
`;

const Contents = styled.li`
  // .list_ty05 .contents
  line-height: 1.75;
  padding: 30px 20px;
`;

const Comment = styled.li`
  // .list_ty05 .comment
  line-height: 1.75;
  padding: 30px 20px;
  line-height: 1.75;
  padding: 30px 20px 0;
  border-bottom: 0;

  &&& {
    & > ul {
      padding: 0 0;
      & > li:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
      }
    }
  }
`;

const CommentItem = styled.li`
  // .list_ty05 .coment ul li
  position: relative;
  padding-left: 100px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px #e9ecf4;
  &&& > span {
    // .list_ty05 .coment ul li span
    font-size: 13px;
    font-weight: 800;
    position: absolute;
    left: 0;
    // .ft-col06
    ${(props) =>
      props.type === CommentType.Reply &&
      css`
        color: #9eb3cd !important;
      `}
  }
`;

const ButtonBlock = styled.div`
  &&& {
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      margin-top: 40px;
      & > div {
        margin-top: 0;
        border-top: none;
        padding-top: 0;
        & > ul {
          display: flex;
          align-items: center;
          & > li > button {
            display: inline-block;
            width: auto;
            min-width: 49%;
            margin-left: 2px;
          }
        }
      }
    }
  }
`;

export default QnaViewPage;
