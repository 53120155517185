import PropTypes from 'prop-types'; // ES6
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import sp_common_png from '../../styles/images/common/sprite/sp_common.png';
const SearchInputFilterBlock = styled.div`
  // .input_filter
  display: inline-block;
  position: relative;
`;

const SearchInputBlock = styled.input`
  // .input_ty
  height: 42px;
  padding: 0 20px;
  border-radius: 4px;
  background-color: #fff;
  border: solid 1px #ccd5e0;
  box-sizing: border-box;
  line-height: 1.54;

  // .input_filter input
  padding: 0 48px 0 16px;
  overflow: hidden;

  // .input_wrap .input_ty, .input_wrap .select_ty
  flex: 1;
  width: 100%;
  margin-right: 4px;

  // .input_ty:focus, .input_ty:hover
  &:hover {
    background-color: #fff;
    border: solid 1px #8c939c;
  }
  //.input_filter input:focus
  &:focus {
    border-color: #5871c0;
    background-color: #fff;
  }
`;

const SearchInputButtonBlock = styled.button`
  //.input_wrap div, .input_wrap button
  flex-basis: auto;
  flex-shrink: 0;

  // .input_filter button
  position: absolute;
  top: 8px;
  right: 14px;
  display: block;
  width: 25px;
  height: 0;
  padding-top: 27px;
  box-sizing: border-box;
  background-color: transparent;
  overflow: hidden;

  // .input_filter button::before
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    height: 27px;
    background: url(${sp_common_png}) no-repeat;
    background-size: 400px auto;
    background-position: -262px -81px;
  }
`;

const SearchInput = (props) => {
  const { resetValue } = props;
  const [value, setValue] = useState(resetValue);
  const btnRef = useRef();

  useEffect(() => {
    !!resetValue && setValue(resetValue);
  }, [resetValue]);

  const onChange = (e) => {
    const val = e.target.value;
    if (val.match(/[^0-9a-zA-Z가-힣ㄱ-ㅎㅏ-ㅣ/-\s]/gi)) {
      val.substring(0, val.length - 1);
    } else {
      setValue(val);
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      btnRef.current.click();
    }
  };

  return (
    <SearchInputFilterBlock>
      <SearchInputBlock
        value={value}
        onChange={onChange}
        placeholder={props.placeholder}
        onKeyPress={onKeyPress}
      />
      <SearchInputButtonBlock
        onClick={() => props.onSearch(value)}
        ref={btnRef}
      >
        <span></span>
      </SearchInputButtonBlock>
    </SearchInputFilterBlock>
  );
};

export const PopupSearchInput = (props) => {
  const { resetValue, register, name, disabled = false } = props;
  const [value, setValue] = useState(resetValue);
  const btnRef = useRef();

  useEffect(() => {
    !!resetValue && setValue(resetValue);
  }, [resetValue]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      btnRef.current.click();
    }
  };
  return (
    <InputWrapBlock>
      <SearchInputBlock
        value={value}
        placeholder={props.placeholder}
        {...register(name, {
          required: {
            value: true,
            message: '실명번호는 필수 입력 항목입니다.'
          },
          pattern: {
            value: /^[0-9]+$/,
            message: '실명번호는 숫자만 입력 가능합니다.'
          }
        })}
        onInput={(e) => setValue(e.target.value)}
        onKeyPress={onKeyPress}
        disabled={disabled}
      />
      <SearchInputButtonBlock
        onClick={() => props.onSearch(value)}
        ref={btnRef}
      >
        <span></span>
      </SearchInputButtonBlock>
    </InputWrapBlock>
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  resetValue: PropTypes.string
};

const InputWrapBlock = styled.div`
  flex: 1.4;
  position: relative;
  display: inline-block;
`;

export default SearchInput;
