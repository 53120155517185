import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonGroup from '../../../../../../components/button/ButtonGroup';
import FileInput from '../../../../../../components/input/FileInput';
import { MODAL_SIZE } from '../../../../../../components/modal/Modal';
import { ModalContext } from '../../../../../../context/ModalContext';
import { useFileDownload } from '../../../../../../hooks/useFileDownload';
import { fetchGetFilesTemporaryAction } from '../../../../../../services/api/files/promiseActions';
import { TempFileType } from '../../../../../../utils/constant/AgmConstant';
import SubModal from '../../../../../IssueIncorporated/register/step4/main/SubModal';
import { UnitTitleWrapper } from '../../../../myAgms/main/ConfirmStockholder';
import { CancelBtn, OkBtn } from './common/PopupButton';

const PopListBlockNonMT = styled.div`
  // .list_ty02
  & > ul {
    position: relative;
    & > li {
      width: 100%;
      padding-bottom: 24px;
      position: relative;
    }
    & > li:last-of-type {
      flex: 1;
      padding-bottom: 0;
    }
  }
  &&& > .unit {
    display: flex;
    & > li {
      padding-bottom: 16px;
    }
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 16px 20px;
  border-radius: 4px;
  background-color: #fff;
  border: solid 1px #ccd5e0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  line-height: 1.54;
`;

const DiverseReasonPopup = (props) => {
  let { setModalConfig, closeModal } = useContext(ModalContext);
  const { setDiversReason } = props;
  const [diversReasonCont, setDiversReasonCont] = useState();
  const [file, setFile] = useState();
  const [subModalOpen, setSubModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onDiverseReason = () => {
    console.log(diversReasonCont);
    if (typeof file === 'undefined') {
      setErrorMessage('파일 업로드는 필수 사항입니다.');
      setSubModalOpen(true);
    } else if (typeof diversReasonCont === 'undefined') {
      setErrorMessage('행사 사유는 필수 입력 사항입니다.');
      setSubModalOpen(true);
    } else {
      setDiversReason(file, diversReasonCont);
      closeModal();
    }
  };

  useEffect(() => {
    setModalConfig({
      size: MODAL_SIZE.SHORT,
      isNoButton: true,
      innerPadding: true
    });
  }, []);

  const callbackFileUpload = (file, type) => {
    setFile(file);
  };

  const { callDownloadFetchAPITemporary } = useFileDownload();

  const fileDownload = (type) => {
    callDownloadFetchAPITemporary(file, fetchGetFilesTemporaryAction);
  };

  const clickModalClose = () => {
    setSubModalOpen(false);
  };

  return (
    <>
      <PopListBlockNonMT>
        <ul className="unit">
          <li>
            <UnitTitleWrapper>
              <span>파일업로드</span>
            </UnitTitleWrapper>
            <FileInput
              fileType={TempFileType.AGENDA_MORE_FIELS}
              callback={callbackFileUpload}
              fileDownload={fileDownload}
              isPopup={true}
            />
          </li>
        </ul>
        <ul className="unit">
          <li>
            <UnitTitleWrapper>
              <span>직접 작성하기</span>
            </UnitTitleWrapper>
            <TextArea
              value={diversReasonCont}
              onChange={(e) => setDiversReasonCont(e.target.value)}
            />
          </li>
        </ul>
        <ButtonGroup>
          <CancelBtn onClick={closeModal} />
          <OkBtn onClick={onDiverseReason} />
        </ButtonGroup>
      </PopListBlockNonMT>
      {subModalOpen && (
        <SubModal
          layerTitle="에러"
          content={errorMessage}
          modalSize={MODAL_SIZE.SHORT}
          handleModal={clickModalClose}
        />
      )}
    </>
  );
};

export default DiverseReasonPopup;
