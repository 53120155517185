import { dispatch, implementPromiseAction } from '@adobe/redux-saga-promise';
import { call } from 'redux-saga/effects';
import { fetchPostAuthRefreshAction } from '../services/api/auth/promiseActions';
import { action as commonAction } from '../store/commonSlice';
import { getGnbRole } from './constant/GnbMenu';
import { setAccessToken } from './requestHelper';
//import { startLoading, finishLoading } from '../utils/';
let retryCnt = 0;
export default function createRequestSaga(type, request) {
  const sagaCall = function* (action) {
    yield call(implementPromiseAction, action, function* () {
      try {
        const response = yield call(request, action.payload);
        console.log('response');
        console.log(response);
        if (response.status === 200 || response.status === 201) {
          return {
            data: response.data,
            meta: response
          };
        } else {
          if (
            (response.status === 401 &&
              response.statusText === 'Unauthorized' &&
              response.data.user_message !== '접근 권한이 없습니다.') ||
            (response.status === 401 &&
              response.statusText === 'Unauthorized' &&
              response.data.user_message ===
                'your access is not allowed. try to login again')
          ) {
            retryCnt++;
            if (retryCnt > 3) {
              console.log('3번 요청함');
              //return false;
              return {
                error: response.data,
                meta: response
              };
            }

            if (sessionStorage.getItem('refreshToken')) {
              const res = yield dispatch(
                fetchPostAuthRefreshAction,
                sessionStorage.getItem('refreshToken')
              );

              if (res.data.status !== 500) {
                yield setAccessToken(res.data.accessToken);
                yield dispatch(commonAction.setCurrentUser, {
                  userSeqno: res.data.userSeqno,
                  userName: res.data.userName,
                  currentAgm: null,
                  role: res.data.role,
                  isAuth: true
                });
                yield dispatch(
                  commonAction.setCurrentGnb,
                  getGnbRole(res.data.role)
                );
                // TODO Resolved 가 두번 실행되는 이유를 모르겠다.
                yield sagaCall(action);
              } else {
                return {
                  meta: response,
                  error: '토큰이 필요합니다'
                };
              }
            } else {
              return {
                meta: response,
                error: '토큰이 필요합니다'
              };
            }
          } else {
            return {
              error: response.data,
              meta: response
            };
          }
        }
      } catch (e) {
        console.log(e);
        return {
          error: e,
          meta: e
        };
      }
    });
  };
  return sagaCall;
}
