import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { idpwsign, SIGN_TYPE } from '../../../../../components/auth/SignIframe';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../../components/button/Button';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import Input, { ErrorTypes } from '../../../../../components/input/Input';
import InputBox from '../../../../../components/input/InputBox';
import PhoneInput from '../../../../../components/input/PhoneInput';
import ListTag from '../../../../../components/layout/ListTag';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { fetchGetAuthPassCertAction } from '../../../../../services/api/auth/promiseActions';
import { fetchPostUsersIndividualAction } from '../../../../../services/api/users/promiseActions';
import {
  AuthenticationMethod,
  Role
} from '../../../../../utils/constant/AgmConstant';
import { getCurrentDatetime, getYMDSplit } from '../../../../../utils/DateUtil';
import Validation from '../../../../../utils/ValidationUtil';
import AgreeBox from '../../../info/AgreeBox';
import SignupBody from '../SignupBody';
import SignupTitle from '../SignupTitle';

const SignupFormBlock = styled.div`
  width: 400px;
  margin: 0 auto;
  padding-top: 20px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    width: auto;
    padding-top: 0px;
  }
  & > div > ul {
    position: relative;
    & > li {
      width: 100%;
      padding-bottom: 55px;
      list-style: none;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        padding-bottom: 16px;
      }
    }
  }
`;

const SignupAuthBlock = styled.div`
  margin-top: 0;
  border-top: 0;
  padding-top: 60px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin-top: 40px;
    padding-top: 0px;
  }
  & > ul {
    display: flex;
    align-items: center;
    & > li {
      flex: 1;
      margin-left: 20px;
    }
    & > li:first-of-type {
      margin-left: 0;
    }
    & > li:only-of-type {
      text-align: center;
    }
  }
`;

const SignupStep3 = (props) => {
  const {
    register,
    authMethod,
    handleSubmit,
    callback,
    errors,
    openModal,
    setStep,
    getValues
  } = props;
  const [checkedSms, setCheckedSms] = useState(false);
  const [checkedEmail, setCheckedEmail] = useState(false);
  const [smsCheckedDttm, setSmsCheckedDttm] = useState(null);
  const [emailCheckedDttm, setEmailCheckedDttm] = useState(null);
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => {
    return windowSize.width <= SCREEN_SIZE_MOBILE;
  }, [windowSize]);

  const onSign = (data, certNum) => {
    const [y, m, d] = getYMDSplit(data.individualUser.birthDt); // 생년월일
    const payload = {
      userName: data.userName,
      passCertificateNumber: certNum,
      cellphNotificationAgreeDateTime: checkedSms ? smsCheckedDttm : null,
      emailNotificationAgreeDateTime: checkedEmail ? emailCheckedDttm : null,
      marketingAgreeDateTime: getCurrentDatetime(),
      utilClauseAgreeDateTime: data.utilClauseAgreeDateTime,
      individualInformationTreatAgreeDateTime:
        data.individualInformationTreatAgreeDateTime,
      individualUser: {
        cell: `${data.frontCell}${data.middleCell}${data.endCell}`,
        birthDt: `${y}-${m}-${d}`,
        authenticationMethod: data.authenticationMethod,
        emailAddress: null
      },
      role: Role.INDIVIUDAL_SHAREHOLDER.code
    };

    if (certNum) {
      dispatch(fetchPostUsersIndividualAction(payload)).then((res) => {
        console.log('fetchPostUsersIndividualAction');
        console.log(res);
        if (!res.error) {
          console.log('회원 가입 완료');
          setStep();
        } else {
          openModal('확인', res.error.user_message);
        }
      });
    } else {
      idpwsign(
        SIGN_TYPE.PERSON,
        (signData) => {
          callback(data, signData);
        },
        payload
      );
    }
  };

  useEffect(() => {
    setSmsCheckedDttm(getCurrentDatetime());
  }, [checkedSms]);

  useEffect(() => {
    setEmailCheckedDttm(getCurrentDatetime());
  }, [checkedEmail]);

  const urlCode = process.env.REACT_APP_KMC_URL_CODE;
  const callbackDomain = process.env.REACT_APP_KMC_CALLBACK_DOMAIN;

  const openPassPopup = () => {
    const certNum = uuidv4();
    const popupWidth = 400;
    const popupHeight = 645;
    popupCenter('', 'KMCISWindow', popupWidth, popupHeight);
    dispatch(
      fetchGetAuthPassCertAction({
        certNum,
        urlCode,
        name: getValues('userName'),
        cell:
          getValues('frontCell') +
          getValues('middleCell') +
          getValues('endCell')
      })
    ).then((res) => {
      const tr_cert = res.data.tr_cert;

      var form = document.createElement('form');
      form.setAttribute('method', 'POST'); //Post 방식
      form.setAttribute('target', 'KMCISWindow'); //Post 방식
      form.setAttribute(
        'action',
        'https://www.kmcert.com/kmcis/web/kmcisReq.jsp'
      ); //요청 보낼 주소

      var hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', 'tr_url');
      //hiddenField.setAttribute('value', tr_url);
      hiddenField.setAttribute(
        'value',
        `${callbackDomain}/pass/signup/callback`
      );

      form.appendChild(hiddenField);

      hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', 'tr_cert');
      hiddenField.setAttribute('value', tr_cert);
      form.appendChild(hiddenField);

      document.body.appendChild(form);
      console.log(form);
      form.submit();
    });
  };

  function popupCenter(url, title, w, h) {
    let dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
    let dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screen.top;
    let width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    let height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;
    var left = width / 2 - w / 2 + dualScreenLeft;
    var top = height / 2 - h / 2 + dualScreenTop;
    var newWindow = window.open(
      url,
      title,
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
        w +
        ', height=' +
        h +
        ', top=' +
        top +
        ', left=' +
        left
    );
    if (newWindow) {
      newWindow.focus();
      window.addEventListener('message', eventHandler, false);
    }
  }

  const eventHandler = (event) => {
    if (event.data) {
      window.removeEventListener('message', eventHandler);
      const popupData = JSON.parse(event.data);
      if (popupData) {
        if (popupData.error) {
          openModal('확인', popupData.meta.data.user_message);
        } else {
          console.log(popupData);
          handleSubmit((data) => onSign(data, popupData.certNum))();
        }
      }
    }
  };

  return (
    <SignupBody>
      <SignupTitle title="주주 정보를 입력합니다" />
      <SignupFormBlock>
        <div>
          <ul>
            {!isMobile && (
              <li>
                <ListTag />
              </li>
            )}
            <li>
              <InputBox
                title="이름"
                isRequired
                error={errors.userName?.message}
                errorType={ErrorTypes.ERROR}
                isMobile={isMobile}
              >
                <Input
                  register={register}
                  name="userName"
                  registerOption={Validation.NAME}
                  placeholder="이름을 입력하세요"
                  isMobile={isMobile}
                  short={isMobile}
                />
              </InputBox>
            </li>
            <li>
              <InputBox
                title="생년월일"
                isRequired
                error={errors.individualUser?.birthDt?.message}
                errorType={ErrorTypes.ERROR}
                isMobile={isMobile}
              >
                <Input
                  register={register}
                  name="individualUser.birthDt"
                  registerOption={Validation.BIRTHDAY}
                  placeholder="19940401"
                  isMobile={isMobile}
                  short={isMobile}
                />
              </InputBox>
            </li>
            <li>
              <InputBox
                title="휴대전화번호"
                isRequired
                error={errors.middleCell?.message || errors.endCell?.message}
                errorType={ErrorTypes.ERROR}
                isMobile={isMobile}
              >
                <PhoneInput register={register} />
              </InputBox>
            </li>
            <li>
              <AgreeBox
                setCheckedEmail={setCheckedEmail}
                setCheckedSms={setCheckedSms}
              />
            </li>
          </ul>
        </div>
        {authMethod === AuthenticationMethod.CO_CERTIFICATE.code && (
          <SignupAuthBlock>
            <ul>
              <li>
                <Button
                  type={ButtonTypes.Primary}
                  size={ButtonSize.Medium}
                  onClick={handleSubmit((data) => onSign(data, false))}
                >
                  공동 인증서
                </Button>
              </li>
            </ul>
          </SignupAuthBlock>
        )}
        {authMethod === AuthenticationMethod.PASS_APP.code && (
          <SignupAuthBlock>
            <ul>
              <li>
                <Button
                  type={ButtonTypes.Primary}
                  size={ButtonSize.Medium}
                  onClick={openPassPopup}
                >
                  PASS 인증
                </Button>
              </li>
            </ul>
          </SignupAuthBlock>
        )}
      </SignupFormBlock>
    </SignupBody>
  );
};

export default SignupStep3;
