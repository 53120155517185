import { useState } from 'react';
import styled from 'styled-components';
import { ButtonSize } from '../../../../../../../../components/button/Button';
import Input from '../../../../../../../../components/input/Input';
import Radio from '../../../../../../../../components/input/Radio';
import RadioGroup from '../../../../../../../../components/input/RadioGroup';
import {
  DateFormatName,
  getDateFormat
} from '../../../../../../../../utils/DateFormatUtil';
import { DATE_FORMAT } from '../../../../../../../../utils/DateUtil';
import { formatNumber } from '../../../../../../../../utils/StringUtil';
import {
  CancelBtn,
  OkBtn
} from '../../../../../../../shareholder/vote/main/components/popup/common/PopupButton';
import {
  BtnAreaBlockWrapper,
  CenterBlock,
  ElecBillAppBlock,
  LinkBlock,
  ListType10BlockWrapper,
  ListType11Format,
  MarginRightBlock,
  TitleBlock,
  UnitTitle
} from '../../layout/LayoutWrapper';

const ElectronicBillPopupForm = (props) => {
  const { register, closeModal, handleSubmit, onSubmitRegister, info } = props;
  const { agendas, agm, template } = info;
  const [popupRadioVal, setPopupRadioVal] = useState(
    ELEC_BILL_CONTENT_TYPE.MANUAL.value
  );

  const onClickElecBillOkBtn = () => {
    if (popupRadioVal === ELEC_BILL_CONTENT_TYPE.AUTO.value) {
      typeof onSubmitRegister === 'function' &&
        onSubmitRegister({ title: '', content: '', initFlag: true });
    } else {
      handleSubmit((data) => {
        typeof onSubmitRegister === 'function' &&
          onSubmitRegister({
            title: data.elecBillTitle,
            content: data.elecBillContent,
            initFlag: false
          });
      })();
    }
    closeModal(250);
  };

  const renderAgendas = () => {
    return (
      <>
        {agendas.map((agenda, index) => {
          return agenda.childs.length === 0 ? (
            <ol>
              <li>
                <span>제 {agenda.agendaNumber}안. </span>
              </li>
              <li>
                <p>{agenda.agendaDetailContent}</p>
              </li>
            </ol>
          ) : (
            <ol>
              <li>
                <span>제 {agenda.agendaNumber}안. </span>
              </li>
              <li>
                <p>{agenda.agendaName}</p>
                {agenda.childs.map((child) => {
                  return (
                    <p>
                      <span>{child.agendaNumber}안.</span>
                      {child.agendaName}
                    </p>
                  );
                })}
              </li>
            </ol>
          );
        })}
      </>
    );
  };

  return (
    <>
      <ListType10BlockWrapper>
        <ul>
          <li>
            <UnitTitle>
              <span>내용 </span>
              <strong>(필수)</strong>
            </UnitTitle>
          </li>
          <li>
            <div>
              <RadioGroup
                selectedValue={popupRadioVal}
                onChange={(value) => setPopupRadioVal(value)}
              >
                <Radio
                  label={ELEC_BILL_CONTENT_TYPE.MANUAL.text}
                  value={ELEC_BILL_CONTENT_TYPE.MANUAL.value}
                  key={`elec_bill_radio_key_manual`}
                />
                <Radio
                  label={ELEC_BILL_CONTENT_TYPE.AUTO.text}
                  value={ELEC_BILL_CONTENT_TYPE.AUTO.value}
                  key={`elec_bill_radio_key_auto`}
                />
              </RadioGroup>
            </div>
          </li>
        </ul>
      </ListType10BlockWrapper>
      <ListType11Format>
        <ElecBillAppBlock>
          <li>
            <p>주주총회 소집 전자고지서</p>
          </li>
          <li>
            <p>
              <strong>{`${template.sampleMap.shareholderName} 님`}</strong>,
              <br />
              <span>
                이 주주총회 소집 통지서는 {agm.issueIncorporatedName}의 요청에
                따라 전자등록기관인 ‘블록체인 전자주총’이 발송하는 주주총회 정보
                고지서 입니다.
              </span>
            </p>
            <CenterBlock style={{ marginTop: '3%' }}>
              <strong>{agm.issueIncorporatedName}</strong>
            </CenterBlock>
            <CenterBlock>
              <strong>{agm.agmShortName}</strong>
            </CenterBlock>
            <p>
              <MarginRightBlock style={{ color: '#9EB3CD' }}>
                주주총회 일시
              </MarginRightBlock>
              {DATE_FORMAT(
                agm.agmDateTime,
                getDateFormat(DateFormatName.FULL_DATE_TIME)
              )}
            </p>
            <p>
              <MarginRightBlock style={{ color: '#9EB3CD' }}>
                주주총회 장소
              </MarginRightBlock>
              {agm.agmPlace}
            </p>
          </li>
          <hr />
          <li>
            <TitleBlock>
              <strong>소유주식 수</strong>
            </TitleBlock>
            <p>
              <span>주식수</span>
              {formatNumber(template.sampleMap.stockAmount)} 주
            </p>
            <p>
              <span>보통주</span>
              {formatNumber(template.sampleMap.commonStockAmount)} 주
            </p>
            <p>
              <span>우선주</span>
              {formatNumber(template.sampleMap.preferredStockAmount)} 주
            </p>
          </li>
          <hr />
          <li>
            <TitleBlock>
              <strong>안건명</strong>
            </TitleBlock>
            {renderAgendas()}
          </li>
          <hr />

          {popupRadioVal === ELEC_BILL_CONTENT_TYPE.MANUAL.value && (
            <>
              <InputWrapper
                placeholder="수정 가능한 제목"
                register={register}
                name="elecBillTitle"
              />
              <TextAreaBlock
                placeholder="추가 내용 기입"
                {...register('elecBillContent')}
              ></TextAreaBlock>
            </>
          )}

          <LinkBlock>블록체인 전자주총 바로가기</LinkBlock>
        </ElecBillAppBlock>
      </ListType11Format>
      <BtnAreaBlockWrapper>
        <ul>
          <li>
            <CancelBtn
              onClick={() => closeModal(250)}
              size={ButtonSize.Popup}
            />
            <OkBtn onClick={onClickElecBillOkBtn} size={ButtonSize.Popup} />
          </li>
        </ul>
      </BtnAreaBlockWrapper>
    </>
  );
};

const TextAreaBlock = styled.textarea`
  border: 1px solid #a5b7ce;
  height: 115px;
  padding: 5px 8px;
  width: 94%;
  margin: 0 3%;
  border-radius: 4px;
  background-color: #fff;
  line-height: 1.54;
  overflow: auto;
  resize: none;
  box-sizing: border-box;
`;

const InputWrapper = styled(Input)`
  width: 94%;
  padding: 0 8px;
  margin: 0 3% 10px;
`;

const ELEC_BILL_CONTENT_TYPE = {
  MANUAL: {
    text: '내용 추가',
    value: '1'
  },
  AUTO: {
    text: '자동 완성',
    value: '2'
  }
};

export default ElectronicBillPopupForm;
