import { useState, useEffect } from 'react';

export const useCalendar = (defaultDate) => {
  const MAX_LENGTH = 6;
  const COL_LENGTH = 7;
  const [date, setDate] = useState(defaultDate);
  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth());

  useEffect(() => {
    setMonth(date.getMonth());
    setYear(date.getFullYear());
  }, [date]);

  const prevMonth = () => {
    setDate(new Date(date.setMonth(date.getMonth() - 1)));
  };
  const nextMonth = () => {
    setDate(new Date(date.setMonth(date.getMonth() + 1)));
  };
  const onchangeMonth = (paramMonth) => {
    setDate(new Date(date.setMonth(paramMonth - 1)));
  };
  const onchangeYear = (paramYear) => {
    setDate(new Date(date.setFullYear(paramYear)));
  };
  const events = {
    setPrevMonth: prevMonth,
    setNextMonth: nextMonth,
    changeMonth: onchangeMonth,
    changeYear: onchangeYear
  };
  const firstDate = new Date(year, month, 1);
  const frontDate = new Date(
    year,
    month,
    firstDate.getDate() - firstDate.getDay() - 1
  );
  const weekArr = [];
  for (let i = 0; i < MAX_LENGTH; i++) {
    let lastDate;
    const dayArr = [];
    for (let j = 0; j < COL_LENGTH; j++) {
      const nextDate = new Date(frontDate.setDate(frontDate.getDate() + 1));
      if (i > 0 && j === MAX_LENGTH) {
        lastDate = nextDate;
      }
      dayArr.push(nextDate);
    }
    weekArr.push(dayArr);
    if (i > 0 && firstDate.getMonth() !== lastDate.getMonth()) {
      break;
    }
  }
  return [date, weekArr, events];
};
