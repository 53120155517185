import styled from 'styled-components';
import { useFileDownload } from '../../../../../../../../../hooks/useFileDownload';
import { fetchGetFilesENoticeAgmSendAction } from '../../../../../../../../../services/api/agm/promiseActions';
import {
  ElecBillFileType,
  getConstNameByCode
} from '../../../../../../../../../utils/constant/AgmConstant';
import {
  DateFormatName,
  getDateFormat
} from '../../../../../../../../../utils/DateFormatUtil';
import { DATE_FORMAT } from '../../../../../../../../../utils/DateUtil';
import { formatNumber } from '../../../../../../../../../utils/StringUtil';
import {
  CenterBlock,
  ElecBillAppBlock,
  LinkBlock,
  MarginRightBlock,
  TitleBlock
} from '../../../layout/LayoutWrapper';

const ElecBillTemplate = ({ data }) => {
  const {
    agm,
    agendas,
    documentAdditionalTitle,
    documentAdditionalContent,
    paramMap,
    files
  } = data;
  const { callDownloadFetchENoticeFailFiles } = useFileDownload();
  const onClickFileButton = (file) => {
    callDownloadFetchENoticeFailFiles(
      fetchGetFilesENoticeAgmSendAction,
      agm.agmSeqno,
      file.sendSeqno,
      {
        filePath: file.filePath,
        fileName:
          getConstNameByCode(ElecBillFileType, file.type) +
          '.' +
          file.fileName.split('.')[1]
      }
    );
  };
  const renderAgendas = () => {
    return (
      <>
        {agendas.map((agenda, index) => {
          return agenda.childs.length === 0 ? (
            <ol>
              <li>
                <span>제 {agenda.agendaNumber}안. </span>
              </li>
              <li>
                <p>{agenda.agendaDetailContent}</p>
              </li>
            </ol>
          ) : (
            <ol>
              <li>
                <span>제 {agenda.agendaNumber}안. </span>
              </li>
              <li>
                <p>{agenda.agendaName}</p>
                {agenda.childs.map((child) => {
                  return (
                    <p>
                      <span>{child.agendaNumber}안.</span>
                      {child.agendaName}
                    </p>
                  );
                })}
              </li>
            </ol>
          );
        })}
      </>
    );
  };
  return (
    <ul>
      <li>
        <ElecBillAppBlock>
          <li>
            <p style={{ background: 'none' }}>주주총회 소집 전자고지서</p>
          </li>
          <li>
            <p>
              <strong>{`${paramMap.shareholderName} 님`}</strong>,<br />
              <span>
                이 주주총회 소집 통지서는 {agm.issueIncorporatedName}의 요청에
                따라 전자등록기관인 ‘블록체인 전자주총’이 발송하는 주주총회 정보
                고지서 입니다.
              </span>
            </p>
            <CenterBlock style={{ marginTop: '3%' }}>
              <strong>{agm.issueIncorporatedName}</strong>
            </CenterBlock>
            <CenterBlock>
              <strong>{agm.agmShortName}</strong>
            </CenterBlock>
            <p>
              <MarginRightBlock style={{ color: '#9EB3CD' }}>
                주주총회 일시
              </MarginRightBlock>
              {DATE_FORMAT(
                agm.agmDateTime,
                getDateFormat(DateFormatName.FULL_DATE_TIME)
              )}
            </p>
            <p>
              <MarginRightBlock style={{ color: '#9EB3CD' }}>
                주주총회 장소
              </MarginRightBlock>
              {agm.agmPlace}
            </p>
          </li>
          <li>
            <TitleBlock>
              <strong>주주총회 소집통지서</strong>
            </TitleBlock>
            {files.map((file) => {
              return (
                <button onClick={() => onClickFileButton(file)}>
                  {getConstNameByCode(ElecBillFileType, file.type)}
                </button>
              );
            })}
            {/* <button>제5회 한국전자투표 주주총회 소집통지서.pdf</button>
            <button>기타 소집 자료서.pdf</button> */}
          </li>
          <hr />
          <li>
            <TitleBlock>
              <strong>소유주식 수</strong>
            </TitleBlock>
            <p>
              <span>주식수</span>
              {formatNumber(paramMap.stockAmount)} 주
            </p>
            <p>
              <span>보통주</span>
              {formatNumber(paramMap.commonStockAmount)} 주
            </p>
            <p>
              <span>우선주</span>
              {formatNumber(paramMap.preferredStockAmount)} 주
            </p>
          </li>
          <hr />
          <li>
            <TitleBlock>
              <strong>안건명</strong>
            </TitleBlock>
            {renderAgendas()}
          </li>
          <hr />

          {/* {!elecBillStateFlag && (
            <ButtonWrapper
              type={ButtonTypes.Primary}
              onClick={onClickElecBillBtn}
            >
              내용 추가
            </ButtonWrapper>
          )} */}
          {/* {elecBillStateFlag && ( */}
          <li>
            <TitleBlock>
              <strong>{documentAdditionalTitle}</strong>
            </TitleBlock>
            <PreBlock>{documentAdditionalContent}</PreBlock>
          </li>
          {/* )} */}
          {/* {elecBillStateFlag && (
            <EditButtonBlock
              type={ButtonTypes.Primary}
              onClick={onClickElecBillBtn}
            >
              내용 수정하기
            </EditButtonBlock>
          )} */}

          <LinkBlock
            onClick={() =>
              // history.push('https://blockchainagm.com/static/link')
              (window.location.href = 'https://blockchainagm.com/static/link')
            }
          >
            블록체인 전자주총 바로가기
          </LinkBlock>
        </ElecBillAppBlock>
      </li>
    </ul>
  );
};

const PreBlock = styled.pre`
  font-size: 13px;
  word-break: keep-all;
  white-space: normal;
  line-height: 1.5;
`;

export default ElecBillTemplate;
