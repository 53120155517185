import { Route, Switch } from 'react-router';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import BoardRegisterPage from './BoardRegisterPage';
import NoticeDetail from './main/NoticeDetail';
import NoticeList from './main/NoticeList';

const NoticePage = () => {
  return (
    <Page>
      <PageHeader title="공지사항" />
      <Switch>
        <Route path="/notice" component={NoticeList} exact />
        <Route path="/:board/register" component={BoardRegisterPage} exact />
        <Route path="/notice/:notientSeqno" component={NoticeDetail} />
      </Switch>
    </Page>
  );
};

export default NoticePage;
