import React from 'react';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';

const VoteBoxListBlock = styled.div`
  // .boxlist_ty > ul > li
  & > ul > li {
    //margin: 16px 0;

    // .boxlist_ty .unit
    & ul {
      position: relative;
      display: block;
      padding: 50px 100px 36px;
      background-color: #fff;
      border: solid 1px #d8e1eb;
      border-radius: 8px;
      box-shadow: 0px 2px 4px rgb(0 0 0 / 12%);

      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        position: relative;
        display: block;
        padding: 50px 20px 24px;
        background-color: #fff;
        border: solid 1px #d8e1eb;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 12%);
      }

      // .boxlist_ty .unit li {
      & li {
        width: 100%;
        font-size: 15px;
        letter-spacing: -0.5px;
        text-align: center;
      }
    }
  }
`;

const VoteBoxList = ({ children }) => {
  return (
    <VoteBoxListBlock>
      <ul>{children}</ul>
    </VoteBoxListBlock>
  );
};

export default VoteBoxList;
