import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Spinner from '../../../../../../components/alert/Spinner';
import { ButtonSize } from '../../../../../../components/button/Button';
import { MODAL_SIZE } from '../../../../../../components/modal/Modal';
import { ModalContext } from '../../../../../../context/ModalContext';
import { fetchGetAgmElectionVoterMineAction } from '../../../../../../services/api/agm/promiseActions';
import {
  checkUndefinedNum,
  formatNumber
} from '../../../../../../utils/StringUtil';
import {
  BoxInfoBlock,
  BoxListBlock,
  PopupType
} from './common/PopupBlockStyle';
import { OkBtn } from './common/PopupButton';

/**
 * 보유주식 상세보기 팝업
 */

const StockDetailPopup = (props) => {
  let { setModalConfig, closeModal } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const { agmSeqno, voters } = props;
  console.log('voters');
  console.log(voters);
  useEffect(() => {
    setModalConfig({
      size: MODAL_SIZE.MEDIUM,
      buttons: [
        <OkBtn size={ButtonSize.Default} onClick={() => closeModal(250)} />
      ]
    });
  }, []);

  useEffect(() => {
    dispatch(
      fetchGetAgmElectionVoterMineAction({
        agmSeqno
      })
    ).then((res) => {
      console.log('fetchGetAgmElectionVoterAction');
      console.log(res);
      setData(voters);
      setLoading(true);
    });
  }, [dispatch, voters, agmSeqno]);

  return (
    <>
      {!loading && <Spinner />}
      {data && (
        <>
          {/* <DescPTagBlock>
            우선주는 의결권 없는 주식수에 포함되어 있습니다.
          </DescPTagBlock> */}
          <BoxInfoBlock>
            <ul>
              <li>
                <span>주주명</span>
                <p>
                  {data.stockholderName}
                  <span>({data.stockholderRealNameNumber})</span>
                </p>
              </li>
              <li>
                <span>보유주식</span>
                <p>
                  {formatNumber(
                    data.commonStockAmount +
                      checkUndefinedNum(data.exercisablePreferredStockAmount) +
                      checkUndefinedNum(data.preferredStockAmount)
                  )}
                  주
                </p>
              </li>
              <li>
                <span>보통주</span>
                <p>{formatNumber(data.commonStockAmount)}주</p>
              </li>
              <li>
                <span>우선주</span>
                <p>
                  {formatNumber(
                    checkUndefinedNum(data.preferredStockAmount) +
                      checkUndefinedNum(data.exercisablePreferredStockAmount)
                  )}
                  주
                </p>
              </li>
              {checkUndefinedNum(data.exercisablePreferredStockAmount) !==
                0 && (
                <li>
                  <span>의결권 있는 우선주</span>
                  <p>
                    {formatNumber(
                      checkUndefinedNum(data.exercisablePreferredStockAmount)
                    )}
                    주
                  </p>
                </li>
              )}
            </ul>
          </BoxInfoBlock>
          <BoxListBlock type={PopupType.STOCK_DETAIL}>
            {data.details?.map((item, idx) => (
              <ul key={`BoxListBlock-ul-${idx}`}>
                <li>
                  <ul>
                    <li>
                      <div className="sequence">No : {idx + 1}</div>
                      <p>보유주식 상세 {idx + 1}</p>
                    </li>
                    <li>
                      <p>
                        보통주
                        <span>{formatNumber(item.commonStockAmount)}주</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        우선주 (의결권 있는 우선주)
                        <span>{`${formatNumber(
                          checkUndefinedNum(item.preferredStockAmount) +
                            checkUndefinedNum(
                              item.exercisablePreferredStockAmount
                            )
                        )} (${checkUndefinedNum(
                          item.exercisablePreferredStockAmount
                        )}) 주`}</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>보유 주식 수</strong>
                        <span>
                          <strong>
                            {formatNumber(
                              item.commonStockAmount +
                                checkUndefinedNum(item.preferredStockAmount) +
                                checkUndefinedNum(
                                  item.exercisablePreferredStockAmount
                                )
                            )}
                            주
                          </strong>
                        </span>
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            ))}
            {/* {data.voteRights.map((item, idx) => (
              <ul key={`BoxListBlock-ul-${idx}`}>
                <li>
                  <ul>
                    <li>
                      <div className="sequence">의안{item.agendaNumber}</div>
                      <p>{item.agendaName}</p>
                    </li>
                    <li>
                      <p>
                        명부상 보유 주식수
                        <span>
                          {formatNumber(
                            data.commonStockAmount +
                              data.exercisablePreferredStockAmount +
                              data.preferredStockAmount
                          )}
                          주
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        의결권 없는 주식수
                        <span>
                          {formatNumber(
                            item.noVotingRightsStockAmmount +
                              data.preferredStockAmount
                          )}
                          주
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        의결권 제한 주식수
                        <span>
                          {formatNumber(item.votingRightsLimitStockAmmount)}주
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>행사가능주식수</strong>
                        <span>
                          <strong>
                            {formatNumber(item.exercisableStockAmmount)}주
                          </strong>
                        </span>
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            ))} */}
          </BoxListBlock>
        </>
      )}
    </>
  );
};

export default StockDetailPopup;
