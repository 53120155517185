import { DELETE, GET, POST, PUT } from '../../../utils/constant/httpMethod';
import {
  request,
  requestFile,
  requestMultipart
} from '../../../utils/requestHelper';

const API_GROUP = 'agm';
const Agm = {
  /**
   * 주주총회 개설
   */
  // 주총개설 - 기본정보 입력(Page1)
  fetchPostAgmBaseInfo({ data }) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/base-info`,
      data
    });
  },
  // 주총개설 - 기본정보 수정
  fetchPutAgmBaseInfo({ agmSeqno, data }) {
    return request({
      method: PUT,
      url: `/v1/${API_GROUP}/${agmSeqno}/base-info`,
      data
    });
  },
  // 주총개설 - 기본정보 조회
  fetchGetAgmBaseInfo(agmSeqno) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/base-info`
    });
  },
  // 주주총회 파일 조회
  fetchGetFilesAgm({ agmSeqno, params }) {
    return requestFile({
      method: GET,
      url: `/v1/files/${API_GROUP}/${agmSeqno}`,
      params: {
        filePath: params.filePath,
        fileName: params.fileName
      }
    });
  },

  /**
   * 안건
   */
  // 주총개설 - 안건등록(Page2)
  fetchPostAgmAgenda({ agmSeqno, data }) {
    console.info(data);
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/${agmSeqno}/agendas`,
      data
    });
  },
  // 주총개설 - 안건수정(Page2)
  fetchPutAgmAgenda({ agmSeqno, data }) {
    return request({
      method: PUT,
      url: `/v1/${API_GROUP}/${agmSeqno}/agendas`,
      data
    });
  },
  // 주총개설 - 안건삭제(Page2)
  fetchDeleteAgmAgenda({ agmSeqno, data }) {
    return request({
      method: DELETE,
      url: `/v1/${API_GROUP}/${agmSeqno}/agendas`,
      params: {
        ...data
      }
    });
  },
  // 주총개설 - 안건 목록 조회(전체)
  fetchGetAgmAgendas({ agmSeqno, params }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/agendas`,
      params
    });
  },
  // 주총개설 - 안건 목록 조회(부모만)
  fetchGetAgmAgendasParentOnly({ agmSeqno }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/agendas/parent-only`
    });
  },
  // 주총개설 - 안건 목록 조회(투표대상만)
  fetchGetAgmAgendasVoteable({ agmSeqno, params = null }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/agendas/votable`,
      params
    });
  },
  // 주총개설 - 안건 조회
  fetchGetAgmAgendasByAgendaSeqno({ agmSeqno, agendaSeqno, params }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/agendas/${agendaSeqno}`,
      params
    });
  },

  /*
    주주 명부
  */
  // 주총개설 - 명부업로드(Page2)
  fetchPostAgmStockHolders({ agmSeqno, data }) {
    return requestMultipart({
      method: POST,
      url: `/v1/${API_GROUP}/${agmSeqno}/stockholders`,
      data
    });
  },
  // 주총개설 - 실패명부다운로드(Page2)
  fetchGetAgmStockHoldersFailExcel({ agmSeqno, filePath }) {
    return requestFile({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/stockholders/fail-excel`,
      params: { filePath: filePath }
    });
  },
  // 주총개설 - 명부 전체 조회
  fetchGetAgmStockHolders({ agmSeqno, data }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/stockholders`,
      params: data
    });
  },
  // 주총개설 - 명부 검색(이름)
  fetchGetAgmStockholdersSearch({ agmSeqno, params }) {
    return request({
      method: GET,
      url: `/v1/agm/${agmSeqno}/stockholders/search`,
      params: params
    });
  },
  // 주총개설 - 주주 조회
  fetchGetAgmStockHolder({ agmSeqno, identificationNumber, stockholderSeqno }) {
    const params = !!identificationNumber
      ? {
          identificationNumber: identificationNumber
        }
      : {
          stockholderSeqno: stockholderSeqno
        };
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/stockholder`,
      params: params
    });
  },

  /**
   * 전자고지서
   */
  // 타입별 전자고지서 목록
  fetchGetENoticeAgmType({ agmSeqno, type }) {
    return request({
      method: GET,
      url: `/v1/e-notice/${API_GROUP}/${agmSeqno}/type/${type}`
    });
  },
  // 타입별 전자고지서 상세 목록
  fetchGetENoticeAgmTypeDetail({ agmSeqno, type }) {
    return request({
      method: GET,
      url: `/v1/e-notice/${API_GROUP}/${agmSeqno}/type/${type}/detail`
    });
  },
  // 전자고지서 수정화면
  fetchGetENoticeAgmSend({ agmSeqno, sendSeqno }) {
    return request({
      method: GET,
      url: `/v1/e-notice/${API_GROUP}/${agmSeqno}/send/${sendSeqno}`
    });
  },
  // 전자고지서 등록
  fetchPostENoticeAgm({ agmSeqno, data }) {
    return request({
      method: POST,
      url: `/v1/e-notice/${API_GROUP}/${agmSeqno}`,
      data
    });
  },
  // 전자고지서 수정
  fetchPutENoticeAgmSend({ agmSeqno, sendSeqno }) {
    return request({
      method: PUT,
      url: `/v1/e-notice/${API_GROUP}/${agmSeqno}/send/${sendSeqno}`
    });
  },
  // 전자고지서 삭제
  fetchDeleteENoticeAgmSend({ agmSeqno, sendSeqno }) {
    return request({
      method: DELETE,
      url: `/v1/e-notice/${API_GROUP}/${agmSeqno}/send/${sendSeqno}`
    });
  },
  // 전자고지서 템플릿 조회
  fetchGetENoticeTemplatesAgmType({ agmSeqno, type }) {
    return request({
      method: GET,
      url: `/v1/e-notice/templates/${API_GROUP}/${agmSeqno}/type/${type}`
    });
  },
  // 전자고지서 문서 조회 - 주주
  fetchGetENotice(data) {
    return request({
      method: GET,
      url: `/v1/e-notice`,
      params: data
    });
  },
  // 전자고지서 상세 정보
  fetchGetENoticeAgmSendDetail({ agmSeqno, sendSeqno }) {
    return request({
      method: GET,
      url: `/v1/e-notice/${API_GROUP}/${agmSeqno}/send/${sendSeqno}/detail`
    });
  },
  // 전자고지서 발송 실패자 목록
  fetchGetENoticeAgmSendFails({ agmSeqno, sendSeqno, data }) {
    return request({
      method: GET,
      url: `/v1/e-notice/${API_GROUP}/${agmSeqno}/send/${sendSeqno}/fails`,
      params: data
    });
  },
  // 전자고지서 발송 실패자 목록 엑셀 다운로드
  fetchGetENoticeAgmSendFailsExcelDownload({ agmSeqno, sendSeqno }) {
    return requestFile({
      method: GET,
      url: `/v1/e-notice/${API_GROUP}/${agmSeqno}/send/${sendSeqno}/fails/excel-download`
    });
  },
  // 전자고지서 파일 다운로드
  fetchGetFilesENoticeAgmSend({ agmSeqno, sendSeqno, data }) {
    return requestFile({
      method: GET,
      url: `/v1/files/e-notice/${API_GROUP}/${agmSeqno}/send/${sendSeqno}`,
      params: data
    });
  },
  /**
   * ******
   * 의결권
   * ******
   */
  // 주총개설 - 의결권 제한 등록
  fetchPostAgmLimits({ agmSeqno, data }) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/${agmSeqno}/limits`,
      data
    });
  },
  // 주총개설 - 의결권 제한 수정
  fetchPutAgmLimits({ agmSeqno, data }) {
    return request({
      method: PUT,
      url: `/v1/${API_GROUP}/${agmSeqno}/limits`,
      data
    });
  },
  // 주총개설 - 의결권 제한 삭제
  fetchPutAgmLimitsDelete({ agmSeqno, data }) {
    return request({
      method: PUT,
      url: `/v1/${API_GROUP}/${agmSeqno}/limits/delete`,
      data
    });
  },
  // 주총개설 - 의결권 제한 조회
  fetchGetAgmLimits({ agmSeqno, data }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/limits`,
      params: data
    });
  },
  // 주총개설 - 의결권 제한 조회 페이징
  fetchGetAgmLimitsPage({ agmSeqno, data }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/limits/page`,
      params: data
    });
  },
  // 주총개설 - 단일주주 의결권 제한 조회
  fetchGetAgmLimitsStockholders({
    securitiesCorpSeqno,
    agmSeqno,
    stockholderSeqno
  }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/limits/stockholders/${stockholderSeqno}`,
      params: {
        securitiesCorpSeqno: securitiesCorpSeqno,
        agmSeqno: agmSeqno,
        stockholderSeqno: stockholderSeqno
      }
    });
  },
  // 주총개설 - 전자투표권자 의결권 제한 조회
  // TODO 위 메소드 명과 함께 변경해야함, API 목록과 일치하지 않음
  fetchGetAgmLimitsStockholdersAll({ securitiesCorpSeqno, agmSeqno }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/limits/stockholders/`,
      params: {
        securitiesCorpSeqno: securitiesCorpSeqno,
        agmSeqno: agmSeqno
      }
    });
  },
  // 주총개설 - 의결권 조회(단일주주)
  fetchGetAgmElectionVoter({ agmSeqno, identificationNumber }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/election-voter`,
      params: { identificationNumber }
    });
  },
  // 주총개설 - 안건별 의결권 조회(전체)
  fetchGetAgmAgendaElectionVoters({ agmSeqno, agendaSeqno, pageNumber, size }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/agenda/${agendaSeqno}/election-voters`,
      params: { pageNumber, size }
    });
  },
  // 주총개설 - 의결권 합산
  fetchGetAgmVotingRightsTotal({ agmSeqno }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/voting-rights/total`
    });
  },
  /**
   * 의결권 제한 등록(엑셀)
   */
  // 주총개설 - 의결권 제한 샘플 다운로드
  fetchGetFilesLimitsSample() {
    return requestFile({
      method: GET,
      url: `/v1/files/limits/sample`
    });
  },
  // 주총개설 - 의결권 제한 등록(엑셀 업로드)
  fetchPostAgmLimitsFileUpload({ agmSeqno, data }) {
    return requestMultipart({
      method: POST,
      url: `/v1/${API_GROUP}/${agmSeqno}/limits/file-upload`,
      data
    });
  },
  // 주총개설 - 의결권 제한 실패 다운로드
  fetchGetAgmLimitsFailExcel({ agmSeqno, filePath }) {
    return requestFile({
      method: POST,
      url: `/v1/${API_GROUP}/${agmSeqno}/limits/fail-excel`,
      params: {
        filePath
      }
    });
  },
  /**
   * 전자투표권자 지정(엑셀)
   */
  // 주총개설 - 전자투표권자 지정 샘플 다운로드
  fetchGetFilesElectionVoterSample({ agmSeqno, filePath }) {
    return requestFile({
      method: GET,
      url: `/v1/files/election-voter/sample`,
      params: {
        filePath
      }
    });
  },
  // 주총개설 - 전자투표권자 지정(엑셀 업로드)
  fetchPostAgmElectionVoterFileUpload({ agmSeqno, data }) {
    return requestMultipart({
      method: POST,
      data
    });
  },
  // 주총개설 - 전자투표권자 지정 실패 다운로드
  fetchGetAgmElectionVoterFailExcel({ agmSeqno, filePath }) {
    return requestFile({
      method: POST,
      url: `/v1/${API_GROUP}/${agmSeqno}/election-voter/fail-excel`,
      params: {
        filePath
      }
    });
  },
  /**
   * 전자투표권자
   */
  // 임시 주총 상태일 때 개인정보 조회
  fetchPostAgmBrowseIndividual({ agmSeqno, data }) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/${agmSeqno}/browse-individual`,
      params: {
        ...data
      }
    });
  },

  // 주총개설 - 전자투표권자 지정
  fetchPostAgmElectionVoter({ agmSeqno, data }) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/${agmSeqno}/election-voter`,
      data
    });
  },
  // 전자투표권자 지정 (개설 완료 후)
  fetchPostAgmElectionVoterWithSign({ agmSeqno, data }) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/${agmSeqno}/election-voter-with-sign`,
      data
    });
  },
  // 의안별 전자투표권자명부 다운로드
  fetchGetAgmAgendasElectionVotersExcel({ agmSeqno, agendaSeqno }) {
    return requestFile({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/agendas/${agendaSeqno}/election-voters/excel`
    });
  },

  /**
   * 주주총회 상태 변경
   */

  // 주총개설 - 주총개설완료
  fetchPutAgmComplete({ agmSeqno, data }) {
    return request({
      method: PUT,
      url: `/v1/${API_GROUP}/${agmSeqno}/complete`,
      data
    });
  },
  // 주총개설 - 주총수정시작
  fetchPutAgmStartModification({ agmSeqno, data }) {
    return request({
      method: PUT,
      url: `/v1/${API_GROUP}/${agmSeqno}/start-modification`,
      data
    });
  },

  /**
   * 주주총회 조회
   */
  // 주주총회 일정 조회-사용자
  fetchGetAgmSchedule(params) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/schedule`,
      params
    });
  },
  //주주총회 일정 조회-게스트
  fetchGetAgmScheduleGuest(params) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/schedule/guest`,
      params
    });
  },
  /**
   * 주주총회 조회 > 주주
   */
  // 내 주주총회 목록 조회
  fetchGetAgm() {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}`
    });
  },
  // 주주-> 주주총회 조회
  fetchGetAgmByAgmSeqno(agmSeqno) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}`
    });
  },
  // 의결권 행사내역 조회
  fetchGetAgmExcercisedRights(agmSeqno) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/excercised-rights`
    });
  },
  // 주주 - 참여 행사확인서 pdf 다운로드
  fetchGetAgmExcercisedConfirmPdf({ agmSeqno }) {
    return requestFile({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/excercised/confirm/pdf`
    });
  },
  // 의결권 조회
  fetchGetAgmElectionVoterMine({ agmSeqno }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/election-voter/mine`
    });
  },

  /**
   * 주주총회 조회 > 발행회사
   */
  // 투표 개설 후 개인정보 조회
  fetchGetAgmBrowseIndividual({ agmSeqno, data }) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/${agmSeqno}/browse-individual`,
      data
    });
  },

  // 위임장 pdf다운로드
  fetchGetAgmElectronicProxyPdf({ agmSeqno }) {
    return requestFile({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/electronic-proxy/pdf`
    });
  },
  // 이전 주주총회 목록 조회
  fetchGetAgmPrevious() {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/previous`
    });
  },
  fetchGetAgmOpened() {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/opened`
    });
  },
  // 발행회사 최근 주주총회 조회
  fetchGetAgmLatest() {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/latest`
    });
  },
  // 발행회사 주주총회 개요 조회
  fetchGetAgmOutline({ agmSeqno, params }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/outline`,
      params
    });
  },
  // 의결권 행사내역 조회(전자투표)
  fetchGetAgmAgendasVotedRights({ agmSeqno, agendaSeqno, params }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/agendas/${agendaSeqno}/voted-rights`,
      params
    });
  },
  // 의결권 행사내역 조회(전자위임장)
  fetchGetAgmAgendasProxyRights({ agmSeqno, agendaSeqno, params }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/agendas/${agendaSeqno}/proxy-rights`,
      params
    });
  },
  // 의결권 불통일 행사내역 조회
  fetchGetAgmVotingRightsDiversities({ agmSeqno, params }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/voting-rights-diversities`,
      params
    });
  },
  // 현황 엑셀 다운로드
  fetchGetFilesAgmExcel({ agmSeqno }) {
    return requestFile({
      method: GET,
      url: `/v1/files/${API_GROUP}/${agmSeqno}/excel`
    });
  },

  /**
   * 주총 결과
   */
  // 주총 결과 - 전자투표 결과
  fetchGetAgmVoteboxResult({ agmSeqno }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/votebox-result`
    });
  },
  // 주총 결과 - 안건별 상세 투표 주주 정보
  fetchGetAgmVoteboxResultAgendaParticipants({
    agmSeqno,
    agendaSeqno,
    params
  }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/votebox-result/agenda/${agendaSeqno}/participants`,
      params
    });
  },
  // 주총 결과 - 전자위임장 결과
  fetchGetAgmProxyResult({ agmSeqno, params }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/proxy-result`,
      params
    });
  },
  // 주총 결과 - 안건별 상세 위임 주주 정보
  fetchGetAgmProxyResultAgendaParticipants({ agmSeqno, agendaSeqno, params }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/proxy-result/agenda/${agendaSeqno}/participants`,
      params
    });
  },
  // 주총 결과 합산 - 남은 행사 가능 수량 조회
  fetchGetAgmRemainExercisableStock({ agmSeqno }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/remain-exercisable-stock`
    });
  },
  // 주총 결과 합산 - 현장 결과 등록
  fetchPostAgmSpotResult({ agmSeqno, data }) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/${agmSeqno}/spot-result`,
      data
    });
  },
  // 주총 결과 합산 - 현장 합산 결과 등록
  fetchPostAgmTotalResult({ agmSeqno, data }) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/${agmSeqno}/total-result`,
      data
    });
  },
  // 주총 결과 - 합산 결과 조회
  fetchGetAgmTotalResult({ agmSeqno }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${agmSeqno}/total-result`
    });
  },
  // 주총 결과 - 합산 결과 엑셀
  fetchGetAgmTotalResultExcel({ agmSeqno }) {
    return requestFile({
      method: GET,
      url: `/v1/agm/${agmSeqno}/total-result/excel`
    });
  },

  // 의안 파일 조회
  fetchGetFilesAgmAgenda({ agmSeqno, agendaSeqno, params }) {
    return requestFile({
      method: GET,
      url: `/v1/files/${API_GROUP}/${agmSeqno}/agenda/${agendaSeqno}`,
      params
    });
  }
};

export default Agm;
