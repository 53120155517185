import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffect, useState } from 'react';
import result1Jpg from '../../../../../../styles/images/common/sprite/issinc/result_1.jpg';
import result2Jpg from '../../../../../../styles/images/common/sprite/issinc/result_2.jpg';
import { Tab_State_Const } from '../../../../../../utils/constant/AgmConstant';
import {
  SerInnerBlock,
  SerStepBlock,
  SerStepItemBlock,
  SerViewBlock,
  SerViewItemBlock,
  SerViewNextBtnBlock,
  SerViewPrevBtnBlock,
  StyledSlider
} from '../../components/InnerBlock';

const AgmResult = ({ title }) => {
  const [showIndex, setShowIndex] = useState(0);
  const [nav, setNav] = useState();

  const Images = [
    {
      url: result1Jpg,
      desc: '로그인 후 나의 주주총회에서 주주총회 결과를 볼 수 있습니다.'
    },
    {
      url: result2Jpg,
      desc: '의안별 가결/부결 상태를 알 수 있으며,<br />해당 의안 선택 시 의안별 자세한 투표 결과를 알 수 있습니다.'
    }
  ];

  const length = Images.length;

  useEffect(() => {
    nav && nav.slickGoTo(showIndex);
  }, [showIndex, nav]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    swipeToSlide: false
  };

  const onClickPrevBtn = () => {
    if (showIndex !== 0) {
      setShowIndex((index) => index - 1);
    } else {
      setShowIndex(length - 1);
    }
  };

  const onClickNextBtn = () => {
    if (showIndex !== length - 1) {
      setShowIndex((index) => index + 1);
    } else {
      setShowIndex(0);
    }
  };

  const getShowState = (state) => {
    return showIndex === state
      ? Tab_State_Const.ING
      : showIndex < state
      ? Tab_State_Const.WILL
      : Tab_State_Const.DONE;
  };

  return (
    <SerInnerBlock>
      {/* <h3>{title}</h3> */}
      <SerStepBlock>
        <ul>
          {/* step 개수대로 map 돌려야됨 */}
          {Array(length)
            .fill()
            .map((_, index) => {
              return (
                <SerStepItemBlock state={getShowState(index)}>
                  <a href="#!" onClick={() => setShowIndex(index)}>
                    {index + 1}
                  </a>
                </SerStepItemBlock>
              );
            })}
        </ul>
      </SerStepBlock>
      <SerViewBlock>
        <SerViewPrevBtnBlock onClick={onClickPrevBtn}>이전</SerViewPrevBtnBlock>
        <SerViewNextBtnBlock onClick={onClickNextBtn}>다음</SerViewNextBtnBlock>
        <StyledSlider {...settings} ref={(c) => setNav(c)}>
          {Images.map((image, index) => {
            return (
              <SerViewItemBlock key={index} notImportant>
                <img src={image.url} alt={`image_${index}`} />
                <p
                  dangerouslySetInnerHTML={{
                    __html: image.desc
                  }}
                />
              </SerViewItemBlock>
            );
          })}
        </StyledSlider>
        <button style={{ display: 'none' }} />
      </SerViewBlock>
    </SerInnerBlock>
  );
};

export default AgmResult;
