import React from 'react';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import { formatNumber } from '../../../../../utils/StringUtil';
import { AbstentionButton } from './VoteAgendaBoxTitle';

const VoteAgendaContainerBlock = styled.li`
  // .boxlist_ty > ul > li
  //margin: 16px 0;
`;

const VoteChildAgendaBoxBlock = styled.ul`
  // .boxlist_ty .unit .agenda
  &&& {
    position: relative;
    // Temp
    box-shadow: none;
    border: none;
    // .boxlist_ty .unit li ul
    padding: 30px 0;
    border-bottom: 1px solid #e9ecf4;

    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      padding: 12px 0;
      border-bottom: 1px solid #e9ecf4;
    }

    & > li {
      width: 100%;
      font-size: 15px;
      letter-spacing: -0.5px;
      text-align: center;
    }
  }
`;

const SpanBlock = styled.span`
  display: inline-block;
  width: auto;
  padding: 10px 0 6px;
  font-size: 14px;
  color: #575f76;
`;

const VoteChildAgendaTitleBlock = styled.p`
  // .boxlist_ty .unit .agenda p
  display: block;
  text-align: left;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 0;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    display: block;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    padding-right: 80px;
  }

  & span {
    // .boxlist_ty .unit .agenda p span
    display: block;
    padding-left: 30px;

    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      width: auto;
      padding: 8px 0 0;
      font-size: 13px;
      color: #575f76;
    }

    & strong {
      margin-left: 20px;
      color: #0075ff;
    }
  }
`;

const VoteChildAgendaBox = ({
  useAbsten,
  isAbsten,
  agendaName,
  stockAmount,
  children,
  agenda,
  onAbstenClick
}) => {
  return (
    <VoteAgendaContainerBlock>
      <VoteChildAgendaBoxBlock>
        <li>
          <VoteChildAgendaTitleBlock>
            {agenda.agendaName}
            <SpanBlock>
              행사가능주식수
              <strong>{`${formatNumber(stockAmount)}주`}</strong>
            </SpanBlock>
          </VoteChildAgendaTitleBlock>
          {useAbsten && (
            <AbstentionButton active={isAbsten} onClick={onAbstenClick}>
              기권
            </AbstentionButton>
          )}
        </li>
        <li>{children}</li>
      </VoteChildAgendaBoxBlock>
    </VoteAgendaContainerBlock>
  );
};

export default VoteChildAgendaBox;
