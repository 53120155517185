import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import spriteCommonPng from '../../../../../styles/images/common/sprite/sp_common.png';

export const VOTE_BUTTON_SIZE = {
  Short: 0,
  Long: 1
};

export const VOTE_RESULT_BUTTON_TYPE = {
  Approved: '1',
  Rejected: '2',
  Invalid: '3'
};

export const VOTE_BUTTON_TEXT_ALIGN = {
  Left: 0,
  Center: 1,
  Right: 2
};

const DefaultButtonStyle = styled.button`
  // .boxlist_ty .unit_result button
  position: relative;
  display: inline-block;
  margin-left: 20px;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  padding: 6px 30px 6px 40px;
  height: 38px;
  background-color: #fff;
  border-radius: 20px;
  border: 2px solid #66738d;
  box-sizing: border-box;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: absolute;
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 4px 18px 3px 30px;
    height: 32px;
    background-color: #fff;
    border-radius: 16px;
    border: 2px solid #66738d;
    box-sizing: border-box;

    word-break: keep-all;
  }

  &::after {
    // .boxlist_ty .unit_result .invalid::after
    content: '';
    position: absolute;
    top: 6px;
    left: 6px;
    width: 24px;
    height: 24px;
    background: url${spriteCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -1px -189px;
  }
`;

export default DefaultButtonStyle;
