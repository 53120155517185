import React from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../components/button/CommonStyle';
import { SignupStep } from '../../../../utils/constant/AgmConstant';

const SignupFooterBlock = styled.div`
  margin-top: 0;
  border-top: 0;
  padding-top: 60px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding-top: 0px;
    margin-top: 40px;
  }
  & > ul {
    display: flex;
    align-items: center;
    & > li {
      flex: 1;
      margin-left: 20px;
    }
    & > li:first-of-type {
      margin-left: 0;
    }
    & > li:only-of-type {
      text-align: center;
    }
  }
`;

const AnchorBlock = styled.a`
  display: inline-block;
  min-width: 300px;
  padding: 16px 40px 15px 40px;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.54;
  letter-spacing: -0.5px;
  text-align: center;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    width: 100%;
    padding: 11px 14px 10px;
    font-size: 15px;
  }
  &:hover {
    cursor: pointer;
  }
  ${(props) => {
    if (!props.allChecked) {
      return css`
        background-color: #dde3eb;
        color: #fff;
      `;
    } else {
      return css`
        background-color: #284cb0;
        color: #fff;
      `;
    }
  }}
`;

const SignupFooter = ({ allChecked, step, onClick }) => {
  return (
    <SignupFooterBlock>
      <ul>
        <li>
          <AnchorBlock allChecked={allChecked} onClick={onClick}>
            {step === SignupStep.Terms ? '다음 단계' : '확인'}
          </AnchorBlock>
        </li>
      </ul>
    </SignupFooterBlock>
  );
};

export default SignupFooter;
