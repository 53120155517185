import React from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import { AgendaResultType } from '../../../../../utils/constant/AgmConstant';
import { formatNumber } from '../../../../../utils/StringUtil';
import { VOTE_RESULT_CODE } from '../../VoteResultPage';
import { VOTE_RESULT_BUTTON_TYPE } from './DefaultButtonStyle';
import VoteResultButton from './VoteResultButton';
export const VOTE_RESULT_ITEM_TYPE = {
  Defualt: 0,
  Short: 1,
  Long: 2
};

const getVoteResultItemCss = (type) => {
  switch (type) {
    case VOTE_RESULT_ITEM_TYPE.Short:
      // .boxlist_ty .unit_result .short
      return css`
        width: 84px;
        font-size: 20px;
      `;

    case VOTE_RESULT_ITEM_TYPE.Long:
      // .boxlist_ty .unit_result .long
      return css`
        width: 400px;
        padding-bottom: 16px;
        margin-bottom: 10px;
        border-bottom: 1px solid #e9ecf4;
      `;

    default:
      break;
  }
};

const VoteResultItemBlock = styled.li`
  ${(props) =>
    props.isInvalid &&
    css`
      color: #9eb3cd;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        color: #9eb3cd;
      }
    `};
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    & > *:not(button) {
      width: 70% !important;
      &:not(:first-child) {
        padding-left: 32px;
      }
    }
  }
`;

const ItemTitleBlock = styled.span`
  // .boxlist_ty .unit_result li span
  position: relative;
  display: inline-block;
  width: 160px;
  padding: 12px 130px 10px 0;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: relative;
    display: inline-block;
    /* width: 178px; */
    padding: 10px 72px 12px 0;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    word-break: keep-all;
    &:not(:first-child) {
      border-top: 1px solid rgba(158, 179, 205, 0.52);
    }
    box-sizing: border-box;

    width: auto;
  }

  ${(props) =>
    !props.isFirst &&
    css`
      border-top: 1px solid rgba(158, 179, 205, 0.52);
    `}

  ${(props) => getVoteResultItemCss(props.type)}
  ${(props) =>
    props.isInvalid &&
    // .boxlist_ty .unit_invalid li span, .boxlist_ty .unit_invalid li span strong
    css`
      color: #9eb3cd;
      font-weight: normal;

      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        color: #9eb3cd;
        font-weight: normal;
      }

      & > strong {
        color: #9eb3cd;
        font-weight: normal;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          color: #9eb3cd;
          font-weight: normal;
        }
      }
    `}
`;

const StockAmountBlock = styled.strong`
  // .boxlist_ty .unit_result li span strong
  position: absolute;
  right: 0;
  width: 120px;
  text-align: right;
  color: #0075ff;
  ${(props) =>
    props.isInvalid &&
    // .boxlist_ty .unit_invalid li span, .boxlist_ty .unit_invalid li span strong
    css`
      color: #9eb3cd;
      font-weight: normal;

      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        color: #9eb3cd;
        font-weight: normal;
      }

      & > strong {
        color: #9eb3cd;
        font-weight: normal;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          color: #9eb3cd;
          font-weight: normal;
        }
      }
    `}
`;

const IndentAgenda = styled.span`
  // .boxlist_ty .unit_result li span
  position: relative;
  display: inline-block;
  width: 160px;
  padding: 15px 130px 13px 0;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  border-top: 1px solid rgba(158, 179, 205, 0.52);
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: relative;
    display: inline-block;
    width: 178px;
    padding: 10px 72px 12px 0;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    word-break: keep-all;
    border-top: 1px solid rgba(158, 179, 205, 0.52);
    box-sizing: border-box;
  }

  // .boxlist_ty .unit_result .indent
  display: block;
  box-sizing: border-box;
  font-size: 15px;
  padding: 2px 0 12px 0;
  margin: 0 auto;
  border-top: 0;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    display: block;
    box-sizing: border-box;
    font-size: 13px;
    padding: 2px 0 8px 20px;
    margin: 0 0 auto;
    border-top: 0;
    width: 196px;
  }
`;

export const renderVoteResultButton = (
  result,
  childCount,
  onResultPopup,
  noResultButton = false,
  isChild = false
) => {
  if (noResultButton) return null;
  switch (result) {
    case VOTE_RESULT_CODE.Approved:
      return (
        <VoteResultButton
          type={VOTE_RESULT_BUTTON_TYPE.Approved}
          onClick={onResultPopup}
        >
          가결
        </VoteResultButton>
      );
    case VOTE_RESULT_CODE.Rejected:
      return (
        <VoteResultButton
          type={VOTE_RESULT_BUTTON_TYPE.Rejected}
          onClick={onResultPopup}
        >
          부결
        </VoteResultButton>
      );

    case VOTE_RESULT_CODE.Invalid:
      return (
        <VoteResultButton
          type={VOTE_RESULT_BUTTON_TYPE.Invalid}
          onClick={onResultPopup}
          isChild={isChild}
        >
          무효
        </VoteResultButton>
      );

    default:
      return <VoteResultButton isAbsten={true}>기권</VoteResultButton>;
  }
};

const VoteResultItemExtend = (props) => {
  const { agenda, result, onResultPopup, childCount, totalValue, isFirst } =
    props;
  if (!totalValue) return null;
  const isInvalid = result === AgendaResultType.INVALID.code;
  return (
    <VoteResultItemBlock isInvalid={isInvalid}>
      <ItemTitleBlock isFirst={isFirst}>{agenda.agendaName}</ItemTitleBlock>
      {renderVoteResultButton(result, childCount, onResultPopup, false, true)}
      <IndentAgenda>
        찬성
        <StockAmountBlock isInvalid={isInvalid}>{`${formatNumber(
          totalValue.ayesCount
        )}주`}</StockAmountBlock>
      </IndentAgenda>
      <IndentAgenda>
        반대
        <StockAmountBlock isInvalid={isInvalid}>{`${formatNumber(
          totalValue.noesCount
        )}주`}</StockAmountBlock>
      </IndentAgenda>
      <IndentAgenda>
        기권
        <StockAmountBlock isInvalid={isInvalid}>{`${formatNumber(
          totalValue.abstentionCount
        )}주`}</StockAmountBlock>
      </IndentAgenda>
    </VoteResultItemBlock>
  );
};

export default VoteResultItemExtend;
