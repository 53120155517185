import React from 'react';
import Input from '../../../../../components/input/Input';
import { formatNumber } from '../../../../../utils/StringUtil';
import ResultTableSelect from '../../../register/main/components/ResultTableSelect';
import { getAgendaDisplayName, getDivStyle } from '../AgmResult';

const TotalYesNoTable = ({ data, onChangeReuslt, onChangeCount, isEdit }) => {
  console.log('TotalYesNoTable');
  console.log(data.electronicVoteValue);
  if (!data || !data.totalValue) return null;
  return (
    <div className="tblcommon-01" style={getDivStyle(data.agenda)}>
      <table>
        <colgroup>
          <col width="*" />
          <col width="*" />
          <col width="*" />
        </colgroup>
        <thead>
          <tr>
            <th colSpan="3" className="title atv">
              {getAgendaDisplayName(data.agenda)}
              <span className="stock">
                행사 가능 주식수
                <strong className="ft-col02 ">
                  {formatNumber(data.agenda.exercisableStockQuantity)}주
                </strong>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>찬성</th>
            <td className="nRight">
              {isEdit ? (
                <Input
                  type="number"
                  onChange={(e) =>
                    onChangeCount(
                      e.target.value,
                      data.agenda.agendaSeqno,
                      data.agendaSeqno,
                      'ayesCount'
                    )
                  }
                  value={data.totalValue.ayesCount}
                />
              ) : (
                formatNumber(data.totalValue.ayesCount)
              )}
            </td>
            <td rowSpan="3">
              <ResultTableSelect
                onChange={onChangeReuslt}
                parentAgendaSeqno={data.agenda.agendaSeqno}
                agendaSeqno={data.agendaSeqno}
                seletcedValue={data.result}
                disabled={!isEdit}
                isEdit={isEdit}
              />
            </td>
          </tr>
          <tr>
            <th>반대</th>
            <td className="nRight">
              {isEdit ? (
                <Input
                  type="number"
                  onChange={(e) =>
                    onChangeCount(
                      e.target.value,
                      data.agenda.agendaSeqno,
                      data.agenda.agendaSeqno,
                      'noesCount'
                    )
                  }
                  value={data.totalValue.noesCount}
                />
              ) : (
                formatNumber(data.totalValue.noesCount)
              )}
            </td>
          </tr>
          <tr>
            <th>기권</th>
            <td className="nRight">
              {isEdit ? (
                <Input
                  type="number"
                  onChange={(e) =>
                    onChangeCount(
                      e.target.value,
                      data.agenda.agendaSeqno,
                      data.agendaSeqno,
                      'abstentionCount'
                    )
                  }
                  value={data.totalValue.abstentionCount}
                />
              ) : (
                formatNumber(data.totalValue.abstentionCount)
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TotalYesNoTable;
