import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import styled, { css } from 'styled-components';
import Section from '../../../components/layout/Section';
import { MODAL_SIZE } from '../../../components/modal/Modal';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import { ModalContext } from '../../../context/ModalContext';
import {
  fetchGetTermsPrivacyPolicyHistoryAction,
  fetchGetTermsSeqnoAction
} from '../../../services/api/terms/promiseActions';
import { AgmTermsType } from '../../../utils/constant/AgmConstant';
import { STOP_EVENT_FUNCTION } from '../../../utils/FunctionUtil';

const PrivacyPage = () => {
  const match = useRouteMatch();
  const { prevNo } = match.params;
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const { openModal, setModalConfig } = useContext(ModalContext);

  useEffect(() => {
    if (!!prevNo) {
      dispatch(fetchGetTermsPrivacyPolicyHistoryAction(prevNo)).then((res) => {
        setData(res.data?.termsContent);
      });
    } else {
      dispatch(fetchGetTermsSeqnoAction(AgmTermsType.PRIVATE_POLICY.code)).then(
        (res) => {
          setData(res.data.content);
        }
      );
    }
  }, [prevNo]);

  useEffect(() => {
    if (!!data) {
      const previousArr = document.querySelectorAll('.privacy-policy-history');
      previousArr.forEach((item) =>
        item.addEventListener('click', () =>
          openTermsModal(item.getAttribute('path'))
        )
      );
    }
  }, [data]);

  const openTermsModal = (path) => {
    const prevNo = path.split('/').pop();
    dispatch(fetchGetTermsPrivacyPolicyHistoryAction(prevNo)).then((res) => {
      if (!res.error) {
        setModalConfig({
          size: MODAL_SIZE.MEDIUM
        });
        openModal(
          '개인정보 처리방침',
          <TermsBlock
            onScroll={(e) => {
              STOP_EVENT_FUNCTION(e);
            }}
            dangerouslySetInnerHTML={{
              __html: res.data.termsContent
            }}
          ></TermsBlock>
        );
      }
    });
  };

  return (
    <Page>
      <PageHeader title="개인정보처리방침" />
      <Section>
        <DD
          dangerouslySetInnerHTML={{
            __html: data
          }}
        ></DD>
      </Section>
    </Page>
  );
};

const DD = styled.dd`
  padding: 16px 30px;
  overflow-y: auto;
  background-color: #fbfcfd;
  border: 1px solid rgba(158, 179, 205, 0.25);
  border-radius: 5px;
  ${(props) =>
    props.active &&
    css`
      display: block;
    `}
  & > span {
    font-size: 13px;
    line-height: 1.65;
    letter-spacing: 1px;
  }
`;

const TermsBlock = styled.span`
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 1px;
  & p {
    margin: 1rem;
  }
`;

export default PrivacyPage;
