import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../components/button/Button';
import ButtonGroup from '../../../../components/button/ButtonGroup';
import FileButton from '../../../../components/button/FileButton';
import ListHeader from '../../../../components/layout/ListHeader';
import Section from '../../../../components/layout/Section';
import Page from '../../../../components/page/Page';
/*
import {
  fecthPutAgmAgendaAction,
  fetchDeleteAgmAgendaAction,
  fetchGetAgmAction,
  fetchGetAgmPreviousAction,
  fetchGetAgmStockholderAction,
  fetchGetAgmStockholdersAction,
  fetchGetAgmStockholdersFailExcelAction,
  fetchPostAgmAgendaAction,
  fetchPostAgmBaseInfoAction,
  fetchPostAgmStockholdersAction,
} from '../../../IssueIncorporated/state/promiseActions';
*/
import {
  fecthPutAgmAgendaAction,
  fetchDeleteAgmAgendaAction,
  fetchGetAgmAction,
  fetchGetAgmPreviousAction,
  fetchGetAgmStockholderAction,
  fetchGetAgmStockholdersAction,
  fetchGetAgmStockholdersFailExcelAction,
  fetchPostAgmAgendaAction,
  fetchPostAgmBaseInfoAction,
  fetchPostAgmStockholdersAction
} from '../../../../services/api/agm/promiseActions';

const postAgmBaseInfoData = {
  agmType: '1', // 1. 정기, 2. 임시
  agmRecordDate: '2021-08-05', //기준일
  agmSessionCount: 1, //회차
  agmDateTime: '2021-08-29T09:00:00',
  agmPlace: '한국전자투표 사무실',
  voteBeginDateTime: '2021-08-27T09:00:00',
  voteEndDateTime: '2021-08-28T17:00:00',
  electronicVoteUse: true, // 전자투표 여부
  electronicProxyUse: true, // 전자위임장 여부
  files: [
    {
      type: '1', // 1. 전자투표이사회 결의서, 2. 주주총회 공고문, 3. 사업보고서, 4. 감사보고서, 5. IR
      filePath: '1628223658716.631',
      fileName: '인물.PNG',
      fileSize: 1037341
    }
  ],
  personInCharge: {
    name: '이상호',
    position: '선임',
    department: '개발팀',
    telephone: '07077912455',
    cell: '01031585928',
    email: 'sanghl87@naver.com'
  },
  solicitorName: '한국전자투표', // 권유자
  voteProxies: ['동상호', '조민희'] // 대리인들
};

const postAgmAgendaData = {
  agendaNumber: '1',
  agendaName: '이사선임의건',
  agendaDetailContent: '이사를 선임하기 위한 의안입니다.',
  concentrateVote: false,
  agendaCategory: '040',
  filePath: null,
  fileName: null,
  fileSize: null,
  childAgendas: [
    {
      agendaNumber: '1-1',
      agendaName: '노수1 이사 선임의 건'
    },
    {
      agendaNumber: '1-2',
      agendaName: '노수2 이사 선임의 건'
    }
  ]
};

const putAgmAgendaData = {
  agendaSeqno: 1,
  agendaNumber: '1',
  agendaName: '이사선임의건',
  agendaDetailContent: '이사를 선임하기 위한 의안입니다.',
  concentrateVote: false,
  agendaCategory: '040',
  filePath: null,
  fileName: null,
  fileSize: null,
  childAgendas: [
    {
      agendaSeqno: 2,
      agendaNumber: '1-1',
      agendaName: '이상호 이사 선임의 건'
    },
    {
      agendaNumber: '1-2',
      agendaName: '조민희 이사 선임의 건2'
    }
  ]
};

const deleteAgmAgendaData = {
  agendaSeqno: '1,2', // 삭제를 원하는 의안번호 목록(부모만 보내면 자식은 알아서 지워줌)
  hoho: 123
};

const FetchTest = () => {
  const dispatch = useDispatch();
  const fetchPostAgmBaseInfo = () => {
    dispatch(fetchPostAgmBaseInfoAction(postAgmBaseInfoData)).then((res) => {
      console.log('fetchPostAgmBaseInfoAction');
      console.log(res);
    });
  };
  const fetchPostAgmAgenda = () => {
    dispatch(
      fetchPostAgmAgendaAction({ agmSeq: 6, data: postAgmAgendaData })
    ).then((res) => {
      console.log('fetchPostAgmAgendaAction');
      console.log(res);
    });
  };
  const fetchPutAgmAgenda = () => {
    dispatch(
      fecthPutAgmAgendaAction({ agmSeq: 6, data: putAgmAgendaData })
    ).then((res) => {
      console.log('fecthPutAgmAgendaAction');
      console.log(res);
    });
  };
  const fetchDeleteAgmAgenda = () => {
    dispatch(
      fetchDeleteAgmAgendaAction({
        agmSeq: 6,
        data: deleteAgmAgendaData
      })
    ).then((res) => {
      console.log('fetchDeleteAgmAgendaAction');
      console.log(res);
    });
  };

  const fetchGetAgm = () => {
    dispatch(fetchGetAgmAction()).then((res) => {
      console.log('fetchGetAgmAction');
      console.log(res);
    });
  };

  const fetchGetAgmPrevious = () => {
    dispatch(fetchGetAgmPreviousAction()).then((res) => {
      console.log('fetchGetAgmPreviousAction');
      console.log(res);
    });
  };

  const fetchPostAgmStockholders = (fileType, fileUploaded) => {
    const fd = new FormData();
    fd.append('file', fileUploaded);
    fd.append('agentOrganizationType', 4);
    fd.append('totalStockAmount', 618);
    fd.append('agmSeqno', 1);
    fd.append('securitiesCorpSeqno', 1);

    console.log(fileUploaded);
    dispatch(fetchPostAgmStockholdersAction({ agmSeq: 6, data: fd })).then(
      (res) => {
        console.log('fetchPostAgmStockholdersAction');
        console.log(res);
      }
    );
  };

  const fetchGetAgmStockholders = () => {
    dispatch(fetchGetAgmStockholdersAction({ agmSeq: 6 })).then((res) => {
      console.log('fetchPostAgmStockholdersAction');
      console.log(res);
    });
  };

  const fetchGetAgmStockholder = () => {
    dispatch(
      fetchGetAgmStockholderAction({
        agmSeq: 6,
        identificationNumber: '1111111111115'
      })
    ).then((res) => {
      console.log('fetchPostAgmStockholdersAction');
      console.log(res);
    });
  };

  const fetchGetAgmStockholdersFailExcel = () => {
    dispatch(
      fetchGetAgmStockholdersFailExcelAction({
        agmSeq: 6,
        filePath: '1629334597690.701'
      })
    ).then((res) => {
      console.log('fetchPostAgmStockholdersAction');
      console.log(res);
      var fileName = res.meta.headers['content-disposition']
        .split("''")[1]
        .split(';')[0];
      fileDownload(res.data, decodeURI(fileName));
    });
  };

  const fileDownload = (data, fileName) => {
    console.log(data);
    console.log(fileName);
    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', decodeURI(fileName));
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <Page>
      <Section>
        <ListHeader title="주주총회 등록" />
        <ButtonGroup>
          <Button onClick={fetchPostAgmBaseInfo}>기본정보 입력</Button>
          <Button onClick={fetchPostAgmAgenda}>안건 등록</Button>
          <Button onClick={fetchPutAgmAgenda}>안건 수정</Button>
          <Button onClick={fetchDeleteAgmAgenda}>안건 삭제</Button>
        </ButtonGroup>
        <ButtonGroup>
          <FileButton fileType="1" handleFile={fetchPostAgmStockholders}>
            명부 업로드
          </FileButton>
          <Button onClick={fetchGetAgmStockholders}>명부 전체 조회</Button>
          <Button onClick={fetchGetAgmStockholder}>주주 조회</Button>
          <Button onClick={fetchGetAgmStockholdersFailExcel}>
            실패 명부 다운로드 (작동 안됨, 액셀 다운로드 알아보기)
          </Button>
        </ButtonGroup>
        <ListHeader title="주주총회 조회" />
        <ButtonGroup>
          <Button onClick={fetchGetAgm}>주주 - 내 주주총회 조화</Button>
          <Button onClick={fetchGetAgmPrevious}>
            발행회사 이전 주주총회 조회
          </Button>
        </ButtonGroup>
      </Section>
    </Page>
  );
};

export default FetchTest;
