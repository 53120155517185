import { Route, Switch } from 'react-router';
import { LOGO_PLACE } from '../../../components/page/Gnb';
import Page from '../../../components/page/Page';
import Corporation from './Corporation';
import Individual from './Individual';

const SignupPage = () => {
  return (
    <Page logoPlace={LOGO_PLACE.NONE}>
      <Switch>
        {/* 서비스 가입 - 개인사용자 */}
        <Route path="/signup/individual" component={Individual} />
        {/* 서비스 가입 - 법인사용자 */}
        <Route path="/signup/corporate" component={Corporation} />
        {/* 서비스 가입 - 발행회사 */}
        <Route path="/signup/issue-incorporated" />
      </Switch>
    </Page>
  );
};

export default SignupPage;
