import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import Page from '../../../../../components/page/Page';
import PageHeader from '../../../../../components/page/PageHeader';
import { fetchGetENoticeAction } from '../../../../../services/api/agm/promiseActions';
import { ListWrapBlock } from '../../../dashboard/CorpDashboardPage';
import ElecBillTemplate from './send/contents/sendForm/template/ElecBillTemplate';
import ErrorPage from './send/contents/sendForm/template/ErrorPage';
import { ListType11Block } from './send/layout/LayoutWrapper';

const DocPage = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const search = location.search;
  const [data, setData] = useState();
  const [errFlag, setErrFlag] = useState(false);
  useEffect(() => {
    const { tx_id, token } = parseQueryString(search);
    const data = {
      tx_id: tx_id,
      token: token
    };
    dispatch(fetchGetENoticeAction(data)).then((res) => {
      if (!res.error) {
        setData(res.data);
      } else {
        setErrFlag(true);
      }
    });
  }, []);

  const parseQueryString = (url) => {
    let parseURL = url.substring(1, url.length);
    const arr = parseURL.split('&');
    const result = {};
    arr.forEach((item, index) => {
      if (index === 0) {
        result.tx_id = item.split('=')[1];
      } else if (index === 1) {
        result.token = item.split('=')[1];
      }
    });
    return result;
  };

  return (
    <Page>
      <PageHeader title="주주총회 소집 전자고지서" noHamburger />
      <ListWrapWrapper>
        <ListType11Block>
          <ul>
            {!!data && (
              <li>
                <ElecBillTemplate data={data} />
              </li>
            )}
            {errFlag && !data && (
              <li>
                <ErrorPage />
              </li>
            )}
          </ul>
        </ListType11Block>
      </ListWrapWrapper>
    </Page>
  );
};

const ListWrapWrapper = styled(ListWrapBlock)`
  max-width: 980px;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding: 16px 0 0;
  }
`;

export default DocPage;
