import PropsType from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Icons } from '../../styles/Icons';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';
import logGuidePng from '../../styles/images/common/sprite/log_guide.png';
const CheckBoxBlock = styled.div`
  flex-basis: auto;
  flex-shrink: 0;
  //
  margin-left: 6%;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    display: inline-block;
    width: auto;
    padding: 4px 12px 4px;
    border: 2px solid #d8e1eb;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
    text-align: left;

    margin-left: 0;

    ${(props) =>
      props.checked &&
      css`
        border: 2px solid #24375c;
      `}
  }
  ${(props) =>
    props.noMargin &&
    css`
      margin-left: 0%;
    `}
  &:first-of-type {
    //margin-left: 0%;
  }
  // checkbox_ty03 {
  position: relative;
  display: inline-block;
`;

const CheckBoxInputBlock = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
`;

const CheckBoxLabelBlock = styled.label`
  position: relative;
  display: inline-block;
  padding: 0 0 0 40px;
  min-height: 20px;
  line-height: 1.67;
  letter-spacing: -0.5px;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: relative;
    display: inline-block;
    padding: 2px 0 2px 24px;
    letter-spacing: -0.5px;
    color: #080808;

    font-size: 13px;
    min-height: auto;
    line-height: inherit;
    ${(props) =>
      props.checked
        ? css`
            font-weight: bold;
          `
        : css`
            font-weight: normal;
          `}
  }
  ::before {
    ${(props) =>
      props.checked ? Icons.check_outline_on.css : Icons.check_outline_off.css}
  }
`;

const CheckBox = (props) => {
  const {
    register,
    name,
    title,
    checked,
    disabled,
    onChange,
    value,
    onlyUseProps,
    noMargin,
    desc
  } = props;
  const [localChecked, setLocalChecked] = useState(checked);
  useEffect(() => {
    setLocalChecked(checked);
  }, [checked]);

  const changeHandler = (e) => {
    if (!onlyUseProps) {
      setLocalChecked(!localChecked);
    }
    if (typeof onChange === 'function') {
      onChange(e.target.checked, value);
    }
  };

  const reg = register ? register(name) : {};
  return (
    <>
      <CheckBoxBlock noMargin={noMargin} checked={localChecked}>
        <CheckBoxLabelBlock checked={localChecked}>
          {title}
          <CheckBoxInputBlock
            {...reg}
            type="checkbox"
            checked={localChecked}
            disabled={disabled}
            onChange={changeHandler}
            value={value}
          />

          {desc && (
            <DescriptionBlock>
              <DescBallonBlock>{desc}</DescBallonBlock>
            </DescriptionBlock>
          )}
        </CheckBoxLabelBlock>
      </CheckBoxBlock>
    </>
  );
};

CheckBox.propTypes = {
  title: PropsType.string,
  checked: PropsType.bool,
  disabled: PropsType.bool,
  onChange: PropsType.func,
  value: PropsType.oneOfType([PropsType.string, PropsType.number]),
  register: PropsType.func,
  onlyUseProps: PropsType.bool,
  desc: PropsType.string
};

CheckBox.defaultProps = {
  title: '',
  checked: false,
  disabled: false,
  onChange: null,
  onlyUseProps: false,
  desc: false
};

const DescriptionBlock = styled.button`
  content: '';
  position: absolute;
  top: 50%;
  left: 7.5em;
  transform: translateY(-50%);
  width: 21px;
  height: 23px;
  background: url(${logGuidePng}) no-repeat;
  background-size: 21px 23px;
  & > div {
    display: none;
  }
  &:hover {
    & > div {
      display: block;
    }
  }
`;

const DescBallonBlock = styled.div`
  position: absolute;
  width: 200px;
  height: auto;
  background: pink;
  border-radius: 10px;
  padding: 10px;
  right: -6.5em;
  top: 2.5em;
  cursor: default;
  &::after {
    border-top: 0px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid pink;
    content: '';
    position: absolute;
    top: -10px;
    left: 100px;
  }
`;

export default CheckBox;
