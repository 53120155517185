import { Redirect, Route, Switch } from 'react-router';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import BoardRegisterPage from '../notice/BoardRegisterPage';
import FaqPage from './FaqPage';

const FaqPageRoute = () => {
  return (
    <Page>
      <PageHeader title="자주 하는 질문" />
      <Switch>
        <Route path="/faq" component={FaqPage} exact />
        <Route path="/:board/register" component={BoardRegisterPage} exact />
        <Redirect to="/" />
      </Switch>
    </Page>
  );
};

export default FaqPageRoute;
