import axios from 'axios';
import { API_HOST } from './constant';

const agmAxios = axios.create();
export let accessToken = '';
/*
  Axios Default Settings
*/
agmAxios.defaults.withCredentials = true;
agmAxios.defaults.headers.common['X_AGM_SECCO_SEQNO'] =
  process.env.REACT_APP_X_AGM_SECCO_SEQNO;

export const setXAgmSeccoSeqno = (seqno) => {
  agmAxios.defaults.headers.common['X_AGM_SECCO_SEQNO'] = seqno;
};

export const setAccessToken = (accessToken) => {
  if (accessToken) {
    agmAxios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  } else {
    agmAxios.defaults.headers.common['Authorization'] = null;
  }
};

export const setRefreshToken = (refreshToken) => {
  sessionStorage.setItem('refreshToken', refreshToken);
};

export const clearRefreshToken = () => {
  sessionStorage.removeItem('refreshToken');
};

export const request = (config) => {
  return requestCommon(config);
};

export const requestMultipart = (config) => {
  return requestCommon({
    ...config,
    headers: { ...config.headers, 'Content-Type': 'application/json' }
  });
};

export const requestFile = (config) => {
  return requestCommon({
    ...config,
    responseType: 'arraybuffer'
  });
};

const requestCommon = (config) => {
  // if (!agmAxios.defaults.headers.common['Authorization']) {
  //   const token = sessionStorage.getItem('accessToken');
  //   setAccessToken(token);
  // }
  const conf = {
    ...config,
    url: API_HOST + config.url,
    headers: {
      'Content-Type': 'application/json',
      ...config.headers
    }
  };
  return agmAxios({
    ...conf,
    withCredentials: true
  })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log(' ///// ERROR ////');
      console.log(error);
      console.log(error.response);
      return error.response;
    });
};
