import React from 'react';
import styled from 'styled-components';

const VoteButtonContainerBlock = styled.li``;

const VoteButtonContainer = ({ children }) => {
  return <VoteButtonContainerBlock>{children}</VoteButtonContainerBlock>;
};

export default VoteButtonContainer;
