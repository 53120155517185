import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import styled, { css, keyframes } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import useWindowSize from '../../../../../hooks/useWindowSize';
import com01Png from '../../../../../styles/images/common/sprite/com_01.png';
import com02Png from '../../../../../styles/images/common/sprite/com_02.png';
import com03Png from '../../../../../styles/images/common/sprite/com_03.png';
import spCommonPng from '../../../../../styles/images/common/sprite/sp_common.png';
import spCommonMobilePng from '../../../../../styles/images/common/sprite/sp_common_mobile.png';
import {
  AgmStateCd,
  ParticipateStatus
} from '../../../../../utils/constant/AgmConstant';
import {
  DateFormatName,
  getDateFormat
} from '../../../../../utils/DateFormatUtil';
import { DATE_FORMAT, now } from '../../../../../utils/DateUtil';
import { formatNumber } from '../../../../../utils/StringUtil';
import { ExerciseMethod } from '../../../exerciseRecord/main/BoxInfo';

const slideUp = keyframes`
  from {
    opacity: 0;
    /* transform: translate(-50%, 50%); */
  }
  to {
    opacity: 1;
    /* transform: translate(-50%, -50%); */
  }
`;

const slideDown = keyframes`
  from {
    opacity: 1;
    /* transform: translate(-50%, -50%); */
  }
  to {
    opacity: 0;
    /* transform: translate(-50%, 50%); */
  }
`;

const ListItemWrapper = styled.li`
  & > a {
    // animation
    animation-duration: 0.25s;
    animation-timing-function: ease-out;
    animation-name: ${slideUp};
    animation-fill-mode: forwards;

    ${(props) =>
      props.hide &&
      css`
        animation-name: ${slideDown};
      `}
  }
`;

const TitleBlock = styled.div`
  // .terms ty01
  margin-top: 16px;
  padding: 17px 24px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.active ? '#84a7d0' : '#2e487c'}!important;
  position: relative;
  // temp
  cursor: pointer;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding: 13px 16px 12px;
    border-radius: 4px;
    position: relative;
  }
  & > p {
    color: #fff;
    display: inline;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      font-size: 14px;
    }
    & > strong {
      margin: 0 4px 0 30px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        margin: 0 3px 0 20px;
      }
    }
  }
  & > button {
    position: absolute;
    top: 22px;
    right: 22px;
    width: 14px;
    height: 8px;
    background: url(${spCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -341px -1px;
    opacity: 1;
  }
`;

const ItemBlock = styled.a`
  display: block;
  padding: 22px 20px 14px 24px;
  margin: 12px 0;
  background-color: #fff;
  border: solid 1px #e9ecf4;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 12%);
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: relative;
    display: block;
    padding: 14px 16px 10px 16px;
    margin: 8px 0;
    background-color: #fff;
    border: solid 1px #e9ecf4;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 12%);
  }
  & > ul {
    // .unit
    position: relative;
    display: block;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      position: static;
    }
    & > li {
      width: 100%;
      font-size: 15px;
      letter-spacing: -0.5px;
      color: #000826;
      text-align: left;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        width: 100%;
        font-size: 15px;
        letter-spacing: -0.5px;
        color: #080808;
        text-align: left;
      }
      & > p {
        display: inline-block;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          font-size: 13px;
        }
      }
      & > span {
        display: inline-block;
        width: 110px;
        padding: 7px 0;
        font-size: 14px;
        color: #575f76;
        margin-right: 20px;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          display: inline-block;
          width: 92px;
          padding: 1px 0;
          font-size: 12px;
          color: #575f76;
        }
      }
    }
    & > li:first-of-type {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 4px;
      }
    }
    & > button {
      position: absolute;
      display: block;
      margin: 0;
      font-weight: bold;
      text-align: center;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
`;

const VotingButton = styled.button`
  // .rbottom1 .voting
  bottom: 0;
  right: 208px;
  width: 200px;
  font-size: 18px;
  height: 48px;
  padding: 14px 0;
  background-color: #0b8fee;
  color: #fff;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin-top: 6px;
    display: inline-block;
    width: 49%;
    height: 36px;
    padding: 10px 0;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border-radius: 4px;
    box-sizing: border-box;
    &:only-child {
      width: 100% !important;
    }
  }
  ${(props) =>
    props.wide &&
    css`
      right: 0px;
      width: 408px;
    `}
`;

const DelegationButton = styled.button`
  // .rbottom2 .delegation
  bottom: 0;
  right: 0;
  width: 200px;
  font-size: 18px;
  height: 48px;
  padding: 14px 0;
  background-color: #f08548;
  color: #fff;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin-top: 6px;
    display: inline-block;
    width: 49%;
    height: 36px;
    padding: 10px 0;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border-radius: 4px;
    box-sizing: border-box;
    &:only-child {
      width: 100% !important;
    }
  }
  ${(props) =>
    props.wide &&
    css`
      right: 0px;
      width: 408px;
    `}
`;

const ResultButton = styled.button`
  // .rbottom1
  bottom: 0;
  right: 208px;
  width: 200px;
  font-size: 18px;
  // .result
  height: 48px;
  padding: 12px 0 12px;
  border: 2px solid #0b8fee;
  background-color: #fff;
  color: #2e2e34;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin-top: 6px;
    display: inline-block;
    width: 49%;
    height: 36px;
    padding: 10px 0;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 8px 0 !important;
    &:only-child {
      width: 100% !important;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 15px;
    right: 32px;
    display: block;
    width: 8px;
    height: 14px;
    background: url(${spCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -312px -15px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      content: '';
      position: absolute;
      top: 11px;
      right: 18px;
      display: block;
      width: 5px;
      height: 10px;
      background: url(${spCommonMobilePng}) no-repeat;
      background-size: 230px auto;
      background-position: -179px -12px;
    }
  }
`;

const BreakdownButton = styled.button`
  // .rbottom2
  bottom: 0;
  right: 0;
  width: 200px;
  font-size: 18px;
  // .breakdown
  height: 48px;
  padding: 12px 0 12px;
  border: 2px solid #9eb3cd;
  background-color: #fff;
  color: #2e2e34;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin-top: 6px;
    display: inline-block;
    width: 49%;
    height: 36px;
    padding: 10px 0;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 8px 0 !important;
    &:only-child {
      width: 100% !important;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 15px;
    right: 32px;
    display: block;
    width: 8px;
    height: 14px;
    background: url(${spCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -312px -15px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      content: '';
      position: absolute;
      top: 11px;
      right: 18px;
      display: block;
      width: 5px;
      height: 10px;
      background: url(${spCommonMobilePng}) no-repeat;
      background-size: 230px auto;
      background-position: -179px -12px;
    }
  }
`;

const NoDataBlock = styled.li`
  justify-content: center;
  /* width: 100%; */
  padding: 60px 0 60px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #caccd7;

  padding: 14px 16px 10px 16px;
  margin: 8px 0;
  background-color: #fff;
  border: solid 1px #e9ecf4;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 12%);
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: relative;
    display: block;
    padding: 14px 16px 10px 16px;
    margin: 8px 0;
    background-color: #fff;
    border: solid 1px #e9ecf4;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 12%);
  }
  & > ul {
    position: relative;
    display: block;
    margin: 10px 0 6px;
    & > li {
      width: 100%;
      letter-spacing: -0.5px;
      text-align: center;
      padding: 0 0 2px;
      & > p {
        font-size: 15px;
        font-weight: bold;
        margin: 10px 0 2px;
      }
      & > span {
        display: inline-block;
        font-size: 12px;
        color: #575f76;
      }
    }
  }
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 22px;
  right: 22px;
  width: 14px;
  height: 8px;
  background: url(${spCommonPng}) no-repeat;
  background-size: 400px auto;
  background-position: -341px -1px;
  opacity: 1;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: absolute;
    top: 18px;
    right: 16px;
    width: 14px;
    height: 8px;
    background: url(${spCommonMobilePng}) no-repeat;
    background-size: 230px auto;
    background-position: -197px -1px;
    opacity: 1;
  }
`;

const sortAgms = (agms) => {
  const ingAgms = [];
  const endAgms = [];
  const willAgms = [];

  agms.forEach((agm) => {
    if (new Date(agm.voteEndDateTime) < now()) {
      endAgms.push(agm);
    } else if (new Date(agm.voteBeginDateTime) < now()) {
      if (agm.participateStatus === ParticipateStatus.Participation) {
        endAgms.push(agm);
      } else {
        ingAgms.push(agm);
      }
    } else {
      willAgms.push(agm);
    }
  });

  return { ingAgms, endAgms, willAgms };
};

const MyAgmTable = ({ agms }) => {
  const history = useHistory();
  const { ingAgms, endAgms, willAgms } = useMemo(() => sortAgms(agms), [agms]);
  const [showState, setShowState] = useState({
    ingDiv: ingAgms.length > 0,
    endDiv: ingAgms.length === 0 && endAgms.length > 0,
    willDiv: ingAgms.length === 0 && endAgms.length === 0 && willAgms.length > 0
  });
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => {
    return windowSize.width <= SCREEN_SIZE_MOBILE;
  }, [windowSize]);

  const renderBasicInfo = (agm) => {
    return (
      <>
        <li>
          <p>{`${agm.agmName}`}</p>
        </li>
        <li>
          <span>주주총회일</span>
          <p>
            {DATE_FORMAT(
              agm.agmDateTime,
              getDateFormat(DateFormatName.SHORT_DATE_2)
            )}
          </p>
        </li>
        <li>
          <span>행사기간</span>
          <p>{`${DATE_FORMAT(
            agm.voteBeginDateTime,
            getDateFormat(DateFormatName.LONG_DATE_TIME)
          )} ~ ${DATE_FORMAT(
            agm.voteEndDateTime,
            getDateFormat(DateFormatName.LONG_DATE_TIME)
          )} `}</p>
        </li>
        <li>
          <span>행사가능 주식수</span>
          <p>{`${formatNumber(agm.exercisableStockAmount)}`} 주</p>
        </li>
      </>
    );
  };

  const onClickHandler = (typeCd) => {
    const newObj = { ...showState };
    switch (Number(typeCd)) {
      case DIV_CONST.ING:
        newObj.ingDiv = !showState.ingDiv;
        break;
      case DIV_CONST.END:
        newObj.endDiv = !showState.endDiv;
        break;
      case DIV_CONST.WILL:
        newObj.willDiv = !showState.willDiv;
        break;
      default:
        break;
    }
    setShowState(newObj);
  };

  const onNavigate = (url) => {
    history.push(url);
  };

  const renderButtonBlock = (agm, target) => {
    switch (target) {
      case DIV_CONST.ING:
        return isMobile ? (
          <ButtonLiBlock>{ingAgmsBtn(agm)}</ButtonLiBlock>
        ) : (
          <>{ingAgmsBtn(agm)}</>
        );
      case DIV_CONST.END:
        return isMobile ? (
          <ButtonLiBlock>{endAgmsBtn(agm)}</ButtonLiBlock>
        ) : (
          <>{endAgmsBtn(agm)}</>
        );
      case DIV_CONST.WILL:
        return isMobile ? (
          <ButtonLiBlock>{willAgmsBtn(agm)}</ButtonLiBlock>
        ) : (
          <>{willAgmsBtn(agm)}</>
        );
      default:
        break;
    }
  };

  const ingAgmsBtn = (agm) => {
    return (
      <>
        {agm.electronicVoteUse && (
          <VotingButton
            onClick={() => onNavigate(`/shareholder/agm/${agm.agmSeqno}/vote`)}
          >
            투표하기
          </VotingButton>
        )}
        {agm.electronicProxyUse && (
          <DelegationButton
            onClick={() => onNavigate(`/shareholder/agm/${agm.agmSeqno}/proxy`)}
          >
            위임장수여
          </DelegationButton>
        )}
      </>
    );
  };

  const endAgmsBtn = (agm) => {
    return new Date(agm.voteEndDateTime) > now() ? (
      <>
        {agm.exerciseMethods.includes(ExerciseMethod.VOTE) && (
          <VotingButton
            onClick={() => onNavigate(`/shareholder/agm/${agm.agmSeqno}/vote`)}
          >
            투표내역/수정
          </VotingButton>
        )}
        {agm.exerciseMethods.includes(ExerciseMethod.PROXY) && (
          <DelegationButton
            onClick={() => onNavigate(`/shareholder/agm/${agm.agmSeqno}/proxy`)}
          >
            위임장내역/취소
          </DelegationButton>
        )}
      </>
    ) : (
      <>
        {agm.agmStateCode === AgmStateCd.END && (
          <ResultButton
            onClick={() =>
              onNavigate(`/shareholder/agm/${agm.agmSeqno}/vote-result`)
            }
          >
            주주총회결과
          </ResultButton>
        )}
        {agm.exerciseMethods.length > 0 && (
          <BreakdownButton
            onClick={() =>
              onNavigate(`/shareholder/agm/${agm.agmSeqno}/exercise-record`)
            }
          >
            행사내역
          </BreakdownButton>
        )}
      </>
    );
  };

  const willAgmsBtn = (agm) => {
    return (
      !!agm.excerciseMethod && (
        <BreakdownButton
          onClick={() =>
            onNavigate(`/shareholder/agm/${agm.agmSeqno}/exercise-record`)
          }
        >
          행사내역
        </BreakdownButton>
      )
    );
  };

  return (
    <>
      <ListItemWrapper>
        <TitleBlock
          active={!showState.ingDiv}
          onClick={() => onClickHandler(DIV_CONST.ING)}
        >
          <p>
            투표/위임 가능회사
            <strong>{ingAgms.length}</strong>개
            <ArrowButton value={DIV_CONST.ING}></ArrowButton>
          </p>
        </TitleBlock>
        {/* 반복문 */}
        {ingAgms.length !== 0 &&
          showState.ingDiv &&
          ingAgms.map((agm, idx) => {
            return (
              <ItemBlock key={`TermsItemBlock-ing-${idx}`}>
                <ul>
                  {renderBasicInfo(agm)}
                  {/* <IssuanceButton complete>주주증명발급</IssuanceButton> */}
                  {renderButtonBlock(agm, DIV_CONST.ING)}
                </ul>
              </ItemBlock>
            );
          })}
        {ingAgms.length === 0 && showState.ingDiv && (
          <NoDataBlock>
            <ul>
              <li>
                <img src={com01Png} alt="회사그림" width="105" height="70" />
              </li>
              <li>
                <p>행사 가능한 회사가 없습니다.</p>
              </li>
              <li>
                <span>
                  본인이 소유하고 있는 주식의 발행회사 공지를 참고해주세요.
                </span>
              </li>
            </ul>
          </NoDataBlock>
        )}
      </ListItemWrapper>
      <ListItemWrapper>
        <TitleBlock
          active={!showState.endDiv}
          onClick={() => onClickHandler(DIV_CONST.END)}
        >
          <p>
            투표/위임 완료회사
            <strong>{endAgms.length}</strong>개
            <ArrowButton value={DIV_CONST.END}></ArrowButton>
          </p>
        </TitleBlock>
        {endAgms.length !== 0 &&
          showState.endDiv &&
          endAgms.map((agm, idx) => {
            return (
              <ItemBlock key={`TermsItemBlock-end-${idx}`}>
                <ul>
                  {renderBasicInfo(agm)}
                  {/* <IssuanceButton complete>주주증명발급완료</IssuanceButton> */}
                  {renderButtonBlock(agm, DIV_CONST.END)}
                </ul>
              </ItemBlock>
            );
          })}
        {endAgms.length === 0 && showState.endDiv && (
          <NoDataBlock>
            <ul>
              <li>
                <img src={com02Png} alt="회사그림" width="105" height="70" />
              </li>
              <li>
                <p>최근 3개월 내 행사 완료한 회사가 없습니다.</p>
              </li>
              <li>
                <span>
                  개인정보보호를 위하여 최근 3개월까지 진행된
                  <br />
                  주주총회 내역만 확인 가능합니다.
                </span>
              </li>
            </ul>
          </NoDataBlock>
        )}
      </ListItemWrapper>
      <ListItemWrapper>
        <TitleBlock
          active={!showState.willDiv}
          onClick={() => onClickHandler(DIV_CONST.WILL)}
        >
          <p>
            투표/위임 예정회사
            <strong>{willAgms.length}</strong>개
            <ArrowButton value={DIV_CONST.WILL}></ArrowButton>
          </p>
        </TitleBlock>
        {willAgms.length !== 0 &&
          showState.willDiv &&
          willAgms.map((agm, idx) => {
            return (
              <ItemBlock key={`TermsItemBlock-will-${idx}`}>
                <ul>
                  {renderBasicInfo(agm)}
                  {/* <IssuanceButton complete>주주증명발급완료</IssuanceButton> */}
                  {/* <ResultButton>주주총회결과</ResultButton> */}
                  {renderButtonBlock(agm, DIV_CONST.WILL)}
                </ul>
              </ItemBlock>
            );
          })}
        {willAgms.length === 0 && showState.willDiv && (
          <NoDataBlock>
            <ul>
              <li>
                <img src={com03Png} alt="회사그림" width="105" height="70" />
              </li>
              <li>
                <p>준비중인 회사가 없습니다.</p>
              </li>
              <li>
                <span>
                  본인이 소유하고 있는 주식의 발행회사 공지를 참고해주세요.
                </span>
              </li>
            </ul>
          </NoDataBlock>
        )}
      </ListItemWrapper>
    </>
  );
};

export default MyAgmTable;

const DIV_CONST = {
  ING: 1,
  END: 2,
  WILL: 3
};

const ButtonLiBlock = styled.li`
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    width: 100%;
    font-size: 15px;
    letter-spacing: -0.5px;
    color: #080808;
    text-align: left;

    display: flex;
    gap: 3px;
  }
`;
