import { ButtonTypes } from '../../../../../../../../components/button/Button';
import { useForm } from 'react-hook-form';
import { ModalContext } from '../../../../../../../../context/ModalContext';
import { useContext, useEffect, useState } from 'react';
import ElectronicBillPopupForm from './ElectronicBillPopupForm';
import { MODAL_SIZE } from '../../../../../../../../components/modal/Modal';
import {
  ButtonWrapper,
  CenterBlock,
  EditButtonBlock,
  ElecBillAppBlock,
  LinkBlock,
  MarginRightBlock,
  TitleBlock
} from '../../layout/LayoutWrapper';
import styled from 'styled-components';
import { formatNumber } from '../../../../../../../../utils/StringUtil';
import { DATE_FORMAT } from '../../../../../../../../utils/DateUtil';
import {
  DateFormatName,
  getDateFormat
} from '../../../../../../../../utils/DateFormatUtil';

const ElectronicBillForm = (props) => {
  const { setValue, info } = props;
  const { agendas, agm, template } = info;
  const { openModal, setModalConfig, closeModal } = useContext(ModalContext);
  const { register, unregister, handleSubmit } = useForm();
  const [appendText, setAppendText] = useState({
    title: '',
    content: ''
  });
  const [elecBillStateFlag, setElecBillStateFlag] = useState(false);

  useEffect(() => {
    !!appendText.title && !!appendText.content && setElecBillStateFlag(true);
    !appendText.title && !appendText.content && setElecBillStateFlag(false);
  }, [appendText]);

  const onClickElecBillBtn = () => {
    setModalConfig({
      size: MODAL_SIZE.LARGE,
      isNoButton: true
    });
    openModal(
      `전자고지서 내용 ${elecBillStateFlag ? '수정' : '추가'}`,
      <>
        {!!info && (
          <ElectronicBillPopupForm
            register={register}
            closeModal={closeModal}
            handleSubmit={handleSubmit}
            onSubmitRegister={onSubmitRegister}
            info={info}
          />
        )}
      </>
    );
  };

  const onSubmitRegister = ({ title, content, initFlag }) => {
    setAppendText({
      title: title,
      content: content
    });
    if (initFlag) {
      unregister('elecBillTitle');
      unregister('elecBillContent');
    }
    if (typeof setValue === 'function') {
      setValue('documentAdditionalTitle', title);
      setValue('documentAdditionalContent', content);
    }
  };

  const renderAgendas = () => {
    return (
      <>
        {agendas.map((agenda, index) => {
          return agenda.childs.length === 0 ? (
            <ol>
              <li>
                <span>제 {agenda.agendaNumber}안. </span>
              </li>
              <li>
                <p>{agenda.agendaDetailContent}</p>
              </li>
            </ol>
          ) : (
            <ol>
              <li>
                <span>제 {agenda.agendaNumber}안. </span>
              </li>
              <li>
                <p>{agenda.agendaName}</p>
                {agenda.childs.map((child) => {
                  return (
                    <p>
                      <span>{child.agendaNumber}안.</span>
                      {child.agendaName}
                    </p>
                  );
                })}
              </li>
            </ol>
          );
        })}
      </>
    );
  };

  return (
    <ul>
      <li>
        <ElecBillAppBlock>
          <li>
            <p style={{ background: 'none' }}>주주총회 소집 전자고지서</p>
          </li>
          <li>
            <p>
              <strong>{`${template.sampleMap.shareholderName} 님`}</strong>,
              <br />
              <span>
                이 주주총회 소집 통지서는 {agm.issueIncorporatedName}의 요청에
                따라 전자등록기관인 ‘블록체인 전자주총’이 발송하는 주주총회 정보
                고지서 입니다.
              </span>
            </p>
            <CenterBlock style={{ marginTop: '3%' }}>
              <strong>{agm.issueIncorporatedName}</strong>
            </CenterBlock>
            <CenterBlock>
              <strong>{agm.agmShortName}</strong>
            </CenterBlock>
            <p>
              <MarginRightBlock style={{ color: '#9EB3CD' }}>
                주주총회 일시
              </MarginRightBlock>
              {DATE_FORMAT(
                agm.agmDateTime,
                getDateFormat(DateFormatName.FULL_DATE_TIME)
              )}
            </p>
            <p>
              <MarginRightBlock style={{ color: '#9EB3CD' }}>
                주주총회 장소
              </MarginRightBlock>
              {agm.agmPlace}
            </p>
          </li>
          <li>
            <TitleBlock>
              <strong>주주총회 소집통지서</strong>
            </TitleBlock>
            <button>주주총회 소집통지서.pdf</button>
            <button>기타 소집 자료서.pdf</button>
          </li>
          <hr />
          <li>
            <TitleBlock>
              <strong>소유주식 수</strong>
            </TitleBlock>
            <p>
              <span>주식수</span>
              {formatNumber(template.sampleMap.stockAmount)} 주
            </p>
            <p>
              <span>보통주</span>
              {formatNumber(template.sampleMap.commonStockAmount)} 주
            </p>
            <p>
              <span>우선주</span>
              {formatNumber(template.sampleMap.preferredStockAmount)} 주
            </p>
          </li>
          <hr />
          <li id="elecBillTextBlock">
            <TitleBlock>
              <strong>안건명</strong>
            </TitleBlock>
            {renderAgendas()}
          </li>
          <hr />

          {!elecBillStateFlag && (
            <ButtonWrapper
              type={ButtonTypes.Primary}
              onClick={onClickElecBillBtn}
            >
              내용 추가
            </ButtonWrapper>
          )}
          {elecBillStateFlag && (
            <AppendTextBlock>
              <TitleBlock>
                <strong>{appendText.title}</strong>
              </TitleBlock>
              <PreBlock>{appendText.content}</PreBlock>
            </AppendTextBlock>
          )}
          {elecBillStateFlag && (
            <EditButtonBlock
              type={ButtonTypes.Primary}
              onClick={onClickElecBillBtn}
            >
              내용 수정하기
            </EditButtonBlock>
          )}

          <LinkBlock>블록체인 전자주총 바로가기</LinkBlock>
        </ElecBillAppBlock>
      </li>
    </ul>
  );
};

const PreBlock = styled.pre`
  font-size: 13px;
  word-break: keep-all;
  white-space: normal;
  line-height: 1.5;
`;

const AppendTextBlock = styled.li`
  background: linear-gradient(
    90deg,
    white 2%,
    lightgrey 0,
    lightgrey 98%,
    white 2%
  );
`;

export default ElectronicBillForm;
