import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonGroup from '../../../../../../components/button/ButtonGroup';
import Input from '../../../../../../components/input/Input';
import Select from '../../../../../../components/input/Select';
import { MODAL_SIZE } from '../../../../../../components/modal/Modal';
import { ModalContext } from '../../../../../../context/ModalContext';
import { VoteValueType } from '../../../VotePage';
import { DescPTagBlock } from './common/PopupBlockStyle';
import { CancelBtn, OkBtn } from './common/PopupButton';

const PopListBlock = styled.div`
  // .list_ty02
  margin-top: 6% !important;
  & > ul {
    position: relative;
    & > li {
      width: 100%;
      padding-bottom: 24px;
      position: relative;
    }
    & > li:last-of-type {
      flex: 1;
      padding-bottom: 0;
    }
  }
  & > .unit {
    display: flex;
  }
`;

const UnitWrapper = styled.ul`
  display: flex;
  & > li {
    padding-bottom: 16px;
  }
`;

const RatioWrapper = styled.li`
  flex: 1;
  margin-left: 1%;
  & > select {
    width: 100%;
  }
`;

const InputWrapper = styled.div`
  // .input_wrap
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  & > p {
    flex: none;
    width: auto;
    margin-right: 30px;
  }
  & > div {
    flex: 1.4;
    position: relative;
    & > input {
      // .input_stock
      padding-right: 42px;
      text-align: right;
      letter-spacing: 1px;

      // .input_ty
      flex: 1;
      width: 100%;
      margin-right: 4px;
    }
    & > span {
      position: absolute;
      display: block;
      top: 16px;
      right: 18px;
      font-size: 14px;
    }
  }
`;
const DiversePopup = (props) => {
  let { setModalConfig, closeModal } = useContext(ModalContext);
  const [exrcsAmount, setExrcsAmount] = useState(0);

  const [voteValue, setVoteValue] = useState({
    [VoteValueType.Yes]: 0,
    [VoteValueType.No]: 0
  });
  const { agenda, voters, onVote } = props;
  useEffect(() => {
    setModalConfig({
      size: MODAL_SIZE.SHORT,
      isNoButton: true,
      innerPadding: true
    });
  }, []);

  useEffect(() => {
    const getExrcsAmount = () => {
      if (!agenda) return 0;
      const agendaSeqno = agenda.agendaSeqno;

      const arr = voters?.map((item) => {
        const data = item.voteRights.find(
          (rights) => rights.agendaSeqno === agendaSeqno
        );
        return data?.exercisableStockAmmount;
      });
      return arr && arr.length !== 0
        ? arr.reduce((acc, curr) => acc + curr)
        : 0;
    };
    setExrcsAmount(getExrcsAmount);
  }, [agenda, voters]);

  const devideVoteValue = (e) => {
    if (e.target.value === '-1') {
      setVoteValue({
        [VoteValueType.Yes]: 0,
        [VoteValueType.No]: 0
      });
      return;
    }
    const ratio = e.target.value;
    const ayesVal = Math.round(exrcsAmount * ratio);
    setVoteValue({
      [VoteValueType.Yes]: ayesVal,
      [VoteValueType.No]: exrcsAmount - ayesVal
    });
  };

  const setInputVoteValue = (type, value) => {
    if (type === VoteValueType.Yes) {
      setVoteValue({
        [VoteValueType.Yes]: value,
        [VoteValueType.No]: exrcsAmount - value
      });
    } else {
      setVoteValue({
        [VoteValueType.Yes]: exrcsAmount - value,
        [VoteValueType.No]: value
      });
    }
  };

  const vote = () => {
    console.log(voteValue);
    onVote(agenda, voteValue);
    closeModal();
  };
  return (
    <>
      <DescPTagBlock>
        불통일을 선택하시면
        <br />
        찬반의 의사를 비율에 따라 행사할 수 있습니다.
      </DescPTagBlock>
      <PopListBlock>
        <ul>
          <li>
            <UnitWrapper>
              <li style={{ width: '60%' }}>
                <InputWrapper>
                  <p>찬성</p>
                  <div>
                    <Input
                      placeholder="10,000"
                      value={voteValue[VoteValueType.Yes]}
                      onChange={(e) =>
                        setInputVoteValue(VoteValueType.Yes, e.target.value)
                      }
                    />
                    <span>주</span>
                  </div>
                </InputWrapper>
              </li>
              <RatioWrapper>
                <Select onchange={devideVoteValue}>
                  <option value="0.1">10%</option>
                  <option value="0.2">20%</option>
                  <option value="0.5">50%</option>
                  <option value="1.0">100%</option>
                </Select>
              </RatioWrapper>
            </UnitWrapper>
            <UnitWrapper>
              <li style={{ width: '60%' }}>
                <InputWrapper>
                  <p>반대</p>
                  <div>
                    <Input
                      placeholder="10,000"
                      value={voteValue[VoteValueType.No]}
                      onChange={(e) =>
                        setInputVoteValue(VoteValueType.No, e.target.value)
                      }
                    />
                    <span>주</span>
                  </div>
                </InputWrapper>
              </li>
              <RatioWrapper></RatioWrapper>
            </UnitWrapper>
          </li>
          <ButtonGroup>
            <CancelBtn onClick={closeModal} />
            <OkBtn onClick={vote} />
          </ButtonGroup>
        </ul>
      </PopListBlock>
    </>
  );
};

export default DiversePopup;
