import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import logoPng from '../../styles/images/common/logo.png';
import spCommonMobilePng from '../../styles/images/common/sprite/sp_common_mobile.png';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { GNB_ID } from '../../utils/constant/GnbMenu';
import { clearRefreshToken, setAccessToken } from '../../utils/requestHelper';
import { action as commonAction } from '../../store/commonSlice';
import { Role } from '../../utils/constant/AgmConstant';
import { isMobile } from 'react-device-detect';

const HamburgerMenu = (props) => {
  const { state, onClose } = props;
  const { user } = useSelector((state) => state.common);
  const roleArr = useMemo(() => {
    return user.isAuth ? HmbgrMenu_State.STOCKHOLDER : HmbgrMenu_State.COMMON;
  }, [user]);
  const dispatch = useDispatch();
  const history = useHistory();

  const logoutCallback = () => {
    dispatch(commonAction.setCurrentUser(null));
    setAccessToken(null);
    clearRefreshToken();
    clearInterval(window.tokenRefresh);
    dispatch(commonAction.setCurrentGnb(GNB_ID.COMMON));
    history.push('/login');
  };

  const onClickName = () => {
    switch (user?.role) {
      case Role.INDIVIUDAL_SHAREHOLDER.code:
      case Role.CORP_SHAREHOLDER.code:
        history.push('/shareholder/info');
        break;
      default:
        break;
    }
  };

  return (
    <LnbBlock on={state}>
      <div>
        <LnbHead>
          <div>
            <span>블록체인 전자주총</span>
          </div>
          {!isMobile && (
            <ul>
              {!user.isAuth ? (
                <>
                  <li>반갑습니다</li>
                  <li>
                    <button onClick={() => history.push('/login')}>
                      로그인
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <span onClick={onClickName}>{user.userName}</span>님 로그인
                    되었습니다
                  </li>
                  <li>
                    <button onClick={logoutCallback}>로그아웃</button>
                  </li>
                </>
              )}
            </ul>
          )}
        </LnbHead>
        <LnbBody>
          {roleArr.map((item) => {
            return (
              <dl>
                <dt>{item.title}</dt>
                <dd>
                  <ul>
                    {item.subTitle?.map((subItem) => {
                      return (
                        <li>
                          <a href={subItem.url}>{subItem.name}</a>
                        </li>
                      );
                    })}
                  </ul>
                </dd>
              </dl>
            );
          })}
        </LnbBody>
        <CloseBtn onClick={onClose}></CloseBtn>
      </div>
    </LnbBlock>
  );
};

const HmbgrMenu_State = {
  COMMON: [
    {
      title: '서비스 소개',
      subTitle: [
        { name: '서비스 소개', url: '/about' },
        { name: '주주서비스 이용안내', url: '/shareholder-guide' },
        { name: '발행회사 서비스 이용안내', url: '/issue-incorporated-guide' }
      ]
    },
    {
      title: '주주총회',
      subTitle: [{ name: '주주총회 일정', url: '/shareholder/agm-schedules' }]
    },
    {
      title: '인증센터',
      subTitle: [
        { name: '공동인증서 관리', url: '/certificate' },
        { name: '공동인증서 (재)등록', url: '/certificate-register' }
      ]
    },
    {
      title: '고객센터',
      subTitle: [
        { name: '공지사항', url: '/notice' },
        { name: '자주 하는 질문', url: '/faq' },
        { name: '개인정보처리방침', url: '/privacy' }
      ]
    }
  ],
  STOCKHOLDER: [
    {
      title: '나의 주주총회',
      subTitle: [
        { name: '나의 주주총회', url: '/shareholder/my-agms' },
        { name: '주주총회 일정', url: '/shareholder/agm-schedules' }
      ]
    },
    {
      title: '인증센터',
      subTitle: [
        { name: '공동인증서 관리', url: '/certificate' },
        { name: '공동인증서 (재)등록', url: '/certificate-register' }
      ]
    },
    {
      title: '고객센터',
      subTitle: [
        { name: '공지사항', url: '/notice' },
        { name: '자주 하는 질문', url: '/faq' },
        { name: '1:1 질문', url: '/qna' }
      ]
    }
  ]
};

const LnbBlock = styled.div`
  // .layer_lnb #hamburger_menu
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* display: none; */
  z-index: 300;
  ${(props) =>
    props.on
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
  & > div {
    // .layer_wrap
    width: 84%;
    height: 100%;
    background-color: #fff;
    box-shadow: 3px -3px 6px 0 rgb(0 0 0 / 25%);
    overflow: hidden;
    transform: translateX(calc(-100% - 6px));
    transition: transform 0.3s;
    ${(props) =>
      props.on &&
      css`
        transform: translateX(0);
      `}
  }
`;

const LnbHead = styled.div`
  // .lnb_head
  height: auto;
  padding: 24px 0 0;
  /* padding: 0 */
  box-sizing: border-box;
  & > div {
    // .logo
    position: relative;
    width: 140px;
    height: 18px;
    margin: 0 auto 25px;
    /* margin: 12px auto 12px; */
    background: url(${logoPng}) no-repeat;
    background-size: 100% auto;
    & > span {
      position: absolute;
      width: 1px;
      height: 1px;
      margin-top: -1px;
      box-sizing: border-box;
      color: transparent;
      overflow: hidden;
      opacity: 0;
    }
  }
  & > ul {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 26px 16px 25px;
    box-sizing: border-box;
    background: rgba(244, 245, 249, 0.7);
    & > li:first-of-type {
      flex: 1;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.5px;
      text-align: center;
      word-break: keep-all;
      & span {
        color: #0094ff;
      }
    }
    & > li + li {
      margin-left: 16px;
      & > button {
        // .login_link
        min-width: 75px;
        padding: 6px 0 5px;
        border-radius: 14px;
        border: solid 1px #caccd7;
        box-sizing: border-box;
        background-color: #fff;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: -0.5px;
        text-align: center;
      }
    }
  }
`;

const LnbBody = styled.div`
  // .lnb_body
  position: relative;
  height: calc(100% - 144px);
  padding: 20px 16px 12px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  & > dl {
    display: flex;
    margin-bottom: 25px;
    & > dt {
      flex: 0.9;
      margin: 4px 0;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -0.5px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 2px;
        right: 0;
        display: block;
        width: 1px;
        height: 14px;
        background: #080808;
      }
    }
    & > dd {
      flex: 2;
      overflow: hidden;
      & > ul {
        display: block;
        & > li {
          height: auto;
          padding: 5px 0 4px 16px;
          & a {
            font-size: 14px;
            letter-spacing: -0.5px;
          }
        }
      }
    }
  }
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 13px;
  height: 13px;
  font-size: 0;
  background: url(${spCommonMobilePng}) no-repeat;
  background-size: 230px auto;
  background-position: -151px -71px;
  overflow: hidden;
`;

export default HamburgerMenu;
