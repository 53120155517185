import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import PageHeader from '../../../components/page/PageHeader';
import RegisterSteps from '../../../components/step/RegisterSteps';
import { ModalContext } from '../../../context/ModalContext';
import { fetchPostUsersCorpAction } from '../../../services/api/users/promiseActions';
import { SignupStep } from '../../../utils/constant/AgmConstant';
import SignupStep1 from './main/corporation/SignupStep1';
import SignupStep2 from './main/corporation/SignupStep2';
import SignupStep3 from './main/corporation/SignupStep3';

const Corporation = () => {
  const [step, setStep] = useState(SignupStep.Terms);
  const { register, setValue, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { openModal } = useContext(ModalContext);
  const renderStepContent = () => {
    switch (step) {
      case SignupStep.Terms:
        return (
          <SignupStep1
            register={register}
            setValue={setValue}
            setStep={setStep}
          />
        );
      case SignupStep.Info:
        return (
          <SignupStep2
            register={register}
            handleSubmit={handleSubmit}
            setStep={setStep}
            onSignup={onSignup}
          />
        );
      case SignupStep.Confirm:
        return <SignupStep3 />;
      default:
        break;
    }
  };

  const onSignup = (data, signData) => {
    const signupData = {
      signData,
      snData: data.corpUser.bizRegistrationNumber
    };

    dispatch(fetchPostUsersCorpAction(signupData)).then((res) => {
      if (!res.error) {
        setStep(SignupStep.Confirm);
      } else {
        openModal('확인', res.error.user_message);
      }
    });
  };

  const moveNextStep = (step) => {
    setStep((prevStep) => step);
  };
  return (
    <>
      <PageHeader title="서비스 가입(법인)" />
      <RegisterSteps
        moveStep={moveNextStep}
        currentStep={step}
        stepInfo={[
          {
            stepSeq: SignupStep.Terms,
            title: '1'
          },
          {
            stepSeq: SignupStep.Info,
            title: '2'
          },
          {
            stepSeq: SignupStep.Confirm,
            title: '3'
          }
        ]}
      />
      {renderStepContent()}
    </>
  );
};

export default Corporation;
