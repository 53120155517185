import React from 'react';
import styled, { css } from 'styled-components';
import LayerBody from '../../../../../components/modal/LayerBody';
import LayerHeader from '../../../../../components/modal/LayerHeader';
import { MODAL_SIZE } from '../../../../../components/modal/Modal';
import TableTitle from '../../../../../components/table/TableTitle';

const SubModalBlock = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  //display: none;
  z-index: 100;
  width: calc(100% - 60px);
  ${(props) => {
    if (props.size === MODAL_SIZE.MEDIUM) {
      return css`
        max-width: 920px;
      `;
    } else if (props.size === MODAL_SIZE.LARGE) {
      return css`
        max-width: 1020px;
      `;
    } else if (props.size === MODAL_SIZE.SHORT) {
      return css`
        max-width: 500px;
      `;
    } else if (props.size === MODAL_SIZE.WIDE) {
      return css`
        max-width: 1220px;
      `;
    } else if (props.size === 'recaptcha') {
      return css`
        max-width: 356px;
      `;
    } else {
      return css``;
    }
  }}
  /* max-width: 1020px; */
  max-height: calc(100% - 60px);
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 200;
`;

const LayerWrapBlock = styled.div`
  padding: 22px 26px 18px;
  border-radius: 16px;
  background-color: #ffffff;
  overflow: hidden;
  & > div > span::after {
    height: 16px;
    margin-top: -5.5px;
  }
`;

const ModalBackgroundBlock = styled.div`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.3);
`;

const SubModal = ({
  layerTitle = '전자투표권자 지정',
  title,
  subTitle,
  content,
  closeAction,
  handleModal,
  modalSize = MODAL_SIZE.LARGE,
  isNoButton = false,
  btnTitle,
  btnHandler
}) => {
  return (
    <>
      <ModalBackgroundBlock hide={false} />

      <SubModalBlock size={modalSize}>
        <LayerWrapBlock>
          {layerTitle && <LayerHeader>{layerTitle}</LayerHeader>}
          <LayerBody okHandler={handleModal} isNoButton={isNoButton}>
            {subTitle && (
              <TableTitle small btnTitle={btnTitle} btnHandler={btnHandler}>
                {subTitle}
              </TableTitle>
            )}
            {typeof content === 'object' ? (
              content
            ) : (
              <span
                dangerouslySetInnerHTML={
                  typeof content === 'string' && {
                    __html: content
                  }
                }
              ></span>
            )}
          </LayerBody>
        </LayerWrapBlock>
      </SubModalBlock>
    </>
  );
};

export default SubModal;
