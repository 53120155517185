import React from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from './CommonStyle';

const ButtonGroupBlock = styled.div`
  ${(props) =>
    props.isLayer
      ? css`
          position: sticky;
          bottom: 0;
          margin-top: 24px;
          padding-top: 20px;
          //border-top: solid 1px #e9ecf4;
          border-bottom: 0;
          background-color: #fff;
        `
      : css`
          /*
          margin-top: 60px;
          border-top: 1px solid #e9ecf4;
          padding-top: 60px;
          */
          margin-top: 26px;
          border-top: 1px solid #e9ecf4;
          padding-top: 20px;
        `}

  ${(props) =>
    props.isPage &&
    css`
      margin-top: 60px;
      border-top: 1px solid #e9ecf4;
      padding-top: 60px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        margin-top: 40px;
        border-top: none;
        padding-top: 0;
      }
    `}        

  & > ul {
    display: flex;
    align-items: center;
    & > li {
      flex: 1;
      margin-left: 20px;
    }
    & > li > button,
    a {
      display: inline-block;
      width: auto;
      height: 100%;
      min-width: 200px;
      align-items: center;
      justify-content: center;
    }
    & > li:first-of-type {
      margin-left: 0;
    }
    & > li:only-of-type {
      text-align: center;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        display: flex;
        justify-content: center;
      }
    }
    & > li:only-of-type > button {
      display: inline-block;
      width: auto;
      min-width: 200px;
      margin-left: 4px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        ${(props) =>
          props.voteButton &&
          css`
            min-width: auto !important;
          `}
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
`;

const ButtonGroup = (props) => {
  const { children } = props;
  return (
    <ButtonGroupBlock {...props}>
      <ul>
        <li>{children}</li>
      </ul>
    </ButtonGroupBlock>
  );
};

export default ButtonGroup;
