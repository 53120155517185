import { createPromiseAction } from '@adobe/redux-saga-promise';

// 자주 하는 질문 - 자주묻는 질문 카테고리 조회
export const fetchGetFaqCategoriesAction = createPromiseAction(
  'FETCH_GET_FAQ_CATEGORIES'
);

// 자주 하는 질문 - 자주 하는 질문 목록 조회
export const fetchGetFaqAction = createPromiseAction('FETCH_GET_FAQ');

// 공지사항 목록 조회
export const fetchGetNoticeAction = createPromiseAction('FETCH_GET_NOTICE');

// 공지사항 조회
export const fetchGetNoticeByNotientSeqnoAction = createPromiseAction(
  'FETCH_GET_NOTICE_BY_NOTIENT_SEQNO'
);

// 공지사항 상단 게시물 목록 조회
export const fetchGetNoticeNotifiedAction = createPromiseAction(
  'FETCH_GET_NOTICE_NOTIFIED'
);
// 공지사항 파일 조회
export const fetchGetNoticeFilesAction = createPromiseAction(
  'FETCH_GET_NOTICE_FILES'
);

/* QNA */
// 문의사항 목록 조회
export const fetchGetQnaAction = createPromiseAction('FETCH_GET_QNA');
// 나의 문의사항 목록 조회
export const fetchGetQnaQuestionedAction = createPromiseAction(
  'FETCH_GET_QNA_QUESTIONED'
);
// 문의사항 파일 조회
export const fetchGetQnaFilesAction = createPromiseAction(
  'FETCH_GET_QNA_FILES'
);
// 문의사항 조회
export const fetchGetQnaByNotientSeqnoAction = createPromiseAction(
  'FETCH_GET_QNA_BY_NOTIENT_SEQNO'
);
// 문의사항 등록
export const fetchPostQnaAction = createPromiseAction('FETCH_POST_QNA');
// 문의사항 재문의
export const fetchPostQnaReplyAction = createPromiseAction(
  'FETCH_POST_QNA_REPLY'
);
// 문의사항 수정
export const fetchPutQnaAction = createPromiseAction('FETCH_PUT_QNA');
// 문의사항 삭제
export const fetchDeleteQnaAction = createPromiseAction('FETCH_DELETE_QNA');

// 문의사항 등록 - 로그인 사용자
export const fetchPostQnaLoginAction = createPromiseAction('FETCH_POST_QNA');

// 증권사 관리자 - 공지사항 - 공지사항 등록
export const fetchPostNoticeAction = createPromiseAction('FETCH_POST_NOTICE');

// 증권사 관리자 - 자주 하는 질문 - 자주묻는 질문 등록
export const fetchPostFaqAction = createPromiseAction('FETCH_POST_FAQ');

// 주소 검색
export const fetchGetAddressAction = createPromiseAction('FETCH_GET_ADDRESS');
