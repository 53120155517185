import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../components/button/CommonStyle';
import { ListWrapBlock } from '../../../../components/page/PageInner';
import { useFileDownload } from '../../../../hooks/useFileDownload';
import {
  fetchGetNoticeByNotientSeqnoAction,
  fetchGetNoticeFilesAction
} from '../../../../services/api/common/promiseActions';
import spCommonPng from '../../../../styles/images/common/sprite/sp_common.png';
import spCommonMobilePng from '../../../../styles/images/common/sprite/sp_common_mobile.png';
import {
  DateFormatName,
  getDateFormat
} from '../../../../utils/DateFormatUtil';
import { DATE_FORMAT } from '../../../../utils/DateUtil';

const NoticeDetail = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const notientSeqno = match.params.notientSeqno;
  const [detail, setDetail] = useState();
  const { callDownloadFetchNoticeFiles } = useFileDownload();
  const onDownloadFile = (file) => {
    callDownloadFetchNoticeFiles(file, fetchGetNoticeFilesAction);
  };

  useEffect(() => {
    dispatch(fetchGetNoticeByNotientSeqnoAction(notientSeqno)).then((res) => {
      if (!res.error) {
        setDetail(res.data);
      }
    });
  }, [dispatch, notientSeqno]);

  const getContent = () => {
    const contentArr = detail.content.split('.');
    let contentStr = '';
    contentArr.forEach((str) => {
      contentStr += str + '<br />';
    });
    return contentStr;
  };

  return (
    <ListWrapBlock>
      {detail && (
        <NoticeTableBlock>
          <table>
            <colgroup>
              <col width="20%" />
              <col width="*" />
            </colgroup>
            <thead>
              <tr>
                <th colSpan="2">
                  {detail.title}
                  <span>
                    {DATE_FORMAT(
                      detail.registerDateTime,
                      getDateFormat(DateFormatName.SHORT_DATE_2)
                    )}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>첨부파일</th>
                <AlignLeftTdBlock>
                  {detail.files.length !== 0 &&
                    detail.files.map((file) => {
                      return (
                        <DownloadBtnBlock onClick={() => onDownloadFile(file)}>
                          {file.fileName}
                        </DownloadBtnBlock>
                      );
                    })}
                </AlignLeftTdBlock>
              </tr>
              <ReasonTrBlock>
                <td
                  colSpan="2"
                  style={{ whiteSpace: 'unset' }}
                  dangerouslySetInnerHTML={{ __html: getContent() }}
                />
              </ReasonTrBlock>
            </tbody>
          </table>
          <TableBtnAreaBlock>
            <ul>
              <li>
                <PagingBlock>
                  <ul>
                    <li>
                      {!!detail.prev ? (
                        <PrevABlock
                          onClick={() => history.push(`/notice/${detail.prev}`)}
                        />
                      ) : (
                        <PrevABlock not />
                      )}
                    </li>
                    <li>
                      {!!detail.next ? (
                        <NextABlock
                          onClick={() => history.push(`/notice/${detail.next}`)}
                        />
                      ) : (
                        <NextABlock not />
                      )}
                    </li>
                    <li>
                      <MoveListBlock onClick={() => history.push('/notice')}>
                        목록
                      </MoveListBlock>
                    </li>
                  </ul>
                </PagingBlock>
              </li>
            </ul>
          </TableBtnAreaBlock>
        </NoticeTableBlock>
      )}
    </ListWrapBlock>
  );
};

export const NoticeTableBlock = styled.div`
  // .tblcommon-01 .notice
  width: 100%;
  & > table {
    width: 100%;
    table-layout: fixed;
    border-collapse: initial;
    border-spacing: 0;
    & th {
      background: #fbfbfc;
      border-top: solid 1px #e9ecf4;
      border-right: solid 1px #e9ecf4;
      box-shadow: 0px 3px 2px rgb(0 0 0 / 3%);
      position: relative;
      padding: 8px 8px 7px;
      height: 32px;
      font-size: 14px;
      text-align: center;
      transform: skew(-0.04deg);
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        position: relative;
        padding: 5px 4px;
        height: 22px;
        font-size: 12px;
        text-align: center;
        transform: skew(-0.04deg);
        background: #fbfbfc;
      }
    }
    & thead th {
      text-align: left;
      font-size: 18px;
      font-weight: bold;
      padding: 18px 25px 16px;
      background-color: rgb(233, 236, 244, 0.3);
      border-bottom: solid 1px #e9ecf4;
      box-shadow: none;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        text-align: left;
        font-size: 18px;
        font-weight: bold;
        padding: 10px 0;
        background-color: #fff;
        border-bottom: solid 1px #e9ecf4;
        box-shadow: none;
      }
      & span {
        display: block;
        font-size: 14px;
        font-weight: normal;
        padding-top: 6px;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          font-size: 12px;
          color: #9eb3cd !important;
        }
      }
    }
    & tr:first-of-type th:first-of-type {
      border-left: 0;
    }
    & tr:first-of-type th:last-of-type,
    tr:last-of-type th:last-of-type {
      border-right: 0;
    }

    & tbody th {
      border-top: 0;
      background-color: rgb(233, 236, 244, 0.3);
      box-shadow: none;
      border-right: solid 1px #e9ecf4 !important;
      border-bottom: solid 1px #e9ecf4 !important;

      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        background-color: rgb(233, 236, 244, 0.3);
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -ms-box-shadow: none;
        border-right: solid 1px #e9ecf4 !important;
        border-bottom: solid 1px #e9ecf4 !important;
      }
    }
    & td {
      padding: 10px 8px 9px;
      height: 30px;
      font-size: 15px;
      line-height: 1.35;
      border-bottom: solid 1px #e9ecf4;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      transform: skew(-0.04deg);
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        padding: 4px 8px 3px;
        height: 24px;
        font-size: 13.6px;
        border-bottom: solid 1px #e9ecf4;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        transform: skew(-0.04deg);
      }
    }
  }
`;

const AlignLeftTdBlock = styled.td`
  text-align: left !important;
`;

export const DownloadBtnBlock = styled.button`
  // .btn_ty01 btn_i03 down
  display: inline-block;
  line-height: 1.33;
  letter-spacing: -0.5px;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  padding: 12px 0 12px 36px;
  min-width: auto;
  background-color: transparent;
  color: #080808;
  margin-right: 40px;
  &::before {
    content: '';
    position: absolute;
    top: 33%;
    right: 22px;
    display: block;
    width: 24px;
    height: 26px;
    margin-top: -5.5px;
    background: url(${spCommonPng}) no-repeat;
    background-size: 380px auto;
    left: 4px;
    background-position: -30px -140px;
  }
`;

const ReasonTrBlock = styled.tr`
  &&& {
    background: rgba(175, 236, 255, 0.16);
    cursor: default;
    & td {
      text-align: left;
      border: 1px solid rgba(175, 236, 255, 0.6);
      border-radius: 6px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        text-align: left;
        padding: 16px;
        border: 1px solid rgba(175, 236, 255, 0.6);
        border-radius: 4px;
      }

      // .bodytext
      height: auto;
      vertical-align: baseline;
      padding: 20px 80px 60px 110px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        height: auto;
        vertical-align: baseline;
        padding: 14px 16px 28px;
      }
    }
  }
`;

const TableBtnAreaBlock = styled.div`
  // .tblbtn_area
  padding-top: 12px;
  & > ul {
    display: flex;
    align-items: center;
    & > li {
      flex: 1;
      text-align: left;
      &:first-of-type {
        margin-left: 0;
      }
      &:only-of-type {
        text-align: center;
      }
      &:last-of-type {
        text-align: right;
      }
    }
  }
`;

const PagingBlock = styled.div`
  // .paging
  width: 100%;
  text-align: center;
  height: 32px;
  & > ul {
    width: auto;
    display: inline-block;
    & > li {
      float: left;
      display: block;
      margin: 0 2px;
      & > a {
        display: block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        padding: 0;
        text-align: center;
        border: 1px solid #e9ecf4 !important;
        background: #fff;
        border-radius: 3px;
        color: #9eb3cd;
        font-size: 15px;
        transform: skew(-0.04deg);
        position: relative;

        cursor: pointer;

        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          width: 22px;
          height: 22px;
          line-height: 22px;
          border-radius: 2px;
        }
        &:hover {
          border: 1px solid #66676b;
          background: #fff;
          color: #66676b;
        }
      }
    }
    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }
`;

const PrevABlock = styled.a`
  // .prev
  &::after {
    content: '';
    position: absolute;
    top: 8px;
    left: 12px;
    display: block;
    width: 8px;
    height: 14px;
    background: url(${spCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -302px -1px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      top: 6px;
      left: 8px;
      display: block;
      width: 5px;
      height: 10px;
      background: url(${spCommonMobilePng}) no-repeat;
      background-size: 230px auto;
      background-position: -174px -1px;
    }
  }
  ${(props) =>
    !props.not
      ? css`
          &:hover::after {
            background-position: -302px -15px;
            @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
              background-position: -174px -12px;
            }
          }
        `
      : css`
          &:hover {
            cursor: not-allowed;
          }
        `}
`;

const NextABlock = styled.a`
  // .next
  &::after {
    content: '';
    position: absolute;
    top: 8px;
    left: 12px;
    display: block;
    width: 8px;
    height: 14px;
    background: url(${spCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -312px -1px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      top: 6px;
      left: 8px;
      display: block;
      width: 5px;
      height: 10px;
      background: url(${spCommonMobilePng}) no-repeat;
      background-size: 230px auto;
      background-position: -179px -1px;
    }
  }
  ${(props) =>
    !props.not
      ? css`
          &:hover::after {
            background-position: -312px -15px;
            @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
              background-position: -179px -12px;
            }
          }
        `
      : css`
          &:hover {
            cursor: not-allowed;
          }
        `}
`;

const MoveListBlock = styled.a`
  // .list
  &&& {
    font-size: 13px;
    padding: 0 20px;
    color: #66676b;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      font-size: 12px;
      width: auto;
      padding: 0 16px;
    }
  }
`;

export default NoticeDetail;
