import React from 'react';
import styled from 'styled-components';

const MainBlock = styled.div``;

const Main = ({ children }) => {
  return <MainBlock>{children}</MainBlock>;
};

export default Main;
