import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import spCommonPng from '../../styles/images/common/sprite/sp_common.png';
import spCommonMobilePng from '../../styles/images/common/sprite/sp_common_mobile.png';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';
const TermsCheckboxBlock = styled.div`
  position: relative;
  display: inline-block;
  & input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  padding-left: 52px;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.5px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding-left: 30px;
    font-size: 14px;
  }
  ${(props) =>
    props.inner &&
    css`
      padding: 1px 0 1px 52px;
      line-height: 1.67;
    `}
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 32px;
    height: 32px;
    margin-top: -16px;
    background: url(${spCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -1px -1px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      width: 19px;
      height: 19px;
      margin-top: -9px;
      background: url(${spCommonMobilePng}) no-repeat;
      background-size: 230px auto;
      background-position: -1px -1px;
    }
    ${(props) => {
      return (
        props.checked &&
        css`
          background-position: -1px -33px;
          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            /* background-position: -25px -18px; */
            background-position: -1px -20px;
          }
        `
      );
    }}/* ${(props) =>
      props.inner
        ? css`
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;
            background: url(${spCommonPng}) no-repeat;
            background-size: 400px auto;
            background-position: -43px -1px;
            ${(props) => {
              return (
                props.checked &&
                css`
                  background-position: -43px -31px;
                `
              );
            }}
          `
        : css`
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 32px;
            height: 32px;
            margin-top: -16px;
            background: url(${spCommonPng}) no-repeat;
            background-size: 400px auto;
            background-position: -1px -1px;
            ${(props) => {
              return (
                props.checked &&
                css`
                  background-position: -1px -33px;
                `
              );
            }}
          `} */
  }
`;
const TermsCheckbox = ({
  inner,
  children,
  type = null,
  onCheckTerms,
  checked = false
}) => {
  const [localChecked, setLocalChecked] = useState(checked);
  useEffect(() => {
    if (typeof onCheckTerms === 'function') {
      onCheckTerms(type, localChecked);
    }
  }, [localChecked]);

  useEffect(() => {
    setLocalChecked(checked);
  }, [checked]);

  const onCheck = () => {
    setLocalChecked((localChecked) => !localChecked);
  };
  return (
    <TermsCheckboxBlock>
      <Label inner={inner} checked={localChecked}>
        <input type="checkbox" onChange={onCheck} />
        {children}
      </Label>
    </TermsCheckboxBlock>
  );
};

export default TermsCheckbox;
