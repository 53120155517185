import { useEffect, useState } from 'react';
import styled from 'styled-components';
import FileInput from '../../../../../../../components/input/FileInput';
import { TableTitleBlock } from '../../../../../../../components/table/TableTitle';
import { useFileDownload } from '../../../../../../../hooks/useFileDownload';
import { fetchGetFilesTemporaryAction } from '../../../../../../../services/api/files/promiseActions';
import { ElecBillFileType } from '../../../../../../../utils/constant/AgmConstant';
import { ListTypeBlock, UnitTitle } from '../layout/LayoutWrapper';

const SendContent = (props) => {
  const { setValue, agm, electronicNotice } = props;
  const [files, setFiles] = useState(); //getValues 해야되나?
  const { callDownloadFetchAPITemporary } = useFileDownload();

  useEffect(() => {
    Array.isArray(files) && setValue('files', files);
  }, [files]);

  useEffect(() => {
    !!electronicNotice && setFiles(electronicNotice.files);
  }, [electronicNotice]);

  const callbackFileUpload = (file, type) => {
    if (!!files) {
      setFiles((prev) => [...prev, file]);
    } else {
      setFiles([file]);
    }
  };
  const onDeleteFile = (fileType) => {
    setFiles((prev) => prev.filter((file) => file.type !== fileType));
  };
  const fileDownload = (fileType) => {
    const file = files.find((target) => target.type === fileType);
    if (typeof file !== 'undefined') {
      callDownloadFetchAPITemporary(file, fetchGetFilesTemporaryAction);
    }
  };
  const findFileByType = (type) => {
    return files.find((file) => file.type === type);
  };
  return (
    <>
      <TableTitleBlock>
        <span>소집통지서 내용</span>
      </TableTitleBlock>
      <ListTypeBlock>
        <ul>
          <li>
            <UnitTitle>
              <span>주주총회</span>
            </UnitTitle>
          </li>
          <li>
            <InputWrapper>{agm?.agmName}</InputWrapper>
          </li>
        </ul>
        <ul>
          <li>
            <UnitTitle>
              <span>{`${ElecBillFileType.ELEC_BILL.name} 파일 첨부`}</span>
              <strong>(필수)</strong>
            </UnitTitle>
          </li>
          <li>
            <FileInput
              fileType={ElecBillFileType.ELEC_BILL.code}
              callback={callbackFileUpload}
              fileDownload={fileDownload}
              onDelete={() => onDeleteFile(ElecBillFileType.ELEC_BILL.code)}
              setFile={
                !!files && findFileByType(ElecBillFileType.ELEC_BILL.code)
              }
            />
          </li>
        </ul>
        <ul>
          <li>
            <UnitTitle>
              <span>{ElecBillFileType.ETC.name}</span>
            </UnitTitle>
          </li>
          <li>
            <FileInput
              fileType={ElecBillFileType.ETC.code}
              callback={callbackFileUpload}
              fileDownload={fileDownload}
              onDelete={() => onDeleteFile(ElecBillFileType.ETC.code)}
              setFile={!!files && findFileByType(ElecBillFileType.ETC.code)}
            />
          </li>
        </ul>
      </ListTypeBlock>
    </>
  );
};

const InputWrapper = styled.div`
  // .input_wrap
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  min-height: 32px;
`;

export default SendContent;
