import { createPromiseAction } from '@adobe/redux-saga-promise';

/**
 * 사용자 가입
 */

// 개인 주주가입
export const fetchPostUsersIndividualAction = createPromiseAction(
  'FETCH_POST_USERS_INDIVIDUAL'
);
// 개인 주주 정보 수정
export const fetchPutUsersIndividualAction = createPromiseAction(
  'FETCH_PUT_USERS_INDIVIDUAL'
);
// 개인주주 실명번호 등록
export const fetchPutUsersIndividualRealNameNumberAction = createPromiseAction(
  'FETCH_PUT_USERS_INDIVIDUAL_REAL_NAME_NUMBER'
);
// 법인 주주 가입
export const fetchPostUsersCorpAction = createPromiseAction(
  'FETCH_POST_USERS_CORP'
);
// 내정보 조회
export const fetchGetUsersMeAction = createPromiseAction('FETCH_GET_USERS_ME');
// 법인 주주 정보 수정
export const fetchPutUsersCorpAction = createPromiseAction(
  'FETCH_PUT_USERS_CORP'
);
// 인증수단 추가
export const fetchPostUsersIndividualAuthenticationMethodAction =
  createPromiseAction('FETCH_POST_USERS_INDIVIDUAL_AUTHENTICATION_METHOD');
