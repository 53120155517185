import { all, takeLatest } from '@redux-saga/core/effects';
import APIs from '..';
import createRequestSaga from '../../../utils/createRequestSaga';
import {
  fetchGetAgmStatusAction,
  fetchGetIssueIncorporatedPageAction,
  fetchGetSeccoAuthorizationAction
} from './promiseActions';

// 사용 권한 조회
const fetchGetSeccoAuthorizationSaga = createRequestSaga(
  fetchGetSeccoAuthorizationAction,
  APIs.Brokerage.fetchGetSeccoAuthorization
);

// 발행회사 정보 조회
const fetchGetIssueIncorporatedPageSaga = createRequestSaga(
  fetchGetIssueIncorporatedPageAction,
  APIs.Brokerage.fetchGetIssueIncorporatedPage
);

// 전자주총 현황 조회
const fetchGetAgmStatusSaga = createRequestSaga(
  fetchGetAgmStatusAction,
  APIs.Brokerage.fetchGetAgmStatus
);

export default function* saga() {
  yield all([
    takeLatest(
      fetchGetSeccoAuthorizationAction,
      fetchGetSeccoAuthorizationSaga
    ),
    takeLatest(
      fetchGetIssueIncorporatedPageAction,
      fetchGetIssueIncorporatedPageSaga
    ),
    takeLatest(fetchGetAgmStatusAction, fetchGetAgmStatusSaga)
  ]);
}
