import React from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import { AgendaResultType } from '../../../../../utils/constant/AgmConstant';
import { formatNumber } from '../../../../../utils/StringUtil';
import BarChart from './charts/BarChart';
import { MyResponsivePie } from './popup/VoteResultDetailPopup';

const BarChartBlock = styled.ul`
  // .result_box > ul
  flex: 1;
`;

const Circles = styled.div`
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    &&& > p {
      font-size: 14px;
      & > span {
        font-size: 15px;
      }
    }
  }
`;

const Circle = styled.div``;

const BarChartBoxBlock = styled.div`
  // .result_box
  padding: 0 30px;
  display: flex;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    flex-direction: column;
    padding: 0 5px;
    &:not(:last-of-type) > :not(ul:first-of-type) {
      border-bottom: 1px solid #e9ecf4;
    }
  }
  ${(props) =>
    props.isShowCircleChart
      ? css`
          & > ul:first-of-type {
            // .result_box > ul:first-of-type
            flex: none;
            flex-basis: 180px;
            flex-shrink: 0;
            text-align: center;
            margin-right: 50px;
          }
        `
      : css`
          & > ul {
            margin: 10px;
            @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
              margin: 10px 0;
            }
          }
        `}
  & > ul:last-of-type li {
    // .result_box > ul:last-of-type li
    // height 임시
    min-height: 128px;
    border-bottom: 1px solid #e9ecf4;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
  & > ul:last-of-type li:last-of-type {
    // .result_box > ul:last-of-type li:last-of-type, .result_box > ul:last-of-type li:last-of-type
    border-bottom: 0;
    margin-bottom: 0;
  }
  & ${Circles} {
    // .result_box .unit .circles
    height: 200px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      text-align: center;
    }
    & > p {
      // .result_box .unit .circles p
      display: block;
      font-size: 15px;
      text-align: left;
      position: relative;
      padding: 4px 0;
    }
    & span {
      // .result_box .unit .circles span
      display: inline-block;
      font-size: 17px;
      position: absolute;
      right: 0;
    }
    & ${Circle} {
      // . circle
      // temp width 124 -> 200
      width: 200px;
      margin: 17px 6px 7px;
      display: inline-block;
      position: relative;
      text-align: center;
      line-height: 1.2;

      // .result_box .unit .circles .circle
      margin: 12px 6px;
      & canvas {
        // .circle canvas
        vertical-align: top;
      }
    }
  }
  & ${BarChartBlock} {
    & li {
      // .result_box > ul:last-of-type li:last-of-type, .result_box > ul:last-of-type li:last-of-type
    }
  }
`;

const ChartInnerTextBlock = styled.p`
  &&& {
    // .result_box .unit .circles .circle p
    position: absolute;
    width: 100%;

    // .result_box .unit .circles .circle p.txt
    // temp top 26 -> 60
    top: 60px;
    font-size: 16px;
    font-weight: bold;
    transform: skew(-0.04deg);
    margin-left: 0;
  }
`;
const ChartInnerRateBlock = styled.p`
  // .result_box .unit .circles .circle p
  &&& {
    position: absolute;
    // temp top 45 -> 60
    top: 79px;
    width: 100%;
    text-align: center;
    font-size: 44px;
    font-weight: 800;
    margin-left: 5px;

    // .result_box .unit .circles .circle p span
    & span {
      font-size: 14px;
      position: static;
      font-weight: normal;
      margin-left: 2px;
    }
  }
`;

const getBarChartData = (chartData) => {
  let data = [];
  if (chartData.childs.length === 0) {
    data.push({
      label: '찬성',
      value: chartData.totalValue.ayesCount,
      percent: chartData.totalValue.ayesRate,
      isAppointment: chartData.result === AgendaResultType.INVALID.code
    });

    data.push({
      label: '반대',
      value: chartData.totalValue.noesCount,
      percent: chartData.totalValue.noesRate,
      isAppointment: chartData.result === AgendaResultType.INVALID.code
    });

    data.push({
      label: '기권',
      value: chartData.totalValue.abstentionCount,
      percent: chartData.totalValue.abstentionRate,
      isAppointment: chartData.result === AgendaResultType.INVALID.code
    });
  } else {
    data = chartData.childs.map((child) => {
      return {
        label: child.agendaName,
        value: child.totalValue.ayesCount,
        percent: child.totalValue.ayesRate,
        isAppointment: chartData.result === AgendaResultType.INVALID.code,
        result: child.result
      };
    });
  }
  return data;
};

const BarChartBox = (props) => {
  const { isShowCircleChart, agenda } = props;
  return (
    <BarChartBoxBlock>
      {isShowCircleChart && (
        <ul>
          <li>
            <Circles>
              <Circle>
                <div
                  style={{
                    width: 200,
                    height: 200,
                    position: 'relative'
                  }}
                >
                  <MyResponsivePie
                    data={[
                      {
                        id: 'value',
                        label: 'value',
                        value: agenda.totalValue?.exerciseRate,
                        color: '#0572EF'
                      },
                      {
                        id: 'empty',
                        label: 'empty',
                        value: 100 - agenda.totalValue?.exerciseRate,
                        color: '#f7f7f7'
                      }
                    ]}
                  />
                </div>
                <ChartInnerTextBlock>행사율</ChartInnerTextBlock>
                <ChartInnerRateBlock>
                  {Number(agenda.totalValue?.exerciseRate)}
                  <span>%</span>
                </ChartInnerRateBlock>
              </Circle>
              <p>
                <strong>투표 행사율(%)</strong>
              </p>
              <p>
                의결가능한 주식수
                <span>{`${formatNumber(
                  agenda.totalValue?.exercisableStockQuantity
                )}주`}</span>
              </p>
              <p>
                투표한 주식수
                <span>{`${formatNumber(
                  agenda.totalValue?.exerciseStockQuantity
                )}주`}</span>
              </p>
            </Circles>
          </li>
        </ul>
      )}
      {isShowCircleChart ? (
        <BarChart
          data={getBarChartData(agenda)}
          extend={true}
          barThemeColor={
            agenda.result === AgendaResultType.INVALID.code ? '#9eb3cd' : null
          }
        />
      ) : (
        agenda.childs.map((child, idx) => (
          <BarChart
            key={`VoteResultDetailPopup-BarChart-${idx}`}
            data={getBarChartData(child)}
            extend={true}
            title={child.agendaName}
          />
        ))
      )}
    </BarChartBoxBlock>
  );
};

export default BarChartBox;
