import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import ButtonGroup from '../../../../../../components/button/ButtonGroup';
import { MODAL_SIZE } from '../../../../../../components/modal/Modal';
import { ModalContext } from '../../../../../../context/ModalContext';
import { CancelBtn, OkBtn } from './common/PopupButton';

const Paragraph = styled.p`
  line-height: 1.53;
  word-break: keep-all;
  margin-bottom: 8px;
  text-align: center;
  & > strong {
    color: #ec2a1e !important;
  }
`;
const WithdrawDiversePopup = (props) => {
  let { setModalConfig, closeModal } = useContext(ModalContext);
  const { okHandler } = props;
  useEffect(() => {
    setModalConfig({
      size: MODAL_SIZE.SHORT,
      isNoButton: true,
      innerPadding: true
    });
  }, []);

  const onOkBtnClick = () => {
    okHandler();
    closeModal();
  };

  return (
    <>
      <Paragraph>
        <strong>불통일 신청 철회 시</strong>, 행사한 내역이
        <strong>전부 초기화</strong>되오니 의결권 제출 전 행사 내역을 다시 한번
        확인하시기 바랍니다. 불통일 신청 철회 하시겠습니까?
      </Paragraph>
      <ButtonGroup>
        <CancelBtn onClick={closeModal} />
        <OkBtn onClick={onOkBtnClick} />
      </ButtonGroup>
    </>
  );
};

export default WithdrawDiversePopup;
