import { useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import { idpwsign, SIGN_TYPE } from '../../../../components/auth/SignIframe';
import { ModalContext } from '../../../../context/ModalContext';
import { useAuth } from '../../../../hooks/useAuth';
import { fetchPutAgmStartModificationAction } from '../../../../services/api/agm/promiseActions';
import spCommonPng from '../../../../styles/images/common/sprite/sp_common.png';
import {
  AgmStateCd,
  getConstNameByCode,
  ReceiverType,
  Role
} from '../../../../utils/constant/AgmConstant';
import {
  DateFormatName,
  getDateFormat
} from '../../../../utils/DateFormatUtil';
import { dateDiff, DATE_FORMAT, now } from '../../../../utils/DateUtil';
import { EMPTY_FUNCTION } from '../../../../utils/FunctionUtil';
import { formatNumber } from '../../../../utils/StringUtil';

const LatestAgm = ({ agm }) => {
  const dispatch = useDispatch();
  const { agmSeqno, agmStateCode } = agm;
  const { openModal } = useContext(ModalContext);
  const isTestRegister = useMemo(() => {
    return agm.test ? agm.test : false;
  });
  const history = useHistory();
  const state = agm.agmStateCode; //getProgressState(agm);
  const role = useSelector((state) => state.common?.user?.role);
  const { changeRole } = useAuth();

  const onNavigate = (url) => {
    history.push(url);
  };

  const updateAgmInfo = () => {
    switch (role) {
      case Role.ISSINC_ADMIN.code:
        if (state === PROGRESS_CONST.WRITING) {
          moveRegisterForm();
        } else if (state < PROGRESS_CONST.ING) {
          openSignModal();
        }
        break;
      case Role.VIEW_ISSINC_ADMIN.code:
        changeRole(Role.ISSINC_ADMIN.code);
        break;
      default:
        break;
    }
  };

  const openSignModal = () => {
    setTimeout(() => {
      const payload = {
        precess: '07' // 주총 수정 시작
      };
      idpwsign(SIGN_TYPE.CORP, signCallback, payload);
    }, 0);
  };

  const signCallback = useCallback((signData) => {
    dispatch(
      fetchPutAgmStartModificationAction({ agmSeqno, data: { signData } })
    ).then((res) => {
      if (!res.error) {
        moveRegisterForm();
      } else {
        openModal('확인', res.error.user_message);
      }
    });
  }, []);

  const moveRegisterForm = () => {
    if (isTestRegister) {
      history.push(`/corp/agm/${agmSeqno}/register/1/test`);
    } else {
      history.push(`/corp/agm/${agmSeqno}/register/1`);
    }
  };

  const onClickAgmStatus = () => {
    history.push(`/corp/agm/info/${agmSeqno}/agm-short-desc`);
  };

  const onClickAgmResult = () => {
    history.push(`/corp/agm/info/${agmSeqno}/agm-result`);
  };

  const checkSevenDays = () => {
    return dateDiff(new Date(agm.agmResultRegisterDateTime), now()) > 7;
  };

  // TODO check 김호수
  /*
  const onClickElecBillApplyBtn = () => {
    setModalConfig({
      size: MODAL_SIZE.SHORT,
      buttons: [
        <CancelBtn onClick={() => closeModal(250)} title="취소" />,
        <OkBtn title="신청" />
      ]
    });
    openModal('전자고지서 서비스', getModalContent());
  };

  const getModalContent = () => {
    return (
      <>
        <p>전자투표 신청 시</p>
        <p>전자고지서 기능을 신청하지 않았습니다.</p>
        <p>
          <b>신청하시겠습니까?</b>
        </p>
      </>
    );
  };
  */

  const getElecBillItemBlock = () => {
    return (
      <ItemBlock
        state={
          state < PROGRESS_CONST.READY
            ? STATE_CONST.WILL
            : state === PROGRESS_CONST.ING
            ? STATE_CONST.ING
            : STATE_CONST.DONE
        }
      >
        <li>
          <p>주주총회 소집통지</p>
        </li>
        <li>
          {agm?.electronicNoticeUse &&
            agm?.electronicNotice?.hasOwnProperty('receiverType') && (
              <p>
                발송 주주
                <span style={{ textAlign: 'center' }}>
                  <span>
                    {getConstNameByCode(
                      ReceiverType,
                      agm.electronicNotice.receiverType
                    )}
                  </span>
                </span>
              </p>
            )}
        </li>
        <li>
          <p>
            전자고지 현황
            {agm?.electronicNoticeUse ? (
              <ColorSpanWrapper style={{ textAlign: 'center' }}>
                {agm?.hasOwnProperty('electronicNotice') ? (
                  <>
                    <p>
                      <strong>
                        {agm?.electronicNotice?.hasOwnProperty('sendDatetime')
                          ? '발송 완료'
                          : '예약 발송'}
                      </strong>
                    </p>
                    <span style={{ fontSize: 'small' }}>
                      (
                      {agm?.electronicNotice?.hasOwnProperty('sendDatetime')
                        ? DATE_FORMAT(
                            agm.electronicNotice.sendDatetime,
                            getDateFormat(DateFormatName.LONG_DATE_TIME)
                          )
                        : DATE_FORMAT(
                            agm.electronicNotice.reservationDatetime,
                            getDateFormat(DateFormatName.LONG_DATE_TIME)
                          )}
                      )
                    </span>
                  </>
                ) : (
                  <p>
                    <strong>미 발송</strong>
                  </p>
                )}
              </ColorSpanWrapper>
            ) : (
              <ColorSpanWrapper>
                <p>
                  <strong>미 신청</strong>
                </p>
              </ColorSpanWrapper>
            )}
          </p>
        </li>
        <li>
          {agm?.electronicNoticeUse ? (
            <>
              {agm?.hasOwnProperty('electronicNotice') ? (
                <>
                  {agm?.electronicNotice?.hasOwnProperty('sendDatetime') ? (
                    <PrimaryButtonWrapper
                      onClick={() =>
                        history.push({
                          pathname: `/corp/agm/info/${agmSeqno}/electronic-billings`,
                          state: agm.electronicNotice.sendSeqno
                        })
                      }
                    >
                      발송 현황 보기
                    </PrimaryButtonWrapper>
                  ) : (
                    <PrimaryButtonWrapper
                      onClick={() =>
                        history.push(
                          `/corp/agm/info/${agmSeqno}/electronic-billings`
                        )
                      }
                    >
                      전자고지 미 발송 주주 내역
                    </PrimaryButtonWrapper>
                  )}
                </>
              ) : (
                <>
                  {now().getTime() <
                    new Date(agm.voteBeginDateTime).getTime() && (
                    <PrimaryButtonWrapper
                      onClick={() =>
                        history.push(
                          `/corp/agm/info/${agmSeqno}/electronic-billings/send`
                        )
                      }
                    >
                      전자고지 발송
                    </PrimaryButtonWrapper>
                  )}
                </>
              )}
            </>
          ) : (
            <PrimaryButtonWrapper>전자고지 신청</PrimaryButtonWrapper>
          )}
        </li>
        {/* 이미 발송했거나, 예약을 했을 때에는 history.push()로 props를 넘겨주어야 함. sendSeqno를. */}
      </ItemBlock>
    );
  };

  return (
    <>
      <ListType07Block>
        <ul>
          <ListTitleWrapper isTestRegister={isTestRegister}>
            <p
              onClick={
                state >= PROGRESS_CONST.READY
                  ? onClickAgmStatus
                  : EMPTY_FUNCTION
              }
              style={state >= PROGRESS_CONST.READY ? { cursor: 'pointer' } : {}}
            >
              {`${isTestRegister ? '[테스트] ' : ''}`}
              {`${agm.agmName}`}
              <span>(기준일 : {agm.agmRecordDate})</span>
            </p>
          </ListTitleWrapper>
          <li>
            <p className="sub_ti">
              <span>현재 진행단계 안내</span>
            </p>
            <ContentBlock>
              <ItemBlock
                state={
                  state <= PROGRESS_CONST.READY
                    ? STATE_CONST.ING
                    : STATE_CONST.DONE
                }
              >
                <li>
                  <p>
                    주주총회개설
                    <strong>
                      {state === PROGRESS_CONST.WRITING ? '작성 중' : '완료'}
                    </strong>
                  </p>
                </li>
                <li>
                  <p>
                    주주명부인원
                    <span>{formatNumber(agm.totalShareholderCount)}명</span>
                  </p>
                  <p>
                    발행된 주식 수
                    <span>{formatNumber(agm.totalIssueStockQuantity)}주</span>
                  </p>
                </li>
                <li>
                  <p>
                    수수료납부
                    <ColorSpanWrapper style={{ textAlign: 'center' }}>
                      <p>
                        <strong>납부완료</strong>
                      </p>
                    </ColorSpanWrapper>
                  </p>
                </li>
                <li>
                  {agmStateCode !== AgmStateCd.WRITING && (
                    <PrimaryButtonWrapper onClick={onClickAgmStatus}>
                      주주총회 개설 현황
                    </PrimaryButtonWrapper>
                  )}
                  {(agmStateCode === AgmStateCd.WRITING ||
                    agmStateCode === AgmStateCd.READY) && (
                    <PrimaryButtonWrapper onClick={updateAgmInfo}>
                      수정
                    </PrimaryButtonWrapper>
                  )}
                </li>
              </ItemBlock>
              {agm.electronicNoticeUse && getElecBillItemBlock()}
              <ItemBlock
                state={
                  state < PROGRESS_CONST.ING
                    ? STATE_CONST.WILL
                    : state === PROGRESS_CONST.ING
                    ? STATE_CONST.ING
                    : STATE_CONST.DONE
                }
              >
                <li>
                  <p>
                    전자투표/전자위임장
                    <strong>
                      {state < PROGRESS_CONST.ING
                        ? '진행 전'
                        : state === PROGRESS_CONST.ING
                        ? '진행 중'
                        : '완료'}
                    </strong>
                  </p>
                </li>
                <li>
                  <p>
                    행사기간
                    {state < PROGRESS_CONST.ING ? (
                      <span>{`${DATE_FORMAT(
                        agm.voteBeginDateTime,
                        getDateFormat(DateFormatName.SHORT_DATE_TIME)
                      )}`}</span>
                    ) : (
                      <ColorSpanWrapper>
                        <strong>{`${DATE_FORMAT(
                          agm.voteBeginDateTime,
                          getDateFormat(DateFormatName.SHORT_DATE_TIME)
                        )}`}</strong>
                      </ColorSpanWrapper>
                    )}
                  </p>
                  <p>
                    {state < PROGRESS_CONST.ING ? (
                      <span>{`~ ${DATE_FORMAT(
                        agm.voteEndDateTime,
                        getDateFormat(DateFormatName.SHORT_DATE_TIME)
                      )}`}</span>
                    ) : (
                      <ColorSpanWrapper>
                        <strong>{`~ ${DATE_FORMAT(
                          agm.voteEndDateTime,
                          getDateFormat(DateFormatName.SHORT_DATE_TIME)
                        )}`}</strong>
                      </ColorSpanWrapper>
                    )}
                  </p>
                </li>
                <li>
                  {!!agm.electronicVoteRate && (
                    <CircleBlock
                      state={
                        state < PROGRESS_CONST.ING
                          ? STATE_CONST.WILL
                          : state === PROGRESS_CONST.ING
                          ? STATE_CONST.ING
                          : STATE_CONST.DONE
                      }
                    >
                      <CircleWrapper>
                        <canvas width="110" height="110" />
                        <p>
                          {agm.electronicVoteRate?.votedStockRate}
                          <span>%</span>
                        </p>
                      </CircleWrapper>
                      <p>행사율</p>
                    </CircleBlock>
                  )}
                </li>
                <li>
                  {state >= PROGRESS_CONST.ING && (
                    <PrimaryButtonWrapper
                      onClick={() =>
                        onNavigate(
                          `/corp/agm/info/${agm.agmSeqno}/counting-result`
                        )
                      }
                    >
                      {new Date().getTime() <
                      new Date(agm.voteEndDateTime).getTime()
                        ? '의안별 투표/위임 현황'
                        : '의안별 투표/위임 결과'}
                    </PrimaryButtonWrapper>
                  )}
                </li>
              </ItemBlock>
              <ItemBlock
                state={
                  state < PROGRESS_CONST.PLACE
                    ? STATE_CONST.WILL
                    : state === PROGRESS_CONST.PLACE
                    ? STATE_CONST.ING
                    : STATE_CONST.DONE
                }
              >
                <li>
                  <p>
                    현장주주총회
                    <strong>
                      {state < PROGRESS_CONST.PLACE
                        ? '진행 전'
                        : state === PROGRESS_CONST.PLACE
                        ? '진행 중'
                        : '완료'}
                    </strong>
                  </p>
                </li>
                <li>
                  <p>
                    현장 주주총회 일시
                    {state < PROGRESS_CONST.PLACE ? (
                      <span>
                        {DATE_FORMAT(
                          agm.agmDateTime,
                          getDateFormat(DateFormatName.SHORT_DATE_TIME)
                        )}
                      </span>
                    ) : (
                      <ColorSpanWrapper>
                        <strong>
                          {DATE_FORMAT(
                            agm.agmDateTime,
                            getDateFormat(DateFormatName.SHORT_DATE_TIME)
                          )}
                        </strong>
                      </ColorSpanWrapper>
                    )}
                  </p>
                </li>
                <li>
                  {/* 참석 주주 수 필드는 현재 구할 수 없기에 가려놓음. */}
                  {/* <p>
                    참석 주주 수<span>90,000명</span>
                  </p> */}
                </li>
                <li>
                  {/* 위와 같은 이유 */}
                  {/* <PrimaryButtonWrapper>주주출석 내역</PrimaryButtonWrapper> */}
                </li>
              </ItemBlock>
              <ItemBlock
                state={
                  state < PROGRESS_CONST.END
                    ? STATE_CONST.WILL
                    : state === AgmStateCd.END &&
                      dateDiff(new Date(agm.agmResultRegisterDateTime), now()) >
                        70000
                    ? STATE_CONST.DONE
                    : STATE_CONST.ING
                }
              >
                <li>
                  <p>
                    주주총회 결과
                    <strong>
                      {state < PROGRESS_CONST.END ? '진행 전' : '완료'}
                    </strong>
                  </p>
                </li>
                <li>
                  {!!agm.agmResultRegisterDateTime && (
                    <p>
                      주주총회 결과 등록 일시
                      <span>
                        {DATE_FORMAT(
                          agm.agmResultRegisterDateTime,
                          getDateFormat(DateFormatName.SHORT_DATE_TIME)
                        )}
                      </span>
                    </p>
                  )}
                </li>
                <li>
                  {!!agm.totalVoteRate && (
                    <CircleBlock
                      state={
                        state < PROGRESS_CONST.END
                          ? STATE_CONST.WILL
                          : state === PROGRESS_CONST.ING
                          ? STATE_CONST.ING
                          : STATE_CONST.DONE
                      }
                    >
                      <CircleWrapper>
                        <canvas width="110" height="110" />
                        <p>
                          {agm.totalVoteRate?.votedStockRate}
                          <span>%</span>
                        </p>
                      </CircleWrapper>
                      <p>행사율</p>
                    </CircleBlock>
                  )}
                </li>
                <li>
                  {role === Role.ISSINC_ADMIN.code && (
                    <PrimaryButtonWrapper onClick={onClickAgmResult}>
                      {checkSevenDays()
                        ? '주주총회 결과 조회'
                        : !!agm.agmResultRegisterDateTime
                        ? `주주총회 결과 수정`
                        : `주주총회 결과 등록`}
                    </PrimaryButtonWrapper>
                  )}
                  {role === Role.VIEW_ISSINC_ADMIN.code && checkSevenDays() && (
                    <PrimaryButtonWrapper onClick={onClickAgmResult}>
                      주주총회 결과 조회
                    </PrimaryButtonWrapper>
                  )}
                </li>
              </ItemBlock>
            </ContentBlock>
          </li>
        </ul>
      </ListType07Block>
    </>
  );
};

const PROGRESS_CONST = {
  WRITING: '1',
  READY: '2',
  ING: '3',
  PLACE: '4',
  END: '5'
};

const STATE_CONST = {
  DONE: 1,
  ING: 2,
  WILL: 3
};

export const ListType07Block = styled.div`
  // .list_ty07
  border: 2px solid #2e487c;
  border-top: 0;
  border-radius: 8px;
  // temp
  &:not(:first-of-type) {
    margin-top: 20px;
  }
  & > ul > li {
    padding: 0px 22px;
    & > p {
      // .sub_ti
      &.sub_ti {
        margin: 16px 0;
        text-align: left;
        & > span {
          font-size: 18px;
          font-weight: 800;
          border-bottom: 2px solid #2e487c;
          padding: 0 20px 8px 0;
          display: inline-block;
        }
      }
    }
  }
`;

const ListTitleWrapper = styled.li`
  &&& {
    padding: 20px 22px 18px;
    border-radius: 5px 5px 0 0;
    background-color: #2e487c;
    ${(props) =>
      props.isTestRegister &&
      `
    background-color: #dc6a40;
    `}
    position: relative;
    & > p {
      font-size: 24px;
      font-weight: bold;
      color: #fff;
      & > span {
        font-size: 14px;
        font-weight: normal;
        margin: 0 4px 0 30px;
      }
    }
  }
`;

const ContentBlock = styled.div`
  // .gm_nav
  display: block;
  width: 100%;
  padding: 0 0 5px 120px;
  box-sizing: border-box;
`;

const ItemBlock = styled.ul`
  // .unit
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  min-height: 98px;
  box-sizing: border-box;
  margin: 12px 0;
  background-color: transparent;
  border: solid 1px #ccd5e0;
  border-radius: 8px;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: -70px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background: #f5f7fa url(${spCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -149px -1px;
    z-index: 1;

    // 진행단계 Step 테두리 지워주기 위한 border-radius
    border-radius: 50%;
  }
  &:not(:last-of-type)::after {
    /* &::after { */
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: -55px;
    width: 1px;
    height: 122px;
    background: transparent;
    border-left: 2px dotted #caccd7;
  }
  ${(props) =>
    props.state === STATE_CONST.DONE &&
    css`
      border: solid 1px #ccd5e0;
      background-color: #fff;
      &::before {
        background-position: -149px -31px;
      }
      &::after {
        border-left: 1px solid #66676b !important;
      }
    `}
  ${(props) =>
    props.state === STATE_CONST.ING &&
    css`
      border: solid 1px #ccd5e0;
      background-color: #fff;
      box-shadow: 0px 2px 4px rgb(0 0 0 / 12%);
      &::before {
        background-position: -149px -31px;
      }
      &::after {
        border-left: 1px solid #66676b !important;
      }
      & > li:first-of-type > p > strong {
        color: #ec2a1e;
        opacity: 1 !important;
      }
    `}

    & > li {
    font-size: 15px;
    letter-spacing: -0.5px;
    color: #080808;
    text-align: left;
    position: relative;
    & > p {
      position: relative;
      margin: 8px 0 6px;
      font-size: 14px;
      color: #575f76;
      & > strong {
        display: block;
        margin-top: 8px;
        font-size: 17px;
        color: #575f76;
        opacity: 0;
      }
      & > span {
        display: block;
        position: absolute;
        top: -1px;
        right: 0;
        font-size: 17px;
        color: #080808;
      }
    }
    & > button {
      margin: 0 2px;
      flex: 0.5;
      padding: 1px 10px 0;
      height: 64px;
      border-radius: 4px;
      box-sizing: border-box;
      line-height: 1.33;
      letter-spacing: -0.5px;
      text-align: center;
      display: none;
      ${(props) =>
        props.state === STATE_CONST.DONE &&
        css`
          display: inline-block;
        `}
      ${(props) =>
        props.state === STATE_CONST.ING &&
        css`
          display: inline-block;
        `}
    }
    & > button:only-of-type {
      flex: 1;
      height: 45px;
    }
  }
  & > li:first-of-type {
    flex-basis: 200px;
    margin-left: 0;
    & > p {
      display: block;
      font-size: 21px;
      font-weight: bold;
      color: #080808;
      text-align: center;
      & > strong {
        opacity: 0.4;
      }
    }
  }
  & > li:nth-of-type(2) {
    flex-basis: 24%;
    margin-left: 4%;
  }
  & > li:nth-of-type(3) {
    flex-basis: 22%;
    margin-left: 4%;
  }
  & > li:last-of-type {
    flex-basis: 220px;
    margin-left: 7%;
    display: flex;
  }
`;

const PrimaryButtonWrapper = styled.button`
  background-color: #284cb0;
  color: #fff;
`;

const ColorSpanWrapper = styled.span`
  color: #0094ff !important;
`;

const CircleBlock = styled.div`
  height: 110px;
  text-align: right;
  position: relative;
  display: none;
  ${(props) =>
    props.state === STATE_CONST.ING &&
    css`
      display: block;
    `}
  ${(props) =>
    props.state === STATE_CONST.DONE &&
    css`
      display: block;
    `}
  & > div {
    width: 110px;
    margin: 0;
    & > p {
      position: absolute;
      left: auto;
      right: 0;
      width: 125px;
      /* width: 102px; */
      text-align: center;
      font-size: 40px;
      font-weight: 800;
      & > span {
        display: inline-block;
        font-size: 13px;
        position: static;
        font-weight: normal;
        margin-left: 2px;
      }
    }
  }
  & > p {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    margin: 0;
  }
`;

const CircleWrapper = styled.div`
  margin: 17px 6px 7px;
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.2;
  width: 124px;
  & > canvas {
    vertical-align: top;
  }
  & > p {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    margin: 0;
  }
`;

export default LatestAgm;
