import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonGroup from '../../../../../../components/button/ButtonGroup';
import Input from '../../../../../../components/input/Input';
import { MODAL_SIZE } from '../../../../../../components/modal/Modal';
import { ModalContext } from '../../../../../../context/ModalContext';
import { formatNumber } from '../../../../../../utils/StringUtil';
import SubModal from '../../../../../IssueIncorporated/register/step4/main/SubModal';
import { VoteValueType } from '../../../VotePage';
import { DescPTagBlock, TableBlock } from './common/PopupBlockStyle';
import { CancelBtn, OkBtn } from './common/PopupButton';
import ConcentrateErrorPopup from './ConcentrateErrorPopup';

const InputWrapper = styled.div`
  // .input_wrap
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  & > div {
    flex: 1.4;
    position: relative;
    & > input {
      // .input_stock
      padding-right: 42px;
      text-align: right;
      letter-spacing: 1px;
      // .input_wrap .input_ty,
      flex: 1;
      width: 100%;
      margin-right: 4px;
    }
    & > span {
      position: absolute;
      display: block;
      top: 16px;
      right: 18px;
      font-size: 14px;
    }
  }
`;

const ConcentratePopup = (props) => {
  let { setModalConfig, closeModal } = useContext(ModalContext);
  const { agenda, voters, okHandler } = props;
  const [errorData, setErrorData] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [voteArr, setVoteArr] = useState([]);
  useEffect(() => {
    setModalConfig({
      size: MODAL_SIZE.SHORT,
      isNoButton: true,
      innerPadding: true
    });
  }, []);

  const getExrcsAmount = (voter) => {
    const data = voter.voteRights.find(
      (rights) => rights.agendaSeqno === agenda.agendaSeqno
    );
    return data.exercisableStockAmmount;
  };

  const setVoteValue = (data, agenda, shareholderSeqno) => {
    /*
    const newData = {
      agenda,
      voteValues: {
        [VoteValueType.Yes]: Number(data),
        agendaSeqno: agenda.agendaSeqno,
        shareholderSeqno: shareholderSeqno,
      },
    };
    */
    const newData = {
      agenda,
      voteValues: {
        [VoteValueType.Yes]: Number(data),
        agendaSeqno: agenda.agendaSeqno,
        shareholderSeqno: shareholderSeqno
      }
    };
    if (
      voteArr.find(
        (item) =>
          item.voteValues.agendaSeqno === agenda.agendaSeqno &&
          item.voteValues.shareholderSeqno === shareholderSeqno
      )
    ) {
      let newArr = voteArr.map((item) => {
        if (
          item.voteValues.agendaSeqno === agenda.agendaSeqno &&
          item.voteValues.shareholderSeqno === shareholderSeqno
        ) {
          return newData;
        } else {
          return item;
        }
      });
      setVoteArr([...newArr]);
    } else {
      setVoteArr([...voteArr, newData]);
    }
  };

  return (
    <>
      {showErrorModal && (
        <SubModal
          layerTitle={null}
          title={
            <>
              <p>행사 내역을 확인 후 다시 시도해주세요</p>
            </>
          }
          content={
            <ConcentrateErrorPopup
              dataSource={errorData}
              closeSubModal={() => setShowErrorModal(false)}
            />
          }
        />
      )}
      <DescPTagBlock>
        의결권을 1인에게 집중적으로 행사하거나 다수인에게 분산 행사할 수
        있습니다.
      </DescPTagBlock>
      {/* votingRights가 다수면, TableBlock을 반복 */}
      {voters?.map((voter) => (
        <>
          <TableBlock>
            <table>
              <colgroup>
                <col width="40%" />
                <col width="*" />
              </colgroup>
              <thead>
                <tr>
                  <th>[주주명] {voter.stockholderName}</th>
                  <th>
                    <span>
                      행사가능주식수
                      <strong>{`${formatNumber(
                        getExrcsAmount(voter)
                      )}주`}</strong>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* 후보자 명 수에 따라 tr을 반복 */}
                {agenda.childs.map((child) => (
                  <tr>
                    <th>{`${child.agendaNumber}. ${child.agendaName}`}</th>
                    <td>
                      <InputWrapper>
                        <div>
                          <Input
                            placeholder={`${formatNumber(
                              getExrcsAmount(voter) / 2
                            )}`}
                            onChange={(e) =>
                              setVoteValue(
                                e.target.value,
                                child,
                                voter.stockholderSeqno
                              )
                            }
                          />
                          <span>주</span>
                        </div>
                      </InputWrapper>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableBlock>
        </>
      ))}
      <ButtonGroup>
        <CancelBtn onClick={() => closeModal(250)} />
        <OkBtn
          onClick={() => {
            const agendaSeqno = agenda.agendaSeqno;
            const voterArr = voters.map((voter) => {
              const temp = voter.voteRights.find(
                (rights) => rights.agendaSeqno === agendaSeqno
              );
              return {
                agendaSeqno,
                stockholderName: voter.stockholderName,
                stockholderSeqno: voter.stockholderSeqno,
                amount: temp.exercisableStockAmmount
              };
            });
            let valueArr = [];
            voteArr.forEach((item, idx) => {
              const value = valueArr.find(
                (v) => v.stockholderSeqno === item.voteValues.shareholderSeqno
              );
              if (value) {
                const data = {
                  stockholderSeqno: item.voteValues.shareholderSeqno,
                  value: item.voteValues.ayes + value.value
                };
                valueArr = valueArr.filter(
                  (a) => a.stockholderSeqno !== item.voteValues.shareholderSeqno
                );
                valueArr.push(data);
              } else {
                valueArr.push({
                  stockholderSeqno: item.voteValues.shareholderSeqno,
                  value: item.voteValues.ayes
                });
              }
            });
            const lastArr = voterArr.map((voter, idx) => {
              const value = valueArr.find((value) => {
                return value.stockholderSeqno === voter.stockholderSeqno;
              }).value;
              return {
                index: idx + 1,
                stockholderName: voter.stockholderName,
                stockholderSeqno: voter.stockholderSeqno,
                amount: voter.amount,
                value: value
              };
            });
            if (
              lastArr.find((vote) => {
                return vote.amount !== vote.value;
              })
            ) {
              setErrorData(lastArr.filter((obj) => obj.amount !== obj.value));
              setShowErrorModal(true);
            } else {
              okHandler(voteArr);
              closeModal(250);
            }
          }}
        />
      </ButtonGroup>
    </>
  );
};

export default ConcentratePopup;
