import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import FileInput from '../../../components/input/FileInput';
import Input, { ErrorTypes } from '../../../components/input/Input';
import InputBox from '../../../components/input/InputBox';
import ListItem from '../../../components/layout/ListItem';
import ToastEditor from '../../../components/toastUI/ToastEditor';
import { useFileDownload } from '../../../hooks/useFileDownload';
import { fetchGetQnaFilesAction } from '../../../services/api/common/promiseActions';
import { fetchGetFilesTemporaryAction } from '../../../services/api/files/promiseActions';
import SubModal from '../../IssueIncorporated/register/step4/main/SubModal';

const QnaWritePage = (props) => {
  const { user } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const [content, setContent] = useState();
  const [files, setFiles] = useState([]);
  const [fileClear, setFileClear] = useState(false);
  const [regMessage, setRegMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [reCaptchaValue, setReCaptchaValue] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm();

  const { highNotientSeqno, notientSeqno, action, data } = props;
  useEffect(() => {
    typeof data?.content !== 'undefined' && setContent(data.content);
    reset(data);
    if (data?.files) {
      setFiles(
        data.files.map((file, idx) => {
          return {
            ...file,
            isNew: false,
            type: idx + 1
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (fileClear) {
      setFileClear((prev) => !prev);
    }
  }, [fileClear]);

  const onClickSubmit = (data) => {
    if (files) {
      data.files = [...files];
    }

    if (user.isAuth) {
      dispatch(
        action({
          ...data,
          captcha: reCaptchaValue,
          notientSeqno,
          highNotientSeqno
        })
      ).then((res) => {
        if (!res.error) {
          setRegMessage('등록 되었습니다.');
        } else {
          setErrorMessage(res.error.user_message);
        }
      });
    } else {
      //openModal('오류', '비 로그인 사용자는 QnA 등록을 할 수 없습니다.');
    }
  };

  const callbackFile = (file, type) => {
    if (!files?.find((item) => item.type === type)) {
      setFiles((prev) => [...prev, { ...file, isNew: true }]);
    } else {
      setFiles((prev) => [
        ...prev.filter((item) => item.type === type),
        { ...file, isNew: true }
      ]);
    }
  };

  const { callDownloadFetchAPITemporary, callDownloadFetchNoticeFiles } =
    useFileDownload();

  const fileDownload = (type) => {
    const file = files.find((item) => item.type === type);
    if (typeof file !== 'undefined') {
      if (file.isNew) {
        callDownloadFetchAPITemporary(file, fetchGetFilesTemporaryAction);
      } else {
        callDownloadFetchNoticeFiles(file, fetchGetQnaFilesAction);
      }
    }
  };

  const onRecaptcha = (value) => {
    setReCaptchaValue(value);
  };

  return (
    <>
      {(errorMessage || regMessage) && (
        <SubModal
          layerTitle="확인"
          // title="확인"
          content={regMessage ? regMessage : errorMessage}
          handleModal={
            errorMessage ? () => setErrorMessage(null) : props.callback
          }
        />
      )}
      <ColumnOnlyListWrapBlock>
        <ListTy02Block>
          <ul>
            <li>
              <ListItem heightType="short">
                <InputBox
                  title="제목"
                  isRequired
                  error={errors?.title?.message}
                  errorType={ErrorTypes.ERROR}
                >
                  <Input
                    placeholder="제목을 입력하세요"
                    register={register}
                    name="title"
                    registerOption={{
                      required: {
                        value: true,
                        message: '제목은 필수 입력사항입니다.'
                      }
                    }}
                  />
                </InputBox>
              </ListItem>
              {/* <ListItem heightType="short">
                <InputBox
                  title="답변 받을 이메일 주소"
                  isRequired
                  error={errors?.title?.email}
                  errorType={ErrorTypes.ERROR}
                >
                  <Input placeholder="이메일을 입력하세요" />
                </InputBox>
              </ListItem> */}
              <ListItem heightType="short">
                <InputBox
                  title="내용"
                  isRequired
                  error={errors?.content?.message}
                  errorType={ErrorTypes.ERROR}
                >
                  <ToastEditor
                    name="content"
                    setValue={setValue}
                    initialValue={content}
                  />
                </InputBox>
              </ListItem>
              <ListItem>
                <InputBox title="파일첨부" isVertical>
                  <FileInput
                    placeholder="첨부하실 파일을 선택하세요"
                    fileType={1}
                    callback={callbackFile}
                    fileDownload={fileDownload}
                    isPopup
                    setFile={data ? data.files[0] : null}
                    onDelete={() =>
                      setFiles(files.filter((file) => file.type !== 1))
                    }
                  />
                  <FileInput
                    placeholder="첨부하실 파일을 선택하세요"
                    fileType={2}
                    callback={callbackFile}
                    fileDownload={fileDownload}
                    isPopup
                    setFile={data ? data.files[1] : null}
                    onDelete={() =>
                      setFiles(files.filter((file) => file.type !== 2))
                    }
                  />
                  <FileInput
                    placeholder="첨부하실 파일을 선택하세요"
                    fileType={3}
                    callback={callbackFile}
                    fileDownload={fileDownload}
                    isPopup
                    setFile={data ? data.files[2] : null}
                    onDelete={() =>
                      setFiles(files.filter((file) => file.type !== 3))
                    }
                  />
                </InputBox>
              </ListItem>
              <ReCAPTCHA
                size="normal"
                sitekey="6LdF2kAdAAAAABND7PtuIIkWiJVyys8pzWuqe2xT"
                onChange={onRecaptcha}
              />
            </li>
          </ul>
        </ListTy02Block>
      </ColumnOnlyListWrapBlock>
      <ButtonGroup>
        <Button
          type={ButtonTypes.Basic}
          size={ButtonSize.Medium}
          onClick={props.closeModal}
        >
          취소
        </Button>
        <Button
          type={ButtonTypes.Primary}
          size={ButtonSize.Medium}
          onClick={handleSubmit(onClickSubmit)}
        >
          등록
        </Button>
      </ButtonGroup>
    </>
  );
};

const ColumnOnlyListWrapBlock = styled.div`
  // .list_wrap .column-only
  margin: 0 auto;
  padding: 60px 0 0;
  //max-width: 640px;
  padding-top: 4px;
`;

const ListTy02Block = styled.div`
  // .list_ty02
  & > ul {
    position: relative;
    & > li {
      width: 100%;
      padding-bottom: 24px;
      position: relative;
    }
    & > li:last-of-type,
    &:last-of-type > li:only-of-type {
      flex: 1;
      padding-bottom: 0;
    }
  }
`;

export default QnaWritePage;
