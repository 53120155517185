export const SELECT_BOX_YEAR_COUNT = 5;
export const MONTH_COUNT = 12;
export const THIS_YEAR = new Date().getFullYear();

/*eslint no-extend-native: ["error", { "exceptions": ["String", "Number"] }]*/
String.prototype.string = function (len) {
  let s = '';
  let i = 0;
  while (i++ < len) {
    s += this;
  }
  return s;
};
String.prototype.zf = function (len) {
  return '0'.string(len - this.length) + this;
};
Number.prototype.zf = function (len) {
  return this.toString().zf(len);
};
export const DATE_FORMAT = (date, format) => {
  if (!date?.valueOf()) {
    return ' ';
  }
  if (checkInvalidDate(new Date(date))) {
    return;
  }
  const d = new Date(date);
  const h = d.getHours() % 12;
  return format.replace(
    /(yyyy|yy|MMM|MM|dd|EEE|E|hh|mm|ss|a\/p)/gi,
    function ($1) {
      switch ($1) {
        case 'yyyy':
          return d.getFullYear();
        case 'yy':
          return (d.getFullYear() % 1000).zf(2);
        case 'MMM':
          return DATE_CONST.MONTH_ABBRS[d.getMonth()];
        case 'MM':
          return (d.getMonth() + 1).zf(2);
        case 'dd':
          return d.getDate().zf(2);
        case 'EEE':
          return DATE_CONST.WEEK_DAY[d.getDay()];
        case 'E':
          return DATE_CONST.WEEK_NAMES[d.getDay()];
        case 'HH':
          return d.getHours().zf(2);
        case 'hh':
          return (h ? h : 12).zf(2);
        case 'mm':
          return d.getMinutes().zf(2);
        case 'ss':
          return d.getSeconds().zf(2);
        case 'a/p':
          return d.getHours() < 12 ? '오전' : '오후';
        default:
          return $1;
      }
    }
  );
};
export function getToday() {
  const tmp = new Date();
  return new Date(tmp.getFullYear(), tmp.getMonth(), tmp.getDate());
}
export function now() {
  return new Date();
}
export const checkInvalidDate = (d) => {
  return !(d instanceof Date && !isNaN(d));
};
export const checkSameDay = (d1, d2) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};
export const getFiveYear = () => {
  const FIVE = 5;
  const resultArr = [];
  for (let i = 0; i < FIVE; i++) {
    resultArr.push(now().getFullYear() + 2 - i);
  }
  return resultArr;
};
export const DATE_CONST = {
  WEEK_NAMES: ['일', '월', '화', '수', '목', '금', '토'],
  WEEK_DAY: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  MONTH_ABBRS: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
};

export const dateTimeToISOString = (date, time) => {
  if (checkInvalidDate(new Date(date))) {
    return;
  }
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  const result = checkInvalidDate(time)
    ? new Date(
        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes()
        ) - timezoneOffset
      )
    : new Date(
        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          time.getHours(),
          time.getMinutes()
        ) - timezoneOffset
      );
  return result?.toISOString().split('.')[0];
};

export const getYMDSplit = (ymd) => {
  // ymd : (ex)19940401
  const year = ymd.substring(0, 4);
  const month = ymd.substring(4, 6);
  const date = ymd.substring(6, 8);
  return [year, month, date];
};

export const getCurrentDatetime = () => {
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  return new Date(now() - timezoneOffset).toISOString();
};

export const dateDiff = (date1, date2) => {
  return Math.ceil((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
};
