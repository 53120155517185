import { useEffect, useState } from 'react';
import DataTable from '../../../components/datatable/DataTable';
import Page from '../../../components/page/Page';
import { TableTitleBlock } from '../../../components/table/TableTitle';
import { useDispatch } from 'react-redux';
import { fetchGetAgmStatusAction } from '../../../services/api/brokerage/promiseActions';
import { ListWrapBlock } from '../../IssueIncorporated/dashboard/CorpDashboardPage';
import {
  AgmStateCode,
  AgmType,
  getConstNameByCode,
  VotingRightsExerciseMethod
} from '../../../utils/constant/AgmConstant';
import { DATE_FORMAT } from '../../../utils/DateUtil';
import { DateFormatName, getDateFormat } from '../../../utils/DateFormatUtil';
import Select from '../../../components/input/Select';
import SearchInput from '../../../components/input/SearchInput';

const OverviewPage = () => {
  const dispatch = useDispatch();
  const [agms, setAgms] = useState();
  const [pageConfig, setPageConfig] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    const data = {
      page: pageNumber,
      size: 10,
      name: searchName
    };
    dispatch(fetchGetAgmStatusAction(data)).then((res) => {
      if (!res.error) {
        const { number, size, totalElements, totalPages, content } = res.data;
        const newArr = [];
        content.forEach((agm) => {
          newArr.push({
            ...agm,
            agmType: getConstNameByCode(AgmType, agm.agmType),
            agmDateTime: `${DATE_FORMAT(
              agm.agmDateTime,
              getDateFormat(DateFormatName.SHORT_DATE_3)
            )}`,
            agmRecordDate: `${DATE_FORMAT(
              agm.agmRecordDate,
              getDateFormat(DateFormatName.SHORT_DATE_3)
            )}`,
            votedStockRate: `${
              !!agm.electronicVoteRate
                ? agm.electronicVoteRate?.votedStockRate
                : 0
            } %`,
            voteDateTime: `${DATE_FORMAT(
              agm.voteBeginDateTime,
              getDateFormat(DateFormatName.SHORT_DATE_OTHER_2)
            )} ~ ${DATE_FORMAT(
              agm.voteEndDateTime,
              getDateFormat(DateFormatName.SHORT_DATE_OTHER_2)
            )}`,
            useVoteProxy: getUseVoteProxy(
              agm.electronicVoteUse,
              agm.electronicProxyUse
            ),
            agmStateCode: getConstNameByCode(AgmStateCode, agm.agmStateCode)
          });
        });
        setAgms(newArr);
        setPageConfig({
          pageNumber: number,
          size,
          totalCount: totalElements,
          totalPageNumber: totalPages
        });
      }
    });
  }, [dispatch, pageNumber, searchName]);

  const columns = [
    {
      title: '법인명',
      key: 'issueIncorporatedName',
      col: '14%'
    },
    {
      title: '구분',
      key: 'agmType',
      col: '6%'
    },
    {
      title: '기수',
      key: 'agmSessionCount',
      col: '6%'
    },
    {
      title: '주총일',
      key: 'agmDateTime',
      col: '10%'
    },
    // {
    //   title: '참여 주주 수(명)',
    //   key: 'totalShareholderCount',
    //   col: '7%',
    //   type: DataType.NUMBER
    // },
    // {
    //   title: '행사 주식 수(주)',
    //   key: '',
    //   col: '7%',
    //   type: DataType.NUMBER
    // },
    {
      title: '행사 일시',
      key: 'voteDateTime',
      col: '*'
    },
    {
      title: '전자투표',
      key: 'useVoteProxy',
      col: '12%'
    },
    {
      title: '진행단계',
      key: 'agmStateCode',
      col: '8%'
    },
    {
      title: '의안 수',
      key: 'numberOfAgendas',
      col: '6%'
    },
    {
      title: '행사율',
      key: 'votedStockRate',
      col: '8%'
    }
  ];

  const getUseVoteProxy = (useVote, useProxy) => {
    let result = '';
    if (useVote) {
      result += VotingRightsExerciseMethod.ELECTRONIC_VOTE.name + ', ';
    }
    if (useProxy) {
      result += VotingRightsExerciseMethod.ELECTRONIC_PROXY.name + ', ';
    }
    return result.slice(0, -2);
  };

  const onSearch = (value) => {
    setSearchName(value);
  };

  const renderSelOptAgendas = () => {
    return <option>발행회사명</option>;
  };

  const onchangeAgenda = () => {};

  const onRowClick = () => {};

  return (
    <Page>
      <ListWrapBlock>
        <TableTitleBlock>
          <span>전자주총 현황 조회</span>
        </TableTitleBlock>
        {agms && (
          <DataTable
            columns={columns}
            dataSource={agms}
            filterConfig={{
              desc: '주총을 선택하시면 상세 정보 페이지로 이동합니다.',
              filterItems: (
                <>
                  <Select onchange={onchangeAgenda} noDefault>
                    {renderSelOptAgendas()}
                  </Select>
                  <SearchInput
                    placeholder="검색"
                    onSearch={(value) => onSearch(value)}
                  />
                </>
              )
            }}
            onRowClick={onRowClick}
            //   detailValueNames={['exerciseContent']}
            pageConfig={{
              ...pageConfig,
              onClick: setPageNumber
            }}
          />
        )}
      </ListWrapBlock>
    </Page>
  );
};

export default OverviewPage;
