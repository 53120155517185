import { Role } from './AgmConstant';

export const GnbMenu = {
  common: [
    {
      index: 0,
      id: 'about',
      title: '서비스 소개',
      url: '/about',
      matchUrl: ['/about', '/shareholder-guide', '/issue-incorporated-guide']
    },
    {
      index: 1,
      id: 'agmSchedule',
      title: '주주총회 일정',
      url: '/shareholder/agm-schedules',
      matchUrl: ['/shareholder/agm-schedules']
    },
    {
      index: 2,
      id: 'certificate',
      title: '인증센터',
      url: '/certificate',
      matchUrl: ['/certificate', '/certificate-register']
    },
    {
      index: 3,
      id: 'notice',
      title: '고객센터',
      url: '/notice',
      matchUrl: ['/notice', '/faq', '/privcay']
    }
  ],
  shareholder: [
    {
      index: 0,
      id: 'shareholder/agm',
      title: '나의 주주총회',
      url: '/shareholder/my-agms',
      matchUrl: ['/shareholder/my-agms', '/shareholder/agm-schedules']
      // url: '/shareholder/agm',
    },
    {
      index: 1,
      id: 'certificate',
      title: '인증센터',
      url: '/certificate',
      matchUrl: ['/certificate']
    },
    {
      index: 2,
      id: 'notice',
      title: '고객센터',
      url: '/notice',
      matchUrl: ['/notice', '/faq']
    }
  ],
  corp: [
    {
      index: 1,
      id: '/corp/agm/dashboard',
      title: '주주총회',
      url: '/corp/agm/dashboard',
      matchUrl: [
        '/corp/agm/',
        '/corp/agm/:agmSeqno',
        '/corp/agm/info/:agmSeqno/:agmMenu',
        '/corp/agm/register/:step',
        '/corp/agm/:agmSeqno/register/:step',
        '/corp/agm/register/:step/test',
        '/corp/agm/:agmSeqno/register/:step/test',
        '/corp/agm/dashboard'
      ]
    },
    {
      index: 2,
      id: 'certificate',
      title: '인증센터',
      url: '/certificate',
      matchUrl: ['/certificate']
    },
    {
      index: 2,
      id: 'notice',
      title: '고객센터',
      url: '/notice',
      matchUrl: ['/notice', '/faq']
    }
  ],
  brokerage: [
    {
      index: 0,
      id: 'issuer-info',
      title: '발행회사 정보 조회',
      url: '/brokerage/issuer-info',
      matchUrl: ['/brokerage/issuer-info']
    },
    {
      index: 1,
      id: 'overview',
      title: '전자주총 현황 조회',
      url: '/brokerage/overview',
      matchUrl: ['/brokerage/overview']
    },
    {
      index: 2,
      id: 'manage',
      title: '서비스 관리',
      url: '/brokerage/manage',
      matchUrl: [
        '/brokerage/manage/notice',
        '/brokerage/manage/faq',
        '/brokerage/manage/privacy',
        '/brokerage/manage/qna'
      ]
    }
  ]
};
export const GnbSubMenu = {
  common: [
    [
      {
        index: 0,
        id: 'about',
        parndId: 'about',
        title: '서비스 소개',
        url: '/about'
      },
      {
        index: 1,
        id: 'about',
        parndId: 'about',
        title: '주주서비스 이용안내',
        url: '/shareholder-guide'
      },
      {
        index: 2,
        id: 'about',
        parndId: 'about',
        title: '발행회사서비스 이용안내',
        url: '/issue-incorporated-guide'
      }
    ],
    [
      {
        index: 0,
        id: 'about',
        parndId: 'about',
        title: '주주총회 일정',
        url: '/shareholder/agm-schedules'
      }
    ],
    [
      {
        index: 0,
        parndId: 'certificate',
        id: 'certificate',
        title: '공동인증서 관리',
        url: '/certificate'
      },
      {
        index: 1,
        id: 'certificate-register',
        parndId: 'certificate-register',
        title: '공동인증서 (재)등록',
        url: '/certificate-register'
      }
    ],
    [
      {
        index: 0,
        id: 'notice',
        parndId: 'notice',
        title: '공지사항',
        url: '/notice'
      },
      {
        index: 1,
        id: 'faq',
        parndId: 'faq',
        title: '자주 하는 질문',
        url: '/faq'
      },
      {
        index: 2,
        id: 'privacy',
        title: '개인정보처리방침',
        url: '/privacy',
        matchUrl: ['/privacy', '/privacy/:prevNo']
      }
      /*
      {
        index: 2,
        id: 'qna',
        parndId: 'qna',
        title: '1:1 질문',
        url: '/qna'
      }
      */
    ]
  ],
  shareholder: [
    [
      {
        index: 0,
        id: 'about',
        parndId: 'about',
        title: '나의 주주총회',
        url: '/shareholder/my-agms',
        matchUrl: ['/shareholder/my-agms']
      },
      {
        index: 0,
        id: 'about',
        parndId: 'about',
        title: '주주총회 일정',
        url: '/shareholder/agm-schedules',
        matchUrl: ['/shareholder/agm-schedules']
      }
    ],
    [
      {
        index: 0,
        id: 'certificate',
        parndId: 'certificate',
        title: '공동인증서 관리',
        url: '/certificate',
        matchUrl: ['/certificate']
      },
      {
        index: 0,
        id: 'certificate-register',
        parndId: 'certificate-register',
        title: '공동인증서 (재)등록',
        url: '/certificate-register'
      }
    ],
    [
      {
        index: 0,
        id: 'notice',
        parndId: 'notice',
        title: '공지사항',
        url: '/notice'
      },
      {
        index: 1,
        id: 'faq',
        parndId: 'faq',
        title: '자주 하는 질문',
        url: '/faq'
      },
      {
        index: 2,
        id: 'qna',
        parndId: 'qna',
        title: '1:1 질문',
        url: '/qna'
      }
    ]
  ],
  corp: [
    [
      {
        index: 2,
        id: 'manage',
        parnId: 'manage',
        title: '주주총회 관리',
        url: '/corp/agm/dashboard'
      },
      {
        index: 0,
        id: 'about',
        parndId: 'about',
        title: '주주총회 등록',
        url: '/corp/agm/register/1',
        matchUrl: ['/corp/agm/register/:step']
      }
      // {
      //   index: 1,
      //   id: 'aboutTest',
      //   parndId: 'aboutTest',
      //   title: '주주총회 등록 (테스트)',
      //   url: '/corp/agm/register/1/test',
      //   matchUrl: ['/corp/agm/register/:step/test']
      // }
      // {
      //   index: 2,
      //   id: 'elecBill',
      //   parnId: 'elecBill',
      //   title: '전자고지 발송',
      //   url: '/corp/agm/elec-bill'
      // }
    ],
    [
      {
        index: 0,
        id: 'certificate',
        parndId: 'certificate',
        title: '공동인증서 관리',
        url: '/certificate'
      },
      {
        index: 1,
        id: 'certificate-register',
        parndId: 'certificate-register',
        title: '공동인증서 (재)등록',
        url: '/certificate-register'
      }
    ],
    [
      {
        index: 0,
        id: 'notice',
        parndId: 'notice',
        title: '공지사항',
        url: '/notice'
      },
      {
        index: 1,
        id: 'faq',
        parndId: 'faq',
        title: '자주 하는 질문',
        url: '/faq'
      },
      {
        index: 2,
        id: 'qna',
        parndId: 'qna',
        title: '1:1 질문',
        url: '/qna'
      }
    ]
  ],
  brokerage: [
    [
      {
        index: 0,
        id: 'info',
        parndId: 'info',
        title: '발행회사 정보 조회',
        url: '/brokerage/issuer-info'
      }
    ],
    [
      {
        index: 0,
        id: 'overview',
        parndId: 'overview',
        title: '전자주총 현황 조회',
        url: '/brokerage/overview'
      }
    ],
    [
      {
        index: 0,
        id: 'notice',
        parndId: 'manage',
        title: '공지사항 관리',
        url: '/brokerage/manage/notice'
      },
      {
        index: 1,
        id: 'faq',
        parndId: 'manage',
        title: 'FAQ 관리',
        url: '/brokerage/manage/faq'
      },
      {
        index: 2,
        id: 'privacy',
        parndId: 'manage',
        title: '동의서 관리',
        url: '/brokerage/manage/privacy'
      },
      {
        index: 3,
        id: 'qna',
        parndId: 'manage',
        title: '1:1 문의 관리',
        url: '/brokerage/manage/qna'
      }
    ]
  ]
};

export const GNB_ID = {
  COMMON: 'common',
  SHAREHOLDER: 'shareholder',
  CORP: 'corp',
  BROKERAGE: 'brokerage'
};
export const getGnbRole = (role) => {
  switch (role) {
    case Role.INDIVIUDAL_SHAREHOLDER.code:
    case Role.CORP_SHAREHOLDER.code:
      return GNB_ID.SHAREHOLDER;
    case Role.ISSINC_ADMIN.code:
    case Role.VIEW_ISSINC_ADMIN.code:
      return GNB_ID.CORP;
    case Role.SECCO_ADMIN.code:
      return GNB_ID.BROKERAGE;
    default:
      return GNB_ID.COMMON;
  }
};
