import React, { useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonTypes } from '../../../../components/button/Button';
import Input from '../../../../components/input/Input';
import Page from '../../../../components/page/Page';
import Table, { TableDataType } from '../../../../components/table/Table';
import { Icons } from '../../../../styles/Icons';

const PageTestBlock = styled.div``;

const PageTest = () => {
  const columns = [
    {
      title: '테스트',
      refId: 'index',
      type: TableDataType.NUMBER
    },
    {
      title: '날짜',
      type: TableDataType.DATETIME,
      refId: 'date'
    },
    {
      title: '숫자',
      type: TableDataType.NUMBER,
      refId: 'number'
    },
    {
      title: '나를 봐 나를봐',
      refId: 'me'
    },
    {
      title: '고양이아 밥 묵장',
      //type: TableDataType.NUMBER,
      refId: 'go'
    },
    {
      title: '인풋 테스트',
      refId: 'test'
    }
  ];

  const dataSource = [];
  for (let i = 0; i < 9; i++) {
    dataSource.push({
      index: i, // <CheckBox checked={i % 2 ? true : false} />,
      date: Date.now(),
      number: i + 10000,
      me: `me_${i + 999}`,
      go: (
        <Button small endIcon={Icons.search_blue}>
          검색
        </Button>
      ),
      test: <Input isComplete />
    });
  }
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const checkTableData = () => {
    // 요거 훅으로...Hook~!!
    selectedRowKeys.map((idx) =>
      dataSource.map((data) => {
        if (idx === data.index) {
          console.log(data);
        }
        return null;
      })
    );
  };

  return (
    <PageTestBlock>
      <Page>
        테스트
        <Button type={ButtonTypes.Basic} endIcon={Icons.search_blue}>
          할롱
        </Button>
        <Table
          title="화장실 간다.."
          columns={columns}
          dataSource={dataSource}
          rowSelection={rowSelection}
        />
        <Button onClick={checkTableData}>확인</Button>
        111
      </Page>
    </PageTestBlock>
  );
};

export default PageTest;
