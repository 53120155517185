import PropTypes from 'prop-types'; // ES6
import React from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import spriteCommonPng from '../../../../../styles/images/common/sprite/sp_common.png';
import spriteCommonMobilePng from '../../../../../styles/images/common/sprite/sp_common_mobile.png';
import { formatNumber } from '../../../../../utils/StringUtil';
import DefaultButtonStyle, {
  VOTE_BUTTON_SIZE,
  VOTE_BUTTON_TEXT_ALIGN,
  VOTE_BUTTON_TYPE
} from './DefaultButtonStyle';

const getVoteButtonTypeCss = (type, size) => {
  switch (type) {
    case VOTE_BUTTON_TYPE.Done:
      // .boxlist_ty .unit .vote_done
      return css`
        display: inline-block;
        width: auto;
        padding: 12px 90px 12px 78px;
        background-color: #fff;
        border-color: #9eb3cd;
        margin: 32px 4px 0;
        box-shadow: none;

        // Temp
        color: #000;
        &::after {
          // .boxlist_ty .unit .vote_done::after
          content: '';
          position: absolute;
          top: 17px;
          right: 60px;
          width: 9px;
          height: 14px;
          background: url(${spriteCommonPng}) no-repeat;
          background-size: 400px auto;
          background-position: -312px -1px;
          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            content: '';
            position: absolute;
            top: 12px;
            right: 10px;
            width: 5px;
            height: 10px;
            background: url(${spriteCommonMobilePng}) no-repeat;
            background-size: 230px auto;
            background-position: -191px -1px;
          }
        }
      `;

    case VOTE_BUTTON_TYPE.Foucs:
      // .boxlist_ty .unit .vote_focus
      return css`
        background-color: #24375c;
        border-color: #24375c;
        color: #fff;
        margin-bottom: 32px;

        // Temp
        &::after {
          // .boxlist_ty .unit .vote_done::after
          content: '';
          position: absolute;
          top: 17px;
          right: 60px;
          width: 9px;
          height: 14px;
          background: url(${spriteCommonPng}) no-repeat;
          background-size: 400px auto;
          background-position: -312px -1px;
          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            content: '';
            position: absolute;
            top: 12px;
            right: 10px;
            width: 5px;
            height: 10px;
            background: url(${spriteCommonMobilePng}) no-repeat;
            background-size: 230px auto;
            background-position: -191px -1px;
          }
        }
      `;

    case VOTE_BUTTON_TYPE.Stock:
      return size === VOTE_BUTTON_SIZE.Long
        ? // .boxlist_ty .unit .aLeft
          css`
            text-align: left;
          `
        : null;

    default:
      return null;
  }
};

const VoteButtonBlock = styled(DefaultButtonStyle)`
  ${(props) =>
    props.size === VOTE_BUTTON_SIZE.Short &&
    // .boxlist_ty .unit .vote_short
    css`
      width: 150px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        width: 94px;
        padding: 7px 10px;
      }
      ${(props) =>
        props.active &&
        // .boxlist_ty .unit .vote_short.vote_on
        css`
          background-color: #0075ff;
          border-color: #0075ff;
          color: #fff;
          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            background-color: #0075ff;
            border-color: #0075ff;
            color: #fff;
            padding-left: 32px;
          }
        `}
    `}

  ${(props) =>
    props.size === VOTE_BUTTON_SIZE.Long &&
    // .boxlist_ty .unit .vote_long
    css`
      display: block;
      margin: 16px auto 0;
      width: 320px;
      height: auto;
      text-align: center;
      border-radius: 4px;
      box-sizing: border-box;

      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        background-color: #24375c;
        border-color: #24375c;
        font-size: 15px;
        color: #fff;
        width: 164px;
        margin-bottom: 20px;
      }

      ${(props) =>
        props.active &&
        // .boxlist_ty .unit .vote_long.vote_on
        css`
          background-color: #0075ff;
          border-color: #0075ff;
          color: #fff;
          padding-right: 120px;
        `}
    `}

    ${(props) => getVoteButtonTypeCss(props.type, props.size)}


    ${(props) => props.textAlign === VOTE_BUTTON_TEXT_ALIGN.Left && ''}

    ${(props) => {
    return (
      props.size === VOTE_BUTTON_SIZE.Short &&
      props.active &&
      // .boxlist_ty .unit .check::after
      css`
        &::after {
          content: '';
          position: absolute;
          top: 14px;
          left: 18px;
          display: block;
          width: 22px;
          height: 16px;
          background: url(${spriteCommonPng}) no-repeat;
          background-size: 400px auto;
          background-position: -302px -95px;

          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            content: '';
            position: absolute;
            top: 11px;
            left: 10px;
            display: block;
            width: 19px;
            height: 14px;
            background: url(${spriteCommonMobilePng}) no-repeat;
            background-size: 230px auto;
            background-position: -174px -55px;
          }
        }
      `
    );
  }}
`;

const StockAmountBlock = styled.span`
  // .boxlist_ty .unit li span
  display: inline-block;
  width: auto;
  padding: 10px 0 6px;
  font-size: 14px;
  color: #575f76;

  // temp
  font-weight: bold;

  // .boxlist_ty .unit button span
  padding: 12px 0 0;
  font-size: 15px;
  color: #fff;

  // .boxlist_ty .unit .vote_short span.stock
  display: block;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    display: inline-block;
    width: auto;
    padding: 8px 0 0;
    font-size: 13px;
    color: #575f76;

    padding: 12px 0 0;
    color: #fff;

    display: block;
  }
`;

const VoteButton = (props) => {
  const { children, type, stockAmount, disabled } = props;
  const showStock =
    type === VOTE_BUTTON_TYPE.Stock && stockAmount !== 0 && !disabled;
  return (
    <VoteButtonBlock {...props} active={showStock}>
      {children}
      {showStock && (
        <StockAmountBlock {...props}>{`${formatNumber(
          stockAmount
        )}주`}</StockAmountBlock>
      )}
    </VoteButtonBlock>
  );
};

VoteButton.propTypes = {
  type: PropTypes.oneOf(Object.values(VOTE_BUTTON_TYPE)),
  size: PropTypes.oneOf(Object.values(VOTE_BUTTON_SIZE)),
  active: PropTypes.bool,
  disabled: PropTypes.bool
};

VoteButton.defaultProps = {
  active: false,
  disabled: false
};

export default VoteButton;
