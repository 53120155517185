import React from 'react';
import PropTypes from 'prop-types'; // ES6
import { Icons } from '../../styles/Icons';
import IconButton from '../button/IconButton';
import TermsCheckbox from './TermsCheckbox';
import styled from 'styled-components';

const TermsItemTitle = ({
  title,
  type,
  checked,
  active,
  inner,
  onToggle,
  onCheckTerms,
  compulsory
}) => {
  const arrowIcon = active ? Icons.arrow_down_on : Icons.arrow_down;
  return (
    <>
      <TermsCheckbox
        inner
        type={type}
        onCheckTerms={onCheckTerms}
        checked={checked}
      >
        {title}
        {compulsory && <ImportantSpanBlock> (필수)</ImportantSpanBlock>}
      </TermsCheckbox>
      <IconButton icon={arrowIcon} onClick={onToggle} />
    </>
  );
};

const ImportantSpanBlock = styled.span`
  color: #ec2a1e !important;
`;

TermsItemTitle.propTypes = {
  title: PropTypes.string,
  checked: PropTypes.bool,
  active: PropTypes.bool
};

TermsItemTitle.defaultProps = {
  title: '',
  checked: false,
  active: false
};

export default TermsItemTitle;
