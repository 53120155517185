import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../components/button/CommonStyle';
import { ModalContext } from '../../../../context/ModalContext';
import { useFileDownload } from '../../../../hooks/useFileDownload';
import { fetchGetFilesAgmAction } from '../../../../services/api/agm/promiseActions';
import spCommonPng from '../../../../styles/images/common/sprite/sp_common.png';
import spCommonMobilePng from '../../../../styles/images/common/sprite/sp_common_mobile.png';
import {
  AgmFileType,
  AgmType,
  getConstNameByCode
} from '../../../../utils/constant/AgmConstant';
import StockDetailPopup from '../../vote/main/components/popup/StockDetailPopup';
import VotingLimitsPopup from '../../vote/main/components/popup/VotingLimitsPopup';
import CertificateCheckSelect from './components/CertificateCheckSelect';
import MultiExercise from './contents/MultiExercise';
import SoloExercise from './contents/SoloExercise';

const UnorderedList = styled.ul`
  position: relative;
  border-bottom: solid 1px #d8e1eb;
  padding-bottom: 20px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: relative;
    border-bottom: solid 1px #d8e1eb;
    padding-bottom: 12px;
  }
  & > li {
    width: 100%;
    padding: 20px 0 0;
    font-size: 16px;
    letter-spacing: -0.5px;
    color: #000826;
    text-align: left;
    display: flex;
    align-content: center;
    align-items: center;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      width: 100%;
      padding: 16px 0 0;
      letter-spacing: -0.5px;
      color: #080808;
      text-align: left;
      display: flex;
      align-content: center;
      align-items: center;
    }
    & > span {
      display: inline-block;
      width: 140px;
      height: 18px;
      padding: 7px 0 7px 40px;
      color: #575f76;
      margin-right: 20px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        display: inline-block;
        width: 70px;
        height: 18px;
        padding: 5px 0;
        font-size: 12px;
        color: #575f76;
        margin-right: 0;
      }
    }
  }
  &:first-of-type {
    & > li {
      padding: 0;
      display: block;
      position: relative;
      & > p {
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          display: inline-block;
          font-weight: bold;
          font-size: 13px;
        }
      }
      & > span {
        display: inline-block;
        width: 140px;
        height: 18px;
        padding: 7px 0 7px 40px;
        color: #575f76;
        margin-right: 20px;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          display: inline-block;
          width: 70px;
          height: 18px;
          padding: 5px 0;
          font-size: 12px;
          color: #575f76;
        }
      }
      & > button {
        position: absolute;
        top: 0;
        right: 0;
        width: 180px;
        height: 44px;
        font-size: 16px;
        color: #24375c;
        display: block;
        margin: 0;
        font-weight: bold;
        text-align: center;
        border-radius: 22px;
        border: 2px solid #9eb3cd;
        background-color: #fff;
        box-sizing: border-box;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          position: absolute;
          top: 0;
          right: 0;
          width: 92px;
          height: 28px;
          color: #24375c;
          display: block;
          margin: 0;
          padding-top: 1px;
          font-weight: bold;
          text-align: center;
          border-radius: 14px;
          border: 2px solid #9eb3cd;
          background-color: #fff;
          box-sizing: border-box;

          font-size: 13px;
        }
      }
    }
  }
`;

export const BoxTitleWrapper = styled.li`
  // .box_ti
  &&& {
    display: block;
    text-align: center;
    padding: 20px 0 32px;
    margin-bottom: 40px;
    position: relative;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      display: block;
      text-align: center;
      padding: 14px 0 16px;
      margin-bottom: 16px;
      position: relative;
    }
    & p {
      display: block;
      font-size: 24px;
      font-weight: 800;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        display: block;
        font-size: 16px;
        font-weight: 800;
      }
    }
    & > button {
      display: inline-block;
      width: auto;
      padding: 0 64px 0 25px;
      height: 44px;
      font-size: 14px;
      font-weight: bold;
      color: #505f7e;
      margin: 20px 2px 0;
      border-radius: 4px;
      border: 1px solid #e3e5ea;
      background-color: transparent;
      box-sizing: border-box;
      position: relative;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        display: inline-block;
        width: auto;
        padding: 0 36px 0 14px;
        height: 30px;
        font-size: 12px;
        font-weight: bold;
        color: #505f7e;
        margin: 20px 2px 0;
        border-radius: 4px;
        border: 1px solid #e3e5ea;
        background-color: transparent;
        box-sizing: border-box;
        position: relative;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 10px;
        right: 20px;
        width: 24px;
        height: 23px;
        background: url(${spCommonPng}) no-repeat;
        background-size: 400px auto;
        background-position: -33px -201px;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          content: '';
          display: block;
          position: absolute;
          top: 5px;
          right: 10px;
          width: 17px;
          height: 16px;
          background: url(${spCommonMobilePng}) no-repeat;
          background-size: 230px auto;
          background-position: -86px -116px;
        }
      }
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      left: calc(50% - 10px);
      width: 20px;
      height: 3px;
      background: #0572ef;
    }
  }
`;

const CertificateInfo = (props) => {
  const {
    info,
    openWithdrawDiversPopup,
    openDiverseReasonPopup,
    setSelectedVoters,
    isVoteScreen,
    selectedVoters,
    useDiverse,
    onCleanData,
    isCleanData,
    selectNonParticipation,
    onChangeElectronicProxyName
  } = props;
  const { openModal } = useContext(ModalContext);
  const { downloadFile } = useFileDownload();
  const dispatch = useDispatch();
  if (!info) return null;
  const { agmSeqno } = info;
  const openVotingLimitsPopup = () => {
    openModal(
      '의결권 제한 내역',
      <VotingLimitsPopup agmSeqno={agmSeqno} voters={info.voters} />
    );
  };

  const openStockDetailPopup = (voter) => {
    openModal(
      '보유주식 상세 보기',
      <StockDetailPopup agmSeqno={agmSeqno} voters={voter} />
    );
  };

  const renderBodyContents = () => {
    if (info.voters.length === 1) {
      return (
        <SoloExercise
          info={info}
          openVotingLimitsPopup={openVotingLimitsPopup}
          openStockDetailPopup={openStockDetailPopup}
          isVoteScreen={isVoteScreen}
        />
      );
    } else {
      return (
        <MultiExercise
          info={info}
          selectedVoters={selectedVoters}
          setSelectedVoters={setSelectedVoters}
          openStockDetailPopup={openStockDetailPopup}
          openVotingLimitsPopup={openVotingLimitsPopup}
          isVoteScreen={isVoteScreen}
          onCleanData={onCleanData}
          isCleanData={isCleanData}
          selectNonParticipation={selectNonParticipation}
        />
      );
    }
    // return <TermsContents info={info} />;
  };

  const getTitle = () => {
    return `${info.issueIncorporatedName} ${getConstNameByCode(
      AgmType,
      info.agmType
    )} ${info.agmSessionCount ? info.agmSessionCount + ' 회' : '주주총회'}`;
  };

  const onFileDownload = (file) => {
    console.log(file);
    dispatch(fetchGetFilesAgmAction({ agmSeqno, params: { ...file } })).then(
      (res) => {
        const fileType = res.meta.headers['content-type'].split(';')[0];
        downloadFile(res.data, file.fileName, fileType);
      }
    );
  };

  return (
    <div>
      {/* .boxinfo */}
      <UnorderedList>
        <BoxTitleWrapper>
          <p>{getTitle()}</p>
          {info.files.map((file, index) => {
            return (
              <button
                key={`boxTitleWrapperFile_${index}`}
                onClick={() => onFileDownload(file)}
              >
                {getConstNameByCode(AgmFileType, file.type)}
              </button>
            );
          })}
        </BoxTitleWrapper>
        {renderBodyContents()}
      </UnorderedList>
      {isVoteScreen && (
        <UnorderedList>
          <CertificateCheckSelect
            info={info}
            useDiverse={useDiverse}
            selectedVoters={selectedVoters}
            solicitor={info.solicitor}
            openWithdrawDiversPopup={openWithdrawDiversPopup}
            openDiverseReasonPopup={openDiverseReasonPopup}
            onChangeElectronicProxyName={onChangeElectronicProxyName}
          />
        </UnorderedList>
      )}
    </div>
  );
};

export default CertificateInfo;

CertificateInfo.propTypes = {
  info: PropTypes.object,
  openWithdrawDiversPopup: PropTypes.func,
  openDiverseReasonPopup: PropTypes.func,
  openVotingLimitsPopup: PropTypes.func,
  openStockDetailPopup: PropTypes.func,
  setSelectedVoters: PropTypes.func,
  isVoteScreen: PropTypes.bool
};

CertificateInfo.defaultProps = {
  isVoteScreen: false
};

export const NEED_BODY_CONTENT = {
  NOPE: '1',
  CHECK_SELECT: '2',
  DATA_TABLE: '3'
};
