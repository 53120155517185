import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { idpwsign, SIGN_TYPE } from '../../../../components/auth/SignIframe';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../components/button/Button';
import { SCREEN_SIZE_MOBILE } from '../../../../components/button/CommonStyle';
import Input from '../../../../components/input/Input';
import InputBox from '../../../../components/input/InputBox';
import Radio from '../../../../components/input/Radio';
import RadioGroup from '../../../../components/input/RadioGroup';
import ListItem from '../../../../components/layout/ListItem';
import ListTag from '../../../../components/layout/ListTag';
import TermsOneItem from '../../../../components/terms/TermsOneItem';
import { ModalContext } from '../../../../context/ModalContext';
import useWindowSize from '../../../../hooks/useWindowSize';
import { fetchGetTermsSeqnoAction } from '../../../../services/api/terms/promiseActions';
import { fetchPutUsersIndividualRealNameNumberAction } from '../../../../services/api/users/promiseActions';
import SpCommonPng from '../../../../styles/images/common/sprite/sp_common.png';
import { AgmTermsType } from '../../../../utils/constant/AgmConstant';
import {
  setAccessToken,
  setRefreshToken
} from '../../../../utils/requestHelper';
import { SignupAuthBlock } from '../../../common/signup/main/corporation/SignupStep2';

const ConfirmBlock = styled.div`
  // .mem_inner
  max-width: 960px;
  margin: 0 auto;
`;

const ContentWrapperBlock = styled.div`
  // .list_wrap
  max-width: 1020px;
  margin: 0 auto;
  padding: 60px 0 0;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    max-width: none;
    padding: 16px 0 0;
  }
`;

const TitleBlock = styled.div`
  // .mem_ti
  font-size: 16px;
  font-weight: bold;
  transform: skew(-0.04deg);
  padding-bottom: 16px;
  border-bottom: 1px solid #e9ecf4;
  margin-bottom: 30px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    width: 100%;
    padding-bottom: 16px;
    position: relative;
    font-weight: bold;
    display: block;
    color: #080808;

    font-size: unset;
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const InfoBlock = styled.div`
  // .list_ty02
  & > ul {
    position: relative;
    & > li {
      width: 100%;
      padding-bottom: 24px;
      position: relative;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        padding-bottom: 16px;
      }
    }
    & > li:last-of-type {
      flex: 1;
      padding-bottom: 0;
    }
  }
`;

const NoticeWrapper = styled.div`
  // .list_ti
  min-height: 28px;
  font-size: 27px;
  font-weight: bold;
  color: #0572ef;
  & > span {
    // .list_dir_bg
    display: block;
    margin-top: 5px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.45;
    color: #8c939c;
    background-color: #f5f7fa;
    border-radius: 4px;
    padding: 8px 12px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      display: block;
      font-size: 12px;
      font-weight: normal;
      color: #2e2e34;
      background-color: white;
      border-radius: 4px;
      padding: 12px;

      margin-top: 0;
    }
  }
`;

const UnitBlock = styled.ul`
  // .unit
  display: flex;
  & > li {
    position: relative;
    flex: 1;
    padding-bottom: 55px;
    margin-left: 8%;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      padding-bottom: 16px;
      margin-left: 0;
    }
  }
  & > li:first-of-type {
    margin-left: 0;
  }
  &:last-of-type > li {
    padding-bottom: 0;
  }
`;

export const UnitTitleWrapper = styled.div`
  // .unit_ti
  display: block;
  margin-bottom: 8px;
  & > span {
    position: relative;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: #080808;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      position: relative;
      display: inline-block;
      font-size: 12px;
      font-weight: bold;
      color: #080808;
    }
    @media not screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -16px;
        display: block;
        width: 11px;
        height: 12px;
        background: url(${SpCommonPng}) no-repeat;
        background-size: 400px auto;
        background-position: -189px -1px;
      }
    }
  }
`;

const ListTagBlock = styled.div`
  // .list_ti
  min-height: 28px;
  font-size: 27px;
  font-weight: bold;
  color: #0572ef;
`;

const RadioWrapper = styled.div`
  & > div {
    margin-left: 6%;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      margin-left: 5%;
    }
  }
  & > div:first-of-type {
    margin-left: 3%;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      margin-left: 1.2%;
    }
  }
`;

const TermBlock = styled.div`
  // .list_ty01 .mt_45
  margin-top: 45px !important;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin-top: 20px !important;
  }
  & > ul {
    position: relative;
    & > li {
      padding-bottom: 20px;
      border-bottom: 0;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        padding-bottom: 0;
      }
    }
  }
`;

const ConfirmStockholder = ({ setError }) => {
  const dispatch = useDispatch();
  const [agreeState, setAgreeState] = useState(false);
  const [selRadioValue, setSelRadioValue] = useState();
  const [allChecked, setAllChecked] = useState(false);
  const { register, handleSubmit } = useForm();
  const [contents, setContents] = useState();
  const { openModal } = useContext(ModalContext);

  const windowSize = useWindowSize();
  const isMobile = useMemo(() => {
    return windowSize.width <= SCREEN_SIZE_MOBILE;
  }, [windowSize]);

  useEffect(() => {
    dispatch(
      fetchGetTermsSeqnoAction(
        AgmTermsType.UNIQUE_ID_INFORAMTION_TREATMENT.code
      )
    ).then((res) => {
      setContents(res.data.content);
    });
  }, [dispatch]);

  useEffect(() => {
    if (Object.values(RADIO_CONST).includes(selRadioValue) && agreeState) {
      setAllChecked(true);
    } else {
      setAllChecked((state) => false);
    }
  }, [selRadioValue, agreeState]);

  const callback = (data, sign) => {
    if (allChecked) {
      data.signData = sign;
      dispatch(fetchPutUsersIndividualRealNameNumberAction(data)).then(
        (res) => {
          if (!res.error) {
            setAccessToken(res.data.accessToken);
            setRefreshToken(res.data.refreshToken);
            setError(null);
          } else {
            openModal('확인', res.error.user_message);
          }

          console.log(res);
        }
      );
    } else {
      // 처리 다시해야됨. signbutton에 onclick 달아야됨
      return;
    }
  };

  const onChangeRadio = (value) => {
    setSelRadioValue(value);
  };

  const onSign = () => {
    handleSubmit((data) => {
      idpwsign(SIGN_TYPE.CORP, (signData) => callback(data, signData));
    })();
  };

  return (
    <ConfirmBlock>
      <ContentWrapperBlock>
        <TitleBlock>
          온라인 주주총회 참석을 통해 전자투표·전자의결권 이용을 위해서는 본인
          확인 절차를 통한 주주 여부 확인이 필요합니다.
        </TitleBlock>
        <InfoBlock>
          <ul>
            <li>
              <NoticeWrapper>
                <span>
                  주주명부와 고객정보 확인을 통해 주주 여부를 확인합니다.
                </span>
              </NoticeWrapper>
            </li>
            {isMobile || (
              <li>
                <ListTagBlock>
                  <ListTag />
                </ListTagBlock>
              </li>
            )}
            <li>
              <UnitBlock>
                <li>
                  <UnitTitleWrapper>
                    <span>주주 구분</span>
                    {isMobile && <RequiredSpanTag>(필수)</RequiredSpanTag>}
                  </UnitTitleWrapper>
                  <RadioWrapper>
                    <RadioGroup onChange={onChangeRadio}>
                      <Radio label="개인" value={RADIO_CONST.INDIVIDUAL} />
                      <Radio label="외국인" value={RADIO_CONST.FOREIGNER} />
                    </RadioGroup>
                  </RadioWrapper>
                </li>
              </UnitBlock>
              <UnitBlock>
                <li>
                  <ListItem>
                    <InputBox
                      title="주민등록번호"
                      error="외국인인 경우, 외국인 등록번호를 입력하세요."
                      isRequired
                      isMobile={isMobile}
                      isMobileVertical
                    >
                      <Input
                        placeholder="'-'제외하고 입력"
                        register={register}
                        name="snData"
                      />
                    </InputBox>
                  </ListItem>
                </li>
              </UnitBlock>
            </li>
          </ul>
        </InfoBlock>
        <TermBlock>
          <ul>
            <li>
              <UnitTitleWrapper>
                <span>고유식별정보의 처리 동의</span>
                {isMobile && <RequiredSpanTag>(필수)</RequiredSpanTag>}
              </UnitTitleWrapper>
              {typeof contents !== 'undefined' && (
                <TermsOneItem
                  title="고유식별정보 처리 동의약관을 읽으신 후, 동의해주세요."
                  confirmMessage="개인정보보호법 제15조 제1항, 제24조 제1항, 제24조의2, 제24조의2 제1항에 따라 개인정보를 수집·이용하는데 동의합니다
"
                  content={contents}
                  agreeState={agreeState}
                  setAgreeState={setAgreeState}
                />
              )}
            </li>
          </ul>
        </TermBlock>
      </ContentWrapperBlock>
      <SignupAuthBlock>
        <ul>
          <li>
            <Button
              size={ButtonSize.Medium}
              type={allChecked ? ButtonTypes.Primary : ButtonTypes.Inert}
              onClick={onSign}
            >
              확인
            </Button>
          </li>
        </ul>
      </SignupAuthBlock>
    </ConfirmBlock>
  );
};

const RequiredSpanTag = styled.span`
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    &&& {
      margin-left: 2px;
      color: #ec2a1e;
    }
  }
`;

export default ConfirmStockholder;

const RADIO_CONST = {
  INDIVIDUAL: '1',
  FOREIGNER: '2',
  CORP: '3',
  INVEST: '4'
};
