import React from 'react';
import Input from '../../../../../components/input/Input';
import { formatNumber } from '../../../../../utils/StringUtil';
import ResultTableSelect from '../../../register/main/components/ResultTableSelect';
import { getAgendaDisplayName, getDivStyle } from '../AgmResult';

const SeparationTable = ({ data, onChangeReuslt, onChangeCount, isEdit }) => {
  console.log('SepTable Props');
  console.log(data);
  return (
    <div className="tblcommon-01" style={getDivStyle(data.agenda)}>
      <table>
        <colgroup>
          <col width="16%;" />
          <col width="14%;" />
          <col width="17%" />
          <col width="17%;" />
          <col width="17%" />
          <col width="*" />
        </colgroup>
        <thead>
          <tr>
            <th colSpan="6" className="title atv">
              {getAgendaDisplayName(data.agenda)}
            </th>
          </tr>
        </thead>
        {data.childs.map((child, idx) => (
          <tbody key={`TotalConcentrateTable-tbody-${idx}`}>
            <tr>
              <th rowSpan="5">{child.agenda.agendaName}</th>
              <th colSpan="5">
                <span className="stock">
                  행사가능 주식수
                  <strong className="ft-col02">
                    {formatNumber(child.agenda.exercisableStockQuantity)}주
                  </strong>
                </span>
              </th>
            </tr>
            <tr>
              <td></td>
              <td>찬성</td>
              <td>반대</td>
              <td>기권</td>
              <td rowSpan="4">
                <ResultTableSelect
                  onChange={onChangeReuslt}
                  parentAgendaSeqno={data.agenda.agendaSeqno}
                  agendaSeqno={child.agenda.agendaSeqno}
                  seletcedValue={child.result}
                  disabled={!isEdit}
                  isEdit={isEdit}
                />
              </td>
            </tr>
            <tr>
              <td>전자투표결과(a)</td>
              <td className="nRight">
                {formatNumber(child.electronicVoteValue?.ayesCount)}
              </td>
              <td className="nRight">
                {formatNumber(child.electronicVoteValue?.noesCount)}
              </td>
              <td className="nRight">
                {formatNumber(child.electronicVoteValue?.abstentionCount)}
              </td>
            </tr>
            <tr>
              <td>현장투표결과(b)</td>
              <td className="nRight">
                {isEdit ? (
                  <Input
                    type="number"
                    onChange={(e) =>
                      onChangeCount(
                        e.target.value,
                        data.agenda.agendaSeqno,
                        child.agenda.agendaSeqno,
                        'ayesCount'
                      )
                    }
                    value={child.spotValue?.ayesCount}
                  />
                ) : (
                  formatNumber(child.spotValue?.ayesCount)
                )}
              </td>
              <td className="nRight">
                {isEdit ? (
                  <Input
                    type="number"
                    onChange={(e) =>
                      onChangeCount(
                        e.target.value,
                        data.agenda.agendaSeqno,
                        child.agenda.agendaSeqno,
                        'noesCount'
                      )
                    }
                    value={child.spotValue?.noesCount}
                  />
                ) : (
                  formatNumber(child.spotValue?.noesCount)
                )}
              </td>
              <td className="nRight">
                {isEdit ? (
                  <Input
                    type="number"
                    onChange={(e) =>
                      onChangeCount(
                        e.target.value,
                        data.agenda.agendaSeqno,
                        child.agenda.agendaSeqno,
                        'abstentionCount'
                      )
                    }
                    value={child.spotValue?.abstentionCount}
                  />
                ) : (
                  formatNumber(child.spotValue?.abstentionCount)
                )}
              </td>
            </tr>
            <tr>
              <td>전체결과(a+b)</td>
              <td className="nRight">
                {formatNumber(child.totalValue?.ayesCount)}
              </td>
              <td className="nRight">
                {formatNumber(child.totalValue?.noesCount)}
              </td>
              <td className="nRight">
                {formatNumber(child.totalValue?.abstentionCount)}
              </td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
};

export default SeparationTable;
