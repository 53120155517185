import React from 'react';
import styled from 'styled-components';

const TableDataBlock = styled.div`
  & a {
    display: block;
    padding: 10px 0;
  }
`;

const TableDataWrapper = styled.ul``;

const TableData = ({ children }) => {
  return (
    <TableDataBlock>
      <TableDataWrapper>{children}</TableDataWrapper>
    </TableDataBlock>
  );
};

export default TableData;
