import { useSelector } from 'react-redux';
import CorpInfo from './CorpInfo';
import IndivisualInfo from './IndivisualInfo';
import IssincInfo from './IssincInfo';

const InfoConroller = () => {
  const role = useSelector((state) => state.common.user.role);
  return (
    <>
      {(AuthTpcd.Issinc === role || AuthTpcd.ViewIssInc === role) && (
        <IssincInfo />
      )}
      {AuthTpcd.Indivisual === role && <IndivisualInfo />}
      {AuthTpcd.Corporate === role && <CorpInfo />}
    </>
  );
};

const AuthTpcd = {
  Issinc: '04',
  ViewIssInc: '98',
  Indivisual: '11',
  Corporate: '12'
};

export default InfoConroller;
