import { GET, POST, PUT } from '../../../utils/constant/httpMethod';
import { request } from '../../../utils/requestHelper';

const API_GROUP = 'elections';
const Elections = {
  // 투표하기

  // 투표 화면
  fetchGetElectionsAgmBallot(agmSeqno) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/agm/${agmSeqno}/ballot`
      //params: agmSeqno,
    });
  },

  // 투표하기 - 전자서명
  fetchPostElectionsAgmVote({ agmSeqno, data }) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/agm/${agmSeqno}/vote`,
      data
    });
  },

  // 투표하기 - 전자위임장 취소
  fetchPutElectionsAgmCancelProxy({ agmSeqno, data }) {
    return request({
      method: PUT,
      url: `/v1/${API_GROUP}/agm/${agmSeqno}/cancel-proxy`,
      data
    });
  },

  // 투표하기 - 전자의결권 취소
  fetchPutElectionsAgmCancelVote({ agmSeqno, data }) {
    return request({
      method: PUT,
      url: `/v1/${API_GROUP}/agm/${agmSeqno}/cancel-vote`,
      data
    });
  }
};

export default Elections;
