import React from 'react';
import styled from 'styled-components';

const TableColumnsBlock = styled.div`
  width: 100%;
  background: #fbfbfc;
  border-top: solid 1px #e9ecf4;
  border-bottom: solid 1px #e9ecf4;
  box-shadow: 0px 3px 2px rgb(0 0 0 / 3%);
`;

const ColumnWrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableColumns = ({ children }) => {
  return (
    <TableColumnsBlock>
      <ColumnWrapper>{children}</ColumnWrapper>
    </TableColumnsBlock>
  );
};

export default TableColumns;
