import React from 'react';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import { formatNumber } from '../../../../../utils/StringUtil';

const ExercisableStockAmountBlock = styled.li``;

const SpanBlock = styled.span`
  // .exercisableStockAmmount
  display: inline-block;
  width: auto;
  padding: 10px 0 6px;
  font-size: 14px;
  color: #575f76;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    display: inline-block;
    width: auto;
    padding: 8px 0 0;
    font-size: 13px;
    color: #575f76;
  }
`;

const StrongBlock = styled.strong`
  // .boxlist_ty .unit li span strong
  margin-left: 20px;
  color: #0075ff;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin-left: 8px;
    color: #0075ff;
  }
`;

const ExercisableStockAmount = (props) => {
  const { stockAmount } = props;
  return (
    <ExercisableStockAmountBlock>
      <SpanBlock>
        행사가능 주식수
        <StrongBlock>{`${formatNumber(stockAmount)}주`}</StrongBlock>
      </SpanBlock>
    </ExercisableStockAmountBlock>
  );
};

export default ExercisableStockAmount;
