import React from 'react';
import styled from 'styled-components';

const FilterBlock = styled.div`
  & > ul {
    display: flex;
    & li {
    }
    & li:last-of-type {
      flex: 1;
      text-align: right;
      font-size: 14px;
      color: #8c939c;
      margin-left: 0;
    }
  }
`;

const Filter = ({ count, description }) => {
  return (
    <FilterBlock>
      <ul>
        <li>{`총 ${count} 건`}</li>
        <li>{description}</li>
      </ul>
    </FilterBlock>
  );
};

export default Filter;
