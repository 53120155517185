import React from 'react';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';

const MemInnerBlock = styled.div`
  // .mem_inner
  max-width: 920px;
  margin: 0 auto;
  & > div {
    // .mem_wrap
    width: 100%;
    margin: 0 auto;
    & > div {
      // .mem_cont2
      width: 700px;
      margin: 0 auto;
      padding-top: 50px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        width: 294px;
        padding-top: 0;
      }
    }
  }
`;

const ImageULBlock = styled.ul`
  // .mem_cont2 > ul
  display: block;
  text-align: center;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding: 16px 0 0;
  }
`;

const ImageListBlock = styled.li`
  // .mem_cont2 > ul > li
  display: inline-block;
  width: 336px;
  margin: 0 5px 20px 5px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    display: inline-block;
    width: 100%;
    margin: 0 0 6px 0;
    vertical-align: middle;
  }
`;

const ListImgItem = ({ children }) => {
  return (
    <MemInnerBlock>
      <div>
        <div>
          <ImageULBlock>
            {Array.isArray(children.props.children) ? (
              children.props.children.map((child, idx) => (
                <ImageListBlock key={`listItem_${idx}`}>{child}</ImageListBlock>
              ))
            ) : (
              <ImageListBlock>{children}</ImageListBlock>
            )}
          </ImageULBlock>
        </div>
      </div>
    </MemInnerBlock>
  );
};

export default ListImgItem;
