import React from 'react';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';

const PageInnerBlock = styled.div`
  // .mem_inner
  max-width: 980px;
  margin: 0 auto;
`;

const ContentWrapperBlock = styled.div`
  // .list_wrap
  max-width: 1020px;
  margin: 0 auto;
  padding: 60px 0 0;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin-top: 16px;
    padding: 0;
  }
`;

export const MemInnerBlock = styled.div`
  // .mem_inner
  max-width: 920px;
  margin: 0 auto;
  /* @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    width: 294px;
  } */
  & > div {
    // .mem_wrap
    width: 100%;
    margin: 0 auto;
    & > div {
      // .mem_cont2
      width: 700px;
      margin: 0 auto;
      padding-top: 50px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        padding-top: 0px;
        width: 294px;
      }
      & > ul {
        display: block;
        text-align: center;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          display: flex;
          align-items: center;
          padding: 16px 40px 0;
        }
        & > li {
          display: inline-block;
          width: 336px;
          margin: 0 5px 20px 5px;
          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            display: inline-block;
            flex: 1;
            margin: 0 0 16px 6px;
            vertical-align: middle;
            width: auto;
            &:first-of-type {
              margin-left: 0;
            }
          }
          & > button {
            position: relative;
            display: block;
            width: 100%;
            border-radius: 4px;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -ms-box-sizing: border-box;
            font-size: 27px;
            line-height: 1.5;
            letter-spacing: -0.5px;
            text-align: center;
            @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
              font-size: 16px;
            }
            & > p {
              margin-top: 2px;
              font-size: 17px;
              @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
                font-size: 12px;
              }
            }
          }
        }
      }
      & > ul:nth-of-type(2) {
        margin-top: 10%;
        & > li {
          color: #5b6676;
          font-size: 15px;
          transform: skew(-0.04deg);
          -webkit-transform: skew(-0.04deg);
          -moz-transform: skew(-0.04deg);
          -ms-transform: skew(-0.04deg);
          & > button {
            margin-top: 12px;
            height: 44px;
            border-radius: 22px;
            font-size: 15px;
          }
        }
      }
    }
  }
`;

const PageInner = ({ children }) => {
  return <PageInnerBlock>{children}</PageInnerBlock>;
};

export const PageInnerListWrap = ({ children }) => {
  return (
    <PageInnerBlock>
      <ContentWrapperBlock>{children}</ContentWrapperBlock>
    </PageInnerBlock>
  );
};

export const ListWrapBlock = ({ children }) => {
  return <ContentWrapperBlock>{children}</ContentWrapperBlock>;
};

export default PageInner;
