import React from 'react';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import { ModalProvider } from './context/ModalContext';
import InfoPage from './pages/brokerage/info/InfoPage';
import OverviewPage from './pages/brokerage/overview/OverviewPage';
import CorpServiceGuidePage from './pages/common/about/CorpServiceGuidePage';
import ServiceAboutPage from './pages/common/about/ServiceAboutPage';
import ServiceGuidePage from './pages/common/about/ServiceGuidePage';
import CertificatePage from './pages/common/certificate/CertificatePage';
import CertificateRegisterPage from './pages/common/certificate/CertificateRegisterPage';
import FaqPageRoute from './pages/common/faq/FaqPageRoute';
import InfoConroller from './pages/common/info/InfoController';
import LoginPage from './pages/common/login/LoginPage';
import PassCallbackPopup from './pages/common/login/PassCallbackPopup';
import PassCert from './pages/common/login/PassCert';
import PassSignupCallbackPopup from './pages/common/login/PassSignupCallbackPopup';
import MainPage from './pages/common/main/MainPage';
import BoardRegisterPage from './pages/common/notice/BoardRegisterPage';
import NoticePage from './pages/common/notice/NoticePage';
import PrivacyPage from './pages/common/privacy/PrivacyPage';
import QnaListPage from './pages/common/qna/QnaListPage';
import QnaViewPage from './pages/common/qna/QnaViewPage';
import QnaWritePage from './pages/common/qna/QnaWritePage';
import AgmSchedules from './pages/common/schedules/AgmSchedules';
import SignupContainer from './pages/common/signup/SignupContainer';
import SignupPage from './pages/common/signup/SignupPage';
import IssueIncorporated from './pages/IssueIncorporated/contract/IssueIncorporated';
import CorpDashboardPage from './pages/IssueIncorporated/dashboard/CorpDashboardPage';
import AgmPage from './pages/IssueIncorporated/management/AgmPage';
import DocPage from './pages/IssueIncorporated/management/elecBill/main/DocPage';
import AgmRegister from './pages/IssueIncorporated/register/AgmRegister';
import ShareholderCertificationPage from './pages/shareholder/certification/ShareholderCertificationPage';
import ExerciseRecordPage from './pages/shareholder/exerciseRecord/ExerciseRecordPage';
import { ExerciseMethod } from './pages/shareholder/exerciseRecord/main/BoxInfo';
import MyAgmsPage from './pages/shareholder/myAgms/MyAgmsPage';
import VoteResultPage from './pages/shareholder/result/VoteResultPage';
import VotePage from './pages/shareholder/vote/VotePage';
import DataTableTest from './pages/test/Test/page/DataTableTest';
import FetchTest from './pages/test/Test/page/FetchTest';
import PageTest from './pages/test/Test/page/PageTest';
import PageTest2 from './pages/test/Test/page/PageTest2';
import RadioTest from './pages/test/Test/page/RadioTest';
import SignTest from './pages/test/Test/page/SignTest';
import Test from './pages/test/Test/page/Test';
import { fetchGetSeccoAuthorizationAction } from './services/api/brokerage/promiseActions';
import { action as commonAction } from './store/commonSlice';
import createStore from './store/index';
import { fontFaces } from './styles/Fonts';
import GlobalStyles from './styles/GlobalStyles';

// 증권사 번호 세팅
const store = createStore();
function AppWrapper() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}
function App() {
  const dispatch = useDispatch();
  dispatch(fetchGetSeccoAuthorizationAction()).then((res) => {
    if (!res.error) {
      dispatch(
        commonAction.setAuthorization({
          methods: res.data.methods,
          didUseMediaList: res.data.didUseMediaList,
          didUseForce: res.data.didUseForce
        })
      );
    }
  });
  return (
    <>
      <GlobalStyles />
      <style>{fontFaces}</style>
      {/*
      <RefreshToken />
      */}
      <ModalProvider>
        <BrowserRouter>
          <AuthRoute>
            <Switch>
              {/* 비로그인 */}
              <Route exact path="/" component={MainPage} />
              <Route path="/about" component={ServiceAboutPage} />
              <Route path="/shareholder-guide" component={ServiceGuidePage} />
              <Route
                path="/issue-incorporated-guide"
                component={CorpServiceGuidePage}
              />
              <Route path="/certificate" component={CertificatePage} />
              <Route
                path="/certificate-register"
                component={CertificateRegisterPage}
              />
              {/* 고객센터 */}
              <Route path="/notice" component={NoticePage} />
              <Route path="/faq" component={FaqPageRoute} />
              <Route exact path="/qna" component={QnaListPage} />
              <Route exact path="/qna/write" component={QnaWritePage} />
              <Route path="/qna/:notientSeqno" component={QnaViewPage} />
              <Route path="/privacy/:prevNo?" component={PrivacyPage} />

              <Route
                path="/board/register/:boardSeqno"
                component={BoardRegisterPage}
              />

              {/* 주주총회 일정 */}
              <Route path="/agm-schedules" component={AgmSchedules} />

              {/* @가입 컨테이너 */}
              <Route exact path="/signup" component={SignupContainer} />
              <Route path="/signup" component={SignupPage} />

              {/* 로그인 */}
              <Route path="/login" component={LoginPage} />
              {/* 주주 */}
              {/* 주주 - 주주총회 일정 */}
              <Route
                path="/shareholder/agm-schedules"
                component={AgmSchedules}
              />
              {/* 주주 - 나의 주주총회 */}
              <Route path="/shareholder/my-agms" component={MyAgmsPage} />
              {/* 주주 - 주주증명 발급 */}
              <Route
                path="/certification"
                component={ShareholderCertificationPage}
              />
              {/* 주주 - 투표행사 */}
              <Route path="/shareholder/agm/:agmSeqno/vote">
                <VotePage exerciseMethod={ExerciseMethod.VOTE} />
              </Route>
              {/* 주주 - 전자위임 */}
              <Route path="/shareholder/agm/:agmSeqno/proxy">
                <VotePage exerciseMethod={ExerciseMethod.PROXY} />
              </Route>
              {/* 주주 - 행사내역 */}
              <Route
                path="/shareholder/agm/:agmSeqno/exercise-record"
                component={ExerciseRecordPage}
              />
              {/* 주주 - 결과 */}
              <Route
                path="/shareholder/agm/:agmSeqno/vote-result"
                component={VoteResultPage}
              />
              {/* 주주 - 행사내역 */}
              {/* <Route
                path="/shareholder/agm/:agmSeqno/exercise-record"
                component={ExerciseRecordPage}
              /> */}

              {/* 발행회사 */}

              {/* 계약 신청 */}
              <Route path="/corp/contract" component={IssueIncorporated} />
              {/* 주총 등록 */}
              <Route
                path="/corp/agm/register/:step"
                component={AgmRegister}
                exact
              />
              {/* 주총 등록 & 수정 */}
              <Route
                path="/corp/agm/:agmSeqno/register/:step"
                component={AgmRegister}
                exact
              />

              {/* 주총 등록 테스트 */}
              <Route
                path="/corp/agm/register/:step/test"
                component={AgmRegister}
              />
              {/* 주총 등록 & 수정 테스트 */}
              <Route
                path="/corp/agm/:agmSeqno/register/:step/test"
                component={AgmRegister}
              />

              {/* 대시 보드 */}
              <Route path="/corp/agm/dashboard" component={CorpDashboardPage} />

              {/* @발행회사 주주총회 컨테이너 */}
              <Route
                path="/corp/agm/info/:agmSeqno/:agmMenu"
                component={AgmPage}
              />

              {/* 증권사 페이지 */}
              {/* 발행회사 정보 조회 */}

              <Route path="/brokerage/issuer-info" component={InfoPage} />

              {/* 전자주총 현황 조회 */}

              <Route path="/brokerage/overview" component={OverviewPage} />

              {/* 서비스 관리 */}

              {/* <Route path="/brokerage/manage" component={ManagePage} /> */}

              <Route path="/:auth/info" component={InfoConroller} />

              {/* 테스트 페이지 */}
              <Route path="/test" component={Test} />
              <Route path="/page" component={PageTest} />
              <Route path="/page2" component={PageTest2} />
              <Route path="/datatable" component={DataTableTest} />
              <Route path="/radio" component={RadioTest} />
              <Route path="/fetch" component={FetchTest} />
              <Route path="/signTest" component={SignTest} />

              <Route path="/vote" component={VotePage} />
              <Route path="/vote-result" component={VoteResultPage} />
              <Route
                path="/pass/login/callback"
                component={PassCallbackPopup}
              />
              <Route
                path="/pass/signup/callback"
                component={PassSignupCallbackPopup}
              />
              <Route exact path="/pass/cert" component={PassCert} />
              <Route exact path="/doc" component={DocPage} />
            </Switch>
          </AuthRoute>
        </BrowserRouter>
      </ModalProvider>
    </>
  );
}

export default AppWrapper;
