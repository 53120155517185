import React from 'react';
import Input from '../../../../../components/input/Input';
import { formatNumber } from '../../../../../utils/StringUtil';
import ResultTableSelect from '../../../register/main/components/ResultTableSelect';
import { getAgendaDisplayName, getDivStyle } from '../AgmResult';

const ConcentrateTable = ({ data, onChangeReuslt, onChangeCount, isEdit }) => {
  console.log('ConcentratTable Props');
  console.log(data);
  if (!data) return <>glglglg</>;

  return (
    <div className="tblcommon-01" style={getDivStyle(data.agenda)}>
      <table>
        <colgroup>
          <col width="*%;" />
          <col width="19%;" />
          <col width="19%" />
          <col width="19%;" />
          <col width="19%" />
        </colgroup>
        <thead>
          <tr>
            <th colSpan="5" className="title atv">
              {getAgendaDisplayName(data.agenda)}
              <span className="stock">
                행사 가능 주식수
                <strong className="ft-col02 ">
                  {formatNumber(data.agenda.exercisableStockQuantity)}주
                </strong>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>
            <th>전자투표결과(a)</th>
            <th>현장투표결과(b)</th>
            <th colSpan="2">전체결과(a+b)</th>
          </tr>
          {data.childs.map((child, idx) => {
            if (child.agenda.agendaSeqno === -1) return null;
            return (
              <tr key={`ConcentrateTable-Tr-${idx}`}>
                <th>{child.agenda.agendaName}</th>
                <td className="nRight">
                  {formatNumber(child.electronicVoteValue?.ayesCount)}
                </td>
                <td className="nRight">
                  {isEdit ? (
                    <Input
                      type="number"
                      onChange={(e) =>
                        onChangeCount(
                          e.target.value,
                          data.agenda.agendaSeqno,
                          child.agenda.agendaSeqno,
                          'ayesCount'
                        )
                      }
                      value={child.spotValue?.ayesCount}
                    />
                  ) : (
                    formatNumber(child.spotValue?.ayesCount)
                  )}
                </td>
                <td className="nRight">
                  {formatNumber(child.totalValue?.ayesCount)}
                </td>
                <td>
                  <ResultTableSelect
                    onChange={onChangeReuslt}
                    parentAgendaSeqno={data.agenda.agendaSeqno}
                    agendaSeqno={child.agenda.agendaSeqno}
                    seletcedValue={child.result}
                    disabled={!isEdit}
                    isEdit={isEdit}
                  />
                </td>
              </tr>
            );
          })}
          <tr>
            <th>기권</th>
            <td className="nRight">
              {formatNumber(
                data.childs.find((child) => child.agenda.agendaSeqno === -1)
                  .electronicVoteValue?.ayesCount
              )}
            </td>
            <td className="nRight">
              {isEdit ? (
                <Input
                  type="number"
                  onChange={(e) =>
                    onChangeCount(
                      e.target.value,
                      data.agenda.agendaSeqno,
                      -1,
                      'ayesCount'
                    )
                  }
                  value={
                    data.childs.find((child) => child.agenda.agendaSeqno === -1)
                      .spotValue?.ayesCount
                  }
                />
              ) : (
                formatNumber(
                  data.childs.find((child) => child.agenda.agendaSeqno === -1)
                    .spotValue?.ayesCount
                )
              )}
            </td>
            <td className="nRight">
              {formatNumber(
                data.childs.find((child) => child.agenda.agendaSeqno === -1)
                  .totalValue?.ayesCount
              )}
            </td>

            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ConcentrateTable;
