import React from 'react';
import Button from '../../../../components/button/Button';

const SignTest = (props) => {
  const idpwsign = () => {
    document.getElementById('layerBg').style.display = 'block';
    const ifrm = document.getElementById('ifrm');
    const content = ifrm.contentWindow;
    if (typeof content.idpwsign === 'function') {
      content.idpwsign();
    } else {
      console.log('함수가 아닙니다..');
    }
  };

  return (
    <>
      <div id="layerBg" style={layerBgStyle}></div>
      <iframe
        id="ifrm"
        style={iframeStyle}
        src={`${process.env.PUBLIC_URL}/modules/signkorea/app/index.html`}
        title="singIframe"
      ></iframe>
      <input
        type="hidden"
        value=""
        id="signDataInput"
        onInput={(e) => {
          props.handleSubmit((data) => props.callback(data, e.target.value))();
        }}
      />
      <Button type={props.type} size={props.size} onClick={idpwsign}>
        {props.title}
      </Button>
    </>
  );
};

const layerBgStyle = {
  position: 'fixed',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
  'z-index': 10,
  'background-color': 'rgb(0, 0, 0)',
  opacity: 0.72,
  display: 'none'
};

const iframeStyle = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  'z-index': 11,
  border: 'none',
  width: '430px',
  height: '530px',
  margin: 'auto'
};

export default SignTest;
