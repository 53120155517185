import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { idpwsign, SIGN_TYPE } from '../../../components/auth/SignIframe';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import Section from '../../../components/layout/Section';
import { MODAL_SIZE } from '../../../components/modal/Modal';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import PageTab from '../../../components/tabs/PageTab';
import { ModalContext } from '../../../context/ModalContext';
import { useAuth } from '../../../hooks/useAuth';
import { fetchPostFilesTemporaryAction } from '../../../services/api/files/promiseActions';
import {
  fetchGetIssueIncorporatedAction,
  fetchGetIssueIncorporatedFilesAction,
  fetchPutIssueIncorporatedAction
} from '../../../services/api/issueIncorporated/promiseActions';
import { Role } from '../../../utils/constant/AgmConstant';
import { getCurrentDatetime } from '../../../utils/DateUtil';
import { AgreeType } from '../../IssueIncorporated/contract/IssueIncorporated';
import CorporationInfo from '../../test/Test/page/CorporationInfo';
import CorporationManager from '../../test/Test/page/CorporationManager';
import ManagerPwChangeForm from '../../test/Test/page/ManagerPwChangeForm';

const IssincInfo = () => {
  let { openModal, setModalConfig } = useContext(ModalContext);
  const role = useSelector((state) => state.common.user.role);
  const itemArr = ['발행회사', '담당자', '비밀번호 변경'];
  const viewItemArr = ['발행회사', '담당자'];
  const [fileUploadedData, setFileUploadedData] = useState([]);
  const [dataObj, setDataObj] = useState();
  const [data, setData] = useState();
  const [activeTabNum, setActiveTabNum] = useState(0);
  const dispatch = useDispatch();
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm();
  const { changeRole } = useAuth();

  /* 동의 관련 데이터 */
  const [agreeTimeMap, setAgreeTimeMap] = useState({
    [AgreeType.CELLPH_NOTI_AGREE_DTTM]: null,
    [AgreeType.EMAIL_NOTI_AGREE_DTTM]: null,
    [AgreeType.MARKTN_AGREE_DTTM]: getCurrentDatetime(),
    [AgreeType.UTIL_CLAUSE_AGREE_DTTM]: getCurrentDatetime(),
    [AgreeType.PRIVACY_POLICY_AGREE_DTTM]: getCurrentDatetime()
  });

  useEffect(() => {
    dispatch(fetchGetIssueIncorporatedAction()).then((res) => {
      if (!res.error) {
        console.log('res');
        console.log(res);
        const data = res.data;
        setData(data);
        setFileUploadedData(data.files);
        setDataObj({
          ...data,
          fullAddress:
            data.baseAddress === data.detailAddress
              ? data.baseAddress
              : data.baseAddress + ' ' + data.detailAddress
        });
      }
    });
  }, [dispatch]);

  useEffect(() => {
    reset(dataObj);
  }, [dataObj, reset]);

  const handleFile = (fileType, file) => {
    const fd = new FormData();
    fd.append('file', file);

    dispatch(fetchPostFilesTemporaryAction(fd))
      .then((res) => {
        const fileData = {
          ...res.data,
          issueIncorporatedFileType: fileType
        };
        setFileUploadedData([...fileUploadedData, fileData]);
      })
      .catch((e) => console.log(e));
  };

  const onFetchFiles = (code) => {
    dispatch(fetchGetIssueIncorporatedFilesAction({ fileCd: code })).then(
      (value) => {
        var fileName = value.meta.headers['content-disposition']
          .split("''")[1]
          .split(';')[0];
        fileDownload(value.data, decodeURI(fileName));
      }
    );
  };
  const fileDownload = (data, fileName) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', decodeURI(fileName));
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  const onUpdate = (signData, payload) => {
    setModalConfig({
      size: MODAL_SIZE.SHORT
    });
    const updateData = {
      signData,
      snData: payload.bizRegistrationNumber
    };
    dispatch(fetchPutIssueIncorporatedAction(updateData)).then((res) => {
      if (!res.error) {
        openModal('확인', '정보 수정이 정상적으로 처리되었습니다');
      } else {
        openModal('확인', res.error.user_message);
      }
    });
  };

  const onChangeTabNum = (num) => {
    setActiveTabNum(num);
  };

  useEffect(() => {
    console.error(agreeTimeMap);
  }, [agreeTimeMap]);

  const openSignModal = (handler, payload) => {
    console.log('payload');
    console.log(payload);
    setTimeout(() => {
      console.log('agreeTimeMap');
      console.log(agreeTimeMap);
      idpwsign(SIGN_TYPE.CORP, handler, {
        ...payload,
        ...agreeTimeMap,
        personInCharge: {
          ...payload.personInCharge,
          ...agreeTimeMap
        },
        files: fileUploadedData
      });
    }, 0);
  };

  const onClickUpdateButton = () => {
    if (role === Role.ISSINC_ADMIN.code) {
      handleSubmit((data) => openSignModal(onUpdate, data))();
    } else if (role === Role.VIEW_ISSINC_ADMIN.code) {
      changeRole(Role.ISSINC_ADMIN.code);
    }
  };

  return (
    <Page>
      <PageHeader title="내 정보 수정" />
      <PageTab
        itemArr={role === Role.ISSINC_ADMIN.code ? itemArr : viewItemArr}
        onChange={onChangeTabNum}
      />
      <Section>
        {activeTabNum === 0 && (
          <CorporationInfo
            register={register}
            handleFile={handleFile}
            setFileUploadedData={setFileUploadedData}
            onFetchFiles={onFetchFiles}
            setValue={setValue}
            dataObj={dataObj}
            data={data}
            watch={watch}
            errors={errors}
          />
        )}
        {activeTabNum === 1 && (
          <CorporationManager
            register={register}
            agreeTimeMap={agreeTimeMap}
            setAgreeTimeMap={setAgreeTimeMap}
          />
        )}
        {activeTabNum === 2 && (
          <ManagerPwChangeForm
            register={register}
            stateMsg={'변경이 완료되었습니다'}
          />
        )}
      </Section>
      <ButtonGroup isPage>
        {activeTabNum !== 2 && (
          <Button
            type={ButtonTypes.Primary}
            size={ButtonSize.Large}
            onClick={onClickUpdateButton}
          >
            {role === Role.ISSINC_ADMIN.code && '수정'}
            {role === Role.VIEW_ISSINC_ADMIN.code && '공동인증서 인증'}
          </Button>
        )}
      </ButtonGroup>
    </Page>
  );
};

export default IssincInfo;
