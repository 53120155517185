import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';
import DataTable, {
  DataType
} from '../../../../components/datatable/DataTable';
import { TableTitleBlock } from '../../../../components/table/TableTitle';
import { useFileDownload } from '../../../../hooks/useFileDownload';
import {
  fetchGetAgmLimitsAction,
  fetchGetAgmLimitsPageAction,
  fetchGetAgmOutlineAction,
  fetchGetAgmVotingRightsTotalAction,
  fetchGetFilesAgmAction,
  fetchGetFilesAgmAgendaAction
} from '../../../../services/api/agm/promiseActions';
import {
  AgendaCategory,
  AgentOrganizationType,
  AgmFileType,
  getConstNameByCode
} from '../../../../utils/constant/AgmConstant';
import {
  DateFormatName,
  getDateFormat
} from '../../../../utils/DateFormatUtil';
import { DATE_FORMAT } from '../../../../utils/DateUtil';
import { checkUndefinedNum, formatNumber } from '../../../../utils/StringUtil';
import { ListWrapBlock } from '../../dashboard/CorpDashboardPage';
import StockholdersList from '../../register/main/contents/StockholdersList';

const AgmShortDesc = ({ baseInfo }) => {
  const match = useRouteMatch();
  const { agmSeqno } = match.params;
  const [agmInfo, setAgmInfo] = useState();
  const dispatch = useDispatch();
  const [limits, setLimits] = useState([]);
  const [agendas, setAgendas] = useState();
  const { callDownloadFetchAPIParam, callDownloadFetchAPIAgmParam } =
    useFileDownload();
  const [limitsPagingInfo, setLimitsPagingInfo] = useState({});

  useEffect(() => {
    dispatch(fetchGetAgmOutlineAction({ agmSeqno }))
      .then((res) => {
        if (!res.error) {
          setAgmInfo(res.data);
        }
      })
      .then((_) => {
        dispatch(fetchGetAgmLimitsAction({ agmSeqno }))
          .then((res) => {
            if (!res.error) {
              setLimits(res.data.votingRightsLimits);
            }
          })
          .then((_) => {
            fetchGetAgmLimits();
          });
      });
    dispatch(fetchGetAgmVotingRightsTotalAction({ agmSeqno })).then((res) => {
      if (!res.error) {
        setAgendas(res.data);
      }
    });
  }, [agmSeqno, dispatch]);

  const fetchGetAgmLimits = (pageNumber = 0) => {
    const data = {
      pageNumber: pageNumber,
      size: 10
    };
    dispatch(fetchGetAgmLimitsPageAction({ agmSeqno, data })).then((res) => {
      if (!res.error) {
        setLimits(res.data.votingRightsLimits);
        const { pageNumber, size, totalCount, totalPageNumber } = res.data;
        setLimitsPagingInfo({
          pageNumber,
          size,
          totalCount,
          totalPageNumber
        });
      }
    });
  };

  const agmInfoColumn = [
    {
      title: '구분',
      key: 'agmType',
      col: '10%'
    },
    {
      title: '기준일',
      key: 'agmRecordDate',
      col: '15%'
    },
    {
      title: '기수',
      key: 'agmSessionCount',
      col: '10%'
    },
    {
      title: '주총일시',
      key: 'agmDateTime',
      col: '18%'
    },
    {
      title: '장소',
      key: 'agmPlace',
      col: '*',
      align: 'left'
    }
  ];

  const getInfoDataSource = () => {
    const newArr = [];
    newArr.push({
      ...agmInfo,
      agmType: agmInfo.agmType === '1' ? '정기' : '임시',
      agmSessionCount: !!agmInfo.agmSessionCount
        ? `제 ${agmInfo.agmSessionCount}회`
        : '',
      agmDateTime: DATE_FORMAT(
        agmInfo.agmDateTime,
        getDateFormat(DateFormatName.SHORT_DATE_OTHER_2)
      )
    });
    return newArr;
  };

  const agmFilesColumn = [
    {
      title: '전자투표이사회결의서',
      key: 'resolutionFile'
    },
    {
      title: '소집공고문',
      key: 'announcementFile'
    },
    {
      title: '사업보고서',
      key: 'businessReportFile'
    },
    {
      title: '감사보고서',
      key: 'auditReportFile'
    },
    {
      title: 'IR자료',
      key: 'irFile'
    }
  ];

  const agmDatesColumn = [
    {
      title: '신청서비스',
      key: 'useServiceType',
      col: '40%'
    },
    {
      title: '전자투표 / 전자위임장 수여 일시',
      key: 'voteProxyDate',
      col: '*'
    }
  ];

  const getElecMethod = (voteUse, proxyUse) => {
    if (voteUse && proxyUse) return '전자투표, 전자위임장';
    else if (!voteUse && proxyUse) return '전자위임장';
    else if (voteUse && !proxyUse) return '전자투표';
  };

  const getDatesDataSource = () => {
    return [
      {
        useServiceType: getElecMethod(
          agmInfo.electronicVoteUse,
          agmInfo.electronicProxyUse
        ),
        voteProxyDate: `${DATE_FORMAT(
          baseInfo.voteBeginDateTime,
          getDateFormat(DateFormatName.LONG_DATE_TIME)
        )} ~ ${DATE_FORMAT(
          baseInfo.voteEndDateTime,
          getDateFormat(DateFormatName.LONG_DATE_TIME)
        )}`
      }
    ];
  };

  const onClickFileDownload = (file) => {
    callDownloadFetchAPIParam(file, fetchGetFilesAgmAction, agmSeqno);
  };

  const onClickFileDownload2 = (file, agendaSeqno) => {
    callDownloadFetchAPIAgmParam(
      file,
      fetchGetFilesAgmAgendaAction,
      agmSeqno,
      agendaSeqno
    );
  };

  const fileDownColumn = (name, file) => {
    return (
      <>
        <FileButtonBlock onClick={() => onClickFileDownload(file)}>
          {file?.fileName?.lastIndexOf('.') > 11
            ? file.fileName.substring(0, 15) +
              '… ' +
              file.fileName.substring(file.fileName.lastIndexOf('.'))
            : file?.fileName}
        </FileButtonBlock>
      </>
    );
  };

  const getFilesDataSource = () => {
    const newArr = [{}];
    agmInfo.files.forEach((file) => {
      switch (file.type) {
        case AgmFileType.RESOLUTION.code:
          // newArr[0].resolutionFile = file.fileName;
          newArr[0].resolutionFile = fileDownColumn(
            AgmFileType.RESOLUTION.name,
            file
          );
          break;
        case AgmFileType.ANNOUNCEMENT.code:
          newArr[0].announcementFile = fileDownColumn(
            AgmFileType.ANNOUNCEMENT.name,
            file
          );
          break;
        case AgmFileType.BUSINESS_REPORT.code:
          newArr[0].businessReportFile = fileDownColumn(
            AgmFileType.BUSINESS_REPORT.name,
            file
          );
          break;
        case AgmFileType.AUDIT_REPORT.code:
          newArr[0].auditReportFile = fileDownColumn(
            AgmFileType.AUDIT_REPORT.name,
            file
          );
          break;
        case AgmFileType.IR.code:
          newArr[0].irFile = fileDownColumn(AgmFileType.IR.name, file);
          break;
        default:
          break;
      }
    });
    return newArr;
  };

  const agmAgendaColumn = [
    {
      title: '의안번호',
      key: 'agendaNumber',
      col: '12%'
    },
    {
      title: '안건명',
      key: 'agendaName',
      col: '*'
    },
    {
      title: '상세내용',
      key: 'agendaDetailContent',
      col: '24%'
    },
    {
      title: '행사가능 주식수',
      key: 'exercisableStockAmmount',
      col: '13%',
      type: DataType.NUMBER
    },
    {
      title: '분리여부',
      key: 'childAgendaCount',
      col: '13%'
    },

    {
      title: '안건추가자료',
      key: 'fileName',
      col: '20%'
    }
  ];

  const getAgendaDataSource = () => {
    if (!!Object.keys(agmInfo).length) {
      const newArr = [];
      for (const agenda of agmInfo.agendas) {
        const file = {
          filePath: agenda.filePath,
          fileName: agenda.fileName
        };
        // 분리의안 여부 구분
        if (!!agenda.childs?.length && !agenda.concentrateVote) {
          agenda.childs.forEach((item) => {
            newArr.push({
              ...item,
              agendaNumber: `제 ${item.agendaNumber}호`,
              agendaName: getConstNameByCode(
                AgendaCategory,
                item.agendaCategory
              ),
              agendaDetailContent: item.agendaName,
              childAgendaCount:
                agenda.childAgendaCount === 0 ? '비분리' : '분리',
              exercisableStockAmmount: getExercisableStockAmmount(item),
              fileName: (
                <FileButtonBlock
                  onClick={() => onClickFileDownload2(file, agenda.agendaSeqno)}
                >
                  {agenda.fileName}
                </FileButtonBlock>
              )
            });
          });
        } else {
          newArr.push({
            ...agenda,
            agendaNumber: `제 ${agenda.agendaNumber}호`,
            childAgendaCount:
              agenda.childAgendaCount !== 0 && !agenda.concentrateVote
                ? '분리'
                : '비분리',
            exercisableStockAmmount: getExercisableStockAmmount(agenda),
            fileName: (
              <FileButtonBlock
                onClick={() => onClickFileDownload2(file, agenda.agendaSeqno)}
              >
                {agenda.fileName}
              </FileButtonBlock>
            )
          });
        }
      }
      return newArr;
    }
  };

  const getExercisableStockAmmount = (target) => {
    if (!!agendas) {
      const agenda = agendas.totalVotingRights.find(
        (item) => item.agendaSeqno === target?.agendaSeqno
      );
      return agenda?.exercisableStockAmmount;
    }
  };

  const agmStockholderColumn = [
    {
      title: '주주명부 총 주식수',
      key: 'totalIssueStockQuantity',
      type: DataType.NUMBER
    },
    {
      title: '총 발행주식수',
      key: 'totalVotingRightsStockQuantity',
      type: DataType.NUMBER
    },
    {
      title: '일치여부',
      key: 'stockQuantityEqualState'
    },
    {
      title: '상태',
      key: 'stockQuantityResultState'
    },
    {
      title: '명의개서 대행기관',
      key: 'agentOrganizationType'
    }
  ];

  const getStockInfoDataSource = () => {
    const newArr = [];
    newArr.push({
      totalIssueStockQuantity: agmInfo.totalIssueStockQuantity,
      totalVotingRightsStockQuantity: agmInfo.totalIssueStockQuantity,
      stockQuantityEqualState: '일치',
      stockQuantityResultState: '정상',
      agentOrganizationType: getConstNameByCode(
        AgentOrganizationType,
        agmInfo.agentOrganizationType
      )
    });
    return newArr;
  };

  const agmLimitsColumn = [
    {
      title: '제한사유',
      key: 'votingRightsLimitContent',
      col: '*',
      align: 'left'
    },
    {
      title: '적용안건',
      key: 'agendaSeqno',
      col: '*',
      align: 'left'
    },
    {
      title: '주민/사업자 등록번호',
      key: 'stockholderRealNameNumber',
      col: '15%'
    },
    {
      title: `의결권 있는 주식 수`,
      key: 'totalExercisableStockAmount',
      col: '12%',
      align: 'right'
    },
    {
      title: '의결권 없는 주식 수',
      key: 'noVotingRightsStockAmmount',
      col: '12%',
      align: 'right',
      type: DataType.NUMBER
    },
    {
      title: '의결권 제한 주식 수',
      key: 'votingRightsLimitStockAmmount',
      col: '12%',
      align: 'right',
      type: DataType.NUMBER
    },
    {
      title: '행사가능 주식 수',
      key: 'exercisableStockAmmount',
      col: '12%',
      align: 'right',
      type: DataType.NUMBER
    }
  ];

  const getLimitsDataSource = () => {
    const newArr = [];
    if (limits.length !== 0) {
      for (const limit of limits) {
        let agenda =
          limit.agendaSeqno === 0
            ? { agendaName: '전체' }
            : agmInfo.agendas.find(
                (agenda) => limit.agendaSeqno === agenda.agendaSeqno
              );
        newArr.push({
          ...limit,
          totalExercisableStockAmount: formatNumber(
            limit.commonStockAmount +
              checkUndefinedNum(limit.exercisablePreferredStockAmount)
          ),
          agendaSeqno: agenda?.agendaName
        });
      }
      return newArr;
    }
  };

  return (
    <>
      <ListWrapBlock>
        <TableTitleBlock>
          <span>주주총회 정보</span>
        </TableTitleBlock>
        {!!agmInfo && (
          <>
            <DataTable
              columns={agmInfoColumn}
              dataSource={getInfoDataSource()}
              shortHeight
            />
            <DataTable
              columns={agmDatesColumn}
              dataSource={getDatesDataSource()}
              shortHeight
            />
            <DataTable
              columns={agmFilesColumn}
              dataSource={getFilesDataSource()}
              shortHeight
            />
          </>
        )}

        <TableTitleBlock>
          <span>안건 정보</span>
        </TableTitleBlock>
        {!!agmInfo && (
          <DataTable
            columns={agmAgendaColumn}
            dataSource={getAgendaDataSource()}
            shortHeight
          />
        )}

        <TableTitleBlock>
          <span>주주명부 정보</span>
        </TableTitleBlock>
        {!!agmInfo && (
          <DataTable
            columns={agmStockholderColumn}
            dataSource={getStockInfoDataSource()}
            shortHeight
          />
        )}

        <TableTitleBlock>
          <span>의결권 제한 내역 정보</span>
        </TableTitleBlock>
        {/* {limits.length !== 0 && ( */}
        <DataTable
          columns={agmLimitsColumn}
          dataSource={getLimitsDataSource()}
          shortHeight
          pageConfiog={limitsPagingInfo}
          pageConfig={{
            ...limitsPagingInfo,
            onClick: fetchGetAgmLimits
          }}
        />

        <TableTitleBlock>
          <span>투표권자 명부</span>
        </TableTitleBlock>
        {!!baseInfo && !!agendas && (
          <StockholdersList
            agmSeqno={agmSeqno}
            baseInfo={baseInfo}
            agendas={agendas}
          />
        )}
      </ListWrapBlock>
    </>
  );
};

const FileButtonBlock = styled.a`
  border-bottom: 1px solid #0572ef;
  cursor: pointer;
`;

export default AgmShortDesc;
