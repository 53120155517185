import React, { useState } from 'react';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import GuidePageTab from '../../../components/tabs/GuidePageTab';
import SignUp from './main/contents/stockholder/SignUp';
import VoteAndProxy from './main/contents/stockholder/VoteAndProxy';
import VoteResult from './main/contents/stockholder/VoteResult';

const ServiceGuidePage = () => {
  const tabArr = Object.values(Tab_Const);
  const [selTab, setSelTab] = useState(0);

  const onChangeTab = (index) => {
    setSelTab(index);
  };

  return (
    <Page extend>
      {<PageHeader title="주주 서비스 이용안내" />}
      <GuidePageTab itemArr={tabArr} onChange={onChangeTab} />
      {selTab === 0 && <SignUp title={Tab_Const.signup} />}
      {selTab === 1 && <VoteAndProxy title={Tab_Const.voteProxy} />}
      {selTab === 2 && <VoteResult title={Tab_Const.voteResult} />}
    </Page>
  );
};

const Tab_Const = {
  signup: '회원가입',
  voteProxy: '투표진행/전자위임',
  voteResult: '투표 결과보기'
};

export default ServiceGuidePage;
