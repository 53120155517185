import PropTypes from 'prop-types'; // ES6
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { ModalContext } from '../../context/ModalContext';
import SubModal from '../../pages/IssueIncorporated/register/step4/main/SubModal';
import { fetchPostFilesTemporaryAction } from '../../services/api/files/promiseActions';
import spriteCommonPng from '../../styles/images/common/sprite/sp_common.png';
import { EMPTY_FUNCTION } from '../../utils/FunctionUtil';
import FileDownButton from '../button/FileDownButton';
import { MODAL_SIZE } from '../modal/Modal';
import Input from './Input';

const InputWrapperBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
`;

const ButtonBlock = styled.button`
  // .btn_i03.notxt {
  padding-left: 15px;
  padding-right: 16px;
  min-width: 55px;
  height: 42px;

  display: inline-block;
  padding: 12px 20px 11px 20px;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 1.33;
  letter-spacing: -0.5px;
  text-align: center;

  // .btn_i03.active {
  ${(props) =>
    props.uploadedFile
      ? css`
          background-color: #0572ef;
        `
      : css`
          background-color: #9eb3cd;
        `}

  // .btn_i03.active::before {
  &::before {
    content: '';
    position: absolute;
    top: 36%;
    right: 22px;
    display: block;
    width: 24px;
    height: 26px;
    margin-top: -5.5px;
    background: url(${spriteCommonPng}) no-repeat;
    background-size: 400px auto;
    ${(props) =>
      props.uploadedFile
        ? css`
            background-position: -33px -121px;
          `
        : css`
            background-position: -33px -95px;
          `}

    //.btn_i03.notxt::before {
    right: 16px;
  }
`;

const FileInput = (props) => {
  const { placeholder, setFile, extension, isPopup, fileDownload, onDelete } =
    props;

  const dispatch = useDispatch();
  const hiddenFileInputRef = useRef();
  const [fileInfo, setFileInfo] = useState(); // 파일 인포
  const [fileName, setFileName] = useState(''); // input 에 들어갈 value
  const [uploadedFile, setUploadedFile] = useState(false);
  const [subModalOpen, setSubModalOpen] = useState(false);
  const { openModal } = useContext(ModalContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const defaultErrorMsg =
    '문서(docx, hwp, pdf) 및 이미지(png, gif, jpeg, jpg) 파일만 업로드 가능합니다.';
  const overFileSizeMsg = '첨부파일 용량이 초과되었습니다. (최대 20MB)';
  useEffect(() => {
    if (!!setFile) {
      setFileInfo(setFile);
      setFileName(setFile.fileName);
      setUploadedFile(true);
    }
  }, [setFile]);

  const clearFile = () => {
    if (uploadedFile) {
      setFileName(fileName);
    } else {
      setFileName('');
      setUploadedFile(false);
    }
    hiddenFileInputRef.current.value = null;
  };

  useEffect(() => {
    if (errorMessage) {
      if (isPopup) {
        setSubModalOpen(true);
      } else {
        openModal('파일 확장자 오류', errorMessage);
      }
      clearFile();
    }
  }, [errorMessage]);

  const handleClick = (event) => {
    hiddenFileInputRef.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      if (extension) {
        const ext = fileUploaded.name
          .substring(
            fileUploaded.name.lastIndexOf('.') + 1,
            fileUploaded.name.length
          )
          .toLowerCase();

        if (Array.isArray(extension)) {
          if (!extension.includes(ext)) {
            //errorModalOpen(defaultErrorMsg);
            setErrorMessage(defaultErrorMsg);
            return;
          }
        } else {
          if (extension !== ext) {
            //errorModalOpen(defaultErrorMsg);
            setErrorMessage(defaultErrorMsg);
            return;
          }
        }

        // 파일 용량 체크
        // TODO 임시로 용랑 체크 (20MB)
        if (fileUploaded.size > 20 * 1000 * 1000) {
          setErrorMessage(overFileSizeMsg);

          return;
        }
      }
      // 원래는 여기 있었으나, 서버 check도 포함해야 하기에 fetch 성공했을때로 변경.
      // setFileName(fileUploaded.name);
      // setUploadedFile(true);

      fetchTemporaryFile(props.fileType, fileUploaded);
    }
  };

  const fetchTemporaryFile = (type, file) => {
    const fd = new FormData();
    fd.append('file', file);
    dispatch(fetchPostFilesTemporaryAction(fd)).then((res) => {
      if (!res.error) {
        // 여기로 이동
        setFileName(file.name);
        setUploadedFile(true);

        const fileData = {
          ...res.data,
          type: type
        };
        setFileInfo(fileData);
        props.callback(fileData, type);
      } else {
        // setModalConfig({ size: MODAL_SIZE.SHORT });
        setErrorMessage(res.error?.user_message);
        // clearFile();
      }
    });
  };

  const clickModalClose = () => {
    setSubModalOpen(false);
    setErrorMessage(null);
  };

  const onDeleteHandler = () => {
    setFileName('');
    setUploadedFile(false);
    if (typeof onDelete === 'function') {
      onDelete();
    }
  };

  return (
    <>
      <InputWrapperBlock>
        <Input
          value={fileName}
          onClick={handleClick}
          placeholder={placeholder}
          onDelete={onDelete ? onDeleteHandler : null}
        />
        <ButtonBlock
          {...props}
          uploadedFile={uploadedFile}
          onClick={handleClick}
        />
        {uploadedFile && (
          <FileDownButton
            onClick={() =>
              typeof fileDownload === 'function' && fileDownload(fileInfo.type)
            }
          />
        )}
        <input
          type="file"
          style={{ display: 'none' }}
          ref={hiddenFileInputRef}
          onChange={handleChange}
        />
      </InputWrapperBlock>
      {subModalOpen && (
        <SubModal
          title="파일 업로드 에러"
          content={errorMessage}
          modalSize={MODAL_SIZE.SHORT}
          handleModal={clickModalClose}
        />
      )}
    </>
  );
};

FileInput.propTypes = {
  register: PropTypes.func,
  placeholder: PropTypes.string,
  fileType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleFile: PropTypes.func,
  extension: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isPopup: PropTypes.bool,
  setFile: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

FileInput.defaultProps = {
  register: EMPTY_FUNCTION,
  placeholder: '파일을 등록해주세요',
  extension: ['jpg', 'png', 'jpeg', 'doc', 'docx', 'hwp', 'pdf', 'gif'],
  isPopup: false
};

export default FileInput;
