import styled from 'styled-components';
import { BtnAreaBlock } from '../../../../../../../components/layout/btnArea/BtnArea';
import { ListType10Block } from '../../../../../../../components/layout/ListType';
import { UnitTitleBlock } from '../../../../../../../components/layout/title/UnitTitle';
import appTopPng from '../../../../../../../styles/images/common/sprite/app_top.png';
import spCommonPng from '../../../../../../../styles/images/common/sprite/sp_common.png';
import h1LogoPng from '../../../../../../../styles/images/common/sprite/h1_logo.png';
import Button from '../../../../../../../components/button/Button';
import { SCREEN_SIZE_MOBILE } from '../../../../../../../components/button/CommonStyle';

export const ListTypeBlock = styled(ListType10Block)`
  & > ul > li {
    align-self: center;
    & > div {
      margin-bottom: 0;
      max-height: 31px;
    }
  }
`;

export const UnitTitle = styled(UnitTitleBlock)`
  & > strong {
    color: #ec2a1e !important;
  }
`;

export const LayerBodyBlock = styled.div`
  position: relative;
  height: auto;
  max-height: calc(100vh - 165px);
  overflow: hidden;
  overflow-y: auto;
`;

export const BtnAreaBlockWrapper = styled(BtnAreaBlock)`
  position: sticky;
  bottom: 0;
  z-index: 2;
  margin-top: 20px;
  padding-top: 18px;
  border-top: solid 1px #e9ecf4;
  background-color: #fff;
  & > ul > li {
    margin-left: 11px;
    &:first-of-type {
      margin-left: 0;
    }
  }
`;

export const ListType10BlockWrapper = styled(ListType10Block)`
  padding-top: 0;
  & > ul {
    // .unit
    & > li {
      padding-bottom: 10px;
      & > div {
        // .input_wrap
        position: relative;
        display: flex;
        align-items: center;
        justify-content: left;
        min-height: 32px;
        & > div {
          flex: 0.3;
          margin-left: 0;
        }
      }
    }
    & > li:first-of-type {
      width: 230px;
    }
  }
`;

export const ListType11Format = ({ children }) => {
  return (
    <ListType11Block>
      <ul>
        <li>
          <ul>
            <li>{children}</li>
          </ul>
        </li>
      </ul>
    </ListType11Block>
  );
};

export const ListType11Block = styled.div`
  // .list_ty11
  & > ul {
    display: flex;
    & > li {
      flex: 1;
      & ${UnitTitleBlock} {
        margin: 20px auto 10px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
      }
      & > ul {
        width: 392px;
        margin: 0 auto;
        border: 1px solid rgba(0, 0, 0, 0.16);
        box-sizing: border-box;
        border-radius: 9px;
        padding: 10px 8px;

        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          padding: 0;
          width: 100%;
        }
      }
    }
    & > li:last-of-type > ul {
      background: rgba(158, 179, 205, 0.3);
      border: 1px solid rgba(0, 0, 0, 0.08);
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        background: none;
        border: none;
      }
    }
  }
`;

export const ElecBillAppBlock = styled.ul`
  // .unit .app
  background: #fff;
  border-radius: 6px;
  & > li {
    padding: 14px 10px;
    width: 100%;
    box-sizing: border-box;

    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      padding: 15px 0;
    }
    & > p {
      font-size: 13px;
      word-break: keep-all;
      line-height: 1.5;
      & > strong {
        font-size: 14px;
      }
      & > span {
        font-size: 12px;
        font-weight: bold;
        display: inline-block;
        min-width: 84px;
        line-height: 1.45;
      }
    }
    & > button {
      width: 100%;
      margin: 0;
      height: 36px;
      font-size: 12px;
      font-weight: bold;
      color: #505f7e;
      background: #fff;
      border: 1px solid rgba(80, 95, 126, 0.16);
      position: relative;
      margin: 0.5% 0;
      &::before {
        content: '';
        position: absolute;
        top: 5px;
        right: 12px;
        display: block;
        width: 24px;
        height: 22px;
        background: url(${spCommonPng}) no-repeat;
        background-size: 380px auto;
        background-position: -31px -191px;
      }
    }
    & > ol {
      display: flex;

      padding: 5px 0;
      & li {
        flex: 1;
      }
      & li:first-child {
        flex: none;
        width: 52px;
        display: inline-block;
        font-size: 13px;
      }
      & p {
        font-size: 13px;
        line-height: 1.5;
        word-break: keep-all;
      }
      & span {
        min-width: 40px;
        display: inline-block;
        font-size: 12px;
        font-weight: bold;

        line-height: 1.5;
      }
    }
  }
  & > li:first-of-type {
    background: #365289;
    padding: 4px 0 10px;
    border-radius: 8px 8px 0 0;

    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      display: none;
    }
    & > p {
      padding-top: 28px;
      font-size: 18px;
      color: #fff;
      text-align: center;
      background: url(${appTopPng}) no-repeat center -2px;
      background-size: auto 36%;
    }
  }
  & > li:nth-of-type(2) {
    padding: 40px 10px 10px;
    margin: 12px auto 4px;
    width: 94%;
    border: 1px solid rgba(158, 179, 205, 0.5);
    border-radius: 8px;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 35%);
    background: url(${h1LogoPng}) no-repeat top 8px right 8px;
    background-size: 110px auto;

    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      width: 100%;
    }
    & > p {
      font-size: 14px;
      & > strong {
        font-size: 15px;
        font-weight: 800;
      }
      & > span {
        min-width: 65px;
      }
    }
  }
  & hr {
    width: 95%;
    margin: 0 auto;

    background-color: #e9ecf4;

    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      width: 100%;
    }
  }
`;

export const KakaoAppBlock = styled.ul`
  // .unit .kakao
  padding: 10px;
  background: #b2c7da;
  & > li {
    &:first-of-type {
      & > p {
        padding-left: 60px;
        padding-top: 10px;
        font-size: 11.4px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 50px;
          height: 50px;
          background: url(${spCommonPng}) no-repeat;
          background-size: 400px auto;
          background-position: -1px -304px;
          border-radius: 12px;
        }
      }
    }
    &:last-of-type {
      background: #fff;
      border-radius: 8px;
      width: 83%;
      margin: 0 0 0 auto;
    }
    & > p {
      width: 100%;
      box-sizing: border-box;
      padding: 8px 10px;
      font-size: 13px;
      font-weight: bold;
      color: #000;
      word-break: keep-all;
      line-height: 1.4;
    }
  }
  & p {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 10px;
    font-size: 13px;
    font-weight: bold;
    color: #000;
    word-break: keep-all;
    line-height: 1.4;
    & button {
      background-color: #e8e9ea;
      width: 48.5%;
      height: 35px;
      border-radius: 3px;
      font-size: 13px;
      font-weight: bold;
    }
  }
  & textarea {
    border: 1px solid #a5b7ce;
    height: 115px;
    padding: 5px 8px;
    width: 94%;
    margin: 0 3%;
  }
`;

export const KakaoBoxTopBlock = styled.p`
  // .box-top
  background: #ffeb34;
  border-radius: 8px 8px 0 0;
`;

export const KakaoDescBlock = styled.p`
  // .box-desc
  color: rgba(0, 8, 38, 0.38);
  border-top: 1px dashed #ddd;
  margin-top: 10px;
`;

export const TitleBlock = styled.p`
  // .ti
  margin-bottom: 10px;
`;

export const LinkBlock = styled.button`
  // .link
  border: 3px solid #caccd7;
  background: #fff;
  width: 94%;
  margin: 6px 3% 10px;
  height: 36px;
  border-radius: 3px;
  font-size: 15px;
  font-weight: bold;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    background: #284cb0;
    color: #fff;

    width: 100%;
    display: inline-block;
    width: 100%;
    padding: 11px 14px 10px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: -0.5px;
    text-align: center;

    border: none;
    margin: 0;
    height: auto;
  }
`;

export const CenterBlock = styled.p`
  // .aCenter
  text-align: center;
`;

export const MarginRightBlock = styled.span`
  // .mr_ty01
  margin-right: 3%;
`;

export const ButtonWrapper = styled(Button)`
  width: 94%;
  margin: 10px 3%;
  font-size: 16px;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 30%);
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 15px;
    height: 15px;
    margin-top: -8px;
    margin-left: 46px;
    background: url(${spCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -375px -1px;
  }
`;

export const EditButtonBlock = styled(ButtonWrapper)`
  font-size: 15px;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: 52px;
    background: url(${spCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -375px -20px;
  }
`;

export const KakaoTextAreaBlock = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 16px 20px;
  border-radius: 4px;
  background-color: #fff;
  border: solid 1px #ccd5e0;
  box-sizing: border-box;
  line-height: 1.54;
`;
