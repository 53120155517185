import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import styled from 'styled-components';
import { idpwsign, SIGN_TYPE } from '../../../../components/auth/SignIframe';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../components/button/Button';
import DataTable, {
  DataType
} from '../../../../components/datatable/DataTable';
import ListHeader from '../../../../components/layout/ListHeader';
import Section from '../../../../components/layout/Section';
import { MODAL_SIZE } from '../../../../components/modal/Modal';
import { ModalContext } from '../../../../context/ModalContext';
import { useFileDownload } from '../../../../hooks/useFileDownload';
import {
  fetchGetAgmAgendasElectionVotersExcelAction,
  fetchGetAgmBaseInfoAction,
  fetchGetAgmVotingRightsTotalAction,
  fetchPutAgmCompleteAction
} from '../../../../services/api/agm/promiseActions';
import { checkUndefinedNum, formatNumber } from '../../../../utils/StringUtil';
import { AGM_REGISTER_STEPS } from '../AgmRegister';
import AgmRegisterPageButton from '../main/components/AgmRegisterPageButton';
import StockholdersList from '../main/contents/StockholdersList';

/*
  주주총회 등록 - 04. 투표권자 명부 확정
*/
const AgmRegisterStep4 = ({ register, handleFile }) => {
  const match = useRouteMatch();
  const { agmSeqno } = match.params;
  const dispatch = useDispatch();
  const { openModal, setModalConfig, closeModal } = useContext(ModalContext);
  const [agendas, setAgendas] = useState();
  const [isRegisterStep, setIsRegisterStep] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (match.path.includes('register')) {
      setIsRegisterStep(true);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchGetAgmVotingRightsTotalAction({ agmSeqno })).then((res) => {
      if (!res.error) {
        setAgendas(res.data);
      }
    });
  }, [dispatch, agmSeqno]);

  const columns = [
    {
      title: '의안번호',
      key: 'agendaNumber'
    },
    {
      title: '의안명(상세)',
      key: 'agendaName',
      col: '20%'
    },
    {
      title: '보유 주식',
      children: [
        {
          title: '주주명부상 보통주',
          key: 'agendaDetailContent',
          type: DataType.NUMBER
        },
        {
          title: ['우선주', '(의결권 있는 우선주)'],
          key: 'totalPrefferedStockAmount'
          // type: DataType.NUMBER
        }
      ]
    },
    {
      title: '의결권',
      children: [
        {
          title: '의결권 없는 주식수',
          key: 'noVotingRightsStockAmmount',
          type: DataType.NUMBER
        },
        {
          title: '의결권 제한 주식수',
          key: 'votingRightsLimitStockAmmount',
          type: DataType.NUMBER
        },
        {
          title: '행사가능 주식수',
          key: 'exercisableStockAmmount',
          type: DataType.NUMBER
        }
      ]
    },
    {
      title: '투표권자 명부',
      key: 'voterList'
    }
  ];

  const getAgendaDataSource = () => {
    const newArr = [];
    if (!!agendas) {
      for (const agenda of agendas.totalVotingRights) {
        newArr.push({
          agendaNumber: `제 ${agenda.agendaNumber}호`,
          agendaName: agenda.agendaName,
          agendaDetailContent: agendas.totalCommonStockAmount,
          totalPrefferedStockAmount: `${formatNumber(
            checkUndefinedNum(agendas.totalPrefferedStockAmount) +
              checkUndefinedNum(agendas.totalExercisablePreferredStockAmount)
          )} (${formatNumber(
            checkUndefinedNum(agendas.totalExercisablePreferredStockAmount)
          )})`,
          noVotingRightsStockAmmount: agenda.noVotingRightsStockAmmount,
          votingRightsLimitStockAmmount: agenda.votingRightsLimitStockAmmount,
          exercisableStockAmmount: agenda.exercisableStockAmmount,
          voterList: (
            <>
              <Button
                type={ButtonTypes.Basic}
                size={ButtonSize.Table}
                onClick={onclickDownBtn}
                value={agenda.agendaSeqno}
              >
                다운로드
              </Button>
            </>
          )
        });
      }
    }
    return newArr;
  };

  const { callDownloadFetchWithoutFile } = useFileDownload();

  const onclickDownBtn = (e) => {
    const agendaSeqno = e.currentTarget.value;
    callDownloadFetchWithoutFile(
      agendaSeqno,
      agmSeqno,
      fetchGetAgmAgendasElectionVotersExcelAction
    );
  };

  const callback = (sign, payload) => {
    const { voteUse, proxyUse } = payload;
    const data = {
      signData: sign
    };
    dispatch(fetchPutAgmCompleteAction({ agmSeqno, data })).then((res) => {
      if (!res.error) {
        // if(전자고지서 미신청 시)
        setModalConfig({
          isNoButton: false,
          size: MODAL_SIZE.SHORT,
          okHandler: completeFunc
        });
        openModal(
          `${getElecMethod(voteUse, proxyUse)} 서비스 개시`,
          `${getElecMethod(voteUse, proxyUse)} 서비스 개시가 완료되었습니다.`,
          () => history.push(`/corp/agm/info/${agmSeqno}/agm-short-desc`)
        );

        // else (전자고지서 신청 시)
        // setModalConfig({
        //   size: MODAL_SIZE.MEDIUM,
        //   buttons: [
        //     <CancBtn
        //       onClick={() =>
        //         history.push(`/corp/agm/info/${agmSeqno}/agm-short-desc`)
        //       }
        //       title="나중에 하기"
        //     />,
        //     <OkBtn onClick={} title="전자고지 발송" />
        //   ],
        //   okHandler: moveElecBillSendPage
        // });
        // openModal('전자투표/전자위임장 서비스 개시', completeModalContent());
      } else {
        setModalConfig({
          isNoButton: false,
          size: MODAL_SIZE.SHORT
        });
        openModal('확인', res.error.user_message);
      }
    });
  };

  // TODO check 김호수
  /*
  const completeModalContent = () => {
    return (
      <>
        <p>전자투표/전자위임장 서비스 개시가</p>
        <p>
          <b>완료되었습니다.</b>
        </p>
        <br />
        <p>전자고지서를 신청 하셨습니다.</p>
        <p>전자고지서 발송 페이지로 이동하시겠습니까?</p>
      </>
    );
  };
  */

  const completeFunc = () => {
    closeModal();
    history.push(`/corp/agm/info/${agmSeqno}/agm-short-desc`);
  };

  const CancelBtn = () => {
    return (
      <Button
        type={ButtonTypes.Basic}
        size={ButtonSize.Table}
        onClick={() => closeModal()}
      >
        취소
      </Button>
    );
  };

  const SignBtn = ({ voteUse, proxyUse }) => {
    return (
      <Button
        type={ButtonTypes.Primary}
        size={ButtonSize.Table}
        onClick={() => openSignModal(voteUse, proxyUse)}
      >
        확정
      </Button>
    );
  };

  const openSignModal = (voteUse, proxyUse) => {
    setTimeout(() => {
      const payload = {
        process: '06', // 주총 개설 완료
        voteUse: voteUse,
        proxyUse: proxyUse
      };
      idpwsign(SIGN_TYPE.CORP, callback, payload);
    }, 0);
    setTimeout(() => {
      closeModal();
    });
  };

  const alertConfirm = () => {
    dispatch(fetchGetAgmBaseInfoAction(agmSeqno)).then((res) => {
      if (!res.error) {
        const { electronicVoteUse, electronicProxyUse } = res.data;
        setModalConfig({
          isNoButton: true,
          size: MODAL_SIZE.SHORT
        });
        openModal('알림', getModalBody(electronicVoteUse, electronicProxyUse));
      }
    });
  };

  const getElecMethod = (voteUse, proxyUse) => {
    if (voteUse && proxyUse) return '전자투표/전자위임장';
    else if (!voteUse && proxyUse) return '전자위임장';
    else if (voteUse && !proxyUse) return '전자투표';
  };

  const getModalBody = (voteUse, proxyUse) => {
    return (
      <LayerBodyBlock>
        <p>
          집합투자재산 등에 대한 의결권 행사주체 및 이에 따른 전자투표권자를
          확인하였으며, {getElecMethod(voteUse, proxyUse)} 서비스를 개시하는
          것에 대해 공인인증을 통해 동의합니다.
        </p>
        <NoLineBtnAreaBlock>
          <ul>
            <li>
              <CancelBtn />
              <SignBtn voteUse={voteUse} proxyUse={proxyUse} />
            </li>
          </ul>
        </NoLineBtnAreaBlock>
      </LayerBodyBlock>
    );
  };

  return (
    <>
      <Section wide>
        <ListHeader title="주주총회 의안" isRequired />
        {!!agendas && (
          <DataTable columns={columns} dataSource={getAgendaDataSource()} />
        )}
        <>
          <ListHeader title="투표권자 명부" />
          {!!agendas && (
            <StockholdersList
              agmSeqno={agmSeqno}
              isRegisterStep={isRegisterStep}
              agendas={agendas}
            />
          )}
        </>
      </Section>
      <AgmRegisterPageButton
        usePrev
        useNext
        step={AGM_REGISTER_STEPS.ELECTION_VOTER}
        handleNextBtn={alertConfirm}
      />
    </>
  );
};

const LayerBodyBlock = styled.div`
  // .layer_body
  position: relative;
  height: auto;
  max-height: calc(100vh - 165px);
  overflow: hidden;
  overflow-y: auto;

  // .layer_pop.w_ty01 .layer_body
  padding: 0 20px;

  & > p {
    line-height: 1.53;
    word-break: keep-all;
    margin-bottom: 8px;
  }
  & > div {
    // .btn_area
    position: sticky;
    bottom: 0;
    margin-top: 20px;
    padding-top: 18px;
    border-top: solid 1px #e9ecf4;
    background-color: #fff;
  }
`;

const NoLineBtnAreaBlock = styled.div`
  // .noline
  &&& {
    padding-top: 0;
    border-top: 0;
  }
  & > ul {
    display: flex;
    align-items: center;
    & > li {
      flex: 1;
      margin-left: 20px;
      & > button {
        display: flex;
        width: 100%;
        height: 100%;
        min-width: auto;
        align-items: center;
        justify-content: center;
      }
    }
    & > li:only-of-type {
      text-align: center;
      & > button {
        display: inline-block;
        width: auto;
        min-width: 200px;
        margin-left: 4px;
        min-width: 120px;
      }
      & > button:first-child {
        margin-left: 0;
      }
    }
    & > li:first-of-type {
      margin-left: 0;
    }
  }
`;

export default AgmRegisterStep4;
