import { GET, POST, PUT } from '../../../utils/constant/httpMethod';
import { request, requestFile } from '../../../utils/requestHelper';

const API_GROUP = 'issue-incorporated';
const Issinc = {
  /**
   * 발행회사 계약 신청
   */
  // 발행회사 계약 신청
  fetchPostIssueIncorporated({ data }) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}`,
      data
    });
  },

  // 발행회사 계약 신청 수정
  fetchPutIssueIncorporated(data) {
    return request({
      method: PUT,
      url: `/v1/${API_GROUP}`,
      data
    });
  },

  // 발행회사 패스워드 변경
  fetchPutIssueIncorporatedPassword(bizRegNumber) {
    return request({
      method: PUT,
      url: `/v1/${API_GROUP}/${bizRegNumber}/password`
    });
  },

  // 발행회사 계약 신청 정보 조회
  fetchGetIssueIncorporated() {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}`
    });
  },

  // 발행회사 파일 조회
  fetchGetIssueIncorporatedFiles({ fileCd }) {
    return requestFile({
      method: GET,
      url: `/v1/${API_GROUP}/files/${fileCd}`
    });
  }
};
export default Issinc;
