import React from 'react';
import styled from 'styled-components';
import { Icons } from '../../styles/Icons';
import spriteCommonPng from '../../styles/images/common/sprite/sp_common.png';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';

const InputHeaderBlock = styled.div`
  display: block;
  margin-bottom: 12px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin-bottom: 8px;
  }
  & > span:first-of-type {
    position: relative;
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    color: #080808;
    ${(props) => (props.isRequired ? Icons.unit_tag.css : '')}
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      font-size: 12px;
      font-weight: bold;
      &::after {
        display: none;
      }
    }
  }
`;

const DescriptionBlock = styled.span`
  position: static;
  font-size: 13px;
  display: inline-block;
  margin-top: 8px;
  padding-left: 20px;
  font-weight: 400;
  color: #8c939c;
  line-height: 1.33;
`;

const HelpBtnBlock = styled.button`
  display: inline-block;
  margin-left: 5px;
  width: 16px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    width: 16px;
    height: 16px;
    margin-top: -14px;
    background: url(${spriteCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -189px -15px;
    opacity: 1;
  }
  & > span {
    display: none;
    font-size: 0;
  }
`;

const OptionalText = styled.strong`
  color: #0094ff !important;
`;

const InputHeader = (props) => {
  const { title, description, isOptional, isRequired, isMobile } = props;
  return (
    <InputHeaderBlock {...props}>
      <span>
        {title}
        {isOptional && <OptionalText> (선택)</OptionalText>}
      </span>
      {isMobile && isRequired && <TagBlock> (필수)</TagBlock>}
      {props.isHelped && (
        <HelpBtnBlock>
          <span>도움말</span>
        </HelpBtnBlock>
      )}
      <DescriptionBlock>
        {Array.isArray(description)
          ? description.map((desc, index) => {
              if (description.length - 1 !== index) {
                return (
                  <>
                    {desc}
                    <br />
                  </>
                );
              } else {
                return desc;
              }
            })
          : description}
      </DescriptionBlock>
    </InputHeaderBlock>
  );
};

const TagBlock = styled.span`
  margin-left: 2px;
  color: #ec2a1e;

  font-size: 12px;
  font-weight: bold;
`;

export default InputHeader;
