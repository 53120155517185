import React from 'react';
import styled, { css } from 'styled-components';
import { TableDataType } from './Table';

const DataBlock = styled.li`
  flex: 1;
  font-size: 15px;
  letter-spacing: -0.5px;
  color: #000826;
  text-align: center;
  overflow: hidden;
  ${(props) =>
    props.dataType === TableDataType.CHECKBOX &&
    css`
      flex: none;
      flex-basis: 100px;
      flex-shrink: 0;
    `}
  ${(props) =>
    props.dataType === TableDataType.DATETIME &&
    css`
      flex: none;
      flex-basis: 200px;
      flex-shrink: 0;
    `}
    
    ${(props) =>
    props.dataType === TableDataType.NUMBER &&
    css`
      flex: none;
      flex-basis: 60px;
      flex-shrink: 0;
    `}
`;

const Data = (props) => {
  return <DataBlock {...props}>{props.children}</DataBlock>;
};

export default Data;
