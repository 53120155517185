import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import { fetchGetAgmAction } from '../../../services/api/agm/promiseActions';
import ConfirmStockholder from './main/ConfirmStockholder';
import MyAgmList from './main/MyAgmList';

const MyAgms = () => {
  const dispatch = useDispatch();
  const [agms, setAgms] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(fetchGetAgmAction()).then((res) => {
      if (!res.error) {
        setAgms((prev) => res.data);
      } else if (res.error.status === 412) {
        setError((prev) => true);
      }
    });
  }, [dispatch, error]);

  const renderBody = useCallback(() => {
    if (error) {
      return <ConfirmStockholder setError={setError} />;
    } else {
      if (!!agms) {
        return <MyAgmList agms={agms} />;
      } else {
        return <></>;
      }
    }
  }, [error, agms]);

  return (
    <Page extend>
      <PageHeader title="나의 주주총회" />
      {renderBody()}
    </Page>
  );
};

export default MyAgms;
