import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonTypes } from '../../../../../../../../components/button/Button';
import { MODAL_SIZE } from '../../../../../../../../components/modal/Modal';
import { ModalContext } from '../../../../../../../../context/ModalContext';
import {
  ButtonWrapper,
  EditButtonBlock,
  KakaoAppBlock,
  KakaoBoxTopBlock,
  KakaoDescBlock
} from '../../layout/LayoutWrapper';
import KakaoPopupForm from './KakaoPopupForm';
import { DATE_FORMAT } from '../../../../../../../../utils/DateUtil';
import {
  DateFormatName,
  getDateFormat
} from '../../../../../../../../utils/DateFormatUtil';

const KakaoForm = ({ setValue, agm, electronicNotice }) => {
  const { openModal, setModalConfig, closeModal } = useContext(ModalContext);
  const { register, unregister, handleSubmit } = useForm();
  const [kakaoStateFlag, setKakaoStateFlag] = useState(false);
  const [appendText, setAppendText] = useState('');

  useEffect(() => {
    !!electronicNotice && setAppendText(electronicNotice.noticeContent);
  }, [electronicNotice]);

  useEffect(() => {
    setKakaoStateFlag(!!appendText ? true : false);
  }, [appendText]);

  const onClickBtn = () => {
    setModalConfig({
      size: MODAL_SIZE.LARGE,
      isNoButton: true
    });
    openModal(
      `카카오톡 내용 ${kakaoStateFlag ? '수정' : '추가'}`,
      <KakaoPopupForm
        register={register}
        closeModal={closeModal}
        handleSubmit={handleSubmit}
        onSubmitRegister={onSubmitRegister}
        appendText={appendText}
        agm={agm}
      />
    );
  };

  const onSubmitRegister = ({ content, initFlag }) => {
    setAppendText(content);
    if (initFlag) {
      unregister('noticeContent');
    }
    setValue('noticeContent', content);
  };

  return (
    <ul>
      <li>
        <KakaoAppBlock>
          <li>
            <p>블록체인 전자주총</p>
          </li>
          <li>
            <KakaoBoxTopBlock>알림도착</KakaoBoxTopBlock>
            <p>블록체인 전자주총에서 전자고지서를 보내 드립니다.</p>
            <p>
              [주주총회 소집통지서]
              <br />
              고객님의 소중한 의결권 행사를 위한 주주총회 관련 안내문입니다.
              하단의 [열람하기]를 눌러 주주총회의 상세정보를 확인해주세요.
            </p>
            <p>
              {agm?.issueIncorporatedName}은 「주식 · 사채 등의 전자등록에 관한
              법률」제38조 · 제70조에 근거하여 권리항사에 관한 업무를 수행하기
              위하여 본 메시지를 발송합니다.
            </p>

            {kakaoStateFlag && (
              <>
                <AppendTextBlock>{appendText}</AppendTextBlock>

                <EditButtonBlock
                  type={ButtonTypes.Primary}
                  onClick={onClickBtn}
                >
                  내용 수정하기
                </EditButtonBlock>
              </>
            )}
            {!kakaoStateFlag && (
              <ButtonWrapper type={ButtonTypes.Primary} onClick={onClickBtn}>
                내용 추가
              </ButtonWrapper>
            )}

            <p>
              - 보낸이 : {agm?.issueIncorporatedName}
              <br />- 내용 : 주주총회 소집통지서
              <br />- 열람가능시간 :
              {DATE_FORMAT(
                agm?.agmDateTime,
                getDateFormat(DateFormatName.LONG_DATE_TIME)
              )}
              까지
              <br />- 고객센터 : 1899-1433
            </p>
            <KakaoDescBlock>
              본 기관은 기존에 우편으로 전달 드리던 <br />
              각종 중요문서, 통지서를 고객님의 카톡을 통해 전달하고자 합니다.
              안내문을 쉽고, 편하게 받아보시려면 아래 열람 버튼을 눌러주세요.
            </KakaoDescBlock>
            <p>
              <button>열람하기</button>
              <button style={{ marginLeft: '3%' }}>내문서함 홈</button>
            </p>
          </li>
        </KakaoAppBlock>
      </li>
    </ul>
  );
};

const AppendTextBlock = styled.p`
  background: linear-gradient(
    90deg,
    white 3%,
    lightgrey 0,
    lightgrey 96%,
    white 3%
  );
`;

export default KakaoForm;
