import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import styled from 'styled-components';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import { SCREEN_SIZE_MOBILE } from '../../../components/button/CommonStyle';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import { PageInnerListWrap } from '../../../components/page/PageInner';
import {
  fetchGetAgmByAgmSeqnoAction,
  fetchGetAgmExcercisedRightsAction
} from '../../../services/api/agm/promiseActions';
import { fetchGetUsersMeAction } from '../../../services/api/users/promiseActions';
import { AgmStateCd } from '../../../utils/constant/AgmConstant';
import { setAccessToken, setRefreshToken } from '../../../utils/requestHelper';
import CertificateInfo from '../certification/main/CertificateInfo';
import { VOTE_BUTTON_SIZE } from '../result/main/components/DefaultButtonStyle';
import { VOTE_BUTTON_TYPE } from '../vote/main/components/DefaultButtonStyle';
import ExercisableStockAmount from '../vote/main/components/ExercisableStockAmount';
import VoteAgendaBox from '../vote/main/components/VoteAgendaBox';
import VoteAgendaBoxTitle from '../vote/main/components/VoteAgendaBoxTitle';
import VoteBoxList from '../vote/main/components/VoteBoxList';
import VoteButton from '../vote/main/components/VoteButton';
import VoteButtonContainer from '../vote/main/components/VoteButtonContainer';
import VoteChildAgendaBox from '../vote/main/components/VoteChildAgendaBox';
import BoxInfo from './main/BoxInfo';

const ExerciseRecordPage = () => {
  const match = useRouteMatch();
  const agmSeqno = match.params.agmSeqno;
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [baseInfo, setBaseInfo] = useState();
  const [selectedVoter, setSelectedVoter] = useState(null);
  const history = useHistory();
  useEffect(() => {
    dispatch(fetchGetUsersMeAction()).then((res) => {
      if (!res.error) {
        dispatch(fetchGetAgmByAgmSeqnoAction(agmSeqno)).then((res) => {
          setBaseInfo(res.data);

          dispatch(fetchGetAgmExcercisedRightsAction(agmSeqno)).then((res) => {
            setData(res.data);
            if (
              res.data?.electionVoters &&
              res.data?.electionVoters.length >= 1
            ) {
              setSelectedVoter([...res.data.electionVoters]);
            }
          });
        });
      } else {
        console.log('error!');
        console.log(res);
        if (
          res.error.status === 401 &&
          res.error.user_message === '접근 권한이 없습니다.'
        ) {
          setAccessToken(null);
          setRefreshToken(null);
          history.push('/');
        }
      }
    });
  }, [dispatch, agmSeqno]);

  const setCheckVoter = (index) => {
    if (index === null) {
      setSelectedVoter([]);
    } else {
      setSelectedVoter((prev) => [
        data.electionVoters.find((_, idx) => idx === index)
      ]);
    }
  };

  // 행사 가능 주식수 계산
  const getExrcsAmount = (agenda) => {
    if (!agenda) return 0;
    const agendaSeqno = agenda.agendaSeqno;

    const arr = selectedVoter?.map((item) => {
      const data = item.voteRights.find(
        (rights) => rights.agendaSeqno === agendaSeqno
      );
      return data?.exercisableStockAmmount;
    });
    return arr && arr.length !== 0 ? arr.reduce((acc, curr) => acc + curr) : 0;
  };

  const getStockData = (agenda) => {
    if (!selectedVoter || selectedVoter.length === 0) {
      return null;
    }
    const agendas =
      selectedVoter &&
      selectedVoter.map((voter) => {
        if (agenda.parentAgendaSeqno) {
          if (agenda.concentrateVote) {
            return voter.voteRights
              ?.find((item) => item.agendaSeqno === agenda.parentAgendaSeqno)
              .childs?.find(
                (child) => child.agendaSeqno === agenda.agendaSeqno
              );
          } else {
            return voter.voteRights?.find(
              (item) => item.agendaSeqno === agenda.agendaSeqno
            );
          }
        } else {
          return voter.voteRights?.find((value) => {
            return agenda.agendaSeqno === value.agendaSeqno ? true : false;
          });
        }
      });

    const agendaStock = agendas.reduce((acc, curr) => {
      return {
        agendaSeqno: agenda.agendaSeqno,
        ayes: acc.ayes + curr.ayes,
        noes: acc.noes + curr.noes,
        abstention: acc.abstention + curr.abstention
      };
    });

    return agendaStock;
  };

  const isAbstenAgenda = (agenda) => {
    const data = getStockData(agenda);
    if (data) {
      if (data.childs) {
        return data.childs[0].abstention > 0;
      } else {
        return data && data.abstention > 0;
      }
    }
  };

  const getVoteButtons = (agenda) => {
    const isAbsten = isAbstenAgenda(agenda);
    if (agenda.childs.length === 0) {
      // 비분리(찬반) 투표
      // 분리 투표
      const data = getStockData(agenda);
      return (
        <VoteButtonContainer>
          <VoteButton
            size={VOTE_BUTTON_SIZE.Short}
            type={VOTE_BUTTON_TYPE.Stock}
            stockAmount={data ? data['yesStockQuantity'] : 0}
            isAbsten={isAbsten}
          >
            찬성
          </VoteButton>
          <VoteButton
            size={VOTE_BUTTON_SIZE.Short}
            type={VOTE_BUTTON_TYPE.Stock}
            stockAmount={data ? data['noStockQuantity'] : 0}
            isAbsten={isAbsten}
          >
            반대
          </VoteButton>
        </VoteButtonContainer>
      );
    } else if (agenda.childs.length !== 0 && agenda.concentrateVote) {
      // 집중 투표
      return (
        <>
          <VoteButtonContainer>
            {agenda.childs.map((child, idx) => {
              const stock = getStockData(child);
              return (
                <VoteButton
                  key={`VoteButton-${idx}`}
                  size={VOTE_BUTTON_SIZE.Short}
                  type={VOTE_BUTTON_TYPE.Stock}
                  stockAmount={stock ? stock['yesStockQuantity'] : 0}
                  isAbsten={isAbsten}
                >
                  {`${child.agendaName}`}
                </VoteButton>
              );
            })}
          </VoteButtonContainer>
        </>
      );
    } else {
      // 잘못 된 의안
    }
  };

  const onNavigate = (url) => {
    history.push(url);
  };

  return (
    <Page>
      <PageHeader title="행사내역" />
      <PageInnerListWrap>
        <ShortSizeDivBlock>
          <CertificateInfo
            info={baseInfo}
            selectedVoters={selectedVoter}
            isVoteScreen={false}
          />
        </ShortSizeDivBlock>
        {typeof data !== 'undefined' && !!selectedVoter && (
          <>
            <BoxInfo
              baseInfo={baseInfo}
              solicitor={data.agmSolicitor}
              voters={data.electionVoters}
              selectedVoter={selectedVoter[0]}
              setSelectedVoter={setCheckVoter}
            />
            <VoteBoxList>
              {data.agendas &&
                data.agendas.map((agenda, idx) => {
                  if (agenda.childs.length !== 0 && !agenda.concentrateVote) {
                    return (
                      <VoteAgendaBox
                        key={`voteAgendaBox_${agenda.agendaSeqno}_${idx}`}
                      >
                        <VoteAgendaBoxTitle
                          agenda={agenda}
                          //useAbsten={true}
                          //isAbsten={true}
                        />
                        {agenda.childs.map((child, childIdx) => {
                          return (
                            <VoteChildAgendaBox
                              key={`voteChildAgendaBox_${childIdx}`}
                              agenda={child}
                              stockAmount={getExrcsAmount(child)}
                              useAbsten={true}
                              isAbsten={isAbstenAgenda(child)}
                            >
                              {getVoteButtons(child)}
                            </VoteChildAgendaBox>
                          );
                        })}
                      </VoteAgendaBox>
                    );
                  } else {
                    return (
                      <VoteAgendaBox
                        key={`voteAgendaBox_${agenda.agendaSeqno}_${idx}`}
                      >
                        <VoteAgendaBoxTitle
                          agenda={agenda}
                          useAbsten={true}
                          isAbsten={isAbstenAgenda(agenda)}
                        />
                        <ExercisableStockAmount
                          stockAmount={getExrcsAmount(agenda)}
                        />
                        {getVoteButtons(agenda)}
                      </VoteAgendaBox>
                    );
                  }
                })}
            </VoteBoxList>
          </>
        )}
      </PageInnerListWrap>
      {!!baseInfo && baseInfo.agmStateCode === AgmStateCd.END && (
        <ButtonGroup>
          <Button
            type={ButtonTypes.Primary}
            size={ButtonSize.Medium}
            onClick={() =>
              onNavigate(`/shareholder/agm/${agmSeqno}/vote-result`)
            }
          >
            주주총회 결과보기
          </Button>
        </ButtonGroup>
      )}
    </Page>
  );
};

const ShortSizeDivBlock = styled.div`
  width: 1020px;
  margin: 0 auto;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    width: auto;
  }
  &&& > div > ul {
    border-bottom: 0;
  }
`;

export default ExerciseRecordPage;
