import PropTypes from 'prop-types'; // ES6
import React from 'react';
import styled, { css } from 'styled-components';
import CommonStyles from '../../styles/CommonStyles';
import spriteCommonPng from '../../styles/images/common/sprite/sp_common.png';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';

const InputWrapperBlock = styled.div`
  flex: 1;
  margin-right: 4px;
  position: relative;
`;
const InputBlock = styled.input`
  flex: 1;
  width: 100%;
  margin-right: 4px;
  // .input_ty
  height: 42px;
  padding: 0 20px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    height: 34px;
    padding: 0 12px;
    ${(props) =>
      props.short &&
      css`
        flex: none;
        width: 140px !important;
        min-width: auto;
      `}
  }
  ${(props) =>
    props.onDelete &&
    css`
      padding-right: 30px;
    `}

  border-radius: 4px;
  background-color: #fff;
  border: solid 1px #ccd5e0;
  box-sizing: border-box;
  line-height: 1.54;
  text-overflow: ellipsis;
  ${(props) =>
    !props.disabled && !props.readonly
      ? css`
          &:hover {
            background-color: #fff;
            border: solid 1px #8c939c;
          }
          &:focus {
            background-color: #fff;
            border: solid 1px #8c939c;
          }
        `
      : css``}

  ${(props) =>
    props.readOnly &&
    css`
    background-color #ccd5e0; 
    &:hover {
      background-color #ccd5e0
    }
    &:focus {
      background-color #ccd5e0
    }
  `}

  ${(props) =>
    props.type === 'password' &&
    css`
      font-family: 'AppleGothicNeoSD', 'Apple Gothic', '애플 고딕', Dotum,
        ' 돋움', sans-serif, Helvetica, Arial;
      font-size: 16px;
      color: ${CommonStyles.THEME_COLOR};
    `}
  ${(props) =>
    props.type === 'number' &&
    css`
      text-align: right;
    `} 

  ${(props) =>
    props.isComplete &&
    // .input_ty.fc_ty01
    css`
      color: #24995a;
      background-color: rgba(36, 153, 90, 0.08);
    `}
  ${(props) =>
    props.isError &&
    // .input_ty.fc_ty02
    css`
      color: #ff4040;
      background-color: rgba(255, 64, 64, 0.08);
    `}
${(props) => {
    let sizeCss = null;
    switch (props.size) {
      case InputSize.SHORT:
        // .input_short
        sizeCss = css`
          max-width: 140px;
        `;
        break;
      case InputSize.MEDIUM:
        sizeCss = css`
          max-width: 300px;
          min-width: 300px;
        `;
        break;
      default:
        sizeCss = css``;
    }
    return sizeCss;
  }}
`;

const DeleteButtonBlock = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  display: block;
  width: 13px;
  height: 0;
  padding-top: 13px;
  background: url(${spriteCommonPng}) no-repeat;
  background-size: 400px auto;
  background-position: -262px -1px;
  color: transparent;
  overflow: hidden;
`;

const Input = (props) => {
  const {
    register,
    name,
    registerOption,
    onChange,
    onKeyPress,
    onDelete,
    value
  } = props;
  const onDeleteHandler = () => {
    onDelete();
  };
  const reg = register ? register(name, registerOption) : {};
  let showDelBtn = false;
  if (value && onDelete) {
    showDelBtn = true;
  }
  return typeof onChange === 'function' ? (
    <InputWrapperBlock>
      <InputBlock {...props} {...reg} onChange={onChange} />
      {showDelBtn && <DeleteButtonBlock onClick={onDeleteHandler} />}
    </InputWrapperBlock>
  ) : (
    <InputWrapperBlock>
      <InputBlock onKeyPress={onKeyPress} {...props} {...reg} />
      {showDelBtn && <DeleteButtonBlock onClick={onDeleteHandler} />}
    </InputWrapperBlock>
  );
};

export const ErrorTypes = {
  DESCRIPTION: 'description',
  ERROR: 'error',
  SUCCESS: 'success'
};

export const InputSize = {
  SHORT: 'short',
  MEDIUM: 'medium'
};

Input.propTypes = {
  register: PropTypes.func,
  registerOption: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  type: PropTypes.oneOf(['text', 'password', 'hidden', 'number']),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  isError: PropTypes.bool,
  isComplete: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(InputSize)),
  name: PropTypes.string,
  onKeyPress: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

Input.defaultProps = {
  register: null,
  type: 'text',
  placeholder: '',
  onChange: null,
  onClick: null,
  disabled: false,
  readOnly: false,
  isError: false,
  isComplete: false
};

export default Input;
