import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../components/button/Button';
import DataTable from '../../../../components/datatable/DataTable';
import Input from '../../../../components/input/Input';
import Select from '../../../../components/input/Select';
import { TableTitleBlock } from '../../../../components/table/TableTitle';
import { fetchGetAgmVotingRightsDiversitiesAction } from '../../../../services/api/agm/promiseActions';
import { DATE_FORMAT } from '../../../../utils/DateUtil';
import { ListWrapBlock } from '../../dashboard/CorpDashboardPage';
import spCommonPng from '../../../../styles/images/common/sprite/sp_common.png';
import { useFileDownload } from '../../../../hooks/useFileDownload';
import { fetchGetFilesAgmDiversityExerciseAction } from '../../../../services/api/files/promiseActions';
import { useForm } from 'react-hook-form';
import InputBox from '../../../../components/input/InputBox';
import {
  DateFormatName,
  getDateFormat
} from '../../../../utils/DateFormatUtil';
import { Role } from '../../../../utils/constant/AgmConstant';

const DiverseExercise = () => {
  const match = useRouteMatch();
  const { agmSeqno } = match.params;
  const [data, setData] = useState([]);
  const [searchType, setSearchType] = useState();
  const [searchText, setSearchText] = useState('');
  const [selRow, setSelRow] = useState();
  const dispatch = useDispatch();
  const inputEl = useRef();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageConfig, setPageConfig] = useState({});
  const { callDownloadFetchAPIParam } = useFileDownload();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const role = useSelector((state) => state?.common?.user?.role);

  useEffect(() => {
    // TODO 불통일 제한 내역 전체 필요
    const params = {
      searchType: typeof searchType === 'undefined' ? '1' : searchType,
      searchText: searchText,
      page: pageNumber,
      size: 5
    };
    dispatch(
      fetchGetAgmVotingRightsDiversitiesAction({ agmSeqno, params })
    ).then((res) => {
      if (!res.error) {
        const { content, pageable, size, totalElements, totalPages } = res.data;
        setPageConfig({
          pageNumber: pageable.pageNumber,
          size: size,
          totalCount: totalElements,
          totalPageNumber: totalPages
        });
        setData(content);
      }
    });
  }, [agmSeqno, dispatch, searchType, searchText, pageNumber]);

  useEffect(() => {
    if (!!selRow && selRow !== -1)
      document.getElementsByTagName('tbody')[0].childNodes[
        selRow * 2 - 1
      ].style = 'display: revert';
  }, [selRow]);

  const columns = [
    {
      title: 'NO',
      key: 'count',
      col: '9%'
    },
    {
      title: '주주명',
      key: 'stockholderName',
      col: '*'
    },
    {
      title: '주민/사업자 등록번호',
      key: 'stockholderRealNameNumber',
      col: '19%'
    },
    {
      title: '주식수',
      key: 'commonStockAmount',
      col: '15%'
    },
    {
      title: '신청일시',
      key: 'exerciseDateTime',
      col: '17%'
    },
    {
      title: '불통일행사 사유',
      key: 'exerciseContent',
      col: '15%'
    }
  ];

  const onDownloadFile = (file) => {
    callDownloadFetchAPIParam(
      file,
      fetchGetFilesAgmDiversityExerciseAction,
      agmSeqno
    );
  };

  const getDataSource = () => {
    const newArr = [];
    data.forEach((item, index) => {
      const fileParam =
        !!item.votingRightsDiversityExercise &&
        item.votingRightsDiversityExercise.filePath
          ? {
              filePath: item.votingRightsDiversityExercise.filePath,
              fileName: item.votingRightsDiversityExercise.fileName,
              stockholderSeqno:
                item.votingRightsDiversityExercise.shareholderSeqno
            }
          : null;
      const dateTime = `${DATE_FORMAT(
        item.votingRightsDiversityExercise.exerciseDateTime,
        getDateFormat(DateFormatName.FULL_DATE_TIME)
      )}`;
      newArr.push({
        count: index + 1,
        stockholderName: item.stockholder.stockholderName,
        stockholderRealNameNumber: item.stockholder.stockholderRealNameNumber,
        commonStockAmount: item.stockholder.commonStockAmount + ' 주',
        exerciseDateTime: dateTime,
        exerciseContent: <ReasonBtnBlock>사유보기</ReasonBtnBlock>,
        exerciseReason: (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  item.votingRightsDiversityExercise.exerciseContent.replace(
                    '.',
                    '. <br />'
                  )
              }}
            ></span>
            <br />
            <br />
            <br />
            {role === Role.ISSINC_ADMIN.code && fileParam && (
              <div>
                <DownloadBtnBlock onClick={() => onDownloadFile(fileParam)}>
                  {fileParam.fileName}
                </DownloadBtnBlock>
              </div>
            )}
          </>
        )
      });
    });
    return newArr;
  };

  const onRowClick = (e) => {
    const index = Math.round(e.currentTarget.rowIndex / 2);
    if (typeof selRow !== 'undefined' && selRow === index) {
      document.getElementsByTagName('tbody')[0].childNodes[
        selRow * 2 - 1
      ].style = 'display: none';
      setSelRow(-1);
    } else {
      setSelRow(index);
    }
  };

  const onChangeType = (e) => {
    setSearchType(e.target.value);
  };

  const onSearch = () => {
    setSearchText(inputEl.current);
  };

  const onChangeSearchValue = (e) => {
    inputEl.current = e.target.value;
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <ListWrapBlock>
      <SearchBlock>
        <ul>
          <li>
            <InputBox error={errors.searchType?.message}>
              <Select
                onchange={onChangeType}
                name="searchType"
                register={register}
                registerOption={{
                  validate: (value) =>
                    parseInt(value) > 0 || '검색 종류를 선택해주세요'
                }}
              >
                <option value="1">주주명</option>
                <option value="2">주민/사업자 등록번호</option>
              </Select>
              <Input
                placeholder="검색어 입력"
                onChange={onChangeSearchValue}
                ref={inputEl}
                onKeyPress={onKeyPress}
              />
              <Button
                size={ButtonSize.Default}
                type={ButtonTypes.Primary}
                onClick={handleSubmit(onSearch)}
              >
                검색
              </Button>
            </InputBox>
          </li>
        </ul>
      </SearchBlock>
      <TableTitleBlock>
        <span>의결권 불통일 행사 내역</span>
      </TableTitleBlock>
      <DataTable
        columns={columns}
        dataSource={getDataSource()}
        onRowClick={onRowClick}
        detailValueNames={['exerciseContent']}
        pageConfig={{
          ...pageConfig,
          onClick: setPageNumber
        }}
      />
    </ListWrapBlock>
  );
};

const SearchBlock = styled.div`
  // .search_ty02
  width: 600px;
  margin: 50px auto 30px;
  & > ul > li {
    width: 100%;
    & > div > select {
      max-width: 200px;
    }
  }
`;

const ReasonBtnBlock = styled.a`
  &&& {
    display: inline-block;
    padding: 2px 16px;
    border-radius: 3px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    font-size: 13px;
    line-height: 2;
    text-align: center;
    background-color: #284cb0;
    color: #fff;
    border-bottom: 1px solid #0572ef;
  }
`;

export const DescBoxBlock = styled.tr`
  // .reason_on
  background: rgba(175, 236, 255, 0.16);
  cursor: default;
  &&& > td {
    text-align: left;
    padding: 30px 50px;
    border: 1px solid rgba(175, 236, 255, 0.6);
    border-radius: 6px;
    & > p {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
`;

const DownloadBtnBlock = styled.button`
  // .btn_ty01 btn_i03 down
  display: inline-block;
  line-height: 1.33;
  letter-spacing: -0.5px;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  padding: 12px 0 12px 36px;
  min-width: auto;
  background-color: transparent;
  color: #080808;
  &::before {
    content: '';
    position: absolute;
    top: 33%;
    right: 22px;
    display: block;
    width: 24px;
    height: 26px;
    margin-top: -5.5px;
    background: url(${spCommonPng}) no-repeat;
    background-size: 380px auto;
    left: 4px;
    background-position: -30px -140px;
  }
`;

export default DiverseExercise;
