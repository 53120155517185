import React from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';
import logoPng from '../../styles/images/common/logo.png';
// import spCommonPng from '../../styles/images/common/sprite/sp_common.png';

const RegisterStepsBlock = styled.div`
  // .step_num
  max-width: 1020px;
  margin: 40px auto 60px;
  & > ul {
    // .step_num > ul
    position: relative;
    text-align: center;
  }
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin: 0px auto 42px;
    padding-top: 22px;
    background: url(${logoPng}) no-repeat left 24px;
    background-size: 128px auto;
    & > ul {
      text-align: right;
    }
  }
`;

const RegisterStepBlock = styled.li`
  display: inline-block;
  margin: 0 4px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin: 0 3px;
  }
  & > span {
    display: block;
    width: 30px;
    padding-top: 4px;
    height: 26px;
    background-color: #dde3eb;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      width: 20px;
      padding-top: 2.2px;
      height: 17.8px;
      font-size: 14px;
    }
    ${(props) =>
      props.complete &&
      !props.active &&
      css`
        height: 30px;
        padding-top: 2.5px;
        color: #7286ac;
        background-color: #fff;
        border: 1.5px solid #7286ac;
        box-sizing: border-box;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          padding-top: 0.5px;
          height: 20px;
        }
      `}
    ${(props) =>
      props.active &&
      css`
        background-color: #0572ef;
      `}
  }
`;

const RegisterSteps = (props) => {
  const { stepInfo, currentStep } = props;
  return (
    <RegisterStepsBlock>
      <ul>
        {stepInfo.map((step, idx) => (
          <RegisterStepBlock
            key={`step_${idx}`}
            active={Number(currentStep) === step.stepSeq}
            complete={step.stepSeq < Number(currentStep)}
          >
            <span>{step.title}</span>
          </RegisterStepBlock>
        ))}
      </ul>
    </RegisterStepsBlock>
  );
};

export default RegisterSteps;
