import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { reducer as issincReducer } from '../pages/IssueIncorporated/state/slice';
import { reducer as commonReducer } from './commonSlice';

import authSaga from '../services/api/auth/saga';
import agmSaga from '../services/api/agm/saga';
import commonSaga from '../services/api/common/saga';
import externalsSaga from '../services/api/externals/saga';
import filesSaga from '../services/api/files/saga';
import issueIncorporatedSaga from '../services/api/issueIncorporated/saga';
import usersSaga from '../services/api/users/saga';
import electionsSaga from '../services/api/elections/saga';
import termsSaga from '../services/api/terms/saga';
import brokerageSaga from '../services/api/brokerage/saga';

import { promiseMiddleware } from '@adobe/redux-saga-promise';
export const rootReducer = combineReducers({
  issinc: issincReducer,
  common: commonReducer
});

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([
    authSaga(),
    agmSaga(),
    commonSaga(),
    externalsSaga(),
    filesSaga(),
    issueIncorporatedSaga(),
    usersSaga(),
    electionsSaga(),
    termsSaga(),
    brokerageSaga()
  ]);
}

const createStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: [promiseMiddleware, sagaMiddleware]
  });

  sagaMiddleware.run(rootSaga);
  return store;
};

export default createStore;
