import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../../../../components/button/Button';
import DataTable, {
  DataType
} from '../../../../../../../components/datatable/DataTable';
import RowDataTable from '../../../../../../../components/datatable/RowDataTable';
import { MODAL_SIZE } from '../../../../../../../components/modal/Modal';
import { TableTitleBlock } from '../../../../../../../components/table/TableTitle';
import { ModalContext } from '../../../../../../../context/ModalContext';
import { useFileDownload } from '../../../../../../../hooks/useFileDownload';
import {
  fetchDeleteENoticeAgmSendAction,
  fetchGetENoticeAgmSendDetailAction,
  fetchGetFilesAgmAction
} from '../../../../../../../services/api/agm/promiseActions';
import {
  ElecBillFileType,
  getConstNameByCode,
  SendPlatForm,
  ReceiverType
} from '../../../../../../../utils/constant/AgmConstant';
import {
  DateFormatName,
  getDateFormat
} from '../../../../../../../utils/DateFormatUtil';
import { DATE_FORMAT } from '../../../../../../../utils/DateUtil';
import {
  CancelBtn,
  OkBtn
} from '../../../../../../shareholder/vote/main/components/popup/common/PopupButton';
import { ListWrapBlock } from '../../../../../dashboard/CorpDashboardPage';

const NoticeAgm = ({ agmSeqno, electronicNotice }) => {
  const [noticeDetail, setNoticeDetail] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const { openModal, setModalConfig, closeModal } = useContext(ModalContext);
  useEffect(() => {
    const data = {
      agmSeqno: agmSeqno,
      sendSeqno: electronicNotice.sendSeqno
    };
    dispatch(fetchGetENoticeAgmSendDetailAction(data)).then((res) => {
      if (!res.error) {
        const data = res.data;
        const datetime = electronicNotice.hasOwnProperty('reservationDatetime')
          ? data.reservationDatetime
          : data.sendDatetime;
        setNoticeDetail((prev) => [
          {
            ...data,
            sendDatetime: DATE_FORMAT(
              datetime,
              getDateFormat(DateFormatName.FULL_DATE_TIME)
            ),
            intermediator: getConstNameByCode(SendPlatForm, data.intermediator),
            attachFiles: data.files.map((file) =>
              fileDownColumn(ElecBillFileType.ELEC_BILL.name, file)
            )
          }
        ]);
      }
    });
  }, [electronicNotice]);

  const getSendStatus = () => {
    if (electronicNotice.hasOwnProperty('successCount')) {
      return '발송 완료';
    } else if (!electronicNotice.hasOwnProperty('sendDatetime')) {
      return '발송 전';
    } else {
      return '발송 중';
    }
  };

  const fileDownColumn = (name, file) => {
    return (
      <>
        <FileButtonBlock onClick={() => onClickFileDownload(file)}>
          {file?.fileName?.lastIndexOf('.') > 11
            ? file.fileName.substring(0, 15) +
              '… ' +
              file.fileName.substring(file.fileName.lastIndexOf('.'))
            : file?.fileName}
        </FileButtonBlock>
      </>
    );
  };
  const { callDownloadFetchAPIParam } = useFileDownload();
  const onClickFileDownload = (file) => {
    callDownloadFetchAPIParam(file, fetchGetFilesAgmAction, agmSeqno);
  };

  const agmNoticeColumn = [
    {
      title: '(예약)발송시간',
      key: 'sendDatetime'
      //   col: '10%'
    },
    {
      title: '발송 플랫폼',
      key: 'intermediator'
      //   col: '15%'
    },
    {
      title: '첨부파일',
      key: 'attachFiles'
      //   col: '10%'
    },
    {
      title: '총 발송 주주 수 (명)',
      key: 'receiverCount',
      type: DataType.NUMBER
      //   col: '18%'
    },
    {
      title: '총 주주 수 (명)',
      key: 'totalShareholderCount',
      type: DataType.NUMBER
      //   col: '*',
      //   align: 'left'
    }
  ];

  const getSendState = () => {
    return (
      <>
        <p>{getConstNameByCode(ReceiverType, electronicNotice.receiverType)}</p>
        <h3>
          <b>{getSendStatus()}</b>
        </h3>
      </>
    );
  };
  const getSendPercent = () => {
    return (
      <>
        <h3>
          <HighlightBlock color="blue">
            {Math.round(
              (electronicNotice.successCount /
                electronicNotice.totalShareholderCount) *
                100
            )}
            %
          </HighlightBlock>
          {getSendStatus()}
        </h3>
        {electronicNotice.successCount} /{' '}
        {electronicNotice.totalShareholderCount} 명
      </>
    );
  };
  const getNotReceivedData = () => {
    return (
      <>
        <h3>
          <HighlightBlock color="red">
            {Math.round(
              (electronicNotice.failCount /
                electronicNotice.totalShareholderCount) *
                100
            )}
            %
          </HighlightBlock>{' '}
          발송 실패
        </h3>
        {electronicNotice.failCount} / {electronicNotice.totalShareholderCount}{' '}
        명
      </>
    );
  };

  const onClickUpdateElecBill = () => {
    history.push({
      pathname: `/corp/agm/info/${agmSeqno}/electronic-billings/send`,
      state: { updateFlag: true, sendSeqno: electronicNotice.sendSeqno }
    });
  };

  const onClickDeleteElecBill = () => {
    setModalConfig({
      size: MODAL_SIZE.SHORT,
      buttons: [
        <CancelBtn onClick={() => closeModal(250)} title="취소" />,
        <OkBtn
          onClick={() => {
            deleteElecBill();
            closeModal(250);
          }}
          title="확인"
        />
      ]
    });
    openModal('확인', '취소하시겠습니까?');
  };

  const deleteElecBill = () => {
    setModalConfig({ size: MODAL_SIZE.SHORT });
    dispatch(
      fetchDeleteENoticeAgmSendAction({
        agmSeqno: agmSeqno,
        sendSeqno: electronicNotice.sendSeqno
      })
    ).then((res) => {
      if (!res.error) {
        openModal('확인', '취소되었습니다.', () => {
          history.push('/corp/agm/dashboard');
        });
      }
    });
  };

  return (
    <ListWrapBlock>
      <TableTitleBlock>
        <span>주주총회 소집 통지서</span>
      </TableTitleBlock>
      <RowTableBlock>
        <RowDataTable columnName="발송 현황" dataSource={[getSendState()]} />
        {electronicNotice.hasOwnProperty('successCount') ? (
          <RowDataTable
            columnName="발송률"
            dataSource={[getSendPercent(), getNotReceivedData()]}
          />
        ) : (
          <RowDataTable style={{ visibility: 'hidden' }} />
        )}
      </RowTableBlock>
      {!!noticeDetail && (
        <DataTable columns={agmNoticeColumn} dataSource={noticeDetail} />
      )}
      <NoticeButtonBlock>
        {!electronicNotice.hasOwnProperty('sendDatetime') && (
          <Button
            type={ButtonTypes.Primary}
            size={ButtonSize.Popup}
            onClick={onClickDeleteElecBill}
          >
            전자고지 취소
          </Button>
        )}
        <Button
          type={ButtonTypes.Basic}
          size={ButtonSize.Popup}
          onClick={onClickUpdateElecBill}
        >
          전자고지 내용 수정
        </Button>
      </NoticeButtonBlock>
    </ListWrapBlock>
  );
};

const NoticeButtonBlock = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-end;
`;

const RowTableBlock = styled.div`
  display: flex;
  gap: 50px;
`;

const HighlightBlock = styled.span`
  ${(props) => css`
    color: ${props.color};
  `}
`;

const FileButtonBlock = styled.a`
  border-bottom: 1px solid #0572ef;
  cursor: pointer;
`;

export default NoticeAgm;
