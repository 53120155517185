import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../../components/button/Button';
import ButtonGroup from '../../../../../components/button/ButtonGroup';
import PropTypes from 'prop-types'; // ES6

const AgmRegisterPageButton = ({ handleNextBtn, step }) => {
  const match = useRouteMatch();
  const { agmSeqno } = match.params;
  let history = useHistory();
  const moveStep = (step) => {
    // history.push(`/corp/agm/${agmSeqno}/register/${step}`);
    if (match.path.includes('test')) {
      history.push(`/corp/agm/${agmSeqno}/register/${step}/test`);
    } else {
      history.push(`/corp/agm/${agmSeqno}/register/${step}`);
    }
  };

  const movePrevStep = () => {
    moveStep(Number(step) - 1);
  };
  const moveNextStep = () => {
    handleNextBtn();
    //moveStep(Number(step) + 1);
  };

  return (
    <ButtonGroup>
      {step !== '1' && (
        <Button
          type={ButtonTypes.Basic}
          size={ButtonSize.Medium}
          onClick={movePrevStep}
        >
          이전
        </Button>
      )}

      {step === '4' ? (
        <Button
          type={ButtonTypes.Primary}
          size={ButtonSize.Medium}
          onClick={moveNextStep}
        >
          확정
        </Button>
      ) : (
        <Button
          type={ButtonTypes.Primary}
          size={ButtonSize.Medium}
          onClick={moveNextStep}
        >
          다음
        </Button>
      )}
    </ButtonGroup>
  );
};

AgmRegisterPageButton.propTypes = {
  usePrev: PropTypes.bool,
  useNext: PropTypes.bool,
  useComplete: PropTypes.bool
};

AgmRegisterPageButton.defaultProps = {
  usePrev: false,
  useNext: false,
  useComplete: false
};

export default AgmRegisterPageButton;
