import React from 'react';
import styled, { css } from 'styled-components';
import individualPng from '../../../../styles/images/common/sprite/mem_ty_01.png';
import corpPng from '../../../../styles/images/common/sprite/mem_ty_02.png';
import issincPng from '../../../../styles/images/common/sprite/mem_ty_03.png';
import PropsType from 'prop-types';
import { UserType } from '../../../../utils/constant/AgmConstant';
import { SCREEN_SIZE_MOBILE } from '../../../../components/button/CommonStyle';

const SignupImgBtnBlock = styled.button`
  // .mem_cont2 > ul > li > button
  position: relative;
  display: block;
  width: 100%;
  padding: 150px 40px 24px 50px;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 28px;
  line-height: 1.54;
  letter-spacing: -0.5px;
  text-align: center;
  background-color: #fcfcfd;
  border: 1px solid #c8d3e1;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: relative;
    padding: 22px 0 22px 72px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
    text-align: left !important;
  }
  &:hover {
    border: 2px solid #0094ff;
    background-color: #fff;
  }
  &::before {
    ${(props) => {
      switch (props.user) {
        case 'individual':
          return css`
            content: '';
            position: absolute;
            top: 32px;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            width: 85px;
            height: 125px;
            background: url(${individualPng}) no-repeat;
            background-size: 85px auto;
            @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
              transform: translateY(-50%);
              top: 50%;
              left: 22px;
              width: 32px;
              height: 38px;
              background-size: 32px auto;
            }
          `;
        case 'corp':
          return css`
            content: '';
            position: absolute;
            top: 28px;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            width: 105px;
            height: 125px;
            background: url(${corpPng}) no-repeat;
            background-size: 105px auto;
            @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
              transform: translateY(-50%);
              top: 50%;
              left: 17px;
              width: 42px;
              height: 44px;
              background-size: 42px auto;
            }
          `;
        case 'issinc':
          return css`
            content: '';
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            width: 92px;
            height: 125px;
            background: url(${issincPng}) no-repeat;
            background-size: 92px auto;
            @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
              transform: translateY(-50%);
              top: 50%;
              left: 23px;
              width: 32px;
              height: 39px;
              background-size: 32px auto;
            }
          `;
        default:
          break;
      }
    }}
  }
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 48%;
      right: 14px;
      width: 6px;
      height: 6px;
      border-top: 1px solid #121418;
      border-right: 1px solid #121418;
      transform: rotate(45deg);
    }
  }
  & > p {
    margin-top: 4px;
    font-size: 16px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      font-size: 12px;
      font-weight: normal;
    }
  }
`;

const SignupImgBtn = ({ title, desc, userType, onClick }) => {
  const onClickHandler = (type) => {
    switch (type) {
      case UserType.INDIVIDUAL.name:
        onClick(UserType.INDIVIDUAL.code);
        break;
      case UserType.CORP.name:
        onClick(UserType.CORP.code);
        break;
      case UserType.ISSINC.name:
        onClick(UserType.ISSINC.code);
        break;
      default:
        break;
    }
  };
  return (
    <SignupImgBtnBlock user={userType} onClick={() => onClickHandler(userType)}>
      {title}
      <p>{desc}</p>
    </SignupImgBtnBlock>
  );
};

export default SignupImgBtn;

SignupImgBtn.propTypes = {
  title: PropsType.string,
  desc: PropsType.string,
  userType: PropsType.string
};
