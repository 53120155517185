import PropsType from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CheckBox from '../input/CheckBox';
import Column from './Column';
import Data from './Data';
import Filter from './Filter';
import TableColumns from './TableColumns';
import TableData from './TableData';
import TableDataRow from './TableDataRow';
import TableHeader from './TableHeader';
import TableNoData from './TableNoData';
import TableTitle from './TableTitle';

export const TableDataType = {
  NUMBER: 'number',
  DATETIME: 'datetime',
  CHECKBOX: 'checkbox'
};

const TableBlock = styled.div``;

const Table = (props) => {
  const { title, columns, dataSource, rowSelection } = props;
  const { selectedRowKeys } = rowSelection;
  const [allChecked, setAllChecked] = useState(false);

  const dataIndex = columns.map((column) => ({
    key: column.refId,
    dataType: column.type
  }));

  const checkboxIndex = dataSource.map((data) => data.index);

  useEffect(() => {
    if (selectedRowKeys) {
      setAllChecked(checkboxIndex.length === selectedRowKeys.length);
    }
  }, [checkboxIndex, selectedRowKeys]);

  const allSelect = (checked) => {
    if (!checked) {
      rowSelection.onChange([]);
    } else {
      rowSelection.onChange(checkboxIndex);
    }
  };

  const setChecked = (checked, value) => {
    if (checked) {
      // 선택 시
      rowSelection.onChange([...rowSelection.selectedRowKeys, value]);
    } else {
      rowSelection.onChange(
        rowSelection.selectedRowKeys.filter((item) => item !== value)
      );
    }
  };

  const renderColumns = () => {
    return (
      <>
        {rowSelection && (
          <Column dataType={TableDataType.CHECKBOX}>
            <CheckBox checked={allChecked} onChange={allSelect} />
          </Column>
        )}
        {columns.map((column, idx) => (
          <Column key={`column_${idx}`} dataType={column.type}>
            {column.title}
          </Column>
        ))}
      </>
    );
  };

  const renderDataRow = (dataSource) => {
    if (dataSource.length > 0) {
      return dataSource.map((data, idx) => {
        const selectedRow = rowSelection
          ? rowSelection.selectedRowKeys.includes(idx)
          : false;
        return (
          <TableDataRow key={`row_${idx}`} checked={selectedRow}>
            {rowSelection && (
              <Data dataType={TableDataType.CHECKBOX}>
                <CheckBox
                  checked={selectedRow}
                  onChange={setChecked}
                  value={idx}
                />
              </Data>
            )}
            {dataIndex.map((dataConf, dataIdx) => (
              <Data key={`data_${idx}_${dataIdx}`} dataType={dataConf.dataType}>
                {data[dataConf.key]}
              </Data>
            ))}
          </TableDataRow>
        );
      });
    } else {
      return (
        <TableDataRow>
          <TableNoData />
        </TableDataRow>
      );
    }
  };

  return (
    <TableBlock>
      <TableHeader>
        <TableTitle>{title}</TableTitle>
        {rowSelection?.selectedRowKeys && (
          <Filter
            count={rowSelection.selectedRowKeys.length}
            description="하하하하"
          />
        )}
      </TableHeader>
      <TableColumns>{renderColumns()}</TableColumns>
      <TableData>{renderDataRow(dataSource)}</TableData>
    </TableBlock>
  );
};

Table.propTypes = {
  title: PropsType.string,
  columns: PropsType.array,
  dataSource: PropsType.array,
  rowSelection: PropsType.object
};

Table.defaultProps = {
  title: '',
  columns: [],
  dataSource: [],
  rowSelection: null
};

export default Table;
