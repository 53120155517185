import React from 'react';
export const LayerBodyContent = (props) => {
  const childArr = Array.isArray(props.children)
    ? props.children
    : [props.children];
  return (
    <>
      {childArr.map((child, idx) => {
        return React.cloneElement(child, {
          heightType: child.type.name === 'ListItem' ? 'short' : null
        });
      })}
    </>
  );
};
