import contract1Jpg from '../../../../../../styles/images/common/sprite/issinc/contract_1.jpg';
import contract2Jpg from '../../../../../../styles/images/common/sprite/issinc/contract_2.jpg';
import contract3Jpg from '../../../../../../styles/images/common/sprite/issinc/contract_3.jpg';
import contract4Jpg from '../../../../../../styles/images/common/sprite/issinc/contract_4.jpg';
import { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useHistory } from 'react-router';
import { Tab_State_Const } from '../../../../../../utils/constant/AgmConstant';
import {
  SerInnerBlock,
  SerStepBlock,
  SerStepItemBlock,
  SerViewBlock,
  SerViewItemBlock,
  SerViewNextBtnBlock,
  SerViewPrevBtnBlock,
  StyledSlider
} from '../../components/InnerBlock';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../../../components/button/Button';

const Contract = ({ title }) => {
  const [showIndex, setShowIndex] = useState(0);
  const [nav, setNav] = useState();
  const history = useHistory();

  const Images = [
    {
      url: contract1Jpg,
      desc: '메인 화면에서 로그인 버튼을 클릭하여 로그인 화면으로 이동'
    },
    {
      url: contract2Jpg,
      desc: '로그인 화면에서 서비스 가입 버튼을 눌러 서비스 가입 절차를 진행'
    },
    {
      url: contract3Jpg,
      desc: '개인은 개인사용자 가입으로, 법인은 법인사용자 가입으로 진행'
    },
    { url: contract4Jpg, desc: '순서대로 기입하여 선택하면 가입 완료' }
  ];

  const length = Images.length;

  useEffect(() => {
    nav && nav.slickGoTo(showIndex);
  }, [showIndex, nav]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    swipeToSlide: false
  };

  const onClickPrevBtn = () => {
    if (showIndex !== 0) {
      setShowIndex((index) => index - 1);
    } else {
      setShowIndex(length - 1);
    }
  };

  const onClickNextBtn = () => {
    if (showIndex !== length - 1) {
      setShowIndex((index) => index + 1);
    } else {
      setShowIndex(0);
    }
  };

  const getShowState = (state) => {
    return showIndex === state
      ? Tab_State_Const.ING
      : showIndex < state
      ? Tab_State_Const.WILL
      : Tab_State_Const.DONE;
  };

  return (
    <SerInnerBlock>
      {/* <h3>{title}</h3> */}
      <SerStepBlock>
        <ul>
          {/* step 개수대로 map 돌려야됨 */}
          {Array(length)
            .fill()
            .map((_, index) => {
              return (
                <SerStepItemBlock state={getShowState(index)}>
                  <a href="#!" onClick={() => setShowIndex(index)}>
                    {index + 1}
                  </a>
                </SerStepItemBlock>
              );
            })}
        </ul>
      </SerStepBlock>
      <SerViewBlock>
        <SerViewPrevBtnBlock onClick={onClickPrevBtn}>이전</SerViewPrevBtnBlock>
        <SerViewNextBtnBlock onClick={onClickNextBtn}>다음</SerViewNextBtnBlock>
        <StyledSlider {...settings} ref={(c) => setNav(c)}>
          {Images.map((image, index) => {
            return (
              <SerViewItemBlock key={index} notImportant>
                <img src={image.url} alt={`image_${index}`} />
                <p>{image.desc}</p>
              </SerViewItemBlock>
            );
          })}
        </StyledSlider>
        <Button
          type={ButtonTypes.Defaults}
          size={ButtonSize.Medium}
          onClick={() => history.push('/login?type=corp')}
        >
          서비스 계약 바로가기
        </Button>
      </SerViewBlock>
    </SerInnerBlock>
  );
};

export default Contract;
