import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import voteProxy1JpgM from '../../../../../../styles/images/common/sprite/stockholder/mobile/vote_proxy_1.jpg';
import voteProxy2JpgM from '../../../../../../styles/images/common/sprite/stockholder/mobile/vote_proxy_2.jpg';
import voteProxy3JpgM from '../../../../../../styles/images/common/sprite/stockholder/mobile/vote_proxy_3.jpg';
import voteProxy4JpgM from '../../../../../../styles/images/common/sprite/stockholder/mobile/vote_proxy_4.jpg';
import voteProxy1Jpg from '../../../../../../styles/images/common/sprite/stockholder/vote_proxy_1.jpg';
import voteProxy2Jpg from '../../../../../../styles/images/common/sprite/stockholder/vote_proxy_2.jpg';
import voteProxy3Jpg from '../../../../../../styles/images/common/sprite/stockholder/vote_proxy_3.jpg';
import voteProxy4Jpg from '../../../../../../styles/images/common/sprite/stockholder/vote_proxy_4.jpg';
import { Tab_State_Const } from '../../../../../../utils/constant/AgmConstant';
import {
  SerInnerBlock,
  SerStepBlock,
  SerStepItemBlock,
  SerViewBlock,
  SerViewItemBlock,
  SerViewNextBtnBlock,
  SerViewPrevBtnBlock,
  StyledSlider
} from '../../components/InnerBlock';

const VoteAndProxy = ({ title }) => {
  const [showIndex, setShowIndex] = useState(0);
  const [nav, setNav] = useState();

  const Images = [
    {
      url: isMobile ? voteProxy1JpgM : voteProxy1Jpg,
      desc: '로그인 후 본인이 원하는 회사의 전자투표 클릭<br />(전자위임의 경우 전자위임 클릭)'
    },
    {
      url: isMobile ? voteProxy2JpgM : voteProxy2Jpg,
      desc: 'DID주주증명으로 주주총회를 진행하려면 DID주주 발급을,<br />아니면 주민등록번호로 주주 인증을 받습니다.'
    },
    {
      url: isMobile ? voteProxy3JpgM : voteProxy3Jpg,
      desc: '투표를 진행해주시고 전자의결권 행사를 클릭해주세요.<br />전자 위임장의 경우 위임할 권유자를 선택하고 전자위임장수여를 클릭해주시면 됩니다.'
    },
    {
      url: isMobile ? voteProxy4JpgM : voteProxy4Jpg,
      desc: '마지막으로 확인을 눌러주시면 투표 끝 <br /> DID주주 증명의 경우 DID주주증명서 제출로 투표가 완료되며 <br /> 그렇지 않으면 공인인증서로 본인인증을 해주셔야 합니다.'
    }
  ];

  const length = Images.length;

  useEffect(() => {
    nav && nav.slickGoTo(showIndex);
  }, [showIndex, nav]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    swipeToSlide: false
  };

  const onClickPrevBtn = () => {
    if (showIndex !== 0) {
      setShowIndex((index) => index - 1);
    } else {
      setShowIndex(length - 1);
    }
  };

  const onClickNextBtn = () => {
    if (showIndex !== length - 1) {
      setShowIndex((index) => index + 1);
    } else {
      setShowIndex(0);
    }
  };

  const getShowState = (state) => {
    return showIndex === state
      ? Tab_State_Const.ING
      : showIndex < state
      ? Tab_State_Const.WILL
      : Tab_State_Const.DONE;
  };

  return (
    <SerInnerBlock>
      {/* <h3>{title}</h3> */}
      <SerStepBlock>
        <ul>
          {/* step 개수대로 map 돌려야됨 */}
          {Array(length)
            .fill()
            .map((_, index) => {
              return (
                <SerStepItemBlock state={getShowState(index)}>
                  <a href="#!" onClick={() => setShowIndex(index)}>
                    {index + 1}
                  </a>
                </SerStepItemBlock>
              );
            })}
        </ul>
      </SerStepBlock>
      <SerViewBlock>
        <SerViewPrevBtnBlock onClick={onClickPrevBtn}>이전</SerViewPrevBtnBlock>
        <SerViewNextBtnBlock onClick={onClickNextBtn}>다음</SerViewNextBtnBlock>
        <StyledSlider {...settings} ref={(c) => setNav(c)}>
          {Images.map((image, index) => {
            return (
              <SerViewItemBlock key={index} notImportant>
                <img src={image.url} alt={`image_${index}`} />
                <p
                  dangerouslySetInnerHTML={{
                    __html: image.desc
                  }}
                />
              </SerViewItemBlock>
            );
          })}
        </StyledSlider>
        <button style={{ display: 'none' }} />
      </SerViewBlock>
    </SerInnerBlock>
  );
};

export default VoteAndProxy;
