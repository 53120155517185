/*
    Icons
*/

import { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../components/button/CommonStyle';
import spriteCommonPng from './images/common/sprite/sp_common.png';
import spriteCommonMobilePng from './images/common/sprite/sp_common_mobile.png';

const SEARCH_WHITE = 'search_white';
const SEARCH_BLUE = 'search_blue';
const LIST_TAG = 'list_tag';
const UNIT_TAG = 'unit_tag';
const PAPER_CHECK = 'paper_check';
const PAPER_ADD = 'paper_add';
const CHECK_OUTLINE_ON = 'check_outline_on';
const CHECK_OUTLINE_OFF = 'check_outline_off';
const LAYER_CLOSE = 'layer_close';
const ARROW_DOWN = 'arrow_down';
const ARROW_DOWN_ACTIVE = 'arrow_down_on';
const PAPER_DOWNLOAD = 'paper_download';
const CALENDAR_CLOSE = 'calendar_close';
export const Icons = {
  [SEARCH_WHITE]: {
    name: SEARCH_WHITE,
    className: 'btn_i01', // 아래 CSS 는 해당 클래스 참고 함
    css: css`
      &::before {
        content: '';
        position: absolute;
        top: 40%;
        ${(props) => getPositionCss(props)}
        display: block;
        width: 22px;
        height: 22px;
        margin-top: -5.5px;
        background: url(${spriteCommonPng}) no-repeat;
        background-size: 400px auto;
        background-position: -1px -95px;
      }
    `
  },
  [SEARCH_BLUE]: {
    name: SEARCH_BLUE,
    className: 'btn_i02',
    css: css`
      &::before {
        content: '';
        position: absolute;
        top: 40%;
        ${(props) => getPositionCss(props)}
        display: block;
        width: 22px;
        height: 22px;
        margin-top: -5.5px;
        background: url(${spriteCommonPng}) no-repeat;
        background-size: 400px auto;
        background-position: -1px -117px;
      }
    `
  },
  [LIST_TAG]: {
    name: LIST_TAG,
    className: 'list_tag',
    css: css`
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      display: block;
      width: 11px;
      height: 12px;
      margin-top: -5.5px;
      background: url(${spriteCommonPng}) no-repeat;
      background-size: 400px auto;
      background-position: -189px -1px;
    `
  },
  [UNIT_TAG]: {
    name: UNIT_TAG,
    className: 'span_tag',
    css: css`
      &::after {
        content: '';
        position: absolute;
        top: 0px;
        right: -16px;
        display: block;
        width: 11px;
        height: 12px;
        background: url(${spriteCommonPng}) no-repeat;
        background-size: 400px auto;
        background-position: -189px -1px;
      }
    `
  },
  [PAPER_CHECK]: {
    name: PAPER_CHECK,
    className: 'btn_i03.active',
    css: css`
      &::before {
        content: '';
        position: absolute;
        top: 36%;
        ${(props) => getPositionCss(props)}
        display: block;
        width: 24px;
        height: 26px;
        margin-top: -5.5px;
        background: url(${spriteCommonPng}) no-repeat;
        background-size: 400px auto;
        background-position: -33px -121px;
      }
    `
  },
  [PAPER_ADD]: {
    name: PAPER_ADD,
    className: 'btn_i03',
    css: css`
      &::before {
        content: '';
        position: absolute;
        top: 36%;
        ${(props) => getPositionCss(props)}
        display: block;
        width: 24px;
        height: 26px;
        margin-top: -5.5px;
        background: url(${spriteCommonPng}) no-repeat;
        background-size: 400px auto;
        background-position: -33px -95px;
      }
    `
  },
  [CHECK_OUTLINE_OFF]: {
    name: CHECK_OUTLINE_OFF,
    className: 'check_outline_off',
    css: css`
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 30px;
      background: url(${spriteCommonPng}) no-repeat;
      background-size: 400px auto;
      background-position: -123px -65px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        width: 17px;
        height: 17px;
        background: url(${spriteCommonMobilePng}) no-repeat;
        background-size: 230px auto;
        background-position: -86px -1px;
      }
    `
  },
  [CHECK_OUTLINE_ON]: {
    name: CHECK_OUTLINE_ON,
    className: 'btn_i03',
    css: css`
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 30px;
      background: url(${spriteCommonPng}) no-repeat;
      background-size: 400px auto;
      background-position: -83px -65px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        width: 17px;
        height: 17px;
        background: url(${spriteCommonMobilePng}) no-repeat;
        background-size: 230px auto;
        background-position: -86px -18px;

        border-color: #24375c;
      }
    `
  },
  [LAYER_CLOSE]: {
    name: LAYER_CLOSE,
    className: 'button.close',
    css: css`
      position: absolute;
      top: 26px;
      right: 24px;
      display: block;
      width: 13px;
      height: 0;
      padding-top: 13px;
      background: url(${spriteCommonPng}) no-repeat;
      background-size: 400px auto;
      background-position: -262px -1px;
      color: transparent;
      overflow: hidden;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        position: absolute;
        display: block;
        top: 20px;
        right: 20px;
        width: 13px;
        height: 13px;
        background: url(${spriteCommonMobilePng}) no-repeat;
        background-size: 230px auto;
        background-position: -151px -71px;
        color: transparent;
        overflow: hidden;
      }
    `
  },
  [ARROW_DOWN]: {
    name: ARROW_DOWN,
    className: 'button.arrow',
    css: css`
      position: absolute;
      top: 24px;
      right: 0;
      width: 16px;
      height: 10px;
      background: url(${spriteCommonPng}) no-repeat;
      background-size: 400px auto;
      background-position: -123px -1px;
      opacity: 0.2;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        position: absolute;
        top: 17px;
        right: 4px;
        width: 10px;
        height: 6px;
        background: url(${spriteCommonMobilePng}) no-repeat;
        background-size: 230px auto;
        background-position: -71px -1px;
        opacity: 0.2;
      }
    `
  },
  [ARROW_DOWN_ACTIVE]: {
    name: ARROW_DOWN_ACTIVE,
    className: 'button.arrow.on',
    css: css`
      position: absolute;
      top: 24px;
      right: 0;
      width: 16px;
      height: 10px;
      background: url(${spriteCommonPng}) no-repeat;
      background-size: 400px auto;
      background-position: -123px -1px;
      opacity: 1;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        position: absolute;
        top: 17px;
        right: 4px;
        width: 10px;
        height: 6px;
        background: url(${spriteCommonMobilePng}) no-repeat;
        background-size: 230px auto;
        background-position: -71px -1px;
        opacity: 1;
      }
    `
  },
  [PAPER_DOWNLOAD]: {
    name: PAPER_DOWNLOAD,
    className: 'btn_i07.before',
    css: css`
      &::before {
        content: '';
        position: absolute;
        top: 36%;
        right: 18px;
        display: block;
        width: 23px;
        height: 26px;
        margin-top: -5.5px;
        background: url(${spriteCommonPng}) no-repeat;
        background-size: 400px auto;
        background-position: -33px -173px;
        opacity: 1;
      }
    `
  },
  [CALENDAR_CLOSE]: {
    name: CALENDAR_CLOSE,
    className: 'button.close',
    css: css`
      position: absolute;
      top: 17px;
      right: 17px;
      /* top: 8px;
      right: 10px; */
      &::after {
        content: '';
        position: absolute;
        top: 3px;
        right: 3px;
        display: block;
        width: 13px;
        height: 14px;
        background: url(${spriteCommonPng}) no-repeat;
        background-size: 400px auto;
        background-position: -359px -29px;
      }
    `
  }
};

const getPositionCss = (props) => {
  return props.startIcon
    ? css`
        left: 28px;
      `
    : css`
        right: 22px;
      `;
};
