import { useState } from 'react';

const useModal = () => {
  let [modal, setModal] = useState(false);
  let [title, setTitle] = useState('');
  let [okHandler, setOkHandler] = useState(null);
  let [modalContent, setModalContent] = useState('');
  let [modalConfig, setModalConfig] = useState({});
  let [isRequired, setIsRequired] = useState(false);
  let [closeCallback, setCloseCallback] = useState();
  let [isRemoveCloseIcon, setIsRemoveCloseIcon] = useState(false);

  const closeModal = () => {
    setModal(false);
    setModalConfig({});
  };
  const openModal = (
    title = '확인',
    content = null,
    okHandler2 = closeModal,
    isRequired = false,
    closeHandler,
    removeCloseIconFlag = false
  ) => {
    setModal(true);
    setTitle(title);
    setModalContent(content);
    setIsRequired(isRequired);
    setIsRemoveCloseIcon(removeCloseIconFlag);
    if (typeof okHandler2 === 'function' && okHandler2 !== closeModal) {
      const closeAction = () => {
        okHandler2();
        closeModal();
      };
      setOkHandler(() => closeAction);
    } else {
      setOkHandler(() => closeModal);
    }
    if (typeof closeHandler === 'function') {
      setCloseCallback(() => closeHandler);
    }
  };

  let handleModal = (title = '확인', content = null, buttons = []) => {
    setModal(!modal);
    if (title) {
      setTitle(title);
    }
    if (content) {
      setModalContent(content);
    }
  };

  return {
    modal,
    handleModal,
    title,
    modalContent,
    openModal,
    okHandler,
    isRequired,
    closeModal,
    setModalConfig,
    setModalContent,
    modalConfig,
    closeCallback,
    isRemoveCloseIcon
  };
};

export default useModal;
