import { useState } from 'react';
import styled, { css } from 'styled-components';
import PropsType from 'prop-types';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';

const AboutPageTab = (props) => {
  const { itemArr, onChange } = props;
  const [activeNum, setActiveNum] = useState(0);

  const onClickBtn = (idx) => {
    setActiveNum(idx);
    typeof onChange === 'function' && onChange(idx);
  };

  return (
    <TabBlock>
      <ul>
        {itemArr.map((item, index) => {
          return (
            <TabLiBlock on={activeNum === index ? true : false}>
              <button onClick={() => onClickBtn(index)}>{item}</button>
            </TabLiBlock>
          );
        })}
      </ul>
    </TabBlock>
  );
};

export const TabBlock = styled.div`
  // .tab_ty
  max-width: 1360px;
  margin: 0 auto;
  // temp
  /* background: #fff; */

  &.fixed {
    position: fixed;
    width: 100%;
    left: 0%;
    right: 0%;
    z-index: 2;
    top: 88px;
  }
  & > ul {
    position: relative;
    display: flex;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #fff;
    }
    & > li {
      & a {
        position: relative;
        display: inline-block;
        width: 100%;
        padding: 20px 0 16px;
        background-color: transparent;
        font-size: 20px;
        letter-spacing: -1px;
        text-align: center;
        color: #ccd5e0;
        z-index: 1;
      }
    }
  }
`;

const TabLiBlock = styled.li`
  flex: 1;
  text-align: center;
  & button {
    width: 96%;
    margin: 14px 2% 10px;
    padding: 18px 0 16px;
    border-radius: 6px;
    border: solid 1px #e9ecf4;
    background-color: rgba(255, 255, 255, 0.35);
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.5px;
    text-align: center;
    color: #a0a6b5;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      font-size: 15px;
      margin: 16px 1% 2px;
      padding: 11px 0 10px;
      border-radius: 5px;
      font-size: 12px;
    }
    ${(props) =>
      props.on &&
      css`
        border-color: #284cb0;
        background-color: #284cb0;
        color: #fff;
      `}
  }
`;

AboutPageTab.propTypes = {
  itemArr: PropsType.array,
  onChange: PropsType.func
};

AboutPageTab.defaultProps = {
  itemArr: ['항목1', '항목2', '항목3']
};

export default AboutPageTab;
