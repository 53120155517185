import React from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import spriteCommonPng from '../../../../../styles/images/common/sprite/sp_common.png';
import { formatNumber } from '../../../../../utils/StringUtil';
import { VOTE_RESULT_CODE } from '../../VoteResultPage';
import { VOTE_RESULT_BUTTON_TYPE } from './DefaultButtonStyle';
import VoteResultButton from './VoteResultButton';
export const VOTE_RESULT_ITEM_TYPE = {
  Defualt: 0,
  Short: 1,
  Long: 2
};

const getVoteResultItemCss = (type) => {
  switch (type) {
    case VOTE_RESULT_ITEM_TYPE.Short:
      // .boxlist_ty .unit_result .short
      return css`
        width: 84px;
        font-size: 20px;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          width: 150px;
        }
      `;

    case VOTE_RESULT_ITEM_TYPE.Long:
      // .boxlist_ty .unit_result .long
      return css`
        width: 400px;
        padding-bottom: 16px;
        margin-bottom: 10px;
        border-bottom: 1px solid #e9ecf4;
      `;

    default:
      break;
  }
};

const VoteResultItemBlock = styled.li`
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    text-align: left;
  }
`;
const ItemTitleWrapperBlock = styled.a`
  // .boxlist_ty .unit_result li a
  display: inline-block;
  padding-right: 24px;
  position: relative;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding-right: 0;
    width: 70% !important;
    display: unset;
    & span {
      border-top: 0;
    }
  }
  ${(props) => {
    !props.isInvalid &&
      css`
        &::after {
          // .boxlist_ty .unit_result li a::after
          content: '';
          position: absolute;
          bottom: 30px;
          right: 0;
          width: 9px;
          height: 14px;
          background: url(${spriteCommonPng}) no-repeat;
          background-size: 400px auto;
          background-position: -312px -15px;
        }
      `;
  }}
  }
`;
const ItemTitleBlock = styled.span`
  // .boxlist_ty .unit_result li span
  position: relative;
  display: inline-block;
  width: 160px;
  padding: 12px 130px 10px 0;
  font-size: 18px;
  font-weight: bold;
  text-align: left;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: relative;
    display: inline-block;
    /* width: 178px; */
    /* padding: 10px 72px 12px 0; */
    padding: 10px 120px 12px 0;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    word-break: keep-all;
    &:not(:first-child) {
      border-top: 1px solid rgba(158, 179, 205, 0.52);
    }
    box-sizing: border-box;

    width: auto;
  }

  ${(props) => getVoteResultItemCss(props.type)}
  ${(props) =>
    props.isInvalid &&
    // .boxlist_ty .unit_invalid li span, .boxlist_ty .unit_invalid li span strong
    css`
      color: #9eb3cd;
      font-weight: normal;

      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        color: #9eb3cd;
        font-weight: normal;
      }

      & > strong {
        color: #9eb3cd;
        font-weight: normal;
      }
    `}
`;

const StockAmountBlock = styled.strong`
  // .boxlist_ty .unit_result li span strong
  position: absolute;
  right: 0;
  width: 120px;
  text-align: right;
  color: #0075ff;
`;

export const renderVoteResultButton = (
  result,
  childCount,
  onResultPopup,
  noResultButton = false
) => {
  if (noResultButton) return null;
  switch (result) {
    case VOTE_RESULT_CODE.Approved:
      return (
        <VoteResultButton
          type={VOTE_RESULT_BUTTON_TYPE.Approved}
          onClick={onResultPopup}
          nochild={childCount === 0}
        >
          가결
        </VoteResultButton>
      );
    case VOTE_RESULT_CODE.Rejected:
      return (
        <VoteResultButton
          type={VOTE_RESULT_BUTTON_TYPE.Rejected}
          onClick={onResultPopup}
          nochild={childCount === 0}
        >
          부결
        </VoteResultButton>
      );

    default:
      return <VoteResultButton isAbsten={true}>기권</VoteResultButton>;
  }
};

const VoteResultItem = (props) => {
  const {
    type,
    children,
    stockAmount = 0,
    result,
    onResultPopup,
    childCount,
    noResultButton = false
  } = props;

  return (
    <VoteResultItemBlock>
      {type === VOTE_RESULT_ITEM_TYPE.Long ? (
        <ItemTitleWrapperBlock {...props}>
          <ItemTitleBlock {...props}>
            {children}
            <StockAmountBlock>{`${formatNumber(
              stockAmount
            )}주`}</StockAmountBlock>
          </ItemTitleBlock>
        </ItemTitleWrapperBlock>
      ) : (
        <ItemTitleBlock {...props}>
          {children}
          <StockAmountBlock>{`${formatNumber(
            stockAmount
          )}주`}</StockAmountBlock>
        </ItemTitleBlock>
      )}
      {renderVoteResultButton(
        result,
        childCount,
        onResultPopup,
        noResultButton
      )}
    </VoteResultItemBlock>
  );
};

export default VoteResultItem;
