import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropsType from 'prop-types';

const Tab1Block = styled.div`
  // .tab_ty03
  position: relative;
  margin-bottom: 8px;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #e9ecf4;
  }
  & > ul {
    font-size: 0;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
  }
  & > ul > li {
    position: relative;
    display: inline-block;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 2px;
      height: 14px;
      margin-top: -7px;
      background-color: #edf0f5;
    }
  }
`;
const Tab1ABlock = styled.a`
  display: block;
  height: 100%;
  padding: 10px 24px;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 17px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #080808;
  cursor: pointer;
  ${(props) =>
    props.select &&
    css`
      color: #0572ef;
      font-weight: bold;
    `}
`;

const Tab2Block = styled.div`
  // .tab_ty03
  position: relative;
  margin-bottom: 8px;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #e9ecf4;
  }
  & > ul {
    font-size: 0;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  & > ul > li {
    display: inline-block;
  }
`;
const Tab2ABlock = styled.a`
  position: relative;
  display: block;
  height: 100%;
  padding: 5px 30px 15px;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: -0.5px;
  text-align: center;
  color: #080808;
  cursor: pointer;
  ${(props) =>
    props.select &&
    css`
      font-weight: 800;
      z-index: 1;
    `}
  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    width: 100%;
    max-width: 64%;
    height: 3px;
    background-color: #0572ef;
    transform: translateX(-50%);
    opacity: 0;
    ${(props) =>
      props.select &&
      css`
        opacity: 1;
      `}
  }
`;

export const ContentTabTypes = {
  Bold: 'bold',
  UnderLine: 'underLine'
};

const ContentTabs = (props) => {
  const { type, items, onClick } = props;

  switch (type) {
    case ContentTabTypes.Bold:
      return ContentTab1(items, onClick);
    case ContentTabTypes.UnderLine:
      return ContentTab2(items, onClick);
    default:
      break;
  }
};

const ContentTab1 = (items, onClick) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onclickTab1Li = (index) => {
    setActiveIndex(index);
    onClick(index);
  };
  const printLiTab2 = () => {
    return (
      <>
        {items.map((item, index) => {
          return (
            <li key={`tab1LiKey_${index}`}>
              <Tab1ABlock
                select={activeIndex === index}
                onClick={() => onclickTab1Li(index)}
                value={index}
              >
                {item}
              </Tab1ABlock>
            </li>
          );
        })}
      </>
    );
  };
  return (
    <Tab1Block>
      <ul>{printLiTab2()}</ul>
    </Tab1Block>
  );
};

const ContentTab2 = (items, onClick) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onclickTab2Li = (index) => {
    setActiveIndex(index);
    onClick(index);
  };
  const printTitle = () => {
    return (
      <>
        {items.map((item, index) => {
          return (
            <li key={`tab2LiKey_${index}`}>
              <Tab2ABlock
                select={activeIndex === index}
                onClick={() => onclickTab2Li(index)}
                value={index}
              >
                {item}
              </Tab2ABlock>
            </li>
          );
        })}
      </>
    );
  };
  return (
    <>
      <Tab2Block>
        <ul style={{ textAlign: 'center' }}>{printTitle()}</ul>
      </Tab2Block>
    </>
  );
};

ContentTabs.propTypes = {
  type: PropsType.string,
  items: PropsType.array,
  onClick: PropsType.func
};

ContentTabs.defaultProps = {
  type: '',
  items: ['항목1', '항목2', '항목3']
};

export default ContentTabs;
