import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import Button, { ButtonTypes } from '../../../../components/button/Button';
import CheckBox from '../../../../components/input/CheckBox';
import DateInput from '../../../../components/input/DateInput';
import FileInput from '../../../../components/input/FileInput';
import Input, { ErrorTypes } from '../../../../components/input/Input';
import InputBox from '../../../../components/input/InputBox';
import Radio from '../../../../components/input/Radio';
import RadioGroup from '../../../../components/input/RadioGroup';
import TimeInput from '../../../../components/input/TimeInput';
import Wave from '../../../../components/input/Wave';
import ListHeader from '../../../../components/layout/ListHeader';
import ListItem from '../../../../components/layout/ListItem';
import Section from '../../../../components/layout/Section';
import { ModalContext } from '../../../../context/ModalContext';
import { useFileDownload } from '../../../../hooks/useFileDownload';
import {
  fetchGetAgmBaseInfoAction,
  fetchGetFilesAgmAction,
  fetchPostAgmBaseInfoAction,
  fetchPutAgmBaseInfoAction
} from '../../../../services/api/agm/promiseActions';
import { fetchGetFilesTemporaryAction } from '../../../../services/api/files/promiseActions';
import { fetchGetIssueIncorporatedAction } from '../../../../services/api/issueIncorporated/promiseActions';
import {
  AgmFileType,
  AgmType,
  VotingRightsExerciseMethod
} from '../../../../utils/constant/AgmConstant';
import {
  DateFormatName,
  getDateFormat
} from '../../../../utils/DateFormatUtil';
import {
  checkInvalidDate,
  dateTimeToISOString,
  DATE_FORMAT
} from '../../../../utils/DateUtil';
import Validation from '../../../../utils/ValidationUtil';
import SearchAddressModal from '../../../common/signup/SearchAddressModal';
import CorporationManager from '../../../test/Test/page/CorporationManager';
import { action as agmAction } from '../../state/slice';
import { AGM_REGISTER_STEPS } from '../AgmRegister';
import AgmRegisterPageButton from '../main/components/AgmRegisterPageButton';

/*
  주주총회 등록 - 01. 주주총회 정보등록
*/
const AgmRegisterStep1 = ({ handleData, moveStep }) => {
  const match = useRouteMatch();
  const { agmSeqno } = match.params;
  const [isTestRegister, setIsTestRegister] = useState(false);
  const dispatch = useDispatch();
  const { callDownloadFetchAPIParam, callDownloadFetchAPITemporary } =
    useFileDownload();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors }
  } = useForm({ mode: 'all' });
  const [radioAgmType, setRadioAgmType] = useState('');
  const [checkBtnProxyUseFlag, setCheckBtnProxyUseFlag] = useState(false);
  const [checkBtnVoteUseFlag, setCheckBtnVoteUseFlag] = useState(false);
  const [oldFiles, setOldFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [dates, setDates] = useState({
    agmRecordDate: '',
    agmDate: '',
    agmTime: '',
    voteBeginDate: '',
    voteBeginTime: '',
    voteEndDate: '',
    voteEndTime: ''
  });
  const { openModal, closeModal } = useContext(ModalContext);
  const loadCpltRef = useRef(false);

  useEffect(() => {
    if (match.path.includes('test')) {
      isTestRegister || setIsTestRegister(true);
    }
    if (!!agmSeqno) {
      dispatch(fetchGetAgmBaseInfoAction(agmSeqno)).then((res) => {
        if (!res.error) {
          const data = res.data;
          data.baseAddress = data.agmPlace;
          setDates({
            agmRecordDate: new Date(data.agmRecordDate),
            agmDate: new Date(data.agmDateTime),
            agmTime: new Date(data.agmDateTime),
            voteBeginDate: new Date(data.voteBeginDateTime),
            voteBeginTime: new Date(data.voteBeginDateTime),
            voteEndDate: new Date(data.voteEndDateTime),
            voteEndTime: new Date(data.voteEndDateTime)
          });
          setRadioAgmType(data.agmType);
          setCheckBtnVoteUseFlag(data.electronicVoteUse);
          setCheckBtnProxyUseFlag(data.electronicProxyUse);
          setOldFiles([...data.files]);
          reset(data, ...data.files);
          loadCpltRef.current = true;
        }
      });
    } else {
      dispatch(fetchGetIssueIncorporatedAction()).then((res) => {
        if (!res.error) {
          const person = {
            personInCharge: { ...res.data?.personInCharge },
            solicitorName: res.data?.name
          };
          reset(person);
          loadCpltRef.current = true;
        }
      });
    }
  }, [dispatch, agmSeqno, reset]);

  const callback = (date, name) => {
    if (!checkInvalidDate(date) && loadCpltRef.current) {
      if (name !== 'agmDate') {
        dates[name] = date;
      } else {
        setDates({
          ...dates,
          agmDate: new Date(date),
          agmTime: new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            9,
            0
          ),
          voteBeginDate: new Date(date.setDate(date.getDate() - 10)),
          voteBeginTime: new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            9,
            0
          ),
          voteEndDate: new Date(date.setDate(date.getDate() + 9)),
          voteEndTime: new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            17,
            0
          )
        });
      }
    }
  };

  const handleStep1Data = (data) => {
    // post인지 put인지 정해야함
    const action = !!agmSeqno
      ? fetchPutAgmBaseInfoAction
      : fetchPostAgmBaseInfoAction;
    const obj = {
      agmSeqno: !!agmSeqno && agmSeqno,
      data: {
        ...data,
        agmPlace: data.baseAddress,
        agmType: radioAgmType,
        agmRecordDate: DATE_FORMAT(
          dates.agmRecordDate,
          getDateFormat(DateFormatName.SHORT_DATE_HYPEN)
        ),
        agmDateTime: dateTimeToISOString(
          new Date(dates.agmDate),
          new Date(dates.agmTime)
        ),
        voteBeginDateTime: dateTimeToISOString(
          new Date(dates.voteBeginDate),
          new Date(dates.voteBeginTime)
        ),
        voteEndDateTime: dateTimeToISOString(
          new Date(dates.voteEndDate),
          new Date(dates.voteEndTime)
        ),
        files: newFiles.length !== 0 ? newFiles : oldFiles,
        voteProxies: data.voteProxies?.filter((value) => value !== ''),
        test: isTestRegister ? true : false
        // electronicNoticeUse: true
      }
    };
    dispatch(action(obj))
      .then((res) => {
        if (!res.error) {
          dispatch(agmAction.setAgmRegisterNewSeqno(res.data.agmSeqno));
          moveNextStep(res.data.agmSeqno);
        } else {
          openModal('알림', res.error.user_message);
        }
      })
      .catch((e) => {
        console.log(JSON.stringify(e));
        openModal('알림', e);
      });
  };

  const moveNextStep = (agmSeqno) => {
    typeof moveStep === 'function' &&
      moveStep(AGM_REGISTER_STEPS.AGENDA, agmSeqno);
  };
  const onChangeAgmType = (value) => {
    if (!!value) {
      setValue('agmType', value);
      setRadioAgmType(value);
    }
  };
  const findFileByType = (type) => {
    let result = oldFiles.find((file) => file.type === type);
    if (!!result) {
      return result;
    } else {
      result = newFiles.find((file) => file.type === type);
      return result;
    }
  };

  const onChangeProxyUse = (e) => {
    setCheckBtnProxyUseFlag((flag) => !flag);
  };

  const fileDownload = (fileType) => {
    const file = oldFiles.find((target) => target.type === fileType);
    if (typeof file === 'undefined') {
      const file = newFiles.find((target) => target.type === fileType);
      typeof file !== 'undefined' &&
        callDownloadFetchAPITemporary(file, fetchGetFilesTemporaryAction);
    } else {
      callDownloadFetchAPIParam(file, fetchGetFilesAgmAction, agmSeqno);
    }
  };

  const blankCheck = (value) => {
    if (getValues('agmType') === AgmType.TEMPORARY.code && value === '') {
      return true;
    } else if (getValues('agmType') === AgmType.GENERAL.code && value === '') {
      return false;
    } else if (typeof getValues('agmType') === 'undefined') {
      return false;
    } else {
      return true;
    }
  };

  const onSetBaseAddr = (addr) => {
    setValue('baseAddress', addr);
    closeModal();
  };
  const openSearchAddressModal = () => {
    openModal(
      '주소 검색',
      <SearchAddressModal onSetBaseAddr={onSetBaseAddr} />
    );
  };

  const callbackFileUpload = (file, type) => {
    if (!newFiles.find((item) => item.type === type)) {
      setNewFiles((prev) => [...prev, file]);
    } else {
      setNewFiles((prev) => [
        ...prev.filter((item) => item.type === type),
        file
      ]);
    }
  };

  const onDeleteFile = (fileType) => {
    setOldFiles((prev) =>
      prev.filter((file) => file.type !== AgmFileType.RESOLUTION.code)
    );
    setNewFiles((prev) =>
      prev.filter((file) => file.type !== AgmFileType.RESOLUTION.code)
    );
  };

  return (
    <>
      <Section>
        <ListHeader title="주주총회" isRequired />
        <ListItem>
          <InputBox title="주주총회 기준일" isRequired noFlex>
            <DateInput
              name="agmRecordDate"
              defaultValue={dates.agmRecordDate}
              callback={callback}
            />
          </InputBox>
          <InputBox title="신청 서비스" isRequired noFlex>
            <CheckBox
              register={register}
              name="electronicVoteUse"
              title={VotingRightsExerciseMethod.ELECTRONIC_VOTE.name}
              checked={checkBtnVoteUseFlag}
            />
            <CheckBox
              register={register}
              name="electronicProxyUse"
              title={VotingRightsExerciseMethod.ELECTRONIC_PROXY.name}
              checked={checkBtnProxyUseFlag}
              onChange={onChangeProxyUse}
            />
            {/* <CheckBox
              register={register}
              name="electronicBillUse"
              title={VotingRightsExerciseMethod.ELECTRONIC_BILL.name}
              checked={checkBtnBillUseFlag}
              onChange={onChangeBillUse}
              desc={VotingRightsExerciseMethod.ELECTRONIC_BILL.desc}
            /> */}
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox
            title="주주총회 구분"
            isRequired
            noFlex
            error={errors.agmType?.message}
            errorType={ErrorTypes.ERROR}
          >
            <RadioGroup
              register={register}
              name="agmType"
              selectedValue={radioAgmType}
              onChange={onChangeAgmType}
              registerOption={{
                required: {
                  value: true,
                  message: '주주총회 구분을 선택해주세요.'
                }
              }}
            >
              <Radio
                label={AgmType.GENERAL.name}
                value={AgmType.GENERAL.code}
              />
              <Radio
                label={AgmType.TEMPORARY.name}
                value={AgmType.TEMPORARY.code}
              />
            </RadioGroup>
          </InputBox>
          <InputBox
            title="주주총회 기수"
            error={errors.agmSessionCount?.message}
            errorType={ErrorTypes.ERROR}
            isRequired={
              !radioAgmType || radioAgmType === AgmType.GENERAL.code
                ? true
                : false
            }
          >
            <Input
              size="short"
              register={register}
              name="agmSessionCount"
              registerOption={{
                pattern: {
                  value: /^[0-9]+$/,
                  message: '숫자만 입력 가능합니다.'
                },
                validate: {
                  positive: (value) =>
                    blankCheck(value) || '기수를 입력해주세요'
                },
                min: 1
              }}
            />
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox title="주주총회 일시" isRequired noFlex>
            <DateInput
              name="agmDate"
              callback={callback}
              defaultValue={dates.agmDate}
            />
            <TimeInput
              name="agmTime"
              callback={callback}
              defaultValue={dates.agmTime}
            />
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox title="전자투표/전자 위임장 수여 일정" isRequired noFlex>
            <DateInput
              name="voteBeginDate"
              callback={callback}
              defaultValue={dates.voteBeginDate}
            />
            <TimeInput
              name="voteBeginTime"
              callback={callback}
              defaultValue={dates.voteBeginTime}
            />
            <Wave />
            <DateInput
              name="voteEndDate"
              callback={callback}
              defaultValue={dates.voteEndDate}
            />
            <TimeInput
              name="voteEndTime"
              callback={callback}
              defaultValue={dates.voteEndTime}
            />
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox title="주주총회 주소" name="address" isRequired>
            <Input
              register={register}
              name="baseAddress"
              // name="agmPlace"
              placeholder="주소를 검색해주세요"
            />
            <Button type={ButtonTypes.Basic} onClick={openSearchAddressModal}>
              검색
            </Button>
          </InputBox>
        </ListItem>
      </Section>
      <Section>
        <ListHeader title="첨부파일" />
        <ListItem>
          <InputBox title="전자투표 이사회 결의서" isRequired>
            <FileInput
              fileType={AgmFileType.RESOLUTION.code}
              setFile={
                !!Object.keys(oldFiles).length &&
                findFileByType(AgmFileType.RESOLUTION.code)
              }
              callback={callbackFileUpload}
              fileDownload={fileDownload}
              onDelete={() => onDeleteFile(AgmFileType.RESOLUTION.code)}
            />
          </InputBox>
          <InputBox title="주주총회 소집 공고문" isRequired>
            <FileInput
              fileType={AgmFileType.ANNOUNCEMENT.code}
              setFile={
                !!Object.keys(oldFiles).length &&
                findFileByType(AgmFileType.ANNOUNCEMENT.code)
              }
              callback={callbackFileUpload}
              fileDownload={fileDownload}
              onDelete={() => onDeleteFile(AgmFileType.ANNOUNCEMENT.code)}
            />
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox title="사업보고서">
            <FileInput
              fileType={AgmFileType.BUSINESS_REPORT.code}
              setFile={
                !!Object.keys(oldFiles).length &&
                findFileByType(AgmFileType.BUSINESS_REPORT.code)
              }
              callback={callbackFileUpload}
              fileDownload={fileDownload}
              onDelete={() => onDeleteFile(AgmFileType.BUSINESS_REPORT.code)}
            />
          </InputBox>
          <InputBox title="감사보고서">
            <FileInput
              fileType={AgmFileType.AUDIT_REPORT.code}
              setFile={
                !!Object.keys(oldFiles).length &&
                findFileByType(AgmFileType.AUDIT_REPORT.code)
              }
              callback={callbackFileUpload}
              fileDownload={fileDownload}
              onDelete={() => onDeleteFile(AgmFileType.AUDIT_REPORT.code)}
            />
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox title="IR자료">
            <FileInput
              fileType={AgmFileType.IR.code}
              // handleFile={handleFile}
              setFile={
                !!Object.keys(oldFiles).length &&
                findFileByType(AgmFileType.IR.code)
              }
              callback={callbackFileUpload}
              fileDownload={fileDownload}
              onDelete={() => onDeleteFile(AgmFileType.IR.code)}
            />
          </InputBox>
          <InputBox isEmpty />
        </ListItem>
      </Section>
      <Section>
        <ListHeader title="업무담당자" isRequired />
        <CorporationManager
          register={register}
          errors={errors}
          isAgmRegister={true}
        />
      </Section>
      {checkBtnProxyUseFlag ? (
        <Section>
          <ListHeader
            title="전자위임장"
            description="전자위임장을 선택한 경우 필수 입력 사항이며, 권유자는 1명, 대리인은 최대 5명까지 입력 가능합니다."
          />
          <ListItem>
            <InputBox title="권유자" isRequired>
              <Input
                register={register}
                name="solicitorName"
                placeholder="이름을 입력하세요"
              />
            </InputBox>
            <InputBox
              title="대리인1"
              isRequired
              error={errors.voteProxies?.[0].message}
              errorType={ErrorTypes.ERROR}
            >
              <Input
                key={`voteProxies_0`}
                register={register}
                name="voteProxies[0]"
                registerOption={Validation.NAME}
                placeholder="대리인을 입력하세요"
              />
            </InputBox>
          </ListItem>
          <ListItem>
            <InputBox title="대리인2">
              <Input
                key={`voteProxies_1`}
                register={register}
                name="voteProxies[1]"
                placeholder="대리인을 입력하세요"
              />
            </InputBox>
            <InputBox title="대리인3">
              <Input
                key={`voteProxies_2`}
                register={register}
                name="voteProxies[2]"
                placeholder="대리인을 입력하세요"
              />
            </InputBox>
          </ListItem>
          <ListItem>
            <InputBox title="대리인4">
              <Input
                register={register}
                key={`voteProxies_3`}
                name="voteProxies[3]"
                placeholder="대리인을 입력하세요"
              />
            </InputBox>
            <InputBox title="대리인5">
              <Input
                key={`voteProxies_4`}
                register={register}
                name="voteProxies[4]"
                placeholder="대리인을 입력하세요"
              />
            </InputBox>
          </ListItem>
        </Section>
      ) : (
        <></>
      )}
      <AgmRegisterPageButton
        usePrev
        useNext
        step={AGM_REGISTER_STEPS.BASE_INFO}
        handleNextBtn={handleSubmit(handleStep1Data)}
      />
    </>
  );
};

export default AgmRegisterStep1;
