import React from 'react';
import Input from '../../../../../components/input/Input';
import { formatNumber } from '../../../../../utils/StringUtil';
import ResultTableSelect from '../../../register/main/components/ResultTableSelect';
import { getAgendaDisplayName, getDivStyle } from '../AgmResult';

const YesNoTable = ({ data, onChangeReuslt, onChangeCount, isEdit }) => {
  console.log('NonSepTable Props');
  console.log(data);
  if (!data || !data.electronicVoteValue) return null;
  return (
    <div className="tblcommon-01" style={getDivStyle(data.agenda)}>
      <table>
        <colgroup>
          <col width="*%;" />
          <col width="19%;" />
          <col width="19%" />
          <col width="19%;" />
          <col width="19%" />
        </colgroup>
        <thead>
          <tr>
            <th colSpan="5" className="title">
              {getAgendaDisplayName(data.agenda)}
              <span className="stock">
                행사 가능 주식수
                <strong className="ft-col02 ">
                  {formatNumber(data.agenda.exercisableStockQuantity)}주
                </strong>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>
            <th>전자투표결과(a)</th>
            <th>현장투표결과(b)</th>
            <th colSpan="2">전체결과(a+b)</th>
          </tr>
          <tr>
            <th>찬성</th>
            <td className="nRight">
              {formatNumber(data.electronicVoteValue.ayesCount)}
            </td>
            <td className="nRight">
              {isEdit ? (
                <Input
                  type="number"
                  onChange={(e) =>
                    onChangeCount(
                      e.target.value,
                      data.agenda.agendaSeqno,
                      data.agendaSeqno,
                      'ayesCount'
                    )
                  }
                  value={data.spotValue ? data.spotValue.ayesCount : 0}
                />
              ) : (
                formatNumber(data.spotValue?.ayesCount)
              )}
            </td>
            <td className="nRight">
              {formatNumber(data.totalValue?.ayesCount)}
            </td>
            <td rowSpan="3">
              <ResultTableSelect
                onChange={onChangeReuslt}
                parentAgendaSeqno={data.agenda.agendaSeqno}
                agendaSeqno={data.agendaSeqno}
                seletcedValue={data.result}
                disabled={!isEdit}
                isEdit={isEdit}
              />
            </td>
          </tr>
          <tr>
            <th>반대</th>
            <td className="nRight">
              {formatNumber(data.electronicVoteValue?.noesCount)}
            </td>
            <td className="nRight">
              {isEdit ? (
                <Input
                  type="number"
                  onChange={(e) =>
                    onChangeCount(
                      e.target.value,
                      data.agenda.agendaSeqno,
                      data.agendaSeqno,
                      'noesCount'
                    )
                  }
                  value={data.spotValue?.noesCount}
                />
              ) : (
                formatNumber(data.spotValue?.noesCount)
              )}
            </td>
            <td className="nRight">
              {formatNumber(data.totalValue?.noesCount)}
            </td>
          </tr>
          <tr>
            <th>기권</th>
            <td className="nRight">
              {formatNumber(data.electronicVoteValue.abstentionCount)}
            </td>
            <td className="nRight">
              {isEdit ? (
                <Input
                  type="number"
                  onChange={(e) =>
                    onChangeCount(
                      e.target.value,
                      data.agenda.agendaSeqno,
                      data.agenda.agendaSeqno,
                      'abstentionCount'
                    )
                  }
                  value={data.spotValue?.abstentionCount}
                />
              ) : (
                formatNumber(data.spotValue?.abstentionCount)
              )}
            </td>
            <td className="nRight">
              {formatNumber(data.totalValue?.abstentionCount)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default YesNoTable;
