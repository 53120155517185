import PropTypes from 'prop-types'; // ES6
import styled from 'styled-components';
import { Icons } from '../../styles/Icons';
import spriteCommonPng from '../../styles/images/common/sprite/sp_common.png';
import Button, { ButtonSize, ButtonTypes } from './Button';

const ButtonBlock = styled.button`
  margin-left: 5px;
  // .btn_i03.notxt {
  padding-left: 15px;
  padding-right: 16px;
  min-width: 55px;
  height: 42px;

  display: inline-block;
  padding: 12px 20px 11px 20px;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 1.33;
  letter-spacing: -0.5px;
  text-align: center;
  background-color: #0572ef;

  // .btn_i03.active::before { 변형
  &::before {
    content: '';
    position: absolute;
    top: 36%;
    right: 22px;
    display: block;
    width: 24px;
    height: 26px;
    margin-top: -5.5px;
    background: url(${spriteCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -33px -173px;

    //.btn_i03.notxt::before {
    right: 16px;
  }
`;
const FileDownButton = (props) => {
  return (
    <>
      {!!props.children ? (
        <Button
          {...props}
          size={ButtonSize.Default}
          type={ButtonTypes.Primary}
          endIcon={Icons.paper_download}
          onClick={props.onClick}
          style={props.style}
        >
          {props.children}
        </Button>
      ) : (
        <ButtonBlock {...props} onClick={props.onClick} />
      )}
    </>
  );
};

FileDownButton.propTypes = {
  size: PropTypes.oneOf(Object.values(ButtonSize)),
  // type
  type: PropTypes.oneOf(Object.values(ButtonTypes)),

  // icon
  startIcon: PropTypes.object,
  endIcon: PropTypes.object,

  // action
  onClick: PropTypes.func
};

FileDownButton.defaultProps = {
  //startIcon: Icons.search_blue,
};

export default FileDownButton;
