import { useMemo } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import useWindowSize from '../../../../../hooks/useWindowSize';
import memberPng from '../../../../../styles/images/common/sprite/mem_ty_02.png';
import SignupBody from '../SignupBody';
import SignupFooter from '../SignupFooter';
import SignupTitle from '../SignupTitle';

const SignupComplete = styled.div`
  text-align: center;
  font-weight: bold;
  & > p {
    padding-top: 160px;
    font-size: 28px;
    margin-bottom: 20px;
    position: relative;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      padding-top: 170px;
      font-size: 15px;
      margin-bottom: 8px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 99px;
      height: 125px;
      background: url(${memberPng}) no-repeat;
      background-size: 99px auto;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        top: 50px;
      }
    }
  }
  & > span {
    font-size: 18px;
    line-height: 1.5;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      font-size: 13px;
    }
    & > strong {
      color: #0094ff;
    }
  }
`;
const SignupStep3 = () => {
  const history = useHistory();
  const onNavigateLogin = () => {
    history.push('/login');
  };
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => {
    return windowSize.width <= SCREEN_SIZE_MOBILE;
  }, [windowSize]);

  return (
    <SignupBody>
      <SignupTitle title="" />
      <SignupComplete>
        <p>서비스 가입이 완료되었습니다.</p>
        <span>
          {isMobile ? (
            <>
              안전하고 간편한 <strong>블록체인 전자주총</strong> 서비스 <br />
              이용을 위해 로그인 화면으로 이동합니다.
            </>
          ) : (
            <>
              안전하고 간편한 <strong>블록체인 전자주총</strong> 서비스 이용을
              위해
              <br />
              로그인 화면으로 이동합니다.
            </>
          )}
        </span>
      </SignupComplete>
      <SignupFooter allChecked={true} onClick={onNavigateLogin} />
    </SignupBody>
  );
};

export default SignupStep3;
