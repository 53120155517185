import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import { MODAL_SIZE } from '../../../components/modal/Modal';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import { PageInnerListWrap } from '../../../components/page/PageInner';
import { ModalContext } from '../../../context/ModalContext';
import {
  fetchGetAgmByAgmSeqnoAction,
  fetchGetAgmTotalResultAction
} from '../../../services/api/agm/promiseActions';
import CertificateInfo from '../certification/main/CertificateInfo';
import VoteResultDetailPopup from './main/components/popup/VoteResultDetailPopup';
import VoteAgendaResultBox from './main/components/VoteAgendaResultBox';
import VoteAgendaResultBoxTitle from './main/components/VoteAgendaResultBoxTitle';
import VoteResultBoxList from './main/components/VoteResultBoxList';
import VoteResultItem, {
  VOTE_RESULT_ITEM_TYPE
} from './main/components/VoteResultItem';
import VoteResultItemExtend from './main/components/VoteResultItemExtend';

export const VOTE_RESULT_CODE = {
  Approved: '1',
  Rejected: '2',
  Invalid: '3',
  Etc: '4'
};

const VoteResultPage = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const { openModal, setModalConfig } = useContext(ModalContext);
  const [agmInfo, setAgmInfo] = useState();
  const [data, setData] = useState(null);
  const agmSeqno = match.params.agmSeqno;
  useEffect(() => {
    dispatch(fetchGetAgmTotalResultAction({ agmSeqno })).then((res) => {
      console.log('fetchGetAgmVotingRightsTotal');
      console.log(res);
      setData(res.data);
    });
  }, []);

  useEffect(() => {
    dispatch(fetchGetAgmByAgmSeqnoAction(agmSeqno)).then((res) => {
      setAgmInfo(res.data);
    });
  }, [dispatch]);

  const onNavigate = (url) => {
    history.push(url);
  };

  const openResultDetailPopup = (agendaSeqno, childAgendaSeqno) => {
    setModalConfig({ size: MODAL_SIZE.LARGE });
    let detailData = data.find((agenda) => agenda.agendaSeqno === agendaSeqno);
    if (childAgendaSeqno) {
      detailData = detailData.childs.find(
        (child) => child.agendaSeqno === childAgendaSeqno
      );
    }
    openModal('주주총회 결과', <VoteResultDetailPopup agenda={detailData} />);
  };
  const renderResultItem = (agenda) => {
    if (agenda.childAgendaCount === 0) {
      return (
        <>
          <VoteResultItem
            stockAmount={agenda.totalValue.ayesCount}
            result={agenda.result}
            type={VOTE_RESULT_ITEM_TYPE.Short}
            childCount={agenda.childAgendaCount}
            noResultButton={true}
            onResultPopup={() => openResultDetailPopup(agenda.agendaSeqno)}
          >
            찬성
          </VoteResultItem>
          <VoteResultItem
            stockAmount={agenda.totalValue.noesCount}
            result={agenda.result}
            childCount={agenda.childAgendaCount}
            noResultButton={true}
            type={VOTE_RESULT_ITEM_TYPE.Short}
            onResultPopup={() => openResultDetailPopup(agenda.agendaSeqno)}
          >
            반대
          </VoteResultItem>
          <VoteResultItem
            stockAmount={agenda.totalValue.abstentionCount}
            result={agenda.result}
            childCount={agenda.childAgendaCount}
            noResultButton={true}
            type={VOTE_RESULT_ITEM_TYPE.Short}
            onResultPopup={() => openResultDetailPopup(agenda.agendaSeqno)}
          >
            기권
          </VoteResultItem>
        </>
      );
    } else {
      return agenda.childs.map((childAgenda, idx) => {
        if (agenda.concentrateVote) {
          return (
            <VoteResultItem
              key={`VoteResultPage-VoteResultItem-${idx}`}
              stockAmount={childAgenda.totalValue.ayesCount}
              result={childAgenda.result}
              childCount={agenda.childAgendaCount}
              type={VOTE_RESULT_ITEM_TYPE.Long}
              onResultPopup={() =>
                openResultDetailPopup(childAgenda.parentAgendaSeqno)
              }
            >
              {childAgenda.agendaNumber ? childAgenda.agendaNumber : ''}
              {`${childAgenda.agendaName}`}
            </VoteResultItem>
          );
        } else {
          return (
            <>
              <>
                <VoteResultItemExtend
                  isFirst={idx === 0}
                  agenda={childAgenda}
                  result={childAgenda.result}
                  type={VOTE_RESULT_ITEM_TYPE.Short}
                  noResultButton={true}
                  childCount={agenda.childAgendaCount}
                  totalValue={childAgenda.totalValue}
                  onResultPopup={() =>
                    openResultDetailPopup(
                      agenda.agendaSeqno,
                      childAgenda.agendaSeqno
                    )
                  }
                >
                  {childAgenda.agendaName}
                </VoteResultItemExtend>
              </>
            </>
          );
        }
      });
    }
  };

  return (
    <Page>
      <PageHeader title="주주총회 결과" />
      <PageInnerListWrap>
        <CertificateInfo
          info={agmInfo}
          selectedVoters={[]}
          isVoteScreen={false}
        />
        <VoteResultBoxList>
          {data &&
            data.map((agenda, idx) => {
              return (
                <VoteAgendaResultBox
                  key={`VoteResultPage-VoteAgendaResultBox-${idx}`}
                  isInvalid={
                    agenda.result && agenda.result === VOTE_RESULT_CODE.Invalid
                  }
                >
                  <VoteAgendaResultBoxTitle
                    agenda={agenda}
                    agendaNo={agenda.agendaNumber}
                    title={`${agenda.agendaName} - ${agenda.agendaDetailContent}`}
                    isInvalid={false}
                    result={agenda.result}
                    childCount={agenda.childAgendaCount}
                    onResultPopup={() =>
                      openResultDetailPopup(agenda.agendaSeqno)
                    }
                  />
                  {renderResultItem(agenda)}
                </VoteAgendaResultBox>
              );
            })}
        </VoteResultBoxList>
      </PageInnerListWrap>
      <ButtonGroup>
        <Button
          type={ButtonTypes.Primary}
          size={ButtonSize.Medium}
          onClick={() =>
            onNavigate(`/shareholder/agm/${agmSeqno}/exercise-record`)
          }
        >
          내 행사내역
        </Button>
      </ButtonGroup>
    </Page>
  );
};

export default VoteResultPage;
