import React, { useState } from 'react';
import Button from '../../../../components/button/Button';
import Radio from '../../../../components/input/Radio';
import RadioGroup from '../../../../components/input/RadioGroup';
import Page from '../../../../components/page/Page';

const RadioTest = () => {
  const [checkedValue, setCheckedValue] = useState('1');
  return (
    <Page>
      <RadioGroup
        name="테스트"
        vvalue={checkedValue}
        selectedValue={checkedValue}
        onSelect={(value) => {
          setCheckedValue(value);
        }}
      >
        <Radio label="분리" value="1" />
        <Radio label="비분리" value="2" />
      </RadioGroup>

      <Radio label={<Button>하하</Button>} />
    </Page>
  );
};

export default RadioTest;
