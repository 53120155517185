import { useState } from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import spCommonPng from '../../../../../styles/images/common/sprite/sp_common.png';
import spCommonMobilePng from '../../../../../styles/images/common/sprite/sp_common_mobile.png';
import { EMPTY_FUNCTION } from '../../../../../utils/FunctionUtil';
import { formatNumber } from '../../../../../utils/StringUtil';

const DataTableWrapperBlock = styled.div`
  // .tblcommon-01
  display: inline-block;
  width: auto;
  height: 227px;
  overflow-y: auto;
  margin: 20px 0 0 200px;
  border: solid 1.5px #505f7e;
  border-radius: 5px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding: 0;
    display: block;
    position: relative;
    height: auto;
    margin: 0;
  }
`;

const TableHeadBlock = styled.thead`
  & > tr {
    &:first-of-type {
      & > th:first-of-type {
        border-left: 0;
      }
    }
    & > th {
      background: #fbfbfc;
      border-top: solid 1px #e9ecf4;
      border-right: solid 1px #e9ecf4;
      box-shadow: 0px 3px 2px rgb(0 0 0 / 3%);
      position: relative;
      padding: 4px 8px 2px;
      height: 46px;
      font-size: 14px;
      text-align: center;
      transform: skew(-0.04deg);
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        background: #fbfbfc;
        border-top: solid 1px #e9ecf4;
        box-shadow: 0px 3px 2px rgb(0 0 0 / 3%);
        position: relative;
        padding: 5px 4px;
        height: 22px;
        font-size: 12px;
        text-align: center;
        transform: skew(-0.04deg);
      }
    }
  }
`;

const TableRowBlock = styled.tr`
  ${(props) =>
    props.$on &&
    css`
      background: rgba(175, 236, 255, 0.16);
      cursor: pointer;
    `}
  & > td {
    padding: 3px 8px 0;
    height: 54px;
    font-size: 15px;
    border-bottom: solid 1px #e9ecf4;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    transform: skew(-0.04deg);
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      padding: 4px 8px 3px;
      height: 24px;
      font-size: 13.6px;
      border-bottom: solid 1px #e9ecf4;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      transform: skew(-0.04deg);
    }
    & > em {
      display: inline-block;
      position: relative;
      width: 15px;
      height: 15px;
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        display: block;
        width: 13px;
        height: 13px;
        background: url(${spCommonPng}) no-repeat;
        background-size: 400px auto;
        ${(props) =>
          props.yes
            ? css`
                background-position: -359px -1px;
              `
            : css`
                background-position: -359px -15px;
              `}
      }
    }
  }
`;

const CheckboxWrapper = styled.div`
  // .checkbox_ty03
  position: relative;
  display: inline-block;
  margin: 10px 0;
  & > input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
  & > label {
    position: relative;
    display: inline-block;
    padding: 0 0 0 40px;
    min-height: 20px;
    line-height: 1.67;
    letter-spacing: -0.5px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      min-height: 13px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 26px;
      height: 26px;
      background: url(${spCommonPng}) no-repeat;
      background-size: 400px auto;
      background-position: -123px -65px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 17px;
        height: 17px;
        background: url(${spCommonMobilePng}) no-repeat;
        background-size: 230px auto;
        background-position: -74px -38px;
      }
    }
  }
  & > input[type='checkbox']:checked + label::before {
    background-position: -83px -65px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      background-position: -48px -38px;
    }
  }
`;

const VoterNameBlock = styled.button`
  &&& {
    background-color: white !important;
  }
`;

/**
  시연용 임시 컴포넌트, 추후 변경이 필요하다
 */
const CertificateDataTableTemp = ({
  voters = [],
  selectedVoter = null,
  setSelectedVoter
}) => {
  const [checked, setChecked] = useState(0);

  const onClickHandler = (index) => {
    setChecked((prev) => {
      const newList = index;
      if (index === prev) {
        setSelectedVoter(null);
        return null;
      }
      setSelectedVoter(index);
      return newList;
    });
  };

  return (
    <DataTableWrapperBlock>
      <table>
        <colgroup>
          <col width="22%;" />
          <col width="28%;" />
          <col width="26%;" />
          <col width="25%;" />
        </colgroup>
        <TableHeadBlock>
          <tr>
            <th>선택</th>
            <th>주주명</th>
            <th>보유주식</th>
            <th>투표여부</th>
          </tr>
        </TableHeadBlock>
        <tbody>
          {voters.map((voter, index) => {
            return (
              <TableRowBlock
                key={`TableRowBlock-${index}`}
                $on={checked === index}
                yes={voter.excerised}
              >
                <td>
                  <CheckboxWrapper>
                    <input
                      type="checkbox"
                      id={`chk_id${index}`}
                      checked={checked === index}
                    />
                    <label
                      htmlFor={`chk_id${index}`}
                      onClick={() => onClickHandler(index)}
                    ></label>
                  </CheckboxWrapper>
                </td>
                <td>
                  <VoterNameBlock onClick={EMPTY_FUNCTION}>
                    {voter.stockholderName}
                  </VoterNameBlock>
                </td>
                <td>{`${formatNumber(voter.commonStockAmount)}주`}</td>
                <td>
                  <em></em>
                </td>
              </TableRowBlock>
            );
          })}
        </tbody>
      </table>
    </DataTableWrapperBlock>
  );
};

export default CertificateDataTableTemp;
