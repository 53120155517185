import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListWrapBlock } from '../../../components/page/PageInner';
import ContentTabs, {
  ContentTabTypes
} from '../../../components/tabs/ContentTabs';
import { fetchGetFaqCategoriesAction } from '../../../services/api/common/promiseActions';
import FaqCategory from './main/FaqCategory';

const FaqPage = () => {
  const [faqCategory, setFaqCategory] = useState([]);
  const [selCategory, setSelCategory] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGetFaqCategoriesAction()).then((res) => {
      if (!res.error) {
        setFaqCategory(res.data);
      }
    });
  }, [dispatch]);

  const onChangeTab = (index) => {
    setSelCategory(index + 1);
  };

  return (
    <>
      <ListWrapBlock>
        {typeof faqCategory !== 'undefined' && (
          <ContentTabs
            type={ContentTabTypes.UnderLine}
            items={faqCategory.map((item) => item.categoryName)}
            onClick={onChangeTab}
          />
        )}
        <FaqCategory sel={selCategory} />
      </ListWrapBlock>
      {/* <DataTable dataSource={getDataSource()} /> */}
      {/* {JSON.stringify(faqList)} */}
    </>
  );
};

export default FaqPage;
