import { GET } from '../../../utils/constant/httpMethod';
import { request } from '../../../utils/requestHelper';

const API_GROUP = 'external';
const Externals = {
  /**
   * 법인번호 기업 조회
   */
  // 법인번호 기업 조회
  fetchGetExternalOrganizations(params) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/organizations`,
      params
    });
  }
};

export default Externals;
