import { createPromiseAction } from '@adobe/redux-saga-promise';

/**
 * 투표하기
 */
// 투표 화면
export const fetchGetElectionsAgmBallotAction = createPromiseAction(
  'FETCH_GET_ELECTIONS_AGM_BALLOT'
);

// 투표 하기 -전자서명
export const fetchPostElectionsAgmVoteAction = createPromiseAction(
  'FETCH_POST_ELECTIONS_AGM_VOTE'
);

export const fetchPutElectionsAgmCancelProxyAction = createPromiseAction(
  'FETCH_PUT_ELECTIONS_AGM_CANCEL_PROXY'
);

export const fetchPutElectionsAgmCancelVoteAction = createPromiseAction(
  'FETCH_PUT_ELECTIONS_AGM_CANCEL_VOTE'
);
