/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../components/button/CommonStyle';

const PagerBlock = styled.div`
  // #fp-nav
  position: fixed;
  top: 45%;
  z-index: 100;
  margin-top: 0;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    display: none;
  }

  // #fp-nav.right
  right: 15px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    right: 0px;
  }

  //
  margin-top: 0;
  & ul {
    // #fp-nav ul
    margin: 0;
    padding: 0;
    & li {
      // #fp-nav ul li
      display: block;
      width: 12px;
      height: 12px;
      margin: 12px;
      position: relative;
      font-size: 0.813em;
      & a {
        // #fp-nav ul li a
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
`;

const SpanBlock = styled.span`
  //#fp-nav ul li a span
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  height: 12px;
  width: 12px;
  box-sizing: border-box;
  border-radius: 50%;
  margin: -6px 0 0 -6px;

  background: #fff;
  opacity: 0.2;
  ${(props) =>
    props.active &&
    css`
      background: #fff;
      opacity: 1;
    `}
`;

const Pager = (props) => {
  const { pageCnt = 4, currentPage, onClick } = props;
  return (
    <PagerBlock>
      <ul>
        {[...Array(pageCnt).keys()].map((page, idx) => (
          <li>
            <a onClick={() => onClick(idx)}>
              <SpanBlock active={currentPage === idx} />
            </a>
          </li>
        ))}
      </ul>
    </PagerBlock>
  );
};

export default Pager;
