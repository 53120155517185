import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import { createRef, useEffect } from 'react';

const ToastEditor = ({ setValue, name, initialValue }) => {
  const editorRef = createRef();

  useEffect(() => {
    const editor = editorRef.current.getInstance();
    editor.removeToolbarItem('code');
    editor.removeToolbarItem('codeblock');
    editor.removeToolbarItem('indent');
    editor.removeToolbarItem('outdent');
  }, [editorRef]);

  useEffect(() => {
    editorRef.current.getInstance().setMarkdown(initialValue);
  }, [initialValue]);

  const onChangeEditorTextHandler = () => {
    setValue(name, editorRef.current.getInstance().getMarkdown());
  };

  return (
    <Editor
      initialEditType="wysiwyg"
      onChange={onChangeEditorTextHandler}
      ref={editorRef}
      hideModeSwitch={true}
    />
  );
};

export default ToastEditor;
