import PropsType from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button, { ButtonTypes } from '../../../../components/button/Button';
import { SCREEN_SIZE_MOBILE } from '../../../../components/button/CommonStyle';
import DateInput from '../../../../components/input/DateInput';
import Input from '../../../../components/input/Input';
import Wave from '../../../../components/input/Wave';
import useWindowSize from '../../../../hooks/useWindowSize';
import {
  fetchGetAgmScheduleAction,
  fetchGetAgmScheduleGuestAction
} from '../../../../services/api/agm/promiseActions';
import {
  DateFormatName,
  getDateFormat
} from '../../../../utils/DateFormatUtil';
import { DATE_FORMAT, now } from '../../../../utils/DateUtil';

const SearchFormBlock = styled.div`
  // .search_ty01
  width: 960px;
  margin: 40px auto 30px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    width: auto;
    /* margin-top: 80px; */
  }
`;

const ListItemBlock = styled.li`
  &:first-of-type {
    // .search_ti
    margin-bottom: 12px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      position: absolute;
      left: 16px;
      padding: 0;
      width: auto;
      padding-top: 0.5em;
    }
  }
  width: 100%;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    display: flex;
    padding-left: 74px;
    box-sizing: border-box;

    flex-direction: column;
    gap: 15px;
    align-items: stretch;
  }
  & > span {
    position: relative;
    display: inline-block;
    font-size: 14px;
    transform: skew(-0.04deg);
    font-weight: bold;
    color: #080808;
  }

  & > div {
    // .input_wrap
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;

    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      & > button {
        background-color: #2e487c !important;
      }
    }
  }
`;

const SearchForm = (props) => {
  const {
    register,
    setSchedules,
    setPageConfig,
    setValue,
    handleSubmit,
    getValues
  } = props;
  const isAuth = useSelector((state) => state.common.user?.isAuth || false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState(
    new Date(now().getFullYear() - 1, 0, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(now().getFullYear() + 1, 0, 1)
  );
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => {
    return windowSize.width <= SCREEN_SIZE_MOBILE;
  }, [windowSize]);

  const getSchedules = useCallback(
    (data) => {
      const action = isAuth
        ? fetchGetAgmScheduleAction
        : fetchGetAgmScheduleGuestAction;
      dispatch(action(data)).then((res) => {
        if (!res.error) {
          if (isAuth) {
            setSchedules((prev) => res.data.content);
          } else {
            setSchedules((prev) => res.data.content);
          }
          const { number, size, totalElements, totalPages } = res.data;
          setPageConfig({
            pageNumber: number,
            size,
            totalCount: totalElements,
            totalPageNumber: totalPages,
            onClick: setPage
          });
        }
      });
    },
    [dispatch, setSchedules, isAuth, setPageConfig]
  );

  const getParams = useCallback(() => {
    return {
      startDate: DATE_FORMAT(
        startDate,
        getDateFormat(DateFormatName.SHORT_DATE_HYPEN)
      ),
      endDate: DATE_FORMAT(
        endDate,
        getDateFormat(DateFormatName.SHORT_DATE_HYPEN)
      ),
      page: page,
      issueIncorporatedName: getValues('issueIncorporatedName')
    };
  }, [endDate, getValues, page, startDate]);

  useEffect(() => {
    const params = getParams();
    getSchedules(params);
  }, [getSchedules, getParams]);

  const onSubmit = (data) => {
    getSchedules({ ...data, page: page });
  };

  const callback = (date, name) => {
    if (name === 'startDate') {
      setStartDate(date);
    } else if (name === 'endDate') {
      setEndDate(date);
    }
    setValue(name, date);
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      const params = getParams();
      getSchedules(params);
    }
  };

  return (
    <SearchFormBlock>
      <ul>
        <ListItemBlock>
          <span>{props.title}</span>
        </ListItemBlock>
        <ListItemBlock>
          {isMobile ? (
            <>
              <MobileDivBlock>
                <DateInput
                  defaultValue={startDate}
                  name="startDate"
                  callback={callback}
                />
                <Wave />
                <DateInput
                  defaultValue={endDate}
                  name="endDate"
                  callback={callback}
                />
              </MobileDivBlock>
              <div>
                <Input
                  type="text"
                  placeholder="기업명 입력"
                  register={register}
                  name="issueIncorporatedName"
                  onKeyPress={onKeyPress}
                />
                <Button
                  type={ButtonTypes.Primary}
                  onClick={handleSubmit(onSubmit)}
                >
                  검색
                </Button>
              </div>
            </>
          ) : (
            <div>
              <DateInput
                defaultValue={startDate}
                name="startDate"
                callback={callback}
              />
              <Wave />
              <DateInput
                defaultValue={endDate}
                name="endDate"
                callback={callback}
              />
              <Input
                type="text"
                placeholder="기업명 입력"
                register={register}
                name="issueIncorporatedName"
                onKeyPress={onKeyPress}
              />
              <Button
                type={ButtonTypes.Primary}
                onClick={handleSubmit(onSubmit)}
              >
                검색
              </Button>
            </div>
          )}
        </ListItemBlock>
      </ul>
    </SearchFormBlock>
  );
};

export default SearchForm;

SearchForm.propTypes = {
  title: PropsType.string
};

const MobileDivBlock = styled.div`
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    & > div {
      flex: 1;
      & > input {
        max-width: none;
      }
    }
  }
`;
