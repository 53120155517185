import { createGlobalStyle } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../components/button/CommonStyle';
const GlobalStyles = createGlobalStyle`
    /* 
        reset
    */

    * {max-height:1000000px; margin:0; padding:0;}
    body {word-wrap:break-word; word-break:normal; /*word-break:keep-all;*/ white-space:normal; -webkit-overflow-scrolling:touch; -webkit-text-size-adjust:none;}
    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {display:block;}
    hr {height:1px; border:none;}
    img {border:none; vertical-align:top;}
    em, i {font-style:normal;}
    li {list-style:none;}
    table {width:100%; table-layout:fixed; border-collapse:initial; border-spacing:0;}
    th, td {vertical-align:middle; word-wrap:break-word; word-break:break-all;}
    a {color:#080808; text-decoration:none;}
    a:hover, a:active, a:focus, a:visited {text-decoration:none;}

    /* font */
    body, input, select, button, textarea, pre {font-family:'NanumSquare', 'AppleGothicNeoSD', 'NotoSans', 'Tahoma', 'Simhei', 'Microsoft Yahei', 'Apple Gothic', '애플 고딕', '맑은 고딕', 'Malgun Gothic', Dotum, '돋움', sans-serif, Helvetica, Arial; font-weight:normal; font-size:15px; color:#080808;}
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        body, input, select, button, textarea {
            font-size: 13px;
            line-height: 1.38;
        }
    } 
    p, select, input, em, span, b, a, button, label, dt, h3, pre {transform : skew(-0.04deg)}
    /* form */
    textarea {overflow:auto;resize:none;}
    button{cursor:pointer;}
    fieldset, button {border:none;}
    input, select, button, textarea {border-radius:0; appearance:none; -moz-appearance:none; -webkit-appearance:none; -ms-appearance:none;}
    input[type=text]::-ms-clear,
    input[type=email]::-ms-clear,
    input[type=number]::-ms-clear {display:none;}
    input::-webkit-search-decoration,
    input::-webkit-search-cancel-button,
    input::-webkit-search-results-button,
    input::-webkit-search-results-decoration {display:none;}
    input[type="date"]::-webkit-clear-button {display:none;}
    input[type="date"]::-webkit-inner-spin-button {display:none;}
    select::-ms-expand {display:none;}
    ::placeholder {color:#CCD5E0;}
    ::-webkit-input-placeholder {color:#CCD5E0;}
    ::-moz-placeholder {color:#CCD5E0;}
    :-ms-input-placeholder {color:#CCD5E0;}
    ::-ms-input-placeholder {color:#CCD5E0;}
    /* focus시 placeholer 사라짐 */
    input:focus::-webkit-input-placeholder {color:transparent;}
    input:focus:-moz-placeholder {color:transparent;}
    input:focus::-moz-placeholder {color:transparent;}
    input:focus:-ms-input-placeholder {color:transparent;}
    textarea:focus::-webkit-input-placeholder {color:transparent;}
    textarea:focus:-moz-placeholder {color:transparent;}
    textarea:focus::-moz-placeholder {color:transparent;}
    textarea:focus:-ms-input-placeholder {color:transparent;}
    // temp
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {display:none;}
    /* blind */
    legend, caption {position:absolute; width:1px; height:1px; margin-top:-1px; box-sizing:border-box; color:transparent; overflow:hidden; opacity:0;}

    // component.css
    textarea::placeholder {color: rgba(0, 8, 30, 0.2);}
    textarea::-webkit-input-placeholder {color: rgba(0, 8, 30, 0.2);}
    textarea::-moz-placeholder {color: rgba(0, 8, 30, 0.2);}
    textarea:-ms-input-placeholder {color: rgba(0, 8, 30, 0.2);}
    textarea::-ms-input-placeholder {color: rgba(0, 8, 30, 0.2);}

    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        html, body {
            height: 100%;
        }
    }
`;

export default GlobalStyles;
