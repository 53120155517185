import { ResponsivePie } from '@nivo/pie';
import React from 'react';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../../components/button/CommonStyle';
import { VOTE_RESULT_CODE } from '../../../VoteResultPage';
import BarChartBox from '../BarChartBox';
import { VOTE_RESULT_BUTTON_TYPE } from '../DefaultButtonStyle';
import VoteResultButton from '../VoteResultButton';

export const MyResponsivePie = ({ data /* see data tab */ }) => (
  <ResponsivePie
    data={data}
    innerRadius={0.85}
    padAngle={0.7}
    activeOuterRadiusOffset={8}
    borderWidth={1}
    borderColor={{ from: 'color', modifiers: [['opacity', 0]] }}
    enableArcLabels={false}
    enableArcLinkLabels={false}
    isInteractive={false}
    margin={{ top: 3, right: 3, bottom: 3, left: 3 }}
    colors={{ datum: 'data.color' }}
    defs={[
      {
        id: 'gradientColor',
        type: 'linearGradient',
        colors: [
          { offset: 0, color: '#20A1FF' },
          { offset: 100, color: '#0572EF' }
        ]
      }
    ]}
    fill={[{ match: { id: 'value' }, id: 'gradientColor' }]}
  />
);

const VoteResultDetailPopupBlock = styled.div``;

const ResultTitleBlock = styled.div`
  //.layer_wrap .layer_body .result_ti
  position: relative;
  display: block;
  padding: 42px 100px 30px;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: relative;
    display: block;
    padding: 42px 12px 16px;
  }

  & li {
    // .layer_wrap .layer_body .result_ti li
    width: 100%;
    text-align: center;
    & p {
      // .layer_wrap .layer_body .result_ti li p
      position: relative;
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 25px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        position: relative;
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 16px;
      }
      & span {
        // .layer_wrap .layer_body .result_ti li span
        display: block;
        width: 100%;
        margin-top: 10px;
        font-size: 16px;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          display: block;
          width: 100%;
          margin-top: 6px;
          font-size: 13px;
        }
      }
    }
    & p::after {
      // .layer_wrap .layer_body .result_ti li p::after
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 20px;
      height: 2px;
      background: #9eb3cd;
      transition: width 250ms ease-in, background-color 250ms ease-in;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 20px;
        height: 2px;
        background: #9eb3cd;
      }
    }
  }
  & button {
    position: absolute;
    top: 0;
    right: 0;
    cursor: not-allowed;
  }
`;

const VoteResultDetailPopup = (props) => {
  const { agenda } = props;
  const renderVoteResultButton = (result) => {
    switch (result) {
      case VOTE_RESULT_CODE.Approved:
        return (
          <VoteResultButton type={VOTE_RESULT_BUTTON_TYPE.Approved}>
            가결
          </VoteResultButton>
        );
      case VOTE_RESULT_CODE.Rejected:
        return (
          <VoteResultButton type={VOTE_RESULT_BUTTON_TYPE.Rejected}>
            부결
          </VoteResultButton>
        );
      case VOTE_RESULT_CODE.Invalid:
        return (
          <VoteResultButton type={VOTE_RESULT_BUTTON_TYPE.Invalid}>
            무효
          </VoteResultButton>
        );

      default:
        break;
    }
  };

  /*[
    {
      label: '찬성',
      value: 65,
      percent: 65,
      isAppointment: true,
    },
    {
      label: '반대',
      value: 30,
      percent: 30,
      isAppointment: false,
    },
    {
      label: '기권',
      value: 10,
      percent: 10,
      isAppointment: false,
    },
  ];
  */

  const isShowCircleChart = true;
  /*  
  agenda.childs.length === 0 ||
    (agenda.childs.length > 0 && agenda.concentrateVote);
  */

  return (
    <VoteResultDetailPopupBlock>
      <>
        <ResultTitleBlock>
          {renderVoteResultButton(agenda.result)}
          <ul>
            <li>
              <p>
                {agenda.agendaName}
                <span>{agenda.agendaDetailContent}</span>
              </p>
            </li>
          </ul>
        </ResultTitleBlock>
        {agenda.childs.length > 0 && !agenda.concentrateVote ? (
          agenda.childs.map((child) => (
            <>
              <ResultTitleBlock>
                <ul>
                  <li>
                    <p>
                      {renderVoteResultButton(child.result)}
                      <span>{child.agendaName}</span>
                    </p>
                  </li>
                </ul>
              </ResultTitleBlock>
              <BarChartBox
                agenda={child}
                isShowCircleChart={isShowCircleChart}
              />
            </>
          ))
        ) : (
          <BarChartBox agenda={agenda} isShowCircleChart={isShowCircleChart} />
        )}
      </>
    </VoteResultDetailPopupBlock>
  );
};

export default VoteResultDetailPopup;
