import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import { PageInnerListWrap } from '../../../components/page/PageInner';
import { fetchGetAgmByAgmSeqnoAction } from '../../../services/api/agm/promiseActions';
import CertificateInfo from './main/CertificateInfo';

const ShareholderCertificationPage = () => {
  const match = useRouteMatch();
  const { agmSeqno } = match.params;
  const [agmInfo, setAgmInfo] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGetAgmByAgmSeqnoAction(agmSeqno)).then((res) => {
      setAgmInfo(res.data);
    });
  }, [dispatch]);

  return (
    <Page>
      <PageHeader title="주주증명발급" />
      <PageInnerListWrap>
        {!!agmInfo && <CertificateInfo info={agmInfo} />}
      </PageInnerListWrap>
    </Page>
  );
};

export default ShareholderCertificationPage;
