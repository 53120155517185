import PropsType from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const NoticeTitleBlock = styled.div`
  ${(props) =>
    props.type === NoticeTypes.Desc &&
    css`
      // .desc
      display: block;
      margin-top: 8px;
      padding-left: 20px;
      font-size: 13px;
      color: #8c939c;
      line-height: 1.33;
    `}
  ${(props) =>
    props.type === NoticeTypes.Notice &&
    css`
      // .notice_ty
      font-size: 12px;
      font-weight: normal;
      line-height: 1.67;
      letter-spacing: -0.5px;
      color: #a0a6b5;
    `}
`;

const UnitTitleBlock = styled.div`
  // .unit_ti
  display: block;
  margin-bottom: 12px;
  & > span {
    position: relative;
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    color: #080808;
  }
`;

const PopUpTitleBlock = styled.div`
  //pop_bo_dir
  display: block;
  margin-top: 30px;
  padding-left: 18px;
  font-size: 13px;
  line-height: 1.45;
  word-break: keep-all;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    display: block;
    width: 10px;
    height: 1px;
    background: #9eb3cd;
    border-radius: 3px;
  }
`;

const DescriptionBlock = styled.span`
  // .desc
  display: block;
  margin-top: 8px;
  padding-left: 20px;
  font-size: 13px;
  color: #8c939c;
  line-height: 1.33;

  // .desc.ty03
  position: relative;
  margin-top: 6px;
  padding-left: 15px;
`;

export const NoticeTypes = {
  Unit: 'unit',
  Desc: 'desc',
  Notice: 'notice',
  PopUp: 'popUp'
};
const Notice = (props) => {
  const { type, description } = props;
  const renderDescription = () => {
    if (!description) {
      return null;
    }

    if (typeof description === 'string') {
      return (
        <DescriptionBlock
          dangerouslySetInnerHTML={`- ${description}`}
        ></DescriptionBlock>
      );
    } else {
      return description.map((desc) => (
        <DescriptionBlock>{`- ${desc}`}</DescriptionBlock>
      ));
    }
  };
  if (type === NoticeTypes.Unit) {
    return (
      <UnitTitleBlock>
        <span>{props.children}</span>
      </UnitTitleBlock>
    );
  } else if (type === NoticeTypes.PopUp) {
    return (
      <PopUpTitleBlock
        dangerouslySetInnerHTML={{
          __html: description.replace('.', '. <br />')
        }}
      />
    );
  } else {
    return (
      <NoticeTitleBlock type={type}>
        {props.children}
        {renderDescription()}
      </NoticeTitleBlock>
    );
  }
};

Notice.propTypes = {
  type: PropsType.string,
  title: PropsType.string,
  description: PropsType.oneOfType([PropsType.string, PropsType.array])
};

export default Notice;
