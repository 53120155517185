import React from 'react';
import styled, { css } from 'styled-components';
import { TableDataType } from './Table';

const ColumnBlock = styled.li`
  position: relative;
  flex: 1;
  font-size: 15px;
  letter-spacing: -0.5px;
  text-align: center;
  padding: 15px 0 14px;
  &:first-of-type:before {
    display: none;
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 2px;
    height: 14px;
    margin-top: -7px;
    background-color: #edf0f5;
  }
  // type checkbox
  ${(props) =>
    props.dataType === TableDataType.CHECKBOX &&
    css`
      flex: none;
      flex-basis: 100px;
      flex-shrink: 0;
    `}

  // type datetime
  ${(props) =>
    props.dataType === TableDataType.DATETIME &&
    css`
      flex: none;
      flex-basis: 200px;
      flex-shrink: 0;
    `}

    // type number
    ${(props) =>
    props.dataType === TableDataType.NUMBER &&
    css`
      flex: none;
      flex-basis: 60px;
      flex-shrink: 0;
    `}
`;

const Column = (props) => {
  return <ColumnBlock {...props}>{props.children}</ColumnBlock>;
};

export default Column;
