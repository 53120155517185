import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import Viewer from '../../../../../components/viewer/Viewer';
import { ModalContext } from '../../../../../context/ModalContext';
import { useFileDownload } from '../../../../../hooks/useFileDownload';
import { fetchGetFilesAgmAgendaAction } from '../../../../../services/api/agm/promiseActions';
import spriteCommonPng from '../../../../../styles/images/common/sprite/sp_common.png';
import {
  arrayBufferToBase64,
  getExtension
} from '../../../../../utils/StringUtil';
import { VOTE_RESULT_BUTTON_TYPE } from './DefaultButtonStyle';
import VoteResultButton from './VoteResultButton';
import { renderVoteResultButton } from './VoteResultItem';
const VoteAgendaBoxTitleBlock = styled.li`
  // temp
  cursor: pointer;
`;
const AgendaNo = styled.div`
  // .sequence
  position: absolute;
  top: 16px;
  left: 0;
  width: auto;
  background-color: #24375c;
  border-radius: 0px 20px 20px 0px;
  padding: 10px 32px;
  font-size: 15px;
  text-align: center;
  color: #fff;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: absolute;
    top: 13px;
    left: 0;
    width: auto;
    background-color: #24375c;
    border-radius: 0px 14px 14px 0px;
    padding: 6px 14px 4px;
    font-size: 12px;
    text-align: center;
    color: #fff;
  }
`;

const AgenaTitleWrapper = styled.a`
  // .boxlist_ty .unit_result li a
  display: inline-block;
  padding-right: 24px;
  position: relative;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    display: inline-block;
    padding-right: 18px;
    position: relative;
  }
  &::after {
    // .boxlist_ty .unit_result li a::after
    content: '';
    position: absolute;
    bottom: 30px;
    right: 0;
    width: 9px;
    height: 14px;
    background: url(${spriteCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -312px -15px;
  }
`;

const AgendaTitle = styled.p`
  // .boxlist_ty .unit_result li p
  position: relative;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
  margin-bottom: 34px;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: relative;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 16px;
    margin-bottom: 8px;
  }

  ${(props) =>
    props.isInvalid &&
    // .boxlist_ty .unit_invalid li p
    css`
      color: #9eb3cd;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        color: #9eb3cd;
      }
    `}

  &::after {
    // .boxlist_ty .unit_result li p::after
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: calc(50% - 10px);
    width: 20px;
    height: 2px;
    background: #9eb3cd;
  }
`;

const VoteAgendaResultBoxTitle = (props) => {
  const {
    agenda,
    agendaNo,
    title,
    isInvalid,
    onResultPopup,
    childCount,
    result
  } = props;
  const match = useRouteMatch();
  const agmSeqno = match.params.agmSeqno;
  const { callDownloadFetchAPIAgmParam } = useFileDownload();
  const photo = ['jpg', 'png', 'jpeg', 'gif'];
  const dispatch = useDispatch();
  const { openModal } = useContext(ModalContext);
  const openDetailContent = () => {
    openModal(
      `제 ${agenda.agendaNumber}호 의안 상세 내용`,
      agenda.agendaDetailContent
    );
  };

  const getDetailFile = () => {
    const extension = getExtension(agenda.fileName);
    const file = {
      fileName: agenda.fileName,
      filePath: agenda.filePath
    };
    if (photo.includes(extension.toLowerCase())) {
      const data = {
        agmSeqno: agmSeqno,
        agendaSeqno: agenda.agendaSeqno,
        params: file
      };
      dispatch(fetchGetFilesAgmAgendaAction(data)).then((res) => {
        if (!res.error) {
          openModal(
            `제 ${agenda.agendaNumber}호 의안 첨부 자료`,
            <Viewer
              imageSource={arrayBufferToBase64(res.data)}
              extension={extension}
            />
          );
        }
      });
    } else {
      callDownloadFetchAPIAgmParam(
        file,
        fetchGetFilesAgmAgendaAction,
        agmSeqno,
        agenda.agendaSeqno
      );
    }
  };

  return (
    <>
      <AgendaNo>{`의안 ${agendaNo}`}</AgendaNo>
      <VoteAgendaBoxTitleBlock>
        <AgenaTitleWrapper onClick={onResultPopup}>
          <AgendaTitle isInvalid={isInvalid}>
            {title}
            <DetailDataBlock>
              {!!agenda.agendaDetailContent && (
                <SpanBlock onClick={openDetailContent}>상세 내용</SpanBlock>
              )}
              {!!agenda.fileSize && (
                <SpanBlock onClick={getDetailFile}>첨부 자료</SpanBlock>
              )}
            </DetailDataBlock>
          </AgendaTitle>
        </AgenaTitleWrapper>
        {childCount === 0 &&
          renderVoteResultButton(result, childCount, onResultPopup)}

        {isInvalid && (
          <VoteResultButton
            type={VOTE_RESULT_BUTTON_TYPE.Invalid}
            active={isInvalid}
          >
            무효
          </VoteResultButton>
        )}
      </VoteAgendaBoxTitleBlock>
    </>
  );
};

const DetailDataBlock = styled.li`
  &&& {
    display: flex;
    gap: 0 25px;
    justify-content: center;
    width: 310px;
    padding-bottom: 10px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      width: auto;
    }
  }
`;

const SpanBlock = styled.span`
  // .exercisableStockAmmount
  display: inline-block;
  width: auto;
  padding: 10px 0 6px;
  font-size: 14px;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    font-size: 13px;
    padding: 10px 0 0;
  }

  border-bottom: solid 1px #e3e5ea;
  cursor: pointer;
  &:hover {
    font-weight: bold;
    border-bottom: solid 1px #9eb3cd;
  }
`;

export default VoteAgendaResultBoxTitle;
