import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { idpwsign, SIGN_TYPE } from '../../../../../components/auth/SignIframe';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../../components/button/Button';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import CheckBox from '../../../../../components/input/CheckBox';
import Input from '../../../../../components/input/Input';
import InputBox from '../../../../../components/input/InputBox';
import PhoneInput from '../../../../../components/input/PhoneInput';
import ListTag from '../../../../../components/layout/ListTag';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { getCurrentDatetime } from '../../../../../utils/DateUtil';
import AgreeBox from '../../../info/AgreeBox';
import SignupBody from '../SignupBody';
import SignupTitle from '../SignupTitle';

const SignupFormBlock = styled.div`
  width: 400px;
  margin: 0 auto;
  padding-top: 20px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    width: auto;
    padding-top: 0px;
  }
  & > div > ul {
    position: relative;
    & > li {
      width: 100%;
      padding-bottom: 20px;
      list-style: none;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        padding-bottom: 16px;
      }
    }
  }
`;

export const SignupAuthBlock = styled.div`
  margin-top: 0;
  border-top: 0;
  padding-top: 60px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin-top: 40px;
    padding-top: 0;
  }
  & > ul {
    display: flex;
    align-items: center;
    & > li {
      flex: 1;
      margin-left: 20px;
    }
    & > li:first-of-type {
      margin-left: 0;
    }
    & > li:only-of-type {
      text-align: center;
    }
  }
`;

const ONE_LINE = styled.div`
  border-bottom: 1px solid #e9ecf4;
  margin-bottom: 30px;
`;

const SignupStep2 = ({ register, handleSubmit, onSignup }) => {
  const [authComplete] = useState(true);
  const [checkedSms, setCheckedSms] = useState(false);
  const [checkedEmail, setCheckedEmail] = useState(false);
  const [smsCheckedDttm, setSmsCheckedDttm] = useState(null);
  const [emailCheckedDttm, setEmailCheckedDttm] = useState(null);
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => {
    return windowSize.width <= SCREEN_SIZE_MOBILE;
  }, [windowSize]);

  const onSign = () => {
    handleSubmit((data) => {
      const payload = {
        userName: data.userName,
        cellphNotificationAgreeDateTime: checkedSms ? smsCheckedDttm : null,
        emailNotificationAgreeDateTime: checkedEmail ? emailCheckedDttm : null,
        marketingAgreeDateTime: getCurrentDatetime(),
        utilClauseAgreeDateTime: '2021-08-06T17:00:00',
        individualInformationTreatAgreeDateTime: '2021-08-06T17:00:00',
        corpUser: {
          ...data.corpUser,
          personInChargeCell: `${data.frontCell}${data.middleCell}${data.endCell}`
        }
      };
      idpwsign(SIGN_TYPE.CORP, (signData) => onSignup(data, signData), payload);
    })();
  };

  useEffect(() => {
    setSmsCheckedDttm(getCurrentDatetime());
  }, [checkedSms]);

  useEffect(() => {
    setEmailCheckedDttm(getCurrentDatetime());
  }, [checkedEmail]);

  return (
    <SignupBody>
      <SignupTitle title="주주 정보를 입력합니다" />
      <SignupFormBlock>
        <div>
          <ul>
            {!isMobile && (
              <li>
                <ListTag />
              </li>
            )}
            <li>
              <InputBox title="사업자등록번호" isRequired isMobile={isMobile}>
                <Input
                  register={register}
                  name="corpUser.bizRegistrationNumber"
                  placeholder="'-'를 제외하고 입력"
                  isMobile={isMobile}
                  short={isMobile}
                  //readOnly={authComplete ? true : false}
                />
              </InputBox>
            </li>
            {authComplete && (
              <>
                <li>
                  <InputBox title="법인명" isRequired isMobile={isMobile}>
                    <Input
                      register={register}
                      name="userName"
                      isMobile={isMobile}
                      short={isMobile}
                      placeholder="법인명"
                    />
                    <CheckBox
                      register={register}
                      name="corpUser.institutionalInvestor"
                      title="자산운영사"
                      noMargin={isMobile}
                    />
                  </InputBox>
                </li>
                <ONE_LINE />
                <li>
                  <InputBox title="담당자명" isRequired isMobile={isMobile}>
                    <Input
                      register={register}
                      name="corpUser.personInChargeName"
                      isMobile={isMobile}
                      short={isMobile}
                      placeholder="담당자명"
                    />
                  </InputBox>
                </li>
                <li>
                  <InputBox title="휴대전화번호" isRequired isMobile={isMobile}>
                    <PhoneInput register={register} isMobile={isMobile} />
                  </InputBox>
                </li>
                <li>
                  <InputBox title="부서명" isMobile={isMobile}>
                    <Input
                      register={register}
                      name="corpUser.personInChargeDepartmentName"
                      short={isMobile}
                      placeholder="부서명"
                    />
                  </InputBox>
                </li>
                <li>
                  <InputBox title="직위명" isMobile={isMobile}>
                    <Input
                      register={register}
                      name="corpUser.personInChargePositionName"
                      isMobile={isMobile}
                      short={isMobile}
                      placeholder="직위명"
                    />
                  </InputBox>
                </li>
                <li>
                  <AgreeBox
                    setCheckedEmail={setCheckedEmail}
                    setCheckedSms={setCheckedSms}
                  />
                </li>
              </>
            )}
            <SignupAuthBlock>
              <ul>
                <li>
                  <Button
                    type={ButtonTypes.Primary}
                    size={ButtonSize.Medium}
                    onClick={onSign}
                  >
                    서비스 가입
                  </Button>
                </li>
              </ul>
            </SignupAuthBlock>
          </ul>
        </div>
      </SignupFormBlock>
    </SignupBody>
  );
};

export default SignupStep2;
