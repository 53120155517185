import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../../../components/button/Button';
import DataTable, {
  DataType
} from '../../../../../../components/datatable/DataTable';
import {
  fetchGetAgmProxyResultAgendaParticipantsAction,
  fetchGetAgmVoteboxResultAgendaParticipantsAction
} from '../../../../../../services/api/agm/promiseActions';
import { Icons } from '../../../../../../styles/Icons';
import {
  DateFormatName,
  getDateFormat
} from '../../../../../../utils/DateFormatUtil';
import { DATE_FORMAT } from '../../../../../../utils/DateUtil';
import { formatNumber } from '../../../../../../utils/StringUtil';
import { ExerciseMethod } from '../../../../../shareholder/exerciseRecord/main/BoxInfo';

const ParticipantsTable = ({
  agmSeqno,
  agendaSeqno,
  concentrateVote = false,
  exerciseMethod = '1'
}) => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);
  const [stockholdersPagingInfo, setStockHoldersPagingInfo] = useState({});
  const [stockholders, setStockholders] = useState([]);
  const [searchName, setSearchName] = useState('');
  const inputEl = useRef('');

  useEffect(() => {
    const params = {
      stockholderName: searchName,
      page: pageNumber,
      size: 10
    };
    const fetchAction =
      exerciseMethod === ExerciseMethod.VOTE
        ? fetchGetAgmVoteboxResultAgendaParticipantsAction
        : fetchGetAgmProxyResultAgendaParticipantsAction;
    dispatch(
      fetchAction({
        agmSeqno,
        agendaSeqno,
        params
      })
    ).then((res) => {
      if (!res.error) {
        setStockholders(res.data.content);
        setStockHoldersPagingInfo({
          pageNumber: res.data.number,
          size: res.data.size,
          totalCount: res.data.totalElements,
          totalPageNumber: res.data.totalPages
        });
      }
    });
  }, [dispatch, agmSeqno, agendaSeqno, pageNumber, exerciseMethod, searchName]);

  const DataSpan = (props) => {
    const keys = {
      Yes: '찬성',
      No: '반대',
      Absten: '기권'
    };
    const { name, data, isLast } = props;
    let elements = null;
    switch (name) {
      case keys.Yes:
        elements = <BlueSpanBlock>{formatNumber(data)}</BlueSpanBlock>;
        break;
      case keys.No:
        elements = <RedSpanBlock>{formatNumber(data)}</RedSpanBlock>;
        break;
      case keys.Absten:
        elements = <GreySpanBlock>{formatNumber(data)}</GreySpanBlock>;
        break;
      default:
        elements = <span>{data}</span>;
        break;
    }

    return (
      <>
        {elements}
        {!isLast && <br />}
      </>
    );
  };

  const dateFormatter = (column, data, idx) => {
    return (
      <td>
        {DATE_FORMAT(
          data.participateDateTime,
          getDateFormat(DateFormatName.LONG_DATE_TIME)
        )}
      </td>
    );
  };
  const stockExerciseFormatter = (column, data, idx) => {
    const textAlignStyle = !!column.align ? column.align : 'center';
    if (data.diversityExercise === 'O') {
      console.log('data ? ');
      console.log(data);
      const dataValues = data.stockExercise.data.filter(
        (item) => item.value !== 0
      );
      const dataKeys = data.stockExercise.data.filter(
        (item) => item.value !== 0
      );
      return (
        <>
          <td
            style={{ textAlign: textAlignStyle }}
            key={`dataTable_td_${idx}_1`}
          >
            {dataValues.map((item, dataIndex) => {
              return (
                <DataSpan
                  name={item.name}
                  data={item.name}
                  isLast={dataIndex === dataKeys.length - 1}
                />
              );
            })}
          </td>
          <td
            style={{ textAlign: textAlignStyle }}
            key={`dataTable_td_${idx}_2`}
          >
            {dataValues.map((item, dataIndex) => {
              return (
                <DataSpan
                  name={item.name}
                  data={item.value}
                  isLast={dataIndex === dataKeys.length - 1}
                />
              );
            })}
          </td>
        </>
      );
    } else {
      return (
        <td style={{ textAlign: textAlignStyle }} colSpan="2">
          <span>{formatNumber(data.stockExercise)}</span>
        </td>
      );
    }
  };

  const column = [
    {
      title: 'No',
      key: 'no',
      col: '6%'
    },
    {
      title: '주주',
      children: [
        {
          title: '주주명',
          key: 'stockholderName',
          col: '12%'
        },
        {
          title: '주민/사업자등록번호',
          key: 'stockholderRealNameNumber',
          col: '*'
        }
      ]
    },
    {
      title: ['행사 가능', '주식 수'],
      key: 'stockholderAllStock',
      col: '12%',
      align: 'right',
      type: 'number'
    },
    {
      title: ['의결한', '의결권'],
      key: 'exerciseStockAmount',
      col: '12%',
      align: 'right',
      type: 'number'
    },
    {
      title: ['찬/반', '투표행사'],
      key: 'stockExercise',
      colArr: '6%,10%',
      colSpan: 2,
      type: DataType.CUSTOM,
      format: stockExerciseFormatter
    },
    {
      title: ['불통일', '행사여부'],
      key: 'diversityExercise',
      col: '6%'
    },
    {
      title: '행사일시',
      key: 'participateDateTime',
      col: '*',
      type: DataType.CUSTOM,
      format: dateFormatter
    }
  ];

  const getDataSource = () => {
    const newArr = [];
    const { pageNumber, size } = stockholdersPagingInfo;
    stockholders &&
      stockholders?.forEach((el, index) => {
        const { stockholderName, stockholderRealNameNumber, voteRights } =
          el.stockholder;
        if (concentrateVote) {
          let exerciseStockAmount = 0;
          for (const e of el.exercises) {
            exerciseStockAmount += e.yesStockQuantity;
          }
          newArr.push({
            no: index++ + (pageNumber * size + 1),
            stockholderName: stockholderName,
            stockholderRealNameNumber: stockholderRealNameNumber,
            stockholderAllStock: voteRights?.find(
              (item) => item.agendaSeqno === agendaSeqno
            ).exercisableStockAmmount,
            // stockholderAllStock: 0,
            exerciseStockAmount: exerciseStockAmount,
            stockExercise: getConcExerStock(el),
            diversityExercise: el.exercises.length > 1 ? 'O' : 'X',
            participateDateTime: el.exercises[0].participateDateTime
          });
        } else {
          const target = el.exercises.find(
            (item) => item.agendaSeqno === agendaSeqno
          );
          newArr.push({
            no: index++ + (pageNumber * size + 1),
            stockholderName: stockholderName,
            stockholderRealNameNumber: stockholderRealNameNumber,
            stockholderAllStock: voteRights?.find(
              (item) => item.agendaSeqno === agendaSeqno
            ).exercisableStockAmmount,
            exerciseStockAmount: `${
              target.yesStockQuantity +
              target.noStockQuantity +
              target.absentStockQuantity
            }`,
            stockExercise: getExerciseStock(target),
            diversityExercise: getDiversityFlag(target) ? 'O' : 'X',
            participateDateTime: DATE_FORMAT(
              target.participateDateTime,
              getDateFormat(DateFormatName.SHORT_DATE_OTHER_2)
            )
          });
        }
      });
    return newArr;
  };

  const getConcExerStock = (el) => {
    // 집중투표
    if (el.exercises.length > 1) {
      let arr = [];
      el.exercises.forEach((vote) => {
        if (vote.agendaSeqno !== -1) {
          arr.push({
            name: vote.agendaName,
            value: vote.yesStockQuantity
          });
        } else {
          arr.push({
            name: '기권',
            value: vote.absentStockQuantity
          });
        }
      });

      const result = {
        isDiversity: true,
        data: arr
      };
      return result;
    } else {
      return el.exercises[0].agendaName;
    }
  };

  const getExerciseStock = (target) => {
    const { yes, no, absent } = getVoteExerciseAmount(target);
    if (getDiversityFlag(target)) {
      // 불통일
      const result = {
        isDiversity: true,
        data: [
          {
            name: '찬성',
            value: yes
          },
          {
            name: '반대',
            value: no
          },
          {
            name: '기권',
            value: absent
          }
        ]
      };
      return result;
    } else {
      // 통일
      return yes !== 0 ? '찬성' : no !== 0 ? '반대' : '기권';
    }
  };

  const getDiversityFlag = (target) => {
    const { yes, no, absent } = getVoteExerciseAmount(target);
    if (yes !== 0) {
      if (no !== 0 || absent !== 0) {
        return true;
      } else {
        return false;
      }
    } else if (no !== 0) {
      if (yes !== 0 || absent !== 0) {
        return true;
      } else {
        return false;
      }
    } else if (absent !== 0) {
      if (yes !== 0 || no !== 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const getVoteExerciseAmount = (target) => {
    const yes = target.yesStockQuantity;
    const no = target.noStockQuantity;
    const absent = target.absentStockQuantity;
    return { yes, no, absent };
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onClickBtn();
    }
  };

  const onClickBtn = (val) => {
    setSearchName(inputEl.current.value);
  };

  return (
    <>
      <TableTitleBlock mt3>
        <span>
          총{' '}
          <strong>
            {stockholdersPagingInfo &&
              formatNumber(stockholdersPagingInfo.totalCount)}
          </strong>
          건
        </span>
        <div>
          <input
            type="text"
            placeholder="주주명 입력"
            onKeyPress={onKeyPress}
            ref={inputEl}
          />
          <Button
            type={ButtonTypes.Basic}
            size={ButtonSize.Popup}
            startIcon={Icons.search_white}
            width={120}
            onClick={onClickBtn}
          >
            검색
          </Button>
        </div>
      </TableTitleBlock>
      <DataTable
        columns={column}
        dataSource={getDataSource()}
        pageConfig={{ ...stockholdersPagingInfo, onClick: setPageNumber }}
      />
    </>
  );
};

const TableTitleBlock = styled.div`
  // .layer_wrap .layer_body .tbl_ty .mt_ty01
  display: block;
  margin-top: 0;
  margin-bottom: 12px;
  position: relative;
  ${(props) =>
    props.mt3 &&
    css`
      margin-top: 3% !important;
    `}
  & > span {
    position: relative;
    display: inline-block;
    padding-left: 18px;
    font-size: 20px;
    font-weight: bold;
    // .tbl_ty > span
    font-size: 17px;

    &::after {
      content: '';
      position: absolute;
      top: 6px;
      left: 0;
      display: block;
      width: 3px;
      height: 18px;
      margin-top: -4.5px;
      background: #0572ef;
      height: 16px;
      margin-top: -5.5px;
    }
    & > strong {
      font-weight: 800;
    }
  }
  & > div {
    // .unit
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 14px;
    font-weight: normal;
    & > input {
      // .input_ty w_ty01
      height: 42px;
      padding: 0 20px;
      border-radius: 4px;
      background-color: #fff;
      border: solid 1px #ccd5e0;
      box-sizing: border-box;
      line-height: 1.54;
    }
  }
`;

const BlueSpanBlock = styled.span`
  color: #0094ff !important;
`;

const RedSpanBlock = styled.span`
  color: #ec2a1e !important;
`;

const GreySpanBlock = styled.span`
  color: #ccd5e0 !important;
`;

export default ParticipantsTable;
