import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../../../components/button/CommonStyle';

export const TableBlock = styled.div`
  // .mt_ty02
  &.mt_ty02 {
    margin-top: 6% !important;
  }
  // .tblcommon-01
  width: 100%;
  & > table {
    width: 100%;
    table-layout: fixed;
    border-collapse: initial;
    border-spacing: 0;
    & th {
      background: #fbfbfc;
      border-top: solid 1px #e9ecf4;
      border-right: solid 1px #e9ecf4;
      box-shadow: 0px 3px 2px rgb(0 0 0 / 3%);
      -webkit-box-shadow: 0px 3px 2px rgb(0 0 0 / 3%);
      -moz-box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.03);
      -ms-box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.03);
      position: relative;
      padding: 8px 8px 7px;
      height: 32px;
      font-size: 14px;
      text-align: center;
      transform: skew(-0.04deg);
      -webkit-transform: skew(-0.04deg);
      -moz-transform: skew(-0.04deg);
      -ms-transform: skew(-0.04deg);
      & > span {
        display: inline-block;
        & > strong {
          display: inline-block;
          width: 120px;
          text-align: right;
          color: #0094ff;
        }
      }
    }
    & td {
      padding: 8px 8px 7px;
      height: 34px;
      font-size: 15px;
      border-bottom: solid 1px #e9ecf4;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      transform: skew(-0.04deg);
      -webkit-transform: skew(-0.04deg);
      -moz-transform: skew(-0.04deg);
      -ms-transform: skew(-0.04deg);
    }
    & tbody th {
      background-color: rgb(233, 236, 244, 0.3);
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      -ms-box-shadow: none;
      border-left: solid 1px #e9ecf4 !important;
      border-right: solid 1px #e9ecf4 !important;
      border-bottom: solid 1px #e9ecf4 !important;
    }
    & tr:first-of-type th:last-of-type,
    tr:last-of-type th:last-of-type {
      border-right: 0;
    }
  }
`;

export const BoxListBlock = styled.div`
  // .boxlist_ty
  & > ul {
    & > li {
      margin: 12px 0;
      & > ul {
        // .unit
        ${(props) =>
          props.type === PopupType.STOCK_DETAIL &&
          css`
            padding: 20px 26px 10px !important;
            @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
              padding: 20px 20px 10px !important;
            }
          `}
        ${(props) =>
          props.type === PopupType.VOTING_LIMITS &&
          css`
            padding: 20px 26px 10px !important;
            @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
              padding: 20px 20px 10px !important;
            }
          `}
        position: relative;
        display: block;
        padding: 50px 100px 36px;
        background-color: #fff;
        border: solid 1px #d8e1eb;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 12%);
        /* & > li {
          width: 100%;
          font-size: 15px;
          letter-spacing: -0.5px;
          text-align: center;
        } */
        & > li {
          width: 100%;
          padding-bottom: 8px;
          letter-spacing: -0.5px;
          text-align: center;
          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            font-size: 15px;
            padding-bottom: 0;
          }
          & > div.sequence {
            position: absolute;

            /* top: -5px;
            left: -26px; */

            top: 10px;
            left: 0;

            width: auto;
            background-color: #24375c;
            border-radius: 0px 20px 20px 0px;
            padding: 8px 26px;
            font-size: 13px;
            text-align: center;
            color: #fff;
            @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
              position: absolute;

              /* top: -7px;
              left: -20px; */
              top: 10px;
              left: 0;

              width: auto;
              background-color: #24375c;
              border-radius: 0px 14px 14px 0px;
              padding: 6px 14px 4px;
              font-size: 12px;
              text-align: center;
              color: #fff;
            }
          }
          & > p {
            display: block;
            text-align: left;
            font-size: 13px;
            font-weight: normal;
            margin-bottom: 0;
            position: relative;
            @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
              display: block;
              text-align: left;
              font-size: 13px;
              font-weight: normal;
              position: relative;
              margin-bottom: 4px;

              // 제한사유를 보여주기 위해
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            & > span {
              position: absolute;
              right: 0;
              display: inline-block;
              min-width: 180px;
              padding: 0;
              font-size: 15px;
              text-align: right;
              @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
                position: absolute;
                right: 0;
                display: inline-block;
                min-width: 180px;
                padding: 0;
                text-align: right;

                font-size: 13px;
                color: #575f76;
                width: auto;

                // 제한사유를 보여주기 위해
                position: static;
                max-width: 50%;
              }
              & > strong {
                margin-left: 0;
                color: #0075ff;
                &.ft-col03 {
                  color: #ec2a1e !important;
                }
              }
            }
          }
        }
        & > li:first-of-type {
          padding-bottom: 30px;
          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            padding-bottom: 15px;
          }
          & > p {
            font-size: 18px;
            font-weight: 800;

            padding: 0 14px 0 48px;
            text-align: center;
            word-break: keep-all;
            margin-bottom: 0;
            @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
              padding: 0 14px 0 48px;
              font-size: 16px;
              font-weight: 800;
              text-align: center;
              word-break: keep-all;
              margin-bottom: 0;
            }
            ${(props) =>
              props.type === PopupType.VOTING_LIMITS &&
              css`
                padding-left: 82px;
                padding: 0 14px 0 48px;
                font-size: 16px;
                font-weight: 800;
                text-align: center;
                word-break: keep-all;
                margin-bottom: 0;
                @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
                  padding: 0 14px 0 48px;
                  font-size: 16px;
                  font-weight: 800;
                  text-align: center;
                  word-break: keep-all;
                  margin-bottom: 0;
                }
              `}
          }
        }
      }
    }
  }
`;

export const BoxInfoBlock = styled.div`
  // .boxinfo
  display: flex;
  // temp
  overflow-x: auto;
  & > ul {
    position: relative;
    flex: 1;
    align-content: center;
    align-items: center;
    border: solid 1px #d8e1eb;
    border-radius: 8px;
    padding: 12px 0 8px;
    background-color: rgba(233, 236, 244, 0.3);
    margin-left: 6px;
    transition: 0.2s linear;
    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -ms-transition: 0.2s linear;
    // temp
    min-width: 195px;
    & > li {
      width: 100%;
      letter-spacing: -0.5px;
      color: #080808;
      text-align: left;
      padding: 0;
      font-size: 14px;
      display: flex;
      opacity: 0.6;
      align-content: center;
      align-items: baseline;
      & > span {
        display: inline-block;
        height: 18px;
        color: #575f76;
        width: 66px;
        padding: 5px 0 5px 24px;
        margin-right: 0;
      }
      & > p {
        display: inline-block;
        font-weight: bold;
        font-size: 16px;
        & > span {
          width: auto;
          padding: 5px 0 2px;
          font-size: 14px;
          font-weight: normal;
          display: block;
        }
      }
    }
  }
  & > ul:first-of-type {
    /* padding-bottom: 30px; */
    & > li {
      position: relative;
    }
  }
  & > ul:only-of-type {
    background-color: rgba(233, 236, 244, 0);
    border-color: #24375c;
    & > li {
      opacity: 1;
      & > span {
        width: 124px;
        padding: 5px 0 5px 24px;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          padding: 3px 0 3px 20px;
          font-size: 13px;
        }
      }
      & > p {
        font-size: 17px;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          font-size: 15px;
        }
        & > span {
          padding: 5px 0 0 8px;
          font-size: 15px;
          display: inline-block;
        }
      }
    }
  }
  & > ul:only-of-type,
  & > ul:first-of-type {
    margin-left: 0;
  }

  // temp
  ${(props) => {
    console.log('props.selectedIndex');
    console.log(props.selectedIndex + 1);
    const index = props.selectedIndex + 1;
    if (index === 1) {
      return css`
        &&& ul:first-of-type {
          background-color: rgba(233, 236, 244, 0);
          border-color: #24375c;
          & > li {
            opacity: 1;
          }
        }
      `;
    } else if (index > 1) {
      return css`
        &&& ul:nth-of-type(${index}) {
          background-color: rgba(233, 236, 244, 0);
          border-color: #24375c;
          & > li {
            opacity: 1;
          }
        }
      `;
    } else {
    }
  }}
`;

export const DescPTagBlock = styled.p`
  line-height: 1.53;
  word-break: keep-all;
  margin-bottom: 8px;
`;

export const PopupType = {
  STOCK_DETAIL: 1,
  VOTING_LIMITS: 2
};
