import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import styled from 'styled-components';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import FileInput from '../../../components/input/FileInput';
import Input from '../../../components/input/Input';
import InputBox from '../../../components/input/InputBox';
import ListItem from '../../../components/layout/ListItem';
import { ListWrapBlock } from '../../../components/page/PageInner';
import ContentTabs, {
  ContentTabTypes
} from '../../../components/tabs/ContentTabs';
import ToastEditor from '../../../components/toastUI/ToastEditor';
import { useFileDownload } from '../../../hooks/useFileDownload';
import {
  fetchGetFaqCategoriesAction,
  fetchPostFaqAction,
  fetchPostNoticeAction
} from '../../../services/api/common/promiseActions';
import { fetchGetFilesTemporaryAction } from '../../../services/api/files/promiseActions';
import spCommonPng from '../../../styles/images/common/sprite/sp_common.png';

const BoardRegisterPage = () => {
  const match = useRouteMatch();
  const board = match.params.board;
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, setValue, handleSubmit } = useForm();
  const [faqCategory, setFaqCategory] = useState([]);
  const [selCategory, setSelCategory] = useState(0);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    dispatch(fetchGetFaqCategoriesAction()).then((res) => {
      if (!res.error) {
        setFaqCategory(res.data);
      }
    });
  }, [dispatch]);

  const getAction = () => {
    if (board === BOARD_CONST.NOTICE) {
      return fetchPostNoticeAction;
    } else if (board === BOARD_CONST.FAQ) {
      return fetchPostFaqAction;
    } else {
      return 'QnA 등록';
    }
  };

  const onChangeTab = (index) => {
    setSelCategory(index);
  };

  const onSubmit = (data) => {
    // data.content = contents;
    if (board === BOARD_CONST.FAQ) {
      data.categorySeqno = faqCategory[selCategory].categorySeqno;
    } else if (board === BOARD_CONST.NOTICE) {
      data.files = files;
    }
    const action = getAction();
    dispatch(action(data)).then((res) => {
      if (!res.error) {
        history.push(`/${board}`);
      }
    });
  };

  const callbackFileupload = (file, type) => {
    setFiles((prev) => [
      ...prev,
      {
        filePath: file.filePath,
        fileName: file.fileName,
        fileSize: file.fileSize,
        type: type
      }
    ]);
  };

  const { callDownloadFetchAPITemporary } = useFileDownload();

  const fileDownload = (type) => {
    const file = files.find((item) => item.type === type);
    if (typeof file !== 'undefined') {
      callDownloadFetchAPITemporary(file, fetchGetFilesTemporaryAction);
    }
  };

  return (
    <>
      <ColumnOnlyListWrapBlock>
        <ListTy02Block>
          <ul>
            <li>
              <ListTiBlock>
                <ListTagBlock>필수 입력사항</ListTagBlock>
              </ListTiBlock>
            </li>
            <li>
              <ListItem heightType="short">
                <InputBox title="제목" isRequired>
                  <Input
                    placeholder="제목을 입력하세요"
                    register={register}
                    name="title"
                  />
                </InputBox>
              </ListItem>
              <ListItem heightType="short">
                <InputBox title="내용" isRequired>
                  <ToastEditor name="content" setValue={setValue} />
                </InputBox>
              </ListItem>
              <ListItem>
                {board === BOARD_CONST.NOTICE ? (
                  <InputBox title="파일첨부" isRequired isVertical>
                    <FileInput
                      placeholder="첨부하실 파일을 선택하세요"
                      fileType={1}
                      callback={callbackFileupload}
                      fileDownload={fileDownload}
                    />
                    <FileInput
                      placeholder="첨부하실 파일을 선택하세요"
                      fileType={2}
                      callback={callbackFileupload}
                      fileDownload={fileDownload}
                    />
                    <FileInput
                      placeholder="첨부하실 파일을 선택하세요"
                      fileType={3}
                      callback={callbackFileupload}
                      fileDownload={fileDownload}
                    />
                  </InputBox>
                ) : board === BOARD_CONST.FAQ ? (
                  faqCategory.length !== 0 && (
                    <ListWrapBlock>
                      {typeof faqCategory !== 'undefined' && (
                        <ContentTabs
                          type={ContentTabTypes.UnderLine}
                          items={faqCategory.map((item) => item.categoryName)}
                          onClick={onChangeTab}
                        />
                      )}
                    </ListWrapBlock>
                  )
                ) : (
                  <></>
                )}
              </ListItem>
            </li>
          </ul>
        </ListTy02Block>
      </ColumnOnlyListWrapBlock>
      <ButtonGroup>
        <Button type={ButtonTypes.Basic} size={ButtonSize.Medium}>
          취소
        </Button>
        <Button
          type={ButtonTypes.Primary}
          size={ButtonSize.Medium}
          onClick={handleSubmit(onSubmit)}
        >
          등록
        </Button>
      </ButtonGroup>
    </>
  );
};

const BOARD_CONST = {
  NOTICE: 'notice',
  FAQ: 'faq',
  QNA: 'qna'
};

const ColumnOnlyListWrapBlock = styled.div`
  // .list_wrap .column-only
  margin: 0 auto;
  padding: 60px 0 0;
  max-width: 640px;
  padding-top: 4px;
`;

const ListTy02Block = styled.div`
  // .list_ty02
  & > ul {
    position: relative;
    & > li {
      width: 100%;
      padding-bottom: 24px;
      position: relative;
    }
    & > li:last-of-type,
    &:last-of-type > li:only-of-type {
      flex: 1;
      padding-bottom: 0;
    }
  }
`;

const ListTiBlock = styled.div`
  // .list_ti
  min-height: 28px;
  font-size: 27px;
  font-weight: bold;
  color: #0572ef;
`;

const ListTagBlock = styled.span`
  // .list_tag
  position: absolute;
  top: 7px;
  right: 0;
  display: block;
  padding-left: 14px;
  font-size: 12px;
  transform: skew(-0.04deg);
  font-weight: normal;
  color: #fb0000;
  &::after {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    display: block;
    width: 11px;
    height: 12px;
    margin-top: -5.5px;
    background: url(${spCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -189px -1px;
  }
`;

export default BoardRegisterPage;
