import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropsType from 'prop-types';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';

const TabBlock = styled.div`
  // .tab_ty
  max-width: 600px;
  margin: 0 auto;
  & > ul {
    position: relative;
    display: flex;
  }
  & > ul > li {
    flex: 1;
    text-align: center;
    cursor: pointer;
  }
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    ${(props) =>
      props.tabPlace === TAB_PLACE.NONE &&
      css`
        display: none;
      `}
  }
`;
const AnchorBlock = styled.a`
  // .tab_ty > ul > li a
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 20px 0 16px;
  background-color: transparent;
  font-size: 20px;
  letter-spacing: -1px;
  text-align: center;
  color: #a5b7ce;
  z-index: 1;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding: 12px 0 11px;
    font-size: 15px;
  }
  ${(props) =>
    props.select &&
    css`
      color: #2e2e34;
      font-weight: bold;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        font-weight: 800;
      }
    `}
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #e9ecf4;
    ${(props) =>
      props.select &&
      css`
        height: 3.5px;
        background-color: #0094ff;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          height: 2.2px;
        }
      `}
  }
`;

const PageTab = (props) => {
  const { itemArr, onChange, tabIndex, tabPlace, style, restrictStyle } = props;
  const [activeNum, setActiveNum] = useState(tabIndex);
  const onclickA = (idx) => {
    setActiveNum(idx);
    typeof onChange === 'function' && onChange(idx);
  };
  const printTitle = () => {
    return (
      <>
        {Array(itemArr.length)
          .fill()
          .map((_, index) => {
            return (
              <li key={`liKey_${index}`} style={{ ...restrictStyle }}>
                <AnchorBlock
                  select={activeNum === index ? true : false}
                  onClick={() => onclickA(index)}
                  value={index}
                >
                  {itemArr[index]}
                </AnchorBlock>
              </li>
            );
          })}
      </>
    );
  };
  return (
    <TabBlock tabPlace={tabPlace} style={{ ...style }}>
      <ul>{printTitle()}</ul>
    </TabBlock>
  );
};

export const TAB_PLACE = {
  NONE: 'none',
  CENTER: 'center'
};

PageTab.propTypes = {
  itemArr: PropsType.array,
  onChange: PropsType.func,
  tabIndex: PropsType.number
};

PageTab.defaultProps = {
  itemArr: ['항목1', '항목2', '항목3'],
  tabIndex: 0
};

export default PageTab;
