import styled from 'styled-components';
import MyAgmTable from './contents/MyAgmTable';

const ListWrapper = styled.div`
  // .list_wrap2
  max-width: 960px;
  margin: 0 auto;
`;

const UnorderedListBlock = styled.ul`
  & > li {
    border-bottom: solid 1px #e9ecf4;
  }
  & > li:last-of-type {
    border-bottom: 0;
  }
`;

const TitleBlock = styled.li`
  font-weight: bold;
  margin-top: 30px;
  border-bottom: 0;
`;

const MyAgmList = ({ agms }) => {
  return (
    <ListWrapper>
      <div>
        <UnorderedListBlock>
          <TitleBlock>
            <p>최근 3개월까지 주주총회 참여 내역정보 확인이 가능합니다.</p>
          </TitleBlock>
          <MyAgmTable agms={agms} />
        </UnorderedListBlock>
      </div>
    </ListWrapper>
  );
};

export default MyAgmList;
