import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import Viewer from '../../../../../components/viewer/Viewer';
import { ModalContext } from '../../../../../context/ModalContext';
import { useFileDownload } from '../../../../../hooks/useFileDownload';
import { fetchGetFilesAgmAgendaAction } from '../../../../../services/api/agm/promiseActions';
import spriteCommonPng from '../../../../../styles/images/common/sprite/sp_common.png';
import {
  arrayBufferToBase64,
  getExtension
} from '../../../../../utils/StringUtil';
import DefaultButtonStyle from './DefaultButtonStyle';

const VoteAgendaBoxTitleBlock = styled.li``;
const AgendaNo = styled.div`
  // .sequence
  position: absolute;
  top: 16px;
  left: 0;
  width: auto;
  background-color: #24375c;
  border-radius: 0px 20px 20px 0px;
  padding: 10px 32px;
  font-size: 15px;
  text-align: center;
  color: #fff;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: absolute;
    top: 13px;
    left: 0;

    width: auto;
    background-color: #24375c;
    border-radius: 0px 14px 14px 0px;
    padding: 6px 14px 4px;
    font-size: 12px;
    text-align: center;
    color: #fff;
  }
`;

const AgendaTitle = styled.p`
  // .boxlist_ty .unit li p
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0px;
  /* &&&::after {
    content: '';
    display: block;
    position: absolute;
    top: -12%;
    right: -35px;
    width: 24px;
    height: 27px;
    background: url(${spriteCommonPng}) no-repeat;
    background-size: 400px auto;
    background-position: -33px -146px;
  } */
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
  }
`;

export const AbstentionButton = styled(DefaultButtonStyle)`
  // .boxlist_ty .unit .invalid
  position: absolute;
  top: 16px;
  right: 16px;
  display: block;
  margin: 0;
  font-size: 16px;
  color: #6d7593;
  width: 100px;
  padding: 0;
  min-height: 38px;
  background-color: #f4f5f9;
  border-radius: 20px;
  border: 2px solid #e8edf3;
  box-shadow: none;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: absolute;
    top: 12px;
    right: 10px;

    display: block;
    margin: 0;
    font-size: 13px;
    color: #6d7593;
    width: 62px;
    padding: 0;
    min-height: 28px;
    background-color: #f4f5f9;
    border-radius: 15px;
    border: 2px solid #e8edf3;
    box-shadow: none;
  }
  ${(props) =>
    props.active &&
    // .boxlist_ty .unit .invalid.on
    css`
      color: #fff;
      background-color: #24375c;
      border: 0;
      // temp
      font-weight: bold;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        color: #fff;
        background-color: #24375c;
        border: 0;
      }
    `}
`;

const VoteAgendaBoxTitle = (props) => {
  const { agenda, useAbsten, isAbsten, onAbstenClick } = props;
  const { callDownloadFetchAPIAgmParam } = useFileDownload();
  const photo = ['jpg', 'png', 'jpeg', 'gif'];
  const dispatch = useDispatch();
  const { openModal } = useContext(ModalContext);

  const openDetailContent = () => {
    openModal(
      `제 ${agenda.agendaNumber}호 의안 상세 내용`,
      agenda.agendaDetailContent
    );
  };

  const getDetailFile = () => {
    const extension = getExtension(agenda.fileName);
    const file = {
      fileName: agenda.fileName,
      filePath: agenda.filePath
    };
    if (photo.includes(extension.toLowerCase())) {
      const data = {
        agmSeqno: agenda.agmSeqno,
        agendaSeqno: agenda.agendaSeqno,
        params: file
      };
      dispatch(fetchGetFilesAgmAgendaAction(data)).then((res) => {
        if (!res.error) {
          openModal(
            `제 ${agenda.agendaNumber}호 의안 첨부 자료`,
            <Viewer
              imageSource={arrayBufferToBase64(res.data)}
              extension={extension}
            />
          );
        }
      });
    } else {
      callDownloadFetchAPIAgmParam(
        file,
        fetchGetFilesAgmAgendaAction,
        agenda.agmSeqno,
        agenda.agendaSeqno
      );
    }
  };

  return (
    <>
      <AgendaNo>{`의안 ${agenda.agendaNumber}`}</AgendaNo>
      <VoteAgendaBoxTitleBlock>
        <AgendaTitle>{agenda.agendaName}</AgendaTitle>
      </VoteAgendaBoxTitleBlock>
      {useAbsten && (
        <AbstentionButton active={isAbsten} onClick={onAbstenClick}>
          기권
        </AbstentionButton>
      )}
      <DetailDataBlock>
        {!!agenda.agendaDetailContent && (
          <SpanBlock onClick={openDetailContent}>상세 내용</SpanBlock>
        )}
        {!!agenda.fileSize && (
          <SpanBlock onClick={getDetailFile}>첨부 자료</SpanBlock>
        )}
      </DetailDataBlock>
    </>
  );
};

const DetailDataBlock = styled.li`
  &&& {
    display: flex;
    gap: 0 25px;
    justify-content: center;
    width: 310px;
    padding-bottom: 10px;
  }
`;

const SpanBlock = styled.span`
  // .exercisableStockAmmount
  display: inline-block;
  width: auto;
  padding: 10px 0 6px;
  font-size: 14px;

  border-bottom: solid 1px #e3e5ea;
  cursor: pointer;
  &:hover {
    font-weight: bold;
    border-bottom: solid 1px #9eb3cd;
  }
`;

export default VoteAgendaBoxTitle;
