import { useMemo, useState } from 'react';
import styled from 'styled-components';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../../components/button/Button';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import useWindowSize from '../../../../../hooks/useWindowSize';
import {
  DateFormatName,
  getDateFormat
} from '../../../../../utils/DateFormatUtil';
import { DATE_FORMAT } from '../../../../../utils/DateUtil';
import {
  checkUndefinedNum,
  formatNumber
} from '../../../../../utils/StringUtil';
import CertificateDataTable from '../components/CertificateDataTable';

const PtagWrapper = styled.p`
  &&& {
    font-size: 15px;
    color: #ec2a1e;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      font-size: 12px;
      color: #ec2a1e;
    }
  }
`;

const MultiExercise = ({
  info,
  selectedVoters,
  setSelectedVoters,
  isVoteScreen,
  openVotingLimitsPopup,
  openStockDetailPopup,
  onCleanData,
  isCleanData,
  selectNonParticipation
}) => {
  const [isSelectNonParticipation, setIsNonParticipation] = useState(false);
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => {
    return windowSize.width <= SCREEN_SIZE_MOBILE;
  }, [windowSize]);
  return (
    <>
      <li>
        <span>주주총회일</span>
        <p>
          {DATE_FORMAT(
            info.agmDateTime,
            getDateFormat(DateFormatName.SHORT_DATE_2)
          )}
        </p>
      </li>
      <li>
        <span>행사기간</span>
        <p>{`${DATE_FORMAT(
          info.voteBeginDateTime,
          getDateFormat(DateFormatName.LONG_DATE_TIME)
        )} ~ ${DATE_FORMAT(
          info.voteEndDateTime,
          getDateFormat(DateFormatName.LONG_DATE_TIME)
        )} `}</p>
      </li>
      {isVoteScreen && (
        <>
          <li>
            <button onClick={openVotingLimitsPopup}>의결권 제한</button>
            <span>총 보유주식</span>
            <p>
              {selectedVoters.length === 0
                ? '의결권을 행사할 주주를 선택하세요'
                : `${formatNumber(
                    selectedVoters.reduce(
                      (total, current) =>
                        total +
                        current.commonStockAmount +
                        checkUndefinedNum(current.preferredStockAmount) +
                        checkUndefinedNum(
                          current.exercisablePreferredStockAmount
                        ),
                      0
                    )
                  )}주`}
            </p>
          </li>
          <CertificateDataTable
            voters={info.voters}
            selectedVoters={selectedVoters}
            setSelectedVoters={setSelectedVoters}
            openStockDetailPopup={openStockDetailPopup}
            onCleanData={onCleanData}
            isCleanData={isCleanData}
            isSelectNonParticipation={isSelectNonParticipation}
          />
          <li>
            {!isMobile && <span></span>}
            <PtagWrapper>
              <span style={{ color: '#000826' }}>
                - 주주명을 클릭하면, 해당 주주의 상세 보유주식을 확인할 수
                있습니다.
              </span>
              <p style={{ marginTop: '2.5px' }} />
              <span>- 선택한 주주에 대해서만 의결권 행사가 가능합니다</span>
            </PtagWrapper>
            {/* <br /> */}
            <Button
              type={ButtonTypes.Primary}
              size={ButtonSize.Default}
              onClick={() => setIsNonParticipation((prev) => !prev)}
              width={isMobile && 120}
            >
              {isSelectNonParticipation
                ? '미참여자 선택 해제'
                : '미참여자 선택'}
            </Button>
          </li>
        </>
      )}
    </>
  );
};

export default MultiExercise;
