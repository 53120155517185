import React from 'react';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../../components/button/Button';
import Select from '../../../../../components/input/Select';
import {
  AgendaResultType,
  getConstNameByCode
} from '../../../../../utils/constant/AgmConstant';

const ResultTableSelect = ({
  onChange,
  seletcedValue,
  parentAgendaSeqno,
  agendaSeqno,
  disabled,
  isEdit
}) => {
  return (
    <>
      {isEdit ? (
        <Select
          value={seletcedValue}
          onchange={(e) =>
            onChange(e.target.value, parentAgendaSeqno, agendaSeqno)
          }
          disabled={disabled}
          style={{ width: '90%' }}
          noDefault
        >
          <option>결과 선택</option>
          <option value="1">가결</option>
          <option value="2">부결</option>
          <option value="3">무효</option>
          <option value="4">보고</option>
        </Select>
      ) : (
        <>
          {seletcedValue && (
            <Button type={ButtonTypes.Primary} size={ButtonSize.Table}>
              {getConstNameByCode(AgendaResultType, seletcedValue)}
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default ResultTableSelect;
