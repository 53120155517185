import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../components/button/Button';
import DataTable from '../../../../components/datatable/DataTable';
import ListHeader from '../../../../components/layout/ListHeader';
import ListItemVertical from '../../../../components/layout/ListItemVertical';
import Section from '../../../../components/layout/Section';
import { MODAL_SIZE } from '../../../../components/modal/Modal';
import { ModalContext } from '../../../../context/ModalContext';
import {
  fecthPutAgmAgendaAction,
  fetchDeleteAgmAgendaAction,
  fetchGetAgmAgendasParentOnlyAction,
  fetchGetAgmOutlineAction,
  fetchPostAgmAgendaAction
} from '../../../../services/api/agm/promiseActions';
import {
  AgendaCategory,
  getConstNameByCode
} from '../../../../utils/constant/AgmConstant';
import { AGM_REGISTER_STEPS } from '../AgmRegister';
import AgmRegisterPageButton from '../main/components/AgmRegisterPageButton';
import AgmRegisterStep2Modal from './AgmRegisterStep2Modal';
import StockholdersRegister from './main/StockholdersRegister';

/*
  주주총회 등록 - 02. 의안/주주명부 등록
*/
const AgmRegisterStep2 = ({ register, handleFile, moveStep }) => {
  const match = useRouteMatch();
  const { agmSeqno } = match.params;
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [agendas, setAgendas] = useState([]);
  const [parseAgendas, setParseAgendas] = useState([]);
  const [reRender, setReRender] = useState(true);
  const [fileUploadCplt, setFileUploadCplt] = useState(false);
  const [agmInfo, setAgmInfo] = useState();
  const { openModal, setModalConfig, closeModal, setModalContent } =
    useContext(ModalContext);

  useEffect(() => {
    if (!!agmSeqno) {
      dispatch(fetchGetAgmAgendasParentOnlyAction({ agmSeqno })).then((res) => {
        if (!res.error) {
          reRender && setAgendas(res.data);
          setReRender(false);
        } else {
          console.log(res);
        }
      });
      dispatch(fetchGetAgmOutlineAction({ agmSeqno })).then((res) => {
        if (!res.error) {
          setAgmInfo(res.data);
        }
      });
    }
  }, [dispatch, reRender, agmSeqno]);
  useEffect(() => {
    setReRender(true);
  }, [closeModal]);
  useEffect(() => {
    const datas = [];
    agendas.forEach((agenda, index) => {
      const data = {
        agendaNumber: agenda.agendaNumber,
        agendaName: agenda.agendaName,
        agendaDetailContent: agenda.agendaDetailContent,
        childAgendaCount:
          agenda.childAgendaCount === 0
            ? '비분리'
            : agenda.concentrateVote
            ? '비분리'
            : '분리',
        concentrateVote: agenda.concentrateVote ? '집중' : '비집중',
        fileName: !!agenda.fileName ? agenda.fileName : ''
      };
      datas.push(data);
    });
    setParseAgendas(datas);
  }, [agendas]);

  const getAgendaData = useCallback((agendaSeqno, agendaNumber, form) => {
    const result = {
      agendaNumber: agendaNumber,
      agendaName:
        form.agendaCategory === AgendaCategory.ETC.code
          ? form.agendaName
          : getConstNameByCode(AgendaCategory, form.agendaCategory),
      agendaDetailContent: form.agendaDetailContent,
      agendaCategory: form.agendaCategory,
      concentrateVote: Object.keys(form).includes('concentrateVote')
        ? form.concentrateVote
          ? true
          : false
        : false,
      filePath: !!form.files ? form.files.filePath : null,
      fileName: !!form.files ? form.files.fileName : null,
      fileSize: !!form.files ? form.files.fileSize : null,
      childs: Object.keys(form).includes('childs') ? form.childs : [],
      passChildAgendaCount: form.passChildAgendaCount
    };
    if (!!agendaSeqno) {
      result.agendaSeqno = agendaSeqno;
    }
    return result;
  }, []);

  const openAgendaRegisterModal = () => {
    // 추가 후 새 form 띄우기
    setModalContent('');
    setModalConfig({
      size: MODAL_SIZE.MEDIUM,
      buttons: null,
      isNoButton: true
    });
    openModal(
      `제 ${agendas.length + 1}호 의안`,
      <AgmRegisterStep2Modal
        agmSeqno={agmSeqno}
        otherEvent={(form) => registerAgenda(form)}
        closeModal={closeModal}
        agendaNum={agendas.length + 1}
      />
    );
  };

  const getIsUpdateAgenda = (agendaSeqno) => {
    return !!agendaSeqno;
  };

  const registerAgenda = (form, agendaSeqno = null, agendaNumber = null) => {
    const isUpdate = getIsUpdateAgenda(agendaSeqno);
    const data = isUpdate
      ? getAgendaData(agendaSeqno, agendaNumber, form)
      : getAgendaData(null, agendas.length + 1, form);
    const action = isUpdate
      ? fecthPutAgmAgendaAction
      : fetchPostAgmAgendaAction;
    closeModal();
    dispatch(action({ agmSeqno, data })).then((res) => {
      if (!res.error) {
        setAgendas([...agendas, res.data]);
      } else {
        openModal('에러', res.error.user_message);
      }
    });
  };

  const openAgendaUpdateModal = (e) => {
    const agendaIndex = e.currentTarget.rowIndex - 1;
    const agendaNumber = agendas[agendaIndex].agendaNumber;
    setModalConfig({
      size: MODAL_SIZE.MEDIUM,
      buttons: null,
      isNoButton: true
    });
    openModal(
      `제 ${agendaNumber}호 의안`,
      <AgmRegisterStep2Modal
        agmSeqno={agmSeqno}
        otherEvent={(data) =>
          registerAgenda(data, agendas[agendaIndex].agendaSeqno, agendaNumber)
        }
        closeModal={closeModal}
        agendaNum={agendaNumber}
        resetValue={agendas[agendaIndex]}
      />
    );
  };
  const columns = [
    {
      title: '의안번호',
      key: 'agendaNumber'
    },
    {
      title: '의안명',
      key: 'agendaName'
    },
    {
      title: '의안상세내용',
      key: 'agendaDetailContent'
    },
    {
      title: '의안분리',
      key: 'childAgendaCount'
    },
    {
      title: '투표방법',
      key: 'concentrateVote'
    },
    {
      title: '추가자료',
      key: 'fileName'
    }
  ];
  const actionItems = useCallback(() => {
    const deleteAgenda = () => {
      if (selectedRowKeys.length !== 0) {
        const agendaSeqnoArr = selectedRowKeys.map((index) => {
          return agendas[index].agendaSeqno;
        });
        const data = {
          agendaSeqno: agendaSeqnoArr.toString()
        };
        dispatch(fetchDeleteAgmAgendaAction({ agmSeqno, data })).then((res) => {
          if (!res.error) {
            openModal('완료', '삭제가 완료되었습니다');
            setReRender(true);
            setSelectedRowKeys([]);
          } else {
            openModal('실패', '삭제를 실패했습니다.');
            console.log(res);
          }
        });
      }
    };
    return (
      <>
        <Button
          type={ButtonTypes.Primary}
          size={ButtonSize.Table}
          onClick={openAgendaRegisterModal}
        >
          추가
        </Button>
        <Button
          type={ButtonTypes.Basic}
          size={ButtonSize.Table}
          onClick={deleteAgenda}
        >
          삭제
        </Button>
      </>
    );
  }, [selectedRowKeys, openAgendaRegisterModal, agendas, dispatch, agmSeqno]);

  const moveNextStep = () => {
    !!agendas.length || openErrorModal('의안을 등록해주세요');
    fileUploadCplt || openErrorModal('명부 파일을 업로드해주세요');

    !!agendas.length && fileUploadCplt && moveStep(AGM_REGISTER_STEPS.LIMITS);
  };

  const openErrorModal = (message) => {
    setModalConfig({ size: MODAL_SIZE.SHORT, buttons: null });
    openModal('오류', message);
  };

  return (
    <>
      <Section wide>
        <ListHeader title="주주총회 의안" isRequired />
        <DataTable
          columns={columns}
          dataSource={parseAgendas}
          onSelected={setSelectedRowKeys}
          clearSeleted={selectedRowKeys.length === 0}
          onRowClick={openAgendaUpdateModal}
          actionItems={actionItems()}
        />
      </Section>
      <Section wide>
        <ListHeader
          title="주주명부 등록"
          description="총 발행주식수를 입력하시고 주주명부를 업로드 합니다.
명의 개서 대행기관이 직접관리이면 샘플파일을 다운로드하여 작성후 업로드 합니다."
          isRequired
        />
        <ListItemVertical>
          {agmInfo && (
            <StockholdersRegister
              agmSeqno={agmSeqno}
              setFileUploadCplt={setFileUploadCplt}
              agmInfo={agmInfo}
            />
          )}
        </ListItemVertical>
      </Section>
      <AgmRegisterPageButton
        usePrev
        useNext
        step={AGM_REGISTER_STEPS.AGENDA}
        handleNextBtn={moveNextStep}
      />
    </>
  );
};

export default AgmRegisterStep2;
