import { createPromiseAction } from '@adobe/redux-saga-promise';
/**
 * 토큰발급 및 로그인
 */
// PASS 인증 요청
export const fetchGetAuthPassCertAction = createPromiseAction(
  'FETCH_GET_AUTH_PASS_CERT'
);
// 토큰발급
export const fetchGetAuthTestTokenAction = createPromiseAction(
  'FETCH_GET_AUTH_TEST_TOKEN'
);
// 토큰 리프레시
export const fetchPostAuthRefreshAction = createPromiseAction(
  'FETCH_POST_AUTH_REFRESH'
);
// 공동인증서 재등록
export const fetchPutAuthCertificateAction = createPromiseAction(
  'FETCH_PUT_AUTH_CERTIFICATE'
);
// 로그인-공동인증서
export const fetchPostAuthLoginCertAction = createPromiseAction(
  'FETCH_POST_AUTH_LOGIN_CERT'
);
// 로그인-PASS
export const fetchPostAuthLoginPassAction = createPromiseAction(
  'FETCH_POST_AUTH_LOGIN_PASS'
);
// 로그인-PASS callback
export const fetchPostAuthLoginPassCallbackAction = createPromiseAction(
  'FETCH_POST_AUTH_LOGIN_PASS_CALLBACK'
);

/*
  공동인증서 재등록
  */
// 공동인증서 재등록 - 개인 사용자
export const fetchPutAuthCertificateIndividualAction = createPromiseAction(
  'FETCH_PUT_AUTH_CERTIFICATE_INDIVIDUAL'
);
// 공동인증서 재등록 - 법인 사용자
export const fetchPutAuthCertificateBizAction = createPromiseAction(
  'FETCH_PUT_AUTH_CERTIFICATE_BIZ'
);
// 로그인 - 발행회사 조회용
export const fetchPostAuthLoginPasswordAction = createPromiseAction(
  'FETCH_POST_AUTH_LOGIN_PASSWORD'
);
// 로그인 - 증권사
export const fetchPostAuthLoginAdminAction = createPromiseAction(
  'FETCH_POST_AUTH_LOGIN_ADMIN'
);
