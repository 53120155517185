import styled from 'styled-components';

export const BtnAreaBlock = styled.div`
  // .btn_area
  margin-top: 60px;
  border-top: 1px solid #e9ecf4;
  padding-top: 60px;
  & > ul {
    display: flex;
    align-items: center;
    & > li {
      flex: 1;
      margin-left: 20px;
      & > button {
        display: flex;
        width: 100%;
        height: 100%;
        min-width: auto;
        align-items: center;
        justify-content: center;
      }
    }
    & > li:only-of-type {
      text-align: center;
      & > button {
        display: inline-block;
        width: auto;
        min-width: 200px;
        margin-left: 4px;
      }
      & > button:first-child {
        margin-left: 0;
      }
    }
    & > li:first-of-type {
      margin-left: 0;
    }
  }
`;
