import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../components/button/Button';
import ButtonGroup from '../../../../components/button/ButtonGroup';
import CheckBox from '../../../../components/input/CheckBox';
import Input from '../../../../components/input/Input';
import InputBox from '../../../../components/input/InputBox';
import Radio from '../../../../components/input/Radio';
import RadioGroup from '../../../../components/input/RadioGroup';
import Select from '../../../../components/input/Select';
import ListBox from '../../../../components/layout/ListBox';
import ListHeader from '../../../../components/layout/ListHeader';
import ListItem from '../../../../components/layout/ListItem';
import Section from '../../../../components/layout/Section';
import { ModalContext } from '../../../../context/ModalContext';
import { fetchPostFilesTemporaryAction } from '../../../../services/api/files/promiseActions';
import { fetchGetIssueIncorporatedFilesAction } from '../../../../services/api/issueIncorporated/promiseActions';
import { fetchGetTermsAction } from '../../../../services/api/terms/promiseActions';
import { Icons } from '../../../../styles/Icons';
import DataTableTest from './DataTableTest';

const TestBlock = styled.div``;

const Test = () => {
  const [checkedValue, setCheckedValue] = useState('1');
  const [imgSrc] = useState('');
  const dispatch = useDispatch();
  const onChangeFile = (event) => {
    if (event.target.files !== null) {
      const fd = new FormData();
      fd.append('file', event.target.files[0]);
      dispatch(fetchPostFilesTemporaryAction(fd))
        .then((value) => {
          console.log(value);
        })
        .catch((e) => console.log(e));
    }
  };

  const onFetchFiles = () => {
    dispatch(
      fetchGetIssueIncorporatedFilesAction({ bizRegNo: 6028700689, fileCd: 1 })
    ).then((value) => {
      var fileName = value.meta.headers['content-disposition']
        .split("''")[1]
        .split(';')[0];
      fileDownload(value.data, decodeURI(fileName));
    });
  };

  const fileDownload = (data, fileName) => {
    console.log(data);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', decodeURI(fileName));
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const onFetchTerms = () => {
    dispatch(fetchGetTermsAction()).then((value) => {
      console.log(value);
    });
  };

  let { handleModal, openModal, setModalConfig } = useContext(ModalContext);

  const al = () => {
    alert(18);
  };

  const modal = () => {
    openModal(
      '모달 테스트',
      <Button onClick={() => alert('1234')}>누르면 Alert!</Button>,
      al
    );
  };

  const modal2 = () => {
    setModalConfig({ size: 'medium' });
    openModal('모달 테스트', <DataTableTest />);
  };

  return (
    <TestBlock>
      <div>
        파일 전송 테스트
        <input type="file" onChange={onChangeFile} />
      </div>
      <div>
        파일 조회 테스트
        <button onClick={onFetchFiles}>Fetch Files</button>
        <Link
          to="/v1/issue-incorporated/6028700689/files/2"
          download
          target="_self"
        >
          파일 다운로드
        </Link>
        <img src={imgSrc} alt="test" />
      </div>
      <div>
        약관 조회 테스트
        <button onClick={onFetchTerms}>Fetch Terms</button>
      </div>
      <RadioGroup
        name="테스트"
        vvalue={checkedValue}
        selectedValue={checkedValue}
        onChange={(value) => {
          //console.log('onChange value = ' + value);
          setCheckedValue(value);
        }}
      >
        <Radio label="분리" value="1" />
        <Radio label="비분리" value="2" />
      </RadioGroup>
      <br />
      <Button>하이루</Button>
      <Button
        primary
        startIcon={Icons.search_white}
        onClick={() =>
          handleModal(
            <Button startIcon={Icons.search_blue}>컴포넌트 테스트</Button>
          )
        }
      >
        모달!
      </Button>
      <Button primary endIcon={Icons.search_white} onClick={modal}>
        openModal()
      </Button>
      <Button primary endIcon={Icons.search_white} onClick={modal2}>
        openModal() no action
      </Button>
      <Button basic>하이루</Button>

      <div style={{ margin: 50 }}>
        {/* 테스트 버튼 타입 */}
        <ButtonGroup>
          <Button>defaults</Button>
          <Button type={ButtonTypes.Basic}>defaults</Button>
          <Button type={ButtonTypes.Primary}>defaults</Button>
          <Button type={ButtonTypes.Outline}>defaults</Button>
        </ButtonGroup>

        <ButtonGroup>
          <Button size={ButtonSize.Small}>small</Button>
          <Button size={ButtonSize.Small} type={ButtonTypes.Basic}>
            small
          </Button>
          <Button size={ButtonSize.Small} type={ButtonTypes.Primary}>
            small
          </Button>
          <Button size={ButtonSize.Small} type={ButtonTypes.Outline}>
            small
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button size={ButtonSize.Medium}>medium</Button>
          <Button size={ButtonSize.Medium} type={ButtonTypes.Basic}>
            medium
          </Button>
          <Button size={ButtonSize.Medium} type={ButtonTypes.Primary}>
            medium
          </Button>
          <Button size={ButtonSize.Medium} type={ButtonTypes.Outline}>
            medium
          </Button>
        </ButtonGroup>

        <ButtonGroup>
          <Button size={ButtonSize.Large}>large</Button>
          <Button size={ButtonSize.Large} type={ButtonTypes.Basic}>
            large
          </Button>
          <Button size={ButtonSize.Large} type={ButtonTypes.Primary}>
            large
          </Button>
          <Button size={ButtonSize.Large} type={ButtonTypes.Outline}>
            large
          </Button>
        </ButtonGroup>

        <ButtonGroup>
          <Button startIcon={Icons.search_blue}>defaults</Button>
          <Button small startIcon={Icons.search_blue}>
            small
          </Button>
          <Button medium startIcon={Icons.search_blue}>
            medium
          </Button>
          <Button large startIcon={Icons.search_blue}>
            large
          </Button>
          <Button large outline startIcon={Icons.search_blue}>
            large
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button primary endIcon={Icons.search_white}>
            defaults
          </Button>
          <Button primary small endIcon={Icons.search_white}>
            small
          </Button>
          <Button primary medium endIcon={Icons.search_white}>
            medium
          </Button>
          <Button primary large endIcon={Icons.search_white}>
            large
          </Button>
          <Button basic large outline endIcon={Icons.search_white}>
            large
          </Button>
        </ButtonGroup>
      </div>

      <div style={{ margin: 50 }}>
        {/* 테스트 버튼 그룹 */}
        <ButtonGroup>
          <Button>일반 버튼</Button>
          <Button startIcon={Icons.search_blue}>파란돋보기 버튼</Button>
          <Button primary endIcon={Icons.search_white}>
            하얀돋보기 강조 버튼
          </Button>
        </ButtonGroup>
      </div>

      <div style={{ margin: 50 }}>
        <Section>
          <ListBox>
            <ListHeader title="테스트" isRequired />
          </ListBox>
          <ListBox>
            <ListHeader title="테스트 필수 입력" isRequired />
            <ListItem>
              <InputBox title="발행회사명 (한글)" isRequired>
                <Input placeholder="회사명을 입력하세요 (한글)" />
              </InputBox>
              <InputBox title="발행회사명 (영문)" isRequired>
                <Input placeholder="회사명을 입력하세요 (영문)" />
              </InputBox>
            </ListItem>

            <ListItem>
              <InputBox title="법인 등록번호" isRequired error="에러입니다..!">
                <Input placeholder="법인명/법인등록번호" type="password" />
                <Button primary endIcon={Icons.paper_check}>
                  법인등기등본
                </Button>
              </InputBox>
              <InputBox title="사업자 등록번호" isRequired>
                <Input placeholder="법인명/법인등록번호" />
                <Button basic endIcon={Icons.paper_add}>
                  사업자 등록증
                </Button>
              </InputBox>
            </ListItem>

            <ListItem>
              <InputBox title="주식시장 구분" isRequired>
                <Select placeholder="법인명/법인등록번호">
                  <option value="1">코스피</option>
                  <option value="2">눈스피</option>
                  <option value="3">입스피</option>
                </Select>
              </InputBox>
              <InputBox title="법인 등록번호" isRequired error="에러입니다..!">
                <Select placeholder="법인명/법인등록번호">
                  <option value="1">코스피</option>
                  <option value="2">눈스피</option>
                  <option value="3">입스피</option>
                </Select>
                <Button primary endIcon={Icons.paper_check}>
                  선택
                </Button>
              </InputBox>
            </ListItem>
            <ListItem>
              <InputBox title="법인 등록번호" isRequired error="에러입니다..!">
                <Input placeholder="법인명/법인등록번호" />
                <CheckBox title="문자 알림 동의" />
              </InputBox>
              <InputBox title="사업자 등록번호" isRequired>
                <Input placeholder="법인명/법인등록번호" />
                <Button basic endIcon={Icons.paper_add}>
                  사업자 등록증
                </Button>
              </InputBox>
            </ListItem>
          </ListBox>
        </Section>
      </div>
    </TestBlock>
  );
};

export default Test;
