import PropTypes from 'prop-types';
import styled from 'styled-components';

const Viewer = (props) => {
  const { imageSource, extension } = props;
  return (
    <>
      <ImageBlock
        src={`data:image/${extension};base64,${imageSource}`}
        alt="첨부 자료"
      />
    </>
  );
};

const ImageBlock = styled.img`
  width: 100%;
  height: auto;
`;

Viewer.propTypes = {
  imageSource: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  extension: PropTypes.string
};

Viewer.defaultProps = {
  extension: 'png'
};

export default Viewer;
