import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { SIGN_TYPE } from '../../../../components/auth/SignIframe';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../components/button/Button';
import ButtonGroup from '../../../../components/button/ButtonGroup';
import DataTable, {
  DataType
} from '../../../../components/datatable/DataTable';
import Notice, { NoticeTypes } from '../../../../components/notice/Notice';
import TableTitle from '../../../../components/table/TableTitle';
import {
  fetchGetAgmBrowseIndividualAction,
  fetchGetAgmLimitsStockholdersAction,
  fetchPostAgmBrowseIndividualAction
} from '../../../../services/api/agm/promiseActions';
import { AgmStateCd } from '../../../../utils/constant/AgmConstant';
import { checkUndefinedNum, formatNumber } from '../../../../utils/StringUtil';
import AgmRegisterStep4SubModal from './AgmRegisterStep4SubModal';

const AgmRegisterStep4Modal = ({
  otherEvent,
  closeParentModal,
  resetValue,
  setReRender,
  agmSeqno,
  baseInfo,
  idpwsign,
  closeModal,
  isRegisterStep
}) => {
  const dispatch = useDispatch();
  const [stockholders, setStockHolders] = useState([]);
  const [stockLimitList, setStockLimitList] = useState([]);
  const [openSubModal, setOpenSubModal] = useState(false);
  const [stockholderRealNameNumber, setStockholderRealNameNumber] = useState();
  const [updateStockholders, setUpdateStockholders] = useState([]);

  useEffect(() => {
    if (!!updateStockholders.length) {
      setStockHolders(updateStockholders);
    }
  }, [updateStockholders]);

  useEffect(() => {
    //보유내역 초기화
    const newArr = [];
    let index = 1;
    for (const rights of resetValue.details) {
      newArr.push({
        no: index++,
        ...resetValue,
        ...rights,
        sumStockAmount:
          rights.commonStockAmount +
          checkUndefinedNum(rights.exercisablePreferredStockAmount) +
          checkUndefinedNum(rights.preferredStockAmount),
        preferredStockAmount: `${formatNumber(
          checkUndefinedNum(rights.preferredStockAmount) +
            checkUndefinedNum(rights.exercisablePreferredStockAmount)
        )} (${formatNumber(
          checkUndefinedNum(rights.exercisablePreferredStockAmount)
        )})`
      });
    }
    setStockHolders(newArr);

    //제한내역 초기화
    dispatch(
      fetchGetAgmLimitsStockholdersAction({
        securitiesCorpSeqno: 1,
        agmSeqno: agmSeqno,
        stockholderSeqno: resetValue.stockholderSeqno
      })
    ).then((res) => {
      if (!res.error) {
        if (
          typeof res.data.votingRightsLimits !== 'undefined' &&
          res.data.votingRightsLimits.length > 0
        ) {
          let index = 0;
          const newArr = [];
          for (const limit of res.data.votingRightsLimits) {
            newArr.push({
              index: `제 ${++index}호`,
              ...limit,
              totalStockAmount:
                limit.commonStockAmount +
                checkUndefinedNum(limit.exercisablePreferredStockAmount)
            });
          }
          setStockLimitList(newArr);
        } else {
          setStockLimitList([]);
        }
      }
    });
  }, [agmSeqno, resetValue.elecvoterName, resetValue, dispatch]);

  useEffect(() => {
    typeof baseInfo !== 'undefined' && console.log(baseInfo);
  }, [baseInfo]);

  const columns = [
    {
      title: 'No',
      key: 'no',
      col: '10%'
    },
    {
      title: '주주명부상 주주명',
      key: 'stockholderName',
      col: '15%'
    },
    {
      title: '투표권자명',
      key: 'elecVoterName',
      col: '15%'
    },
    {
      title: '보유주식수',
      children: [
        {
          title: '보통주',
          key: 'commonStockAmount',
          col: '15%',
          type: DataType.NUMBER
        },
        {
          title: ['우선주', '(의결권 있는 우선주)'],
          key: 'preferredStockAmount',
          col: '15%'
        },
        {
          title: '합계',
          key: 'sumStockAmount',
          col: '15%',
          type: DataType.NUMBER
        }
      ]
    }
  ];

  const columns2 = [
    {
      title: '의안번호',
      key: 'index',
      col: '9%'
    },
    {
      title: '의안명',
      key: 'agendaName',
      col: '20%'
    },
    {
      title: '제한사유',
      key: 'votingRightsLimitContent',
      col: '*'
    },
    {
      title: ['의결권 있는', '주식수'],
      key: 'totalStockAmount',
      col: '12%',
      type: DataType.NUMBER
    },
    {
      title: '의결권 제한 내역',
      children: [
        {
          title: '의결권 없는 주식수',
          key: 'noVotingRightsStockAmmount',
          col: '15%',
          type: DataType.NUMBER
        },
        {
          title: '의결권 제한 주식수',
          key: 'votingRightsLimitStockAmmount',
          col: '14%',
          type: DataType.NUMBER
        }
      ]
    },
    {
      title: ['행사가능', '주식수'],
      key: 'exercisableStockAmmount',
      col: '12%',
      type: DataType.NUMBER
    }
  ];

  const onClickCloseBtn = () => {
    otherEvent();
    closeModal();
  };

  const changeVoter = (e) => {
    setOpenSubModal(true);
  };

  const completeUpdate = () => {
    setOpenSubModal(false);
    // 실명 번호 감추기
    setStockholderRealNameNumber(null);
    typeof setReRender === 'function' && setReRender(true);
  };

  const certCallback = (signData, payload) => {
    const data = {
      agmSeqno,
      data: { signData, ...payload }
    };
    dispatch(fetchGetAgmBrowseIndividualAction(data)).then((res) => {
      if (!res.error) {
        setStockholderRealNameNumber(atob(res.data.stockholderRealNameNumber));
      } else {
        alert(res.error?.user_message);
      }
    });
  };
  const browseIndividualNoCert = () => {
    const data = {
      agmSeqno,
      data: {
        shareholderSeqno: stockholders[0].stockholderSeqno
      }
    };
    dispatch(fetchPostAgmBrowseIndividualAction(data)).then((res) => {
      if (!res.error) {
        setStockholderRealNameNumber(atob(res.data.stockholderRealNameNumber));
      }
    });
  };
  const browseIndividual = () => {
    if (isRegisterStep) {
      browseIndividualNoCert();
    } else {
      idpwsign(SIGN_TYPE.CORP, certCallback, {
        individualInformationTreatScreenCode: '0001', //화면 코드
        shareholderSeqno: stockholders[0].stockholderSeqno
      });
    }
  };
  return (
    <>
      {openSubModal && (
        <AgmRegisterStep4SubModal
          agmSeqno={agmSeqno}
          handleModal={completeUpdate}
          resetValue={resetValue}
          baseInfo={baseInfo}
          idpwsign={idpwsign}
          btnTitle={'실명번호 조회'}
          stockholderRealNameNumber={stockholderRealNameNumber}
          btnHandler={browseIndividual}
          isRegisterStep={isRegisterStep}
          setUpdateStockholders={setUpdateStockholders}
        />
      )}
      <TableTitleBlock>
        <TableTitle small>보유 내역</TableTitle>
      </TableTitleBlock>
      <DataTable columns={columns} dataSource={stockholders} shortHeight />
      <TableTitleBlock mt3>
        <TableTitle small>제한 내역</TableTitle>
      </TableTitleBlock>
      <DataTable columns={columns2} dataSource={stockLimitList} shortHeight />
      <Notice
        type={NoticeTypes.PopUp}
        description="전자투표권자 변경은 이력이 저장되며, 모든 안건에 적용되며, 주주의
        주민/사업자번호 기준으로 변경됩니다.(단, 이미 의결권 행사한 경우에는
        변경할 수 없습니다."
      />
      <ButtonGroup>
        <Button
          type={ButtonTypes.Basic}
          size={ButtonSize.Popup}
          onClick={onClickCloseBtn}
        >
          닫기
        </Button>
        {(!baseInfo || baseInfo?.agmStateCode < AgmStateCd.PLACE) && (
          <Button
            type={ButtonTypes.Primary}
            size={ButtonSize.Popup}
            onClick={changeVoter}
          >
            투표권자 변경
          </Button>
        )}
      </ButtonGroup>
    </>
  );
};

const TableTitleBlock = styled.div`
  // .layer_wrap .layer_body .tbl_ty
  display: block;
  margin-top: 0;
  margin-bottom: 12px;
  position: relative;
  ${(props) =>
    props.mt3 &&
    css`
      margin-top: 3% !important;
    `}
  & > span::after {
    height: 16px;
    margin-top: -5.5px;
  }
`;

export default AgmRegisterStep4Modal;
