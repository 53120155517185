import React, { useState } from 'react';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../components/button/Button';
import DataTable from '../../../../components/datatable/DataTable';
import Input from '../../../../components/input/Input';
import SearchInput from '../../../../components/input/SearchInput';
import Select from '../../../../components/input/Select';
import { TableDataType } from '../../../../components/table/Table';
import { Icons } from '../../../../styles/Icons';

/*
  Temp Data
*/
const columns = [
  {
    title: '주주',
    children: [
      {
        title: '주주명',
        key: 'name'
      },
      {
        title: '주민등록번호/사업자번호',
        key: 'ssno'
      }
    ]
  },
  {
    title: '투표권자/위임장피권유자',
    children: [
      {
        title: '주주명',
        key: 'name'
      },
      {
        title: '주민등록번호/사업자번호',
        key: 'ssno'
      }
    ]
  },
  {
    title: '보유주식수',
    key: 'a1',
    type: TableDataType.NUMBER
  },
  {
    title: '의결권 없는 주식주',
    key: 'a2',
    type: TableDataType.NUMBER
  },
  {
    title: '의결권 제한 주식수',
    key: 'a3',
    type: TableDataType.NUMBER
  },
  {
    title: '행사가능 주식수',
    key: 'a4',
    type: TableDataType.NUMBER
  }
];

const getDataSource = () => {
  let newArr = [];
  for (let i = 0; i < 9; i++) {
    newArr.push({
      index: i, // <CheckBox checked={i % 2 ? true : false} />,
      name: `조민희_${i}`,
      ssno: '880229-1111222',
      a1: `${i * Math.ceil(Math.random() * 1000)}주`,
      a2: `${i * Math.ceil(Math.random() * 1000)}주`,
      a3: `${i * Math.ceil(Math.random() * 1000)}주`,
      a4: `${i * Math.ceil(Math.random() * 1000)}주`,

      go: (
        <Button small endIcon={Icons.search_blue}>
          검색
        </Button>
      ),
      test: <Input />
    });
  }
  return newArr;
};

const DataTableTest = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const dataSource = getDataSource();

  return (
    <>
      <DataTable
        columns={columns}
        dataSource={dataSource}
        // rowSelection={rowSelection}
        onSelected={(selected) => {
          console.log('__');
          console.log(selected);
          setSelectedRowKeys(selected);
        }}
        filterConfig={{
          desc: '지금까지 이 말이 우리가 다시 시작하자는 건 아니야, 그저 너에 남아있던 기억들이 떠올랐을뿐야 정말 하루도 빠짐 없이 사랑한다 말해줬었지 잠들기 전에 또 눈 뜨마자 말해주던 너 생각이 나서 말해보는거야',
          filterItems: (
            <>
              <Select>
                <option>때론</option>
                <option>기다리는</option>
              </Select>
              <SearchInput onSearch={(value) => alert(value)} />
            </>
          )
        }}
        pageConfig={{
          currentPage: 1,
          totalPage: 2,
          onclick: (page) => console.log(page)
        }}
        actionItems={
          <>
            <Button
              type={ButtonTypes.Basic}
              size={ButtonSize.Table}
              endIcon={Icons.search_blue}
            >
              삭제
            </Button>
            <Button
              size={ButtonSize.Table}
              onClick={() => console.log(selectedRowKeys)}
            >
              정보 가져오기
            </Button>
          </>
        }
      />
    </>
  );
};

export default DataTableTest;
