import { GET } from '../../../utils/constant/httpMethod';
import { request } from '../../../utils/requestHelper';

const API_GROUP = 'terms';
const Common = {
  /**
   *  약관
   */
  // 약관 조회
  fetchGetTerms() {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}`
    });
  },
  // 약관 조회 - 개별
  fetchGetTermsSeqno(termsSeqno) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/${termsSeqno}`
    });
  },
  // 약관 조회 - 서비스 가입
  fetchGetTermsSignUp(params = null) {
    console.log('params');
    console.log(params);
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/sign-up`,
      params
    });
  },
  // 약관 조회 - 고유식별처리 동의
  fetchGetTermsUniqueInfoTreatment(termsSqno) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/unique-info-treatment`
    });
  },
  // 약관 조회 - 과거 약관
  fetchGetTermsPrivacyPolicyHistory(prevNo) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/privacy-policy/history/${prevNo}`
    });
  }
};

export default Common;
