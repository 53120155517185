import { all, takeLatest } from '@redux-saga/core/effects';
import APIs from '..';
import createRequestSaga from '../../../utils/createRequestSaga';
import {
  fetchDeleteQnaAction,
  fetchGetAddressAction,
  fetchGetFaqAction,
  fetchGetFaqCategoriesAction,
  fetchGetNoticeAction,
  fetchGetNoticeByNotientSeqnoAction,
  fetchGetNoticeFilesAction,
  fetchGetNoticeNotifiedAction,
  fetchGetQnaAction,
  fetchGetQnaByNotientSeqnoAction,
  fetchGetQnaFilesAction,
  fetchGetQnaQuestionedAction,
  fetchPostFaqAction,
  fetchPostNoticeAction,
  fetchPostQnaAction,
  fetchPostQnaReplyAction,
  fetchPutQnaAction
} from './promiseActions';

// 자주 하는 질문 - 자주묻는 질문 카테고리 조회
const fetchGetFaqCategoriesSaga = createRequestSaga(
  fetchGetFaqCategoriesAction,
  APIs.Common.fetchGetFaqCategories
);

// 자주 하는 질문 - 자주 하는 질문 목록 조회
const fetchGetFaqSaga = createRequestSaga(
  fetchGetFaqAction,
  APIs.Common.fetchGetFaq
);

// 공지사항 목록 조회
const fetchGetNoticeSaga = createRequestSaga(
  fetchGetNoticeAction,
  APIs.Common.fetchGetNotice
);

// 공지사항 조회
const fetchGetNoticeByNotientSeqnoSaga = createRequestSaga(
  fetchGetNoticeNotifiedAction,
  APIs.Common.fetchGetNoticeByNotientSeqno
);

// 공지사항 파일 조회
const fetchGetNoticeFileSaga = createRequestSaga(
  fetchGetNoticeFilesAction,
  APIs.Common.fetchGetNoticeFiles
);

// 공지사항 상단 게시물 목록 조회
const fetchGetNoticeNotifiedSaga = createRequestSaga(
  fetchGetNoticeNotifiedAction,
  APIs.Common.fetchGetNoticeNotified
);

// 문의사항 목록 조회
const fetchGetQnaSaga = createRequestSaga(
  fetchGetQnaAction,
  APIs.Common.fetchGetQna
);

// 나의 문의사항 목록 조회
const fetchGetQnaQuestionedSaga = createRequestSaga(
  fetchGetQnaQuestionedAction,
  APIs.Common.fetchGetQnaQuestioned
);
// 문의사항 파일 조회
const fetchGetQnaFilesSaga = createRequestSaga(
  fetchGetQnaFilesAction,
  APIs.Common.fetchGetQnaFiles
);
// 문의사항 조회
const fetchGetQnaByNotientSeqnoSaga = createRequestSaga(
  fetchGetQnaByNotientSeqnoAction,
  APIs.Common.fetchGetQnaByNotientSeqno
);

// 문의사항 등록
const fetchPostQnaSaga = createRequestSaga(
  fetchPostQnaAction,
  APIs.Common.fetchPostQna
);
// 문의사항 재문의
const fetchPostQnaReplySaga = createRequestSaga(
  fetchPostQnaReplyAction,
  APIs.Common.fetchPostQnaReply
);
// 문의사항 수정
const fetchPutQnaSaga = createRequestSaga(
  fetchPutQnaAction,
  APIs.Common.fetchPutQna
);
// 문의사항 삭제
const fetchDeleteQnaSaga = createRequestSaga(
  fetchDeleteQnaAction,
  APIs.Common.fetchDeleteQna
);

// 증권사 관리자 - 공지사항 - 공지사항 등록
const fetchPostNoticeSaga = createRequestSaga(
  fetchPostNoticeAction,
  APIs.Common.fetchPostNotice
);

// 증권사 관리자 - 자주 하는 질문 - 자주묻는 질문 등록
const fetchPostFaqSaga = createRequestSaga(
  fetchPostFaqAction,
  APIs.Common.fetchPostFaq
);

// 주소 검색
const fetchGetAddressSaga = createRequestSaga(
  fetchGetAddressAction,
  APIs.Common.fetchGetAddress
);

export default function* saga() {
  yield all([
    takeLatest(fetchGetNoticeAction, fetchGetNoticeSaga),
    takeLatest(
      fetchGetNoticeByNotientSeqnoAction,
      fetchGetNoticeByNotientSeqnoSaga
    ),
    takeLatest(fetchGetQnaAction, fetchGetQnaSaga),
    takeLatest(fetchGetNoticeFilesAction, fetchGetNoticeFileSaga),
    takeLatest(fetchGetNoticeNotifiedAction, fetchGetNoticeNotifiedSaga),
    takeLatest(fetchGetFaqCategoriesAction, fetchGetFaqCategoriesSaga),
    takeLatest(fetchGetFaqAction, fetchGetFaqSaga),
    takeLatest(fetchPostNoticeAction, fetchPostNoticeSaga),
    takeLatest(fetchPostFaqAction, fetchPostFaqSaga),
    // QNA
    takeLatest(fetchGetQnaAction, fetchGetQnaSaga),
    takeLatest(fetchGetQnaQuestionedAction, fetchGetQnaQuestionedSaga),
    takeLatest(fetchGetQnaFilesAction, fetchGetQnaFilesSaga),
    takeLatest(fetchGetQnaByNotientSeqnoAction, fetchGetQnaByNotientSeqnoSaga),
    takeLatest(fetchPostQnaAction, fetchPostQnaSaga),
    takeLatest(fetchPostQnaReplyAction, fetchPostQnaReplySaga),
    takeLatest(fetchPutQnaAction, fetchPutQnaSaga),
    takeLatest(fetchDeleteQnaAction, fetchDeleteQnaSaga),

    takeLatest(fetchGetAddressAction, fetchGetAddressSaga)
  ]);
}
