import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import PageHeader from '../../../components/page/PageHeader';
import RegisterSteps from '../../../components/step/RegisterSteps';
import { ModalContext } from '../../../context/ModalContext';
import { fetchPostUsersIndividualAction } from '../../../services/api/users/promiseActions';
import { SignupStep } from '../../../utils/constant/AgmConstant';
import { getYMDSplit } from '../../../utils/DateUtil';
import SignupStep1 from './main/stockholder/SignupStep1';
import SignupStep2 from './main/stockholder/SignupStep2';
import SignupStep3 from './main/stockholder/SignupStep3';
import SignupStep4 from './main/stockholder/SignupStep4';

const Individual = () => {
  const [step, setStep] = useState(SignupStep.AuthenticationMethod);
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();
  const { openModal } = useContext(ModalContext);
  const [role, setRole] = useState(null);

  const onSignup = (data, signData) => {
    const [y, m, d] = getYMDSplit(data.individualUser.birthDt); // 생년월일
    const signupData = {
      signData,
      userName: data.userName,
      utilClauseAgreeDateTime: data.utilClauseAgreeDateTime,
      individualInformationTreatAgreeDateTime:
        data.individualInformationTreatAgreeDateTime,
      individualUser: {
        cell: `${data.frontCell}${data.middleCell}${data.endCell}`,
        birthDt: `${y}-${m}-${d}`,
        authenticationMethod: data.authenticationMethod
      }
    };
    dispatch(fetchPostUsersIndividualAction(signupData)).then((res) => {
      if (!res.error) {
        setStep(SignupStep.Confirm);
      } else {
        openModal('확인', res.error.user_message);
      }
    });
  };

  const renderStepContnet = () => {
    switch (step) {
      case SignupStep.AuthenticationMethod:
        return (
          <SignupStep1
            register={register}
            setValue={setValue}
            setStep={setStep}
            setRole={setRole}
          ></SignupStep1>
        );
      case SignupStep.Terms:
        return (
          <SignupStep2
            register={register}
            setStep={setStep}
            setValue={setValue}
            role={role}
          ></SignupStep2>
        );
      case SignupStep.Info:
        return (
          <SignupStep3
            register={register}
            handleSubmit={handleSubmit}
            callback={onSignup}
            authMethod={getValues('authenticationMethod')}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            errors={errors}
            setStep={() => setStep(SignupStep.Confirm)}
            openModal={openModal}
          ></SignupStep3>
        );
      case SignupStep.Confirm:
        return <SignupStep4 register={register}></SignupStep4>;
      default:
        break;
    }
  };

  return (
    <>
      <PageHeader title="서비스 가입(개인)" />
      <RegisterSteps
        currentStep={step}
        stepInfo={[
          {
            stepSeq: SignupStep.AuthenticationMethod,
            title: '1'
          },
          {
            stepSeq: SignupStep.Terms,
            title: '2'
          },
          {
            stepSeq: SignupStep.Info,
            title: '3'
          },
          {
            stepSeq: SignupStep.Confirm,
            title: '4'
          }
        ]}
      />
      {renderStepContnet()}
    </>
  );
};

export default Individual;
