import PropTypes from 'prop-types'; // ES6
import React from 'react';
import styled, { css } from 'styled-components';
import CommonStyles from '../../styles/CommonStyles';
import ListTag from './ListTag';
const ListHeaderBlock = styled.div`
  min-height: 32px;
  font-size: 27px;
  font-weight: bold;
  color: ${CommonStyles.THEME_COLOR};
  ${(props) =>
    props.popup &&
    css`
      min-height: 38px;
      padding-bottom: 12px;
    `}
  ${(props) =>
    props.small &&
    css`
      min-height: 10px;
    `}
`;

const ListHeaderDescBlock = styled.span`
  display: block;
  margin-top: 5px;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.45;
  color: #8c939c;
`;

const ListHeader = ({
  title,
  isRequired,
  description,
  popup = false,
  small = false
}) => {
  return (
    <>
      <ListHeaderBlock small={small} popup={popup}>
        {title}
        {isRequired && <ListTag />}
      </ListHeaderBlock>
      {description && <ListHeaderDescBlock>{description}</ListHeaderDescBlock>}
    </>
  );
};

ListHeader.propTypes = {
  title: PropTypes.string,
  isRequired: PropTypes.bool
};

ListHeader.defaultProps = {
  title: '',
  isRequired: false
};

export default ListHeader;
