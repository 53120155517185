import { all, takeLatest } from '@redux-saga/core/effects';
import APIs from '..';
import createRequestSaga from '../../../utils/createRequestSaga';
import {
  fetchGetAuthPassCertAction,
  fetchGetAuthTestTokenAction,
  fetchPostAuthLoginAdminAction,
  fetchPostAuthLoginCertAction,
  fetchPostAuthLoginPassAction,
  fetchPostAuthLoginPassCallbackAction,
  fetchPostAuthLoginPasswordAction,
  fetchPostAuthRefreshAction,
  fetchPutAuthCertificateAction,
  fetchPutAuthCertificateBizAction,
  fetchPutAuthCertificateIndividualAction
} from './promiseActions';

/**
 * 토큰발급 및 로그인
 */

// PASS 인증 요청
const fetchGetAuthPassCertSaga = createRequestSaga(
  fetchGetAuthPassCertAction,
  APIs.Auth.fetchGetAuthPassCert
);
// 토큰발급 (테스트)
const fetchGetAuthTestTokenSaga = createRequestSaga(
  fetchGetAuthTestTokenAction,
  APIs.Auth.fetchGetAuthTestToken
);

// 토큰 리프레시
const fetchPostAuthRefreshSaga = createRequestSaga(
  fetchPostAuthRefreshAction,
  APIs.Auth.fetchPostAuthRefresh
);

// 공동인증서 재등록
const fetchPutAuthCertificateSaga = createRequestSaga(
  fetchPutAuthCertificateAction,
  APIs.Auth.fetchPutAuthCertificate
);

// 로그인-공동인증서
const fetchPostAuthLoginCertSaga = createRequestSaga(
  fetchPostAuthLoginCertAction,
  APIs.Auth.fetchPostAuthLoginCert
);

// 로그인-PASS
const fetchPostAuthLoginPassSaga = createRequestSaga(
  fetchPostAuthLoginPassAction,
  APIs.Auth.fetchPostAuthLoginPass
);

// 로그인-PASS
const fetchPostAuthLoginPassCallbackSaga = createRequestSaga(
  fetchPostAuthLoginPassCallbackAction,
  APIs.Auth.fetchPostAuthLoginPassCallback
);

/*
  공동인증서 재등록
  */
// 공동인증서 재등록 - 개인 사용자
const fetchPutAuthCertificateIndividualSaga = createRequestSaga(
  fetchPutAuthCertificateIndividualAction,
  APIs.Auth.fetchPutAuthCertificateIndividual
);

// 공동인증서 재등록 - 법인 사용자
const fetchPutAuthCertificateBizSaga = createRequestSaga(
  fetchPutAuthCertificateBizAction,
  APIs.Auth.fetchPutAuthCertificateBiz
);

// 로그인 - 발행회사 조회용
const fetchPostAuthLoginPasswordSaga = createRequestSaga(
  fetchPostAuthLoginPasswordAction,
  APIs.Auth.fetchPostAuthLoginPassword
);

// 로그인 - 증권사
const fetchPostAuthLoginAdminSaga = createRequestSaga(
  fetchPostAuthLoginAdminAction,
  APIs.Auth.fetchPostAuthLoginAdmin
);

// 액션 할 차례
export default function* saga() {
  yield all([
    takeLatest(fetchGetAuthTestTokenAction, fetchGetAuthTestTokenSaga),
    takeLatest(fetchPostAuthRefreshAction, fetchPostAuthRefreshSaga),
    takeLatest(fetchPutAuthCertificateAction, fetchPutAuthCertificateSaga),
    takeLatest(fetchPostAuthLoginCertAction, fetchPostAuthLoginCertSaga),
    takeLatest(fetchPostAuthLoginPassAction, fetchPostAuthLoginPassSaga),
    takeLatest(
      fetchPostAuthLoginPassCallbackAction,
      fetchPostAuthLoginPassCallbackSaga
    ),
    takeLatest(
      fetchPutAuthCertificateIndividualAction,
      fetchPutAuthCertificateIndividualSaga
    ),
    takeLatest(
      fetchPutAuthCertificateBizAction,
      fetchPutAuthCertificateBizSaga
    ),
    takeLatest(fetchGetAuthPassCertAction, fetchGetAuthPassCertSaga),
    takeLatest(
      fetchPostAuthLoginPasswordAction,
      fetchPostAuthLoginPasswordSaga
    ),
    takeLatest(fetchPostAuthLoginAdminAction, fetchPostAuthLoginAdminSaga)
  ]);
}
