import styled from 'styled-components';

export const ListType10Block = styled.div`
  // .list_ty10
  padding-top: 16px;
  & > ul {
    display: flex;
    & > li {
      position: relative;
      min-width: 140px;
      padding-bottom: 32px;
    }
    & > li:first-of-type {
      width: 260px;
      flex: none;
    }
    & > li:last-of-type {
      flex: 1;
    }
  }
`;
