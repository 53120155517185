import PropTypes from 'prop-types'; // ES6
import React from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import spriteCommonPng from '../../../../../styles/images/common/sprite/sp_common.png';
import spriteCommonMobilePng from '../../../../../styles/images/common/sprite/sp_common_mobile.png';
import DefaultButtonStyle, {
  VOTE_BUTTON_SIZE,
  VOTE_BUTTON_TEXT_ALIGN,
  VOTE_RESULT_BUTTON_TYPE
} from './DefaultButtonStyle';

const getVoteResultButtonTypeCss = (type, size, isChild = false) => {
  switch (type) {
    case VOTE_RESULT_BUTTON_TYPE.Approved:
      // .boxlist_ty .unit_result .rejected::after
      return css`
        &::after {
          content: '';
          position: absolute;
          top: 6px;
          left: 6px;
          width: 24px;
          height: 24px;
          background: url(${spriteCommonPng}) no-repeat;
          background-size: 400px auto;
          background-position: -1px -165px;
          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 24px;
            height: 24px;
            background: url(${spriteCommonMobilePng}) no-repeat;
            background-size: 230px auto;
            background-position: -129px -140px;
          }
        }
      `;

    case VOTE_RESULT_BUTTON_TYPE.Rejected:
      return css`
        &::after {
          // .boxlist_ty .unit_result .approved::after
          content: '';
          position: absolute;
          top: 6px;
          left: 6px;
          width: 24px;
          height: 24px;
          background: url(${spriteCommonPng}) no-repeat;
          background-size: 400px auto;
          background-position: -1px -141px;
          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 24px;
            height: 24px;
            background: url(${spriteCommonMobilePng}) no-repeat;
            background-size: 230px auto;
            background-position: -129px -116px;
          }
        }
      `;

    case VOTE_RESULT_BUTTON_TYPE.Invalid:
      // .boxlist_ty .unit_result .invalid::after
      return css`
        ${(props) =>
          !props.isChild &&
          css`
            position: absolute;
            top: 16px;
            right: 16px;
            margin: 0;
            @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
              position: absolute;
              top: 12px;
              right: 10px;
              margin: 0;
            }
          `}

        &::after {
          content: '';
          position: absolute;
          top: 6px;
          left: 6px;
          width: 24px;
          height: 24px;
          background: url(${spriteCommonPng}) no-repeat;
          background-size: 400px auto;
          background-position: -1px -189px;

          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 24px;
            height: 24px;
            background: url(${spriteCommonMobilePng}) no-repeat;
            background-size: 230px auto;
            background-position: -129px -164px;
          }
        }
      `;
    default:
      return null;
  }
};

const VoteResultButtonBlock = styled(DefaultButtonStyle)`
  ${(props) => getVoteResultButtonTypeCss(props.type, props.size)}
  ${(props) => props.textAlign === VOTE_BUTTON_TEXT_ALIGN.Left && ''}
  ${(props) =>
    !props.isShow &&
    css`
      visibility: hidden;
    `}
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    // 자식의안 없으면 위에 결과창이 붙게 바꿈.
    ${(props) =>
      props.nochild &&
      css`
        top: 13px;
      `}
  }
`;

const VoteResultButton = (props) => {
  const { children, isAbsten } = props;
  return (
    <VoteResultButtonBlock {...props} isShow={!isAbsten}>
      {children}
    </VoteResultButtonBlock>
  );
};

VoteResultButton.propTypes = {
  type: PropTypes.oneOf(Object.values(VOTE_RESULT_BUTTON_TYPE)),
  size: PropTypes.oneOf(Object.values(VOTE_BUTTON_SIZE)),
  active: PropTypes.bool
};

VoteResultButton.defaultProps = {
  active: false
};

export default VoteResultButton;
