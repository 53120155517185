import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../components/button/CommonStyle';
import FileDownButton from '../../../../components/button/FileDownButton';
import CheckBox from '../../../../components/input/CheckBox';
import { useFileDownload } from '../../../../hooks/useFileDownload';
import { fetchGetAgmExcercisedConfirmPdfAction } from '../../../../services/api/agm/promiseActions';
import selectMobilePng from '../../../../styles/images/common/select_bg_mobile.png';
import { AgmStateCd } from '../../../../utils/constant/AgmConstant';
import CertificateDataTableTemp from '../../certification/main/components/CertificateDataTableTemp';

const BoxInfo = ({
  baseInfo,
  solicitor,
  voters,
  selectedVoter,
  setSelectedVoter
}) => {
  // const [diverseFlag, setDiverseFlag] = useState();
  const diverseType = selectedVoter?.excerciseMethod;
  console.log(baseInfo);
  // const onClickFlagHandler = (e) => {
  //   setDiverseFlag(e.target.value);
  // };

  const { callDownloadFetchAPIOnlyAgmseqno } = useFileDownload();

  const onClickFileDownload = () => {
    callDownloadFetchAPIOnlyAgmseqno(
      baseInfo.agmSeqno,
      fetchGetAgmExcercisedConfirmPdfAction
    );
  };

  return (
    <ListWrapBlock>
      <BoxInfoBlock className="particulars">
        <FileDownULBlock>
          {baseInfo && (
            <>
              {baseInfo.voters?.length !== 1 && (
                <CertificateDataTableTemp
                  voters={baseInfo.voters}
                  selectedVoter={selectedVoter}
                  setSelectedVoter={setSelectedVoter}
                />
              )}
              {baseInfo.agmStateCode === AgmStateCd.END && (
                <FileDownButton onClick={onClickFileDownload}>
                  행사확인서 다운로드
                </FileDownButton>
              )}
            </>
          )}
        </FileDownULBlock>

        <ul>
          <li>
            <span>불통일여부</span>
            <CheckBox
              title="불통일 신청"
              checked={
                selectedVoter?.votingRightsDiversityExercise ? true : false
              }
              onlyUseProps={true}
            />
          </li>
          <li>
            <span>행사구분</span>
            <CheckBox
              title="의결권행사"
              checked={diverseType === ExerciseMethod.VOTE}
              onlyUseProps={true}
            />
            <CheckBox
              title="전자위임"
              checked={diverseType === ExerciseMethod.PROXY}
              onlyUseProps={true}
            />
          </li>
          {!!solicitor && (
            <li>
              <span>권유자</span>
              <SelectTypeBlock disabled>
                <option>{solicitor?.solicitorName}</option>
              </SelectTypeBlock>
              <span>대리인</span>
              <SelectTypeBlock disable={solicitor?.voteProxyMap.length === 1}>
                {/* <option>김대리</option> */}
                {solicitor?.voteProxyMap.map((proxy, idx) => {
                  if (proxy !== '')
                    return (
                      <option key={`SelectTypeBlock_option_${idx}`}>
                        {proxy}
                      </option>
                    );
                  else return <></>;
                })}
              </SelectTypeBlock>
            </li>
          )}
        </ul>
      </BoxInfoBlock>
    </ListWrapBlock>
  );
};

export const ExerciseMethod = {
  VOTE: '1',
  PROXY: '2',
  WRITTEN: '3',
  SPOT: '4'
};

const ListWrapBlock = styled.div`
  // .list_wrap
  max-width: 1020px;
  margin: 0 auto;
  padding: 0;
`;

const BoxInfoBlock = styled.div`
  // .boxinfo
  & > ul {
    position: relative;
    border-bottom: solid 1px #d8e1eb;
    padding-bottom: 20px;
    & > li {
      width: 100%;
      padding: 20px 0 0;
      font-size: 16px;
      letter-spacing: -0.5px;
      color: #080808;
      text-align: left;
      display: flex;
      align-content: center;
      align-items: center;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        width: 100%;
        padding: 16px 0 0;
        letter-spacing: -0.5px;
        color: #080808;
        text-align: left;
        display: flex;
        align-content: center;
        align-items: center;
        & > span:not(:first-of-type) {
          width: 48px;
          margin-left: 1.2%;
        }
      }
      & > span {
        display: inline-block;
        width: 140px;
        height: 18px;
        padding: 7px 0 7px 40px;
        color: #575f76;
        margin-right: 20px;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          display: inline-block;
          width: 70px;
          height: 18px;
          padding: 5px 0;
          font-size: 12px;
          color: #575f76;
        }
      }
      & > select {
        flex: 1;
      }
    }
  }
  & > ul:first-of-type {
    padding-bottom: 30px;
    & > li {
      padding: 0;
      display: block;
      position: relative;
      & > p {
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
  &.particulars > ul {
    // .boxinfo .particulars
    border-bottom: 0;
    padding-bottom: 20px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      border-bottom: solid 1px #d8e1eb;
      padding-bottom: 12px;
    }
  }
`;

const SelectTypeBlock = styled.select`
  // .select_ty
  height: 46px;
  padding: 10px 42px 10px 20px;
  border-radius: 4px;
  border: solid 1px #ccd5e0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  background: url(../img/common/select_bg.png) no-repeat right center;
  background-color: #fff;
  background-size: 36px auto;
  line-height: 1.33;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    height: 34px;
    padding: 7px 22px 6px 12px;
    border-radius: 4px;
    border: solid 1px #a5b7ce;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    background: url(${selectMobilePng}) no-repeat right -3px center;
    background-color: #fff;
    background-size: 22px auto;
  }
  &:disabled {
    background: none;
    background-color: #fbfbfc;
    border-color: #d8e1eb;
    color: #080808;
    opacity: 1;
  }
`;

const FileDownULBlock = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 5px;
`;

export default BoxInfo;
