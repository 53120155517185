import PropsType from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';
import { ErrorTypes } from './Input';
import InputHeader from './InputHeader';

export const InputWrapperBlock = styled.div`
  // .input_wrap
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;

  ${(props) =>
    props.isMobile &&
    props.isMobileVertical &&
    css`
      flex-direction: column;
      align-items: start;
      gap: 5px;
    `}

  ${(props) =>
    !props.noFlex &&
    css`
      & > div {
        flex: 1;
      }
    `}

  ${(props) =>
    props.mt20 &&
    css`
      margin-top: 20px !important;
    `}
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    & > &:last-of-type,
    div:last-of-type {
      ${(props) =>
        props.isMobile &&
        css`
          flex-basis: 100%;
        `}
    }
  }
`;
const InputErrorBlock = styled.span`
  position: absolute;
  display: block;
  margin-top: 8px;
  padding-left: 20px;
  font-size: 14px;
  color: #8c939c;
  line-height: 1.33;
  ${(props) =>
    props.type === ErrorTypes.SUCCESS
      ? css`
          color: blue;
        `
      : props.type === ErrorTypes.ERROR
      ? css`
          color: #ec2a1e;
        `
      : css``}
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: static;
    flex-basis: 100%;
    padding-left: 0px;
    font-size: 12px;
    margin-top: 5px;
  }
`;

const InputBox = ({
  title,
  isRequired,
  isOptional,
  description,
  error,
  isEmpty,
  children,
  noFlex,
  isHelped,
  errorType,
  isVertical = false,
  isMobile,
  isMobileVertical
}) => {
  if (isEmpty) {
    return <InputWrapperBlock />;
  } else {
    return (
      <>
        {title && (
          <InputHeader
            title={title}
            isRequired={isRequired}
            isOptional={isOptional}
            description={description}
            isHelped={isHelped}
            isMobile={isMobile}
          />
        )}
        {isVertical ? (
          <>
            {children.map((item, index) => {
              return (
                <InputWrapperBlock
                  key={`InputWrapperBlock_${index}`}
                  mt20={index !== 0}
                >
                  {item}
                </InputWrapperBlock>
              );
            })}
          </>
        ) : (
          <InputWrapperBlock
            noFlex={noFlex}
            isMobile={isMobile}
            isMobileVertical={isMobileVertical}
          >
            {children}
            {isMobile && error && (
              <InputErrorBlock type={errorType}>
                {Array.isArray(error)
                  ? error.map((e, index) => {
                      if (error.length - 1 !== index) {
                        return (
                          <React.Fragment key={`inputError_${index}`}>
                            {e}
                            <br />
                          </React.Fragment>
                        );
                      } else {
                        return e;
                      }
                    })
                  : error}
              </InputErrorBlock>
            )}
          </InputWrapperBlock>
        )}
        {!isMobile && (
          <InputErrorBlock type={errorType}>
            {Array.isArray(error)
              ? error.map((e, index) => {
                  if (error.length - 1 !== index) {
                    return (
                      <React.Fragment key={`inputError_${index}`}>
                        {e}
                        <br />
                      </React.Fragment>
                    );
                  } else {
                    return e;
                  }
                })
              : error}
          </InputErrorBlock>
        )}
      </>
    );
  }
};

InputBox.propTypes = {
  title: PropsType.string,
  isRequired: PropsType.bool,
  isOptional: PropsType.bool,
  description: PropsType.oneOfType([PropsType.string, PropsType.array]),
  error: PropsType.oneOfType([PropsType.array, PropsType.string]),
  children: PropsType.oneOfType([PropsType.array, PropsType.object]),
  noFlex: PropsType.bool,
  isHelped: PropsType.bool,
  errorType: PropsType.string
};

InputBox.defaultProps = {
  title: '',
  isRequired: false,
  isOptional: false,
  description: '',
  error: '',
  children: null,
  noFlex: false,
  isHelped: false
};

export default InputBox;
