import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button, { ButtonTypes } from '../../../../components/button/Button';
import FileButton from '../../../../components/button/FileButton';
import FileDownButton from '../../../../components/button/FileDownButton';
import Input from '../../../../components/input/Input';
import InputBox from '../../../../components/input/InputBox';
import Select from '../../../../components/input/Select';
import ListItem from '../../../../components/layout/ListItem';
import { ModalContext } from '../../../../context/ModalContext';
import { useFileDownload } from '../../../../hooks/useFileDownload';
import {
  fetchGetFilesTemporaryAction,
  fetchPostFilesTemporaryAction
} from '../../../../services/api/files/promiseActions';
import { fetchGetIssueIncorporatedFilesAction } from '../../../../services/api/issueIncorporated/promiseActions';
import {
  AgentOrganizationType,
  Role,
  StockMarketType
} from '../../../../utils/constant/AgmConstant';
import SearchAddressModal from '../../../common/signup/SearchAddressModal';

export const FileType = {
  CorpRegitFile: '1',
  BzRegitFile: '2'
};

const CorporationInfo = (props) => {
  const { register, setFileUploadedData, setValue, dataObj, watch, errors } =
    props;
  const dispatch = useDispatch();
  const [isCorpRegitFile, setCorpRegitFile] = useState(false);
  const [corpFile, setCorpFile] = useState();
  const [isBzRegitFile, setBzRegitFile] = useState(false);
  const [bzFile, setBzFile] = useState();
  const { openModal, closeModal } = useContext(ModalContext);
  const role = useSelector((state) => state?.common?.user?.role);
  console.log(role);
  useEffect(() => {
    if (role === Role.VIEW_ISSINC_ADMIN.code) {
      setTimeout(() => {
        document
          .getElementsByTagName('input')
          .forEach((item) => (item.disabled = true));
        document
          .getElementsByTagName('select')
          .forEach((item) => (item.disabled = true));
      }, 100);
    }
  }, []);

  useEffect(() => {
    const corp = dataObj?.files.find(
      (file) => file.type === FileType.CorpRegitFile
    );
    const bz = dataObj?.files.find(
      (file) => file.type === FileType.BzRegitFile
    );
    setCorpFile({ ...corp, isNew: false });
    setBzFile({ ...bz, isNew: false });
    setCorpRegitFile(corp ? true : false);
    setBzRegitFile(bz ? true : false);
  }, [dataObj]);

  const clearFile = (fileType) => {
    if (fileType === FileType.CorpRegitFile) {
      setCorpFile(null);
      setCorpRegitFile(false);
      setFileUploadedData((prev) =>
        prev.filter((file) => file.type !== FileType.CorpRegitFile)
      );
    } else if (fileType === FileType.BzRegitFile) {
      setBzFile(null);
      setBzRegitFile(false);
      setFileUploadedData((prev) =>
        prev.filter((file) => file.type !== FileType.BzRegitFile)
      );
    }
  };

  const handleFile = (fileType, file) => {
    const fd = new FormData();
    fd.append('file', file);
    dispatch(fetchPostFilesTemporaryAction(fd))
      .then((res) => {
        const fileData = {
          ...res.data,
          type: fileType
        };
        setFileUploadedData((prev) => [...prev, fileData]);
        return fileData;
      })
      .then((fileData) => {
        switch (fileData.type) {
          case FileType.CorpRegitFile:
            setCorpFile({ ...fileData, isNew: true });
            setCorpRegitFile(true);
            break;
          case FileType.BzRegitFile:
            setBzFile({ ...fileData, isNew: true });
            setBzRegitFile(true);
            break;
          default:
            break;
        }
      })
      .catch((e) => console.log(e));
  };

  const {
    callDownloadFetchAPITemporary,
    callDownloadFetchIssueIncorporatedFiles
  } = useFileDownload();

  const onFetchFiles = (type) => {
    if (type === FileType.CorpRegitFile) {
      if (corpFile.isNew) {
        callDownloadFetchAPITemporary(corpFile, fetchGetFilesTemporaryAction);
      } else {
        callDownloadFetchIssueIncorporatedFiles(
          FileType.CorpRegitFile,
          fetchGetIssueIncorporatedFilesAction
        );
      }
    } else if (type === FileType.BzRegitFile) {
      if (bzFile.isNew) {
        callDownloadFetchAPITemporary(bzFile, fetchGetFilesTemporaryAction);
      } else {
        callDownloadFetchIssueIncorporatedFiles(
          FileType.BzRegitFile,
          fetchGetIssueIncorporatedFilesAction
        );
      }
    }
  };

  const onSetBaseAddr = (addr) => {
    setValue('baseAddress', addr);
    closeModal();
  };
  const openSearchAddressModal = () => {
    openModal(
      '주소 검색',
      <SearchAddressModal onSetBaseAddr={onSetBaseAddr} />
    );
  };

  const watchMarketType = watch('stockMarketType');

  const requiredFlagMemo = useMemo(() => {
    if (watchMarketType === StockMarketType.UNLISTED.code) {
      return false;
    } else {
      return true;
    }
  }, [watchMarketType]);

  const checkIsinCode = (value) => {
    if (value.length === 6) {
      return true;
    } else {
      if (requiredFlagMemo) return false;
      else return true;
    }
  };

  return (
    <>
      <ListItem>
        <InputBox title="발행회사명 (한글)" isRequired>
          <Input
            register={register}
            name="name"
            placeholder="회사명을 입력해주세요(한글)"
          />
        </InputBox>
        <InputBox title="발행회사명 (영문)" isRequired>
          <Input
            register={register}
            name="englishName"
            placeholder="회사명을 입력해주세요(영문)"
          />
        </InputBox>
      </ListItem>
      <ListItem>
        <InputBox
          title="법인 등록번호"
          isRequired
          error="숫자만 입력 가능합니다"
        >
          <Input
            register={register}
            name="corpRegisterNumber"
            placeholder="법인명/법인등록번호"
          />
          {(!role || role === Role.ISSINC_ADMIN.code) && (
            <FileButton
              fileType={FileType.CorpRegitFile}
              handleFile={handleFile}
              isUploaded={isCorpRegitFile}
              setIsUploaded={setCorpRegitFile}
              clearFile={() => clearFile(FileType.CorpRegitFile)}
            >
              법인등기등본
            </FileButton>
          )}
          {isCorpRegitFile && (
            <FileDownButton
              onClick={() => onFetchFiles(FileType.CorpRegitFile)}
            />
          )}
        </InputBox>
        <InputBox title="사업자 등록번호" isRequired>
          <Input
            register={register}
            name="bizRegistrationNumber"
            placeholder="사업자등록번호"
          />
          {(!role || role === Role.ISSINC_ADMIN.code) && (
            <FileButton
              fileType={FileType.BzRegitFile}
              handleFile={handleFile}
              isUploaded={isBzRegitFile}
              setIsUploaded={setBzRegitFile}
              clearFile={() => clearFile(FileType.BzRegitFile)}
            >
              사업자 등록증
            </FileButton>
          )}
          {isBzRegitFile && (
            <FileDownButton
              onClick={() => onFetchFiles(FileType.BzRegitFile)}
            />
          )}
        </InputBox>
      </ListItem>
      <ListItem>
        <InputBox
          title="주식시장 구분"
          isRequired
          error={errors.stockMarketType?.message}
        >
          <Select
            register={register}
            name="stockMarketType"
            registerOption={{
              validate: (value) =>
                parseInt(value) > 0 || '주식시장 구분을 선택해주세요'
            }}
          >
            {/* <option value="0" key={`stockMarket_00`}>
              선택해주세요
            </option> */}
            {Object.keys(StockMarketType).map((type, index) => {
              return (
                <option
                  value={StockMarketType[type].code}
                  key={`stockMarket_${index}`}
                >
                  {StockMarketType[type].name}
                </option>
              );
            })}
          </Select>
        </InputBox>
        <InputBox
          title="주식종목코드 (상장회사)"
          isRequired={requiredFlagMemo}
          error={errors.standardIsin?.message}
        >
          <Input
            register={register}
            name="standardIsin"
            placeholder="주식종목코드"
            registerOption={{
              validate: (value) =>
                checkIsinCode(value) || '주식종목코드를 확인하세요'
            }}
          />
        </InputBox>
        <InputBox />
      </ListItem>
      <ListItem>
        <InputBox title="대표자" isRequired>
          <Input
            register={register}
            name="representativeName"
            placeholder="대표자 이름"
          />
        </InputBox>
        <InputBox title="결산월" isRequired>
          <Select register={register} name="settleAccountingMonth">
            {Array(12)
              .fill()
              .map((_, index) => {
                return (
                  <option value={index + 1} key={`monthKey_${index + 1}`}>
                    {index + 1}
                  </option>
                );
              })}
          </Select>
        </InputBox>
        <InputBox title="대행기관" isRequired>
          <Select register={register} name="agentOrganizationType">
            {Object.keys(AgentOrganizationType).map((type, index) => {
              return (
                <option
                  value={AgentOrganizationType[type].code}
                  key={`AgentOrganizationType_${index}`}
                >
                  {AgentOrganizationType[type].name}
                </option>
              );
            })}
          </Select>
        </InputBox>
      </ListItem>
      <ListItem>
        <InputBox title="주소" name="address" isRequired isVertical>
          <>
            <Input
              register={register}
              name="baseAddress"
              placeholder="기본 주소를 검색해주세요"
              //onClick={openSearchAddressModal}
            />
            {role === Role.ISSINC_ADMIN.code && (
              <Button type={ButtonTypes.Basic} onClick={openSearchAddressModal}>
                검색
              </Button>
            )}
          </>
          <Input
            register={register}
            name="detailAddress"
            placeholder="상세 주소를 입력해주세요"
          />
        </InputBox>
      </ListItem>
    </>
  );
};

export default CorporationInfo;
