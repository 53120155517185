export const DateFormatName = {
  // date time
  FULL_DATE_TIME: 'FULL_DATE_TIME',
  SHORT_DATE_TIME: 'SHORT_DATE_TIME',
  // date
  SHORT_DATE: 'SHORT_DATE',
  SHORT_DATE_2: 'SHORT_DATE_2',
  SHORT_DATE_3: 'SHORT_DATE_3',
  SHORT_DATE_HYPEN: 'SHORT_DATE_HYPEN',
  SHORT_DATE_WEEK_NAME: 'SHORT_DATE_WEEK_NAME',
  SHORT_DATE_OTHER_1: 'SHORT_DATE_OTHER_1',
  SHORT_DATE_OTHER_2: 'SHORT_DATE_OTHER_2',
  // time
  SHORT_TIME: 'SHORT_TIME',
  LONG_TIME: 'LONG_TIME',
  LONG_DATE_TIME: 'LONG_DATE_TIME'
};
const DateTimeFormat = {
  // FULL_DATE_TIME: 'yyyy.MM.dd hh:mm a/p',
  FULL_DATE_TIME: 'yyyy.MM.dd a/p hh:mm',
  LONG_DATE_TIME: 'yyyy.MM.dd HH:mm',
  SHORT_DATE_TIME: 'yy.MM.dd HH:mm',
  SHORT_DATE: 'yy.MM.dd',
  SHORT_DATE_2: 'yyyy.MM.dd',
  SHORT_DATE_3: 'yyyy.MM.dd(E)',
  SHORT_DATE_HYPEN: 'yyyy-MM-dd',
  SHORT_DATE_WEEK_NAME: 'yyyy-MM-dd(E)',
  SHORT_DATE_OTHER_1: 'yyyy년 MM월 dd일(E)',
  SHORT_DATE_OTHER_2: 'yyyy.MM.dd(E) HH:mm',
  SHORT_TIME: 'HH:mm',
  LONG_TIME: 'a/p hh:mm'
};
export const getDateFormat = (key) => {
  return DateTimeFormat[key];
};
