import React from 'react';
import styled from 'styled-components';
import selectPng from '../../styles/images/common/select_bg.png';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';

const PhoneWrapperBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
`;

const SelectBlock = styled.select`
  flex: 1;
  width: 100%;
  margin-right: 4px;
  height: 42px;
  padding: 10px 46px 10px 20px;
  border-radius: 4px;
  border: solid 1px #ccd5e0;
  box-sizing: border-box;
  background: url(${selectPng}) no-repeat right center;
  background-color: #fff;
  background-size: 36px auto;
  line-height: 1.33;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    height: 34px;
    padding: 7px 22px 6px 12px;
    background: url(${selectPng}) no-repeat right -3px center;
    background-size: 22px auto;

    flex: none;
    width: 80px !important;
  }
`;

const PhoneInputBlock = styled.input`
  flex: 1;
  width: 100%;
  margin-right: 4px;
  height: 42px;
  padding: 0 20px;
  border-radius: 4px;
  background-color: #fff;
  border: solid 1px #ccd5e0;
  box-sizing: border-box;
  line-height: 1.54;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    flex: none;
    height: 34px;
    width: 80px !important;
    padding: 0;
    text-align: center;
  }
`;

const MinusBlock = styled.em`
  margin: 0 10px;
`;

const PhoneInput = (props) => {
  const { register } = props;
  return (
    <PhoneWrapperBlock>
      <SelectBlock {...register('frontCell')}>
        <option>010</option>
        <option>011</option>
        <option>016</option>
        <option>019</option>
      </SelectBlock>
      <MinusBlock>-</MinusBlock>
      <PhoneInputBlock
        {...register('middleCell', {
          required: { value: true, message: '핸드폰번호를 확인해주세요' },
          pattern: { value: /^[0-9]+$/, message: '숫자만 입력 가능합니다.' },
          validate: {
            length: (value) =>
              value.length === 4 || '핸드폰번호를 확인해주세요.'
          }
        })}
        placeholder="0000"
        maxLength="4"
      />
      <MinusBlock>-</MinusBlock>
      <PhoneInputBlock
        {...register('endCell', {
          required: { value: true, message: '핸드폰번호를 확인해주세요' },
          pattern: { value: /^[0-9]+$/g, message: '숫자만 입력 가능합니다.' },
          validate: {
            length: (value) =>
              value.length === 4 || '핸드폰번호를 확인해주세요.'
          }
        })}
        placeholder="0000"
        maxLength="4"
      />
    </PhoneWrapperBlock>
  );
};

export default PhoneInput;
