import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import { fetchGetFaqAction } from '../../../../services/api/common/promiseActions';
import { NoticeTableBlock } from '../../notice/main/NoticeDetail';
import spCommonPng from '../../../../styles/images/common/sprite/sp_common.png';
import spCommonMobilePng from '../../../../styles/images/common/sprite/sp_common_mobile.png';
import { SCREEN_SIZE_MOBILE } from '../../../../components/button/CommonStyle';

const FaqCategory = ({ sel }) => {
  const [faqList, setFaqList] = useState([]);
  const [selSeqnoArr, setSelSeqnoArr] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGetFaqAction({ categorySeqno: sel })).then((res) => {
      if (!res.error) {
        setFaqList(res.data.content);
        setSelSeqnoArr([]);
      }
    });
  }, [dispatch, sel]);

  const onClickRow = (seqno) => {
    if (selSeqnoArr.includes(seqno)) {
      setSelSeqnoArr(selSeqnoArr.filter((no) => no !== seqno));
    } else {
      setSelSeqnoArr((prev) => [...prev, seqno]);
    }
  };

  const getParseContent = (content) => {
    let parseContent = content.replace(/\r\n/gi, '<br>');
    parseContent = parseContent.replace(/\\n/gi, '<br>');
    parseContent = parseContent.replace(/\n/gi, '<br>');
    return parseContent;
  };

  return (
    <NoticeTableBlock>
      <table>
        <colgroup>
          <col width="100%" />
        </colgroup>
        <tbody>
          {faqList.length !== 0 &&
            faqList.map((faq) => {
              return (
                <>
                  <tr onClick={() => onClickRow(faq.notientSeqno)}>
                    <TitleThBlock on={selSeqnoArr.includes(faq.notientSeqno)}>
                      {faq.title}
                    </TitleThBlock>
                  </tr>
                  <ReasonTrBlock show={selSeqnoArr.includes(faq.notientSeqno)}>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: getParseContent(faq.content)
                      }}
                    />
                  </ReasonTrBlock>
                </>
              );
            })}
        </tbody>
      </table>
    </NoticeTableBlock>
  );
};

const slideUp = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideDown = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const TitleThBlock = styled.th`
  &&& {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    padding: 18px 25px 16px;
    border-top: solid 1px #e9ecf4;
    border-right: 0 !important;

    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      text-align: left;
      font-size: 14px;
      font-weight: bold;
      padding: 17px 46px 15px 16px;
      border-right: 0 !important;

      background-color: #fff;
      ${(props) =>
        props.on &&
        css`
          background-color: #284cb0;
          color: #fff;
        `}
    }
    ${(props) =>
      props.on &&
      css`
        background-color: #fff;
      `}
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -6px;
      right: 25px;
      width: 14px;
      height: 8px;
      background: url(${spCommonPng}) no-repeat;
      background-size: 400px auto;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        margin-top: -6px;
        right: 12px;
        width: 14px;
        height: 8px;
        background: url(${spCommonMobilePng}) no-repeat;
        background-size: 230px auto;
      }
      ${(props) =>
        props.on
          ? css`
              background-position: -341px -19px;
              @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
                background-position: -197px -28px;
              }
            `
          : css`
              background-position: -341px -10px;
              @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
                background-position: -197px -10px;
              }
            `}
    }
  }
`;

const ReasonTrBlock = styled.tr`
  background: rgba(175, 236, 255, 0.16);
  cursor: default;
  &&& > td {
    text-align: left;
    padding: 30px 50px;
    border: 1px solid rgba(175, 236, 255, 0.6);
    border-radius: 6px;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      text-align: left;
      padding: 16px;
      border: 1px solid rgba(175, 236, 255, 0.6);
      border-radius: 4px;
    }
  }

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  ${(props) =>
    props.show
      ? css`
          display: revert;
          animation-name: ${slideUp};
        `
      : css`
          display: none;
          animation-name: ${slideDown};
        `}
`;

export default FaqCategory;
