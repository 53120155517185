import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';
import SpCommonPng from '../../../../../styles/images/common/sprite/sp_common.png';
import {
  AuthenticationMethod,
  Role,
  SignupStep
} from '../../../../../utils/constant/AgmConstant';
import SignupBody from '../SignupBody';
import SignupTitle from '../SignupTitle';

const CertificateBlock = styled.div`
  width: 400px;
  margin: 0 auto;
  padding-top: 20px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    width: auto;
    padding-top: 0px;
  }
  & > ul {
    display: block;
    & > li {
      display: block;
      margin-bottom: 20px;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        margin-bottom: 6px;
      }
      & > button {
        display: inline-block;
        width: 100%;
        padding: 24px 40px 24px 40px;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 20px;
        line-height: 1.54;
        letter-spacing: -0.5px;
        text-align: center;
        background-color: #fcfcfd;
        border: 1px solid #c8d3e1;
        position: relative;
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          padding: 14px 40px 13px 40px;
          font-size: 15px;
        }
        &:hover {
          background-color: #284cb0;
          color: #fff;
        }
        &::before {
          content: '';
          position: absolute;
          top: 35px;
          right: 20px;
          display: block;
          width: 8px;
          height: 14px;
          margin-top: -5.5px;
          background: url(${SpCommonPng}) no-repeat;
          background-size: 400px auto;
          background-position: -312px -15px;
          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 14px;
            margin-top: -3px;
            width: 6px;
            height: 6px;
            border-top: 1px solid #121418;
            border-right: 1px solid #121418;
            transform: rotate(45deg);

            background: none;
          }
        }
      }
    }
  }
`;

const SignupStep1 = ({ register, setValue, setStep, setRole }) => {
  const methods = useSelector((state) => state.common.authorization.methods);
  const onClickHandler = (e) => {
    setValue('authenticationMethod', e.target.value);
    setStep(SignupStep.Terms);
  };
  useEffect(() => {
    setRole(Role.INDIVIUDAL_SHAREHOLDER.code);
  }, [setRole]);
  return (
    <SignupBody>
      <SignupTitle title="본인인증 방식을 선택하세요" />
      <CertificateBlock>
        <ul>
          <li>
            <button
              onClick={onClickHandler}
              value={AuthenticationMethod.CO_CERTIFICATE.code}
            >
              공동인증서
            </button>
          </li>
          {!!methods && methods.includes(AuthenticationMethod.PASS_APP.code) && (
            <li>
              <button
                onClick={onClickHandler}
                value={AuthenticationMethod.PASS_APP.code}
              >
                통신사(PASS) 인증
              </button>
            </li>
          )}
        </ul>
      </CertificateBlock>
    </SignupBody>
  );
};

export default SignupStep1;
