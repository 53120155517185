import styled from 'styled-components';
import {
  AgmType,
  getConstNameByCode
} from '../../../../utils/constant/AgmConstant';
import {
  DateFormatName,
  getDateFormat
} from '../../../../utils/DateFormatUtil';
import { DATE_FORMAT } from '../../../../utils/DateUtil';
import TableBlock, { TableDataBlock } from './components/TableBlock';

const PrevAgmList = (props) => {
  const { agms } = props;
  return (
    <TableBlock>
      <TableDataBlock>
        {agms.map((agm, idx) => {
          return (
            <tr key={`TableDataBlock_tr_${idx}`}>
              <LeftAlignTdBlock>{agm.agmName}</LeftAlignTdBlock>
              <td>
                {DATE_FORMAT(
                  agm.agmDateTime,
                  getDateFormat(DateFormatName.LONG_DATE_TIME)
                )}
              </td>
              <td>
                {getConstNameByCode(AgmType, agm.agmType)}/{agm.agmSessionCount}
                회
              </td>
              <td>
                {DATE_FORMAT(
                  agm.agmRecordDate,
                  getDateFormat(DateFormatName.SHORT_DATE)
                )}
              </td>
              <td>{agm.electronicVoteRate}%</td>
              <td>
                {agm.totalVoteRate === 0
                  ? '합산 결과 등록 안함'
                  : `${agm.totalVoteRate}%`}
              </td>
              <td>{`${agm.numberOfPassedAgendas}안/${agm.numberOfTotalAgendas}안`}</td>
            </tr>
          );
        })}
      </TableDataBlock>
    </TableBlock>
  );
};

const LeftAlignTdBlock = styled.td`
  text-align: left;
`;

export default PrevAgmList;
