import React from 'react';
import styled from 'styled-components';

const VoteResultBoxListBlock = styled.div``;

const VoteResultBoxList = ({ children }) => {
  return (
    <VoteResultBoxListBlock>
      <ul>{children}</ul>
    </VoteResultBoxListBlock>
  );
};

export default VoteResultBoxList;
