import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../../../../components/button/Button';
import DataTable, {
  DataType
} from '../../../../../../../components/datatable/DataTable';
import { TableTitleBlock } from '../../../../../../../components/table/TableTitle';
import { useFileDownload } from '../../../../../../../hooks/useFileDownload';
import {
  fetchGetENoticeAgmSendFailsAction,
  fetchGetENoticeAgmSendFailsExcelDownloadAction
} from '../../../../../../../services/api/agm/promiseActions';
import { ListWrapBlock } from '../../../../../dashboard/CorpDashboardPage';

const NotRcvdList = ({ agmSeqno, sendSeqno }) => {
  const dispatch = useDispatch();
  const [failList, setFailList] = useState([]);
  const [pageConfig, setPageConfig] = useState();
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    const data = {
      page: pageNumber,
      size: 10
    };
    dispatch(
      fetchGetENoticeAgmSendFailsAction({ agmSeqno, sendSeqno, data })
    ).then((res) => {
      if (!res.error) {
        const { number, size, totalElements, totalPages, content } = res.data;
        const newArr = [];
        content.forEach((item, index) => {
          newArr.push({
            ...item,
            no: index + 1
          });
        });
        setFailList(newArr);
        setPageConfig({
          pageNumber: number,
          size,
          totalCount: totalElements,
          totalPageNumber: totalPages
        });
      }
    });
  }, [dispatch, pageNumber]);

  const { callDownloadFetchENoticeFailFiles } = useFileDownload();

  const onClickFailExcelDownload = () => {
    callDownloadFetchENoticeFailFiles(
      fetchGetENoticeAgmSendFailsExcelDownloadAction,
      agmSeqno,
      sendSeqno
    );
  };

  const notRcvdTableColumn = [
    {
      title: 'No',
      key: 'no',
      col: '7%'
    },
    {
      title: '주주명',
      key: 'name',
      col: '13%'
    },
    {
      title: '주민등록번호',
      key: 'realNameNumber',
      type: DataType.REALNUMBER,
      col: '20%'
    },
    {
      title: '보유 주식 수 (주)',
      key: 'stockAmount',
      type: DataType.NUMBER,
      col: '15%'
    },
    {
      title: '실패 사유',
      key: 'errorMessage',
      col: '*'
    }
  ];

  return (
    <ListWrapBlock>
      <TableTitleBlock>
        <span>미 수신 주주 내역</span>
      </TableTitleBlock>
      <DataTable
        columns={notRcvdTableColumn}
        dataSource={failList}
        filterConfig={{
          desc: '해당 주주가 전자고지기능을 사용하지 않아 전자고지서가 미 수신 된 주주 목록입니다.<br /> 해당 주주들에게 오프라인 우편으로 주주총회 소집통지서를 발송 하시기 바랍니다.',
          filterItems: (
            <Button
              type={ButtonTypes.Primary}
              size={ButtonSize.Small}
              onClick={onClickFailExcelDownload}
            >
              Excel 다운
            </Button>
          )
        }}
        pageConfig={{
          ...pageConfig,
          onClick: setPageNumber
        }}
        shortHeight
      />
    </ListWrapBlock>
  );
};

export default NotRcvdList;
