import React from 'react';
import styled, { css } from 'styled-components';
import { Icons } from '../../styles/Icons';

const ListTagBlock = styled.span`
  position: absolute;
  top: 7px;
  ${(props) =>
    props.isRequired
      ? css`
          right: 25px;
        `
      : css`
          right: 0;
        `}
  display: block;
  padding-left: 16px;
  font-size: 13px;
  font-weight: normal;
  color: #fb0000;
  &::after {
    ${(props) => Icons.list_tag.css}
  }
`;

const ListTag = ({ children, isRequired = false }) => {
  return (
    <ListTagBlock isRequired={isRequired}>{children}필수 입력사항</ListTagBlock>
  );
};

export default ListTag;
