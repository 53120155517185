import { GET, POST, PUT } from '../../../utils/constant/httpMethod';
import { request } from '../../../utils/requestHelper';

const API_GROUP = 'auth';
/**
 * 토큰발급 및 로그인
 */
const Auth = {
  // PASS 인증 요청
  fetchGetAuthPassCert({ certNum, urlCode, name, cell }) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/pass/cert`,
      params: { certNum, urlCode, name, cell }
    });
  },
  // 토큰발급 (테스트)
  fetchGetAuthTestToken(userSeqno) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/test-token`,
      params: userSeqno
    });
  },
  // 토큰 리프레시
  fetchPostAuthRefresh(refreshToken) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/refresh`,
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    });
  },
  // 공동인증서 재등록
  fetchPutAuthCertificate(data) {
    return request({
      method: PUT,
      url: `/v1/${API_GROUP}/certificate`,
      data
    });
  },
  // 로그인-공동인증서
  fetchPostAuthLoginCert(data) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/login-cert`,
      data
    });
  },
  // 로그인-PASS
  fetchPostAuthLoginPass(data) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/login-pass`,
      data
    });
  },
  // 로그인-PASS callback
  fetchPostAuthLoginPassCallback(recCert) {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/pass/callback${recCert}`
    });
  },
  /*
  공동인증서 재등록
  */
  // 공동인증서 재등록 - 개인 사용자
  fetchPutAuthCertificateIndividual(data) {
    return request({
      method: PUT,
      url: `/v1/${API_GROUP}/certificate/individual`,
      data
    });
  },
  // 공동인증서 재등록 - 법인 사용자
  fetchPutAuthCertificateBiz(data) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/certificate/biz`,
      data
    });
  },
  // 로그인 - 발행회사 조회용
  fetchPostAuthLoginPassword(data) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/login-password`,
      data
    });
  },
  // 로그인 - 증권사
  fetchPostAuthLoginAdmin(data) {
    return request({
      method: POST,
      url: `/v1/${API_GROUP}/login-admin`,
      data
    });
  }
};

export default Auth;
