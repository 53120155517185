import TableBlock from './components/TableBlock';

const PersonInChargeInfo = ({ info }) => {
  return (
    <TableBlock>
      <table>
        <colgroup>
          <col width="15%" />
          <col width="*%" />
          <col width="15%" />
          <col width="*%" />
          <col width="15%" />
          <col width="*%" />
        </colgroup>
        <tbody>
          <tr>
            <th>성명</th>
            <td>{info.name}</td>
            <th>직위</th>
            <td>{info.position}</td>
            <th>부서명</th>
            <td>{info.department}</td>
          </tr>
          <tr>
            <th>전화번호</th>
            <td>{info.telephone}</td>
            <th>휴대폰번호</th>
            <td>{info.cell}</td>
            <th>이메일</th>
            <td>{info.email}</td>
          </tr>
        </tbody>
      </table>
    </TableBlock>
  );
};

export default PersonInChargeInfo;
