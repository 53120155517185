import PropTypes from 'prop-types';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../../../../components/button/Button';

export const CancelBtn = (props) => {
  const { onClick, title = '취소', size } = props;
  return (
    <Button
      size={!!size ? size : ButtonSize.Table}
      type={ButtonTypes.Basic}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

export const OkBtn = (props) => {
  const { onClick, title = '확인', size } = props;
  return (
    <Button
      size={!!size ? size : ButtonSize.Table}
      type={ButtonTypes.Primary}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

OkBtn.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func
};

CancelBtn.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func
};
