import PropTypes from 'prop-types'; // ES6
import React, { useState } from 'react';
const RadioGroup = (props) => {
  const { name, onChange, register, registerOption, selectedValue, children } =
    props;
  const [checkedValue, setCheckedValue] = useState(selectedValue);

  const onSelect = (value) => {
    if (!!value) {
      setCheckedValue(value);
      typeof onChange === 'function' && onChange(value);
    }
  };

  return (
    <>
      {children.map((child, idx) => {
        if (typeof child !== 'undefined' && !child.props?.isWrapper) {
          return React.cloneElement(
            child,
            {
              key: `radio_${idx}`,
              name,
              // ...reg,
              register,
              registerOption,
              selectedValue: !!selectedValue ? selectedValue : checkedValue,
              onSelect: onSelect
            },
            null
          );
        } else {
          return child;
        }
      })}
    </>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  register: PropTypes.func,
  selectedValue: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
};

RadioGroup.defaultProps = {
  name: '',
  onChange: null,
  register: null,
  selectedValue: '',
  children: null
};

export default RadioGroup;
