import PropTypes from 'prop-types'; // ES6
import React from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../../components/button/CommonStyle';
import spCommonPng from '../../../../../../styles/images/common/sprite/sp_common.png';
import { formatNumber } from '../../../../../../utils/StringUtil';

const ValueDisplayType = {
  Inner: 'inner',
  Outer: 'outer'
};

const BarChartContainer = styled.ul`
  flex: 1;
`;

const BarChartBlock = styled.li`
  // .result_box > ul:last-of-type li
  & {
    min-height: 120px;
    border-bottom: 1px solid #e9ecf4;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

const GraphInner = styled.div`
  // .graphInner
  width: 100%;
  // 임시로 크기 변경
  height: 60px; // 40px
  padding: 10px 0 0 100px;
  position: relative;
  box-sizing: border-box;
  background: #ffffff;
  // temp 17% -> 5%, 20% -> 10%
  background: linear-gradient(to right, #ffffff 5%, #eff4f6 10%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eff4f6',GradientType=1 );

  ${(props) =>
    props.extend &&
    css`
    //graphInner.extend 
    height: auto;
    padding: 26px 10px 10px;
    background: #eff4f6;

    // .result_box > ul > li > div:first-of-type.graphInner.extend
    padding-top : 42px;
}
  `}
`;

const GraphBar = styled.div`
  // .graphBar
  width: 99%;
  // 임시로 크기 변경
  height: 40px; // 20px
  line-height: normal;
  background: #eff4f6;
  border-radius: 3px;

  & > div {
    // .graphBar .result_type1
    display: inline-block;
    // 임시로 크기 변경
    height: 40px; // 20px
    position: relative;
    background: #0094ff;
    ${(props) =>
      props.barColor &&
      css`
        background: ${props.barColor};
      `}
    border-radius: 3px 0 0 3px;

    & > p {
      // .graphBar p
      text-align: right;
      // temp
      margin-top: 10px;
      & > span {
        // .graphBar p span
        font-size: 12px;
        white-space: nowrap;
        position: absolute;
        left: 100%;
        margin: 0 0 0 4px;

        // .ft-col02
        color: #0094ff !important;
        ${(props) =>
          props.barColor &&
          css`
            color: ${props.barColor} !important;
          `}

        ${(props) =>
          props.displayType === ValueDisplayType.Inner &&
          css`
            // .graphBar .morethan p span
            left: revert;
            right: 0;
            margin: 0 4px 0 0;
            color: #fff !important;
          `}

        & > strong {
          // .graphBar p strong
          display: inline-block;
          font-size: 16px;
          font-weight: 800;
          margin: 0 0 0 6px;
        }
      }
    }
  }
`;

const Legendary = styled.div`
  // .legendary
  display: inline-block;
  height: 30px;
  width: 100%;

  // .graphInner .legendary
  display: inline-block;
  height: 0;

  ${(props) =>
    props.extend &&
    css`
      // .graphInner.extend .legendary
      position: absolute;
      // temp bottom 58px > 75px
      bottom: 80px;
      z-index: 1;
    `}

  & > p {
    // .legendary p
    display: inline-block !important;
    text-align: left;
    font-size: 16px !important;
    font-weight: 800 !important;
    margin: 7px 40px 0 0 !important;
    position: relative;

    // .graphInner .legendary p
    position: absolute;
    // temp top 10px -> 20px
    top: 20px;
    left: 20px;
    display: block !important;
    margin: 0 !important;

    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      font-size: 14px !important;
    }

    ${(props) =>
      props.extend &&
      css`
        // .graphInner.extend .legendary p
        top: inherit;
        left: inherit;
        position: static;
        /*
        & > em {
          // .graphInner.extend .legendary p em
          margin: -5px 4px 0 0;
        }
        */
      `}
    ${(props) =>
      props.barColor &&
      css`
        color: ${props.barColor} !important;
      `}
  }
`;

const LegendaryLabelBlock = styled.em`
  // .graphInner .legendary p em
  display: inline-block;
  margin: -5px 4px 4px -5px;
  padding: 4px 0;
  min-width: 50px;
  height: 24px;
  background-color: #ec2a1e;
  color: #fff;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  box-sizing: border-box;

  // .graphInner .legendary .approved, .graphInner .legendary .rejected
  padding: 4px 12px 4px 26px;
  height: 28px;
  background-color: #fff;
  color: #080808;
  border: 2px solid #66738d;

  // .graphInner .legendary .approved::after
  &::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background: url(${spCommonPng}) no-repeat;
    background-size: 330px auto;
    ${(props) =>
      props.result === '1' &&
      css`
        background-position: -1px -136px;
      `}
    ${(props) =>
      props.result === '2' &&
      css`
        background-position: -1px -116px;
      `}
  }
`;

const getLegendaryLabel = (item) => {
  if (item.result) {
    const label = item.result === '1' ? '가결' : '부결';
    return (
      <LegendaryLabelBlock result={item.result}>{label}</LegendaryLabelBlock>
    );
  } else {
    return null;
  }
};

const BarChart = (props) => {
  const { data, extend, unit, title, barThemeColor } = props;
  return (
    <BarChartContainer>
      {title && title}
      <BarChartBlock>
        {data &&
          data.map((item, index) => {
            const dType =
              item.percent > 70
                ? ValueDisplayType.Inner
                : ValueDisplayType.Outer;
            return (
              <GraphInner extend={extend} key={`GraphInner_${index}`}>
                <GraphBar
                  barWidth={item.percent}
                  displayType={dType}
                  barColor={barThemeColor}
                >
                  <div style={{ width: `${item.percent}%` }}>
                    <p>
                      <span>
                        {`${formatNumber(item.value)}${unit}`}
                        <strong>{`${item.percent}%`}</strong>
                      </span>
                    </p>
                  </div>
                </GraphBar>
                <Legendary extend={extend} barColor={barThemeColor}>
                  <p>
                    {getLegendaryLabel(item)}
                    {item.label}
                  </p>
                </Legendary>
              </GraphInner>
            );
          })}
      </BarChartBlock>
    </BarChartContainer>
  );
};

BarChart.prototype = {
  data: PropTypes.array,
  extend: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(ValueDisplayType)),
  unit: PropTypes.string
};

BarChart.defaultProps = {
  data: [],
  extend: false,
  type: ValueDisplayType.Outer,
  unit: '주'
};

export default BarChart;
