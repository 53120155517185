import React from 'react';
import Modal from '../components/modal/Modal';
import useModal from '../hooks/useModal';

let ModalContext;
let { Provider } = (ModalContext = React.createContext({}));

let ModalProvider = ({ children }) => {
  let {
    modal,
    handleModal,
    title,
    modalContent,
    okHandler,
    openModal,
    closeModal,
    setModalConfig,
    modalConfig,
    isRequired,
    setModalContent,
    closeCallback,
    isRemoveCloseIcon
  } = useModal();
  return (
    <Provider
      value={{
        modal,
        handleModal,
        title,
        modalContent,
        openModal,
        okHandler,
        isRequired,
        closeModal,
        setModalConfig,
        setModalContent,
        modalConfig,
        closeCallback,
        isRemoveCloseIcon
      }}
    >
      <Modal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
