import React from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';
import ListBox from './ListBox';

const SectionBlock = styled.div`
  ${(props) =>
    props.wide
      ? css`
          width: 1360px;
        `
      : css`
          width: 1020px;
        `}
  margin: 0 auto;
  padding: 60px 16px 0;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    width: auto;
    padding: 16px 0 0;
  }
`;

const Section = ({ wide = false, children }) => {
  return (
    <SectionBlock wide={wide}>
      <ListBox>{children}</ListBox>
    </SectionBlock>
  );
};

export default Section;
