import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { TableTitleBlock } from '../../../../../components/table/TableTitle';
import { fetchGetAgmVoteboxResultAction } from '../../../../../services/api/agm/promiseActions';
import VoteState from './contents/votebox/VoteState';
import VoteboxResult from './contents/votebox/VoteboxResult';

const VoteExercise = (props) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { agmSeqno } = match.params;
  const [voteboxResult, setVoteboxResult] = useState();

  useEffect(() => {
    dispatch(fetchGetAgmVoteboxResultAction({ agmSeqno })).then((res) => {
      if (!res.error) {
        console.log('dispatch(fetchGetAgmVoteboxResult');
        console.log(res.data);
        setVoteboxResult(res.data);
      }
    });
  }, [dispatch, agmSeqno]);

  return (
    <>
      {/* isUpdatePossible={false} 삭제 */}
      {!!voteboxResult && <VoteState info={voteboxResult.voteSummary} />}
      <TableTitleBlock>
        <span>주주총회 결의현황 (전자투표 결과)</span>
      </TableTitleBlock>
      {!!voteboxResult && (
        <VoteboxResult agmSeqno={agmSeqno} agendas={voteboxResult.agendas} />
      )}
    </>
  );
};

export default VoteExercise;
