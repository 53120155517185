import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Terms from '../../../../../components/terms/Terms';
import { fetchGetTermsSignUpAction } from '../../../../../services/api/terms/promiseActions';
import {
  AgmTermsType,
  Role,
  SignupStep
} from '../../../../../utils/constant/AgmConstant';
import SignupBody from '../SignupBody';
import SignupFooter from '../SignupFooter';
import SignupTitle from '../SignupTitle';

const SignupStep1 = ({ register, setValue, setStep }) => {
  const [termsCheckArray, setTermsCheckArray] = useState([]);
  const [termsData, setTermsData] = useState();
  const [isAllChecked, setIsAllChecked] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const params = {
      role: Role.CORP_SHAREHOLDER.code
    };

    dispatch(fetchGetTermsSignUpAction(params)).then((res) => {
      if (!res.error) {
        setTermsData(res.data);
      }
    });
  }, [dispatch]);

  const onClickHandler = () => {
    if (isAllChecked) {
      setValue(
        'utilClauseAgreeDateTime',
        termsCheckArray.find(
          (item) => item.type === AgmTermsType.USE_OF_VOTING_SERVICE.code
        )?.checkedDttm
      );
      setValue(
        'individualInformationTreatAgreeDateTime',
        termsCheckArray.find(
          (item) =>
            item.type ===
            AgmTermsType.BUSINESS_CONSIGNMENT_OF_PERSONAL_INFORMATION_FOR_SHAREHOLDER
        )?.checkedDttm
      );
      setStep(SignupStep.Info);
    }
  };

  return (
    <SignupBody>
      <SignupTitle
        title="서비스 이용약관을 읽으신 후, 동의해주세요."
        desc="주주총회 전자투표 서비스 이용약관, 개인정보 수집 및 이용에 모두 동의가 필요합니다."
      />
      <Terms
        data={termsData}
        termsCheckArray={termsCheckArray}
        setTermsCheckArray={setTermsCheckArray}
        setIsAllChecked={setIsAllChecked}
      />
      <SignupFooter
        allChecked={isAllChecked}
        step={SignupStep.Terms}
        onClick={onClickHandler}
      />
    </SignupBody>
  );
};

export default SignupStep1;
