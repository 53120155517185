import styled from 'styled-components';
import { formatNumber } from '../../../../../../../utils/StringUtil';

const VoteState = (props) => {
  const { info } = props;

  return (
    <TableCommon01Block style={{ borderTop: 'solid 1px #e9ecf4' }}>
      <table>
        <colgroup>
          <col width="27%" />
          <col width="23%" />
          <col width="25%" />
          <col width="23%" />
        </colgroup>
        <tbody>
          <tr>
            <th>발행주식총수(주)</th>
            <td>{formatNumber(info?.totalIssueStockQuantity)}</td>
            <th>주주명부 인원(명)</th>
            <td>{formatNumber(info?.totalShareholderCount)}</td>
          </tr>
          <tr>
            <th>참여율</th>
            <td>{formatNumber(info?.votedShareholderRate)} %</td>
            <th>참여 인원(명)</th>
            <td>{formatNumber(info?.voteParcptShareholderCount)}</td>
          </tr>
        </tbody>
      </table>
    </TableCommon01Block>
  );
};

const TableCommon01Block = styled.div`
  // .tblcommon-01 .nothead
  width: 100%;
  & > table {
    & > tbody {
      & th {
        border-top: 0;
        background-color: rgb(233, 236, 244, 0.3);
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -ms-box-shadow: none;
        border-right: solid 1px #e9ecf4 !important;
        border-bottom: solid 1px #e9ecf4 !important;
      }
    }
    & th {
      background: #fbfbfc;
      border-top: solid 1px #e9ecf4;
      border-right: solid 1px #e9ecf4;
      box-shadow: 0px 3px 2px rgb(0 0 0 / 3%);
      -webkit-box-shadow: 0px 3px 2px rgb(0 0 0 / 3%);
      -moz-box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.03);
      -ms-box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.03);
      position: relative;
      padding: 8px 8px 7px;
      height: 32px;
      font-size: 14px;
      text-align: center;
      transform: skew(-0.04deg);
      -webkit-transform: skew(-0.04deg);
      -moz-transform: skew(-0.04deg);
      -ms-transform: skew(-0.04deg);
    }
    & td {
      padding: 8px 8px 7px;
      height: 34px;
      font-size: 15px;
      border-bottom: solid 1px #e9ecf4;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      transform: skew(-0.04deg);
      -webkit-transform: skew(-0.04deg);
      -moz-transform: skew(-0.04deg);
      -ms-transform: skew(-0.04deg);
    }
    & > tr:first-of-type th:first-of-type {
      border-left: 0;
    }
    & > tr:first-of-type th:last-of-type,
    & > tr:last-of-type th:last-of-type {
      border-right: 0;
    }
  }
`;

export default VoteState;
