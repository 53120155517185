import React from 'react';
import styled from 'styled-components';

const Description = styled.span`
  display: inline-block;
  margin-top: 18px;
  padding-left: 0;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.6px;
  color: #8c939c;
`;

const FilterBlock = styled.div`
  & > ul {
    display: flex;
    margin-bottom: 10px;
  }
`;

const InputWrapperBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
`;

const LiBlock = styled.li`
  &:first-of-type {
    flex: 1;
  }
  &:last-of-type {
    margin-left: 5%;
    text-align: right;
    font-size: 14px;
    color: #8c939c;
  }
`;

const DataFilter = (props) => {
  const { desc, filterItems } = props.filterConfig || {};
  return (
    <FilterBlock>
      <ul>
        <LiBlock>
          {desc && (
            <Description
              dangerouslySetInnerHTML={{
                __html: desc
              }}
            >
              {/* {desc} */}
            </Description>
          )}
        </LiBlock>
        <LiBlock>
          <InputWrapperBlock>{filterItems}</InputWrapperBlock>
        </LiBlock>
      </ul>
    </FilterBlock>
  );
};

export default DataFilter;
