import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import Spinner from '../../../components/alert/Spinner';
import { fetchGetAuthPassCertAction } from '../../../services/api/auth/promiseActions';

const PassCert = () => {
  const certNum = uuidv4();
  const urlCode = process.env.REACT_APP_KMC_URL_CODE;
  const callbackDomain = process.env.REACT_APP_KMC_CALLBACK_DOMAIN;
  const history = useHistory();
  const type = history.location.search.substring(1).split('=')[1];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchGetAuthPassCertAction({ certNum, urlCode })).then((res) => {
      console.log(fetchGetAuthPassCertAction);
      console.log(res);

      const tr_cert = res.data.tr_cert;

      var form = document.createElement('form');
      form.setAttribute('method', 'POST'); //Post 방식
      form.setAttribute(
        'action',
        'https://www.kmcert.com/kmcis/web/kmcisReq.jsp'
      ); //요청 보낼 주소

      var hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', 'tr_url');
      hiddenField.setAttribute(
        'value',
        `${callbackDomain}/pass/${type}/callback`
      );

      form.appendChild(hiddenField);

      hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', 'tr_cert');
      hiddenField.setAttribute('value', tr_cert);
      form.appendChild(hiddenField);

      document.body.appendChild(form);
      form.submit();
    });
  }, []);

  return <Spinner isEmptyScreen={true} />;
};

export default PassCert;
