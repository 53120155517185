import { createPromiseAction } from '@adobe/redux-saga-promise';

/**
 * 발행회사 계약 신청
 */
// 발행회사 계약 신청
export const fetchPostIssueIncorporatedAction = createPromiseAction(
  'FETCH_POST_ISSUE_INCORPORATED'
);
// 발행회사 계약 신청 수정
export const fetchPutIssueIncorporatedAction = createPromiseAction(
  'FETCH_PUT_ISSUE_INCORPORATED'
);
// 발행회사 패스워드 변경
export const fetchPutIssueIncorporatedPasswordAction = createPromiseAction(
  'FETCH_PUT_ISSUE_INCORPORATED_PASSWORD'
);
// 발행회사 계약 신청 정보 조회
export const fetchGetIssueIncorporatedAction = createPromiseAction(
  'FETCH_GET_ISSUE_INCORPORATED'
);
// 발행회사 파일 조회
export const fetchGetIssueIncorporatedFilesAction = createPromiseAction(
  'FETCH_GET_ISSUE_INCORPORATED_FILES'
);
