import React from 'react';
import styled from 'styled-components';

const TableNoDataBlock = styled.li`
  justify-content: center;
  width: 100% !important;
  padding: 60px 0 60px !important;
  letter-spacing: -0.5px;
  text-align: center;
  color: #caccd7;
`;
const TableNoData = () => {
  return <TableNoDataBlock>데이터가 없습니다.</TableNoDataBlock>;
};

export default TableNoData;
