import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { SCREEN_SIZE_MOBILE } from '../../../components/button/CommonStyle';
import ListImgItem from '../../../components/layout/ListImgItem';
import { LOGO_PLACE } from '../../../components/page/Gnb';
import Page from '../../../components/page/Page';
import PageHeader from '../../../components/page/PageHeader';
import PageTab, { TAB_PLACE } from '../../../components/tabs/PageTab';
import useWindowSize from '../../../hooks/useWindowSize';
import { UserType } from '../../../utils/constant/AgmConstant';
import SignupImgBtn from './main/SignupImgBtn';

const SignupContainer = () => {
  let history = useHistory();
  const [selTabNum, setSelTabNum] = useState(0);
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => {
    return windowSize.width <= SCREEN_SIZE_MOBILE;
  }, [windowSize]);
  const tabItemArr = useMemo(() => {
    return isMobile ? ['주주'] : ['주주', '발행회사'];
  }, [isMobile]);

  /* 동의 관련 데이터 */
  /*
  const [agreeTimeMap, setAgreeTimeMap] = useState({
    [AgreeType.CELLPH_NOTI_AGREE_DTTM]: null,
    [AgreeType.EMAIL_NOTI_AGREE_DTTM]: null,
    [AgreeType.MARKTN_AGREE_DTTM]: new Date().toISOString(), //null,
    [AgreeType.UTIL_CLAUSE_AGREE_DTTM]: new Date().toISOString(), //null,
    [AgreeType.PRIVACY_POLICY_AGREE_DTTM]: new Date().toISOString() //null,
  });

  {
    "bizRegistrationNumber" : "6028700689",    // 사업자 등록증
    "institutionalInvestor" : false,          // 기관투자자여부
    "personInChargeName" : "직원이름",        // 담당자명
    "personInChargePositionName" : "직워",    // 직위
    "personInChargeDepartmentName" : "부서",  // 부서
    "personInChargeCell" : "01000000001",
    "personInChargeEmailAddress" : "aaa@bbb.ccc",
    "signData" : "",       // 서명 데이터
    "userName" : "한국전자투표",               //회사 명
    "cellphNotificationAgreeDateTime" : "2021-08-06T17:00:00",
    "emailNotificationAgreeDateTime" : "2021-08-06T17:00:00",
    "marketingAgreeDateTime" : "2021-08-06T17:00:00",
    "utilClauseAgreeDateTime" : "2021-08-06T17:00:00",
    "individualInformationTreatAgreeDateTime" : "2021-08-06T17:00:00"
  }
  
  const onSignup = (data) => {
    const signupData = {
      ...data,
      authenticationMethod: AuthenticationMethod.CO_CERTIFICATE.code,
      ...agreeTimeMap,
      signData: TEST_DATA.INDIVIUDAL_SIGN_DATA
    };
    
    dispatch(fetchPostUsersCorpAction(signupData)).then((res) => {
      if (res.meta.status === 201) {
        openModal('확인', '가입되었습니다!', () => history.push('/login'));
      } else if (res.error) {
        openModal('에러', res.error.dev_message);
      }
    });
  };
  */

  const onChangeTab = (value) => {
    setSelTabNum(value);
  };

  const onClickRegister = (value) => {
    switch (value) {
      case UserType.INDIVIDUAL.code:
        history.push('signup/individual');
        break;
      case UserType.CORP.code:
        history.push('/signup/corporate');
        break;
      case UserType.ISSINC.code:
        history.push('/corp/contract');
        break;
      default:
        break;
    }
  };

  return (
    <Page logoPlace={LOGO_PLACE.CENTER}>
      <PageHeader title="서비스 가입" />
      <PageTab
        itemArr={tabItemArr}
        onChange={onChangeTab}
        tabPlace={TAB_PLACE.CENTER}
      />
      {selTabNum === PAGE.STOCKHOLDER && (
        <ListImgItem>
          <>
            <SignupImgBtn
              title="개인사용자"
              desc="본인 인증 후, 가입이 진행됩니다."
              userType={UserType.INDIVIDUAL.name}
              onClick={onClickRegister}
            />
            <SignupImgBtn
              title="법인사용자"
              desc="법인 공동인증서로 가입이 진행됩니다."
              userType={UserType.CORP.name}
              onClick={onClickRegister}
            />
          </>
        </ListImgItem>
      )}
      {selTabNum === PAGE.ISSINC && (
        <ListImgItem>
          <>
            <SignupImgBtn
              title="이용계약 신청"
              desc="개인/법인 인증 후, 절차가 진행됩니다."
              userType={UserType.ISSINC.name}
              onClick={onClickRegister}
            />
          </>
        </ListImgItem>
      )}
    </Page>
  );
};

export default SignupContainer;

const PAGE = {
  STOCKHOLDER: 0,
  ISSINC: 1
};
