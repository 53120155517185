import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ModalContext } from '../context/ModalContext';

export const useFileDownload = () => {
  const dispatch = useDispatch();

  const { openModal } = useContext(ModalContext);

  const getFileName = (metaData) => {
    if (metaData.headers['content-disposition']) {
      const fileName = metaData.headers['content-disposition']
        .split("''")[1]
        .split(';')[0];
      return decodeURI(fileName);
    }
    return null;
  };

  const getFileType = (metaData) => {
    if (metaData.headers['content-type']) {
      const fileType = metaData.headers['content-type'].split(';')[0];
      return fileType;
    }
    return null;
  };

  const actionHandler = (action, payload) => {
    if (typeof action !== 'function') return;
    dispatch(action({ ...payload })).then((res) => {
      if (!res.error) {
        downloadFile(res.data, getFileName(res.meta), getFileType(res.meta));
      } else {
        openModal('확인', '파일이 존재하지 않습니다');
      }
    });
  };
  const callDownloadFetchAPIOnlyAgmseqno = (agmSeqno, action) => {
    actionHandler(action, { agmSeqno });
  };

  const callDownloadFetchWithoutFile = (agendaSeqno, agmSeqno, action) => {
    actionHandler(action, { agmSeqno, agendaSeqno });
  };

  const callDownloadFetchAPI = (file, action, agmSeqno) => {
    actionHandler(action, { agmSeqno, filePath: file.filePath });
  };

  const callDownloadFetchAPIParam = (file, action, agmSeqno) => {
    actionHandler(action, { agmSeqno, params: file });
  };

  const callDownloadFetchAPIAgmParam = (
    file,
    action,
    agmSeqno,
    agendaSeqno
  ) => {
    actionHandler(action, {
      agmSeqno,
      agendaSeqno,
      params: { filePath: file.filePath, fileName: file.fileName }
    });
  };

  const callDownloadFetchAPITemporary = (file, action) => {
    actionHandler(action, file);
  };

  const callDownloadFetchNoticeFiles = (file, action) => {
    actionHandler(action, { ...file });
  };

  const callDownloadFetchIssueIncorporatedFiles = (fileCd, action) => {
    actionHandler(action, { fileCd });
  };

  const callDownloadFetchENoticeFailFiles = (
    action,
    agmSeqno,
    sendSeqno,
    data
  ) => {
    actionHandler(action, { agmSeqno, sendSeqno, data });
  };

  const downloadFile = (data, fileName, fileType = 'image/png') => {
    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: fileType
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', decodeURI(fileName));
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return {
    callDownloadFetchAPIOnlyAgmseqno,
    callDownloadFetchAPI,
    callDownloadFetchAPIParam,
    callDownloadFetchWithoutFile,
    callDownloadFetchAPIAgmParam,
    callDownloadFetchAPITemporary,
    callDownloadFetchNoticeFiles,
    callDownloadFetchIssueIncorporatedFiles,
    callDownloadFetchENoticeFailFiles,
    downloadFile
  };
};
