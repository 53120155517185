import React from 'react';
import styled, { css } from 'styled-components';

const TableBlock = styled.table`
  // .tblcommon-01
  width: 100%;
  min-width: 100%;
  margin-bottom: 50px;
  ${(props) =>
    props.readOnlyTable &&
    css`
      & tr:hover {
        background: rgba(5, 114, 239, 0.08);
        cursor: pointer;
      }
    `}
  & th {
    background: #fbfbfc;
    border-top: solid 1px #e9ecf4;
    border-right: solid 1px #e9ecf4;
    box-shadow: 0px 3px 2px rgb(0 0 0 / 3%);
    position: relative;
    padding: 5px 8px 2px;
    height: 50px;
    font-size: 14px;
    text-align: center;
    transform: skew(-0.04deg);
  }
  & tr:first-of-type {
    border-left: 0;
  }
  & tr:first-of-type > th {
    //text-align: left;
  }
  & th:first-of-type {
    border-left: 0;
  }

  & td {
    padding: 3px 8px 0;
    height: 54px;
    font-size: 15px;
    border-bottom: solid 1px #e9ecf4;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    transform: skew(-0.04deg);
  }
`;

const ResultTable = ({ children }) => {
  return <TableBlock>{children}</TableBlock>;
};

export default ResultTable;
