const Validation = {
  NAME: {
    required: {
      value: true,
      message: '이름은 필수 입력 항목입니다.'
    },
    pattern: {
      value: /^[가-힣a-zA-Z\s]+$/,
      message: '이름을 다시 확인해주세요.'
    },
    minLength: {
      value: 2,
      message: '이름은 2자 이상이어야 합니다.'
    }
  },

  NAME_SUB: {
    pattern: {
      value: /^[가-힣a-zA-Z\s]+$/,
      message: '이름을 다시 확인해주세요.'
    },
    minLength: {
      value: 2,
      message: '이름은 2자 이상이어야 합니다.'
    }
  },

  BIRTHDAY: {
    required: {
      value: true,
      message: '생년월일은 필수 입력 항목입니다.'
    },
    pattern: {
      value:
        /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/,
      message: '생년월일을 다시 확인해주세요.'
    }
  },

  PASSWORD: {
    required: {
      value: true,
      message: [
        '조회 전용 비밀번호를 설정하여 주세요.',
        '8~16자리 영문, 숫자, 특수문자 사용하세요.'
      ]
    },
    pattern: {
      value:
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,16}$/,
      message: '비밀번호는 문자, 숫자, 특수문자를 포함해야 합니다.'
    },
    minLength: {
      value: 8,
      message: '비밀번호는 8자리 이상이어야 합니다.'
    },
    maxLength: {
      value: 16,
      message: '비밀번호는 16자리 이하이어야 합니다.'
    }
  },

  TEL: {
    required: {
      value: true,
      message: '전화번호는 필수 입력 항목입니다.'
    },
    pattern: {
      value: /^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/,
      message: '전화번호를 다시 확인해주세요'
    }
  },

  PHONE: {
    required: {
      value: true,
      message: '휴대폰번호는 필수 입력 항목입니다.'
    },
    pattern: {
      value: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
      message: '휴대폰번호를 다시 확인해주세요.'
    }
  },

  EMAIL: {
    required: {
      value: true,
      message: '이메일은 필수 입력 항목입니다.'
    },
    pattern: {
      value:
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
      message: '이메일을 다시 확인해주세요.'
    }
  }
};

export default Validation;
