import PropTypes from 'prop-types'; // ES6
import React from 'react';
import styled, { css } from 'styled-components';
import CommonStyles from '../../styles/CommonStyles';
import { Icons } from '../../styles/Icons';
import {
  btnType01,
  btnType02,
  btnType03,
  btnType04,
  btnType05,
  btnType06,
  btnType07
} from './CommonStyle';

export const ButtonSize = {
  Small: 'small',
  Medium: 'medium',
  Large: 'large',
  Default: 'default',
  Table: 'table',
  Footer: 'footer',
  Popup: 'popup'
};

export const ButtonTypes = {
  Primary: 'primary',
  Basic: 'basic',
  Defaults: 'defaults',
  Outline: 'outline',
  Inert: 'inert',
  Disabled: 'disabled'
};

const getButtonCss = (props) => {
  const { size } = props;
  switch (size) {
    case ButtonSize.Small:
      return btnType02;
    case ButtonSize.Medium:
      return btnType04;
    case ButtonSize.Large:
      return btnType03;
    case ButtonSize.Default:
      return btnType01;
    case ButtonSize.Table:
      return btnType05;
    case ButtonSize.Footer:
      return btnType06;
    case ButtonSize.Popup:
      return btnType07;
    default:
      return btnType01;
  }
};

const getButtonColor = (props) => {
  const { type } = props;

  switch (type) {
    case ButtonTypes.Primary:
      return css`
        &&& {
          background-color: ${CommonStyles.Colors.primary};
          color: #fff;
        }
      `;
    case ButtonTypes.Basic:
      return css`
        background-color: #9eb3cd;
        color: #fff;
      `;

    case ButtonTypes.Defaults:
      return css`
        border: solid 1px #8c939c;
        background-color: #fff;
        color: #080808;
      `;
    case ButtonTypes.Outline:
      return css`
        position: relative;
        background-color: #fff;
        color: ${CommonStyles.THEME_COLOR};
        border: solid 2px ${CommonStyles.THEME_COLOR};
      `;

    case ButtonTypes.Inert:
      return css`
        position: relative;
        background-color: #dde3eb;
        color: #fff;
      `;

    case ButtonTypes.Disabled:
      return css`
        position: relative;
        background-color: #8c939c;
        color: #dde3eb;
      `;

    default:
      return css`
        border: solid 1px #8c939c;
        background-color: #fff;
        color: #080808;
      `;
  }
};

const getIconCss = (props) => {
  if (!props.startIcon && !props.endIcon) {
    return null;
  }
  return css`
    &&& {
      position: relative;
      padding-left: ${props.startIcon ? '62px' : '28px'};
      padding-right: ${props.startIcon ? '30px' : '60px'};
      ${(props) =>
        props.startIcon && Icons[props.startIcon.name]
          ? Icons[props.startIcon.name].css
          : props.endIcon && Icons[props.endIcon.name]
          ? Icons[props.endIcon.name].css
          : null}
    }
  `;
};
const ButtonBlock = styled.button`
  ${(props) => getButtonCss(props)}
  ${(props) => getIconCss(props)}
  ${(props) => getButtonColor(props)}
  ${(props) =>
    props.width &&
    css`
      &&& {
        width: ${props.width}px;
        min-width: ${props.width}px;
        max-width: ${props.width}px;
      }
    `}
  }
`;

const Button = (props) => {
  return <ButtonBlock {...props}>{props.children}</ButtonBlock>;
};

Button.propTypes = {
  size: PropTypes.oneOf(Object.values(ButtonSize)),
  // type
  type: PropTypes.oneOf(Object.values(ButtonTypes)),

  // icon
  startIcon: PropTypes.object,
  endIcon: PropTypes.object,

  // action
  onClick: PropTypes.func,

  // style
  style: PropTypes.number
};

Button.defaultProps = {
  //startIcon: Icons.search_blue,
};

export default Button;
