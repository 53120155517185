import React from 'react';
import styled from 'styled-components';

const SignupBlock = styled.div`
  max-width: 980px;
  margin: 0 auto;
  & > div {
    width: 100%;
    margin: 0 auto;
  }
`;

const SignupBody = ({ children }) => {
  return (
    <SignupBlock>
      <div>{children}</div>
    </SignupBlock>
  );
};

export default SignupBody;
