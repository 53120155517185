import React from 'react';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../../../components/button/CommonStyle';

const VoteAgendaContainerBlock = styled.li`
  // .boxlist_ty > ul > li
  margin: 16px 0;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    margin: 12px 0;
  }
`;

const VoteAgendaResultBoxBlock = styled.ul`
  // .boxlist_ty .unit_result
  position: relative;
  display: block;
  padding: 50px 100px 36px;
  background-color: #fff;
  border: solid 1px #24375c;
  border-radius: 8px;

  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    position: relative;
    display: block;
    padding: 50px 20px 26px;
    background-color: #fff;
    border: solid 1px #24375c;
    border-radius: 8px;
  }

  ${(props) =>
    props.isInvalid &&
    // .boxlist_ty .unit_invalid
    css`
      background-color: #f5f7fa;
      border-color: #cad5e3;
      @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
        background-color: #f5f7fa;
        border-color: #cad5e3;
      }
    `}

  // .boxlist_ty .unit_result li
  & > li {
    width: 100%;
    font-size: 15px;
    letter-spacing: -0.5px;
    text-align: center;
    & > button {
      right: 1%;
    }
  }

  & > li:first-of-type {
    & a::after {
      // .boxlist_ty .unit_result li:first-of-type a::after
      top: 5px;
      bottom: none;
    }
  }
`;

const VoteAgendaResultBox = (props) => {
  const { children, isInvalid } = props;
  return (
    <VoteAgendaContainerBlock>
      <VoteAgendaResultBoxBlock isInvalid={isInvalid}>
        {children}
      </VoteAgendaResultBoxBlock>
    </VoteAgendaContainerBlock>
  );
};

export default VoteAgendaResultBox;
