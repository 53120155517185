import React from 'react';
import styled, { css } from 'styled-components';

const TableDataRowBlock = styled.li`
  border-bottom: solid 1px #e9ecf4;
`;

const UL = styled.ul`
  display: flex;
  align-items: center;
`;

const Anchor = styled.a`
  &:hover {
    background: rgba(5, 114, 239, 0.08);
  }
  ${(props) =>
    props.checked &&
    css`
      background: rgba(5, 114, 239, 0.08);
    `}
`;

const TableDataRow = ({ checked, children }) => {
  return (
    <TableDataRowBlock>
      <Anchor checked={checked}>
        <UL>{children}</UL>
      </Anchor>
    </TableDataRowBlock>
  );
};

export default TableDataRow;
