import styled, { css } from 'styled-components';
import { ButtonSize } from '../../../../../../components/button/Button';
import ButtonGroup from '../../../../../../components/button/ButtonGroup';
import spCommonPng from '../../../../../../styles/images/common/sprite/sp_common.png';
import { EMPTY_FUNCTION } from '../../../../../../utils/FunctionUtil';
import { formatNumber } from '../../../../../../utils/StringUtil';
import { VoteValueType } from '../../../VotePage';
import { DescPTagBlock, TableBlock } from './common/PopupBlockStyle';

const { useContext, useEffect, useState } = require('react');
const { MODAL_SIZE } = require('../../../../../../components/modal/Modal');
const { ModalContext } = require('../../../../../../context/ModalContext');
const { CancelBtn, OkBtn } = require('./common/PopupButton');

/**
 * 의결권 행사 Modal
 */

const VotingExercisePopup = (props) => {
  let { setModalConfig, closeModal } = useContext(ModalContext);
  const { agenda, voters, voteData, setVoteData } = props;
  const [localData, setLocalData] = useState(voteData ? [...voteData] : []);
  useEffect(() => {
    setModalConfig({
      size: MODAL_SIZE.MEDIUM,
      isNoButton: true
    });
  }, []);

  useEffect(() => {
    console.log('changed voteData');
    console.log(localData);
  }, [localData]);

  const onAllVote = (voteValueType) => {
    // eslint-disable-next-line array-callback-return
    voters.map((voter) => {
      const exercisableStockAmount = voter.voteRights.find(
        (rights) => rights.agendaSeqno === agenda.agendaSeqno
      )?.exercisableStockAmmount;
      onPopupVote(agenda, voter.stockholderSeqno, {
        [voteValueType]: exercisableStockAmount
      });
      setTimeout(EMPTY_FUNCTION, 0);
    });
  };

  const onPopupVote = (agenda, shareholderSeqno, voteValue) => {
    const initValue = { ayes: 0, noes: 0, abstention: 0 };
    setLocalData((prev) => {
      return [
        ...prev.map((item) => {
          if (item.ballot.shareholderSeqno === shareholderSeqno) {
            return {
              ...item,
              ballot: {
                ...item.ballot,
                values: item.ballot.values.map((value) => {
                  if (value.agendaSeqno === agenda.agendaSeqno) {
                    return {
                      ...value,
                      ...initValue,
                      ...voteValue
                    };
                  } else {
                    if (value.childs) {
                      return {
                        ...value,
                        childs: value.childs.map((child) => {
                          if (
                            value.agendaSeqno === agenda.parentAgendaSeqno &&
                            child.agendaSeqno === agenda.agendaSeqno
                          ) {
                            return {
                              ...child,
                              ...initValue,
                              ...voteValue
                              //[key]: val,
                            };
                          } else {
                            return child;
                          }
                        })
                      };
                    } else {
                      return value;
                    }
                  }
                })
              }
            };
          } else {
            return item;
          }
        })
      ];
    });
  };

  return (
    <>
      <DescPTagBlock>주주별로 찬성 혹은 반대 의결권을 선택하세요</DescPTagBlock>
      <TableBlock>
        <PopupBtnAreaBlock>
          <ul>
            <li>
              <VoteButtonWrapper onClick={() => onAllVote(VoteValueType.Yes)}>
                모두 찬성
              </VoteButtonWrapper>
              <VoteButtonWrapper onClick={() => onAllVote(VoteValueType.No)}>
                모두 반대
              </VoteButtonWrapper>
            </li>
          </ul>
        </PopupBtnAreaBlock>
        <table>
          <colgroup>
            <col width="28%" />
            <col width="*" />
            <col width="35%" />
          </colgroup>
          <thead>
            <tr>
              <th>주주명</th>
              <th colSpan="2">의결사항</th>
            </tr>
          </thead>
          <tbody>
            {/* tr 반복문 돌려야됨 */}
            {voters &&
              voters.map((voter, index) => {
                const exercisableStockAmount = voter.voteRights.find(
                  (rights) => rights.agendaSeqno === agenda.agendaSeqno
                )?.exercisableStockAmmount;
                let voterData = localData?.find(
                  (data) =>
                    data.ballot.shareholderSeqno === voter.stockholderSeqno
                );
                let voterBallotData = voterData.ballot.values?.find(
                  (value) =>
                    value.agendaSeqno === agenda.agendaSeqno ||
                    value.agendaSeqno === agenda.parentAgendaSeqno
                );

                if (voterBallotData.childs) {
                  voterBallotData = voterBallotData.childs.find(
                    (child) => child.agendaSeqno === agenda.agendaSeqno
                  );
                }

                return (
                  <tr key={`VotingExercisePopup-tr-${index}`}>
                    <th>{voter.stockholderName}</th>
                    <td>
                      <VoteButtonWrapper
                        checked={voterBallotData?.ayes > 0}
                        onClick={() =>
                          onPopupVote(agenda, voter.stockholderSeqno, {
                            [VoteValueType.Yes]: exercisableStockAmount
                          })
                        }
                      >
                        찬성
                      </VoteButtonWrapper>
                      <VoteButtonWrapper
                        checked={voterBallotData?.noes > 0}
                        onClick={() =>
                          onPopupVote(agenda, voter.stockholderSeqno, {
                            [VoteValueType.No]: exercisableStockAmount
                          })
                        }
                      >
                        반대
                      </VoteButtonWrapper>
                    </td>
                    <td>
                      <StockSpanBlock>
                        행사가능주식수
                        <strong>{`${formatNumber(
                          exercisableStockAmount
                        )}주`}</strong>
                      </StockSpanBlock>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <ButtonGroup isLayer={true}>
          <CancelBtn
            size={ButtonSize.Default}
            onClick={() => closeModal(250)}
          />
          <OkBtn
            size={ButtonSize.Default}
            onClick={() => {
              setVoteData(localData);
              closeModal(250);
            }}
          />
        </ButtonGroup>
      </TableBlock>
    </>
  );
};

const PopupBtnAreaBlock = styled.div`
  // .tblbtn_area
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 10px !important;
  & > ul {
    display: flex;
    align-items: center;
    & > li {
      flex: 1;
      text-align: left;
      & > button {
        display: flex;
        width: 100%;
        height: 100%;
        min-width: auto;
        align-items: center;
        justify-content: center;
        margin-left: 4px;
      }
      & > button:first-child {
        margin-left: 0;
      }
    }
    & > li:first-of-type {
      margin-left: 0;
    }
    & > li:only-of-type,
    & > li:nth-child(2) {
      text-align: center;
    }
    & > li:last-of-type {
      text-align: right;
    }
  }
`;

const VoteButtonWrapper = styled.button`
  &&& {
    position: relative;
    display: inline-block;
    margin: 4px 2px 2px;
    font-size: 17px;
    font-weight: bold;
    width: 140px;
    text-align: center;
    padding: 8px 20px;
    background-color: #fff;
    border-radius: 4px;
    border: 2px solid #0094ff;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 18%);
    -webkit-box-shadow: 0px 2px 2px rgb(0 0 0 / 18%);
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.18);
    -ms-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.18);
    &:hover {
      background-color: #0075ff;
      border-color: #0075ff;
      color: #fff;
    }
    ${(props) =>
      props.checked &&
      css`
        background-color: #0075ff;
        border-color: #0075ff;
        color: #fff;
        &::after {
          content: '';
          position: absolute;
          top: 9px;
          left: 16px;
          display: block;
          width: 20px;
          height: 15px;
          background: url(${spCommonPng}) no-repeat;
          background-size: 340px auto;
          background-position: -256px -80px;
        }
      `}
  }
`;

const StockSpanBlock = styled.span`
  // .stock
  display: inline-block;
  & > strong {
    display: inline-block;
    width: 120px;
    text-align: right;
    color: #0094ff;
  }
`;

export default VotingExercisePopup;
