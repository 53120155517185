import { all, takeLatest } from '@redux-saga/core/effects';
import APIs from '../../../services/api';
import createRequestSaga from '../../../utils/createRequestSaga';
import {
  fecthPutAgmAgendaAction,
  fetchDeleteAgmAgendaAction,
  fetchGetAgmAction,
  fetchGetAgmAgendaElectionVotersAction,
  fetchGetAgmAgendasElectionVotersExcelAction,
  fetchGetAgmAgendasAction,
  fetchGetAgmAgendasByAgendaSeqnoAction,
  fetchGetAgmAgendasParentOnlyAction,
  fetchGetAgmAgendasProxyRightsAction,
  fetchGetAgmAgendasVoteableAction,
  fetchGetAgmAgendasVotedRightsAction,
  fetchGetAgmBaseInfoAction,
  fetchGetAgmByAgmSeqnoAction,
  fetchGetAgmElectionVoterAction,
  fetchGetAgmElectionVoterFailExcelAction,
  fetchGetAgmExcercisedRightsAction,
  fetchGetAgmLatestAction,
  fetchGetAgmLimitsAction,
  fetchGetAgmLimitsFailExcelAction,
  fetchGetAgmLimitsStockholdersAction,
  fetchGetAgmOpenedAction,
  fetchGetAgmOutlineAction,
  fetchGetAgmPreviousAction,
  fetchGetAgmProxyResultAction,
  fetchGetAgmProxyResultAgendaParticipantsAction,
  fetchGetAgmRemainExercisableStockAction,
  fetchGetAgmScheduleAction,
  fetchGetAgmScheduleGuestAction,
  fetchGetAgmStockholderAction,
  fetchGetAgmStockholdersAction,
  fetchGetAgmStockholdersFailExcelAction,
  fetchGetAgmTotalResultAction,
  fetchGetAgmVoteboxResultAction,
  fetchGetAgmVoteboxResultAgendaParticipantsAction,
  fetchGetAgmVotingRightsDiversitiesAction,
  fetchGetAgmVotingRightsTotalAction,
  fetchGetFilesAgmAction,
  fetchGetFilesAgmAgendaAction,
  fetchGetFilesElectionVoterSampleAction,
  fetchGetFilesLimitsSampleAction,
  fetchPostAgmAgendaAction,
  fetchPostAgmBaseInfoAction,
  fetchPostAgmElectionVoterAction,
  fetchPostAgmElectionVoterFileUploadAction,
  fetchPostAgmLimitsAction,
  fetchPostAgmLimitsFileUploadAction,
  fetchPostAgmSpotResultAction,
  fetchPostAgmStockholdersAction,
  fetchPostAgmTotalResultAction,
  fetchPutAgmCompleteAction,
  fetchPutAgmLimitsAction,
  fetchPutAgmLimitsDeleteAction,
  fetchPutAgmStartModificationAction,
  fetchPutAgmBaseInfoAction,
  fetchGetAgmElectionVoterMineAction,
  fetchPostAgmElectionVoterWithSignAction,
  fetchGetAgmElectronicProxyPdfAction,
  fetchGetAgmLimitsPageAction,
  fetchGetAgmStockholdersSearchAction,
  fetchGetAgmBrowseIndividualAction,
  fetchPostAgmBrowseIndividualAction,
  fetchGetAgmLimitsStockholdersAllAction,
  fetchGetAgmTotalResultExcelAction,
  fetchGetAgmExcercisedConfirmPdfAction,
  fetchGetFilesAgmExcelAction,
  fetchGetENoticeAgmTypeAction,
  fetchGetENoticeAgmSendAction,
  fetchPostENoticeAgmAction,
  fetchPutENoticeAgmSendAction,
  fetchGetENoticeTemplatesAgmTypeAction,
  fetchGetENoticeAgmSendDetailAction,
  fetchGetENoticeAgmSendFailsAction,
  fetchGetENoticeAgmSendFailsExcelDownloadAction,
  fetchGetENoticeAction,
  fetchGetFilesENoticeAgmSendAction,
  fetchGetENoticeAgmTypeDetailAction,
  fetchDeleteENoticeAgmSendAction
} from './promiseActions';

/**
 * 주총 개설
 */

// 주총개설 - 기본정보 입력(Page1)
const fetchPostAgmBaseInfoSaga = createRequestSaga(
  fetchPostAgmBaseInfoAction,
  APIs.Agm.fetchPostAgmBaseInfo
);
// 주총개설 - 기본정보 수정
const fetchPutAgmBaseInfoSaga = createRequestSaga(
  fetchPutAgmBaseInfoAction,
  APIs.Agm.fetchPutAgmBaseInfo
);
// 주총개설 - 기본정보 조회(Page1)
export const fetchGetAgmBaseInfoSaga = createRequestSaga(
  fetchGetAgmBaseInfoAction,
  APIs.Agm.fetchGetAgmBaseInfo
);
// 주주총회 파일 조회
export const fetchGetFilesAgmSaga = createRequestSaga(
  fetchGetFilesAgmAction,
  APIs.Agm.fetchGetFilesAgm
);
/**
 * 안건
 */
// 주총개설 - 안건등록(Page2)
const fetchPostAgmAgendaSaga = createRequestSaga(
  fetchPostAgmAgendaAction,
  APIs.Agm.fetchPostAgmAgenda
);

// 주총개설 - 안건수정(Page2)
const fetchPutAgmAgendaSaga = createRequestSaga(
  fecthPutAgmAgendaAction,
  APIs.Agm.fetchPutAgmAgenda
);

// 주총개설 - 안건삭제(Page2)
const fetchDeleteAgmAgendaSaga = createRequestSaga(
  fetchDeleteAgmAgendaAction,
  APIs.Agm.fetchDeleteAgmAgenda
);
// 주총개설 - 안건 목록 조회(전체)
const fetchGetAgmAgendasSaga = createRequestSaga(
  fetchGetAgmAgendasAction,
  APIs.Agm.fetchGetAgmAgendas
);
// 주총개설 - 안건 목록 조회(부모만)
const fetchGetAgmAgendasParentOnlySaga = createRequestSaga(
  fetchGetAgmAgendasParentOnlyAction,
  APIs.Agm.fetchGetAgmAgendasParentOnly
);
// 주총개설 - 안건 목록 조회(투표대상만)
const fetchGetAgmAgendasVoteableSaga = createRequestSaga(
  fetchGetAgmAgendasVoteableAction,
  APIs.Agm.fetchGetAgmAgendasVoteable
);
// 주총개설 - 안건 조회
const fetchGetAgmAgendasByAgendaSeqnoSaga = createRequestSaga(
  fetchGetAgmAgendasByAgendaSeqnoAction,
  APIs.Agm.fetchGetAgmAgendasByAgendaSeqno
);

/**
 * 주주명부
 */
// 주총개설 - 명부업로드(Page2)
const fetchPostAgmStockholdersSaga = createRequestSaga(
  fetchPostAgmStockholdersAction,
  APIs.Agm.fetchPostAgmStockHolders
);
// 주총개설 - 실패명부다운로드(Page2)
const fetchGetAgmStockholdersFailExcelSaga = createRequestSaga(
  fetchGetAgmStockholdersFailExcelAction,
  APIs.Agm.fetchGetAgmStockHoldersFailExcel
);
// 주총개설 - 명부 전체 조회
const fetchGetAgmStockholdersSaga = createRequestSaga(
  fetchGetAgmStockholdersAction,
  APIs.Agm.fetchGetAgmStockHolders
);
// 주총개설 - 명부 검색(이름)
const fetchGetAgmStockholdersSearchSaga = createRequestSaga(
  fetchGetAgmStockholdersSearchAction,
  APIs.Agm.fetchGetAgmStockholdersSearch
);
// 주총개설 - 주주 조회
const fetchGetAgmStockholderSaga = createRequestSaga(
  fetchGetAgmStockholderAction,
  APIs.Agm.fetchGetAgmStockHolder
);

/**
 * 전자고지서
 */
// 타입별 전자고지서 목록
const fetchGetENoticeAgmTypeSaga = createRequestSaga(
  fetchGetENoticeAgmTypeAction,
  APIs.Agm.fetchGetENoticeAgmType
);
// 타입별 전자고지서 상세 목록
const fetchGetENoticeAgmTypeDetailSaga = createRequestSaga(
  fetchGetENoticeAgmTypeDetailAction,
  APIs.Agm.fetchGetENoticeAgmTypeDetail
);
// 전자고지서 수정화면
const fetchGetENoticeAgmSendSaga = createRequestSaga(
  fetchGetENoticeAgmSendAction,
  APIs.Agm.fetchGetENoticeAgmSend
);
// 전자고지서 등록
const fetchPostENoticeAgmSaga = createRequestSaga(
  fetchPostENoticeAgmAction,
  APIs.Agm.fetchPostENoticeAgm
);
// 전자고지서 수정
const fetchPutENoticeAgmSendSaga = createRequestSaga(
  fetchPutENoticeAgmSendAction,
  APIs.Agm.fetchPutENoticeAgmSend
);
// 전자고지서 삭제
const fetchDeleteENoticeAgmSendSaga = createRequestSaga(
  fetchDeleteENoticeAgmSendAction,
  APIs.Agm.fetchDeleteENoticeAgmSend
);
// 전자고지서 템플릿 조회
const fetchGetENoticeTemplatesAgmTypeSaga = createRequestSaga(
  fetchGetENoticeTemplatesAgmTypeAction,
  APIs.Agm.fetchGetENoticeTemplatesAgmType
);
// 전자고지서 문서 조회 - 주주
const fetchGetENoticeSaga = createRequestSaga(
  fetchGetENoticeAction,
  APIs.Agm.fetchGetENotice
);
// 전자고지서 상세 정보
const fetchGetENoticeAgmSendDetailSaga = createRequestSaga(
  fetchGetENoticeAgmSendDetailAction,
  APIs.Agm.fetchGetENoticeAgmSendDetail
);
// 전자고지서 발송 실패자 목록
const fetchGetENoticeAgmSendFailsSaga = createRequestSaga(
  fetchGetENoticeAgmSendFailsAction,
  APIs.Agm.fetchGetENoticeAgmSendFails
);
// 전자고지서 발송 실패자 목록 엑셀 다운로드
const fetchGetENoticeAgmSendFailsExcelDownloadSaga = createRequestSaga(
  fetchGetENoticeAgmSendFailsExcelDownloadAction,
  APIs.Agm.fetchGetENoticeAgmSendFailsExcelDownload
);
// 전자고지서 파일 다운로드
const fetchFilesENoticeAgmSendSaga = createRequestSaga(
  fetchGetFilesENoticeAgmSendAction,
  APIs.Agm.fetchGetFilesENoticeAgmSend
);
/**
 * 의결권
 */
// 주총개설 - 의결권 제한 등록
const fetchPostAgmLimitsSaga = createRequestSaga(
  fetchPostAgmLimitsAction,
  APIs.Agm.fetchPostAgmLimits
);
// 주총개설 - 의결권 제한 수정
const fetchPutAgmLimitsSaga = createRequestSaga(
  fetchPutAgmLimitsAction,
  APIs.Agm.fetchPutAgmLimits
);
// 주총개설 - 의결권 제한 삭제
const fetchPutAgmLimitsDeleteSaga = createRequestSaga(
  fetchPutAgmLimitsDeleteAction,
  APIs.Agm.fetchPutAgmLimitsDelete
);
// 주총개설 - 의결권 제한 조회
const fetchGetAgmLimitsSaga = createRequestSaga(
  fetchGetAgmLimitsAction,
  APIs.Agm.fetchGetAgmLimits
);
// 주총개설 - 의결권 제한 조회 페이징
const fetchGetAgmLimitsPageSaga = createRequestSaga(
  fetchGetAgmLimitsPageAction,
  APIs.Agm.fetchGetAgmLimitsPage
);
// 주총개설 - 단일주주 의결권 제한 조회
const fetchGetAgmLimitsStockholdersSaga = createRequestSaga(
  fetchGetAgmLimitsStockholdersAction,
  APIs.Agm.fetchGetAgmLimitsStockholders
);
// 주총개설 - 전자투표권자 의결권 제한 조회
const fetchGetAgmLimitsStockholdersAllSaga = createRequestSaga(
  fetchGetAgmLimitsStockholdersAllAction,
  APIs.Agm.fetchGetAgmLimitsStockholdersAll
);
// 현황 엑셀 다운로드
const fetchGetFilesAgmExcelSaga = createRequestSaga(
  fetchGetFilesAgmExcelAction,
  APIs.Agm.fetchGetFilesAgmExcel
);
// 주총개설 - 의결권 조회(단일주주)
const fetchGetAgmElectionVoterSaga = createRequestSaga(
  fetchGetAgmElectionVoterAction,
  APIs.Agm.fetchGetAgmElectionVoter
);
// 주총개설 - 안건별 의결권 조회(전체)
const fetchGetAgmAgendaElectionVotersSaga = createRequestSaga(
  fetchGetAgmAgendaElectionVotersAction,
  APIs.Agm.fetchGetAgmAgendaElectionVoters
);
// 주총개설 - 의결권 합산
const fetchGetAgmVotingRightsTotalSaga = createRequestSaga(
  fetchGetAgmVotingRightsTotalAction,
  APIs.Agm.fetchGetAgmVotingRightsTotal
);
/**
 * 의결권 제한 등록(엑셀)
 */
// 주총개설 - 의결권 제한 샘플 다운로드
const fetchGetFilesLimitsSampleSaga = createRequestSaga(
  fetchGetFilesLimitsSampleAction,
  APIs.Agm.fetchGetFilesLimitsSample
);
// 주총개설 - 의결권 제한 등록(엑셀 업로드)
const fetchPostAgmLimitsFileUploadSaga = createRequestSaga(
  fetchPostAgmLimitsFileUploadAction,
  APIs.Agm.fetchPostAgmLimitsFileUpload
);
// 주총개설 - 의결권 제한 실패 다운로드
const fetchGetAgmLimitsFailExcelSaga = createRequestSaga(
  fetchGetAgmLimitsFailExcelAction,
  APIs.Agm.fetchGetAgmLimitsFailExcel
);
/**
 * 전자투표권자 지정 (엑셀)
 */
// 주총개설 - 전자투표권자 지정 샘플 다운로드
const fetchGetFilesElectionVoterSampleSaga = createRequestSaga(
  fetchGetFilesElectionVoterSampleAction,
  APIs.Agm.fetchGetFilesElectionVoterSample
);
// 주총개설 - 전자투표권자 지정(엑셀 업로드)
const fetchPostAgmElectionVoterFileUploadSaga = createRequestSaga(
  fetchPostAgmElectionVoterFileUploadAction,
  APIs.Agm.fetchPostAgmElectionVoterFileUpload
);
// 주총개설 - 전자투표권자 지정 실패 다운로드
const fetchGetAgmElectionVoterFailExcelSaga = createRequestSaga(
  fetchGetAgmElectionVoterFailExcelAction,
  APIs.Agm.fetchGetAgmElectionVoterFailExcel
);

/**
 * 전자투표권자
 */
// 임시 주총 상태일 때 개인정보 조회
const fetchPostAgmBrowseIndividualSaga = createRequestSaga(
  fetchPostAgmBrowseIndividualAction,
  APIs.Agm.fetchPostAgmBrowseIndividual
);

// 주총개설 - 전자투표권자 지정
const fetchPostAgmElectionVoterSaga = createRequestSaga(
  fetchPostAgmElectionVoterAction,
  APIs.Agm.fetchPostAgmElectionVoter
);

// 전자투표권자 지정 (개설 완료 후)
const fetchPostAgmElectionVoterWithSignSaga = createRequestSaga(
  fetchPostAgmElectionVoterWithSignAction,
  APIs.Agm.fetchPostAgmElectionVoterWithSign
);

// 의안별 전자투표권자명부 다운로드
const fetchGetAgmAgendasElectionVotersExcelSaga = createRequestSaga(
  fetchGetAgmAgendasElectionVotersExcelAction,
  APIs.Agm.fetchGetAgmAgendasElectionVotersExcel
);
/**
 * 주주총회 상태 변경
 */
// 주총개설 - 주총개설완료
const fetchPutAgmCompleteSaga = createRequestSaga(
  fetchPutAgmCompleteAction,
  APIs.Agm.fetchPutAgmComplete
);
// 주총개설 - 주총수정시작
const fetchPutAgmStartModificationSaga = createRequestSaga(
  fetchPutAgmStartModificationAction,
  APIs.Agm.fetchPutAgmStartModification
);

/**
 * 주주총회 조회
 */
// 주주총회 일정 조회-사용자
const fetchGetAgmScheduleSaga = createRequestSaga(
  fetchGetAgmScheduleAction,
  APIs.Agm.fetchGetAgmSchedule
);
//주주총회 일정 조회-게스트
const fetchGetAgmScheduleGuestSaga = createRequestSaga(
  fetchGetAgmScheduleGuestAction,
  APIs.Agm.fetchGetAgmScheduleGuest
);

/**
 * 주주총회 조회 > 주주
 */
// 내 주주총회 목록 조회
const fetchGetAgmSaga = createRequestSaga(
  fetchGetAgmAction,
  APIs.Agm.fetchGetAgm
);

// 주주-> 주주총회 조회
const fetchGetAgmByAgmSeqnoSaga = createRequestSaga(
  fetchGetAgmByAgmSeqnoAction,
  APIs.Agm.fetchGetAgmByAgmSeqno
);

// 의결권 행사내역 조회
const fetchGetAgmExcercisedRightsSaga = createRequestSaga(
  fetchGetAgmExcercisedRightsAction,
  APIs.Agm.fetchGetAgmExcercisedRights
);

// 주주 - 참여 행사확인서 pdf 다운로드
const fetchGetAgmExcercisedConfirmPdfSaga = createRequestSaga(
  fetchGetAgmExcercisedConfirmPdfAction,
  APIs.Agm.fetchGetAgmExcercisedConfirmPdf
);

/**
 * 주주총회 조회 > 발행회사
 */

// 투표 개설 후 개인정보 조회
const fetchGetAgmBrowseIndividualSaga = createRequestSaga(
  fetchGetAgmBrowseIndividualAction,
  APIs.Agm.fetchGetAgmBrowseIndividual
);

// 위임장 pdf다운로드
const fetchGetAgmElectronicProxyPdfSaga = createRequestSaga(
  fetchGetAgmElectronicProxyPdfAction,
  APIs.Agm.fetchGetAgmElectronicProxyPdf
);
// 이전 주주총회 목록 조회
const fetchGetAgmPreviousSaga = createRequestSaga(
  fetchGetAgmPreviousAction,
  APIs.Agm.fetchGetAgmPrevious
);
const fetchGetAgmOpenedSaga = createRequestSaga(
  fetchGetAgmOpenedAction,
  APIs.Agm.fetchGetAgmOpened
);
// 발행회사 최근 주주총회 조회
const fetchGetAgmLatestSaga = createRequestSaga(
  fetchGetAgmLatestAction,
  APIs.Agm.fetchGetAgmLatest
);
// 발행회사 주주총회 개요 조회
const fetchGetAgmOutlineSaga = createRequestSaga(
  fetchGetAgmOutlineAction,
  APIs.Agm.fetchGetAgmOutline
);
// 의결권 행사내역 조회(전자투표)
const fetchGetAgmAgendasVotedRightsSaga = createRequestSaga(
  fetchGetAgmAgendasVotedRightsAction,
  APIs.Agm.fetchGetAgmAgendasVotedRights
);
// 의결권 행사내역 조회(전자위임장)
const fetchGetAgmAgendasProxyRightsSaga = createRequestSaga(
  fetchGetAgmAgendasProxyRightsAction,
  APIs.Agm.fetchGetAgmAgendasProxyRights
);
// 의결권 불통일 행사내역 조회
const fetchGetAgmVotingRightsDiversitiesSaga = createRequestSaga(
  fetchGetAgmVotingRightsDiversitiesAction,
  APIs.Agm.fetchGetAgmVotingRightsDiversities
);

// 의결권 조회
const fetchGetAgmElectionVoterMineSaga = createRequestSaga(
  fetchGetAgmElectionVoterMineAction,
  APIs.Agm.fetchGetAgmElectionVoterMine
);

/**
 * 주총 결과
 */
// 주총 결과 - 전자투표 결과
const fetchGetAgmVoteboxResultSaga = createRequestSaga(
  fetchGetAgmVoteboxResultAction,
  APIs.Agm.fetchGetAgmVoteboxResult
);
// 주총 결과 - 안건별 상세 투표 주주 정보
const fetchGetAgmVoteboxResultAgendaParticipantsSaga = createRequestSaga(
  fetchGetAgmVoteboxResultAgendaParticipantsAction,
  APIs.Agm.fetchGetAgmVoteboxResultAgendaParticipants
);
// 주총 결과 - 전자위임장 결과
const fetchGetAgmProxyResultSaga = createRequestSaga(
  fetchGetAgmProxyResultAction,
  APIs.Agm.fetchGetAgmProxyResult
);
// 주총 결과 - 안건별 상세 위임 주주 정보
const fetchGetAgmProxyResultAgendaParticipantsSaga = createRequestSaga(
  fetchGetAgmProxyResultAgendaParticipantsAction,
  APIs.Agm.fetchGetAgmProxyResultAgendaParticipants
);
// 주총 결과 합산 - 남은 행사 가능 수량 조회
const fetchGetAgmRemainExercisableStockSaga = createRequestSaga(
  fetchGetAgmRemainExercisableStockAction,
  APIs.Agm.fetchGetAgmRemainExercisableStock
);
// 주총 결과 합산 - 현장 결과 등록
const fetchPostAgmSpotResultSaga = createRequestSaga(
  fetchPostAgmSpotResultAction,
  APIs.Agm.fetchPostAgmSpotResult
);
// 주총 결과 합산 - 현장 합산 결과 등록
const fetchPostAgmTotalResultSaga = createRequestSaga(
  fetchPostAgmTotalResultAction,
  APIs.Agm.fetchPostAgmTotalResult
);
// 주총 결과 - 합산 결과 조회
const fetchGetAgmTotalResultSaga = createRequestSaga(
  fetchGetAgmTotalResultAction,
  APIs.Agm.fetchGetAgmTotalResult
);
// 주총 결과 - 합산 결과 엑셀
const fetchGetAgmTotalResultExcelSaga = createRequestSaga(
  fetchGetAgmTotalResultExcelAction,
  APIs.Agm.fetchGetAgmTotalResultExcel
);

// 의안 파일 조회
const fetchGetFilesAgmAgendaSaga = createRequestSaga(
  fetchGetFilesAgmAgendaAction,
  APIs.Agm.fetchGetFilesAgmAgenda
);

export default function* saga() {
  yield all([
    takeLatest(fetchPostAgmBaseInfoAction, fetchPostAgmBaseInfoSaga),
    takeLatest(fetchPutAgmBaseInfoAction, fetchPutAgmBaseInfoSaga),
    takeLatest(fetchGetAgmBaseInfoAction, fetchGetAgmBaseInfoSaga),
    takeLatest(fetchGetFilesAgmAction, fetchGetFilesAgmSaga),
    // 안건
    takeLatest(fetchPostAgmAgendaAction, fetchPostAgmAgendaSaga),
    takeLatest(fecthPutAgmAgendaAction, fetchPutAgmAgendaSaga),
    takeLatest(fetchDeleteAgmAgendaAction, fetchDeleteAgmAgendaSaga),

    takeLatest(fetchGetAgmAgendasAction, fetchGetAgmAgendasSaga),
    takeLatest(
      fetchGetAgmAgendasParentOnlyAction,
      fetchGetAgmAgendasParentOnlySaga
    ),
    takeLatest(
      fetchGetAgmAgendasVoteableAction,
      fetchGetAgmAgendasVoteableSaga
    ),
    takeLatest(
      fetchGetAgmAgendasByAgendaSeqnoAction,
      fetchGetAgmAgendasByAgendaSeqnoSaga
    ),
    //
    takeLatest(fetchPostAgmStockholdersAction, fetchPostAgmStockholdersSaga),
    takeLatest(fetchGetAgmStockholdersAction, fetchGetAgmStockholdersSaga),
    takeLatest(
      fetchGetAgmStockholdersSearchAction,
      fetchGetAgmStockholdersSearchSaga
    ),
    takeLatest(fetchGetAgmStockholderAction, fetchGetAgmStockholderSaga),
    takeLatest(
      fetchGetAgmStockholdersFailExcelAction,
      fetchGetAgmStockholdersFailExcelSaga
    ),
    /**
     * 전자고지서
     */
    takeLatest(fetchGetENoticeAgmTypeAction, fetchGetENoticeAgmTypeSaga),
    takeLatest(
      fetchGetENoticeAgmTypeDetailAction,
      fetchGetENoticeAgmTypeDetailSaga
    ),
    takeLatest(fetchGetENoticeAgmSendAction, fetchGetENoticeAgmSendSaga),
    takeLatest(fetchPostENoticeAgmAction, fetchPostENoticeAgmSaga),
    takeLatest(fetchPutENoticeAgmSendAction, fetchPutENoticeAgmSendSaga),
    takeLatest(fetchDeleteENoticeAgmSendAction, fetchDeleteENoticeAgmSendSaga),
    takeLatest(
      fetchGetENoticeTemplatesAgmTypeAction,
      fetchGetENoticeTemplatesAgmTypeSaga
    ),
    takeLatest(fetchGetENoticeAction, fetchGetENoticeSaga),
    takeLatest(
      fetchGetENoticeAgmSendDetailAction,
      fetchGetENoticeAgmSendDetailSaga
    ),
    takeLatest(
      fetchGetENoticeAgmSendFailsAction,
      fetchGetENoticeAgmSendFailsSaga
    ),
    takeLatest(
      fetchGetENoticeAgmSendFailsExcelDownloadAction,
      fetchGetENoticeAgmSendFailsExcelDownloadSaga
    ),
    takeLatest(fetchGetFilesENoticeAgmSendAction, fetchFilesENoticeAgmSendSaga),
    /**
     * 의결권
     */
    takeLatest(fetchPostAgmLimitsAction, fetchPostAgmLimitsSaga),
    takeLatest(fetchPutAgmLimitsAction, fetchPutAgmLimitsSaga),
    takeLatest(fetchPutAgmLimitsDeleteAction, fetchPutAgmLimitsDeleteSaga),
    takeLatest(fetchGetAgmLimitsAction, fetchGetAgmLimitsSaga),
    takeLatest(fetchGetAgmLimitsPageAction, fetchGetAgmLimitsPageSaga),
    takeLatest(fetchGetAgmElectionVoterAction, fetchGetAgmElectionVoterSaga),
    takeLatest(
      fetchPostAgmBrowseIndividualAction,
      fetchPostAgmBrowseIndividualSaga
    ),
    takeLatest(
      fetchGetAgmAgendaElectionVotersAction,
      fetchGetAgmAgendaElectionVotersSaga
    ),
    takeLatest(
      fetchGetAgmVotingRightsTotalAction,
      fetchGetAgmVotingRightsTotalSaga
    ),
    takeLatest(fetchGetFilesLimitsSampleAction, fetchGetFilesLimitsSampleSaga),
    takeLatest(
      fetchPostAgmLimitsFileUploadAction,
      fetchPostAgmLimitsFileUploadSaga
    ),
    takeLatest(
      fetchGetAgmLimitsFailExcelAction,
      fetchGetAgmLimitsFailExcelSaga
    ),
    takeLatest(
      fetchGetFilesElectionVoterSampleAction,
      fetchGetFilesElectionVoterSampleSaga
    ),
    takeLatest(
      fetchPostAgmElectionVoterFileUploadAction,
      fetchPostAgmElectionVoterFileUploadSaga
    ),
    takeLatest(
      fetchGetAgmElectionVoterFailExcelAction,
      fetchGetAgmElectionVoterFailExcelSaga
    ),

    takeLatest(fetchPostAgmElectionVoterAction, fetchPostAgmElectionVoterSaga),
    takeLatest(
      fetchPostAgmElectionVoterWithSignAction,
      fetchPostAgmElectionVoterWithSignSaga
    ),
    takeLatest(
      fetchGetAgmAgendasElectionVotersExcelAction,
      fetchGetAgmAgendasElectionVotersExcelSaga
    ),

    //
    takeLatest(fetchPutAgmCompleteAction, fetchPutAgmCompleteSaga),
    takeLatest(
      fetchPutAgmStartModificationAction,
      fetchPutAgmStartModificationSaga
    ),
    takeLatest(fetchGetAgmScheduleAction, fetchGetAgmScheduleSaga),
    takeLatest(fetchGetAgmScheduleGuestAction, fetchGetAgmScheduleGuestSaga),
    takeLatest(fetchGetAgmAction, fetchGetAgmSaga),
    takeLatest(fetchGetAgmByAgmSeqnoAction, fetchGetAgmByAgmSeqnoSaga),
    takeLatest(
      fetchGetAgmExcercisedRightsAction,
      fetchGetAgmExcercisedRightsSaga
    ),
    takeLatest(
      fetchGetAgmExcercisedConfirmPdfAction,
      fetchGetAgmExcercisedConfirmPdfSaga
    ),

    takeLatest(
      fetchGetAgmBrowseIndividualAction,
      fetchGetAgmBrowseIndividualSaga
    ),
    takeLatest(
      fetchGetAgmElectronicProxyPdfAction,
      fetchGetAgmElectronicProxyPdfSaga
    ),
    takeLatest(fetchGetAgmPreviousAction, fetchGetAgmPreviousSaga),
    takeLatest(fetchGetAgmOpenedAction, fetchGetAgmOpenedSaga),

    takeLatest(fetchGetAgmLatestAction, fetchGetAgmLatestSaga),
    takeLatest(fetchGetAgmOutlineAction, fetchGetAgmOutlineSaga),
    takeLatest(
      fetchGetAgmAgendasVotedRightsAction,
      fetchGetAgmAgendasVotedRightsSaga
    ),
    takeLatest(
      fetchGetAgmAgendasProxyRightsAction,
      fetchGetAgmAgendasProxyRightsSaga
    ),
    takeLatest(
      fetchGetAgmVotingRightsDiversitiesAction,
      fetchGetAgmVotingRightsDiversitiesSaga
    ),
    takeLatest(
      fetchGetAgmElectionVoterMineAction,
      fetchGetAgmElectionVoterMineSaga
    ),
    takeLatest(
      fetchGetAgmLimitsStockholdersAction,
      fetchGetAgmLimitsStockholdersSaga
    ),
    takeLatest(
      fetchGetAgmLimitsStockholdersAllAction,
      fetchGetAgmLimitsStockholdersAllSaga
    ),
    takeLatest(fetchGetFilesAgmExcelAction, fetchGetFilesAgmExcelSaga),
    takeLatest(fetchGetAgmVoteboxResultAction, fetchGetAgmVoteboxResultSaga),
    takeLatest(
      fetchGetAgmVoteboxResultAgendaParticipantsAction,
      fetchGetAgmVoteboxResultAgendaParticipantsSaga
    ),
    takeLatest(fetchGetAgmProxyResultAction, fetchGetAgmProxyResultSaga),
    takeLatest(
      fetchGetAgmProxyResultAgendaParticipantsAction,
      fetchGetAgmProxyResultAgendaParticipantsSaga
    ),
    takeLatest(
      fetchGetAgmRemainExercisableStockAction,
      fetchGetAgmRemainExercisableStockSaga
    ),

    takeLatest(fetchPostAgmSpotResultAction, fetchPostAgmSpotResultSaga),
    takeLatest(fetchPostAgmTotalResultAction, fetchPostAgmTotalResultSaga),
    takeLatest(fetchGetAgmTotalResultAction, fetchGetAgmTotalResultSaga),
    takeLatest(
      fetchGetAgmTotalResultExcelAction,
      fetchGetAgmTotalResultExcelSaga
    ),
    takeLatest(fetchGetFilesAgmAgendaAction, fetchGetFilesAgmAgendaSaga)
  ]);
}
