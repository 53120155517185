import { GET } from '../../../utils/constant/httpMethod';
import { request } from '../../../utils/requestHelper';

const API_GROUP = 'secco';
const Brokerage = {
  // 사용 권한 조회
  fetchGetSeccoAuthorization() {
    return request({
      method: GET,
      url: `/v1/${API_GROUP}/authorization`
    });
  },
  // 발행회사 정보 조회
  fetchGetIssueIncorporatedPage(data) {
    return request({
      method: GET,
      url: `/v1/issue-incorporated/page`,
      params: data
    });
  },
  // 전자주총 현황 조회
  fetchGetAgmStatus(data) {
    return request({
      method: GET,
      url: `/v1/agm/status`,
      params: data
    });
  }
};

export default Brokerage;
