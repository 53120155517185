import React from 'react';

export const idpwsign = (signType, callback, payload, isVoteData = false) => {
  document.getElementById('layerBg').style.display = 'block';
  document.getElementById('layerBg').style.zIndex = 201;
  document.getElementById('ifrm').style.zIndex = 202; // 임시
  document.getElementById('ifrm').style.display = 'block'; // 임시

  const ifrm = document.getElementById('ifrm');
  if (window.scrollY > 300) {
    ifrm.style.top = window.scrollY + 200 + 'px';
    ifrm.style.left = window.innerWidth / 2 - 215 + 'px';
  } else {
    ifrm.style.top = window.scrollY + 200 + 'px';
    ifrm.style.left = window.innerWidth / 2 - 215 + 'px';
  }
  const content = ifrm.contentWindow;
  if (typeof content.idpwsign === 'function') {
    content.idpwsign(signType, callback, payload, isVoteData);
  } else {
    console.log('함수가 아닙니다.');
  }
};

export const SIGN_TYPE = {
  PERSON: 0,
  CORP: 1
};

export const SIGN_DATA_TYPE = {
  VOTE: 1,
  VOTE_CANCEL: 2
};

const SignIframe = (props) => {
  return (
    <>
      <div id="layerBg" style={layerBgStyle}></div>
      <iframe
        id="ifrm"
        style={iframeStyle}
        src={`${process.env.PUBLIC_URL}/modules/signkorea/app/index.html`}
        title="singIframe"
      ></iframe>
    </>
  );
};

const layerBgStyle = {
  position: 'fixed',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
  zindex: 9,
  backgroundColor: 'rgb(0, 0, 0)',
  opacity: 0.72,
  display: 'none'
};

const iframeStyle = {
  position: 'absolute',
  // 'z-index': 11,
  zIndex: -1,
  border: 'none',
  display: 'none',
  width: '430px',
  height: '530px'
};

export default SignIframe;
