import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Button, {
  ButtonSize,
  ButtonTypes
} from '../../../../components/button/Button';
import ButtonGroup from '../../../../components/button/ButtonGroup';
import FileButton from '../../../../components/button/FileButton';
import DataTable from '../../../../components/datatable/DataTable';
import CheckBox from '../../../../components/input/CheckBox';
import DateInput from '../../../../components/input/DateInput';
import FileInput from '../../../../components/input/FileInput';
import Input from '../../../../components/input/Input';
import InputBox from '../../../../components/input/InputBox';
import Select from '../../../../components/input/Select';
import TimeInput from '../../../../components/input/TimeInput';
import ListHeader from '../../../../components/layout/ListHeader';
import ListItem from '../../../../components/layout/ListItem';
import Section from '../../../../components/layout/Section';
import Page from '../../../../components/page/Page';
import PageHeader from '../../../../components/page/PageHeader';
import Steps from '../../../../components/step/Steps';
import Terms from '../../../../components/terms/Terms';
import { fetchGetAuthTestTokenAction } from '../../../../services/api/auth/promiseActions';
import { fetchGetExternalOrganizationsAction } from '../../../../services/api/externals/promiseActions';
import { fetchPostFilesTemporaryAction } from '../../../../services/api/files/promiseActions';
import { Icons } from '../../../../styles/Icons';
import { setAccessToken } from '../../../../utils/requestHelper';

const PageTest2 = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  // Test Value : 1101111296676
  //const [issIncNo, setIssIncNo] = useState('');
  const [issIncNo, setIssIncNo] = useState('1101111296676');
  const [fileUploadedData, setFileUploadedData] = useState([]);

  // Test Login
  useEffect(() => {
    dispatch(fetchGetAuthTestTokenAction({ userSeqno: '2418988' }))
      .then((res) => {
        setAccessToken(res.data.accessToken);
      })
      .catch((e) => console.log(e));
  });

  useEffect(() => {
    console.log(issIncNo);
  }, [issIncNo]);
  /*
  const uploadCorpFile = (fileType, file) => {
    dispatch(fetchPostFilesTemporaryAction(file))
      .then((res) => {
        const fileData = {
          ...res.data,
          issueIncorporatedFileType: fileType,
        };
        setFileUploadedData([...fileUploadedData, fileData]);
        console.log(fileUploadedData);
      })
      .catch((e) => console.log(e));
  };

  const uploadBizFile = (file) => {
    setFileUploadedData([...fileUploadedData, file]);
  };
*/
  const fetchOrganizations = () => {
    dispatch(fetchGetExternalOrganizationsAction({ searchText: issIncNo }))
      .then((res) => {
        console.log('res ././....!');
        console.log(res);
        console.log(res.data.response.body);
        const data = res.data.response.body.items.item[0];
        reset({
          corpNm: data.corpNm,
          corpEnsnNm: data.corpEnsnNm,
          bzno: data.bzno,
          crno: data.crno
        });
        console.log(res);
      })
      .catch((e) => console.log(e));
  };

  const handleFile = (fileType, file) => {
    const fd = new FormData();
    fd.append('file', file);

    dispatch(fetchPostFilesTemporaryAction(fd))
      .then((res) => {
        console.log('res !!!!!!!!!!!!!!!!');
        console.log(res);
        const fileData = {
          ...res.data,
          issueIncorporatedFileType: fileType
        };
        setFileUploadedData([...fileUploadedData, fileData]);
        console.log('fileUploadedData');
        console.log(fileUploadedData);
      })
      .catch((e) => console.log(e));
  };

  const onSubmit = (data) => {
    const newData = {
      ...data,
      files: fileUploadedData
    };

    console.log('submit Data..');
    console.log(newData);
  };

  return (
    <Page gnb={null}>
      <PageHeader title="발행회사 계약 신청" />
      <Section>
        <Terms />
      </Section>
      <Section>
        <ListHeader title="발행회사" isRequired />
        <ListItem>
          <InputBox title="검색 식별정보" isRequired>
            <Input
              value={issIncNo}
              onChange={(e) => setIssIncNo(e.target.value)}
              placeholder="검색어를 입력해주세요"
            />
            <Button
              type={ButtonTypes.Basic}
              startIcon={Icons.search_white}
              onClick={fetchOrganizations}
            >
              조회
            </Button>
          </InputBox>
          <InputBox isEmpty />
        </ListItem>
      </Section>
      <Section>
        <ListHeader title="가입정보" />
        <ListItem>
          <InputBox title="발행회사명 (한글)" isRequired>
            <Input
              register={register}
              name="corpNm"
              placeholder="회사명을 입력해주세요(한글)"
            />
          </InputBox>
          <InputBox title="발행회사명 (영문)" isRequired>
            <Input
              register={register}
              name="corpEnsnNm"
              placeholder="회사명을 입력해주세요(영문)"
            />
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox
            title="법인 등록번호"
            isRequired
            error="숫자만 입력 가능합니다"
          >
            <Input
              register={register}
              name="crno"
              placeholder="법인명/법인등록번호"
            />
            법인등기등본
            <FileButton fileType="1" handleFile={handleFile}></FileButton>
          </InputBox>
          <InputBox title="사업자 등록번호" isRequired>
            <Input
              register={register}
              name="bzno"
              placeholder="회사명을 입력해주세요(영문)"
            />
            <FileButton fileType="2" handleFile={handleFile}>
              사업자 등록증
            </FileButton>
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox title="주식시장 구분" isRequired>
            <Select register={register} name="selectbox">
              <option value="1">코스피</option>
              <option value="2">코스피2</option>
            </Select>
          </InputBox>
          <InputBox title="주식종목코드 (상장회사)" isRequired>
            <Input
              register={register}
              name="bzno2"
              placeholder="주식종목코드"
            />
          </InputBox>
          <InputBox />
        </ListItem>
        <ListItem>
          <InputBox title="대표자" isRequired>
            <Input
              register={register}
              name="bzno2"
              placeholder="주식종목코드"
            />
          </InputBox>
          <InputBox title="결산월" isRequired>
            <Select register={register} name="selectbox">
              <option value="12">12</option>
              <option value="11">11</option>
            </Select>
          </InputBox>
          <InputBox title="대행기관" isRequired>
            <Select register={register} name="selectbox">
              <option value="1">한국예탈결제원</option>
              <option value="2">우리집</option>
            </Select>
          </InputBox>
        </ListItem>
      </Section>
      <Section>
        <ListHeader title="비밀번호" />
        <ListItem>
          <InputBox
            title="비밀번호"
            isRequired
            error={[
              '조회 전용 비밀번호를 설정하여 주세요.',
              '8~16자리 영문, 숫자, 특수문자 사용하세요'
            ]}
          >
            <Input type="password" register={register} name="password" />
          </InputBox>
          <InputBox
            title="비밀번호 확인"
            isRequired
            error="비밀번호 일치합니다"
          >
            <Input type="password" register={register} name="passwordConfirm" />
          </InputBox>
        </ListItem>
      </Section>
      <Section>
        <ListHeader title="발행회사 담당자" />
        <ListItem>
          <InputBox title="이름" isRequired>
            <Input register={register} name="person.name" placeholder="이름" />
          </InputBox>
          <InputBox title="직위" isRequired>
            <Input
              register={register}
              name="person.wlrdnl"
              placeholder="직위"
            />
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox title="부서" isRequired>
            <Input register={register} name="person.qntj" placeholder="부서" />
          </InputBox>
          <InputBox title="전화번호" isRequired>
            <Input
              register={register}
              name="person.telno"
              placeholder="숫자만 입력 가능합니다."
            />
          </InputBox>
        </ListItem>
        <ListItem>
          <InputBox title="휴대폰번호" isRequired>
            <Input
              register={register}
              name="person.cellno"
              placeholder="숫자만 입력 가능합니다."
            />
            <CheckBox
              register={register}
              name="person.check1"
              title="문자 알림 동의"
            />
          </InputBox>
          <InputBox title="이메일" isRequired>
            <Input
              register={register}
              name="person.email"
              placeholder="이메일 형식을 맞추어 입력하세요"
            />
            <CheckBox
              register={register}
              name="person.check2"
              title="이메일 알림 동의"
            />
          </InputBox>
        </ListItem>
      </Section>
      <Section>
        <ButtonGroup>
          <Button
            type={ButtonTypes.Outline}
            size={ButtonSize.Large}
            endIcon={Icons.search_blue}
          >
            전자투표관리서비스 위탁계약서
          </Button>
          <Button
            type={ButtonTypes.Outline}
            size={ButtonSize.Large}
            endIcon={Icons.search_blue}
          >
            전자위임장관리서비스 위탁계약서
          </Button>
        </ButtonGroup>
      </Section>
      <DateInput />
      <TimeInput />
      <FileButton>하하</FileButton>
      <FileButton>하하</FileButton>
      <FileInput />
      <Section>
        <ListItem>
          <InputBox title="전자투표 이사회 결의서" isRequired>
            <FileInput />
          </InputBox>
          <InputBox title="전자투표 이사회 결의서" isRequired>
            <FileInput />
          </InputBox>
          <InputBox title="전자투표 이사회 결의서" isRequired>
            <FileInput />
          </InputBox>
        </ListItem>
      </Section>
      <Section>
        <Steps
          stepInfo={[
            {
              title: '01 주주총회 정보등록',
              value: 100,
              complete: true
            },
            {
              title: '02 의안/주주명부 등록',
              value: 100,
              complete: true
            },
            {
              title: '03 의결권 행사 제한 등록',
              on: true,
              value: 100
            },
            {
              title: '04 투표권자 명부 확정',
              value: 50
            }
          ]}
        />
      </Section>
      <ButtonGroup>
        <Button
          type={ButtonTypes.Primary}
          size={ButtonSize.Medium}
          onClick={handleSubmit(onSubmit)}
        >
          법인 공인인증서 등록 및 가입
        </Button>
      </ButtonGroup>
      <Section>
        <DataTable />
      </Section>
    </Page>
  );
};

export default PageTest2;
