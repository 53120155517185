import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import PageFooter from '../../pages/common/main/PageFooter';
import { fetchGetUsersMeAction } from '../../services/api/users/promiseActions';
import { action as commonAction } from '../../store/commonSlice';
import { GNB_ID } from '../../utils/constant/GnbMenu';
import { clearRefreshToken, setAccessToken } from '../../utils/requestHelper';
import { isCommonUrl } from '../../utils/UrlUtil';
import Spinner from '../alert/Spinner';
import { SCREEN_SIZE_MOBILE } from '../button/CommonStyle';
import Gnb from './Gnb';

const ContentBlock = styled.section`
  // .content
  /* max-width: 1360px; */
  /* min-height: 100%; */
  min-height: calc(100vh - 117px);
  padding-top: 88px;
  margin: 0 auto;
  padding: 88px 0 80px;
  box-sizing: border-box;
  ${(props) =>
    props.extend &&
    css`
      max-width: 100%;
      background-color: #f5f7fa;
    `}
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    min-height: 100%;
    padding: 50px 16px 60px;
  }
`;

const PageBlock = styled.div`
  height: 100%;
`;

const Page = ({ children, extend = false, logoPlace }) => {
  const dispatch = useDispatch();
  const [isAuth, setIsAuth] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!isCommonUrl(history.location.pathname)) {
      dispatch(fetchGetUsersMeAction()).then((res) => {
        if (!res.error) {
          setIsAuth(true);
        } else {
          dispatch(commonAction.setCurrentUser(null));
          setAccessToken(null);
          clearRefreshToken();
          clearInterval(window.tokenRefresh);
          dispatch(commonAction.setCurrentGnb(GNB_ID.COMMON));
          history.push('/');
        }
      });
    } else {
      setIsAuth(true);
    }
  }, []);

  useEffect(() => {
    // 페이지 이동 시 스크롤 초기화
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);
  return (
    <>
      {isAuth && (
        <PageBlock>
          <Gnb logoPlace={logoPlace} />
          <ContentBlock extend={extend ? true : false}>
            {/*
        <Navigation />
        */}
            {children}
          </ContentBlock>
          <PageFooter />
        </PageBlock>
      )}
      {!isAuth && <Spinner isEmptyScreen={true} />}
    </>
  );
};

export default Page;
