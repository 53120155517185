import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SCREEN_SIZE_MOBILE } from '../../../components/button/CommonStyle';
import {
  fetchGetNoticeAction,
  fetchGetNoticeNotifiedAction
} from '../../../services/api/common/promiseActions';
import locationArrPng from '../../../styles/images/common/sprite/location_arr.png';
import spCommonPng from '../../../styles/images/common/sprite/sp_common.png';
import { DateFormatName, getDateFormat } from '../../../utils/DateFormatUtil';
import { DATE_FORMAT } from '../../../utils/DateUtil';
import PageFooter from './PageFooter';

const FooterArea = styled.div`
  // #footer
  width: 100%;
  background-color: #fff;
  height: 360px !important;
`;

const GuideArea = styled.div`
  // #footer .guidearea
  max-width: 1360px;
  margin: 0 auto;
  & > ul {
    // #footer .guidearea ul
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 20px;
    }
    & > li {
      // #footer .guidearea ul > li
      flex: 1;
      text-align: center;
      padding: 28px 0 24px;
      height: 82px;
      position: relative;

      &::before {
        // #footer .guidearea ul > li:before
        position: absolute;
        top: calc(50% - 68px / 2);
        left: 0;
        width: 1px;
        height: 46px;
        background-color: #d2d4d5;
        content: '';
        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          left: 5px;
          top: calc(50% - 100px / 2);
          width: 300px;
          height: 1px;
        }
      }
      & > a {
        // #footer .guidearea ul > li > a
        display: inline-block;
        & > p {
          // #footer .guidearea ul > li p
          position: relative;
          display: inline-block;
          padding-left: 76px;
          font-size: 25px;
          text-align: center;

          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            font-size: 20px;
          }

          &::after {
            // #footer .guidearea ul > li > a > p::after
            content: '';
            position: absolute;
            left: 0;
            top: 5px;
            background: #fff url(${spCommonPng}) no-repeat;
            background-size: 400px auto;
            background-position: -1px -235px;
            width: 65px;
            height: 66px;
          }

          & > span {
            // #footer .guidearea ul > li p span
            display: block;
            font-size: 14px;
            margin: 12px 0;
          }
        }
      }
      & p span {
        display: block;
        font-size: 14px;
        margin: 12px 0;
      }
    }
    & > li:last-child p {
      // #footer .guidearea ul > li:last-child p
      padding-left: 0;
      font-size: 30px;
      margin: 12px 0 -4px;
    }
    & > li:first-child::before {
      // #footer .guidearea ul > li:first-child::before
      display: none;
    }
  }
`;

const NoticeArea = styled.div`
  // #footer .nInner
  width: 100%;
  background-color: #f4f5f9;
  padding: 38px 0 32px;
  @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
    padding: 18px 0 12px;
  }

  & ul {
    // #footer .nInner ul
    max-width: 1360px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    & > li {
      // #footer .nInner ul > li
      display: inline-block;
      width: auto;
      text-align: left;
      & > p {
        // #footer .nInner ul > li > p
        padding-left: 18px;
        width: 196px;
        font-size: 20px;
        font-weight: bold;
        position: relative;

        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          padding-left: 18px;
          width: auto;
          margin-right: 15px;
          font-size: 15px;
          font-weight: bold;
          position: relative;
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 6px;
          background-color: #0094ff;
          border-radius: 3px;
          width: 5px;
          height: 5px;

          @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
            display: none;
          }
        }
      }
      & > span {
        // #footer .nInner ul > li > span
        display: block;
        width: 140px;
      }
      & > a {
        // #footer .nInner ul > li > a
        display: block;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 17px;

        @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
          font-size: 13px;
          line-height: 17px;
        }
      }
      & > button {
        position: relative;
        display: inline-block;
        width: auto;
        height: 30px;
        padding: 0 32px 0 22px;
        margin-left: 6px;
        border: solid 1.8px #caccd7;
        border-radius: 16px;
        font-size: 13px;
        font-weight: bold;
        background: #fff;
        &::after {
          // #footer .nInner ul > li > button::after
          content: '';
          position: absolute;
          top: 8px;
          right: 18px;
          width: 6px;
          height: 9px;
          background: url(${locationArrPng}) no-repeat;
        }
      }
    }
    & > li:nth-child(3) {
      flex: 1;
    }
    @media only screen and (max-width: ${SCREEN_SIZE_MOBILE}px) {
      & > li:nth-child(2) {
        display: none;
      }
      & > li:nth-child(4) {
        display: none;
      }
    }
  }
`;

const MainPageFooter = () => {
  const dispatch = useDispatch();
  const [notice, setNotice] = useState();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchGetNoticeNotifiedAction()).then((res) => {
      if (!res.error) {
        if (res.data.length !== 0) {
          setNotice(res.data[res.data.length - 1]);
        } else {
          dispatch(fetchGetNoticeAction()).then((res2) => {
            if (!res2.error) {
              setNotice(res2.data.content[res2.data.content.length - 1]);
            }
          });
        }
      }
    });
  }, [dispatch]);

  const onClickNoticeDetail = () => {
    history.push({
      pathname: `/notice/${notice.notientSeqno}`,
      state: { detail: notice }
    });
  };

  return (
    <FooterArea>
      <GuideArea>
        <ul>
          <li>
            <Link to="/shareholder-guide">
              <p>
                <span>주주 서비스 이용이 처음인가요?</span>
                <strong>주주 서비스 이용</strong> 가이드
              </p>
            </Link>
          </li>
          <li>
            <Link to="/issue-incorporated-guide">
              <p>
                <span>발행회사 서비스 이용이 처음인가요?</span>
                <strong>발행회사 서비스 이용</strong> 가이드
              </p>
            </Link>
          </li>
          <li>
            <p>
              고객센터 <strong>070-7791-1100</strong>
              <span>평일 오전 10시 ~ 오후 5시</span>
            </p>
          </li>
        </ul>
      </GuideArea>
      {notice && (
        <NoticeArea>
          <ul>
            <li>
              <p>공지사항</p>
            </li>
            {!!notice && (
              <>
                <li>
                  <span>
                    {DATE_FORMAT(
                      notice.registerDateTime,
                      getDateFormat(DateFormatName.SHORT_DATE_HYPEN)
                    )}
                  </span>
                </li>
                <li>
                  <Link onClick={onClickNoticeDetail} to="#">
                    {notice.title}
                  </Link>
                </li>
                <li>
                  <button onClick={onClickNoticeDetail}>자세히보기</button>
                  <button
                    onClick={() => {
                      history.push('/notice');
                    }}
                  >
                    목록
                  </button>
                </li>
              </>
            )}
          </ul>
        </NoticeArea>
      )}
      <PageFooter />
    </FooterArea>
  );
};

export default MainPageFooter;
